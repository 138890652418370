import React, { useEffect, useState } from 'react';
import { config, apiHelper, Modal, toast } from '../index';
import { CustoForm } from './CustoForm';

export function Custo(props) {
    const endPoint = 'v2/projeto/custo';
    const projetoId = props.parentId;
    const orcamentoId = props.orcamentoId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: '',
        showModal: false,
        modalTitle: "Novo Custo"
    });
    const [tipos, setTipos] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=projetoId::equal::${projetoId}`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);
                    apiHelper.get('portal/crud/PortalMetadata?filter=Categoria::equal::TipoProvisionamento')
                        .then(resp => {
                            let tips = [];
                            resp.data.data.map((item) =>
                                tips.push({ valor: item.valor, nome: item.nome })
                            );
                            setTipos(tips);
                        }).catch(error => {
                            toast.error('Houve um erro ao carregar o registro.');
                        });
                }

                else
                    toast.error(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const handleNew = () => {
        setParam({ ...params, showModal: true, rowId: '', modalTitle: 'Novo Custo' });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData();
            props.onUpdate();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleShowForm = (rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: rowId > 0 ? 'Editar Custo' : 'Novo Custo'
        });
    };

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
        props.onUpdate();
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustoForm orcamentoId={orcamentoId} projetoId={projetoId} custoId={params.rowId} onHide={handleHide} />
                </Modal.Body>
            </Modal>

            {(props.canEdit || config.validarClaim('projeto.escopo.editar')) && <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
            </div>}

            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin">
                    <thead>
                        <tr className="bg-light-blue color-palette">
                            <th>Descrição</th>
                            <th>Data Cadastro</th>
                            <th>Tipo</th>
                            <th>Valor</th>
                            <th>Criado Por</th>
                            {props.canEdit && <th style={{ width: 80 }}>Ação</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .map((item, i) => {
                                return (
                                    <tr key={i} style={{ "width": "95px" }}>
                                        <td>{item.descricao}</td>
                                        <td>{config.dateFix(item.dataCriacao)}</td>
                                        <td>{(item.prestador ? item.tipo + ': ' + item.prestador :
                                            (tipos.length > 0 && tipos.find((tipo) => tipo.valor === item.tipo) ?
                                                tipos.find((tipo) => tipo.valor === item.tipo).nome :
                                                item.tipo))}</td>
                                        <td>{config.currencyFix(item.valor)}</td>
                                        <td>{item.criador}</td>
                                        {(props.canEdit || config.validarClaim('projeto.escopo.editar')) && <td style={{ "width": "95px" }}>
                                            <button style={{ "marginRight": "10px" }} type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm(item.id)}><i className="fa fa-edit" /></button>
                                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /></button>
                                        </td>}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
