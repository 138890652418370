import React, { Component } from 'react';
import {
    Modals, FieldGroup, Table, Button
} from '../../components/adminlte/AdminLTE';

export class PesquisarMaterial extends Component {

    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            apiEndPointMaterial: localStorage.getItem('apiUrl') + 'siga/serviceOrder/material',
            data: this.props.data,
            onImport: this.props.onImport,
            isSaving: false,
            row: {},
            materialList: [],
            materialValueSearch: ''
        };

        this.materialSearch = this.materialSearch.bind(this);
        this.setMateriais = this.setMateriais.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row });
    }

    getMateriais(filter) {
        fetch(this.state.apiEndPointMaterial + "?name=" + filter, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ materialList: data });
        });
    }

    materialSearch(e) {

        let value = e === null ? '' : e.target.value;
        this.setState({ materialValueSearch: value });

        if (value.length > 2) {
            this.getMateriais(value);
        } else {
            this.setState({ materialList: [] });
        }
    }

    onHide() {

        this.setState({ materialList: [], materialSearch: '' });
        this.props.onHide();
    }

    setMateriais(row) {
        this.setState({ materialList: [], materialValueSearch: row.name });
        this.state.onImport(row);
    }

    render() {

        const { show = false } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title="Buscar Materiais" show={show} onHide={() => this.onHide()} >
                <form onSubmit={this.handleSubmit}>

                    <FieldGroup id="nameMaterial" col={12} type="text" onChange={this.materialSearch} value={this.state.materialValueSearch} />

                    <Table striped bordered hover>
                        <tbody>
                            {this.state.materialList.map(row => {
                                return (
                                    <tr key={row.materialCode}>
                                        <td>{row.name}</td>
                                        <td style={{ width: "50px" }}>
                                            <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.setMateriais(row)}>
                                                {isSaving ? 'Adicionando...' : 'Adicionar'}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                    <Button bsStyle="success" type="button" onClick={() => this.onHide()}>Ok</Button>
                </form>
            </Modals>
        );
    }
}

