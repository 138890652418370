import React, { useState, useEffect } from 'react';
import { apiHelper, Select, fileHelper, toast } from '../';
import moment from 'moment-timezone';
import { Table, BarChart } from './reports';

export function ReportView(props) {
    const reportId = props.reportId || props.match.params.id;
    const [report, setReport] = useState({});
    const [config, setConfig] = useState([]);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({});
    const [filtro, setFiltro] = useState([]);

    let where = "";

    const fetchData = () => {

        apiHelper.get(`portal/crud/Report?filter=id::equal::${reportId}`)
            .then(res => {
                if (!res.data.data[0]) {
                    return;
                }
                setReport(res.data.data[0]);
                setConfig(JSON.parse(res.data.data[0].config));
                let whre = '';
                if (res.data.data[0].filtro && res.data.data[0].filtro !== "") {
                    setFiltro(JSON.parse(res.data.data[0].filtro));
                    let values = [];
                    JSON.parse(res.data.data[0].filtro).map(item => {
                        if (item.defaultValue) {
                            if (whre !== '')
                                whre += ';;AND;;';
                            whre += `${item.id}::${item.condicao}::${item.defaultValue}`;
                            let fieldAux = item.field;
                            values.push([fieldAux, item.defaultValue]);
                        }

                        return null;
                    });
                    setFilter(Object.fromEntries(values));
                }
                else
                    setFiltro([]);

                if (!res.data.data[0].caminho) {
                    apiHelper.get(`v2/report/${reportId}?filter=${whre}`)
                        .then(res => {
                            res.data.data && setData(res.data.data);
                        })
                        .catch(error => {
                            toast.error('Não foi possível exibir este relatório!');
                        });
                }
            })
            .catch(error => {
                toast.error('Não foi encontrar o relatório!');
            });
    }

    useEffect(fetchData, [reportId]);

    const heandleExportExcel = (e) => {
        handleSearch();
        fileHelper.exportToExcelFromReport(where, reportId, report.nome + moment().format("DD/MM/YYYY"));
    }

    const fetchFiltro = () => {
        apiHelper.get(`v2/report/${reportId}?filter=${where}`)
            .then(res => {
                setData(res.data.data);
            })
            .catch(error => {
                toast.error('Não foi possível carregar os dados!');
            });
    }

    const renderReport = (report) => {
        if (report.caminho) {
            try {
                let Report = require('../' + report.caminho).default;
                return <Report />;
            } catch (erro) {
                return "";
            }
        } else {
            switch (report.tipo) {
                case "table":
                    return <Table data={data} config={config} />
                case "bar_chart":
                    return <BarChart data={data} config={report.config} />
                default:
                    return "";
            }
        }
    }

    const handleRefresh = () => {
        setFilter([]);
        setData([]);
        where = "";
        fetchFiltro();
    };

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = () => {
        where = "";

        filtro.map(item => {
            let value = filter[item.field];
            if (value) {
                let { id, condicao } = item;
                if (where !== '') where += ';;AND;;';
                where += `${id}::${condicao}::${value}`
            }

            return "";
        });

        fetchFiltro();
    };

    const renderInput = (item) => {
        switch (item.type) {
            case "select":
                return (
                    <Select id={item.field} className="form-control form-control-sm" onChange={handleChange} value={filter[item.field] || item.defaultValue || ""}>
                        <option value="">{item.select.initialValue || "Selecione"}</option>
                        {
                            Array.isArray(item.select.data)
                                ?
                                item.select.data.map((subItem, i) => <option key={i} value={subItem.valueField}>{subItem.textField}</option>)
                                :
                                <option endpoint={item.select.data} valuefield={item.select.valueField} textfield={item.select.textField} />
                        }
                    </Select>
                )
            case "date":
                return (<input id={item.field} type={item.type} className="form-control form-control-sm" onChange={handleChange} value={filter[item.field] || ""} placeholder="dd/mm/yyyy" />)
            default:
                return (<input id={item.field} type={item.type} className="form-control form-control-sm" onChange={handleChange} value={filter[item.field] || ""} />)
        }
    }

    return (
        <React.Fragment>
            <div className="mailbox-controls">
                {(report.tipo === "table" || report.tipo === "bar_chart") && (filtro && filtro.length > 0) &&
                    <div className="btn-group mr-1">
                        <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">Pesquisar</button>

                        <div className="dropdown-menu p-0" role="menu" style={{ width: 600 }} onClick={e => e.stopPropagation()}>
                            <div className="card shadow-none m-0">
                                <div className="card-body p-2" style={{ maxHeight: 300, overflow: 'auto' }}>
                                    <div className="row">
                                        {filtro.map((item, i) =>
                                            <div key={i} className={`form-group col-md-${item.size || 12}`}>
                                                <label className="text-sm">{item.label}</label>
                                                {renderInput(item)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer p-2">
                                    <button type="button" className="btn btn-default btn-sm float-right ml-2" onClick={handleRefresh}>Limpar</button>
                                    <button type="button" className="btn btn-primary btn-sm float-right ml-2" onClick={handleSearch}>Pesquisar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {config.isExported && <button type="button" className="btn btn-default btn-sm mr-1" onClick={heandleExportExcel}>Exportar</button>}
            </div>

            {renderReport(report)}
        </React.Fragment>
    )
}