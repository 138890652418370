import React from 'react';
import axios from 'axios';
import { Route } from 'react-router-dom';
import { config, apiHeader } from '../../../_helpers';

import { Home } from './Home';
import { Dashboard } from './Dashboard';
import { DashboardAdm } from './DashboardAdm';
import { ReportAdm } from './ReportAdm';
import { Datasource } from './Datasource';

const API_URL = config.apiUrl();
const API_HEADER = apiHeader();

const Menu = () => {
    let items = []

    axios.get(API_URL + "portal/crud/ReportsDashboard/?filter=", { headers: API_HEADER })
        .then(res =>
            res.data.data.map((m, i) =>
                items.push({ claim: "MenuDashboard", name: m.name, icon: m.icon, order: i, url: `/dashboard/${m.id}` })
            )
        )

    return [
        { claim: "MenuDashboard", name: "Home", icon: "fas fa-home", order: 0, url: "/" },
        { claim: "MenuDashboard_verificar", name: "Dashboards", icon: "fas fa-tachometer-alt", order: 1, url: "#", children: items },
        {
            claim: "MenuGroupRelatorio", name: "Relatórios", icon: "fas fa-chart-bar", order: 2, url: "#", children: [
                { claim: "MenuGroupRelatorio", name: "Relatórios", icon: "fas fa-chart-bar", order: 3, url: "/report" },
                { claim: "MenuGroupRelatorio", name: "Dashboards", icon: "fas fa-tachometer-alt", order: 3, url: "/dashboards" },
                { claim: "MenuGroupRelatorio", name: "Fonte de Dados", icon: "fas fa-database", order: 3, url: "/datasource" },
            ]
        }
    ]
}

const ReportApp = (props) => {
    return (
        <>
            <Route exact path="/" component={Home} />
            <Route exact path="/report" component={ReportAdm} />
            <Route exact path="/datasource" component={Datasource} />
            <Route exact path="/dashboards" component={DashboardAdm} />
            <Route exact path="/dashboard/:id" component={Dashboard} />
        </>
    );
};

export default {
    name: 'Relatórios',
    version: '1.0',
    control: <ReportApp />,
    menu: Menu()
};
