import React, { useState } from 'react';
import { Content, Link, config, apiHelper, fileHelper, toast } from '../';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function Alerta(props) {
    const endPointAlerta = "vms/device/alert/";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        searchValue: '',
        data: ''
    });
    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowLoading(true);
        apiHelper.get(endPointAlerta + '?filter=' + filter.searchValue + '&date=' + filter.data)
            .then(res => {
                setData(res.data);
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            searchValue: '',
            data: ''
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const handleOrdenarColunas = (e, nomeVariavelColuna) => {
        e.preventDefault();
        let sortedObjs = config.ordenarColunas(data, nomeVariavelColuna);
        setData(sortedObjs);
    }

    return (
        <Content title="Relatório de Alertas" browserTitle="Relatório de Alertas">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="searchValue">Filtro</label>
                                <input id="searchValue" type="text" className="form-control" placeholder="" value={filter.searchValue} onChange={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"data"} col={12} showHora={false} labelData={"Data"} valueData={filter.data} onSelectValue={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Relatório de Alertas</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => fileHelper.exportToCSV(data, 'RelatorioAlertas_' + moment().format("DD/MM/YYYY"))}><i class="fa fa-file-excel-o"></i></button>
                                </div>
                            </div>
                            <div className="box-body table-responsive no-padding">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'name')}><i className="fa fa-sort" /> Nome</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'serialNumber')}><i className="fa fa-sort" /> Serial</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'channel')}><i className="fa fa-sort" /> Canal</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'alarmDescription')}><i className="fa fa-sort" /> Tipo</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'alarmTime')}><i className="fa fa-sort" /> Data</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'alarmReason')}><i className="fa fa-sort" /> Motivo</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'username')}><i className="fa fa-sort" /> Usuário</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.serialNumber}</td>
                                                        <td>{item.channel}</td>
                                                        <td>{item.alarmDescription}</td>
                                                        <td>{config.dateTimeFix(item.alarmTime)}</td>
                                                        <td>{item.alarmReason}</td>
                                                        <td>{item.username}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
