import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { config, apiHelper, toast, Modal, FormGroupButton, Button } from '../index';
import { PesquisaGenerica } from '../../components/controls/PesquisaGenerica';

export function AprovarRequisicao(props) {
    const { handleSubmit } = useForm();
    const [requisicao, setRequisicao] = useState([]);
    const [data, setData] = useState([]);
    const [dataOS, setDataOS] = useState([]);
    const [dataPrestador, setDataPrestador] = useState({});
    const [showFormFindMateriais, setShowFormFindMateriais] = useState(false);
    const [materialValue, setMaterialValue] = useState({});
    const [form, setForm] = useState({});
    const endPointRequisicaoMaterial = 'suprimentos/rm/aprovarRequisicao';
    const [paramsEditarMaterial, setParamEditarMaterial] = useState({ showModal: false, new: false, edit: false });
    const endPoint = 'suprimentos/rm/';

    const fetchData = () => {

        obterEnderecoSite(props.requisicao.id);
        obterEnderecoPrestador(props.requisicao.id);
        setRequisicao(props.requisicao);

        apiHelper.get(`${endPoint}${requisicao.id}/material/`)
            .then(res => {
                if (res.data.code === 200) {
                    setData([]);
                    setData(res.data.data);

                    props.refresh(res.data.data);
                }
                else
                    toast.error('Atenção, não foi possível carregar os materiais!');
            }).catch(error => {
                toast.error(error);
            });
    }

    useEffect(fetchData, [requisicao]);

    const handleShowModalEditarMaterial = (item) => {
        setMaterialValue({});
        setMaterialValue({ ...materialValue, quantidade: item.quantidade });
        setParamEditarMaterial({ ...paramsEditarMaterial, showModal: true, edit: true, new: false, id: item.id });
    }

    const onSubmit = () => {
        if (data.find(x => x.nome === 'Material Genérico')) {
            toast.warn('Edite os materiais genéricos.');
            return;
        }
        if (!window.confirm("Deseja realmente enviar esta requisição ?"))
            return;
        apiHelper.put(`${endPointRequisicaoMaterial}/${requisicao.id}`)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        props.onHide();
    }

    const handleAddMaterial = (row) => {
        setMaterialValue({ ...materialValue, nome: row.nome, id: row.pkMaterial });
    }
    const handleChange = (e) => {
        setMaterialValue({ ...materialValue, quantidade: e.target["value"] });
    }

    const handleChangeEndereco = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleEditarMaterial = () => {
        if (materialValue.quantidade <= 0) {
            toast.warn('Preencha uma quantidade válida.');
            return;
        }
        let obj = materialValue;
        apiHelper.put(`${endPoint}/material/${paramsEditarMaterial.id}`, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                setMaterialValue({});
                handleHideModalEditarMaterial();
            } else if (res.data.code === 406) {
                toast.warn(res.data.msg);
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    const handleHideModalEditarMaterial = () => {
        setParamEditarMaterial({ ...paramsEditarMaterial, showModal: false, edit: false, new: false, id: 0 });
        fetchData();
    }
    const handleShowEditEndereco = () => {
        setParamEditarMaterial({ ...paramsEditarMaterial, showModal: true, edit: false, new: true, id: 0 });
    }
    const handleSaveEndereco = () => {

        let obj = { "localEntrega": "" };
        if (form.localEntregaSite === "Site")
            obj.localEntrega = dataOS.enderecoSite;
        else if (form.localEntregaSite === "Prestador/Regional")
            obj.localEntrega = dataPrestador.enderecoPrestador;
        else if (form.localEntregaSite === "Outro")
            obj.localEntrega = form.localEntrega;

        apiHelper.put(`${endPoint}/avulso/${requisicao.id}`, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                setForm({});
                handleHideModalEditarMaterial();
                requisicao.endereco = obj.localEntrega;

            } else if (res.data.code === 406) {
                toast.warn(res.data.msg);
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    const obterEnderecoSite = (id) => {
        apiHelper.get(`v3/os/${id}`).then(res => {
            let data = res.data;
            setDataOS(data);
        });
    }

    const obterEnderecoPrestador = (id) => {
        apiHelper.get(`v3/os/${id}/prestador`).then(res => {
            let data = res.data.data
            setDataPrestador(data);
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <React.Fragment>
                <section>
                    <div className="row">
                        <div className="col-xs-12">
                            <h2 className="page-header">
                                <i className="fa fa-wrench"></i> {requisicao.id}
                                <small class="pull-right">{config.dateFix(requisicao.dataRequisicao)}</small>
                            </h2>
                        </div>

                    </div>

                    <div className="row invoice-info">
                        <div className="col-sm-4 invoice-col">
                            <address>
                                <b>OS #{requisicao.origemId}</b><br />
                                <strong>Solicitante: </strong> {config.formatName(requisicao.solicitante)}<br />
                                <strong>Data Necessidade:</strong> {config.dateFix(requisicao.dataNecessidade)}<br />
                                <strong>Centro de Custo:</strong> {requisicao.centroCusto}<br />
                            </address>
                        </div>
                        <div className="col-sm-4 invoice-col">
                            <b>Cliente:</b> {requisicao.cliente}<br />
                            <b>Site:</b> {requisicao.site}<br />
                            <b>Endereço Entrega</b> {requisicao.endereco ? requisicao.endereco : "Não Informado"}
                            <span> <i className="fa fa-pencil-square-o" onClick={() => handleShowEditEndereco()} /></span>
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Quantidade</th>
                                        {data.find(x => x.nomeMaterialSolicitado) && <th>Material Solicitado</th>}
                                        <th>Produto</th>
                                        <th>Valor</th>
                                        <th>Observação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td><span> <i className="fa fa-pencil-square-o" onClick={() => handleShowModalEditarMaterial(item)} /></span></td>
                                                <td>{item.quantidade}</td>
                                                {data.find(x => x.nomeMaterialSolicitado) && <td>{item.nomeMaterialSolicitado}</td>}
                                                <td>{item.nome}</td>
                                                <td>{config.currencyFix(item.valor)}</td>
                                                <td>{item.observacao}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </section>
                <div className="box-footer">
                    <div className="box-tool pull-right">
                        <div className="btn-toolbar">
                            <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                            <button type="submit" className="btn btn-primary pull-right">Aprovar</button>
                        </div>
                    </div>
                </div>
                <Modal show={paramsEditarMaterial.showModal} onHide={handleHideModalEditarMaterial} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{paramsEditarMaterial.edit ? "Editar Material" : "Editar Endereço"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {paramsEditarMaterial.edit && <>
                            <div className="row">
                                <div className="form-group col-md-9">
                                    <FormGroupButton placeholder="Material" label="Material" disabled
                                        value={materialValue.nome}
                                        buttonRight={<Button bsStyle="success" type="button" onClick={() => setShowFormFindMateriais(true)}>...</Button>}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Quantidade</label>
                                    <input type="number" id="quantidade" className="form-control" onChange={handleChange} value={materialValue.quantidade || ''} required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-default pull-right" onClick={() => handleHideModalEditarMaterial()}>Fechar</button>
                                    <button type="submit" className="btn btn-primary pull-right" onClick={() => handleEditarMaterial()} style={{ marginRight: "7px" }}>Salvar</button>
                                </div>
                            </div>
                        </>}{paramsEditarMaterial.new && <div className="row">
                            <div className="form-group col-sm-12">
                                <label>Local Entrega Site *</label>
                                <select id="localEntregaSite" className="form-control" onChange={handleChangeEndereco} required>
                                    <option value="">Selecione</option>
                                    <option value={'Site'}>Site</option>
                                    <option value={'Prestador/Regional'}>Prestador/Regional</option>
                                    <option value={'Outro'}>Outro</option>
                                </select>
                            </div>
                            {form.localEntregaSite === 'Outro' && <div className="form-group col-md-12">
                                <label>Endereço de Entrega *</label>
                                <textarea id="localEntrega" className="form-control" rows="3" onChange={handleChangeEndereco} required />
                            </div>}
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-primary pull-right" style={{ marginBottom: "7px" }} onClick={handleSaveEndereco}>Salvar</button>
                            </div>
                        </div>}
                    </Modal.Body>
                </Modal>
                {showFormFindMateriais && <PesquisaGenerica config={{ apiUrl: localStorage.getItem('apiUrl') }} show={showFormFindMateriais} title="Buscar Materiais" source={`${endPoint}material?filter=`} onImport={(row) => { handleAddMaterial(row) }} onHide={() => { setShowFormFindMateriais(false) }} />}
            </React.Fragment>
        </form>
    )
}