import React, { useState, useEffect } from 'react'
import Core from '../Core';
import { Link } from "react-router-dom";

export const NovasOsMonitor = (props) => {
    const [data, setData] = useState([]);

    const config = {
        endPoint: "comercial/proposta?tableName=uvw_novas_os",
        claimsUsuarioLogado: (localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined) ? localStorage.getItem('claims').split(',') : '',
        userId: localStorage.getItem('id')
    };

    const carregarNovasOS = async () => {
        let filtro = '&filtro=';

        if (config.claimsUsuarioLogado.includes('Prestador'))
            filtro = filtro + 'siteId in (SELECT IdSite FROM PessoaSite WHERE IdPessoa = ' + config.userId + ' )';

        await Core.api_get(config.endPoint + filtro).then(res => {
            setData(res.data.data);
        });
    }

    const loadData = () => {
        if (config.claimsUsuarioLogado.includes('HD')) {
            carregarNovasOS();
        }

        const interval = setInterval(() => {
            if (config.claimsUsuarioLogado.includes('HD')) {
                carregarNovasOS();
            }
        }, props.timer);

        return () => clearInterval(interval);
    }

    useEffect(loadData, [props.timer]);

    return (

        <div className="table-responsive">
            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                <thead>
                    <tr className="bg-light-blue">
                        <th>Numero OS</th>
                        <th>Titulo</th>
                        <th>Cliente</th>
                        <th>Site</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item) => {
                            return (<tr key={item.os}>
                                <td><Link to={`/atendimento/${item.os}`}>{item.os}</Link></td>
                                <td>{item.titulo}</td>
                                <td>{item.cliente}</td>
                                <td>{item.site}</td>
                                <td>{item.abertura}</td>
                            </tr>)
                        })
                    }
                </tbody>
            </table>
        </div>
        
)
}