import React, { Component } from 'react';

export class ButtonGroup extends Component {
    render() {
        const { children, pullRight = false } = this.props;
        return (
            <div className={"btn-group" + pullRight ? ' pull-right' : ''}>
                {children}
            </div>
        );
    }
}