import React, { Component } from 'react';

export default class Vehicle extends Component {
    render() {
        return (
            <div>
                Vehicle
            </div>
        );
    }
}