import React, { useEffect, useState } from 'react';
import { config, apiHelper, Modal, toast } from '../index';
import { OrcamentoProdutoForm } from './OrcamentoProdutoForm';
import { ExcelImport } from '../../components/controls/ExcelImport';
import * as _ from 'lodash';

let distinctSites = [];

export function OrcamentoEscopo(props) {
    const permissaoEscrita = config.validarClaim('orcamento.escrita');
    const endPoint = 'orcamento';
    const endPointOrcamentoItem = 'portal/crud/ComercialOrcamentoItem';
    const endPointOrcamentoSite = 'portal/crud/uvw_v2_comercial_orcamento_site';
    const parentId = props.parentId;
    const clienteId = props.clienteId;
    const [data, setData] = useState([]);
    const [totalProdutos, setTotalProdutos] = useState(0);
    const [totalInstalacao, setTotalInstalacao] = useState(0);
    const [totalManutencao, setTotalManutencao] = useState(0);
    const [totalServicos, setTotalServicos] = useState(0);

    const [params, setParam] = useState({
        showModal: false,
        modalMode: 'New',
        idItem: 0
    });

    const fetchData = () => {
        if (clienteId === undefined)
            return;

        apiHelper.get(`${endPoint}/${parentId}/material`)
            .then(res => {

                let dataOrcamentoItem = res.data.data;
                obterSites(dataOrcamentoItem.data);

                setTotalProdutos(dataOrcamentoItem.totalProdutos);
                setTotalInstalacao(dataOrcamentoItem.totalInstalacao);
                setTotalManutencao(dataOrcamentoItem.totalManutencao);
                setTotalServicos(dataOrcamentoItem.totalServico);

            });
    };

    const obterSites = (dataOrcamentoItem) => {
        apiHelper.get(`${endPointOrcamentoSite}?filter=IdCliente::equal::${clienteId};;AND;;OrcamentoId::equal::${parentId};;`)
            .then(res => {
                let sites = res.data.data;

                dataOrcamentoItem.map((item, i) => {
                    let site = sites.find(c => c.id === item.siteId);
                    dataOrcamentoItem[i].nomeSite = site && site.nome;
                    return null;
                });

                let nomesSites = dataOrcamentoItem.map((item, i) => { return item.nomeSite });
                distinctSites = nomesSites.filter((v, i, a) => a.indexOf(v) === i);

                let dataSitesAgrupados = _.groupBy(dataOrcamentoItem, 'nomeSite')
                let dados = [];

                distinctSites.map(item => {
                    dados.push(dataSitesAgrupados[item]);
                    return null;
                });
                setData(dados);
            });
    };

    useEffect(fetchData, [props.clienteId, props.refresh]);

    const handleAction = (action) => {
        setParam({ ...params, showModal: true, modalMode: action, idItem: 0 });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false, modalMode: 'Novo' });
        fetchData();
        props.onUpdate();
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPointOrcamentoItem}/${id}/id`).then(res => {
            config.httpResult(res.data);
            fetchData();
            props.onUpdate();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    };

    const handleEditarProduto = (idItem, action) => {
        setParam({ ...params, showModal: true, modalMode: action, idItem: idItem });
    };

    const onImportSuccessHandle = () => {
        toast.success('Planilha importada com sucesso.');
    };

    const onImportErrorHandle = () => {
        toast.error('Atenção, não foi possível importar planilha.');
    };

    const handleNotificarSuprimentos = async () => {

        let orcamento = {
            processed: false,
            solicitadoAtualizacaoValores: true
        };

        apiHelper.put(`orcamento/${parentId}/suprimentos`, orcamento)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
                props.onUpdate();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    };

    const handleRemoverTodosProdutos = () => {
        if (window.confirm(`Deseja excluir todos os produtos do orçamento ${parentId}?`)) {
            apiHelper.api_delete(`${endPoint}/${parentId}/removerProdutos`).then(res => {
                config.httpResult(res.data);
                fetchData();
                props.onUpdate();
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir os registros.');
            });
        }
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalMode === 'Novo' ? "Novo Produto" : "Importar Materiais"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(params.modalMode === 'New' || params.modalMode === 'Edit') && <OrcamentoProdutoForm parentId={parentId} clienteId={clienteId} idItem={params.idItem} onHide={handleHide} />}
                    {params.modalMode === 'Import' && <ExcelImport label="Importar Produtos" endPoint={`${endPoint}/${parentId}/planilha`} onHide={handleHide} onImportErrorHandle={onImportErrorHandle} onImportSuccessHandle={onImportSuccessHandle} filePath="PlanilhaImportacaoProdutos.xls" />}
                </Modal.Body>
            </Modal>
            <div style={{ marginBottom: "7px" }}>
                {props.status === 'Rascunho' && permissaoEscrita &&
                    <React.Fragment>
                        <button type="button" style={{ marginRight: "2px" }} className="btn btn-default btn-sm btn-flat" onClick={() => handleAction('New')}><i className="fa fa-plus" /> Adicionar</button>
                        <button type="button" style={{ marginRight: "2px" }} className="btn btn-default btn-sm btn-flat" onClick={() => handleAction('Import')}><i className="fa fa-file-excel-o" /> Importar</button>
                        <button type="button" style={{ marginRight: "2px" }} className="btn btn-default btn-sm btn-flat" onClick={handleNotificarSuprimentos}><i className="fa fa-envelope-o" /> Notificar Suprimentos</button>
                        <button type="button" style={{ marginRight: "2px" }} className="btn btn-default btn-sm btn-flat" onClick={handleRemoverTodosProdutos}><i className="fa fa-times" /> Remover Todos</button>
                    </React.Fragment>
                }
            </div>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Descrição</th>
                            <th>Classificação</th>
                            <th>Tipo</th>
                            <th>Qtd</th>
                            <th>Valor</th>
                            <th>Valor LPU</th>
                            <th>Total</th>
                            {props.status === 'Rascunho' && <th>Ação</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && distinctSites.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td colSpan="8" style={{ background: '#E4E4E4' }}><b>{item}</b></td>
                                    </tr>
                                    {data[i].filter(c => c.nomeSite === item).map((item, i) => {
                                        return <tr key={i}>
                                            <td>
                                                {item.nome}{item.desatualizado === "true" && <span style={{ color: 'red' }}>*</span>}
                                                {(item.tipo === 'venda' || item.tipo === 'locacao') && <React.Fragment><br /><small>Última Atualização {config.dateFix(item.dataUltimaAtualizacao)} </small></React.Fragment>}
                                            </td>
                                            <td>{item.classificacao}</td>
                                            <td>{item.tipo}</td>
                                            <td>{item.quantidade}</td>
                                            <td>{config.currencyFix(item.valorUnitario)}</td>
                                            <td>{config.currencyFix(item.valorLPU)}</td>
                                            <td>{config.currencyFix((!item.lpu) ? item.quantidade * item.valorUnitario : item.quantidade * item.valorLPU)}</td>
                                            {(props.status === 'Rascunho' && (!props.enviadoRevisao || (props.enviadoRevisao && item.solicitadoRevisao))) &&
                                                <td>
                                                    <button className="btn btn-sm" onClick={() => handleEditarProduto(item.id, 'Edit')}><i className="fa fa-pencil-square-o" /></button> {' '}
                                                    <button className="btn btn-sm" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /></button>
                                                </td>
                                            }
                                        </tr>
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="7">
                                <div className="pull-left">
                                    <span style={{ color: 'red' }}> * Produto desatualizado </span>
                                </div>
                                <div className="pull-right">
                                    <table className="table table-hover table-striped" style={{ margin: 0 }}>
                                        <tbody>
                                            {totalProdutos > 0 && <tr><td>Total de Produtos</td><td align="right">{config.currencyFix(totalProdutos)}</td></tr>}
                                            {totalInstalacao > 0 && <tr><td>Total de Instalação</td><td align="right">{config.currencyFix(totalInstalacao)}</td></tr>}
                                            {totalManutencao > 0 && <tr><td>Total de Manutenção</td><td align="right">{config.currencyFix(totalManutencao)}</td></tr>}
                                            {totalServicos > 0 && <tr><td>Total de Serviço</td><td align="right">{config.currencyFix(totalServicos)}</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </React.Fragment>
    );
}