import React, { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from './Menu';
import noavatar from '../../assets/no-avatar.png';

export function AdminLTE3(props) {
    const { userPanel = false, footer = false, aside = false, menu = [] } = props;

    useLayoutEffect(() => {
        document.body.classList.remove(...document.body.classList);
        document.getElementById('root').className = 'wrapper';

        document.body.classList.add('sidebar-mini');
        document.body.classList.add('layout-fixed');
        document.body.classList.add('layout-navbar-fixed');
        //document.body.classList.add('sidebar-collapse');
    }, []);

    const logout = () => {
        props.logout && props.logout();
    }

    const setTheme = () => {
        console.log("setTheme")
        let theme = localStorage.getItem("theme") || "";
        console.log("setTheme " + theme);
        if (theme === 'adminlte3' || theme === "")
            localStorage.setItem("theme", 'adminlte');
        else
            localStorage.setItem("theme", 'adminlte3');

            document.location.href="/";
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" data-widget="pushmenu" to="#" role="button"><i className="fas fa-bars" /></Link>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block"><Link to="/" className="nav-link">Home</Link></li>
                    <li className="nav-item d-none d-sm-inline-block"><Link to="#" className="nav-link" onClick={setTheme}>SIGA V2</Link></li>
                </ul>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" data-widget="fullscreen" to="#" role="button"><i className="fas fa-expand-arrows-alt" /></Link>
                    </li>
                    {aside &&
                        <li class="nav-item">
                            <Link class="nav-link" data-widget="control-sidebar" data-slide="true" to="#" role="button"><i class="fas fa-th-large" /></Link>
                        </li>
                    }
                    <li className="nav-item">
                        <Link replace className="nav-link" to="#" onClick={logout}><i className="fas fa-sign-out-alt" /></Link>
                    </li>
                </ul>
            </nav>

            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <Link to="/" className="brand-link" onDoubleClick={setTheme}>
                    <div className="brand-image img-circle pt-2 pl-2" style={{ opacity: .8 }}><i className={`fas fa-globe`} /></div>
                    <span className="brand-text font-weight-light">Veolink SIGA</span>
                </Link>

                <div className="sidebar">
                    {userPanel &&
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src={noavatar} className="img-circle elevation-2" alt="Nome Usuario" />
                            </div>
                            <div className="info">
                                <Link to="/profile" className="d-block">Nome Usuario</Link>
                            </div>
                        </div>
                    }

                    <Menu data={menu} />
                </div>
            </aside>

            <div className="content-wrapper">
                {props.children}
            </div>

            {aside &&
                <aside className="control-sidebar control-sidebar-dark">
                    <div className="p-3">
                        <h5>Title</h5>
                        <p>Sidebar content</p>
                    </div>
                </aside>
            }

            {footer &&
                <footer className="main-footer">
                    <div className="float-right d-none d-sm-inline">
                        Veolink SIGA ERP
                    </div>
                    <strong>Copyright &copy; <a href="https://veolink.com.br">Veolink</a>.</strong>
                </footer>
            }
        </>
    )
}

export * from './Content';
export * from './Login';