import React, { useState, useEffect } from 'react';
import { apiHelper } from './../../index';

export function GestaoNiveisServicos(props) {
    const endPoint = 'atendimentoReport/gestaoServicos';
    const [data, setData] = useState([]);
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Gestão de Níveis de Serviço</h3>
            </div>
            <div className="panel-body">
                {data && data.length > 0 &&
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin">
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th>Ano</th>
                                            <th>Mês</th>
                                            <th>Previsto</th>
                                            <th>Realizado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.ano < b.ano ? 1 : -1)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.ano}</td>
                                                        <td>{item.mes}</td>
                                                        <td>{item.qtdPrevista}</td>
                                                        <td>{item.qtdRealizada}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}
