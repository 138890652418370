import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../';
import { CronogramaTarefa } from './CronogramaTarefa';

export function CronogramaView(props) {
    const endPoint = 'projetoCliente/cronograma';
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
    });

    const fetchData = () => {
        rowId > 0 && apiHelper.get(`${endPoint}/${rowId}`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível a requisição ' + rowId);
            });
    }

    useEffect(fetchData, []);

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleClose = () => {
        props.onHide();
    }

    return (
        <React.Fragment>
            <div className="box">
                <div className="box-header with-border">
                    <h3 className="box-title">Tarefas Cronograma {data.descricao}</h3>

                    <div className="box-tools pull-right">
                        <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                    </div>
                </div>
                <div className="box-body">
                    <CronogramaTarefa parentId={rowId} projetoId={data.projetoId} onHide={handleHide} />
                </div>
            </div>
        </React.Fragment>
    )
}
