import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, Modal } from '../index';
import { InteracaoForm } from './InteracaoForm';
import Core from '../Core';

export function Interacao(props) {
    const endPoint = "projetoCliente/interacao";
    const orcamentoId = props.parentId;
    const [data, setData] = useState([]);
    const folder = config.getFolderModuloOportunidade();
    const [params, setParam] = useState({
        showModal: false,
        cancelarAcionamento: false,
        interacao: false,
        idAcionamentoTecnico: 0,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}/${orcamentoId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [orcamentoId, props.refresh]);

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    }

    const downloadAnexo = async (fileName) => {
        await Core.api_download(folder, fileName).then(res => {
        });
    }

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Nova Interação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InteracaoForm orcamentoId={orcamentoId} onHide={handleHide} />
                </Modal.Body>
            </Modal>

            <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Data</th>
                            <th>Descrição</th>
                            <th>Usuário</th>
                            <th>Arquivo</th>
                            {/* <th></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{config.dateTimeFix(item.dataCadastro)}</td>
                                        <td>{item.texto}</td>
                                        <td>{item.usuario}</td>
                                        <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                        {/* <td style={{ width: '50px' }}>
                                                {item.criadorId ===  config.idUsuarioLogado() && <div className="btn-toolbar">
                                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.id)}> <i className="fa fa-trash" /></button>
                                                </div>}
                                        </td> */}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
