import React, { Component } from 'react';
import { ButtonToolbar, Button, Table } from 'react-bootstrap';
import {
    uuidv4, FasIcon, Box, Row, Col,
    FormGroupButton, Tabs, TabContent
} from '../../adminlte/AdminLTE';

import Fields from './Fields';
import FormField from '../FormField';
import CrudForm from './CrudForm';
import InternalMember from './InternalMember';
import ModalMember from './ModalMember';

class Crud extends Component {
    constructor(props) {
        super(props);

        this.state = {
            api: props.api,
            keyName: props.keyName,
            rowName: props.rowName,
            tableData: [],
            selectedRow: {},
            searchValue: '',
            action: 'view',
            selectedTab: 0,
            loaded: false
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleSearchRefresh = this.handleSearchRefresh.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getDataTable();
    }

    getDataTable(filter) {
        if (filter === undefined) filter = '';
        const { endPoint, headers } = this.state.api;

        fetch(endPoint + '?filter=' + filter, { headers: headers })
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    let row = (filter === '' || data.length === 1 ? data[0] : this.state.selectedRow);
                    this.selectRow(row);
                    this.setState({
                        tableData: data
                    });
                }
            });
    }

    selectRow(row) {
        this.setState({
            selectedRow: row,
            action: 'view',
            selectedTab: 0
        })
    }

    handleSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ searchValue: value });

        if (value.length > 2 || value === '')
            this.getDataTable(value);
    }

    handleSearchRefresh() {
        this.setState({ searchValue: '' });
        this.getDataTable();
    }

    handleNew() {
        this.setState({
            action: 'new'
        })
    }

    handleDelete() {
        if (!window.confirm("Deseja realmente excluir este registro?"))
            return;

        const { api } = this.props;
        const { selectedRow, keyName } = this.state;

        fetch(api.endPoint + selectedRow[keyName], { method: 'DELETE', headers: api.headers })
            .then(response => { return Promise.all([response.status, response.json()]); })
            .then((response) => {
                if (response[0] === 200) {
                    alert("Dados excluidos com sucesso!");
                    this.getDataTable();
                }
                else
                    alert(response[1]["msg"]);
            })
            .catch(error => { alert(error); });
    }

    clearForm(field) {
        this.setState(prevState => {
            return { formData: { ...prevState.formData, [field]: '' } };
        });
    }

    onHide() {
        this.setState({ action: 'view' });
        this.getDataTable();
    }

    handleEdit() {
        this.setState({ action: 'edit', selectedTab: 0 })
    }

    render() {
        const { tableData, selectedRow, keyName, rowName, selectedTab, api } = this.state;
        const { children } = this.props

        return (
            <React.Fragment>
                <Row>
                    <Col md={4}>
                        <Box type="success">
                            <FormGroupButton size="small" placeholder="Filtro"
                                onChange={this.handleSearch}
                                value={this.state.searchValue}
                                buttonLeft={<Button bsStyle="success" onClick={this.handleNew}>NOVO</Button>}
                                buttonRight={<Button onClick={this.handleSearchRefresh}><FasIcon name='undo' /></Button>}
                            />
                            <Table striped bordered hover>
                                <tbody>
                                    {tableData.map(row => {
                                        return (
                                            <tr key={row[keyName]} onClick={() => this.selectRow(row)} style={{ cursor: "pointer" }}>
                                                <td>{row.name}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Box>
                    </Col>
                    <Col md={8}>
                        {selectedRow !== undefined &&
                            <Box type="primary" title={selectedRow[rowName]} headerContent={
                                <ButtonToolbar>
                                    <Button bsSize="small" bsStyle="primary" onClick={() => this.handleEdit()}><FasIcon name="edit" /></Button>
                                    <Button bsSize="small" bsStyle="danger" onClick={this.handleDelete}><FasIcon name="trash" /></Button>
                                </ButtonToolbar>}
                            >
                                <Tabs id={uuidv4()} defaultActiveKey={0} activeKey={selectedTab}>
                                    {React.Children.map(children, (item, i) => {
                                        let label = (item.props.label === undefined || item.props.label === '' ? '{label}' : item.props.label)
                                        return (
                                            <TabContent key={i} title={label} eventKey={i}>
                                                {item.type === Fields &&
                                                    <CrudForm config={this.state} onHide={this.onHide}>
                                                        {item.props.children}
                                                    </CrudForm>
                                                }
                                                {item.type !== Fields &&
                                                    <Row>
                                                        <Col md={12}>
                                                            {React.cloneElement(item, {
                                                                key: uuidv4(),
                                                                parentkey: selectedRow[keyName],
                                                                parentapi: api,
                                                                membercommand: { [keyName]: selectedRow[keyName] }
                                                            })}
                                                        </Col>
                                                    </Row>
                                                }
                                            </TabContent>
                                        )
                                    })}
                                </Tabs>
                            </Box>
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Crud;
export { Crud as Core, Fields, FormField, InternalMember, ModalMember };