import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, fileHelper, Modal, toast } from '../index';
import { NovaInteracao } from './NovaInteracao';

export function Interacao(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false,
        interacao: false,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        try {
            apiHelper.get(`${endPoint}/acaocomercial/${rowId}`)
                .then(res => {
                    
                    setData(res.data.data);
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    };

    useEffect(fetchData, [rowId]);

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    }

    const downloadAnexo = (fileName) => {
        fileHelper.download('Proposta', fileName).then(res => { });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onHide();
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Nova Interação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NovaInteracao parentId={rowId} onHide={handleHide} />
                </Modal.Body>
            </Modal>
            <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Data</th>
                            <th>Nome</th>
                            <th>Titulo</th>
                            <th>Tipo</th>
                            <th>Agenda Início</th>
                            <th>Agenda Fim</th>
                            <th>Descrição</th>
                            <th>Arquivo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.data < b.data ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{config.dateTimeFix(item.data)}</td>
                                        <td>{item.usuario.nome}</td>
                                        <td>{item.titulo}</td>
                                        <td>{item.tipo}</td>
                                        <td>{config.dateTimeFix(item.dataInicioAgenda)}</td>
                                        <td>{config.dateTimeFix(item.dataFimAgenda)}</td>
                                        <td>{item.descricao}</td>
                                        <td>{item.arquivo ? <Link to="#" onClick={() => downloadAnexo(item.arquivo)}>{item.arquivo}</Link> : "-"}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>
                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
        </React.Fragment>
    );
}
