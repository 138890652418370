import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AuthHelper from '../auth/AuthHelper';
import { history } from '../../_helpers/history';

export class Content extends Component {
    componentDidMount() {
        let Auth = new AuthHelper();

        if (!Auth.loggedIn()) {
            localStorage.setItem('last_url', window.location.hash);
            Auth.logout();
            history.push('/#/login');
        }

        const { browserTitle } = this.props;
        if (browserTitle) { document.title = browserTitle; }
        this.setTheme();

    }
    setTheme = () => {
        console.log(window.location.hash);
        if (window.location.hash.includes("report")) {
            localStorage.setItem("theme", 'adminlte3');
            window.location.reload();
        }
    }
    render() {
        const { children, title, optionalTitle } = this.props;

        return (
            <React.Fragment>
                {title &&
                    <section className="content-header">
                        <h1>
                            {title}
                            {optionalTitle !== null && <small>{optionalTitle}</small>}
                        </h1>
                        <ol className="breadcrumb">
                            <li><Link to="/"><i className="fa fa-dashboard" /> Home</Link></li>
                            <li className="active">{title}</li>
                        </ol>
                    </section>
                }
                <section className="content container-fluid">
                    {children}
                </section>
            </React.Fragment>
        );
    }
}
