import React, { Component } from 'react';
import AuthHelper from './AuthHelper';

export default function withAuth(AuthComponent) {
    const Auth = new AuthHelper();

    return class AuthWrapped extends Component {
        state = {
            confirm: null,
            loaded: false
        }

        UNSAFE_componentWillMount() {
            if (!Auth.loggedIn()) {
                localStorage.setItem('last_url', this.props.location.pathname);
                this.props.history.replace('/login');
            }
            else {
                let last_url = localStorage.getItem('last_url');
                if (last_url) {
                    localStorage.removeItem('last_url');
                    this.props.history.replace(last_url);
                }

                try {
                    const confirm = Auth.getConfirm();

                    this.setState({
                        confirm: confirm,
                        loaded: true
                    });
                }
                catch (err) {
                    Auth.logout();
                    this.props.history.replace('/login');
                }
            }
        }

        render() {
            if (this.state.loaded === true) {
                if (this.state.confirm) {
                    return <AuthComponent history={this.props.history} confirm={this.state.confirm} />;
                }
                else {
                    console.log("not confirmed!");
                    return null;
                }
            }
            else {
                return null;
            }
        }
    };
}