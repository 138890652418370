import React, { useEffect, useState } from 'react';
import { config, apiHelper, toast, Select, Modal } from '../index';
import * as _ from 'lodash';

let distinctSites = [];
let itensSelecionados = [];

export function Escopo(props) {
    const endPointOrcamentoSite = 'portal/crud/uvw_v2_comercial_orcamento_site';
    const endPointProjeto = 'v2/projeto/';
    const endPointOrcamentoItem = 'portal/crud/ComercialOrcamentoItem';
    const projetoId = props.projetoId;
    const orcamentoId = props.orcamentoId;
    const clienteId = props.clienteId;

    const [data, setData] = useState([]);
    const [escopo, setEscopo] = useState([]);
    const [dadosAdicionais, setDadosAdicionais] = useState({});

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        itemClassificacao: ""
    });

    const fetchData = () => {
        if (orcamentoId) {
            apiHelper.get(endPointProjeto + projetoId + '/aprovacaoOrcamento').then(res => {
                setData(res.data.data);
                obterSites(res.data.data.escopo.data);
            });
        }
    };

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, [props.orcamentoId, props.refresh]);

    const obterSites = (dataOrcamentoItem) => {
        apiHelper.get(`${endPointOrcamentoSite}?filter=IdCliente::equal::${clienteId};;AND;;OrcamentoId::equal::${orcamentoId};;`)
            .then(res => {
                let sites = res.data.data;

                dataOrcamentoItem.map((item, i) => {
                    let site = sites.find(c => c.id === item.siteId);
                    dataOrcamentoItem[i].nomeSite = site && site.nome;
                    return null;
                });

                let nomesSites = dataOrcamentoItem.map((item, i) => { return item.nomeSite });
                distinctSites = nomesSites.filter((v, i, a) => a.indexOf(v) === i);

                let dataSitesAgrupados = _.groupBy(dataOrcamentoItem, 'nomeSite');
                let dados = [];

                distinctSites.map(item => {
                    dados.push(dataSitesAgrupados[item]);
                    return null;
                });

                props.handleLoadDados(distinctSites);
                setEscopo(dados);
            });
    };

    const handleHide = () => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            itemClassificacao: ""
        });

        setDadosAdicionais({
            ...dadosAdicionais,
            necessitaIndustrializacao: false,
            detalhamentoTecnico: '',
            valorUnitarioReal: ''
        });

        itensSelecionados = [];
        fetchData();
        props.onUpdate();
    }

    const handleSolicitarRevisao = () => {
        if (itensSelecionados.length === 0) {
            toast.warn("Selecione pelo menos um item!");
            return;
        }

        apiHelper.put(endPointProjeto + projetoId + '/revisao', itensSelecionados).then(res => {
            config.httpResult(res.data);
            handleHide();
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const handleConcluirEdicao = () => {
        let isProcessedFromSupplies = { isProcessedFromSupplies: !data.isProcessedFromSupplies };
        let mes = data.isProcessedFromSupplies ? "Deseja reabilitar a edição dos custos dos materiais desse projeto?" : "Deseja finalizar a edição dos custos dos materiais desse projeto?";
        let texto = data.isProcessedFromSupplies ? "Edição de custos de materiais reaberta." : "Edição de custos de materiais finalizada";
        let interacao = {
            criadorId: '[user_id]',
            visivelCliente: 0,
            projetoId: projetoId,
            file: "",
            anexo: "",
            texto: texto
        };
        if (window.confirm(mes)) {
            apiHelper.put(`portal/crud/Projeto/${projetoId}/Id`, isProcessedFromSupplies)
                .then(res => {
                    apiHelper.post(`v2/projeto/interacao`, interacao).then(resp => {
                        config.httpResult(res.data);
                        handleHide();
                        fetchData();
                    }).catch(error => {
                        toast.error('Atenção, não foi possível salvar o interação.');
                    })
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleChecked = (e, item) => {
        if (e.target.checked)
            itensSelecionados.push(item);
        else
            itensSelecionados = itensSelecionados.filter(c => c.id !== item.id);
    }

    const handleUpdateRow = (e) => {
        setParam({
            ...params,
            rowId: e.id,
            showModal: true,
            modalTitle: "Atualizar Dados",
            formName: "",
            itemClassificacao: e.classificacao
        });

        setDadosAdicionais({
            ...dadosAdicionais,
            necessitaIndustrializacao: e.necessitaIndustrializacao,
            detalhamentoTecnico: e.detalhamentoTecnico,
            valorUnitarioReal: e.valorUnitarioReal
        });
    };

    const handleSubmit = () => {
        if (!dadosAdicionais.valorUnitarioReal)
            delete dadosAdicionais.valorUnitarioReal

        apiHelper.put(`${endPointOrcamentoItem}/${params.rowId}/Id`, dadosAdicionais)
            .then(res => {
                config.httpResult(res.data);
                handleHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    };

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setDadosAdicionais({ ...dadosAdicionais, [name]: value });
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        {config.validarClaim('projeto.escopo.editar.descricao') && <React.Fragment>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Montagem</label>
                                    <Select id="necessitaIndustrializacao" className="form-control" onChange={handleChange} value={dadosAdicionais.necessitaIndustrializacao}>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </Select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Detalhamento Técnico</label>
                                    <textarea id="detalhamentoTecnico" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={dadosAdicionais.detalhamentoTecnico} />
                                </div>
                            </div>
                        </React.Fragment>}
                        {(params.itemClassificacao === "Software" || params.itemClassificacao === "Hardware") && (config.validarClaim('projeto.escopo.editar')) &&
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Custo Real Unitário</label>
                                    <input type="number" step=".01" id="valorUnitarioReal" className="form-control" onChange={handleChange} value={dadosAdicionais.valorUnitarioReal} disabled={data.isProcessedFromSupplies} />
                                </div>
                            </div>}
                        <div className="row">
                            <div className="col-md-12">
                                <button type="button" className="btn btn-default pull-left" onClick={() => handleHide()}>Fechar</button>
                                <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin">
                    <thead>
                        <tr className="bg-light-blue">
                            <th style={{ width: "50px" }}>#</th>
                            <th>Descrição</th>
                            <th>Classificação</th>
                            <th>Tipo</th>
                            <th>Qtd</th>
                            <th>Custo</th>
                            <th>Custo LPU</th>
                            <th>Total</th>
                            <th>Custo Real</th>
                            <th>Custo Real Total</th>
                            <th>Requisição</th>
                            <th>Status Requisição</th>
                            <th>Revisão</th>
                            {props.canEdit &&
                                <React.Fragment>
                                    <th>Detalhamento Técnico</th>
                                    <th>Montagem</th>
                                    <th></th>
                                </React.Fragment>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {distinctSites.length > 0 && distinctSites.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td colSpan="16" style={{ background: '#E4E4E4' }}><b>{item}</b></td>
                                    </tr>
                                    {escopo.length > 0 && escopo[i].filter(c => c.nomeSite === item).map((item, j) => {
                                        return <tr key={j}>
                                            {item.solicitadoRevisao === false && item.requisicaoId === null ?
                                                <td><input id={'chk' + j} type="checkbox" onClick={(e) => handleChecked(e, item)} /></td>
                                                :
                                                <td></td>
                                            }
                                            <td>{item.nome}</td>
                                            <td>{item.classificacao}</td>
                                            <td>{item.tipo}</td>
                                            <td>{item.quantidade}</td>
                                            <td>{config.currencyFix(item.valorUnitario)}</td>
                                            <td>{config.currencyFix(item.valorLPU)}</td>
                                            <td>{config.currencyFix((!item.lpu) ? item.quantidade * item.valorUnitario : item.quantidade * item.valorLPU)}</td>
                                            <td>{config.currencyFix(item.valorUnitarioReal)}</td>
                                            <td>{config.currencyFix(item.quantidade * item.valorUnitarioReal)}</td>
                                            <td>{item.requisicaoId > 0 ? item.requisicaoId : ""}</td>
                                            <td>{item.statusRequisicao}</td>
                                            <td>{item.solicitadoRevisao ? 'Sim' : 'Não'}</td>
                                            {props.canEdit &&
                                                <React.Fragment>
                                                    <td>{item.detalhamentoTecnico}</td>
                                                    <td>{item.necessitaIndustrializacao ? 'Sim' : 'Não'}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleUpdateRow(item)}><i className="fa fa-pencil" /></button>
                                                    </td>
                                                </React.Fragment>}
                                        </tr>
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="14">
                                {props.canEdit && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleSolicitarRevisao} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Solicitar Revisão</button>}
                                {((props.canEdit && !data.isProcessedFromSupplies && config.validarClaim('projeto.escopo.editar')) || (data.isProcessedFromSupplies && config.validarClaim('projeto.escopo.reabrir'))) && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleConcluirEdicao}><i className="fa fa-plus" />{data.isProcessedFromSupplies ? " Abrir Escopo" : " Concluir Escopo"}</button>}
                                <div className="pull-right">
                                    <table className="table table-hover table-striped" style={{ margin: 0 }}>
                                        <tbody>
                                            {data.escopo && data.escopo.totalHardware > 0 && <tr><td>Total Hardware</td><td align="right">{config.currencyFix(data.escopo.totalHardware)}</td></tr>}
                                            {data.escopo && data.escopo.totalSoftware > 0 && <tr><td>Total Software</td><td align="right">{config.currencyFix(data.escopo.totalSoftware)}</td></tr>}
                                            {data.escopo && data.escopo.totalInstalacao > 0 && <tr><td>Total de Instalação</td><td align="right">{config.currencyFix(data.escopo.totalInstalacao)}</td></tr>}
                                            {data.escopo && data.escopo.totalManutencao > 0 && <tr><td>Total de Manutenção</td><td align="right">{config.currencyFix(data.escopo.totalManutencao)}</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </React.Fragment>
    );
}
