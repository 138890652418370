import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';
import moment from 'moment-timezone';

export function FaturamentoForm(props) {
    const endPoint = 'v2/projeto/faturamento';
    const rowId = props.rowId;
    const [data, setData] = useState({});
    const [tipo, setTipo] = useState('');
    const [anexoEmail, setAnexoEmail] = useState({
        folder: config.getFolderModuloProjeto(),
        fileName: '',
        file: {}
    });
    const [anexoNF, setAnexoNF] = useState({
        folder: config.getFolderModuloProjeto(),
        fileName: '',
        file: {}
    });

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/${rowId}`)
                .then(res => {
                    let data = res.data.data;
                    if (data.dataFaturamento !== null)
                        data.dataFaturamento = `${data.dataFaturamento.split('-')[0]}-${data.dataFaturamento.split('-')[1]}-${data.dataFaturamento.split('-')[2].substr(0, 2)}`;
                    if (data.dataProvisionamento !== null)
                        data.dataProvisionamento = `${data.dataProvisionamento.split('-')[0]}-${data.dataProvisionamento.split('-')[1]}-${data.dataProvisionamento.split('-')[2].substr(0, 2)}`;
                    Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                    setData(obj);
                    setTipo(obj.tipo);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        if (name === 'tipo') {
            setTipo(value);
        }
        if (name === "anexoEmail" || name === "anexoNF") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    if (name === "anexoEmail")
                        setAnexoEmail({ ...anexoEmail, folder: '', file: '' });
                    else if (name === "anexoNF")
                        setAnexoNF({ ...anexoNF, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                if (name === "anexoEmail")
                    setAnexoEmail({ ...anexoEmail, folder: anexoEmail.folder, fileName: fileName, file: file });
                else if (name === "anexoNF")
                    setAnexoNF({ ...anexoNF, folder: anexoNF.folder, fileName: fileName, file: file });

                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (tipo === 'Provisionamento') {
            let partes = data.dataProvisionamento.split('-');
            let date = new Date(parseInt(partes[0]), parseInt(partes[1]) - 1, parseInt(partes[2]));
            if (date < new Date()) {
                window.alert('Data de provosionamento inválida! (Deve ser superior a hoje.)');
                return;
            }
        }

        if (anexoNF.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoNF.file, anexoNF.folder, anexoNF.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        if (anexoEmail.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoEmail.file, anexoEmail.folder, anexoEmail.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        data.criadorId = '[user_id]';
        data.projetoId = props.projetoId;

        if (!rowId || rowId === '') {
            apiHelper.post(endPoint, data)
                .then(res => {
                    if (res.data.code === 200) {
                        toast.success('Dados salvos com sucesso!');
                        props.onHide();
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar a interação.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar a interação.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}`, data).then(res => {
                config.httpResult(res.data);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                    <li className="active"><a href="#tab_faturar" data-toggle="tab">Faturar</a></li>
                    {tipo !== 'Provisionamento' && <li><a href="#tab_nf" data-toggle="tab">Nota Fiscal</a></li>}
                </ul>
                <div className="tab-content">
                    <div id="tab_faturar" className="tab-pane active">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label>{tipo === 'Provisionamento' ? 'Valor a Provisionar' : 'Valor a Faturar'}</label>
                                <input id="valorFaturado" type="number" className="form-control" onChange={handleChange} value={data.valorFaturado} required />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Anexo Email</label>
                                <input type="file" id="anexoEmail" onChange={handleChange} />
                            </div>
                            <div className="form-group col-md-12">
                                <label>Tipo</label>
                                <Select id="tipo" className="form-control" onChange={handleChange} value={data.tipo || ''} required>
                                    <option value="">Selecione</option>
                                    <option endpoint={`portal/crud/PortalMetadata?filter=Categoria::equal::TipoFaturamento`} valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                            {tipo === 'Provisionamento' && <>
                                <div className="form-group col-md-12">
                                    <label>Tipo de Provisionamento</label>
                                    <Select id="tipoProvisionamento" className="form-control" onChange={handleChange} value={data.tipoProvisionamento || ''} required>
                                        <option value="">Selecione</option>
                                        <option endpoint={`portal/crud/PortalMetadata?filter=Categoria::equal::TipoFaturamento;;and;;nome::notequal::Provisionamento`} valuefield="nome" textfield="nome" />
                                    </Select>
                                </div>
                                <div className="form-group col-sm-12">
                                    <label>Provisionado Para</label>
                                    <input id="dataProvisionamento" type="date" className="form-control" onChange={handleChange} value={data.dataProvisionamento} />
                                </div>
                            </>}
                            <div className="form-group col-sm-12">
                                <label>Descrição</label>
                                <textarea id="descricao" className="form-control" maxLength="2000" onChange={handleChange} value={data.descricao} />
                            </div>
                            <div className="form-group col-sm-12">
                                <label>Observação</label>
                                <textarea id="observacao" className="form-control" maxLength="500" onChange={handleChange} value={data.observacao} />
                            </div>
                        </div>
                    </div>
                    {tipo !== 'Provisionamento' && <div id="tab_nf" className="tab-pane">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label>Número NF</label>
                                <input id="numeroNF" className="form-control" onChange={handleChange} value={data.numeroNF} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Data Faturamento</label>
                                <input id="dataFaturamento" type="date" className="form-control" onChange={handleChange} value={data.dataFaturamento} />
                            </div>
                            <div className="form-group col-sm-12">
                                <label>Anexo NF</label>
                                <input type="file" id="anexoNF" onChange={handleChange} />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    );
}

