import React, { useState, useEffect } from 'react';
import { apiHelper, config, toast, Link } from '../';
import { RegionalUsuarioForm } from './RegionalUsuarioForm';

export function RegionalUsuario(props) {
    const endPoint = 'v2/regional/usuario';
    const regionalId = props.parentId;

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        nome: ''
    });

    const [params, setParams] = useState({
        rowId: 0,
        edit: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        where = `fkRegional::equal::${regionalId};;AND;;isExcluido::equal::false;;AND;;${where}`;

        apiHelper.get(`${endPoint}?filter=${where}&order=nome DESC`)
            .then(res => {
                setData(res.data.data);
            });
    }

    const onLoad = () => {
        fetchData('');
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
        let where = '';

        if (name === "pkRegionalSubordinado" && value) {
            where = 'pkRegionalSubordinado::equal::' + value;
        } else {
            Object.entries(filter).map(([key, value2]) => {
                let operador = 'contains';

                if (key !== name) {
                    if (value2 && value2 !== '') {
                        if (where !== '') {
                            where += ';;AND;;';
                        }

                        where += `${key}::${operador}::${value2}`;
                    }
                } else if (value && value !== '') {
                    if (where !== '') {
                        where += ';;AND;;';
                    }

                    where += `${name}::${operador}::${value}`;
                }

                return null;
            });
        }

        fetchData(where);
    }

    const handleNew = () => {
        setParams({ ...params, edit: true });
    }

    const handleEdit = (id) => {
        setParams({ ...params, rowId: id, edit: true });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res);
            fetchData('');
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const onImport = () => {
        setParams({ ...params, rowId: 0, edit: false });
        fetchData();
    }

    const onHide = () => {
        setParams({
            ...params,
            rowId: 0,
            edit: false,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <div style={{ marginBottom: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Adiconar Usuário</button>
                        <div className="pull-right">{renderPage()}</div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-striped no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th style={{ width: "20px" }}>#</th>
                                    <th>Nome</th>
                                    <th>Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fetchData()}><i className="fa fa-refresh" /></button></td>
                                    <td><input type="text" className="form-control" id="nome" onChange={handleChange} /></td>
                                </tr>
                                {data && data.sort((a, b) => a.pkOS < b.pkOS ? 1 : -1)
                                    .slice(params.initRow, params.endRow)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '150px' }}>
                                                    <div className="btn-group dropup">
                                                        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v" />
                                                        </button>
                                                        <ul className="dropdown-menu" role="menu">
                                                            <li><Link replace to="#" onClick={() => handleEdit(item.pkRegionalSubordinado)}><i className="fa fa-edit" /> Editar</Link></li>
                                                            <li><Link replace to="#" onClick={() => handleDelete(item.pkRegionalSubordinado)}><i className="fa fa-trash" /> Excluir</Link></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td>{item.nome}</td>
                                                <td>{item.perfil}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginTop: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fetchData()}><i className="fa fa-refresh" /> Atualizar</button>

                        <div className="pull-right">
                            {renderPage()}
                        </div>
                    </div>
                </div>
            </div>
            {params.edit && <RegionalUsuarioForm parentId={regionalId} rowId={params.rowId} show={params.edit} onImport={onImport} onHide={onHide} />}
        </div>
    )
}
