import React, { useState } from 'react';
import { Content, Link, config, apiHelper, fileHelper, Select, toast } from '../';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function ComunicacaoVenda(props) {
    const endPointComunicadoVenda = "siga/relatorio/comercial/comunicacaoVenda/";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        Status: null,
        Cliente: null,
        Site: null,
        ResponsavelVenda: null,
        DataInicioPeriodo: null,
        DataFimPeriodo: null,
        DataInicioAberturaOS: null,
        DataFimAberturaOS: null,
        FormaVenda: null,
        TipoServico: null,
        IsParaTeste: null,
        Versao: 0,
        TipoEmpresa: null,
    });
    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowLoading(true);
        apiHelper.get(endPointComunicadoVenda + "?param=" + JSON.stringify(filter))
            .then(res => {
                setData(res.data.data);
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            Status: 0,
            Cliente: 0,
            Site: 0,
            ResponsavelVenda: 0,
            DataInicioPeriodo: '',
            DataFimPeriodo: '',
            DataInicioAberturaOS: '',
            DataFimAberturaOS: '',
            FormaVenda: 0,
            Servico: 0,
            IsParaTeste: false,
            Versao: '',
            TipoEmpresa: 0
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const handleOrdenarColunas = (e, nomeVariavelColuna) => {
        e.preventDefault();
        let sortedObjs = config.ordenarColunas(data, nomeVariavelColuna);
        setData(sortedObjs);
    }

    return (
        <Content title="Relatório Comunicação Venda" browserTitle="Relatório Comunicação Venda">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label>Responsável Venda</label>
                                <Select id="ResponsavelVenda" className="form-control" onChange={handleChange} value={filter.ResponsavelVenda}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="Cliente" className="form-control" onChange={handleChange} value={filter.Cliente}>
                                    <option value="">Todos</option>
                                    <option endpoint="siga/serviceOrder/client" valuefield="clientCode" textfield="name" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Site</label>
                                <Select id="Site" className="form-control" onChange={handleChange} value={filter.Site} refresh={filter.Cliente}>
                                    <option value="">Todos</option>
                                    <option endpoint={filter.Cliente !== null ? `siga/serviceOrder/${filter.Cliente}/site` : ''} valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Tipo Empresa</label>
                                <Select id="TipoEmpresa" className="form-control" onChange={handleChange} value={filter.TipoEmpresa}>
                                    <option value="9">Todos</option>
                                    <option value="0">Publica</option>
                                    <option value="1">Privada</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Forma Venda</label>
                                <Select id="FormaVenda" className="form-control" onChange={handleChange} value={filter.FormaVenda}>
                                    <option value="9">Todos</option>
                                    <option value="0">Locação</option>
                                    <option value="1">Venda</option>
                                    <option value="2">Outra</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Serviço</label>
                                <Select id="TipoServico" className="form-control" onChange={handleChange} value={filter.TipoServico}>
                                    <option value="9">Todos</option>
                                    <option value="0">Instalação</option>
                                    <option value="1">Manutenção</option>
                                    <option value="2">Outros Serviços</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <Select id="Status" className="form-control" onChange={handleChange} value={filter.Status}>
                                    <option value="0">Todos</option>
                                    <option value="5">Cancelada</option>
                                    <option value="3">Concluida</option>
                                    <option value="8">Concluida Parcialmente</option>
                                    <option value="2">Em Andamento</option>
                                    <option value="6">Em Teste</option>
                                    <option value="9">Nova</option>
                                    <option value="7">Pendente Aprovação</option>
                                    <option value="4">Suspensa</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>CV para Teste</label>
                                <Select id="IsParaTeste" className="form-control" onChange={handleChange} value={filter.IsParaTeste}>
                                    <option value="2">Todos</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Versão</label>
                                <Select id="Versao" className="form-control" onChange={handleChange} value={filter.Versao}>
                                    <option value="2">Todas</option>
                                    <option value="1">Primeira</option>
                                    <option value="0">Ultima</option>
                                </Select>
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"DataInicioPeriodo"} col={12} showHora={false} labelData={"Período de"} valueData={filter.DataInicioPeriodo} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"DataFimPeriodo"} col={12} showHora={false} labelData={"Período Até"} valueData={filter.DataFimPeriodo} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"DataInicioAberturaOS"} col={12} showHora={false} labelData={"Data Abertura"} valueData={filter.DataInicioAberturaOS} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"DataFimAberturaOS"} col={12} showHora={false} labelData={"Data Abertura Até"} valueData={filter.DataFimAberturaOS} onSelectValue={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Relatório Comunicação Venda</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => fileHelper.exportToCSV(data, 'RelatorioComunicadoVenda_' + moment().format("DD/MM/YYYY"))}><i class="fa fa-file-excel-o"></i></button>
                                </div>
                            </div>
                            <div className="box-body table-responsive no-padding">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'numeroComunicacaoVenda')}><i className="fa fa-sort" />Número Referência</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'nomeResponsavelVenda')}><i className="fa fa-sort" />Responsável Venda</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'nomeCliente')}><i className="fa fa-sort" />Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'sites')}><i className="fa fa-sort" />Site</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorVendaLocacao')}><i className="fa fa-sort" />Venda Locação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorVendaManutencao')}><i className="fa fa-sort" />Venda Manutenção</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorVendaInstalacao')}><i className="fa fa-sort" />Venda Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorVenda')}><i className="fa fa-sort" />Venda</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorVendaOutrosServicos')}><i className="fa fa-sort" />Venda Outros Serviços</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorCustoMaterial')}><i className="fa fa-sort" />Custo Material</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorCustoManutencao')}><i className="fa fa-sort" />Custo Manutenção</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorCustoInstalacao')}><i className="fa fa-sort" />Custo Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorCustoOutrosServicos')}><i className="fa fa-sort" />Custo Outros Serviços</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'cameras')}><i className="fa fa-sort" />Câmeras</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'modulos')}><i className="fa fa-sort" />Módulos</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'sensor')}><i className="fa fa-sort" />Sensores</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'centralmonitoramento')}><i className="fa fa-sort" />Central Monit.</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'outros')}><i className="fa fa-sort" />Outros</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'status')}><i className="fa fa-sort" />Status</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataAbertura')}><i className="fa fa-sort" />Data Abertura</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'versao')}><i className="fa fa-sort" />Versão</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'tipoEmpresa')}><i className="fa fa-sort" />Tipo Empresa</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'data')}><i className="fa fa-sort" />Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><Link to="#" onClick={(e) => { window.open('http://siga.veotex.com.br/Forms/CvComunicacaoVendaOrcamento_Form.aspx?ID=' + item.pkComunicacaoVenda); }}>{item.numeroComunicacaoVenda}</Link></td>
                                                        <td>{item.nomeResponsavelVenda}</td>
                                                        <td>{item.nomeCliente}</td>
                                                        <td>{item.sites}</td>
                                                        <td>{config.currencyFix(item.valorVendaLocacao)}</td>
                                                        <td>{config.currencyFix(item.valorVendaManutencao)}</td>
                                                        <td>{config.currencyFix(item.valorVendaInstalacao)}</td>
                                                        <td>{config.currencyFix(item.valorVenda)}</td>
                                                        <td>{config.currencyFix(item.valorVendaOutrosServicos)}</td>
                                                        <td>{config.currencyFix(item.valorCustoMaterial)}</td>
                                                        <td>{config.currencyFix(item.valorCustoManutencao)}</td>
                                                        <td>{config.currencyFix(item.valorCustoInstalacao)}</td>
                                                        <td>{config.currencyFix(item.valorCustoOutrosServicos)}</td>
                                                        <td>{item.cameras}</td>
                                                        <td>{item.modulos}</td>
                                                        <td>{item.sensor}</td>
                                                        <td>{item.centralmonitoramento}</td>
                                                        <td>{item.outros}</td>
                                                        <td>{item.status}</td>
                                                        <td>{config.dateFix(item.dataAbertura)}</td>
                                                        <td>{item.versao}</td>
                                                        <td>{item.tipoEmpresa}</td>
                                                        <td>{config.dateTimeFix(item.data)}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
