import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Select, Modal, toast } from '../index';
import { FuncionarioForm } from './FuncionarioForm';
import Core from '../Core';

export function Funcionarios(props) {
    const endPoint = 'portal/crud/RHFuncionario';
    const endPointFuncionario = 'funcionario';
    const folderFoto = config.getFolderModuloRHFotos();
    const folderCurriculo = config.getFolderModuloRHCurriculos();
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        pkFuncionario: '',
        nome: '',
        isAtivo: '',
        dataCadastro: ''
    });

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "isExcluido::equal::0;;AND;;isAtivo::equal::1";
        else
            where = where + ';;AND;;isExcluido::equal::0';

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData('');
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nome')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }

            return null;
        });
        fetchData(where);
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };
    const handleDesativar = (item) => {
        if (!window.confirm(`Deseja realmente desativar o funcionário ${item.nome}?`))
            return;

        let funcionario = {
            isAtivo: false
        }
        apiHelper.patch(`${endPointFuncionario}/${item.pkFuncionario}/status`, funcionario)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData('');
    };

    const handleRefresh = () => {
        setFilter({
            pkFuncionario: '',
            nome: '',
            isAtivo: '',
            dataCadastro: ''
        });

        setData([]);
        fetchData('');
    };

    const downloadAnexoFoto = async (fileName) => {
        await Core.api_download(folderFoto, fileName).then(res => {
        });
    }

    const downloadAnexoCurriculo = async (fileName) => {
        await Core.api_download(folderCurriculo, fileName).then(res => {
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Funcionários" browserTitle="Funcionários">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("funcionario", "Novo Funcionário", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Funcionário</button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>Id</label>
                                                                <input id="pkFuncionario" type="text" className="form-control input-sm" onChange={handleChange} value={filter.pkFuncionario} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Nome</label>
                                                                <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} defaultValue="teste" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Status</label>
                                                                <Select id="isAtivo" className="form-control" onChange={handleChange} value={filter.isAtivo}>
                                                                    <option value="">Todos</option>
                                                                    <option value="1">Ativo</option>
                                                                    <option value="0">Inativo</option>
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Data Cadastro</label>
                                                                <input id="dataCadastro" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastro} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-footer" style={{ padding: 5 }}>
                                                    <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                    <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th style={{ width: "50px" }}>#</th>
                                            <th style={{ width: "120px" }}>ID</th>
                                            <th>Funcionário</th>
                                            <th>Status</th>
                                            <th>Data Cadastro</th>
                                            <th>Anexo Foto</th>
                                            <th>Anexo Curriculo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.pkFuncionario < b.pkFuncionario ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </button>
                                                                <ul className="dropdown-menu" role="menu">
                                                                    <li><Link replace to="#" onClick={() => handleShowForm("funcionario", "Editar Funcionário", item.pkFuncionario)}><i className="fa fa-pencil" /> Editar Funcionário</Link></li>
                                                                    <li><Link replace to="#" onClick={() => handleDesativar(item)}><i className="fa fa-trash" />Desativar</Link></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><Link to={'/rh/funcionarios/' + item.pkFuncionario}>{item.pkFuncionario}</Link></td>{/*<td><Link to={'/rh/funcionarios/' + item.pkFuncionario}>{item.pkFuncionario}</Link></td>*/}
                                                        <td>{item.nome}</td>
                                                        <td>{item.isAtivo === true ? 'Ativo' : item.isAtivo === false ? 'Inativo' : ''}</td>
                                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                                        <td>{item.caminhoFoto ? <Link to="#" onClick={() => downloadAnexoFoto(item.caminhoFoto)}>{item.caminhoFoto}</Link> : "-"}</td>
                                                        <td>{item.caminhoCurriculo ? <Link to="#" onClick={() => downloadAnexoCurriculo(item.caminhoCurriculo)}>{item.caminhoCurriculo}</Link> : "-"}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal bsSize='large' show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'funcionario' && <FuncionarioForm parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
