import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, Modal, toast } from '../index';
import { CronogramaForm } from './CronogramaForm';
import { CronogramaView } from './CronogramaView';
import Core from '../Core';

export function Cronograma(props) {
    const endPoint = "v2/projeto/cronograma";
    const endPointTarefa = 'portal/crud/projetoTarefa';
    const projetoId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: '',
        showModal: false,
        modalTitle: "Novo Cronograma",
        formName: "",
        details: false,
        grid: true
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=projetoId::equal::${projetoId};;AND;;isExcluido::equal::0&order=projetoId DESC`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const handleNew = () => {
        setParam({ ...params, showModal: true, rowId: '', formName: 'edit' });
    }

    const handleDeleteCronograma = (id) => {
        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleDeleteTarefas = (id) => {
        apiHelper.api_delete(`${endPointTarefa}/${id}/cronogramaId`).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const verificarExistemTarefas = (id) => {
        apiHelper.get(`${endPointTarefa}?filter=cronogramaId::equal::${id}`)
            .then(res => {
                let tarefas = res.data.data;
                if (tarefas.length > 0) {
                    if (window.confirm(`O cronograma ${id} possui ${tarefas.length} tarefa(s) associada(s).Você deseja realmente excluir os registros?`)) {
                        handleDeleteTarefas(id);
                        handleDeleteCronograma(id);
                    }
                } else {
                    if (window.confirm("Deseja realmente excluir este registro ?"))
                        handleDeleteCronograma(id);
                }
            });
    }

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    const handleHide = (refresh) => {
        setParam({
            ...params,
            showModal: false,
            grid: true,
            details: false
        });
        refresh && fetchData();
    }

    const handleDetails = (id) => {
        setParam({
            ...params,
            rowId: id,
            grid: false,
            details: true,
        });
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "edit" && <CronogramaForm projetoId={projetoId} rowId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>

            {params.grid &&
                <React.Fragment>
                    <div style={{ marginBottom: "7px" }}>
                    {props.canEdit && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>}
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th>Nome</th>
                                    <th>Data Cadastro</th>
                                    <th>Anexo</th>
                                {props.canEdit && <th style={{ width: 80 }}>Ação</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.dataCadastro < b.dataCadastro ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td><Link to={'#'} onClick={() => handleDetails(item.id)}>{item.descricao}</Link></td>
                                                <td>{config.dateFix(item.dataCadastro)}</td>
                                                <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                                {props.canEdit && <td>
                                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("edit", "Editar Cronograma", item.id)}><i className="fa fa-edit" /></button>
                                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => verificarExistemTarefas(item.id)}><i className="fa fa-trash" /></button>
                                                </td>}
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }

            {params.details && <CronogramaView parentId={params.rowId} onHide={handleHide} canEdit={props.canEdit}/>}
        </React.Fragment>
    );
}
