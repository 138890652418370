import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select, config } from '../index';
import { createHashHistory } from 'history'
export const history = createHashHistory();

export function OrcamentoForm(props) {
    const endPointOrcamento = 'projetoCliente';
    const rowId = props.rowId;
    const [data, setData] = useState({
        clienteId: 0,
        siteId: [],
        nome: '',
        descricao: '',
        file: '',
        anexo: ''
    });

    const [codigoConsultor, setCodigoConsultor] = useState(0);

    let usuarioLogadoId = localStorage.getItem('claims') !== null
        && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const fetchData = () => {
        setCodigoConsultor(0)
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                apiHelper.toBase64(file)
                    .then((base) => {
                        setData({ ...data, file: base, anexo: file.name });
                    });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleChangeSite = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data.clienteValueSearch !== undefined)
            delete data.clienteValueSearch;

        if (data.codigoIdentificadorCliente !== undefined)
            delete data.codigoIdentificadorCliente;

        let dataOrcamento = {
            nome: data.nome,
            clienteId: data.clienteId,
            oportunidadeComercialId: data.oportunidadeComercialId,
            estadodeFaturamentoEmpresa: data.estadodeFaturamentoEmpresa,
            estadodeFaturamentoCliente: data.estadodeFaturamentoCliente,
            descricao: data.descricao,
            numeroIdentificador: data.numeroIdentificador,
            criadorId: usuarioLogadoId,
            crossSelling: data.crossSelling,
            responsavelVendaId: codigoConsultor === 0 ? data.responsavelVendaId : codigoConsultor,
            tipoId: data.tipoId,
            empresaOrigem: data.empresaOrigem,
            criadoPorCliente: true
        }

        if (data.leadId === null || data.leadId === '')
            delete dataOrcamento.leadId;

        if (rowId === 0) {
            dataOrcamento.numeroIdentificador = '';
            dataOrcamento.status = 'Rascunho';
            dataOrcamento.processed = 0;
            dataOrcamento.guid = "";
            let form = { orcamento: dataOrcamento, sitesIds: data.siteId, file: data.file, anexo: data.anexo };
            apiHelper.post(`${endPointOrcamento}`, form)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        history.push(`/v2/client/project/${res.data.data}`);
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                {!rowId > 0 &&
                    <div className="form-group col-sm-12">
                        <label>Cliente</label>
                        <Select id="clienteId" className="form-control input-sm" onChange={handleChange} value={data.clienteId}>
                            <option value="">Selecione</option>
                            <option endpoint={'v3/os/clientes'} valuefield="id" textfield="nome" />
                        </Select>
                    </div>
                }
                <div className="form-group col-sm-12">
                    <label>Site</label>
                    <Select multiple id="siteId" className="form-control input-sm" onChange={handleChangeSite} refresh={data.clienteId} style={{ height: 150 }}>
                        <option endpoint={data.clienteId !== '' ? `v3/os/clientes/${data.clienteId}/sites` : ''} valuefield="id" textfield="nome" />
                    </Select>
                </div>

            </div>

            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ""} required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao} required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-12">
                    <label>Anexo</label>
                    <input type="file" id="file" onChange={handleChange} />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="pull-right">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>

        </form>
    )
}
