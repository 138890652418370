import React from "react";
import * as Tools from '../';

export function Project(props) {
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        const fetchData = () => {
            setRows([]);
        }

        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <Tools.Content title="Projetos">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Projetos</h3>
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse"><i className="fas fa-minus" /></button>
                        <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove"><i className="fas fa-times" /></button>
                    </div>
                </div>
                <div className="card-body p-0">
                    <table className="table table-striped projects">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Cliente</th>
                                <th>Membros</th>
                                <th>Progresso</th>
                                <th className="text-center">Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((m, i) =>
                                <tr key={i}>
                                </tr>
                            )}
                            <tr>
                                <td>1000</td>
                                <td>
                                    <Tools.Link to="#">Implantação de CFTV</Tools.Link>
                                    <br />
                                    <small>Criação 01.01.2019</small>
                                </td>
                                <td>Cliente 01</td>
                                <td>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <img alt="Avatar" className="table-avatar" src="../../dist/img/avatar.png" />
                                        </li>
                                        <li className="list-inline-item">
                                            <img alt="Avatar" className="table-avatar" src="../../dist/img/avatar2.png" />
                                        </li>
                                    </ul>
                                </td>
                                <td className="project_progress">
                                    <div className="progress progress-sm">
                                        <div className="progress-bar bg-green" role="progressbar" aria-valuenow="57" aria-valuemin="0" aria-valuemax="100" style={{ width: "57%" }}>
                                        </div>
                                    </div>
                                    <small>57% Completo</small>
                                </td>
                                <td className="project-state">
                                    <span className="badge badge-success">Success</span>
                                </td>
                                <td className="project-actions text-right">
                                    <div className="btn-group">
                                        <button className="btn btn-primary btn-sm"><i className="fas fa-eye" /></button>
                                        <button className="btn btn-info btn-sm"><i className="fas fa-pencil-alt" /></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Projeto</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="form-group col-md-8">
                                    <label for="inputName">Nome do Projeto</label>
                                    <input type="text" id="inputName" className="form-control" value="AdminLTE" />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="inputStatus">Status</label>
                                    <select id="inputStatus" className="form-control custom-select">
                                        <option disabled>Selecione</option>
                                        <option value="0">Orçamento</option>
                                        <option value="1">Aprovado</option>
                                        <option value="1">Reprovado</option>
                                        <option value="2">Em Andamento</option>
                                        <option value="3">Cancelado</option>
                                        <option value="4">Concluído</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="inputClientCompany">Cliente</label>
                                    <input type="text" id="inputClientCompany" className="form-control" value="Deveint Inc" />
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="inputDescription">Descrição</label>
                                    <textarea id="inputDescription" className="form-control" />
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="inputProjectLeader">Lider do Projeto</label>
                                    <input type="text" id="inputProjectLeader" className="form-control" value="Tony Chicken" />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer p-2">
                            <button className="btn btn-secondary">Cancelar</button>
                            <button type="submit" className="btn btn-success float-right">Salvar</button>
                        </div>
                    </div>

                    <div className="card card-info">
                        <div className="card-header">
                            <h3 className="card-title">Membros</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse"><i className="fas fa-minus" /></button>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Papel</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Consultor 01</td>
                                        <td>Comercial</td>
                                    </tr>
                                    <tr>
                                        <td>E&O 01</td>
                                        <td>E&O</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="card card-secondary">
                        <div className="card-header">
                            <h3 className="card-title">Orçamento</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body p-2">
                            <table className="table table-hover table-bordered table-striped">
                                <thead>
                                    <tr className="bg-light-blue">
                                        <th>Descrição</th>
                                        <th>Resultado</th>
                                        <th>Prazo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Serviço Não Recorrente</td>
                                        <td>R$ 3.138,20</td>
                                        <td>2 meses</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="form-group">
                                <label for="inputEstimatedBudget">Target</label>
                                <input type="number" id="inputEstimatedBudget" className="form-control" value="2300" step="1" />
                            </div>
                            <div className="form-group">
                                <label for="inputSpentBudget">Total amount spent</label>
                                <input type="number" id="inputSpentBudget" className="form-control" value="2000" step="1" />
                            </div>
                            <div className="form-group">
                                <label for="inputEstimatedDuration">Estimated project duration</label>
                                <input type="number" id="inputEstimatedDuration" className="form-control" value="20" step="0.1" />
                            </div>
                        </div>
                    </div>
                    <div className="card card-info">
                        <div className="card-header">
                            <h3 className="card-title">Itens de Projeto</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Produto</th>
                                        <th>QTDE</th>
                                        <th>Valor</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Produto</td>
                                        <td>Camera IP</td>
                                        <td className="text-right">2</td>
                                        <td className="text-right">100,00</td>
                                        <td className="text-right">200,00</td>
                                        <td className="text-right">
                                            <div className="btn-group btn-group-sm">
                                                <button className="btn btn-info"><i className="fas fa-edit" /></button>
                                                <button className="btn btn-danger"><i className="fas fa-trash" /></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Serviço</td>
                                        <td>Deslocamento</td>
                                        <td className="text-right">100</td>
                                        <td className="text-right">20,00</td>
                                        <td className="text-right">2.000,00</td>
                                        <td className="text-right py-0 align-middle">
                                            <div className="btn-group btn-group-sm">
                                                <button className="btn btn-info"><i className="fas fa-edit" /></button>
                                                <button className="btn btn-danger"><i className="fas fa-trash" /></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Serviço</td>
                                        <td>Desenvolvimento de Sistema</td>
                                        <td className="text-right">350</td>
                                        <td className="text-right">120,00</td>
                                        <td className="text-right">42.000,00</td>
                                        <td className="text-right py-0 align-middle">
                                            <div className="btn-group btn-group-sm">
                                                <button className="btn btn-info"><i className="fas fa-edit" /></button>
                                                <button className="btn btn-danger"><i className="fas fa-trash" /></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Serviço</td>
                                        <td>Infra</td>
                                        <td className="text-right">1</td>
                                        <td className="text-right">2.500,00</td>
                                        <td className="text-right">2.500,00</td>
                                        <td className="text-right py-0 align-middle">
                                            <div className="btn-group btn-group-sm">
                                                <button className="btn btn-info"><i className="fas fa-edit" /></button>
                                                <button className="btn btn-danger"><i className="fas fa-trash" /></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Projects Detail</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove" title="Remove">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <div class="info-box bg-light">
                                        <div class="info-box-content">
                                            <span class="info-box-text text-center text-muted">Estimated budget</span>
                                            <span class="info-box-number text-center text-muted mb-0">2300</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="info-box bg-light">
                                        <div class="info-box-content">
                                            <span class="info-box-text text-center text-muted">Total amount spent</span>
                                            <span class="info-box-number text-center text-muted mb-0">2000</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="info-box bg-light">
                                        <div class="info-box-content">
                                            <span class="info-box-text text-center text-muted">Estimated project duration</span>
                                            <span class="info-box-number text-center text-muted mb-0">20</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h4>Recent Activity</h4>
                                    {/* <div class="post">
                                        <div class="user-block">
                                            <img class="img-circle img-bordered-sm" src="../../dist/img/user1-128x128.jpg" alt="user image" />
                                            <span class="username">
                                                <a href="#">Jonathan Burke Jr.</a>
                                            </span>
                                            <span class="description">Shared publicly - 7:45 PM today</span>
                                        </div>

                                        <p>
                                            Lorem ipsum represents a long-held tradition for designers,
                                            typographers and the like. Some people hate it and argue for
                                            its demise, but others ignore.
                                        </p>
                                        <p>
                                            <a href="#" class="link-black text-sm"><i class="fas fa-link mr-1"></i> Demo File 1 v2</a>
                                        </p>
                                    </div>
                                    <div class="post clearfix">
                                        <div class="user-block">
                                            <img class="img-circle img-bordered-sm" src="../../dist/img/user7-128x128.jpg" alt="User Image" />
                                            <span class="username">
                                                <a href="#">Sarah Ross</a>
                                            </span>
                                            <span class="description">Sent you a message - 3 days ago</span>
                                        </div>

                                        <p>
                                            Lorem ipsum represents a long-held tradition for designers,
                                            typographers and the like. Some people hate it and argue for
                                            its demise, but others ignore.
                                        </p>
                                        <p>
                                            <a href="#" class="link-black text-sm"><i class="fas fa-link mr-1"></i> Demo File 2</a>
                                        </p>
                                    </div>
                                    <div class="post">
                                        <div class="user-block">
                                            <img class="img-circle img-bordered-sm" src="../../dist/img/user1-128x128.jpg" alt="user image" />
                                            <span class="username">
                                                <a href="#">Jonathan Burke Jr.</a>
                                            </span>
                                            <span class="description">Shared publicly - 5 days ago</span>
                                        </div>

                                        <p>
                                            Lorem ipsum represents a long-held tradition for designers,
                                            typographers and the like. Some people hate it and argue for
                                            its demise, but others ignore.
                                        </p>
                                        <p>
                                            <a href="#" class="link-black text-sm"><i class="fas fa-link mr-1"></i> Demo File 1 v1</a>
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                            <h3 class="text-primary"><i class="fas fa-paint-brush"></i> AdminLTE v3</h3>
                            <p class="text-muted">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terr.</p>
                            <br />
                            <div class="text-muted">
                                <p class="text-sm">Client Company
                                    <b class="d-block">Deveint Inc</b>
                                </p>
                                <p class="text-sm">Project Leader
                                    <b class="d-block">Tony Chicken</b>
                                </p>
                            </div>
                            <h5 class="mt-5 text-muted">Project files</h5>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="/" class="btn-link text-secondary"><i class="far fa-fw fa-file-word"></i> Functional-requirements.docx</a>
                                </li>
                                <li>
                                    <a href="/" class="btn-link text-secondary"><i class="far fa-fw fa-file-pdf"></i> UAT.pdf</a>
                                </li>
                                <li>
                                    <a href="/" class="btn-link text-secondary"><i class="far fa-fw fa-envelope"></i> Email-from-flatbal.mln</a>
                                </li>
                                <li>
                                    <a href="/" class="btn-link text-secondary"><i class="far fa-fw fa-image "></i> Logo.png</a>
                                </li>
                                <li>
                                    <a href="/" class="btn-link text-secondary"><i class="far fa-fw fa-file-word"></i> Contract-10_12_2014.docx</a>
                                </li>
                            </ul>
                            <div class="text-center mt-5 mb-3">
                                <a href="/" class="btn btn-sm btn-primary">Add files</a>
                                <a href="/" class="btn btn-sm btn-warning">Report contact</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Tools.Content>
    );
}
