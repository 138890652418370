import React, { useState, useEffect } from 'react';
import { Link, apiHelper, toast, Modal } from '../';
import { AgendamentoItem } from './AgendamentoItem';
import { AgendamentoForm } from './AgendamentoForm';

export function AgendamentoView(props) {
    const endPoint = 'portal/crud/Agendamento'
    const rowId = props.rowId;

    const [data, setData] = useState({});
    const [params, setParam] = useState({ showModal: false });

    const fetchData = () => {
        rowId > 0 && apiHelper.get(`${endPoint}/${rowId}/id`).then(res => {
            if (res.data.code === 200)
                setData(res.data.data);
            else
                toast.error('Atenção, não foi possível a requisição ' + rowId);
        });
    }

    useEffect(fetchData, [rowId]);

    const handleEdit = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onHide();
    };

    return (
        <React.Fragment>
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                    <li className="header">{data.nome}</li>
                    <li className="active"><a href="#tab_agendamento" data-toggle="tab">Agendamentos</a></li>
                    <li className="pull-right">
                        <div className="btn-group">
                            <Link replace to="#" className="btn btn-flat text-muted" onClick={handleEdit}><i className="fa fa-edit" /></Link>
                            <Link replace to="/agendamento" className="btn btn-flat text-muted"><i className="fa fa-times" /></Link>
                        </div>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="tab_agendamento">
                        <AgendamentoItem parentId={rowId} />
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Agendamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AgendamentoForm rowId={rowId} onHide={handleHide} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
