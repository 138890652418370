import React, { Component } from 'react';
import {
    Table, Button, FormGroupButton
} from '../../components/adminlte/AdminLTE';
import { toast } from 'react-toastify';

export class DeviceGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            table: [],
            tableSearch: '',
            showForm: false,
            name: '',
            serialNumber: 0,
            groups: []
        };

        this.tableSearch = this.tableSearch.bind(this);
        this.getGroupsDevice = this.getGroupsDevice.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { row } = newProps;

        if (this.props.row !== newProps.row) {
            this.getData();
            this.getGroupsDevice(row.serialNumber);
        }
    }

    getData(filter) {
        const { apiServer, token } = this.props.config;

        if (filter === undefined)
            filter = '';

        fetch(apiServer + 'identity/group?filter=' + filter, {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ table: data });
        });
    }

    getGroupsDevice(key) {
        const { apiUrl, token } = this.props.config;

        fetch(apiUrl + key + '/groupIdentity/', {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ groups: data });
        });
    }

    tableSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ tableSearch: value });

        if (value.length > 2 || value === '')
            this.getData(value);
    }

    addRemove(e, action) {

        if (action === 'del') {
            this.remove(e);
            return;
        }
        const { config, row } = this.props;

        let deviceGroup = {
            deviceCode: row.id,
            groupCode: e.id
        };

        fetch(config.apiUrl + '/group', {
            method: 'PUT',
            body: JSON.stringify(deviceGroup),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);

        }).then((response) => {

            if (response[0] === 200) {
                this.getData(this.state.tableSearch);
                this.getGroupsDevice(row.serialNumber);
            }
            else
                toast.warn(response[1]["msg"]);
        }).catch(error => {
            toast.error(error);
        });
    }

    remove(e) {
        const { config, row } = this.props;

        fetch(config.apiUrl + "/" + row.serialNumber + '/groupIdentity/' + e.name, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.getData(this.state.tableSearch);
                this.getGroupsDevice(row.serialNumber);
            }
            else
                toast.warn(response[1]["msg"]);
        }).catch(error => {
            toast.error(error);
        });
    }

    findGroup(obj) {

        let group = this.state.groups.filter(e => e.id === obj.id)
        return group.length > 0;
    }

    render() {
        return (
            <React.Fragment>
                <FormGroupButton size="sm" placeholder="Buscar grupos"
                    onChange={this.tableSearch} value={this.state.tableSearch}
                    buttonRight={<Button text="Limpar" onClick={() => this.tableSearch(null)}>LIMPAR</Button>}
                />
                <Table striped bordered hover>
                    <tbody>
                        {this.state.table.map(row => {
                            return (
                                <tr key={row.id}>
                                    <td>{row.name}</td>
                                    <td style={{ width: "50px" }}>
                                        <Button bsSize="xsmall" bsStyle={this.findGroup(row) ? "success" : "default"} onClick={() => this.addRemove(row, this.findGroup(row) ? 'del' : 'add')}>
                                            {this.findGroup(row) ? 'Remover' : 'Adicionar'}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </React.Fragment >
        );
    }
}

