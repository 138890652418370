import React, { Component } from 'react';
import {
    Modals, FieldGroup, Row, Col, Button,
    ButtonToolbar, FormGroupButton
} from '../../components/adminlte/AdminLTE';

import { ModalFindSubGroups } from './ModalFindSubGroups';
import { toast } from 'react-toastify';

const data = {
    name: '',
    description: '',
    parentId: ''
};

export class GroupEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            keyValue: '',
            isSaving: false,
            subGroupList: [],
            subGroupValueSearch: '',
            showFormFindSubGroups: false,
            data: data
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { action, keyValue } = this.props;

        if (action === 'edit') {
            this.setState({ keyValue: keyValue });
            this.getDeviceSubGroups(keyValue);
        }
        else
            this.setState({ keyValue: '', subGroupValueSearch: '' });
    }

    getDeviceSubGroups(name) {
        if (name === undefined)
            name = '';

        fetch(this.state.config.apiUrl + name, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ subGroupList: data, data: data, subGroupValueSearch: data.parentName });
        });
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { data: { ...prevState.data, [name]: value } };
        });
    }

    handleSubmit(e) {
        const { apiUrl, token } = this.props.config;
        const { action } = this.props;

        fetch(apiUrl + this.state.keyValue, {
            method: action === 'edit' ? 'PUT' : 'POST',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.onHide();
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    handleAddSubGroup(row) {
        this.setState({ subGroupValueSearch: row.name, subGroupList: [] });

        this.setState(prevState => {
            return { data: { ...prevState.data, parentId: row.id } };
        });
    }

    onHide() {
        this.setState({ data: data });
        this.props.onHide();
    }

    render() {
        const { show = false, config, row } = this.props;
        const { isSaving, data } = this.state;

        return (
            <Modals title="Edi&#xE7;&#xE3;o de Grupo" show={show} onHide={() => this.onHide()} footer={
                <ButtonToolbar>
                    <Button bsStyle="success" disabled={isSaving} onClick={this.handleSubmit}>
                        {isSaving ? 'Salvando...' : 'Salvar'}
                    </Button>
                    <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                </ButtonToolbar>}
            >
                <Row>
                    <FieldGroup col={9} id="name" type="text" label="Nome" value={this.state.data.name} onChange={this.handleChange} />
                    <FieldGroup col={3} id="active" componentClass="select" label="Ativo" value={this.state.data.active} onChange={this.handleChange}>
                        <option value>Ativo</option>
                        <option value={false}>Inativo</option>
                    </FieldGroup>
                    <Col md={12}>
                        <FormGroupButton placeholder="SubGrupo" label="SubGrupo" labelPosition="none" disabled onChange={this.subGroupSearch} value={this.state.subGroupValueSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showFormFindSubGroups: true })}>Selecionar</Button>} />
                    </Col>
                </Row>
                {this.state.showFormFindSubGroups && <ModalFindSubGroups config={config} data={data} show={this.state.showFormFindSubGroups} onImport={(row) => { this.handleAddSubGroup(row); }} row={row} onHide={() => this.setState({ showFormFindSubGroups: false })} />}
            </Modals>
        );
    }
}