import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../index';

export function BuscarUsuario(props) {
    const endPoint = 'projetoCliente/usuariosAssociados';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        nome: ''
    });

    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (text) => {
        if (text.length === 0 || text.length >= 3)
            apiHelper.get(`${endPoint}/${parentId}?nome=${text}`).then(res => {
                setData(res.data.data);
            });
    };

    const onLoad = () => {
        fetchData("");
    };

    useEffect(onLoad, [parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
        fetchData(value);
    };

    const handleAdicionar = (pkUsuario) => {
        let data = {
            userId: pkUsuario,
            Id: parentId
        };

        apiHelper.put(`${endPoint}`, data)
            .then(res => {
                if (res.data.code === 200) {
                    props.onHide(true);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    }

    const handleRefresh = () => {
        setFilter({
            nome: ''
        });

        setData([]);
        fetchData("");
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                        <thead>
                            <tr className="bg-light-blue color-palette">
                                <th>Nome</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} /></td>
                                <td style={{ width: '50px' }}><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                            </tr>
                            {data && data.sort((a, b) => a.pkUsuario < b.pkUsuario ? 1 : -1)
                                .slice(params.initRow, params.endRow)
                                .map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.nome}</td>
                                            <td> <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleAdicionar(item.pkUsuario)}> <i className="fa fa-plus" /></button></td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
                <div style={{ marginTop: "7px" }}>
                    <div className="pull-right">
                        {renderPage()}
                    </div>
                </div>
            </div>
        </div>
    )
}
