import React from 'react';
import moment from 'moment';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const utils = {
    createRoutes,
    uuidv4,
    uuidshort,
    toPropArray,
    getChild,
    dataTypeFormat,
    getBase64,
    getRandomColor
};

function createRoutes(menu, props) {
    return menu.map((item, i) => {
        try {
            const DynamicApp = require(`${item.component}`).default;
            return <Route key={i} path={item.path} element={<DynamicApp {...props} />} />
        }
        catch (e) {
            console.log('Failed to load Component: ' + e);
            return [];
        }
    });
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

function uuidshort() {
    return 'xx-xxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r && 0x3) | 0x8);
        return v.toString(16);
    });
}

function toPropArray(data) {
    return React.Children.toArray(data);
}

function getChild(data, value) {
    return data.children.filter(f => f.type === value)[0];
}

function dataTypeFormat(type, value) {
    if (!type) return value;

    let val = value ? value : "";

    if (type === "enabled" && val === "true")
        val = <span className="badge badge-success">Ativo</span>;

    if (type === "enabled" && val === "false")
        val = <span className="badge badge-secondary">Inativo</span>;

    if (type === "date")
        val = moment(val).format('DD/MM/YYYY');

    if (type === "datetime")
        val = moment(val).format('DD/MM/YYYY hh:mm:ss');

    if (type === "datetime-m")
        val = moment(val).format('DD/MM/YYYY HH:mm');

    if (type === 'file')
        val = (
            <Link replace to="#" onClick={null}>
                <i className="fas fa-link" /> {value}
            </Link>
        )

    return val;
}

function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
        cb(reader.result);
    };

    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

function getRandomColor(size) {
    let colors = []

    for (let index = 0; index < (size || 1); index++) {
        colors.push('#' + (Math.random().toString(16) + '0000000').slice(2, 8));
    }

    return colors;

    // var letters = '0123456789ABCDEF'.split('');
    // var color = '#';

    // for (var i = 0; i < 6; i++) {
    //     color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
}