import React, { Component } from 'react';
import OrdemServicoTimeline from './OrdemServicoTimeline';

const collection = [];

export class OrdemServicoItens extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowId: 0,
            dates: [],
            collection: [],
            apiEndPoint: localStorage.getItem('apiUrl') + 'siga/serviceOrder/'
        };

        this.getDataTable = this.getDataTable.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps !== this.state.rowId) {
            this.setState({
                collection: [],
                rowId: newProps.rowId
            });
            this.getDataTable(newProps.rowId);
        }
    }

    fillIcon(item) {
        item.icon = "user";
        item.color = "fa bg-aqua";

        if (item.usuario === "SISTEMA") {
            item.icon = "cogs";
            item.color = "fa bg-blue";
            return;
        }
        else if (item.description.includes("Acionamento")) {
            item.icon = "user-plus";
            item.color = "fa bg-blue";
            return;
        }
        else if (item.description.includes("Produtiva")) {
            item.icon = "building";
            item.color = "fa bg-blue";
            return;
        }
        else if (item.description.includes("Improdutiva")) {
            item.icon = "building";
            item.color = "fa bg-orange";
            return;
        }
        else if (item.description.includes("Material:")) {
            item.icon = "shopping-cart";
            item.color = "fa bg-blue";
            return;
        }
        else if (item.description.endsWith("aprovado.")) {
            item.icon = "shopping-cart";
            item.color = "fa bg-blue";
            return;
        }
        else if (item.description.endsWith("reprovado.")) {
            item.icon = "shopping-cart";
            item.color = "fa bg-orange";
            return;
        } else if (item.description.includes("Anexo:")) {
            item.icon = 'folder';
            item.color = "fa bg-blue";
            return;
        }
    }

    getDataTable(id) {
        if (id !== 0 && id !== undefined) {
            fetch(this.state.apiEndPoint + 'interaction/' + id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('id_token')
                }
            }).then(
                response => response.json()
            ).then(data => {
                const dates = [];

                if (data[0] !== undefined) {
                    data.map(item => {
                        dates.push(item.dateCreatedInteractionFormat.split(' ')[0]);
                        return true;
                    });

                    collection.length = 0;

                    [...new Set(dates)].map(date => {
                        let collectionItem = { date: "", itens: [] };
                        collectionItem.date = date;
                        data.filter(f => f.dateCreatedInteractionFormat.split(' ')[0] === date)
                            .sort((a, b) => new Date(b.codeOS) - new Date(a.codeOS))
                            .map(item => {
                                this.fillIcon(item);
                                collectionItem.itens.push({
                                    title: item.userName,
                                    subTitle: item.description,
                                    description: item.description,
                                    anexoName: item.anexoName,
                                    anexoCode: item.anexoCode,
                                    pathAnexo: item.pathAnexo,
                                    actTechnicalCode: item.actTechnicalCode,
                                    isActTechnicalCanceled: item.isActTechnicalCanceled,
                                    isActTechnicalDone: item.isActTechnicalDone,
                                    codeOS: item.interactionCode,
                                    icon: item.icon,
                                    color: item.color,
                                    hour: item.dateCreatedInteractionFormat.split(' ')[1],
                                    isVisivelCliente: item.isVisivelCliente
                                });
                                return null;
                            });

                        collection.push(collectionItem);

                        return null;
                    });

                    this.setState({ collection });
                }
            });
        }
    }

    render() {
        return (
            <OrdemServicoTimeline apiEndPoint={this.state.apiEndPoint} data={this.state.collection} getDataTable={this.getDataTable} codeOs={this.state.rowId} />
        );
    }
}