import React, { useState, useEffect } from 'react';
import { Content, apiHelper, Modal, toast } from '../index';
import { ReportForm } from './ReportForm';
import { Report } from '../index';

export function ReportAdm(props) {
    const endPoint = 'portal/crud/Report';
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const usuarioLogadoId = localStorage.getItem('claims') !== null
        && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: ""
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=criador::equal::${usuarioLogadoId};;AND;;${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, [row]);

    const handleChange = (e) => {
        fetchData(`nome::contains::${e.target["value"]}`);
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}/id`).then(res => {
            toast.success('Grupo excluído com sucesso!');
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    return (
        <Content title="Relatórios" browserTitle="Relatórios">
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block margin-bottom" onClick={() => handleShowForm("relatorio", "Novo Relatório", 0)}>Novo Relatório</button>

                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Pesquisar:</h3>
                            <div className="box-tools pull-right">
                                <div className="has-feedback">
                                    <input id="nome" type="text" className="form-control input-sm" placeholder="Nome" onChange={handleChange} />
                                    <span className="glyphicon glyphicon-search form-control-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive" style={{ overflow: "auto", maxHeight: 400 }}>
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th style={{ width: "5px" }}>#</th>
                                            <th>Nome</th>
                                            <th style={{ width: "5px" }}>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-toolbar">
                                                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("relatorio", "Editar Relatório", item.id)}><i className="fa fa-pencil" /></button>
                                                            </div>
                                                        </td>
                                                        <td onClick={() => setRow(item)} style={{ cursor: 'pointer' }}>
                                                            {item.nome}
                                                            <div><small>{item.descricao}</small></div>
                                                        </td>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-toolbar">
                                                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.id)}> <i className="fa fa-trash" /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    {row.id && <Report id={row.id} admin={true} />}
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'relatorio' && <ReportForm parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}