import React, { Component } from 'react';
import {
    Content, FieldGroup, Row, Box, Col, Button,
    Tabs, TabContent, ButtonToolbar
} from '../../components/adminlte/AdminLTE';
import { toast } from 'react-toastify';

const userImage = process.env.PUBLIC_URL + '/adminlte/img/avatar.png';

export class PerfilUsuario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            endPoint: localStorage.getItem('apiUrl') + 'identity/user',
            token: 'Bearer ' + localStorage.getItem('id_token'),
            data: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.action === 'edit') {
            this.setState({ data: newProps.row });
        }
    }

    handleChange(e) {
        let value = e.target["value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { data: { ...prevState.data, [name]: value } };
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.data.newPassword !== this.state.data.confirmNewPassword) {
            toast.warn('Senha e confirmação de senha não coecidem!');
            return;
        }

        fetch(this.state.endPoint + "/changePasswordUser/", {
            method: 'POST',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': this.state.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.clearData();
                toast.success('Dados salvos com sucesso!');
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    clearData() {
        this.setState({
            data: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
        });
    }

    render() {
        return (
            <Content title="Perfil de Usuário" browserTitle="Siga - Perfil de Usuário">
                <Row>
                    <Col md={3}>
                        <Box type="primary">
                            <img class="profile-user-img img-responsive img-circle" src={userImage} alt="" />
                            <h3 class="profile-username text-center">{localStorage.getItem('id_name')}</h3>
                        </Box>
                    </Col>
                    <Col md={9}>
                        <Tabs defaultActiveKey="password">
                            <TabContent title="Senha" eventKey="password">
                                <form onSubmit={this.handleSubmit}>
                                    <FieldGroup id="currentPassword" type="password" label="Senha Atual" value={this.state.data.currentPassword} onChange={this.handleChange} />
                                    <FieldGroup id="newPassword" type="password" label="Nova Senha" value={this.state.data.newPassword} onChange={this.handleChange} />
                                    <FieldGroup id="confirmNewPassword" type="password" label="Confirmar Nova Senha" value={this.state.data.confirmNewPassword} onChange={this.handleChange} />
                                    <ButtonToolbar>
                                        <Button bsStyle="success" type="submit">Salvar</Button>
                                    </ButtonToolbar>
                                </form>
                            </TabContent>
                        </Tabs>
                    </Col>
                </Row>
            </Content>
        );
    }
}
