import React, { useEffect, useState } from 'react';
import { apiHelper, toast, Button, FormGroupButton } from './../../index';
import { PesquisaGenerica } from './../../../components/controls/PesquisaGenerica';

export function UsuarioAssociarSites(props) {
    const endPoint = 'portal/crud/uvw_siteNew';
    const endPointSitesPessoa = 'portal/crud/uvw_v2_ObterSitesPessoa';
    const endPointComercialSite = 'comercial/site';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [showFormFindClientes, setShowFormFindClientes] = useState(false);
    const [tablePessoaSite, setTablePessoaSite] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: ''
    });
    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where, idCliente = '') => {
        if (!where)
            where = `idCliente::equal::${idCliente !== '' ? idCliente : clientes.idCliente}`
        else
            where = `idCliente::equal::${clientes.idCliente};;AND;;${where}`;

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const obterSitesPessoa = (idPessoa) => {
        if (idPessoa === '')
            return;

        apiHelper.get(`${endPointSitesPessoa}?filter=idPessoa::equal::${idPessoa}`).then(res => {
            setTablePessoaSite(res.data.data);
        });
    }

    useEffect(fetchData, [props.parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nome')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const addRemove = (e, action) => {
        if (action === 'del') {
            removerAssociacaoPessoaSite(e);
            return;
        }

        var siteCommand = {
            id: e.id,
            idPessoa: parentId
        }

        apiHelper.post(`${endPointComercialSite}/associarPessoaSite`, siteCommand)
            .then(res => {
                if (res.data.code === 200) {
                    props.onUpdate();
                    obterSitesPessoa(parentId);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const removerAssociacaoPessoaSite = (item) => {
        let idSite = item.id;

        apiHelper.api_delete(`${endPointComercialSite}/removerPessoaSite/${parentId}/${idSite}`).then(res => {
            props.onUpdate();
            obterSitesPessoa(parentId);
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleAddCliente = (row) => {
        setClientes({ ...clientes, clienteValueSearch: row.nome, codigoIdentificadorCliente: row.id, idCliente: row.id });
        obterSitesPessoa(parentId);
        fetchData('', row.id);
    }

    const findSite = (obj) => {
        let site = tablePessoaSite.filter((e) => e.idSite === obj.id);
        return site.length > 0;
    }

    const handleAdicionarTodos = () => {
        var data = {
            idCLiente: clientes.idCliente,
            idPessoa: parentId
        }

        apiHelper.post(`${endPointComercialSite}/associarTodosSites`, data)
            .then(res => {
                if (res.data.code === 200) {
                    props.onUpdate();
                    obterSitesPessoa(parentId);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const handleRemoverTodosSites = () => {
        if (window.confirm(`Deseja excluir todos os sites associados do ${clientes.clienteValueSearch}?`)) {
            apiHelper.api_delete(`${endPointComercialSite}/removerTodosSites/${parentId}/${clientes.idCliente}`).then(res => {
                props.onUpdate();
                obterSitesPessoa(parentId);
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o registro.');
            });
        }
    }

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: ''
        });

        setData([]);
        //setClientes([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <FormGroupButton placeholder="Cliente" label="Cliente" disabled value={clientes.clienteValueSearch}
                        buttonRight={<Button bsStyle="success" type="button" onClick={() => { setShowFormFindClientes(true) }}>Selecionar</Button>}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: "7px", marginTop: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={() => { handleAdicionarTodos() }}>Adicionar Todos</button>
                        <button type="button" className="btn btn-danger btn-sm btn-flat" onClick={() => { handleRemoverTodosSites() }}>Remover todos</button>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>Id</th>
                                    <th>Nome</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                    <td><input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} /></td>
                                    <td><input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} /></td>
                                    <td></td>
                                </tr>
                                {data.length > 0 && data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: '50px' }}>#</td>
                                            <td>{item.id}</td>
                                            <td>{item.nome}</td>
                                            <Button bsSize="xsmall"
                                                bsStyle={findSite(item) ? "success" : "default"} onClick={() => addRemove(item, findSite(item) ? 'del' : 'add')}> {findSite(item) ? 'Remover' : 'Adicionar'}
                                            </Button>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showFormFindClientes && <PesquisaGenerica config={{ apiUrl: localStorage.getItem('apiUrl') }} show={showFormFindClientes} title="Buscar Clientes" source="comercial/cliente/?filtro=" onImport={(row) => { handleAddCliente(row) }} onHide={() => { setShowFormFindClientes(false) }} />}
        </React.Fragment>
    );
}
