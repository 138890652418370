import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Tabs, TabContent } from '../';
import { RequisicaoMaterialForm } from './RequisicaoMaterialForm';
import { RequisicaoMaterialItem } from './RequisicaoMaterialItem';
import { RequisicaoMaterialTarefa } from './RequisicaoMaterialTarefa';

export function RequisicaoMaterial(props) {
    const endPoint = 'api/suprimentos/rm/';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        status: '',
        codigoObra: 0,
        dataRequisicaoInicio: '',
        dataRequisicaoFim: '',
        dataNecessidadeInicio: '',
        dataNecessidadeFim: '',
    });

    const [params, setParams] = useState({
        rowId: props.match.params.id ? parseInt(props.match.params.id) : -1,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (params.rowId !== -1)
            return;

        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                setData(res.data.data);
            });
    }

    const onLoad = () => {
        config.validarPermissao('MenuEstoqueRequisicaoMaterial');
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleFilter = () => {
        let where = '';

        Object.entries(filter).map(([key, value]) => {
            if (value && value !== '' && !key.includes('data')) {
                where = where === '' ? where : ';;AND;;';
                where += `${key}::equal::${value}`;
            }

            return "";
        });

        if (filter.dataRequisicaoInicio !== '') {
            if (where !== '')
                where += ';;AND;;';

            where += 'dataRequisicao::greaterEqual::' + filter.dataRequisicaoInicio;

            if (filter.dataRequisicaoFim !== '')
                where += ';;AND;;dataRequisicao::lessEqual::' + filter.dataRequisicaoFim;
        }

        if (filter.dataNecessidadeInicio !== '') {
            if (where !== '')
                where += ';;AND;;';

            where += 'dataNecessidade::greaterEqual::' + filter.dataNecessidadeInicio;

            if (filter.dataNecessidadeFim !== '')
                where += ';;AND;;dataNecessidade::lessEqual::' + filter.dataNecessidadeFim;
        }

        fetchData(where);
    }

    const handleNew = () => {
        setParams({
            ...params,
            rowId: 0,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });

        props.history.push({
            pathname: '/estoque/requisicaoMaterial/0'
        });
    }

    const handleRefresh = () => {
        setFilter({
            ...filter, status: '',
            codigoObra: 0,
            dataRequisicaoInicio: '',
            dataRequisicaoFim: '',
            dataNecessidadeInicio: '',
            dataNecessidadeFim: '',
        });

        fetchData();
    }

    const handleClose = () => {
        setParams({ ...params, rowId: -1 });
        props.history.push({
            pathname: '/estoque/requisicaoMaterial/'
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Requisição de material" browserTitle="Requisição de material">
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block margin-bottom" onClick={handleNew}>Nova Requisição</button>
                    <div className="box box-solid">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="status">Status</label>
                                <select id="status" className="form-control" value={filter.status} onChange={handleChange}>
                                    <option value="">Selecione</option>
                                    <option value="Novo">Novo</option>
                                    <option value="Enviado">Enviado</option>
                                    <option value="Andamento">Em andamento</option>
                                    <option value="Concluido">Concluído</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="codigoObra">Código Obra</label>
                                <input id="codigoObra" type="number" className="form-control" value={filter.codigoObra} placeholder="Cód. Obra" onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dataRequisicaoInicio">Data Requisição</label>
                                <input type="date" id="dataRequisicaoInicio" className="form-control" value={filter.dataRequisicaoInicio} onChange={handleChange} />
                                <input type="date" id="dataRequisicaoFim" className="form-control" value={filter.dataRequisicaoFim} onChange={handleChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="dataNecessidadeInicio">Data Necessidade</label>
                                <input type="date" id="dataNecessidadeInicio" className="form-control" value={filter.dataNecessidadeInicio} onChange={handleChange} />
                                <input type="date" id="dataNecessidadeFim" className="form-control" value={filter.dataNecessidadeFim} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <div className="btn-toolbar">
                                <button type="button" className="btn btn-primary pull-right" onClick={handleFilter}>Pesquisar</button>
                                <button type="button" className="btn btn-default pull-right" onClick={handleRefresh}>Limpar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    {params.rowId === -1 &&
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Materiais</h3></div>
                            <div className="box-body no-padding">
                                <div className="table-responsive mailbox-messages">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Solicitante</th>
                                                <th>Cliente | Site</th>
                                                <th>Status</th>
                                                <th>Cód. Obra</th>
                                                <th>Data Requisição</th>
                                                <th>Data Necessidade</th>
                                                <th>Local Entrega</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length > 0 && data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td><Link to={'/estoque/requisicaoMaterial/' + item.id}>{item.usuario}</Link></td>
                                                            <td>{item.site ? item.cliente + ' | ' + item.site : 'Não informado!'}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.codigoObra}</td>
                                                            <td>{config.dateTimeFix(item.dataRequisicao)}</td>
                                                            <td>{config.dateTimeFix(item.dataNecessidade)}</td>
                                                            <td>{item.localEntrega}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {params.rowId > 0 &&
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">{params.rowId} - Requisição de material</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times"></i></button>
                                </div>
                            </div>

                            <div className="box-body">
                                <Tabs id="rc" defaultActiveKey="edicao">
                                    <TabContent title="Edição" eventKey="edicao">
                                        <RequisicaoMaterialItem parentId={params.rowId} onHide={handleClose} />
                                    </TabContent>
                                    <TabContent title="Tarefa" eventKey="tarefa">
                                        <RequisicaoMaterialTarefa parentId={params.rowId} onHide={handleClose} />
                                    </TabContent>
                                </Tabs>
                            </div>
                        </div>
                    }

                    {params.rowId === 0 &&
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Nova Requisição</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="box-body">
                                <RequisicaoMaterialForm parentId={0} onHide={handleClose} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Content>
    )
}
