import React, { useState, useEffect } from 'react';
import { Link, config, apiHelper, toast } from '../';
import { RequisicaoMaterialTarefaForm } from './RequisicaoMaterialTarefaForm';

export function RequisicaoMaterialTarefa(props) {
    const endPoint = 'estoque/requisicaoMaterial/tarefa/';
    const parentId = props.parentId;
    const [data, setData] = useState([]);    
    const [params, setParams] = useState({
        rowId: props.parentId,
        novaTarefa: false,
        tarefaId: 0,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = `idRequisicaoMaterial::equal::${parentId}`;

        apiHelper.get(`${endPoint}?filter=${where}&order=id`)
            .then(res => {
                setData(res.data.data);
            });
    }

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, [parentId]);

    const handleNew = () => {
        setParams({
            ...params,
            novaTarefa: true,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });
    }

    const handleEdit = (id) => {
        setParams({
            ...params,
            novaTarefa: true,
            tarefaId: id,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}${id}`, { isExcluido: true }).then(res => {
            config.httpResult(res.data);
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const onHide = () => {
        setParams({ ...params, novaTarefa: false, tarefaId: 0 });
        fetchData();
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="box box-solid">
                    <div className="box-header with-border">
                        <h3 className="box-title">Tarefas</h3>
                        <div className="box-tools pull-right">
                            <div role="toolbar" className="btn-toolbar">
                                <button className="btn btn-warning pull-right" title="Novo Item" onClick={handleNew}>Nova Tarefa</button>
                            </div>
                        </div>
                    </div>

                    <div className="box-body no-padding">
                        <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Status</th>
                                        <th>Responsável</th>
                                        <th>Previsão</th>
                                        <th>Conclusão</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                        .slice(params.initRow, params.endRow)
                                        .map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td><Link to={'#'} onClick={() => handleEdit(item.id)}>{item.nome}</Link></td>
                                                    <td>{item.status}</td>
                                                    <td>{item.responsavel}</td>
                                                    <td>{config.dateFix(item.previsao)}</td>
                                                    <td>{item.conclusao ? config.dateFix(item.conclusao) : ''}</td>
                                                    <td><button type="button" className="btn btn-box-tool" onClick={() => handleDelete(item.id)}><i className="fa fa-trash"></i></button></td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="box-footer">
                        <div className="mailbox-controls">
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {params.novaTarefa && <RequisicaoMaterialTarefaForm parentId={parentId} tarefaId={params.tarefaId} show={params.novaTarefa} onHide={onHide} endPoint={endPoint} />}
        </div>
    )
}
