import React, { useState, useEffect } from 'react';
import { Button, config, apiHelper, toast } from '../index';
import Select from 'react-select';

export function RegionalForm(props) {
    const endPoint = 'v2/regional/';
    const rowId = props.parentId;
    const [usuarios, setUsuarios] = useState({});
    const [gerenteRegional, setGerenteRegional] = useState('');
    const [aprovadores, setAprovadores] = useState({});
    const [prestadores, setPrestadores] = useState({});
    const [prestador, setPrestador] = useState('');

    const [data, setData] = useState({
        nome: '',
        gerenteRegional: '',
        fkUsuarioGerenteRegional: 0,
        fkPrestador: 0,
        aprovadores: ''
    });



    const fetchData = () => {

        apiHelper.get('portal/crud/prestador?filter=isSituacaoAtivo::equal::1;;and;;isExcluido::equal::0').then(resp => {
            let prest = [];
            resp.data.data.map((item) =>
                prest.push({ value: item.pkPrestador, label: item.nome })
            );
            setPrestadores(prest);

            apiHelper.get('portal/crud/usuario?filter=ativo::equal::1;;and;;isExcluido::equal::0').then(re => {
                let users = [];
                re.data.data.map((item) =>
                    users.push({ value: item.pkUsuario, label: item.nome })
                );
                setUsuarios(users);

                if (rowId && rowId !== '') {
                    let obj = {};
                    apiHelper.get(`${endPoint}/${rowId}/pkRegional`)
                        .then(res => {
                            Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                            setData(obj);
                            setGerenteRegional({ value: obj.fkUsuarioGerenteRegional, label: obj.gerenteRegional });
                            let teste = prest.filter(item => item.value === obj.fkPrestador);
                            setPrestador(teste);
                            let arrayValues = obj.aprovadores ? JSON.parse(obj.aprovadores) : null;
                            if (Array.isArray(arrayValues)) {
                                let rows = users.filter(e => arrayValues.includes(e.value));
                                setAprovadores(rows);
                            }

                        }).catch(error => {
                            toast.error('');
                        });
                }

            }).catch(error => {
                toast.error('Houve um erro ao carregar as cidades.');
            });
        }).catch(error => {
            toast.error('Houve um erro ao carregar as cidades.');
        });
    }


    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res.data);
                props.onImport();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkRegional`, data).then(res => {
                config.httpResult(res.data);
                props.onImport();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const onHide = () => {
        props.onHide();
    }

    const handleChangeGerente = (e) => {
        setData({ ...data, fkUsuarioGerenteRegional: e.value, gerenteRegional: e.label });
        setGerenteRegional(e);
    }

    const handleChangePrestador = (e) => {
        setData({ ...data, fkPrestador: e.value });
        setPrestador(e);
    }

    const handleChangeAprovador = (e) => {
        setData({ ...data, aprovadores: e ? JSON.stringify(e.map(p => p.value)) : [] });
        setAprovadores(e ? e : []);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Nome</label>
                    <input type="text" id="nome" className="form-control" onChange={handleChange} value={data.nome || ''} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Gerente Regional</label>
                    <Select
                        onChange={handleChangeGerente}
                        options={usuarios}
                        id="gerenteRegional"
                        value={gerenteRegional || ''}
                        placeholder="Selecione"
                        required
                    />
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ opacity: 0, height: 0 }}
                        value={data.gerenteRegional || ""}
                        onChange={() => { }}
                        required
                    />
                </div>
                <div className="form-group col-md-12">
                    <label htmlFor="name">Prestador</label>
                    <Select
                        onChange={handleChangePrestador}
                        options={prestadores || ''}
                        id="fkPrestador"
                        value={prestador}
                        placeholder="Selecione"
                    />
                </div>
                <div className="form-group col-md-12">
                    <label htmlFor="name">Aprovadores</label>
                    <Select
                        id="aprovadores"
                        value={(aprovadores && aprovadores.length > 0) && aprovadores}
                        isMulti
                        name="colors"
                        options={usuarios}
                        onChange={handleChangeAprovador}
                        placeholder="Selecione"
                    />
                </div>
                <div className="col-sm-12">
                    <div className="box-tool pull-right">
                        <div className="btn-toolbar">
                            <Button type="submit" bsStyle="success">Salvar</Button>
                            <Button bsStyle="default" onClick={onHide}>Cancelar</Button>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    )
}
