import React, { useEffect, useState } from 'react';
import { config, apiHelper } from '../index';

export function OrcamentoRevisao(props) {
    const endPoint = 'portal/crud/uvw_report_orcamentos_resumoV2';
    const nome = props.nome;
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({ showModal: false });

    const fetchData = () => {
        setParam({ showModal: false })

        apiHelper.get(`${endPoint}?filter=(;;Nome::contains::${nome};;OR;;NomeCliente::contains::${nome};;);;`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [parentId]);

    const CarregarRevisaoSelecionada = (item) => {
        alert("Teste");
    }

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Nome</th>
                            <th>Status</th>
                            <th>Data Cadastro</th>
                            <th>Selecionar</th>
                            {data.find(c => c.motivoReprovacao != null) && <th>Motivo</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.nome}</td>
                                        <td>{item.status}</td>
                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                        <td><button type="button" className="btn btn-primary btn-xs" onClick={() => CarregarRevisaoSelecionada(item)}><i className="fa fa-filter"></i></button></td>
                                        {item.motivoReprovacao != null && <td>{item.motivoReprovacao}</td>}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
