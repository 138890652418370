import React, { useState, useEffect } from 'react';
import { config } from '../..';

export function Table(props) {
    const { data, size = "sm", bordered = false, striped = true } = props;
    const configReport = props.config;
    const header = [];
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: props.config.pageSize ? props.config.pageSize : 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: props.config.pageSize ? props.config.pageSize : 10
    });

    if (data && data.length > 0) {
        Object.keys(data[0]).forEach((key) => {
            header.push(key);
        });
    }

    const fetchData = () => {
        setParam({
            ...params,
            pageSize: props.config.pageSize ? props.config.pageSize : 10,
            endRow: props.config.pageSize ? props.config.pageSize : 10
        });
    }

    useEffect(fetchData, [data]);

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <table className={`table table-${size}${bordered ? " table-bordered" : ""}${striped ? " table-striped" : ""} m-0`}>
            <thead>
                <tr className="bg-light-blue">
                    {header.map((row, i) =>
                        <th key={i}>{config.capitalize(row)}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {data && (configReport.isPaged ?
                    (data.slice(params.initRow, params.endRow).map((item, i) =>
                        <tr key={i}>
                            {header.map((key, j) => <td key={j} className="text-sm">{config.formatField(item[key])}</td>)}
                        </tr>
                    ))
                    :
                    (data.map((item, i) =>
                        <tr key={i}>
                            {header.map((key, j) => <td key={j} className="text-sm">{config.formatField(item[key])}</td>)}
                        </tr>
                    )))
                }
            </tbody>
            {configReport.isPaged && <tfoot>
                <tr>
                    <td colSpan={header.length} className="p-1">
                        {renderPage()}
                    </td>
                </tr>
            </tfoot>}
        </table>
    )
}