import React, { useState } from 'react';
import { Content, Link, config, apiHelper, fileHelper, Select, toast } from '../';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function OS(props) {
    const endPointRelatorioOs = "portal/crud/uvw_Relatorio_OS";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        titleFiltro: '',
        status: '',
        clienteSearch: '',
        searchValuePrestador: '',
        dataInicioAberturaOS: '',
        dataFimAberturaOS: '',
        dataInicioConclusaoOS: '',
        dataFimConclusaoOS: '',
        searchValueTecnico: '',
        regional: '',
        searchStatus: '',
        groupCode: '',
        subGroupClientCode: '',
        searchValueCliente: '',
        siteSearch: '',
        usuarioConcluiuOs: '',
        codeCliente: ''
    });
    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowLoading(true);
        let query = montarQuery();

        apiHelper.get(`${endPointRelatorioOs}?filter=${query}`)
            .then(res => {
                setData(res.data.data);
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            status: '',
            clienteSearch: '',
            searchValuePrestador: '',
            dataInicioAberturaOS: '',
            dataFimAberturaOS: '',
            dataInicioConclusaoOS: '',
            dataFimConclusaoOS: '',
            searchValueTecnico: '',
            regional: '',
            searchStatus: '',
            groupCode: '',
            subGroupClientCode: '',
            searchValueCliente: '',
            siteSearch: '',
            usuarioConcluiuOs: '',
            codeCliente: ''
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const montarQuery = () => {
        let parametros = "";
        let tecnico = filter.searchValueTecnico === '' ? '' : `IdUsuarioAcionamento::equal::${filter.searchValueTecnico};;AND;;`;
        let cliente = filter.searchValueCliente === '' ? '' : `IdCliente::equal::${filter.searchValueCliente};;AND;;`;
        let site = filter.siteSearch === '' ? '' : `IdSite::equal::${filter.siteSearch};;AND;;`;
        let regional = filter.regional === '' ? '' : `IdRegional::equal::${filter.regional};;AND;;`;
        let prestador = filter.searchValuePrestador === '' ? '' : `IdUsuarioAcionamento::equal::${filter.searchValuePrestador};;AND;;`;
        let status = filter.searchStatus === '' ? '' : filter.searchStatus === "-1" ? 'IdStatus::in::1,2,3;;AND;;' : `IdStatus::equal::${filter.searchStatus};;AND;;`;
        let grupo = filter.groupCode === '' ? '' : `(;;IdGrupoAbertura::equal::${filter.groupCode};;OR;;IdGrupoConclusao::equal::${filter.groupCode};;);;AND;;`;
        let subGrupo = filter.subGroupClientCode === '' ? '' : `(;;IdSubGrupoAbertura::equal::${filter.subGroupClientCode};;OR;;IdSubGrupoConclusao::equal::${filter.subGroupClientCode};;);;AND;;`;
        let dataAberturaOS = filter.dataInicioAberturaOS === '' ? '' : `DataAbertura::greaterEqual::${filter.dataInicioAberturaOS};;AND;;DataAbertura::lessEqual::${filter.dataFimAberturaOS} 23:59:59;;AND;;`;
        let dataConclusaoOS = filter.dataInicioConclusaoOS === '' ? '' : `DataConclusao::greaterEqual::${filter.dataInicioConclusaoOS};;AND;;DataConclusao::lessEqual::${filter.dataFimConclusaoOS} 23:59:59;;AND;;`;
        let usuarioConcluiuOs = filter.usuarioConcluiuOs === '' ? '' : `IdUsuarioConcluiuOs::equal::${filter.usuarioConcluiuOs};;`;

        return parametros.concat(tecnico, cliente, site, regional, prestador, status, grupo, subGrupo, dataAberturaOS, dataConclusaoOS, usuarioConcluiuOs)
    }

    const handleOrdenarColunas = (e, nomeVariavelColuna) => {
        e.preventDefault();
        let sortedObjs = config.ordenarColunas(data, nomeVariavelColuna);
        setData(sortedObjs);
    }

    return (
        <Content title="Relatórios de OS" browserTitle="Relatórios de OS">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label>Técnico</label>
                                <Select id="searchValueTecnico" className="form-control" onChange={handleChange} value={filter.searchValueTecnico}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=Tecnico" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="searchValueCliente" className="form-control" onChange={handleChange} value={filter.searchValueCliente}>
                                    <option value="">Todos</option>
                                    <option endpoint="siga/serviceOrder/client/" valuefield="clientCode" textfield="name" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Site</label>
                                <Select id="siteSearch" className="form-control" onChange={handleChange} value={filter.siteSearch} refresh={filter.searchValueCliente}>
                                    <option value="">Todos</option>
                                    <option endpoint={filter.searchValueCliente !== ''?`siga/serviceOrder/${filter.searchValueCliente}/site`:''} valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Usuário Conclusão OS</label>
                                <Select id="usuarioConcluiuOs" className="form-control" onChange={handleChange} value={filter.usuarioConcluiuOs}>
                                    <option value="">Todos</option>
                                    <option endpoint="portal/crud/uvw_UsuariosConcluiramOs" valuefield="Id" textfield="Nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Regional</label>
                                <Select id="regional" className="form-control" onChange={handleChange} value={filter.regional}>
                                    <option key="" value="">Todos</option>
                                    <option value="2">Operações MG</option>
                                    <option value="3">Operações RJ</option>
                                    <option value="4">Operações SP</option>
                                    <option value="5">Operações N-NE</option>
                                    <option value="6">Operações DETRAN-RJ</option>
                                    <option value="7">Operações TI</option>
                                    <option value="8">Operações CSO Global</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Prestador</label>
                                <Select id="searchValuePrestador" className="form-control" onChange={handleChange} value={filter.searchValuePrestador}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=Prestador" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Grupo</label>
                                <Select id="groupCode" className="form-control" onChange={handleChange} value={filter.groupCode}>
                                    <option value="">Todos</option>
                                    <option endpoint="portal/crud/Grupo?filter=isExcluido::equal::0;;" valuefield="pkGrupo" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Sub Grupo</label>
                                <Select id="subGroupClientCode" className="form-control" onChange={handleChange} value={filter.subGroupClientCode} refresh={filter.groupCode}>
                                    <option key="" value="">Todos</option>
                                    <option endpoint={`portal/crud/SubGrupo?filter=fkGrupo::equal::${filter.groupCode}`} valuefield="pkSubGrupo" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <Select id="searchStatus" className="form-control" onChange={handleChange} value={filter.searchStatus}>
                                    <option value="">Todos</option>
                                    <option value="-1">Abertas</option>
                                    <option value="1">Nova</option>
                                    <option value="2">Em Andamento</option>
                                    <option value="3">Pendência do Cliente</option>
                                    <option value="4">Concluida</option>
                                    <option value="5">Finalizada</option>
                                </Select>
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataInicioAberturaOS"} col={12} showHora={false} labelData={"Data Início Abertura OS"} valueData={filter.dataInicioAberturaOS} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataFimAberturaOS"} col={12} showHora={false} labelData={"Data Fim Abertura OS"} valueData={filter.dataFimAberturaOS} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataInicioConclusaoOS"} col={12} showHora={false} labelData={"Data Início Conclusão OS"} valueData={filter.dataInicioConclusaoOS} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataFimConclusaoOS"} col={12} showHora={false} labelData={"Data Fim Conclusão OS"} valueData={filter.dataFimConclusaoOS} onSelectValue={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Relatórios de OS</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => fileHelper.exportToExcel(montarQuery(), 'uvw_Relatorio_OS','RelatorioOS_' + moment().format("DD/MM/YYYY"))}><i class="fa fa-file-excel-o"></i></button>
                                </div>
                            </div>
                            <div className="box-body table-responsive no-padding">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'os')}><i className="fa fa-sort" />OS</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'titulo')}><i className="fa fa-sort" />Titulo</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'tecnico_Prestador')}><i className="fa fa-sort" />Técnico/Prestador</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'cliente')}><i className="fa fa-sort" />Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'site')}><i className="fa fa-sort" />Site</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'grupoAbertura')}><i className="fa fa-sort" />Grupo Abertura</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'subGrupoAbertura')}><i className="fa fa-sort" />SubGrupo Abertura</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'grupoConclusao')}><i className="fa fa-sort" />Grupo Conclusão</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'subGrupoConclusao')}><i className="fa fa-sort" />SubGrupo Conclusão</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'responsavel')}><i className="fa fa-sort" />Responsável</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'descricao')}><i className="fa fa-sort" />Descrição</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'manutencaoExecutada')}><i className="fa fa-sort" />Manutenção Executada</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataAbertura')}><i className="fa fa-sort" />Data de Abertura</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataConclusao')}><i className="fa fa-sort" />Data Conclusão</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'sLANivel1')}><i className="fa fa-sort" />SLA Nivel1</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'sLANivel2')}><i className="fa fa-sort" />SLA Nivel2</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'sLANivel3')}><i className="fa fa-sort" />SLA Nivel3</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'status')}><i className="fa fa-sort" />Status</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'regional')}><i className="fa fa-sort" />Regional</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'uf')}><i className="fa fa-sort" />Uf</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'usuarioConcluiuOs')}><i className="fa fa-sort" />Usuario_ConcluiuOS</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'totalTempoDecorrido')}><i className="fa fa-sort" />Tempo Decorrido</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><Link to={`/atendimento/${item.os}`}>{item.os}</Link></td>
                                                        <td>{item.titulo}</td>
                                                        <td>{item.tecnico_Prestador}</td>
                                                        <td>{item.cliente}</td>
                                                        <td>{item.site}</td>
                                                        <td>{item.grupoAbertura}</td>
                                                        <td>{item.subGrupoAbertura}</td>
                                                        <td>{item.grupoConclusao}</td>
                                                        <td>{item.subGrupoConclusao}</td>
                                                        <td>{item.responsavel}</td>
                                                        <td>{item.descricao}</td>
                                                        <td>{item.manutencaoExecutada}</td>
                                                        <td>{config.dateFix(item.dataAbertura)}</td>
                                                        <td>{config.dateFix(item.dataConclusao)}</td>
                                                        <td>{item.sLANivel1}</td>
                                                        <td>{item.sLANivel2}</td>
                                                        <td>{item.sLANivel3}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.regional}</td>
                                                        <td>{item.uf}</td>
                                                        <td>{item.usuarioConcluiuOs}</td>
                                                        <td>{item.totalTempoDecorrido}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
