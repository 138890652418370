import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, toast, fileHelper, Select } from '../';
import { OrdemServicoForm } from './OrdemServicoForm';

export function OrdemServico(props) {
    let where = '';
    const endPoint = 'v3/os/';
    const [data, setData] = useState([]);

    const [filter, setFilter] = useState({
        id: '',
        dataCadastro: '',
        nova: true,
        andamento: true,
        pendenciaCliente: true,
        concluida: false,
        finalizada: false,
        resumo: '',
        clienteId: '',
        siteId: '',
        uf: '',
        criador: '',
        tecnico: '',
        nivelSLA: '',
        codigoExternoCliente: '',
        regionalId: '',
        dataCadastroInicio: '',
        dataCadastroFim: '',
        tipoChamadoId: '',
        grupo: ''
    });

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        siteId: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 1,
        endRow: 10
    });

    const fetchData = (where, page = 0, init = 1, end = 10) => {
        setData([]);

        try {
            apiHelper.get(`${endPoint}/pages?filter=${where}&initRow=${init}&endRow=${end}`)
                .then(res => {
                    setData(res.data.data);
                    setParam({
                        ...params,
                        rowId: 0,
                        showModal: false,
                        modalTitle: "",
                        formName: "",
                        siteId: "",
                        totalPages: res.data.totalRegistros,
                        selectedPage: page,
                        initRow: init,
                        endRow: end,
                    });
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    };

    useEffect(() => {
        const onLoad = () => {
            config.validarPermissao("atendimento.listar");
            handleSearch();
        };

        onLoad();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    };

    const handleShowForm = (name, title, rowId, siteId = '') => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name,
            siteId: siteId
        });
    };

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: "",
            siteId: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            dataCadastro: '',
            nova: true,
            andamento: true,
            pendenciaCliente: true,
            concluida: false,
            finalizada: false,
            resumo: '',
            clienteId: '',
            siteId: '',
            criador: '',
            tecnico: '',
            nivelSLA: '',
            codigoExternoCliente: '',
            regionalId: '',
            dataCadastroInicio: '',
            dataCadastroFim: '',
            tipoChamadoId: '',
            grupo: ''
        });

        fetchData();
    };

    const handleSearch = () => {
        where = '';
        let status2 = [];

        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'resumo' || key === 'criador' || (key === 'tecnico'))
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'nova') {
                status2.push(1);
            }
            else if (key === 'andamento') {
                status2.push(2);
            }
            else if (key === 'pendenciaCliente') {
                status2.push(3);
            }
            else if (key === 'concluida') {
                status2.push(4);
            }
            else if (key === 'finalizada') {
                status2.push(5);
            }
            else if (key === 'dataCadastroInicio') {
                where += `${'dataCadastro'}::greaterEqual::${value2};;AND;;${'dataCadastro'}::lessEqual::${filter.dataCadastroFim} 23:59:59;;AND;;`;
            }
            else if (key !== 'dataCadastroFim')
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        if (status2.length > 0) {
            where += 'statusId::in::' + status2.join(",");
        }

        fetchData(where);
    }

    const setPage = (page) => {
        if (!page || page < 0) page = 0;
        if (page === params.totalPages) page = params.totalPages;

        let init = (page * params.pageSize) + 1;
        let end = (page * params.pageSize) + params.pageSize;

        fetchData(where, page, init, end);
    };

    const renderPage = () => {
        const pages = Math.ceil(params.totalPages / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + ' de ' + pages + ' - ' + params.totalPages + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const HandleExcel = () => {
        try {
            apiHelper.get(`${endPoint}/pages?filter=${where}&initRow=${1}&endRow=${params.totalPages}`)
                .then(res => {
                    let dataExcel = [];

                    res.data.data && res.data.data.map((item, i) =>
                        dataExcel.push({
                            'OS': item.id,
                            'Data': config.dateTimeFix(item.dataCadastro),
                            'Data Conclusão': (config.dateTimeFix(item.dataConclusao) === '01/01/1900 00:00:00') ? '' : config.dateTimeFix(item.dataConclusao),
                            'Status': item.status,
                            'Nível': item.nivelSLA === 0 ? '' : item.nivelSLA,
                            'SLA': `${item.tempoDecorridoHHMM} de ${item.nivelSLACorrente}h{${Math.round(item.percentualSLACorrente)}%}`,
                            'Resumo': item.resumo,
                            'Cliente': item.cliente,
                            'Site': item.site,
                            'UF': item.uf,
                            'Criador': item.criador,
                            'Responsável Despacho': item.despacho,
                            'Técnico': item.tecnico,
                            'Cód. Externo Cliente': item.codigoExternoCliente,
                            'Regional': item.regional,
                            'TipoChamado': item.tipoChamado,
                            'Grupo': item.grupo,
                            'Sub Grupo': item.subGrupo,
                        })
                    );

                    fileHelper.exportToCSV(dataExcel, 'Ordem de Serviço');
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    }

    const getPercent = (value) => {
        let color = "";

        if (value < 40) color = 'badge-success';
        else if (value < 70) color = 'badge-primary';
        else if (value < 99) color = 'badge-warning';
        else color = 'badge-danger';

        return color;
    };

    return (
        <Content title="Ordem de Serviço" browserTitle="Ordem de Serviço">
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block mb-3" onClick={() => handleShowForm("new", "Abrir OS", 0)} data-toggle="modal" data-target="#modal-atendimento">Nova OS</button>

                    {params.showModal === true &&
                        <div className="modal" tabIndex="-1" role="dialog" id="modal-atendimento" data-backdrop="false">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-light">
                                        <h5 className="modal-title">Nova OS</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <OrdemServicoForm onHide={handleHide} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Status</h3>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="nova" onChange={handleChange} checked={filter.nova ? filter.nova : false} />
                                    <label className="form-check-label" htmlFor="nova">Nova</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="andamento" onChange={handleChange} checked={filter.andamento ? filter.andamento : false} />
                                    <label className="form-check-label" htmlFor="andamento">Em andamento</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="pendenciaCliente" onChange={handleChange} checked={filter.pendenciaCliente ? filter.pendenciaCliente : false} />
                                    <label className="form-check-label" htmlFor="pendenciaCliente">Pendencia do cliente</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="concluida" onChange={handleChange} checked={filter.concluida ? filter.concluida : false} />
                                    <label className="form-check-label" htmlFor="concluida">Concluída</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="finalizada" onChange={handleChange} checked={filter.finalizada ? filter.finalizada : false} />
                                    <label className="form-check-label" htmlFor="finalizada">Finalizada</label>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer p-2">
                            <button type="button" className="btn btn-default btn-sm" onClick={handleRefresh}>Limpar</button>
                            <button type="button" className="btn btn-primary btn-sm float-right" onClick={handleSearch}>Filtrar</button>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Pesquisa</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label>Início</label>
                                            <input id="dataCadastroInicio" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastroInicio} />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Fim</label>
                                            <input id="dataCadastroFim" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastroFim} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Nível</label>
                                            <select id="nivelSLA" className="form-control input-sm" onChange={handleChange} value={filter.nivelSLA}>
                                                <option key="" value="">Todos</option>
                                                <option value="1">Nível 1</option>
                                                <option value="2">Nível 2</option>
                                                <option value="3">Nível 3</option>
                                                <option value="4">Solicitação</option>
                                                <option value="5">Preventiva</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>UF</label>
                                            <Select id="uf" className="form-control" onChange={handleChange} value={filter.uf}>
                                                <option value="">Todos</option>
                                                <option endpoint="portal/crud/UF" valuefield="sigla" textfield="sigla" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Cliente</label>
                                            <Select id="clienteId" className="form-control input-sm" onChange={handleChange} value={filter.clienteId}>
                                                <option value="">Todos</option>
                                                <option endpoint={`${endPoint}clientes`} valuefield="id" textfield="nome" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Site</label>
                                            <Select id="siteId" className="form-control input-sm" onChange={handleChange} value={filter.siteId} refresh={filter.clienteId}>
                                                <option value="">Todos</option>
                                                <option endpoint={filter.clienteId !== '' ? `${endPoint}clientes/${filter.clienteId}/sites` : ''} valuefield="id" textfield="nome" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Regional</label>
                                            <Select id="regionalId" className="form-control input-sm" onChange={handleChange} value={filter.regionalId}>
                                                <option value="">Todos</option>
                                                <option endpoint={`${endPoint}/regional`} valuefield="pkRegional" textfield="nome" />
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer p-2">
                            <button type="button" className="btn btn-default btn-sm" onClick={handleRefresh}>Limpar</button>
                            <button type="button" className="btn btn-primary btn-sm float-right" onClick={handleSearch}>Pesquisar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card card-primary card-outline">
                        <div className="card-header">
                            <h3 className="card-title">Ordem de Serviço</h3>
                            <div className="card-tools">
                                <div className="input-group input-group-sm">
                                    <input id="id" value={filter.id} type="number" className="form-control" onChange={handleChange} placeholder="Pesquisa de OS" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" onClick={handleSearch}><i className="fas fa-search" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="mailbox-controls">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => HandleExcel()}><i className="fas fa-file-excel" /> Exportar</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => handleRefresh()}><i className="fas fa-sync-alt" /></button>
                                </div>
                                <div className="float-right">
                                    {renderPage()}
                                </div>
                            </div>
                            <div className="table-responsive mailbox-messages">
                                <table className="table table-striped">
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id)
                                            .map((item, i) => {
                                                let title = `${item.tempoDecorridoHHMM} de ${item.nivelSLACorrente}h (${Math.round(item.percentualSLACorrente)}%)`;

                                                return (
                                                    <tr key={i}>
                                                        <td className="p-2">
                                                            <Link to="#" className="product-title text-uppercase" onClick={() => props.onSelect(item.id)}>
                                                                {item.uf} - {item.site}
                                                            </Link>
                                                            <div className="product-description">{item.resumo || "-"}</div>
                                                        </td>
                                                        <td className="p-2">
                                                            <div className="product-description text-sm">{config.dateTimeFix(item.dataCadastro)}</div>
                                                            <span className={`badge ${getPercent(item.percentualSLACorrente)}`}>{title}</span>
                                                        </td>
                                                        <td className="p-2">
                                                            <span className={`text-bold float-right`}>Nº {item.id}</span>
                                                            <br />
                                                            <span className="float-right text-bold1">{item.status}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
