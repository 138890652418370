import React from 'react';
import { authService, config } from './';
import { AdminLTE3 } from './adminlte3';
import Modules from './modules';

export function Portal(props) {
    const getMenu = () => {
        const claims = config.getClaims();
        const items = [];

        Modules.map(module => items.push(...module.menu))

        return items.filter(f => claims.includes(f.claim)).map(m => {
            if (m.children)
                m.children = m.children.filter(f => claims.includes(f.claim));

            return m;
        });
    }

    const logout = () => authService.logout();

    return (
        <AdminLTE3 menu={getMenu()} logout={logout}>
            {Modules.map((module, i) =>
                React.cloneElement(module.control, { key: i })
            )}
        </AdminLTE3>
    );
}
