import React, { useState, useEffect } from 'react';
import { apiHelper, config, toast } from '../';
import ReportContainer from './ReportContainer';

export function Report(props) {
    const reportId = props.id || props.match.params.id;
    const [report, setReport] = useState({});

    const fetchData = () => {
        apiHelper.get(`v2/report/${reportId}/${config.idUsuarioLogado()}`)
            .then(res => {
                if (res.data.data === null) {
                    window.location.href = '#/erro/';
                    return;
                }
                setReport(res.data.data);
            }).catch(error => {
                toast.error('Não foi possível encontrar o relatório!');
            });
    }

    useEffect(fetchData, [reportId]);

    return (
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">{report.titulo}</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                </div>
            </div>
            <div className="card-body p-1">
                <ReportContainer reportId={reportId} showBox={true} />
            </div>
        </div>
    )
}