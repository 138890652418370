import React, { useState, useEffect } from 'react';
import { Link, Content, apiHelper } from '../';
import { RegionalUsuario } from './RegionalUsuario';

export function RegionalView(props) {
    const endPoint = 'v2/regional/';
    const rowId = props.match.params.id;
    const [row, setRow] = useState({});
    const [aprovadores, setAprovadores] = useState([]);

    const fetchData = () => {

        apiHelper.get(`${endPoint}?filter=pkRegional::equal::${rowId}`)
            .then(res => {
                setRow(res.data.data[0]);
                let app = res.data.data[0].aprovadores.replace("[", "").replace("]", "");
                apiHelper.get(`portal/crud/usuario?filter=pkUsuario::in::${app}`)
                    .then(info => {
                        setAprovadores(info.data.data);
                    });
            });
    }

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, [props.parentId]);

    return (
        <Content title="Regional" browserTitle="Regional">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{rowId + ' - ' + row.nome}</h3>
                        </div>
                        <div className="box-body">
                            <strong><i className="fa fa-book margin-r-5"></i> Gerente</strong>
                            <p className="text-muted">{row.gerenteRegional}</p>
                            <hr />
                            <strong><i className="fa fa-book margin-r-5"></i> Aprovadores de Material</strong>
                            {(aprovadores && aprovadores.length > 0) ? aprovadores.map((item, i) => {
                                return (<p className="text-muted" key={i}>{item.nome}</p>);
                            } 
                            ) : <p className="text-muted">Nenhum aprovador associado</p>}
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_Usuario" data-toggle="tab">Usuários</a></li>
                            <li className="pull-right"><Link to="/regional/" className="text-muted"><i className="fa fa-times" /></Link></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_Usuario" className="tab-pane active">
                                <RegionalUsuario parentId={rowId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
