import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Link } from '../index';

export default function AprovacaoRequisicaoMaterial(props) {
    const endPoint = 'portal/crud/uvw_v2_requisicaoMaterial';
    const endPointRequisicaoMaterial = 'suprimentos/rm/enviarRequisicao';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        solicitante: '',
        cliente: '',
        site: '',
        centroCusto: '',
        dataNecessidade: '',
        dataRequisicao: '',
        origemId: '',
    });

    const [params, setParam] = useState({
        rowId: 0,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "Status::equal::Em Aprovação;;";
        else
            where = `Status::equal::Em Aprovação;;And;;${where}`;

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'solicitante' || key === 'cliente' || key === 'site' || key === 'centroCusto')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            solicitante: '',
            cliente: '',
            site: '',
            centroCusto: '',
            dataNecessidade: '',
            dataRequisicao: '',
            origemId: ''
        });

        setData([]);
        fetchData();
    };

    const handleAprovarMaterial = (item) => {
        apiHelper.put(`${endPointRequisicaoMaterial}/${item.id}`)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const handleReprovarMaterial = (item) => {
        if (!window.confirm(`Deseja realmente reprovar a requisição ${item.id}?`))
            return;

        let data = {
            status: 'Reprovado'
        }
        apiHelper.put(`suprimentos/rm/cancelarRequisicao/${item.id}`, data)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <div className="tab-content">
                        <div id="tab_grid" className="tab-pane active">
                            <div style={{ marginBottom: "7px" }}>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>OS</label>
                                                                <input id="origemId" type="text" className="form-control input-sm" onChange={handleChange} value={filter.origemId} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>ID</label>
                                                                <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Solicitante</label>
                                                                <input id="solicitante" type="text" className="form-control input-sm" onChange={handleChange} value={filter.solicitante} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Cliente</label>
                                                                <input id="cliente" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cliente} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Site</label>
                                                                <input id="site" type="text" className="form-control input-sm" onChange={handleChange} value={filter.site} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Centro de custo</label>
                                                                <input id="centroCusto" type="text" className="form-control input-sm" onChange={handleChange} value={filter.centroCusto} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Data Necessidade</label>
                                                                <input id="dataNecessidade" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataNecessidade} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Data Requisição</label>
                                                                <input id="dataRequisicao" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataRequisicao} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th style={{ width: "120px" }}>OS</th>
                                            <th>ID</th>
                                            <th>Solicitante</th>
                                            <th>Cliente</th>
                                            <th>Site</th>
                                            <th>Centro de Custo</th>
                                            <th>Data Necessidade</th>
                                            <th>Data Requisição</th>
                                            {config.validarClaim('AprovarMaterial') && <th>Ação</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><Link to={'/atendimento/' + item.origemId}>{item.origemId}</Link></td>
                                                        <td>{item.id}</td>
                                                        <td>{item.solicitante}</td>
                                                        <td>{item.cliente}</td>
                                                        <td>{item.site}</td>
                                                        <td>{item.centroCusto}</td>
                                                        <td>{config.dateFix(item.dataNecessidade)}</td>
                                                        <td>{config.dateFix(item.dataRequisicao)}</td>
                                                        {config.validarClaim('atendimento.rm.aprovar') && <td className="text-center">
                                                            <div>
                                                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleAprovarMaterial(item)}><i className="fa fa-thumbs-o-up" /></button>
                                                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleReprovarMaterial(item)}><i className="fa fa-thumbs-o-down" /></button>
                                                            </div>
                                                        </td>}
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
