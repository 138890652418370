import React from 'react';
import ReactSelect from 'react-select';
import { apiHelper, toast } from '../';

export function DashboardForm(props) {
    const endPoint = 'portal/crud/ReportsDashboard';
    const rowId = props.parentId || 0;

    const [data, setData] = React.useState({});
    const [grupos, setGrupos] = React.useState({});
    const [defaultGrupos, setDefaultGrupos] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            let res = await apiHelper.get(`${endPoint}/${rowId}/id`)
            setData(res.data.data)
        }

        setData({});
        rowId > 0 && fetchData();
    }, [rowId]);

    React.useEffect(() => {
        const fetchData = () => {
            let groups = [];
            apiHelper.get('portal/crud/IdentityGroup').then(resp => {
                resp.data.data.map((item) =>
                    groups.push({ value: item.id, label: item.name })
                );
                setGrupos(groups);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }

        fetchData();
    }, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setData({ ...data, [name]: value });
    }

    const handleChangeGrupo = (e) => {
        setDefaultGrupos(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const usuarioLogadoId = localStorage.getItem('claims') !== null
            && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

        let obj = {
            ...data,
            groups: defaultGrupos.length > 0 ? defaultGrupos.map(item => item.value) : "",
            creator_id: usuarioLogadoId,
            //config: JSON.stringify(data.config)
        };

        if (data.id)
            apiHelper.put(`${endPoint}/${data.id}/id`, obj).then(res => {
                if (res.data.code === 200) {
                    toast.success("Dashboard salvo com sucesso!");
                    props.onHide(true);
                }
                else {
                    toast.error('Atenção, não foi possível salvar o registro.');
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        else
            apiHelper.post(`${endPoint}`, obj).then(res => {
                if (res.data.code === 200) {
                    toast.success("Dashboard salvo com sucesso!");
                    props.onHide(true);
                }
                else {
                    toast.error('Atenção, não foi possível salvar o registro.');
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="card">
                <div className="card-body p-2">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Nome *</label>
                                <input id="name" type="text" className="form-control" onChange={handleChange} value={data.name || ""} required />
                            </div>
                            <div className="form-group">
                                <label>Titulo *</label>
                                <input id="title" type="text" className="form-control" onChange={handleChange} value={data.title || ""} required />
                            </div>
                            <div className="form-group">
                                <label>Icone</label>
                                <input id="icon" type="text" className="form-control" onChange={handleChange} value={data.icon || ""} />
                            </div>
                            <div className="form-group">
                                <label>Módulo</label>
                                <input id="path" type="text" className="form-control" onChange={handleChange} value={data.path || ""} />
                            </div>
                            <div className="form-group">
                                <label>Grupo</label>
                                <ReactSelect isMulti name="colors" id="idsGrupo"
                                    value={(defaultGrupos && defaultGrupos.length > 0) && defaultGrupos}
                                    options={grupos}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleChangeGrupo}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Configuração</label>
                                <textarea id="config" rows={8} className="form-control" onChange={handleChange} value={data.config || ""} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer p-2">
                    <button type="button" className="btn btn-default float-right ml-1" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary float-right ml-1">Salvar</button>
                </div>
            </div>
        </form>
    )
}