import React, { useState, useEffect } from 'react';
import { Content, apiHelper, config, Link, toast, Select } from '../';
import { OrdemServicoConcluir } from './OrdemServicoConcluir';
import { OrdemServicoReabrir } from './OrdemServicoReabrir';
import { Interacao } from './Interacao';
import { Acidente } from './Acidente';
import { Acionamento } from './Acionamento';
import { RequisicaoMaterial } from './RequisicaoMaterial';
import { Historico } from './Historico';
import { Rat } from '../atendimento/Rat';

export function OrdemServicoView(props) {
    const endPoint = 'v2/os/';
    const endPointV3 = 'v3/os/';
    const rowId = props.match.params.id;

    const [data, setData] = useState({});
    const [regional, setRegional] = useState({});
    const [tipoChamadoId, setTipoChamadoId] = useState({});
    const [isAcionamento, setIsAcionamento] = useState(false);
    const [params, setParam] = useState({ refresh: false });
    const [dataPrestador, setDataPrestador] = useState({});
    const [isManutencao, setIsManutencao] = useState(false);
    const [form, setForm] = useState({});

    const handleEditou = (e) => {
        const formKeys = Object.keys(form);
        return formKeys.some((key) => form[key] && form[key] !== data[key]);
    }

    const handleSalvar = () => {
        const formKeys = Object.keys(form);
        if (!handleEditou)
            return;
        const changedKeys = formKeys.filter((key) => form[key] && form[key] !== data[key]);

        const obj = {};
        changedKeys.forEach((key) => {
            if (key === 'resumo')
                obj.titulo = form.resumo;
            else
                obj[key] = form[key];
        });

        apiHelper.put(`${endPoint}${rowId}/editarAtendimento/`, obj)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                    setParam({
                        ...params,
                        refresh: !params.refresh
                    });
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    };

    const changeCampoEditavel = (e, name, length) => {
        let value = e.target.innerText;
        if (value) {
            if (value.length > length)
                setForm({ ...form, [name]: value.slice(0, length) });
            else
                setForm({ ...form, [name]: value });
        }
        else
            setForm({ ...form, [name]: data[name] });
    };

    const setVazio = (e, name) => {
        let value = e.target.innerText;
        if (value === data[name])
            setForm({ ...form, [name]: '' });
    };

    const fetchData = () => {
        config.validarPermissao("atendimento.listar");
        apiHelper.get(`${endPointV3}/${rowId}`).then(res => {
            let data = res.data;

            if (res.data) {
                //portal/crud/tipochamado/

                setIsAcionamento(data.regionalId > 0);
                if (data.tipoChamadoId === 4) {
                    setIsManutencao(true);

                    if (data.dataManutencaoPrevista !== null)
                        data.dataManutencaoPrevista = `${data.dataManutencaoPrevista.split('-')[0]}-${data.dataManutencaoPrevista.split('-')[1]}-${data.dataManutencaoPrevista.split('-')[2].substr(0, 2)}`;

                    if (data.dataManutencaoRealizada !== null)
                        data.dataManutencaoRealizada = `${data.dataManutencaoRealizada.split('-')[0]}-${data.dataManutencaoRealizada.split('-')[1]}-${data.dataManutencaoRealizada.split('-')[2].substr(0, 2)}`;

                }
                else
                    setIsManutencao(false);
            } else { window.location.href = '#/erro/'; }

            if (res.data.tipoChamadoId)
                apiHelper.get(`portal/crud/tipochamado?filter=id::equal::${res.data.tipoChamadoId}`).then(res => {
                    data.tipoChamado = res.data.data[0].nome;
                    setData(data);
                    setForm({ descricao: data.descricao, resumo: data.resumo, contatoCliente: data.contatoCliente });
                });
            else {
                setData(data);
                setForm({ descricao: data.descricao, resumo: data.resumo, contatoCliente: data.contatoCliente });
            }


        });
        obterEnderecoPrestador();
    }

    const obterEnderecoPrestador = () => {
        apiHelper.get(`${endPointV3}/${rowId}/prestador`).then(res => {
            let data = res.data.data
            setDataPrestador(data);
        });
    }

    const obterSLA = () => {
        let color = '';

        if (data.percentualSLACorrente < 40)
            color = 'label-success';
        else if (data.percentualSLACorrente < 70)
            color = 'label-primary';
        else if (data.percentualSLACorrente < 99)
            color = 'label-warning';
        else
            color = 'label-danger';

        return color;
    }

    useEffect(fetchData, [rowId]);

    const handleAtualizarRegional = (e) => {
        let value = e.target["value"];
        let name = e.target.id;
        setRegional({ ...regional, [name]: value });
        setData({ ...data, [name]: value });
    }

    const handleAtualizarTipoChamado = (e) => {
        let value = e.target["value"];
        let name = e.target.id;
        setTipoChamadoId({ ...tipoChamadoId, [name]: value });

        if (value !== '4') {
            setIsManutencao(false);
            setData({ ...data, dataManutencaoPrevista: '', dataManutencaoRealizada: '' });
        }
        setData({ ...data, [name]: value });
    }

    const handleAtualizarDataManutencao = (e) => {
        let value = e.target["value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handeSubmit = () => {
        if (Object.keys(regional).length !== 0)
            apiHelper.put(`${endPoint}${rowId}/regional/`, regional)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        fetchData();
                        setParam({
                            ...params,
                            refresh: !params.refresh
                        });
                        setIsAcionamento(true);
                    }
                    else
                        toast.error('Atenção, não foi possível executar esta operação!');
                }).catch(error => {
                    toast.error('Atenção, não foi possível executar esta operação!');
                });
    }

    const handeSubmitTipoChamado = () => {
        if (Object.keys(tipoChamadoId).length !== 0)
            apiHelper.put(`${endPoint}${rowId}/tipochamado/`, tipoChamadoId)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        fetchData();
                        setParam({
                            ...params,
                            refresh: !params.refresh
                        });
                        setIsAcionamento(true);
                        if (tipoChamadoId.tipoChamadoId === '4')
                            setIsManutencao(true);
                    }
                    else
                        toast.error('Atenção, não foi possível executar esta operação!');
                }).catch(error => {
                    toast.error('Atenção, não foi possível executar esta operação!');
                });
    }

    const handleSubmitDataManutencaoPrevista = () => {

        let obj = {
            dataManutencaoPrevista: data.dataManutencaoPrevista
        }
        apiHelper.put(`${endPoint}${rowId}/manutencaoPrevista`, obj)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                    setParam({
                        ...params,
                        refresh: !params.refresh
                    });

                    if (tipoChamadoId.tipoChamadoId === '4')
                        setIsManutencao(true);
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleSubmitDataManutencaoRealizada = () => {

        let obj = {
            dataManutencaoRealizada: data.dataManutencaoRealizada
        }
        apiHelper.put(`${endPoint}${rowId}/manutencaoRealizada`, obj)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                    setParam({
                        ...params,
                        refresh: !params.refresh
                    });

                    if (tipoChamadoId.tipoChamadoId === '4')
                        setIsManutencao(true);
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleHide = () => {
        fetchData();
        setParam({
            ...params,
            refresh: !params.refresh
        });
    };

    return (
        data && <Content title="Ordem de Serviço" bdataserTitle="Ordem de Serviço">
            <div className="row">
                <div className="col-md-3 no-printme">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">OS {data && data.id}</h3>
                            <div className="pull-right">
                                <label className={`${obterSLA()}`} style={{ margin: 0 }}>{`${data.tempoDecorridoHHMM} de ${data.nivelSLACorrente}h`} ({Math.round(data.percentualSLACorrente)}%)</label>
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>
                                    <tr>
                                        <th>Abertura</th>
                                        <td>{data && config.dateTimeFix(data.dataCadastro)}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{data && data.status}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>
                                            <b>Tipo Chamado:</b><br />
                                            <div className="row">
                                                <div className="form-group col-md-10" style={{ marginBottom: 0, paddingRight: 0 }}>
                                                    {(data.status === 'Concluída' || data.status === 'Finalizada' || !config.validarClaimExiste("Funcionario")) ?
                                                        data.tipoChamado ? data.tipoChamado : "Não Classificado" :
                                                        <Select id="tipoChamadoId" className="form-control input-sm col-sm-6" onChange={handleAtualizarTipoChamado} value={data.tipoChamadoId}>
                                                            <option value="">Selecione</option>
                                                            <option endpoint={'portal/crud/tipochamado/'} valuefield="id" textfield="nome" />
                                                        </Select>}
                                                </div>
                                                <div className="form-group col-md-2" style={{ marginBottom: 0, paddingLeft: 5 }}>
                                                    {(data.status !== 'Concluída' && data.status !== 'Finalizada' && config.validarClaimExiste("Funcionario")) && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handeSubmitTipoChamado}><i className="fa fa-save" /></button>}
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    {(data.tipoChamadoId === '4' || isManutencao) &&
                                        <React.Fragment>
                                            <tr>
                                                <th colSpan={2}>
                                                    <b>Data Manutenção Prevista</b><br />
                                                    <div className="row">
                                                        <div className="form-group col-md-10">
                                                            <input id="dataManutencaoPrevista" type="date" className="form-control" onChange={handleAtualizarDataManutencao} value={data.dataManutencaoPrevista} />
                                                        </div>
                                                        <div className="form-group col-md-2" style={{ marginBottom: 0, paddingLeft: 5 }}>
                                                            {(data.status !== 'Concluída' && data.status !== 'Finalizada') && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleSubmitDataManutencaoPrevista}><i className="fa fa-save" /></button>}
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>
                                                    <b>Data Manutenção Realizada</b><br />
                                                    <div className="row">
                                                        <div className="form-group col-md-10" style={{ marginBottom: 0, paddingRight: 0 }}>
                                                            <input id="dataManutencaoRealizada" type="date" className="form-control" onChange={handleAtualizarDataManutencao} value={data.dataManutencaoRealizada} />
                                                        </div>
                                                        <div className="form-group col-md-2" style={{ marginBottom: 0, paddingLeft: 5 }}>
                                                            {(data.status !== 'Concluída' && data.status !== 'Finalizada') && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleSubmitDataManutencaoRealizada}><i className="fa fa-save" /></button>}
                                                        </div>
                                                    </div>

                                                </th>
                                            </tr>
                                        </React.Fragment>}
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Regional  Padrão:</b><br />
                                            {data.regionalPadrao}
                                        </td>
                                    </tr>
                                    {config.validarClaimExiste('atendimento.reginal.associar') && <tr>
                                        <th colSpan={2}>
                                            <b>Regional:</b><br />
                                            <div className="row">
                                                <div className="form-group col-md-10" style={{ marginBottom: 0, paddingRight: 0 }}>
                                                    {(data.status === 'Concluída' || data.status === 'Finalizada') ? data.regional :
                                                        <Select id="regionalId" className="form-control input-sm col-sm-6" onChange={handleAtualizarRegional} value={data.regionalId}>
                                                            <option value="">Selecione</option>
                                                            <option endpoint={'portal/crud/regional/'} valuefield="pkRegional" textfield="nome" />
                                                        </Select>}
                                                </div>
                                                <div className="form-group col-md-2" style={{ marginBottom: 0, paddingLeft: 5 }}>
                                                    {(data.status !== 'Concluída' && data.status !== 'Finalizada') && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handeSubmit}><i className="fa fa-save" /></button>}
                                                </div>
                                            </div>
                                        </th>
                                    </tr>}
                                    <tr>
                                        <th>SLA Nível 1</th>
                                        <td>{data && config.timeConvert(data.tempoDecorridoNivel1)} de {data && data.nivel1}</td>
                                    </tr>

                                    {(data && data.nivel2 > 0) && <tr>
                                        <th>SLA Nível 2</th>
                                        <td>{data && config.timeConvert(data.tempoDecorridoNivel2)} de {data && data.nivel2}</td>
                                    </tr>}
                                    {(data && data.nivel3 > 0) && <tr>
                                        <th>SLA Nível 3</th>
                                        <td>{data && config.timeConvert(data.tempoDecorridoNivel3)} de {data && data.nivel3}</td>
                                    </tr>}
                                    {(data && data.slaSolicitacao > 0) && <tr>
                                        <th>SLA Solicitação</th>
                                        <td>{data && config.timeConvert(data.tempoDecorridoNivelSolicitacao)} de {data && data.slaSolicitacao}</td>
                                    </tr>}
                                    {(data && data.slaPreventiva > 0) && <tr>
                                        <th>SLA Preventiva</th>
                                        <td>{data && config.timeConvert(data.tempoDecorridoNivelPreventiva)} de {data && data.slaPreventiva}</td>
                                    </tr>}
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Cliente:</b><br />
                                            {data.cliente}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Cód. Externo Cliente: </b>{data.codigoExternoCliente}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Site:</b><br />
                                            {data.site}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Endereço:</b><br />
                                            {data.enderecoSite}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Contato Cliente:</b><br />
                                            {config.validarClaim("atendimento.editar") ?
                                                <div style={{ background: '#fcf8e1', outline: 0, maxWidth: 370 }} id="contatoCliente"
                                                    suppressContentEditableWarning contentEditable onBlur={(e) => changeCampoEditavel(e, 'contatoCliente', 150)}
                                                    onFocus={(e) => setVazio(e, 'contatoCliente')} >{form.contatoCliente}</div>
                                                :
                                                data.contatoCliente
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Resumo:</b><br />
                                            {config.validarClaim("atendimento.editar") ?
                                                <div style={{ background: '#fcf8e1', outline: 0, maxWidth: 370 }} id="resumo"
                                                    suppressContentEditableWarning contentEditable onBlur={(e) => changeCampoEditavel(e, 'resumo', 150)}
                                                    onFocus={(e) => setVazio(e, 'resumo')} >{form.resumo}</div>
                                                :
                                                data.resumo
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <b>Descrição:</b><br />
                                            {config.validarClaim("atendimento.editar") ?
                                                <div style={{ background: '#fcf8e1', outline: 0, maxWidth: 370 }} id="descricao"
                                                    suppressContentEditableWarning contentEditable onBlur={(e) => changeCampoEditavel(e, 'descricao', 1500)}
                                                    onFocus={(e) => setVazio(e, 'descricao')}>{form.descricao}</div>
                                                :
                                                data.descricao
                                            }
                                        </td>
                                    </tr>
                                    {handleEditou(true) &&
                                        <tr>
                                            <td colSpan={2}>
                                                <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat pull-right" onClick={handleSalvar}><i className="fa fa-check" /> Salvar</button>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs no-printme">
                            {config.validarClaim('atendimento.interacao.listar') && <li className="active"><a href="#tab_Interacao" data-toggle="tab">Interações</a></li>}
                            {(config.validarClaim('atendimento.tecnico.listar')) && <li> <a href="#tab_tecnico" data-toggle="tab">Acionamento</a></li>}
                            {config.validarClaim('atendimento.rm.listar') && <li><a href="#tab_material" data-toggle="tab">Materiais</a></li>}
                            {config.validarClaim('atendimento.interacao.listar') && <li><a href="#tab_Acidente" data-toggle="tab">Acidente</a></li>}
                            {(data && data && config.validarClaim('atendimento.concluir')) && data.acionamentoTecnicoId > 0 &&
                                <li><a href="#tab_concluir" data-toggle="tab">{data.statusId < 4 ? 'Concluir' : 'Informações da Conclusão'}</a></li>
                            }
                            <li><a href="#tab_rat" data-toggle="tab">RAT</a></li>
                            {(data && data.statusId === 4 && config.validarClaim('atendimento.reabrir')) &&
                                <li><a href="#tab_finalizar" data-toggle="tab">Finalizar</a></li>
                            }
                            {config.validarClaim('atendimento.historico') && <li><a href="#tab_historico" data-toggle="tab">Histórico</a></li>}

                            <li className="pull-right"><Link to="/atendimento/"><i className="fa fa-times" /></Link></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_Interacao" className="tab-pane active">
                                {(config.validarClaim('atendimento.interacao.listar')) && <Interacao parentId={rowId} allowEdit={data.statusId < 4} onHide={handleHide} refresh={params.refresh} />}
                            </div>
                            <div id="tab_tecnico" className="tab-pane">
                                {!isAcionamento ? 'Necessário informar a regional antes de criar um acionamento técnico!' :
                                    (config.validarClaim('atendimento.tecnico.listar')) &&
                                    <Acionamento parentId={rowId} data={data} siteId={data && data.siteId} allowEdit={data.statusId < 4} regionalId={data.regionalId} onHide={handleHide} />
                                }
                            </div>
                            <div id="tab_material" className="tab-pane">
                                {config.validarClaim('atendimento.rm.listar') &&
                                    <RequisicaoMaterial parentId={rowId} allowEdit={data.statusId < 4} clienteId={data && data.clienteId} siteId={data && data.siteId} site={data && data.site} enderecoSite={data && data.enderecoSite} enderecoPrestador={dataPrestador && dataPrestador.enderecoPrestador} />
                                }
                            </div>

                            <div id="tab_Acidente" className="tab-pane">
                                {(config.validarClaim('atendimento.interacao.listar')) &&
                                    <Acidente parentId={rowId} allowEdit={data.statusId < 4} onHide={handleHide} refresh={params.refresh} />}
                            </div>

                            {(data && config.validarClaim('atendimento.concluir')) && data.acionamentoTecnicoId > 0 &&
                                <div id="tab_concluir" className="tab-pane">
                                    <OrdemServicoConcluir parentId={rowId} refresh={params.refresh} onHide={handleHide} />
                                </div>
                            }
                            <div id="tab_rat" className="tab-pane">
                                <Rat parentId={rowId} refresh={params.refresh} onHide={handleHide} />
                            </div>
                            {(data && data.statusId === 4 && config.validarClaim('atendimento.reabrir')) &&
                                <div id="tab_finalizar" className="tab-pane">
                                    <OrdemServicoReabrir parentId={rowId} onHide={handleHide} />
                                </div>
                            }
                            <div id="tab_historico" className="tab-pane">
                                {config.validarClaim('atendimento.historico') && data && data.siteId && <Historico parentId={rowId} siteId={data.siteId} onHide={handleHide} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
