import React, { useState } from 'react';
import { Checkbox, config, apiHelper, toast } from '../index';

export function InteracaoForm(props) {
    const endPoint = 'v2/projeto/interacao';
    const [data, setData] = useState({
        dataCadastro: config.dateTimeFix(new Date()),
        visivelCliente: false,
        texto: '',
        anexo: '',
        file: '',
        criadorId: '[user_id]',
        projetoId: props.projetoId
    });

    const handleChange = (e) => {
        let name = e.target.id;
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                apiHelper.toBase64(file)
                    .then((base) => {
                        setData({ ...data, file: base, anexo: file.name });
                    });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.post(endPoint, data)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success('Dados salvos com sucesso!');
                    props.onHide();
                }
                else if (res.code === 400)
                    toast.warn(res.data.data);
                else
                    toast.error('Atenção, não foi possível salvar a interação.');
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a interação.');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição</label>
                    <textarea id="texto" className="form-control" rows="3" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Anexo</label>
                    <input type="file" id="file" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <Checkbox inline id="isVisivelCliente" onClick={handleChange}>Visivel cliente</Checkbox>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Criar Interação</button>
                </div>
            </div>
        </form>
    );
}

