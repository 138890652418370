import React, { Component } from 'react';
import {
    Row, Modals, FieldGroup, Button, ButtonToolbar
} from '../../components/adminlte/AdminLTE';
import { Global } from '../Core';
import { toast } from 'react-toastify';

export class OrdemServicoConcluir extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            titleOS: 'Concluir OS',
            row: {},
            groupList: [],
            subGroupList: [],
            maintenanceExecutedList: [],
            data: {
                groupCode: 0,
                subGroupCode: 0,
                maintenanceExecutedCode: 0,
                descriptionConclude: '',
                nomeAnexo: "",
                anexo: "",
                valorOS: 0
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeGroup = this.handleChangeGroup.bind(this);
        this.handleChangeSubGroup = this.handleChangeSubGroup.bind(this);
    }

    componentDidMount() {
        this.setState({
            data: {
                maintenanceExecutedCode: 0,
                name: ''
            },
            row: this.props.row
        });

        this.getGroup();
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "anexo") {
            let file = e.target.files[0];
            this.setState(prevState => {
                return { data: { ...prevState.data, anexo: file } };
            });

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    alert("Não é permitido anexo acima de 20MB.");
                    return;
                }

                Global.ToBase64(file).then((base) => {
                    this.setState(prevState => {
                        return { data: { ...prevState.data, anexo: base, nomeAnexo: file.name } };
                    });
                });
            }
        } else {
            this.setState(prevState => {
                return { data: { ...prevState.data, [name]: value } };
            });
        }
    }

    handleSubmit(e) {
        if (this.state.data.descriptionConclude === "") {
            toast.warn('Informe a Descricao');
            return;
        }
        if (this.state.data.groupCode === "") {
            toast.warn('Informe o Grupo');
            return;
        }
        if (this.state.data.subGroupCode === "") {
            toast.warn('Informe o Subgrupo');
            return;
        }
        if (this.state.data.maintenanceExecutedCode === "") {
            toast.warn('Informe a Manutencao Preventiva');
            return;
        }
       
        this.setState({ isSaving: true });

        const { config, row } = this.props;

        fetch(config.apiEndPoint + row.codeOS + "/conclude", {
            method: 'PUT',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }

        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {

            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.props.onReload();
            }
            else {
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            this.setState({ isSaving: false });
            toast.error(error.response.data.msg);
        });
    }

    onHide() {
        this.props.onHide();
    }

    getGroup() {
        const { config } = this.props;

        console.log(config.apiEndPoint + "group");
        fetch(config.apiEndPoint + "group", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ groupList: data });
        });
    }

    handleChangeGroup(e) {
        let value = e.target["value"];

        this.setState(prevState => {
            return { data: { ...prevState.data, groupCode: value } };
        });

        const { config } = this.props;

        fetch(config.apiEndPoint + "/subGroup/" + value, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ subGroupList: data });
        });
    }

    handleChangeSubGroup(e) {
        let value = e.target["value"];

        this.setState(prevState => {
            return { data: { ...prevState.data, subGroupCode: value } };
        });

        const { config } = this.props;

        fetch(config.apiEndPoint + "/maintenanceExecuted/" + value, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ maintenanceExecutedList: data });
        });
    }

    render() {
        const { show = false, row } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title={"Concluir OS " + row.codeOS} show={show} onHide={() => this.onHide()}
                footer={
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="success" disabled={isSaving} onClick={this.handleSubmit}>
                            {isSaving ? 'Salvando...' : 'Salvar'}
                        </Button>
                        <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                    </ButtonToolbar>
                }>

                <Row>
                    <FieldGroup id="groupCode" col={6} componentClass="select" label="Grupo" value={this.state.data.groupCode} onChange={this.handleChangeGroup}>
                        <option key="" value="Selecione">Selecione</option>
                        {this.state.groupList.map(item => {
                            return (
                                <option key={item.groupCode} value={item.groupCode}>{item.name}</option>
                            );
                        })}
                    </FieldGroup>

                    <FieldGroup id="subGroupCode" col={6} componentClass="select" label="Sub Grupo" value={this.state.data.subGroupCode} onChange={this.handleChangeSubGroup}>
                        <option key="" value="Selecione">Selecione</option>
                        {this.state.subGroupList.map(item => {
                            return (
                                <option key={item.subGroupCode} value={item.subGroupCode}>{item.name}</option>
                            );
                        })}
                    </FieldGroup>

                    <FieldGroup id="maintenanceExecutedCode" col={6} componentClass="select" label="Manutenção Executada" value={this.state.data.maintenanceExecutedCode} onChange={this.handleChange}>
                        <option key="" value="Selecione">Selecione</option>
                        {this.state.maintenanceExecutedList.map(item => {
                            return (
                                <option key={item.maintenanceExecutedCode} value={item.maintenanceExecutedCode}>{item.name}</option>
                            );
                        })}
                    </FieldGroup>

                    <FieldGroup id="valorOS" col={6} type="number" label="Valor" onChange={this.handleChange} />
                    <FieldGroup id="anexo" col={12} type="file" label="Anexo" onChange={(e) => this.handleChange(e)} />
                    <FieldGroup id="descriptionConclude" col={12} rows={12} type="text" componentClass="textarea" label="Descrição" maxLength="1000" value={this.state.data.descriptionConclude} onChange={this.handleChange} />
                </Row>
            </Modals>
        );
    }
}