import React, { useState, useEffect } from 'react';
import { Button, config, apiHelper, toast, Select } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function RequisicaoMaterialForm(props) {
    const endPoint = 'estoque/requisicaoMaterial/';
    const colM4 = `form-group col-sm-12 ${props.cols !== undefined ? props.cols : 'col-md-4'}`;
    const colM8 = `form-group col-sm-12 ${props.cols !== undefined ? props.cols : 'col-md-8'}`;
    const rowId = props.parentId;
    const [form, setForm] = useState([]);
    const [params, setParams] = useState({});

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/${rowId}/id`)
                .then(res => {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setForm(obj);

                    document.getElementById('dataNecessidade').value = obj.dataNecessidade.split('T')[0];
                }).then(res => {
                    apiHelper.get(`${endPoint}?filter=id::equal::${rowId}`)
                        .then(res => {
                            setParams(res.data.data[0]);
                        });
                })
                .catch(error => {
                    toast.error('');
                });
        } else {
            setForm({ idSolicitante: '[user_id]', dataRequisicao: config.dateTimeFix(new Date()) });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleRefresh = () => {
        setForm({ ...form, idSite: 0 });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            apiHelper.post(endPoint, form).then(res => {
                config.httpResult(res.data);

                if (res.data.code !== 500)
                    props.onHide();

            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/id`, form).then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const handleAddSite = (row) => {
        setForm({ ...form, idSite: row.pkSite });
    }

    const onHide = () => {
        props.onHide();
    }

    return (
        <form onSubmit={handleSubmit}>
            {props.solicitante && <div className="row">
                <div className="form-group col-sm-12">
                    <label htmlFor="name">Solicitante: {props.solicitante}</label>
                </div>
            </div>}

            <div className="row">
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label htmlFor="name">Centro Custo</label>
                    <Select id="idCentroCusto" className="form-control" onChange={handleChange} value={form.idCentroCusto} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/CentroDeCusto/" valuefield="pkCentroCusto" textfield="nome" />
                    </Select>
                </div>
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label>Empresa</label>
                    <input type="text" id="empresa" className="form-control" onChange={handleChange} value={form.empresa} required />
                </div>
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label>Código Obra</label>
                    <input id="codigoObra" type="text" className="form-control" value={form.codigoObra} onChange={handleChange} required />
                </div>
            </div>
            <div className="row">
                <div className={`form-group col-sm-12 ${colM8}`}>
                     <PesquisaGenericaHooks required textField="clienteSite" titulo="Site" endpoint="portal/crud/uvw_v2_pesquisa_site/?filter=nome::contains::" selecao={params.site} onImport={(row) => { handleAddSite(row); }} onHide={handleRefresh} />
                </div>
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label>Data Necessidade</label>
                    <input type="date" id="dataNecessidade" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label>Modelo Requisição</label>
                    <input type="text" id="modeloRequisicao" name="modeloRequisicao" value={form.modeloRequisicao} className="form-control" onChange={handleChange} />
                </div>

                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label>Unidade Negócio</label>
                    <input type="text" id="unidadeNegocio" className="form-control" value={form.unidadeNegocio} onChange={handleChange} />
                </div>
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label>Classificação Contabil</label>
                    <input type="text" id="classificacaoContabil" className="form-control" value={form.classificacaoContabil} onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className={`form-group col-sm-12 ${colM8}`}>
                    <label>Local Entrega</label>
                    <input type="text" id="localEntrega" className="form-control" value={form.localEntrega} onChange={handleChange} />
                </div>
                <div className={`form-group col-sm-12 ${colM4}`}>
                    <label htmlFor="status">Status</label>
                    <select id="status" className="form-control" onChange={handleChange} value={form.status}>
                        <option value="">Selecione</option>
                        <option value="Novo">Novo</option>
                        <option value="Enviado">Enviado</option>
                        <option value="Andamento">Em andamento</option>
                        <option value="Concluido">Concluído</option>
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="box-tool pull-right">
                        <div className="btn-toolbar">
                            <Button type="submit" bsStyle="success">Salvar</Button>
                            {rowId === 0 && <Button bsStyle="default" onClick={onHide}>Cancelar</Button>}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
