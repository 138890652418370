import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Modal, toast, Select } from '../';
import { RequisicaoMaterialForm } from './RequisicaoMaterialForm';

export function RequisicaoMaterial(props) {
    const endPoint = 'suprimentos/rm/';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        dataRequisicao: '',
        dataNecessidade: '',
        status: '',
        solicitante: '',
        empresaVeolink: '',
        cliente: '',
        centroCusto: '',
        origem: '',
        descricao: '',
        compraSolicitada: ''
    });

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",

        pageSize: 8,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 8
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar as requisições');
            });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            dataRequisicao: '',
            dataNecessidade: '',
            status: '',
            solicitante: '',
            empresaVeolink: '',
            cliente: '',
            centroCusto: '',
            origem: '',
            descricao: ''
        });
        setData([]);
        fetchData();
    };

    const handleSearch = () => {
        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'solicitante' || key === 'empresaVeolink' || key === 'cliente' || key === 'centroCusto' || key === 'descricao')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataRequisicao' || key === 'dataNecessidade') {
                where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;AND;;`;
            }
            else
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Requisição de Materiais" browserTitle="Requisição de Materiais">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("new", "Nova Requisição", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Nova Requisição</button>

                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>Id</label>
                                                                <input id="id" type="number" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Solicitação</label>
                                                                <input id="dataRequisicao" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataRequisicao} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Necessidade</label>
                                                                <input id="dataNecessidade" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataNecessidade} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Status</label>
                                                                <Select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status} required>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint="portal/crud/PortalMetadata/?filter=categoria::equal::SolicitacaoMaterialStatus" textfield="nome" valuefield="valor" />
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Compra Solicitada</label>
                                                                <select id="compraSolicitada" className="form-control input-sm" onChange={handleChange} value={filter.compraSolicitada} required>
                                                                    <option value="">Todos</option>
                                                                    <option value="não">Não</option>
                                                                    <option value="sim">Sim</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Solicitante</label>
                                                                <input id="solicitante" type="text" className="form-control input-sm" onChange={handleChange} value={filter.solicitante} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Empresa Origem</label>
                                                                <input id="empresaVeolink" type="text" className="form-control input-sm" onChange={handleChange} value={filter.empresaVeolink} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Cliente</label>
                                                                <input id="cliente" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cliente} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Centro de Custo</label>
                                                                <input id="centroCusto" type="text" className="form-control input-sm" onChange={handleChange} value={filter.centroCusto} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Tipo</label>
                                                                <select id="origem" className="form-control input-sm" onChange={handleChange} value={filter.origem} required>
                                                                    <option value="">Todos</option>
                                                                    <option value="usuario">Usuário</option>
                                                                    <option value="os">Ordem de Serviço</option>
                                                                    <option value="projeto">Projeto</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-8">
                                                                <label>Descrição</label>
                                                                <input id="descricao" type="text" className="form-control input-sm" onChange={handleChange} value={filter.descricao} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th>#</th>
                                            <th>ID</th>
                                            <th>Solicitação</th>
                                            <th>Necessidade</th>
                                            <th>Status</th>
                                            <th>Compra Solicitada</th>
                                            <th>Solicitante</th>
                                            <th>Empresa Origem</th>
                                            <th>Cliente</th>
                                            <th>Centro de Custo</th>
                                            <th>Tipo</th>
                                            <th>Descricao</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.dataRequisicao < b.dataRequisicao ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {

                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </button>
                                                                <ul className="dropdown-menu bg-gray disabled" role="menu">
                                                                    <li className="header"><center>{item.id}</center></li>
                                                                    <li className="divider" />
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><Link to={'/suprimentos/rm/' + item.id}>{item.id}</Link></td>
                                                        <td>{config.dateFix(item.dataRequisicao)}</td>
                                                        <td>{config.dateFix(item.dataNecessidade)}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.compraSolicitada}</td>
                                                        <td>{item.usuario}</td>
                                                        <td>{item.empresaVeolink}</td>
                                                        <td>{item.cliente}</td>
                                                        <td>{item.centroCusto}</td>
                                                        <td>{item.origem}</td>
                                                        <td>{item.descricao}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleRefresh}> <i className="fa fa-refresh" /> Atualizar</button>

                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "new" && <RequisicaoMaterialForm {...props} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
