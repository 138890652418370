import React, { useState, useEffect } from 'react';
import { apiHelper, Select, fileHelper, toast } from '../';
import moment from 'moment-timezone';
import { Table, BarChart } from './reports';
import LoadingOverlay from 'react-loading-overlay';
import { SyncLoader } from 'react-spinners';

export default function ReportContainer(props) {
    const reportId = props.reportId || props.match.params.id;
    const [report, setReport] = useState({});
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({});
    const [filtro, setFiltro] = useState([]);
    let where = "";
    const [showLoading, setShowLoading] = useState(false);


    const fetchData = () => {
        setShowLoading(true);
        apiHelper.get(`portal/crud/Report?filter=id::equal::${reportId}`)
            .then(res => {
                if (!res.data.data[0]) {
                    setShowLoading(false);
                    return;
                }


                setReport(res.data.data[0]);

                if (res.data.data[0].filtro && res.data.data[0].filtro !== "")
                    setFiltro(JSON.parse(res.data.data[0].filtro));
                if (!res.data.data[0].caminho) {
                    apiHelper.get(`v2/report/${reportId}`)
                        .then(res => {
                            res.data.data && setData(res.data.data);
                            setShowLoading(false);
                        })
                        .catch(error => {
                            setShowLoading(false);
                            toast.error('Não foi possível encontrar a view deste relatório!');
                        });
                } else
                    setShowLoading(false);
            })
            .catch(error => {
                setShowLoading(false);
                toast.error('Não foi encontrar o relatório!');
            });
    }
    const heandleExportExcel = (e) => {
        handleSearch();
        fileHelper.exportToExcelFromReport(where, reportId, report.nome + moment().format("DD/MM/YYYY"));
    }

    const fetchFiltro = () => {
        apiHelper.get(`v2/report/${reportId}?filter=${where}`)
            .then(res => {
                setData(res.data.data);
                setShowLoading(false);
            })
            .catch(error => {
                setShowLoading(false);
                toast.error('Não foi possível carregar os dados!');
            });
    }

    const renderReport = (report) => {
        if (report.caminho && report.caminho !== "") {
            try {
                let Report = require('../' + report.caminho).default;
                return <Report />;
            } catch (erro) {
                return "";
            }
        } else {
            switch (report.tipo) {
                case "table":
                    return <Table data={data} />
                case "bar_chart":
                    return <BarChart data={data} config={report.config} />
                default:
                    return "";
            }
        }
    }

    const handleRefresh = () => {
        setFilter([]);
        setData([]);
        where = "";
        fetchFiltro();
    };

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = () => {
        setShowLoading(true);
        where = "";
        filtro.map((item, index) => {
            let value = filter[item.field];
            if (value) {
                let { id, condicao } = item;
                if (where !== '')
                    where += ';;AND;;';
                where += `${id}::${condicao}::${value}`
            }
            return "";
        });
        fetchFiltro();
    };

    const renderInput = (item) => {
        switch (item.type) {
            case "select":
                return (<Select id={item.field} className="form-control input-sm" onChange={handleChange} value={filter[item.field] || item.defaultValue || ""}>
                    <option value="">{item.select.initialValue || "Selecione"}</option>
                    {
                        Array.isArray(item.select.data)
                            ? item.select.data.map((subItem) => {
                                return (<option value={subItem.valueField}>{subItem.textField}</option>)
                            })
                            : <option endpoint={item.select.data} valuefield={item.select.valueField} textfield={item.select.textField} />
                    }
                </Select>)
            case "date":
                return (<input id={item.field} type={item.type} className="form-control input-sm" onChange={handleChange} value={filter[item.field] || item.defaultValue || ""} placeholder="dd/mm/yyyy" />)
            default:
                return (<input id={item.field} type={item.type} className="form-control input-sm" onChange={handleChange} value={filter[item.field] || item.defaultValue || ""} />)
        }
    }

    useEffect(fetchData, [reportId]);

    return (
        <div>
            <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                <div style={{ marginBottom: "7px" }}>
                    {filtro && filtro.length > 0 &&
                        <div className="btn-group">
                            {report.tipo === "table" && <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={heandleExportExcel}><i className="fa fa-file-excel-o" /> Exportar</button>}
                            {(report.tipo === "table" || report.tipo === "bar_chart") && <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                Pesquisar <span className="caret" />
                            </button>}
                            <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                <div className="box" style={{ margin: 0 }}>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {filtro.map((item, i) => {
                                                        return (
                                                            <div key={i} className="form-group col-md-4">
                                                                <label>{item.label}</label>
                                                                {renderInput(item)}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer" style={{ padding: 5 }}>
                                            <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                            <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }</div>
                {renderReport(report)}
            </LoadingOverlay>
        </div>
    )
}