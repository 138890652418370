import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Select, Modal, fileHelper, toast } from '../';
import { ClienteForm } from './ClienteForm';
import moment from 'moment-timezone';
let where = '';
export function Cliente(props) {
    const endPoint = 'comercial/cliente/pages';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        cpfCnpj: '',
        consultorId: '',
        ativo: ''
    });

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 1,
        endRow: 10
    });

    const fetchData = (where, page = 0, init = 1, end = 10) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filtro=${where}&initRow=${init}&endRow=${end}`).then(res => {
            setData(res.data.data.data);

            setParam({
                ...params,
                totalPages: res.data.data.totalRegistros,
                selectedPage: page,
                initRow: init,
                endRow: end,
                rowId: 0,
                showModal: false,
                modalTitle: "",
                formName: ""
            });
        });
    };

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = () => {
        where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'nome' || key === 'cpfCnpj')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataCompraInicio') {
                where += `${'dataCompra'}::greaterEqual::${value2};;AND;;${'dataCompra'}::lessEqual::${filter.dataCompraFim} 23:59:59;;AND;;`;
            }
            else if (key !== 'dataCompraFim')
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: rowId && rowId > 0 ? title + ' ' + rowId : title,
            formName: name
        });
    };

    const handleHide = () => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            cpfCnpj: '',
            consultorId: '',
            ativo: ''
        });

        where = '';
        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = (page * params.pageSize) + 1;
        let end = (page * params.pageSize) + params.pageSize;

        fetchData(where, page, init, end);
    };

    const renderPage = () => {
        const pages = Math.ceil(params.totalPages / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + params.totalPages + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const HandleExcel = () => {

        try {
            apiHelper.get(`${endPoint}?filtro=${where}&initRow=${1}&endRow=${params.totalPages}`)
                .then(res => {
                    let dataExcel = [];
                    res.data.data.data && res.data.data.data.map((item, i) => {
                        let obj = {
                            'ID': item.id,
                            'Nome': item.nome,
                            'Razão Social': item.razaoSocial,
                            'CNPJ/CPF': item.cpfCnpj,
                            'Inscrição Estadual': item.inscricaoEstadual,
                            'Inscrição Municipal': item.inscricaoMunicipal,
                            'Tipo Pessoa': item.tipoPessoa,
                            'Tipo Empresa': item.tipoEmpresa,
                            'Tipo Cliente': item.tipoCliente,
                            'Status': item.ativo === true ? 'Ativo' : 'Inativo',
                            'Consultor': item.consultor.nome
                        }
                        dataExcel.push(obj);
                        return null;
                    });
                    fileHelper.exportToCSV(dataExcel, 'OS_' + moment().format("DD/MM/YYYY"));
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    }

    return (
        <Content title="Gestão de Clientes" browserTitle="Gestão de Clientes">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("novoCliente", "Novo Cliente", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Cliente</button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>Id</label>
                                                                <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Nome</label>
                                                                <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>CNPJ</label>
                                                                <input id="cpfCnpj" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cpfCnpj} />
                                                            </div>
                                                            {!config.validarClaim('ConsultorComercial') &&
                                                                <div className="form-group col-md-4">
                                                                    <label>Consultor</label>
                                                                    <Select id="consultorId" className="form-control" onChange={handleChange} value={filter.consultorId}>
                                                                        <option value="">Todos</option>
                                                                        {!config.validarClaim('AnalistaVeolink') && <option endpoint="comercial/pessoa/perfil?perfil=AnalistaVeolink" valuefield="id" textfield="nome" />}
                                                                    </Select>
                                                                </div>}
                                                            <div className="form-group col-md-4">
                                                                <label>Status</label>
                                                                <select id="ativo" className="form-control input-sm" onChange={handleChange} value={filter.ativo}>
                                                                    <option key="" value="">Todos</option>
                                                                    <option value="true">Ativo</option>
                                                                    <option value="false">Inativo</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => HandleExcel()} style={{ marginRight: "5px" }}><i className="fa fa-file-excel-o" /> Exportar</button>
                                </div>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" >
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th style={{ width: "50px" }}>#</th>
                                            <th>ID</th>
                                            <th>Nome</th>
                                            <th>CNPJ</th>
                                            <th>Consultor</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </button>
                                                                <ul className="dropdown-menu" role="menu">
                                                                    <li><Link replace to="#" onClick={() => handleShowForm("novoCliente", "Editar Cliente", item.id)}><i className="fa fa-pencil" /> Editar Cliente</Link></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><Link to={'/comercial/cliente/' + item.id}>{item.id}</Link></td>
                                                        <td>{item.nome}</td>
                                                        <td>{item.cpfCnpj}</td>
                                                        <td>{item.consultor ? item.consultor.nome : <span className="text-yellow pull-right">Sem Consultor</span>}</td>
                                                        <td>{item.ativo ? <span className="label label-success">Ativo</span> : <span className="label label-warning">Inativo</span>}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={() => handleHide()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'novoCliente' && <ClienteForm parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
