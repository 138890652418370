import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper } from '../index';
import Core from '../Core';

export function OrcamentoDocumento(props) {
    const endPoint = "v2/projeto/documentocomercial/";
    const parentId = props.parentId;
    const [data, setData] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}${parentId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [parentId]);

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloOportunidade(), fileName);
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                <tbody>
                    {data && data.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    <small className="pull-right">{config.dateFix(item.dataCadastro)}</small>
                                    {item.descricao}
                                    <br />
                                    <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link>
                                </td>
                            </tr>
                        )
                    })}

                    {data.length === 0 &&
                        <tr>
                            <td><center>Nenhum documento encontrado!</center></td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}
