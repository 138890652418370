import axios from 'axios';
import { config } from './config';
import { apiHeader } from './api-header';
import { apiHelper } from './api-helper';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const API_URL = config.apiUrl();
const API_HEADER = apiHeader();

export const fileHelper = {
    getBlob,
    download,
    exportToCSV,
    exportToExcel,
    exportToExcelFromReport
};

function getBlob(endPoint) {
    return axios({
        url: API_URL + endPoint,
        method: 'GET',
        responseType: 'blob',
        headers: API_HEADER
    });
}

async function download(type, file) {
    await getBlob(`anexo/download/${type}/${file}`).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
    });
};

function exportToCSV(data, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const _data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(_data, fileName + fileExtension);
}

async function exportToExcel(filter,objectName, fileName) {
    apiHelper.getBlob(`anexo/excel/${objectName}?filter=${filter}`)
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName + ".xlsx");
            document.body.appendChild(link);
            link.click();
        });
}

async function exportToExcelFromReport(filter, reportId, fileName) {
    apiHelper.getBlob(`anexo/excelReport/${reportId}?filter=${filter}`)
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName + ".xlsx");
            document.body.appendChild(link);
            link.click();
        });
}