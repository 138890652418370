import React, { useState, useEffect } from 'react';
import { Bar } from "react-chartjs-2";
import { apiHelper } from '../../index';

export function TecnicoMensalGrafico(props) {
    const endPoint = 'v2/report/45';
    const [tecnicos, setTecnicos] = useState([]);
    const [horasAtendimento, sethorasAtendimento] = useState([]);
    const [horasDeslocamento, sethorasDeslocamento] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            try {
                apiHelper.get(`${endPoint}`).then(res => {
                    if (res.data.data) {

                        let validData = res.data.data.filter(p => p.horasAtendimento > 0 || p.horasDeslocamento > 0);
                        let tecnicos = validData.map(p => p.tecnico);
                        let horasAtendimento = validData.map(p => p.horasAtendimento);
                        let horasDeslocamento = validData.map(p => p.horasDeslocamento);
                        setTecnicos(tecnicos);
                        sethorasAtendimento(horasAtendimento);
                        sethorasDeslocamento(horasDeslocamento);
                    }
                });
            } catch (error) { }
        }

        fetchData();
        setTimeout(() => setRefresh(!refresh), 30000);

        // eslint-disable-next-line
    }, [refresh]);
    
    const data = {
        labels: tecnicos,
        datasets: [
            {
                label: "Horas Deslocamento",
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgb(255, 99, 132)",
                data: horasDeslocamento,
                stack: 'Stack 0',
            },
            {
                label: "Horas Atendimento",
                backgroundColor: "rgb(54, 162, 235)",
                borderColor: "'rgb(54, 162, 235)",
                data: horasAtendimento,
                stack: 'Stack 0',
            }
        ],
    };
    return (
        <div className="card card-info card-outline">
            <div className="card-header">
                <h3 className="card-title">Hora Técnicos Mensal</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" /></button>
                    <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                </div>
            </div>
            <div className="card-body p-1" style={{ height: 500 }}>
                <Bar data={data} options={{maintainAspectRatio: false}}  />
            </div>
        </div>
    );
}
