import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select } from '../index';

export function MovimentacaoForm(props) {
    const endPoint = 'suprimentos/movimentacao';
    const [form, setForm] = useState([]);
    const [cliente, setCliente] = useState(0);
    const [data, setData] = useState([]);
    const ids = props.ids;
    const [params, setParams] = useState({
        endpoint: '',
        valuefield: '',
        textfield: ''
    });

    const fetchData = () => {
        if (!props.ids)
            return;

        let ids = String([...props.ids]);
        let where = `pkEstoque::in::${ids}`;
        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar os materiais selecionados!');
            });
    };

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "cliente") {
            setCliente(value);
            return;
        }

        if (name === "tipo") {
            if (value === "2")
                setParams({ ...params, endpoint: 'portal/crud/localArmazenamento', valuefield: 'pkLocalArmazenamento', textfield: 'nome' });
            else if (value === "3")
                setParams({ ...params, endpoint: 'portal/crud/prestador/?filter=isSituacaoAtivo::equal::1', valuefield: 'pkPrestador', textfield: 'nome' });
            else if (value === "4")
                setParams({ ...params, endpoint: 'portal/crud/rhFuncionario?filter=isAtivo::equal::1', valuefield: 'pkFuncionario', textfield: 'nome' });
        }

        setForm({ ...form, [name]: value });
    }

    const handleRemoverItem = (id) => {
        if (props.ids.has(id)) {
            props.ids.delete(id);
            props.removerItemSelecionado(id);

            let itens = data.filter(item => item.pkEstoque !== id)
            setData(itens);

            if (itens.length === 0) {
                props.onHide();
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var obj = form;
        obj.ids = JSON.stringify([...ids]);

        apiHelper.post(`${endPoint}/movimentar`, form).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                props.onHide();
            }
            else
                toast.error('Atenção, não foi possível salvar registro.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar registro.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-4">
                    <label htmlFor="name">Tipo</label>
                    <Select id="tipo" className="form-control" onChange={handleChange}>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/tipoLocalArmazenamento'} textfield="nome" valuefield="pkTipoLocalArmazenamento" />
                    </Select>
                </div>
                {form.tipo === "1" &&
                    <React.Fragment>
                        <div className="form-group col-md-8">
                            <label htmlFor="cliente">Cliente</label>
                            <Select id="cliente" className="form-control" onChange={handleChange}>
                                <option value="">Selecione</option>
                                <option endpoint={'portal/crud/uvw_cliente'} textfield="nome" valuefield="id" />
                            </Select>
                        </div>

                        <div className="form-group col-md-12">
                            <label htmlFor="localArmazenamento">Site</label>
                            <Select id="localArmazenamento" className="form-control" onChange={handleChange} refresh={cliente}>
                                <option value="">Selecione</option>
                                <option endpoint={'portal/crud/uvw_v2_pesquisa_site/?filter=fkCliente::equal::' + cliente} textfield="clienteSite" valuefield="pkSite" />
                            </Select>
                        </div>
                    </React.Fragment>
                }
                {form.tipo && form.tipo !== "1" &&
                    <div className="form-group col-md-8">
                        <label htmlFor="localArmazenamento">Local Armazenamento Destino</label>
                        <Select id="localArmazenamento" className="form-control" onChange={handleChange}>
                            <option value="">Selecione</option>
                            <option endpoint={params.endpoint} textfield={params.textfield} valuefield={params.valuefield} />
                        </Select>
                    </div>
                }
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Observação</label>
                    <textarea id="observacao" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th>Id</th>
                                    <th>Material</th>
                                    <th>Patrimônio</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.pkEstoque < b.pkEstoque ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.pkEstoque}</td>
                                                <td>{item.material}</td>
                                                <td>{item.patrimonio}</td>
                                                <td> <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRemoverItem(item.pkEstoque)}><i className="fa fa-trash" /></button></td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Cancelar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Movimentar </button>
                </div>
            </div>
        </form>
    )
}
