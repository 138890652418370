import React from 'react';
import { Content } from '../';

export function SystemConfig(props) {
    return (
        <Content title="Configurações de Sistema" browserTitle="Configurações de Sistema">
            <div className="row">
                <div className="col-md-12">

                </div>
            </div>
        </Content>
    )
}
