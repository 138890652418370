import React, { Component } from 'react';
import Map from './TrackerMap';

export class TrackerGoogleMaps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingInfoWindow: false,
            listOs: [],
            activeMarker: {},
            selectedPlace: {},
            apiEndPoint: localStorage.getItem('apiUrl') + 'tracker/Gps',
            listOsSite: [],
            sites: [],
            markers: [],
            latitude: -19.9253486,
            longitude: -43.9193513
        };
    }

    componentDidMount() {
        this.getCoordenadas();
    }

    getCoordenadas() {
        fetch(this.state.apiEndPoint, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(
            response => response.json()
        ).then(data => {
            var listOs = [];
            var k = 0;

            for (var i = 0; i < data.sites.length; i++) {
                for (var j = 0; j < data.sites[i].serviceOrders.length; j++) {
                    listOs[k] = data.sites[i].serviceOrders[j];
                    k++;
                }
            }

            this.setState({ sites: data.sites, listOsSite: listOs });
        });
    }

    getCenter() {
        this.setState({ latitude: this.state.latitude.toString().replace(",", "."), longitude: this.state.longitude.toString().replace(",", ".") });
    }

    render() {
        return (
            <div style={{ height: '100vh' }}>
                <Map id="myMap"
                    options={{
                        center: { lat: this.state.latitude, lng: this.state.longitude },
                        zoom: 4,
                        styles: [
                            { "featureType": "administrative", "elementType": "geometry", "stylers": [{ "visibility": "off" }] },
                            {
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "labels.text",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "labels",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road",
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "labels",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "labels",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "water",
                                "elementType": "labels.text",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ]
                    }}
                    onMapLoad={() => {
                        //const marker = new window.google.maps.Marker({
                        //    position: { lat: this.state.latitude, lng: this.state.longitude },
                        //    map: map,
                        //    title: 'Hello!'
                        //});
                    }}
                />

            </div>
        );
    }
}

