import React from 'react';
import { GoogleMap } from '../../index';

export function Projetos(props) {
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        const fetchData = () => {
            setRows([]);
        }

        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="card card-info card-outline">
            <div className="card-header">
                <h3 className="card-title">Projetos em Andamento</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" /></button>
                    <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                </div>
            </div>
            <div className="card-body p-1">

                <div className="row">
                    <div className="col-md-8" style={{ height: 400, overflow: 'auto' }}>
                        <table className="table table-striped table-bordered m-0">
                            <tbody>
                                {rows.map((m, i) =>
                                    <tr key={i}>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-4">
                        <GoogleMap height="100%" zoomLevel={4}>
                            {/* lista de obras ativas */}
                        </GoogleMap>
                    </div>
                </div>
            </div>
        </div>
    );
}
