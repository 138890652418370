import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select, Button, FormGroupButton, config, Checkbox } from '../index';
import { PesquisaGenerica } from '../../components/controls/PesquisaGenerica';
import { createHashHistory } from 'history'
export const history = createHashHistory();

export function OrcamentoForm(props) {
    const endPoint = "portal/crud/ComercialOrcamento/";
    const endPointOrcamento = 'orcamento';
    const endPointComercialAssociadoCliente = "portal/crud/uvw_ObterComercialAssociadoCliente/";
    const rowId = props.rowId;
    const nomeCliente = props.nomeCliente;
    const [data, setData] = useState({});
    const [codigoConsultor, setCodigoConsultor] = useState(0);
    const [showFormFindClientes, setShowFormFindClientes] = useState(false);
    const [clienteValueSearch, setClienteValueSearch] = useState('');

    let usuarioLogadoId = localStorage.getItem('claims') !== null
        && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}/id`).then(res => {
                let data = res.data.data;
                data.codigoIdentificadorCliente = data.clienteId;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);

                setData(obj);
                setClienteValueSearch(nomeCliente);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o Orçamento');
            });
        }
    }

    const obterCodigoConsultorCliente = (idCliente) => {
        if (idCliente && idCliente > 0) {
            apiHelper.get(`${endPointComercialAssociadoCliente}?filter=IdCliente::equal::${idCliente};;`)
                .then(res => {
                    setCodigoConsultor(res.data.data[0].consultorId);
                }).catch(error => {
                    toast.error('Houve um erro ao carregar o Orçamento');
                });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data.leadId === undefined || data.leadId === null || data.leadId === '') {
            if (data.crossSelling === undefined || data.crossSelling === false) {
                toast.warn("Informe o código do Lead!");
                return;
            }
        }

        if (data.clienteValueSearch !== undefined)
            delete data.clienteValueSearch;

        if (data.codigoIdentificadorCliente !== undefined)
            delete data.codigoIdentificadorCliente;

        let dataOrcamento = {
            nome: data.nome,
            clienteId: data.clienteId,
            oportunidadeComercialId: data.oportunidadeComercialId,
            estadodeFaturamentoEmpresa: data.estadodeFaturamentoEmpresa,
            estadodeFaturamentoCliente: data.estadodeFaturamentoCliente,
            descricao: data.descricao,
            numeroIdentificador: data.numeroIdentificador,
            criadorId: usuarioLogadoId,
            crossSelling: data.crossSelling,
            leadId: data.leadId,
            responsavelVendaId: codigoConsultor === 0 ? data.responsavelVendaId : codigoConsultor,
            tipoId: data.tipoId,
            empresaOrigem: data.empresaOrigem
        }

        if (data.leadId === null || data.leadId === '')
            delete dataOrcamento.leadId;

        if (rowId === 0) {
            dataOrcamento.numeroIdentificador = '';
            dataOrcamento.status = 'Rascunho';
            dataOrcamento.processed = 0;
            dataOrcamento.guid = "";

            apiHelper.post(`${endPointOrcamento}`, dataOrcamento)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        history.push(`/v2/orcamento/${res.data.data}`);
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/Id`, dataOrcamento)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleAddCliente = (row) => {
        setData({ ...data, clienteValueSearch: row.nome, codigoIdentificadorCliente: row.id, clienteId: row.id });
        setClienteValueSearch(row.nome);
        obterCodigoConsultorCliente(row.id);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                {!rowId > 0 &&
                    <div className="form-group col-sm-12">
                        <FormGroupButton placeholder="Cliente" label="Cliente" disabled
                            value={clienteValueSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => setShowFormFindClientes(true)}>Selecionar</Button>}
                        />
                    </div>
                }
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ""} required/>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Tipo Orçamento *</label>
                    <Select id="tipoId" className="form-control" onChange={handleChange} value={data.tipoId} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/TipoOrcamento`} valuefield="id" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Empresa Origem *</label>
                    <Select id="empresaOrigem" className="form-control" onChange={handleChange} value={data.empresaOrigem || ""} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/PortalMetadata?filter=categoria::equal::EmpresasVeolink`} valuefield="valor" textfield="nome" />
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>UF Faturamento *</label>
                    <Select id="estadodeFaturamentoEmpresa" className="form-control" onChange={handleChange} value={data.estadodeFaturamentoEmpresa} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>UF Destino *</label>
                    <Select id="estadodeFaturamentoCliente" className="form-control" onChange={handleChange} value={data.estadodeFaturamentoCliente} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-sm-6">
                    <Checkbox id="crossSelling" onChange={handleChange} checked={data.crossSelling ? data.crossSelling : false}>Origem Cliente – Base</Checkbox>
                </div>
                <div className="form-group col-sm-6">
                    <label>Lead</label>
                    <input id="leadId" type="text" className="form-control" onChange={handleChange} value={data.leadId || ""} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao} required />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="pull-right">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>

            {showFormFindClientes && <PesquisaGenerica config={{ apiUrl: localStorage.getItem('apiUrl') }} show={showFormFindClientes} title="Buscar Clientes" source="comercial/cliente/?filtro=nome::contains::" onImport={(row) => { handleAddCliente(row) }} onHide={() => { setShowFormFindClientes(false) }} />}
        </form>
    )
}
