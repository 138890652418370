import React from 'react'
import { Content } from '../';
import { DashboardView } from './DashboardView';

export function Dashboard(props) {
    const rowId = props.match.params.id || 2;

    return (
        <Content title="Dashboard" browserTitle="Siga | Dashboard">
            {rowId && <DashboardView id={rowId} admin={false} />}
        </Content>
    );
}