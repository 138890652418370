import React, { useState, useEffect } from 'react';
import { Link, config, apiHelper, toast, Modal } from '../';
import { RequisicaoMaterialView } from './RequisicaoMaterialView';
import { RequisicaoForm } from './RequisicaoForm';

export function RequisicaoMaterial(props) {
    const endPoint = 'suprimentos/rm/';
    const endPointProjeto = 'v2/projeto/';
    const projetoId = props.parentId;
    const [data, setData] = useState([]);
    const [projeto, setProjeto] = useState([]);

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        details: false,
        grid: true,
        modalTitle: "",
        formName: ""
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}origem/${projetoId}/Projeto`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar as requisições');
            });
    };

    const fetchProjeto = () => {
        if (projetoId) {
            apiHelper.get(`${endPointProjeto}${projetoId}/orcamento`)
                .then(res => {
                    setProjeto(res.data.data);
                });
        }
    }

    const onLoad = () => {
        fetchData();
        fetchProjeto();
    };

    useEffect(onLoad, [projetoId, props.refresh]);

    const handleDetails = (id) => {
        setParam({
            ...params,
            rowId: id,
            grid: false,
            details: true,
        });
    }

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            grid: true,
            details: false,
            modalTitle: "",
            formName: ""
        });
        fetchData();
        props.onUpdate();
    };

    const handleUpdate = (refresh) => {
        fetchData();
        props.onUpdate();
    };
    const handleShowForm = () => {
        setParam({
            ...params,
            showModal: true
        });
    };
    return (
        <React.Fragment>
            {params.grid &&
                <React.Fragment>
                    {props.canEdit &&
                        <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>Nova Requisição</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <RequisicaoForm projetoId={projetoId} onHide={handleHide} />
                            </Modal.Body>
                        </Modal>

                    }
                    {props.canEdit && <div style={{ marginBottom: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={handleShowForm}><i className="fa fa-plus" /> Adicionar</button>
                    </div>}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin">
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th>ID</th>
                                            <th>Solicitante</th>
                                            <th>Data Solicitação</th>
                                            <th>Data Necessidade</th>
                                            <th>Status</th>
                                            <th>Descricao</th>
                                            <th>Abrir Requisição</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1).map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td onClick={() => handleDetails(item.id)}><Link to={'#'}>{item.id}</Link></td>
                                                    <td>{item.usuario}</td>
                                                    <td>{config.dateFix(item.dataRequisicao)}</td>
                                                    <td>{config.dateFix(item.dataNecessidade)}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.descricao}</td>
                                                    <td onClick={() => handleDetails(item.id)}><Link to={'#'}>Abrir</Link></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }

            {params.details && <RequisicaoMaterialView parentId={params.rowId} onHide={handleHide} projeto={projeto} onUpdate={handleUpdate} canEdit={props.canEdit} />}
        </React.Fragment>
    )
}
