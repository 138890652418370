import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, fileHelper, Modal, toast } from '../index';
import { InteracaoForm } from './InteracaoForm';

export function Interacao(props) {
    const endPoint = "v2/os/interacao";
    const idOS = props.parentId;    

    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false,
        cancelarAcionamento: false,
        interacao: false,
        idAcionamentoTecnico: 0,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        try {
            apiHelper.get(`${endPoint}?filter=pkOS::equal::${idOS}&order=dataCadastro DESC`)
                .then(res => {                    
                    setData(res.data);
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    };

    useEffect(fetchData, [idOS, props.refresh]);

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    }

    const downloadAnexo = (fileName) => {
        fileHelper.download('OsAnexo', fileName).then(res => { });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onHide();
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Nova Interação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InteracaoForm idOS={idOS} onHide={handleHide} />
                </Modal.Body>
            </Modal>

            <div style={{ marginBottom: "7px" }}>
                {(config.validarClaim('atendimento.interacao.criar') && props.allowEdit) ? <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
                    : <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>}

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Data</th>
                            <th>Usuário</th>
                            <th>Descrição</th>
                            <th>Status</th>
                            <th>Visível Cliente</th>
                            <th>Arquivo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.dataCadastroInteracao < b.dataCadastroInteracao ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{config.dateTimeFix(item.dataCadastroInteracao)}</td>
                                        <td>{item.usuario}</td>
                                        <td>{item.descricaoInteracao}</td>
                                        <td>{item.descricaoStatus}</td>
                                        <td>{item.isVisivelCliente ? 'Sim' : 'Não'}</td>                                        
                                        <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
        </React.Fragment>
    );
}
