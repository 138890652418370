import React, { useState, useEffect } from 'react';
import { Content, apiHelper } from '../index';
import { DatasourceForm } from './DatasourceForm';

export function Datasource() {

    const endPoint = 'portal/crud/ReportsSource';
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const [novo, setNovo] = useState(false);
    
    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [row]);

    const handleHide = (refresh) => {
        setRow({});
        setNovo(false);
        refresh && fetchData();
    };

    const handleVisible = (item) => {
        setNovo(false);
        setRow(item);
    }

    const handleNovo = () => {
        setNovo(true);
        setRow({});
    }

    return (
        <Content title="Datasource" browserTitle="Datasource">
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block mb-3" onClick={() => handleNovo(true)}>Novo Datasource</button>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Datasources</h3>
                        </div>
                        <div className="card-body p-1">
                            <div className="table-responsive">
                                <table className="table table-striped m-0">
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .map((item, i) =>
                                                <tr key={i} onClick={() => handleVisible(item)} style={{ cursor: 'pointer' }}>
                                                    <td>{item.name}</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    {(novo || row.id) && <DatasourceForm parentId={novo ? 0 : row.id} onHide={handleHide} title={novo ? "Novo Datasource" : "Editar Datasource"} />}
                </div>
            </div>
        </Content>
    );
}