import React, { useState, useEffect } from 'react';
import { Content, apiHelper, Modal } from '../../index';
import { UsuarioForm } from './UsuarioForm';
import { UsuarioGrupo } from './UsuarioGrupo';
import { UsuarioPerfis } from './UsuarioPerfis';
import { UsuarioSitesAssociados } from './UsuarioSitesAssociados';
import { UsuarioAssociarSites } from './UsuarioAssociarSites';

export function UsuarioView(props) {
    const endPoint = 'portal/crud/IdentityUser';
    const rowId = props.match.params.id;

    const [data, setData] = useState({});
    const [params, setParam] = useState({
        showModal: false,
        refresh: false
    });

    const fetchData = () => {
        setData([]);
        apiHelper.get(`${endPoint}/${rowId}/id`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [rowId]);

    const handleEdit = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    const handleClose = () => {
        props.history.push({
            pathname: '/identity/v2/user/'
        });
    };

    const handleUpdate = () => {
        fetchData();
        setParam({ ...params, refresh: !params.refresh });
    }

    return (
        <Content title="Detalhes do Usuário" browserTitle="Detalhes do Usuário">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id}</h3>
                            <div className="box-tools pull-right">
                                <button title="Editar" type="button" className="btn btn-box-tool" onClick={handleEdit}><i className="fa fa-pencil" /></button>
                                <button title="Sair" type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>
                                    <tr>
                                        <th>Nome:</th>
                                        <td>{data.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Email:</th>
                                        <td>{data.emailAddress}</td>
                                    </tr>
                                    <tr>
                                        <th>Status:</th>
                                        <td>{data.enabled ? 'Ativo' : 'Inativo'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grupos" data-toggle="tab">Grupos</a></li>
                            <li><a href="#tab_perfis" data-toggle="tab">Perfis</a></li>
                            <li><a href="#tab_associarSites" data-toggle="tab">Associação de Sites</a></li>
                            <li><a href="#tab_sitesAssociadosUsuario" data-toggle="tab">Sites Associados ao Usuário</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grupos" className="tab-pane active">
                                <UsuarioGrupo parentId={rowId} userName={data.username} onUpdate={handleUpdate} />
                            </div>
                            <div id="tab_perfis" className="tab-pane">
                                <UsuarioPerfis parentId={rowId} onUpdate={handleUpdate} />
                            </div>
                            <div id="tab_associarSites" className="tab-pane">
                                <UsuarioAssociarSites parentId={rowId} onUpdate={handleUpdate} />
                            </div>
                            <div id="tab_sitesAssociadosUsuario" className="tab-pane">
                                <UsuarioSitesAssociados parentId={rowId} nome={data.name} onUpdate={handleUpdate} refresh={params.refresh} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UsuarioForm parentId={rowId} onHide={handleHide} />
                </Modal.Body>
            </Modal>
        </Content>
    )
}
