import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export class ModalConfirm extends Component {
    onHide() {
        this.props.onConfirm(false);
    }

    handleConfirm() {
        this.props.onConfirm(true);
    }

    render() {
        const { show = false } = this.props;
        return (
            <Modal show={show} onHide={() => this.onHide()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Confirma?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Esta opera&#xE7;&#xE3;o &#xE9; irrevers&#xED;vel. Deseja continuar?
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={() => this.onHide()} className="pull-left">Cancelar</Button>
                    <Button bsStyle="danger" onClick={() => this.handleConfirm()}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
