import React, { Component } from 'react';
import { Modals, FieldGroup, Table, Button } from '../../components/adminlte/AdminLTE';
import Core from '../Core';

export class PesquisarTecnico extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            apiEndPointTechnical: localStorage.getItem('apiUrl') + 'comercial/pessoa/perfil',
            data: this.props.data,
            onImport: this.props.onImport,
            isSaving: false,
            row: {},
            technicalList: [],
            technicalValueSearch: ''
        };

        this.technicalSearch = this.technicalSearch.bind(this);
        this.setTechnicals = this.setTechnicals.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row, technicallList: [], technicalSearch: '' });
    }

    getTechnicals = async (filter) => {
        await Core.api_get('comercial/pessoa/perfil?perfil=Tecnico&nome=' + filter).then(res => {
            this.setState({ technicalList: res.data.data });
        });
    }

    technicalSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ technicalValueSearch: value.trim() });

        if (value.length > 2) {
            this.getTechnicals(value);
        } else {
            this.setState({ technicalList: [] });
        }
    }

    onHide() {
        this.setState({ technicallList: [], technicalSearch: '' });
        this.props.onHide();
    }

    setTechnicals(row) {
        this.setState({ technicalList: [], technicalValueSearch: row.nome });
        this.props.onHide();
        this.state.onImport(row);
    }

    render() {
        const { show = false } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title="Buscar Tecnico" show={show} onHide={() => this.onHide()} >
                <form onSubmit={this.handleSubmit}>

                    <FieldGroup id="nameTecnico" col={12} type="text" onChange={this.technicalSearch} value={this.state.technicalValueSearch} />

                    <Table striped bordered hover>
                        <tbody>
                            {this.state.technicalList.map(row => {
                                return (
                                    <tr key={row.id}>
                                        <td>{row.nome}</td>
                                        <td style={{ width: "50px" }}>
                                            <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.setTechnicals(row)}>
                                                {isSaving ? 'Adicionando...' : 'Adicionar'}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </form>
            </Modals>
        );
    }
}

