import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { apiHelper } from '../../index';

export function EvolucaoQtdDispositivos(props) {
    const endPoint = 'atendimentoReport/evQtdDispositivos';
    const [data, setData] = useState([]);
    const title = 'Evolução Qtd. de Dispositivos';
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: {
            mode: 'point',
            callbacks: {
                label: (tooltipItem) => {
                    return `${tooltipItem.value}`
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 5 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!dataEntradaInicio && !dataEntradaFim)
            return;

        let values = [];
        let labels = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
        labels.map((mes) => {
            let valorMes = data.find(c => c.mes === mes) !== undefined ? data.find(c => c.mes === mes).qtdDispositivos : 0;
            values.push(valorMes);

            return null;
        });

        let datasets = [
            {
                label: `${title}`, borderColor: 'rgb(75, 192, 192)', fill: false, tension: 0.1, pointRadius: 4,
                pointHoverRadius: 15, pointStyle: 'circle', data: values },
        ];

        const dataAux = {
            labels: labels,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
                {data.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Line data={gerarGrafico} options={options} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
