import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../index';
import { Select } from '../../components/controls/crud/Select';
import { SitesAssociadosUsuario } from './SitesAssociadosUsuario';
import ReactSelect from 'react-select';

export function UsuarioForm(props) {
    const endPoint = "identity/user";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [perfis, setPerfis] = useState({});
    const [grupos, setGrupos] = useState({});
    const [defaultPerfis, setDefaultPerfis] = useState({});
    const [defaultGrupos, setDefaultGrupos] = useState({});

    const fetchData = () => {
        let groups = [];
        let defaultGroups = [];
        let perfil = [];
        let defaultperfil = [];

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/usuario/${rowId}`).then(res => {
                setData(res.data.data);

                let idsGrupo = res.data.data.idsGrupo;
                apiHelper.get('portal/crud/IdentityGroup').then(resp => {
                    resp.data.data.map((item) =>
                        groups.push({ value: item.id, label: item.name })
                    );
                    setGrupos(groups);

                    resp.data.data.filter(item => idsGrupo.includes(item.id)).map((item) =>
                        defaultGroups.push({ value: item.id, label: item.name })
                    );
                    setDefaultGrupos(defaultGroups);
                }).catch(error => {
                    toast.error('Houve um erro ao carregar o registro.');
                });


                let idsPerfil = res.data.data.idsPerfil;
                apiHelper.get('portal/crud/IdentityClaim/?filter=type::equal::UserData').then(resp => {
                    resp.data.data.map((item) =>
                        perfil.push({ value: item.id, label: item.description })
                    );
                    setPerfis(perfil);

                    resp.data.data.filter(item => idsPerfil.includes(item.id)).map((item) =>
                        defaultperfil.push({ value: item.id, label: item.description })
                    );
                    setDefaultPerfis(defaultperfil);
                }).catch(error => {
                    toast.error('Houve um erro ao carregar o registro.');
                });

            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        } else {
            apiHelper.get('portal/crud/IdentityGroup').then(resp => {
                resp.data.data.map((item) =>
                    groups.push({ value: item.id, label: item.name })
                );
                setGrupos(groups);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });

            apiHelper.get('portal/crud/IdentityClaim/?filter=type::equal::UserData').then(resp => {
                resp.data.data.map((item) =>
                    perfil.push({ value: item.id, label: item.description })
                );
                setPerfis(perfil);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleChangePerfil = (e) => {
        setDefaultPerfis(e);
    }

    const handleChangeGrupo = (e) => {
        setDefaultGrupos(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let obj = {
            ...data,
            idsPerfil: (defaultPerfis && defaultPerfis.length > 0) ? defaultPerfis.map(item => item.value) : null,
            idsGrupo: (defaultGrupos && defaultGrupos.length > 0) ? defaultGrupos.map(item => item.value) : null
        };
        if (rowId === 0) {
            apiHelper.post(`${endPoint}`, obj)
                .then(res => {
                    toast.success("Usuário cadastrado com sucesso!");
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            obj.id = rowId;
            apiHelper.put(`${endPoint}`, obj)
                .then(res => {
                    toast.success("Usuário alterado com sucesso!");
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <React.Fragment>
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                    <li className="active"><a href="#tab_geral" data-toggle="tab">Geral</a></li>
                    <li><a href="#tab_sites" data-toggle="tab">Associar Sites</a></li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="tab_geral">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-sm-12">
                                        <label>Nome</label>
                                        <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ""} required={!rowId || rowId === 0} />
                                    </div>
                                    <div className="form-group col-sm-8">
                                        <label>Email</label>
                                        <input id="email" type="text" className="form-control" onChange={handleChange} value={data.email || ""} required={!rowId || rowId === 0} />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Status</label>
                                        <Select id="status" className="form-control" onChange={handleChange} value={data.status}>
                                            <option value={true}>Ativo</option>
                                            <option value={false}>Inativo</option>
                                        </Select>
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>Usuário</label>
                                        <input id="username" type="text" className="form-control" onChange={handleChange} value={data.username || ""} required={!rowId || rowId === 0} />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>Senha</label>
                                        <input id="password" type="password" className="form-control" onChange={handleChange} value={data.password || ""} required={!rowId || rowId === 0} />
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <label>Grupo</label>
                                        <ReactSelect
                                            id="idsGrupo"
                                            value={(defaultGrupos && defaultGrupos.length > 0) && defaultGrupos}
                                            isMulti
                                            name="colors"
                                            options={grupos}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleChangeGrupo}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12">
                                        <label>Perfil</label>
                                        <ReactSelect
                                            id="idsPerfil"
                                            value={(defaultPerfis && defaultPerfis.length > 0) && defaultPerfis}
                                            isMulti
                                            name="colors"
                                            options={perfis}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleChangePerfil}
                                        />
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <div className="box-tool pull-right">
                                        <div className="btn-toolbar">
                                            <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                                            <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="tab-pane" id="tab_sites">
                        <div className="row">
                            {rowId && rowId > 0 ? <SitesAssociadosUsuario parentId={rowId} /> : <h4>Cadastre o usuário para associar os sites!</h4>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}