import React, { Component } from 'react';

export class InputGroup extends Component {
    render() {
        const { children, ...props } = this.props;
        return (
            <div className="input-group margin">
                <input type="text" className="form-control" {...props} />
                <span className="input-group-btn">
                    {children}
                </span>
            </div>
        );
    }
}
