import React, { useEffect } from 'react';

export function LeadMensagemErro(props) {
    const mensagemErro = props.mensagemErro;

    const fetchData = () => {
        console.log(mensagemErro);
    }
    useEffect(fetchData, [props.mensagemErro]);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <p>{mensagemErro}</p>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
