import React, { useState} from 'react';
import { Content, Link, config, apiHelper, fileHelper, Select, toast } from '../';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function Cliente(props) {
    const endPointRelatorioComercial = "portal/crud/uvw_Relatorio_Comercial";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        status: '',
        clienteSearch: '',
        searchValueConsultor: '',
        searchValueAcaoComercial: '',
        dataInicio: '',
        dataFim: '',
        origemContato: ''
    });
    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowLoading(true);
        let query = montarQuery();

        apiHelper.get(`${endPointRelatorioComercial}?filter=${query}`)
            .then(res => {
                setData(res.data.data);
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            status: '',
            clienteSearch: '',
            searchValueConsultor: '',
            searchValueAcaoComercial: '',
            dataInicio: '',
            dataFim: '',
            origemContato: ''
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const montarQuery = () => {
        let parametros = "";
        let consultor = filter.searchValueConsultor === '' ? '' : `IdConsultor::equal::${filter.searchValueConsultor};;AND;;`;
        let cliente = filter.clienteSearch === '' ? '' : `IdCliente::equal::${filter.clienteSearch};;AND;;`;
        let statusProposta = filter.status === '' ? '' : `StatusProposta::equal::${filter.status};;AND;;`;
        let origemContato = filter.origemContato === '' ? '' : `OrigemContato::equal::${filter.origemContato};;AND;;`;
        let acaoComercial = filter.searchValueAcaoComercial === '' ? '' : `AcaoComercial::equal::${filter.searchValueAcaoComercial};;AND;;`;
        let data = filter.dataInicio === '' ? '' : `Data::greaterEqual::${filter.dataInicio};;AND;;Data::lessEqual::${filter.dataFim} 23:59:59;;`;

        return parametros.concat(consultor, cliente, statusProposta, origemContato, acaoComercial, data);
    }

    const handleOrdenarColunas = (e, nomeVariavelColuna) => {
        e.preventDefault();
        let sortedObjs = config.ordenarColunas(data, nomeVariavelColuna);
        setData(sortedObjs);
    }

    return (
        <Content title="Relatórios Comerciais" browserTitle="Relatórios Comerciais">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label>Consultor</label>
                                <Select id="searchValueConsultor" className="form-control" onChange={handleChange} value={filter.searchValueConsultor}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="clienteSearch" className="form-control" onChange={handleChange} value={filter.clienteSearch}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/projetoImplantacao/cliente" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <Select id="status" className="form-control" onChange={handleChange} value={filter.status}>
                                    <option value="">Todos</option>
                                    <option value="Rascunho">Abertas</option>
                                    <option value="Finalizada">Orçamentos Finalizados</option>
                                    <option value="PendenteAprovacao">Pendente Aprovação Financeira</option>
                                    <option value="Aprovada">Aprovadas Financeiro</option>
                                    <option value="Rejeitada">Reprovadas Finaceiro</option>
                                    <option value="EnviadaParaCliente">Enviadas para Cliente</option>
                                    <option value="AprovadaPeloCliente">Aprovadas pelo Cliente</option>
                                    <option value="ReprovadaPeloCliente">Reprovadas pelo Cliente</option>
                                    <option value="Projeto">Projetos</option>
                                    <option value="Cancelada">Canceladas</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Origem Contato</label>
                                <Select id="origemContato" className="form-control" onChange={handleChange} value={filter.origemContato}>
                                    <option value="">Todos</option>
                                    <option value="Linkedin">Linkedin</option>
                                    <option value="ProspeccaoPropria">Prospecção própria</option>
                                    <option value="FTI">FTI</option>
                                    <option value="Indicacao">Indicação</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Ação Comercial</label>
                                <Select id="searchValueAcaoComercial" className="form-control" onChange={handleChange} value={filter.searchValueAcaoComercial}>
                                    <option value="">Todos</option>
                                    <option value="NovaInteracao">Nova Interação</option>
                                    <option value="Reuniao">Reunião</option>
                                    <option value="Almoco">Almoço</option>
                                    <option value="Apresentacao">Apresentação</option>
                                    <option value="Treinamento">Treinamento</option>
                                    <option value="VisitaTecnica">Visita Técnica</option>
                                    <option value="VisitaComercial">Visita Comercial</option>
                                    <option value="ContatoTelefonico">Contato telefônico</option>
                                    <option value="Entrevista">Entrevista</option>
                                </Select>
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataInicio"} col={12} showHora={false} labelData={"Data Início"} valueData={filter.dataInicio} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataFim"} col={12} showHora={false} labelData={"Data Fim"} valueData={filter.dataFim} onSelectValue={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Relatórios Comerciais</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => fileHelper.exportToExcel(montarQuery(), 'uvw_Relatorio_Comercial','RelatorioComercial_' + moment().format("DD/MM/YYYY"))}><i class="fa fa-file-excel-o"></i></button>
                                </div>
                            </div>
                            <div className="box-body table-responsive no-padding">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'proposta')}><i className="fa fa-sort" /> Proposta</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'consultor')}><i className="fa fa-sort" /> Consultor</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'cliente')}><i className="fa fa-sort" /> Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'statusProposta')}><i className="fa fa-sort" /> Status Proposta</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'origemContato')}><i className="fa fa-sort" /> Origem Contato</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'acaoComercial')}><i className="fa fa-sort" /> Ação Comercial</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'descricao')}><i className="fa fa-sort" /> Descrição Ação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'vendaMaterial')}><i className="fa fa-sort" /> Venda Material</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'vendaLocacao')}><i className="fa fa-sort" /> Venda Locação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'vendaServicoRecorrente')}><i className="fa fa-sort" /> Venda Serv.Recorrente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'vendaServicoNaoRecorrente')}><i className="fa fa-sort" /> Venda Serv.Não Recorrente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'prazoContratoVenda')}><i className="fa fa-sort" /> Prz.Contrato Venda</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'prazoContratoLocacao')}><i className="fa fa-sort" /> Prz.Contrato Locação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'prazoContratoServicoRecorrente')}><i className="fa fa-sort" /> Prz.Contrato Serv.Recorrente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'prazoContratoServicoNaoRecorrente')}><i className="fa fa-sort" /> Prz.Contrato Serv.Não Recorrente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'data')}><i className="fa fa-sort" /> Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={item.idAcaoComercial}>
                                                        <td>{item.proposta}</td>
                                                        <td>{item.consultor}</td>
                                                        <td>{item.cliente}</td>
                                                        <td>{item.statusProposta}</td>
                                                        <td>{item.origemContato}</td>
                                                        <td>{item.acaoComercial}</td>
                                                        <td>{item.descricao}</td>
                                                        <td>{item.acaoComercial === 'Proposta comercial' ? config.currencyFix(item.vendaMaterial) : item.vendaMaterial}</td>
                                                        <td>{item.acaoComercial === 'Proposta comercial' ? config.currencyFix(item.vendaLocacao) : item.vendaLocacao}</td>
                                                        <td>{item.acaoComercial === 'Proposta comercial' ? config.currencyFix(item.vendaServicoRecorrente) : item.vendaServicoRecorrente}</td>
                                                        <td>{item.acaoComercial === 'Proposta comercial' ? config.currencyFix(item.vendaServicoNaoRecorrente) : item.vendaServicoNaoRecorrente}</td>
                                                        <td>{item.prazoContratoVenda}</td>
                                                        <td>{item.prazoContratoLocacao}</td>
                                                        <td>{item.prazoContratoServicoRecorrente}</td>
                                                        <td>{item.prazoContratoServicoNaoRecorrente}</td>
                                                        <td>{config.dateFix(item.data)}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
