import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function QtdChamadosAno(props) {
    const endPoint = 'atendimentoReport/qtdChamadosAno';
    const [data, setData] = useState([]);
    const title = 'Quantitativo de chamados no ano';
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: {
            mode: 'point',
            callbacks: {
                label: (tooltipItem) => {
                    return `${tooltipItem.value}`
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 5 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!dataEntradaInicio && !dataEntradaFim)
            return;

        let values = [];
        let labels = [];

        data.map((item) => {
            labels.push(item.nome);
            values.push(item.valor);

            return "";
        });

        let datasets = [
            { label: `${title}`, backgroundColor: '#3e71c4', borderWidth: 1, data: values },
        ];

        const dataAux = {
            labels: labels,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
                {data.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
