class Global {
    ApiUrl = () => {
        return localStorage.getItem('apiUrl');
    }

    ApiHeader = () => {
        let header = {
            'Access-Control-Allow-Origin': true,
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        };

        return header;
    }

    FormatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        //return [year, month, day].join('-');
        return [day, month, year].join('/');
    }

    CurrencyFix = (val) => {
        if (val)
            return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        else
            return 0;
    }

    CurrencyDolarFix = (val) => {
        if (val)
            return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        else
            return 0;
    }

    DateFix = (val) => {
        if (val)
            return new Date(val).toLocaleDateString('pt-BR');
        else
            return "";
    }

    DateTimeFix = (val) => {
        if (val)
            return new Date(val).toLocaleString('pt-BR');
        else
            return "";
    }

    ToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default new Global();