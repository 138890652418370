import React, { Component } from 'react';
import { Route } from 'react-router';
import withAuth from './auth/withAuth';
import AdminLTE, { Sidebar, axios, uuidv4 } from './adminlte/AdminLTE';
import * as Apps from '../modules';
import { Theme } from '../_components/Theme';

const config = {
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiUrl: localStorage.getItem('apiUrl'),
    endPoint: localStorage.getItem('apiUrl') + 'portal/menu/'
};

class Portal extends Component {
    constructor() {
        super();
        this.state = {
            menuData: [],
            sidebar: [],
        };
    }

    UNSAFE_componentWillMount() {
        axios({
            method: 'GET', url: config.endPoint,
            headers: { 'Authorization': config.token }
        }).then(response => {
            this.setState({ menuData: response.data })

            var sidebar = this.getMenu(1);
            this.setState({ sidebar: sidebar });
        });
    }

    getMenu = (node) => this.state.menuData.filter(cNode => cNode.parentId === node)
        .sort((a, b) => a.menuOrder - b.menuOrder)
        .map(cNode => {
            let child = this.getMenu(cNode.id);

            if (child.length === 0)
                return <Sidebar.Item key={uuidv4()} to={cNode.url} icon={cNode.icon} text={cNode.name} />;
            else
                return <Sidebar.Item key={uuidv4()} to={cNode.url} icon={cNode.icon} text={cNode.name}>{child}</Sidebar.Item>
        });

    render() {
        return (
            <AdminLTE sidebar={this.state.sidebar} showFooter>
                <Route exact path="/theme" component={Theme} />

                <Route exact path="/" component={Apps.Dashboard} />
                <Route exact path="/dashboard/:id" component={Apps.Dashboard} />
                <Route exact path="/dashboard-adm" component={Apps.DashboardReport} />
                <Route exact path="/identity/group" component={Apps.IdentityGrupo} />
                <Route exact path="/identity/user" component={Apps.IdentityUsuario} />

                <Route exact path="/identity/perfil" component={Apps.PerfilUsuario} />

                <Route exact path="/tracker" component={Apps.TrackerGoogleMaps} />
                <Route exact path="/vms/group" component={Apps.DeviceGroup} />
                <Route exact path="/vms/device" component={Apps.Device} />

                <Route exact path="/agendamento" component={Apps.Agendamento} />
                <Route exact path="/agendamento/:id" component={Apps.Agendamento} />

                <Route exact path="/atendimento" component={Apps.OrdemServicoV2} />
                <Route exact path="/atendimento/:id" component={Apps.OrdemServicoView} />
                <Route exact path="/atendimento/rm/:id" component={Apps.OrdemServicoRequisicaoMaterialView} />
                <Route exact path="/aprovacaoRequisicaoMaterial" component={Apps.AprovacaoRequisicaoMaterial} />

                <Route exact path="/grupo" component={Apps.Grupo} />
                <Route exact path="/subGrupo" component={Apps.SubGrupo} />
                <Route exact path="/manutencaoExecutada" component={Apps.ManutencaoExecutada} />

                <Route exact path="/prestador" component={Apps.Prestador} />
                <Route exact path="/prestador/:id" component={Apps.PrestadorView} />

                <Route exact path="/regional" component={Apps.RegionalOS} />
                <Route exact path="/regional/:id" component={Apps.RegionalView} />

                <Route exact path="/oi/import" component={Apps.Import} />
                <Route exact path="/oi/ponto" component={Apps.PortaPonto} />

                <Route exact path="/comercial/cliente" component={Apps.Cliente} />
                <Route exact path="/comercial/cliente/:id" component={Apps.ClienteView} />
                <Route exact path="/crm/lead" component={Apps.Lead} />
                <Route exact path="/crm/lead/:id" component={Apps.LeadView} />

                <Route exact path='/v2/orcamento' component={Apps.Orcamento} />
                <Route exact path='/v2/orcamento/:id' component={Apps.OrcamentoView} />

                <Route exact path="/cliente/contrato" component={Apps.Contrato} />
                <Route exact path="/cliente/contrato/:id" component={Apps.Contrato} />

                <Route exact path="/v2/projeto" component={Apps.Projeto} />
                <Route exact path="/v2/projeto/:id" component={Apps.ProjetoView} />


                <Route exact path="/v2/client/project" component={Apps.ProjetoCliente} />
                <Route exact path="/v2/client/project/:id" component={Apps.ProjetoClienteView} />

                <Route exact path="/marketing/campanha" component={Apps.Campanha} />
                <Route exact path="/marketing/campanha/:id" component={Apps.CampanhaView} />

                <Route exact path='/cliente/site' component={Apps.Site} />
                <Route exact path='/cliente/site/:id' component={Apps.SiteView} />

                <Route exact path="/aprovarMaterial" component={Apps.AprovarMaterial} />

                <Route exact path="/estoque/material" component={Apps.Material} />
                <Route exact path="/estoque/material/:id" component={Apps.Material} />
                <Route exact path="/estoque/pesquisaItem" component={Apps.PesquisaItem} />
                <Route exact path="/estoque/fabricante" component={Apps.Fabricante} />
                <Route exact path="/estoque/fabricante/:id" component={Apps.Fabricante} />
                <Route exact path="/estoque/categoria" component={Apps.Categoria} />
                <Route exact path="/estoque/categoria/:id" component={Apps.Categoria} />
                <Route exact path="/estoque/armazemProtheus" component={Apps.ArmazemProtheus} />
                <Route exact path="/estoque/armazemProtheus/:id" component={Apps.ArmazemProtheus} />
                <Route exact path="/estoque/tipo" component={Apps.Tipo} />
                <Route exact path="/estoque/tipo/:id" component={Apps.Tipo} />
                <Route exact path="/estoque/modelo" component={Apps.Modelo} />
                <Route exact path="/estoque/modelo/:id" component={Apps.Modelo} />
                <Route exact path="/estoque/regional" component={Apps.Regional} />
                <Route exact path="/estoque/regional/:id" component={Apps.Regional} />

                <Route exact path="/suprimentos/rm/" component={Apps.RequisicaoMaterial} />
                <Route exact path="/suprimentos/rm/:id" component={Apps.RequisicaoMaterialView} />

                <Route exact path="/suprimentos/movimentacao" component={Apps.Movimentacao} />
                <Route exact path="/suprimentos/movimentacao/:id" component={Apps.MovimentacaoView} />
                <Route exact path="/suprimentos/fornecedor" component={Apps.Fornecedor} />
                <Route exact path="/suprimentos/fornecedor/:id" component={Apps.FornecedorView} />
                <Route exact path="/suprimentos/registrarEntrada" component={Apps.RegistrarEntrada} />
                <Route exact path="/suprimentos/registrarEntrada/:id" component={Apps.RegistrarEntradaView} />

                <Route exact path="/relatorio/OS" component={Apps.RelatorioOS} />
                <Route exact path="/relatorio/projeto" component={Apps.RelatorioProjeto} />
                <Route exact path="/relatorio/comunicacaoVenda" component={Apps.RelatorioComunicacaoVenda} />
                <Route exact path="/relatorio/acompanhamentoObra" component={Apps.RelatorioAcompanhamentoObra} />
                <Route exact path="/relatorio/alarm" component={Apps.Alerta} />
                <Route exact path="/relatorio/cliente" component={Apps.RelatorioCliente} />
                <Route exact path="/report/:id" component={Apps.Report} />
                <Route exact path='/report-adm/' component={Apps.ReportAdm} />

                <Route exact path='/rh/aberturaVagas' component={Apps.AberturaVagas} />
                <Route exact path='/rh/aberturaVagas/:id' component={Apps.AberturaVagasView} />
                <Route exact path='/rh/funcionarios' component={Apps.Funcionarios} />
                <Route exact path='/rh/funcionarios/:id' component={Apps.FuncionariosView} />
                <Route exact path='/rh/cargo' component={Apps.Cargo} />
                <Route exact path='/rh/cargo/:id' component={Apps.CargoView} />

                <Route exact path="/erro" component={Apps.Error} />
                <Route exact path="/report" component={Apps.Dashboard} />
            </AdminLTE>
        );
    }
}

export default withAuth(Portal);