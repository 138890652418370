import { toast } from 'react-toastify';
import { authService } from '../_services';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = data || response.statusText;

            if ([400].indexOf(response.status) !== -1) {
                error.errors ?
                    toast.error(JSON.stringify(error.errors)) :
                    toast.error(error);
            }
            else if ([401].indexOf(response.status) !== -1) {
                if (!authService.checkValidSession()) {
                    toast.error('Sessão expirada. Entre novamente!');
                    window.location.href = '#/login';
                }
            }
            else if ([403].indexOf(response.status) !== -1) {
                toast.error('Usuário não autorizado!');
            }
            else if ([404].indexOf(response.status) !== -1) {
                toast.error('Houve um erro ao acessar a api!');
            }
            else if ([500].indexOf(response.status) !== -1) {
                //toast.error(error);
            }
            else {
                //toast.error("Houve um erro não especificado!");
            }

            return Promise.reject(error);
        }

        return data;
    });
}