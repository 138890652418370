import React from 'react';
import { apiHelper, Content, config } from '../';
import { ReportView } from './ReportView';

// const schema = {
//     grid: [
//         { row: 1, cols: [{ col: 1, width: 12 }] },
//         { row: 2, cols: [{ col: 1, width: 8 }, { col: 2, width: 4 }] },
//     ],
//     widgets: [
//         { id: 1, reportId: 18, order: 0, row: 1, col: 1, refresh: 20, className: "card-danger", style: {}, title: { label: "Widget 01", className: "" } },
//         { id: 2, reportId: 30, order: 0, row: 2, col: 1, refresh: 20, className: "card-success", style: {}, title: { label: "Widget 02", className: "" } },
//         { id: 3, reportId: 18, order: 1, row: 2, col: 2, refresh: 20, className: "card-info", style: {}, title: { label: "Widget 03", className: "" } },
//     ]
// }

export function Dashboard(props) {
    const rowId = props.id || props.match.params.id;
    const [data, setData] = React.useState({});
    const [schema, setSchema] = React.useState({});

    React.useEffect(() => {
        const fetchData = async () => {
            let res = await apiHelper.get(`portal/crud/ReportsDashboard/${rowId}/id`)

            if (res.data.data) {
                setData(res.data.data)
                res.data.data.config && setSchema(JSON.parse(res.data.data.config))
            }
        }

        setSchema({});
        setData({});

        config.validarClaim("MenuDashboard") && fetchData();
    }, [rowId]);

    const getWidget = (row, col) => {
        return schema.widgets
            .filter(f => f.row === row && f.col === col)
            .map((w, i) =>
                <div key={i} className={`card ${w.className ? w.className : 'card-primary card-outline'}`}>
                    <div className="card-header ui-sortable-handle border-0" style={{ cursor: "move" }}>
                        <h3 className="card-title">{w.title}</h3>

                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fa fa-minus" /></button>
                            <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                        </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                        <ReportView reportId={w.reportId} admin={false} />
                    </div>
                </div>
            )
    }

    const renderGrid = () => {
        if (data.path) {
            try {
                let Control = require('../' + data.path).default;
                return <Control />;
            } catch (erro) {
                return <div>Erro ao renderizar o controle!</div>;
            }
        }

        return schema.grid && schema.grid.map((y, i) =>
            <div key={i} name={y.row} className="row">
                {y.cols.map((x, j) =>
                    <div key={j} name={x.id} className={`col-${x.width}`}>
                        {getWidget(y.row, x.col)}
                    </div>
                )}
            </div>
        )
    }

    return (
        props.id ?
            renderGrid() || <div>Não encontrado</div>
            :
            <Content title={data.title || "Não encontrado!"}>
                {renderGrid()}
            </Content>
    );
}