import React, { useEffect, useState } from 'react';
import { Link, apiHelper, Modal, toast } from '../index';
import { ContatoForm } from './ContatoForm';

export function Contato(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        try {
            apiHelper.get(`${endPoint}/${rowId}/contato?nome=''`)
                .then(res => {
                    
                    setData(res.data.data);
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    };

    useEffect(fetchData, [rowId]);

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    }

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onHide();
    }

    const handleDelete = (item) => {
        if (!window.confirm(`Deseja realmente excluir o contato ${item.nome}?`))
            return;

        apiHelper.api_delete(`${endPoint}/${rowId}/contato?emailContato=${item.email}`).then(res => {
            if (res.data.code === 200)
                fetchData();
            else
                toast.error('Atenção, não foi possível excluir o registro!');
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro!');
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Novo Contato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContatoForm parentId={rowId} onHide={handleHide} />
                </Modal.Body>
            </Modal>
            <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" />Adicionar Contato</button>
                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Nome</th>
                            <th>Telefone</th>
                            <th>Email</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.nome}</td>
                                        <td>{item.telefone}</td>
                                        <td>{item.email}</td>
                                        <td><Link replace to="#" onClick={() => handleDelete(item)}><i className="fa fa-trash" /></Link></td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>
                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
        </React.Fragment>
    );
}
