import React, { useState, useEffect } from 'react';
import { Modals, Button, config, apiHelper, toast } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function CentroCustoForm(props) {
    const endPoint = 'v2/regional/';
    const rowId = props.parentId;

    const [data, setData] = useState({
        nome: '',
        gerenteRegional: '',
        fkUsuarioGerenteRegional: 0
    });

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/${rowId}/pkRegional`)
                .then(res => {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setData(obj);

                }).catch(error => {
                    toast.error('');
                });
        }
    }


    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res.data);
                props.onImport();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkRegional`, data).then(res => {
                config.httpResult(res.data);
                props.onImport();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const handleAddGerenteRegional = (row) => {
        setData({ ...data, fkUsuarioGerenteRegional: row.pkUsuario, gerenteRegional: row.nome });
    }

    const onHide = () => {
        props.onHide();
    }

    return (
        <Modals title="Nova Regional" show={props.show} onHide={onHide}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Nome</label>
                        <input type="text" id="nome" className="form-control" onChange={handleChange} value={data.nome} />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-sm-12 col-md-12">
                        <PesquisaGenericaHooks required textField="nome" titulo="Gerente Regional" selecao={data.gerenteRegional} endpoint="portal/crud/usuario/?filter=nome::contains::" onImport={(row) => { handleAddGerenteRegional(row); }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="box-tool pull-right">
                            <div className="btn-toolbar">
                                <Button type="submit" bsStyle="success">Salvar</Button>
                                <Button bsStyle="default" onClick={onHide}>Cancelar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modals>
    )
}
