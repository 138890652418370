import React, { Component } from 'react';

export class FormGroup extends Component {
    render() {
        const { children, ...props } = this.props;
        return (
            <div className="form-group has-feedback">
                <input className="form-control" {...props} />
                {children}
            </div>
        )
    }
}