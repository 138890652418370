import React, { useState, useEffect } from 'react';
import { apiHelper } from '../../';

export function TicketResumo(props) {
    const endPoint = 'v3/os/';
    const [data, setData] = useState({});
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            try {
                apiHelper.get(`${endPoint}/pages?filter=statusId::in::1,2,3&initRow=1&endRow=10000`)
                    .then(res => {
                        if (res.data.data) {
                            let rows = res.data.data;

                            let obj = {
                                total: rows.length,
                                novas: rows.filter(f => f.statusId === 1).length,
                                andamento: rows.filter(f => f.statusId === 2).length,
                                pendentes: rows.filter(f => f.statusId === 3).length,
                                estouradas: rows.filter(f => f.percentualSLACorrente === 100).length,
                            }

                            setData(obj);
                        }
                    });
            } catch (error) { }
        }

        fetchData();
        setTimeout(() => setRefresh(!refresh), 30000);

    }, [refresh]);

    return (
        <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                    <span className="info-box-icon bg-success elevation-1"><i className="fas fa-folder-open" /></span>
                    <div className="info-box-content">
                        <span className="info-box-text">Novas</span>
                        <span className="info-box-number">{data.novas} ({data.novas && (data.novas * 100 / data.total).toFixed(2)}%)</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-tasks" /></span>
                    <div className="info-box-content">
                        <span className="info-box-text">Em Andamento</span>
                        <span className="info-box-number">{data.andamento} ({data.andamento && (data.andamento * 100 / data.total).toFixed(2)}%)</span>
                    </div>
                </div>
            </div>
            <div className="clearfix hidden-md-up"></div>
            <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-info elevation-1"><i className="fas fa-pause-circle" /></span>
                    <div className="info-box-content">
                        <span className="info-box-text">Pausadas</span>
                        <span className="info-box-number">{data.pendentes} ({data.pendentes && (data.pendentes * 100 / data.total).toFixed(2)}%)</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-bomb" /></span>
                    <div className="info-box-content">
                        <span className="info-box-text">Estouradas</span>
                        <span className="info-box-number">{data.estouradas} de {data.total} ({data.estouradas && (data.estouradas * 100 / data.total).toFixed(2)}%)</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
