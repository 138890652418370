import React, { useState } from 'react';
import { Modals, FieldGroup, Table, Button, FormGroupButton, apiHelper } from '../../modules/index';

export function PesquisaGenericaHooks(props) {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [params, setParams] = useState({
        titulo: props.titulo,
        endpoint: props.endpoint,
        onImport: props.onImport,
        textfield: props.textField ? props.textField : 'nome',
        selecao: props.selecao ? props.selecao : '',
        icon: props.icon,
        row: props.row
    });

    const fetchData = (filter) => {
        apiHelper.get(`${params.endpoint}${filter}`)
            .then(res => {                
                setData(res.data.data);
            }).catch(error => {
            });
    }

    const handleFilter = (e) => {
        let value = e === null ? '' : e.target.value;
        setParams({ ...params, selecao: value });

        if (value.length > 2) {
            fetchData(value);
        } else {
            setData([]);
        }
    }

    const onHide = () => {
        setParams({ ...params, selecao: '' });
        setShowModal(false);
        setData([]);

        props.onHide && props.onHide();
    }

    const setRow = (row) => {
        setParams({ ...params, selecao: row[params.textfield] });
        setData([]);
        setShowModal(false);
        props.onImport(row);
    }

    return (
        <React.Fragment>
            <FormGroupButton id="selecao" placeholder="Filtro" label={params.titulo} required={props.required ? true : false} disabled value={params.selecao}
                buttonRight={<Button bsStyle="success" type="button" onClick={() => setShowModal(true)}>{params.icon ? <i className={params.icon} /> : 'Selecionar'}</Button>} />

            <Modals title={params.titulo ? params.titulo : 'Filtrar'} show={showModal} onHide={onHide}>
                <FieldGroup placeholder="Filtro" id="name" autoFocus col={12} type="text" onChange={handleFilter} value={params.filtro} />

                <Table striped bordered hover>
                    <tbody>
                        {data.filter((item) => {
                            return !item[params.textfield].includes('OBSOLETO');
                        }).map((row, i) => {
                            return (
                                <tr key={i}>
                                    <td>{row[params.textfield]}</td>
                                    <td style={{ width: "50px" }}><Button bsSize="xsmall" bsStyle="link" onClick={() => setRow(row)}>Adicionar</Button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Modals>
        </React.Fragment>
    );
}

