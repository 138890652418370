import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, config, Modal } from '../';
import { ClienteForm } from './ClienteForm';
import { Endereco } from './Endereco';
import { Interacao } from './Interacao';
import { Contato } from './Contato';

export function ClienteView(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.match.params.id;
    const [data, setData] = useState({});
    const [enderecoCorrespondencia, setEnderecoCorrespondencia] = useState({});
    const [enderecoFaturamento, setEnderecoFaturamento] = useState({});
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: ""
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}/${rowId}/cliente`).then(res => {
            let data = res.data.data;
            let EnderecoCorrespondencia = {};
            let EnderecoFaturamento = {};

            EnderecoCorrespondencia = {
                uf: data.enderecoCorrepondencia.uf,
                cidade: data.enderecoCorrepondencia.cidade,
                endereco: data.enderecoCorrepondencia.endereco,
                cep: data.enderecoCorrepondencia.cep,
                bairro: data.enderecoCorrepondencia.bairro,
                logradouro: data.enderecoCorrepondencia.logradouro,
                complemento: data.enderecoCorrepondencia.complemento,
                numero: data.enderecoCorrepondencia.numero
            };

            EnderecoFaturamento = {
                uf: data.enderecoFaturamento.uf,
                cidade: data.enderecoFaturamento.cidade,
                endereco: data.enderecoFaturamento.endereco,
                cep: data.enderecoFaturamento.cep,
                bairro: data.enderecoFaturamento.bairro,
                logradouro: data.enderecoFaturamento.logradouro,
                complemento: data.enderecoFaturamento.complemento,
                numero: data.enderecoFaturamento.numero
            };

            setData(data);
            setEnderecoCorrespondencia(EnderecoCorrespondencia);
            setEnderecoFaturamento(EnderecoFaturamento);
        });
    };

    useEffect(fetchData, [rowId]);

    const handleClose = () => {
        props.history.push({
            pathname: '/comercial/cliente'
        });
    };

    const handleEdit = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: rowId && rowId > 0 ? title + ' ' + rowId : title,
            formName: name,
        });
    };

    const handleHide = (refresh= true) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    return (
        <Content title="Gestão de Clientes" browserTitle="Detalhes Gestão de Clientes">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id} - {data.nome}</h3>
                            <div className="box-tools pull-right">
                                {config.validarClaim('cliente.escrita') &&
                                    <React.Fragment>
                                        <button title="Editar" type="button" className="btn btn-box-tool" onClick={() => handleEdit('editarCliente', 'Editar Cliente', rowId)}><i className="fa fa-pencil" /></button>
                                        <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                                    </React.Fragment>}
                            </div>
                        </div>
                        <div className="box-body">
                            <dl>
                                <dt>Razão Social</dt>
                                <dd>{data.razaoSocial}</dd>
                                <dt>CNPJ ou CPF</dt>
                                <dd>{data.cpfCnpj}</dd>
                                <dt>Inscrição Estadual</dt>
                                <dd>{data.inscricaoEstadual !== null ? data.inscricaoEstadual : 'N/A'} </dd>
                                <dt>Inscrição Municipal</dt>
                                <dd>{data.inscricaoMunicipal !== null ? data.inscricaoMunicipal : 'N/A'}</dd>
                                <dt>Tipo</dt>
                                <dd>{data.tipoPessoa}</dd>
                                <dt>Tipo Empresa</dt>
                                <dd>{data.tipoEmpresa}</dd>
                                <dt>Ativo</dt>
                                <dd>{data.ativo === true ? 'Sim' : 'Não'}</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Correspondências</h3>
                        </div>
                        <div className="box-body">
                            <dl>
                                <dt><strong><i className="fa fa-map-marker margin-r-5" />Endereços</strong>
                                    <div className="box-tools pull-right">
                                        {config.validarClaim('cliente.escrita') && <button title="Editar" type="button" className="btn btn-box-tool" onClick={() => handleEdit('enderecoCorrespondencia', 'Editar Endereço de Correspondência', rowId)}><i className="fa fa-pencil" /></button>}
                                    </div>
                                </dt>
                                <dd>{enderecoCorrespondencia && (enderecoCorrespondencia.logradouro === null ? 'Nao informado' : `${enderecoCorrespondencia.logradouro}, ${enderecoCorrespondencia.numero}/${enderecoCorrespondencia.complemento} ${enderecoCorrespondencia.bairro} - ${enderecoCorrespondencia.cidade}, ${enderecoCorrespondencia.uf}, cep ${enderecoCorrespondencia.cep}`)}</dd>
                                <hr />
                                <dt><strong><i className="fa fa-map-marker margin-r-5" />Faturamento</strong>
                                    <div className="box-tools pull-right">
                                        {config.validarClaim('cliente.escrita') && <button title="Editar" type="button" className="btn btn-box-tool" onClick={() => handleEdit('enderecoFaturamento', 'Editar Endereço de Faturamento', rowId)}><i className="fa fa-pencil" /></button>}
                                    </div>
                                </dt>
                                <dd>{enderecoFaturamento && (enderecoFaturamento.logradouro === null ? 'Nao informado' : `${enderecoFaturamento.logradouro}, ${enderecoFaturamento.numero}/${enderecoFaturamento.complemento} ${enderecoFaturamento.bairro} - ${enderecoFaturamento.cidade}, ${enderecoFaturamento.uf}, cep ${enderecoFaturamento.cep}`)}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><Link to="#tab_interacao" data-toggle="tab">Interações</Link></li>
                            <li><Link to="#tab_contato" data-toggle="tab">Contatos</Link></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_interacao" className="tab-pane active">
                                <Interacao parentId={rowId} onHide={handleHide} />
                            </div>
                            <div id="tab_contato" className="tab-pane">
                                <Contato parentId={rowId} onHide={handleHide} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={() => handleHide()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'editarCliente' && <ClienteForm parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'enderecoCorrespondencia' && <Endereco parentId={params.rowId} tipoEndereco={'correspondencia'} onHide={handleHide} />}
                    {params.formName === 'enderecoFaturamento' && <Endereco parentId={params.rowId} tipoEndereco={'faturamento'} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
