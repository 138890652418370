import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../index';
import { Select } from '../../components/controls/crud/Select';
import ReactSelect from 'react-select';

export function DashboardForm(props) {
    const endPoint = 'Dashboard/boardReport';
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [grupos, setGrupos] = useState({});
    const [defaultGrupos, setDefaultGrupos] = useState({});

    const fetchData = () => {
        let groups = [];
        apiHelper.get('portal/crud/IdentityGroup').then(resp => {
            resp.data.data.map((item) =>
                groups.push({ value: item.id, label: item.name })
            );
            setGrupos(groups);
        }).catch(error => {
            toast.error('Houve um erro ao carregar o registro.');
        });
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let obj = {
            ...data,
            idsGrupo: (defaultGrupos && defaultGrupos.length > 0) ? defaultGrupos.map(item => item.value) : null
        };
        apiHelper.post(`${endPoint}`, obj)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success("Dashboard cadastrado com sucesso!");
                    props.onHide(true);
                }
                else
                    toast.error('Atenção, não foi possível salvar o registro.');

            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const handleChangeGrupo = (e) => {
        setDefaultGrupos(e);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ""} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Grupo</label>
                    <ReactSelect
                        id="idsGrupo"
                        value={(defaultGrupos && defaultGrupos.length > 0) && defaultGrupos}
                        isMulti
                        name="colors"
                        options={grupos}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleChangeGrupo}
                    />
                </div>
                <div className="form-group col-sm-8">
                    <label>Descrição</label>
                    <input id="descricao" type="text" className="form-control" onChange={handleChange} value={data.descricao || ""} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Status</label>
                    <Select id="ativo" className="form-control" onChange={handleChange} value={data.ativo}>
                        <option value={true}>Ativo</option>
                        <option value={false}>Inativo</option>
                    </Select>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    )
}