import React, { useState, useEffect } from 'react';
import { apiHelper, Link, utils, GoogleMap } from '../../index';

export function Tecnicos(props) {
    const endPoint = 'v2/report/28';
    const [rows, setRows] = useState([]);
    const [detail, setDetail] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const fetchData = () => {
            try {
                detail.pkOs && setDetail(rows.filter(p => p.pkOs === detail.pkOs)[0]);

                apiHelper.get(`${endPoint}`).then(res => {
                    if (res.data.data) {
                        setRows(res.data.data);
                    }
                });
            } catch (error) { }
        }

        fetchData();
        setTimeout(() => setRefresh(!refresh), 30000);

        // eslint-disable-next-line
    }, [refresh]);

    const getDetail = (id) => {
        try {
            let x = rows.filter(p => p.pkOs === id)[0];
            setDetail(x);
        } catch (error) { }
    }

    const handleButtonFilter = (e) => {
        let id = e.target.id;
        //let filtredData = [];
        // switch (id) {
        //     case "btn-online":
        //         filtredData = rows.filter(m => m.tempoUltimaLocalizacao && m.tempoUltimaLocalizacao < 30);
        //         break;
        //     case "btn-acionado":
        //         filtredData = rows.filter(m => m.pkOs);
        //     case "btn-deslocamento":
        //         filtredData = rows.filter(m => m.tempoDeDeslocamento && !m.tempoDeAtendimento);
        //     case "btn-atendimento":
        //         filtredData = rows.filter(m => m.tempoDeAtendimento && !m.dataFimAtendimento);
        //     case "btn-disponivel":
        //         filtredData = rows.filter(m => m.pkOs);
        //     default:
        //         filtredData = rows;
        //         setFilter(id);
        //         break;
        // }
        // console.log(filtredData);
        // setRows(filtredData);
        setFilter(id);
        //setFilter({ tempoUltimaLocalizacao: 30 })
    }

    const initCenter = {
        lat: detail.latitude ? parseFloat(detail.latitude.replace(',', '.')) : -18.096408,
        lng: detail.longitude ? parseFloat(detail.longitude.replace(',', '.')) : -50.659592
    }

    return (
        <div className="card card-info card-outline">
            <div className="card-header">
                <h3 className="card-title">Painel de Técnicos</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" /></button>
                    <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                </div>
            </div>
            <div className="card-header p-1">
                <button onClick={handleButtonFilter} id="btn-todos" className={`btn btn-${filter === "btn-todos" || filter === "" ? "primary" : "default"} btn-sm mr-1`}>Todos</button>
                <button onClick={handleButtonFilter} id="btn-online" className={`btn btn-${filter === "btn-online" ? "primary" : "default"} btn-sm mr-1`}>Online</button>
                <button onClick={handleButtonFilter} id="btn-acionado" className={`btn btn-${filter === "btn-acionado" ? "primary" : "default"} btn-sm mr-1`}>Acionados</button>
                <button onClick={handleButtonFilter} id="btn-deslocamento" className={`btn btn-${filter === "btn-deslocamento" ? "primary" : "default"} btn-sm mr-1`}>Deslocamento</button>
                <button onClick={handleButtonFilter} id="btn-atendimento" className={`btn btn-${filter === "btn-atendimento" ? "primary" : "default"} btn-sm mr-1`}>Atendimento</button>
                <button onClick={handleButtonFilter} id="btn-disponivel" className={`btn btn-${filter === "btn-disponivel" ? "primary" : "default"} btn-sm mr-1`}>Disponiveis</button>
            </div>
            <div className="card-body p-1" style={{ height: 350, overflow: 'auto' }}>
                <table className="table table-striped table-bordered m-0">
                    <tbody>
                        {rows.map((m, i) =>
                            <tr key={i}>
                                <td className="p-2">
                                    <Link className={`badge ${(!m.tempoUltimaLocalizacao || m.tempoUltimaLocalizacao > 30) ? 'text-danger' : 'text-success'} float-right`} to="#" onClick={() => props.onSelect(m)}>
                                        <i className="fas fa-map-marker-alt" /> {m.ultimaLocalizacao ? utils.dataTypeFormat('datetime-m', m.ultimaLocalizacao) : "Sem localização"}
                                    </Link>
                                    <div className="text-sm text-uppercase">
                                        {m.pkOs
                                            ? <Link className="product-title text-uppercase text-sm" to="#" onClick={() => getDetail(m.pkOs)}>{m.tecnico}</Link>
                                            : m.tecnico
                                        }
                                    </div>
                                    <div className="float-right">
                                        <span className={`badge badge-${(m.tempoDeDeslocamento && !m.dataFimDeslocamento) ? 'success' : 'secondary'} ml-1`}>
                                            <i className="fas fa-car" /> {m.tempoDeDeslocamento || "00:00"}
                                        </span>
                                        <span className={`badge badge-${(m.tempoDeAtendimento && !m.dataFimAtendimento) ? 'success' : 'secondary'} ml-1`}>
                                            <i className="fas fa-wrench" /> {m.tempoDeAtendimento || "00:00"}
                                        </span>
                                    </div>
                                    {m.pkOs ? <div className="text-sm text-uppercase">{m.pkOs} - {m.site || ""}</div> : "Sem atendimento"}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {detail.pkOs &&
                    <div className="modal fade show" id="modal-tecnico" style={{ display: detail.pkOs ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="card card-info m-0">
                                    <div className="card-header">
                                        <h3 className="card-title">{detail.tecnico}</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus" /></button>
                                            <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                                            <button type="button" className="btn btn-tool" onClick={() => setDetail({})}><i className="fas fa-times" /></button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <dl>
                                                    <dt>SITE</dt>
                                                    <dd>{detail.pkOs} - {detail.site}</dd>
                                                    <dt>Localização do Site</dt>
                                                    <dd>
                                                        <table className="table table-sm table-bodered table-striped m-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Latitude</td>
                                                                    <td>{detail.latitudeSite && detail.latitudeSite}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Longitude</td>
                                                                    <td>{detail.longitudeSite && detail.longitudeSite}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Cerca</td>
                                                                    <td>{detail.raio && detail.raio} metros</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </dd>
                                                    <dt>Última localização</dt>
                                                    <dd>{detail.ultimaLocalizacao ? utils.dataTypeFormat('datetime-m', detail.ultimaLocalizacao) : "Sem localização"}</dd>
                                                    <dt>Deslocamento</dt>
                                                    <dd>
                                                        <table className="table table-sm table-bodered table-striped m-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Inicio:</td>
                                                                    <td>{detail.dataDeslocamento && utils.dataTypeFormat('datetime-m', detail.dataDeslocamento)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fim:</td>
                                                                    <td>{detail.dataFimDeslocamento && utils.dataTypeFormat('datetime-m', detail.dataFimDeslocamento)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tempo:</td>
                                                                    <td>{detail.tempoDeDeslocamento || "00:00"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </dd>
                                                    <dt>Atendimento</dt>
                                                    <dd>
                                                        <table className="table table-sm table-bodered table-striped m-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Data:</td>
                                                                    <td>{detail.dataInicioAtendimento && utils.dataTypeFormat('datetime-m', detail.dataInicioAtendimento)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Distância:</td>
                                                                    <td>{detail.distanciaInicio} km</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </dd>
                                                    <dt>Fim de Atendimento</dt>
                                                    <dd>
                                                        <table className="table table-sm table-bodered table-striped m-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Data:</td>
                                                                    <td>{detail.dataFimAtendimento && utils.dataTypeFormat('datetime-m', detail.dataFimAtendimento)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tempo:</td>
                                                                    <td>{detail.tempoDeAtendimento || "00:00"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Distância:</td>
                                                                    <td>{detail.distanciaFim} km</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-8">
                                                <GoogleMap height="100%" initCenter={initCenter} zoomLevel={detail.latitude ? 14 : 4}>
                                                    <maker position={{ lat: detail.latitude, lng: detail.longitude, title: detail.tecnico }} />
                                                    <circle circlePosition={{ lat: detail.latitudeSite, lng: detail.longitudeSite, radius: detail.raio }} />
                                                </GoogleMap>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
