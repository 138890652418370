import React, { Component } from 'react';
import {
    Content, Row, Box, Col, Table, Button, FormGroupButton
} from '../../components/adminlte/AdminLTE';

const config = {
    keyName: 'name',
    rowName: 'name',
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiUrl: localStorage.getItem('apiUrl'),
    endPoint: localStorage.getItem('apiUrl') + 'oi/porta/'
};

export class PortaPonto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table: [],
            tableSearch: ''
        };

        this.tableSearch = this.tableSearch.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    getData(filter) {
        if (filter === undefined) filter = '';

        fetch(config.endPoint + '?filter=' + filter, {
            headers: { 'Authorization': config.token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ table: data });
        });
    }

    tableSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ tableSearch: value });

        if (value.length > 2 || value === '')
            this.getData(value);
    }

    addRemove(doorId, action) {
        if (action === 'del') {
            this.remove(doorId);
            return;
        }

        fetch(config.endPoint + '?doorId=' + doorId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.getData(this.state.tableSearch);
            }
            else
                alert(response[1]["msg"]);
        }).catch(error => {
            alert(error);
        });
    }

    remove(doorId) {
        fetch(config.endPoint + doorId, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.getData(this.state.tableSearch);
            }
            else
                alert(response[1]["msg"]);
        }).catch(error => {
            alert(error);
        });
    }

    render() {
        return (
            <Content title="Portas de Ponto" browserTitle="OI Portal | Ponto">
                <Row>
                    <Col md={4}>
                        <Box>
                            <FormGroupButton size="sm" placeholder="Buscar porta"
                                onChange={this.tableSearch} value={this.state.tableSearch}
                                buttonRight={<Button text="Limpar" onClick={() => this.tableSearch(null)}>LIMPAR</Button>}
                            />
                        </Box>
                    </Col>
                    <Col md={8}>
                        <Box>
                            <Table striped bordered hover>
                                <tbody>
                                    {this.state.table.map(row => {
                                        return (
                                            <tr key={row.name}>
                                                <td>{row.name}</td>
                                                <td style={{ width: "50px" }}>
                                                    <Button bsSize="xsmall"
                                                        bsStyle={row.ponto === 0 ? "default" : "success"}
                                                        onClick={() => this.addRemove(row.guid, row.ponto === 0 ? 'add' : 'del')}
                                                    >
                                                        {row.ponto === 0 ? 'Adicionar' : 'Remover'}
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Box>
                    </Col>
                </Row>
            </Content>
        );
    }
}