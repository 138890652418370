import React, { useState, useEffect } from 'react';
import { apiHelper } from './../../index';

export function GestaoSites(props) {
    const endPoint = 'atendimentoReport/gestaoSites';
    const [data, setData] = useState([]);
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.idCliente)
            return;
        apiHelper.get(`${endPoint}?idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.idCliente]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Gestão dos Sites</h3>
            </div>
            <div className="panel-body">
                {data !== undefined && <div className="row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin">
                                <thead>
                                    <tr>
                                        <th className="bg-light-blue color-palette">Total de Sites</th>
                                        <td>{data.qtdSites}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-light-blue color-palette">Total de Dispositivos Ativos SCA</th>
                                        <td>{data.qtdDispositivos}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
