import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select, Link } from '../index';

let listSetores = [];
let listLocalTrabalho = [];
let listHorarioTrabalho = [];
let arrayLocaisTrabalho = [];

export function CargoForm(props) {
    const endPoint = "cargo";
    const rowId = props.parentId;
    const [data, setData] = useState([]);
    const [localTrabalho, setLocalTrabalho] = useState([]);
    const [dataSetores, setDataSetores] = useState([]);
    const [dataLocaisTrabalho, setDataLocaisTrabalho] = useState([]);
    const [dataHorariosTrabalho, setDataHorariosTrabalho] = useState([]);

    const fetchData = () => {
        listSetores = [];
        listLocalTrabalho = [];
        listHorarioTrabalho = [];
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}/cargo`).then(res => {

                let data = res.data.data;
                let obj = {
                    nome: data[0].nome,
                    perfil: data[0].perfil,
                    regimeContratacao: data[0].fkRegimeContratacao,
                    grauEscolaridade: data[0].fkGrauEscolaridade,
                    nomeCurso: data[0].nomeCurso,
                    isCompleto: data[0].isCompleto,
                    salarioAtual: data[0].salarioAtual,
                    nivelConhecimentoInformatica: data[0].fkNivelConhecimentoInformatica,
                    ingles: data[0].ingles,
                    espanhol: data[0].espanhol,
                    frances: data[0].frances,
                    fkNivelConhecimentoIngles: data[0].fkNivelConhecimentoIngles,
                    fkNivelConhecimentoEspanhol: data[0].fkNivelConhecimentoEspanhol,
                    fkNivelConhecimentoFrances: data[0].fkNivelConhecimentoFrances,
                    nomeOutroIdioma: data[0].nomeOutrosIdiomas,
                    outrosCursos: data[0].outrosCursos,
                    centroCusto: data[0].fkCentroCusto,
                    usuarioAprovador: data[0].fkUsuarioAprovacao
                }

                setData(obj);

                obterSetores();
                obterLocaisTrabalho();
                obterHorariosTrabalho();

            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    const obterSetores = () => {
        apiHelper.get(`${endPoint}/${rowId}/setores`).then(res => {
            let data = res.data.data;
            data.forEach((item) => {
                let obj = { setor: item.fkSetor, nomeSetor: item.nome };
                listSetores.push(obj);
            });
            setDataSetores({ ...dataSetores, listSetores });

        }).catch(error => {
            toast.error('Houve um erro ao carregar os setores.');
        });
    }

    const obterLocaisTrabalho = () => {
        apiHelper.get(`${endPoint}/${rowId}/locaisTrabalho`).then(res => {
            let data = res.data.data;
            data.forEach((item) => {
                let obj = { localTrabalho: item.fkLocalTrabalho, nomeLocalTrabalho: item.nome };
                listLocalTrabalho.push(obj);
            });
            setDataLocaisTrabalho({ ...dataLocaisTrabalho, listLocalTrabalho });

        }).catch(error => {
            toast.error('Houve um erro ao carregar os locais de trabalho.');
        });
    }

    const obterHorariosTrabalho = () => {
        apiHelper.get(`${endPoint}/${rowId}/horariosTrabalho`).then(res => {
            let data = res.data.data;
            data.forEach((item) => {
                let obj = {

                    horarioTrabalho: item.fkHorarioTrabalho, nomeHorarioTrabalho: item.diaSemanaHorario
                };
                listHorarioTrabalho.push(obj);
            });
            setDataHorariosTrabalho({ ...dataHorariosTrabalho, listHorarioTrabalho });

        }).catch(error => {
            toast.error('Houve um erro ao carregar os horários de trabalho.');
        });
    }

    useEffect(fetchData, [rowId]);

    const obterLocalTrabalho = (idSetor) => {
        apiHelper.get(`${endPoint}/${idSetor}/localTrabalho`).then(res => {
            let data = res.data;
            let locaisConcatenados = concatenarArrayLocalTrabalho(data);
            arrayLocaisTrabalho = [];
            arrayLocaisTrabalho.push(locaisConcatenados);

            setLocalTrabalho({ ...localTrabalho, local: arrayLocaisTrabalho })
        }).catch(error => {
            toast.error('Houve um erro ao carregar o registro.');
        });

    }

    const concatenarArrayLocalTrabalho = (data) => {
        let aux = [];

        data.map((item) => {
            let obj = {
                pkLocalTrabalho: item.pkLocalTrabalho,
                nome: item.nome,
                pkSetor: item.pkSetor
            }

            aux.push(obj);
            return "";
        });

        arrayLocaisTrabalho.map((item) => {
            let obj = {
                pkLocalTrabalho: item.pkLocalTrabalho,
                nome: item.nome,
                pkSetor: item.pkSetor
            }

            aux.push(obj);
            return "";
        });

        return aux;
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (data.grauEscolaridade !== '3' && data.grauEscolaridade !== '4') {
            setData({ ...data, nomeCurso: "" });
        }

        if (name === 'ingles' && value === false) {
            setData({ ...data, [name]: value, fkNivelConhecimentoIngles: '' });
        } else if (name === 'espanhol' && value === false) {
            setData({ ...data, [name]: value, fkNivelConhecimentoEspanhol: '' });
        } else if (name === 'frances' && value === false) {
            setData({ ...data, [name]: value, fkNivelConhecimentoFrances: '' });
        } else if (name === 'outrosIdiomas' && value === false) {
            setData({ ...data, [name]: value, nomeOutroIdioma: '' });
        } else if (name === 'setor') {
            let nomeTextField = e.target.selectedOptions[0].innerText;
            setData({ ...data, [name]: value, nomeSetor: nomeTextField });
        } else if (name === 'localTrabalho') {
            let nomeTextField = e.target.selectedOptions[0].innerText;
            setData({ ...data, [name]: value, nomeLocalTrabalho: nomeTextField });
        } else if (name === 'horarioTrabalho') {
            let nomeTextField = e.target.selectedOptions[0].innerText;
            setData({ ...data, [name]: value, nomeHorarioTrabalho: nomeTextField });
        } else
            setData({ ...data, [name]: value });
    }

    const handleAdicionarSetor = () => {
        if (data.setor !== undefined && data.setor !== '') {
            let obj = { setor: data.setor, nomeSetor: data.nomeSetor };
            listSetores.push(obj);
            setDataSetores({ ...dataSetores, listSetores });

            obterLocalTrabalho(data.setor);
        }
    }

    const handleAdicionarLocalTrabalho = () => {
        if (data.localTrabalho !== undefined && data.localTrabalho !== '') {
            let obj = { localTrabalho: data.localTrabalho, nomeLocalTrabalho: data.nomeLocalTrabalho };
            listLocalTrabalho.push(obj);
            setDataLocaisTrabalho({ ...dataLocaisTrabalho, listLocalTrabalho });
        }
    }

    const handleAdicionarHorarioTrabalho = () => {
        if (data.horarioTrabalho !== undefined && data.horarioTrabalho !== '') {
            let obj = { horarioTrabalho: data.horarioTrabalho, nomeHorarioTrabalho: data.nomeHorarioTrabalho };
            listHorarioTrabalho.push(obj);
            setDataHorariosTrabalho({ ...dataHorariosTrabalho, listHorarioTrabalho });
        }
    }

    const handleRemoverSetor = (item) => {
        listSetores = listSetores.filter(c => c.setor !== item.setor);
        setDataSetores({ ...dataSetores, listSetores });
    }

    const handleRemoverLocalTrabalho = (item) => {
        listLocalTrabalho = listLocalTrabalho.filter(c => c.localTrabalho !== item.localTrabalho);
        setDataLocaisTrabalho({ ...dataLocaisTrabalho, listLocalTrabalho });
    }

    const handleRemoverHorarioTrabalho = (item) => {
        listHorarioTrabalho = listHorarioTrabalho.filter(c => c.horarioTrabalho !== item.horarioTrabalho);
        setDataHorariosTrabalho({ ...dataHorariosTrabalho, listHorarioTrabalho });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let obj = {
            data,
            dataSetores,
            dataLocaisTrabalho,
            dataHorariosTrabalho
        };

        if (rowId === 0) {
            apiHelper.post(`${endPoint}`, obj)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}`, obj)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-8">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Regime de Contratação</label>
                    <Select id="regimeContratacao" className="form-control" onChange={handleChange} value={data.regimeContratacao}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhRegimeContratacao" valuefield="pkRegimeContratacao" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <label>Perfil</label>
                    <textarea id="perfil" className="form-control" rows="3" maxLength="200" onChange={handleChange} value={data.perfil} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Escolaridade</label>
                    <Select id="grauEscolaridade" className="form-control" onChange={handleChange} value={data.grauEscolaridade}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhGrauEscolaridade" valuefield="pkGrauEscolaridade" textfield="nome" />
                    </Select>
                </div>
                {data.grauEscolaridade && ((data.grauEscolaridade === 3 || data.grauEscolaridade === '3') || (data.grauEscolaridade === 4 || data.grauEscolaridade === '4')) &&
                    <div className="form-group col-sm-12">
                        <label>Nome do Curso *</label>
                        <input id="nomeCurso" type="text" className="form-control" onChange={handleChange} value={data.nomeCurso} required />
                    </div>}
                <div className="form-group col-sm-6">
                    <label>Completo </label>
                    <Select id="isCompleto" className="form-control" onChange={handleChange} value={data.isCompleto}>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Salário atual</label>
                    <input id="salarioAtual" type="number" className="form-control" onChange={handleChange} value={data.salarioAtual} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Informática</label>
                    <Select id="nivelConhecimentoInformatica" className="form-control" onChange={handleChange} value={data.nivelConhecimentoInformatica}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhNivelConhecimento" valuefield="pkNivelConhecimento" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <label style={{ paddingRight: 10 }}>Idioma</label>
                    <label className="checkbox-inline"><input type="checkbox" id="ingles" onChange={handleChange} checked={data.ingles ? data.ingles : false} /> Inglês</label>
                    <label className="checkbox-inline"><input type="checkbox" id="espanhol" onChange={handleChange} checked={data.espanhol ? data.espanhol : false} /> Espanhol</label>
                    <label className="checkbox-inline"><input type="checkbox" id="frances" onChange={handleChange} checked={data.frances ? data.frances : false} /> Frances</label>
                    <label className="checkbox-inline"><input type="checkbox" id="outrosIdiomas" onChange={handleChange} checked={data.outrosIdiomas ? data.outrosIdiomas : false} /> Outros</label>
                </div>
                {data.ingles && <div className="form-group col-sm-4">
                    <label>Nível Inglês *</label>
                    <Select id="fkNivelConhecimentoIngles" className="form-control" onChange={handleChange} value={data.fkNivelConhecimentoIngles} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhNivelConhecimento" valuefield="pkNivelConhecimento" textfield="nome" />
                    </Select>
                </div>}
                {data.espanhol && <div className="form-group col-sm-4">
                    <label>Nível Espanhol *</label>
                    <Select id="fkNivelConhecimentoEspanhol" className="form-control" onChange={handleChange} value={data.fkNivelConhecimentoEspanhol} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhNivelConhecimento" valuefield="pkNivelConhecimento" textfield="nome" />
                    </Select>
                </div>}
                {data.frances && <div className="form-group col-sm-4">
                    <label>Nível Frances *</label>
                    <Select id="fkNivelConhecimentoFrances" className="form-control" onChange={handleChange} value={data.fkNivelConhecimentoFrances} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhNivelConhecimento" valuefield="pkNivelConhecimento" textfield="nome" />
                    </Select>
                </div>}
                {data.outrosIdiomas && <div className="form-group col-sm-12">
                    <label>Outros Idiomas *</label>
                    <input id="nomeOutroIdioma" type="text" className="form-control" onChange={handleChange} value={data.nomeOutroIdioma} required />
                </div>}
                <div className="form-group col-sm-12">
                    <label>Outros Cursos</label>
                    <textarea id="outrosCursos" className="form-control" rows="3" maxLength="200" onChange={handleChange} value={data.outrosCursos} />
                </div>
                <div className="form-group col-sm-10">
                    <label>Setor</label>
                    <Select id="setor" className="form-control" onChange={handleChange} value={data.setor}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhSetor" valuefield="pkSetor" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-2">
                    <Link to="#" onClick={handleAdicionarSetor}>Adicionar</Link>
                </div>
                {dataSetores.listSetores && dataSetores.listSetores.length > 0 &&
                    <div className="form-group col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                <thead>
                                    <tr className="bg-light-blue">
                                        <th>Setor</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataSetores.listSetores.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.nomeSetor}</td>
                                                <td><Link to="#" onClick={() => handleRemoverSetor(item)}><i class="fa fa-trash-o"></i></Link></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                <div className="form-group col-sm-10">
                    <label>Local de trabalho</label>
                    <Select id="localTrabalho" className="form-control" onChange={handleChange} value={data.localTrabalho}>
                        <option value="">Selecione</option>
                        {localTrabalho.local !== undefined && localTrabalho.local.length > 0 &&
                            localTrabalho.local[0].map((item, i) => {
                                return (<option value={item.pkLocalTrabalho}>{item.nome}</option>)

                            })}
                    </Select>
                </div>
                <div className="form-group col-sm-2">
                    <Link to="#" onClick={handleAdicionarLocalTrabalho}>Adicionar</Link>
                </div>
                {dataLocaisTrabalho.listLocalTrabalho && dataLocaisTrabalho.listLocalTrabalho.length > 0 &&
                    <div className="form-group col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                <thead>
                                    <tr className="bg-light-blue">
                                        <th>Local de trabalho</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLocaisTrabalho.listLocalTrabalho.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.nomeLocalTrabalho}</td>
                                                <td><Link to="#" onClick={() => handleRemoverLocalTrabalho(item)}><i class="fa fa-trash-o"></i></Link></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                <div className="form-group col-sm-10">
                    <label>Horário de trabalho</label>
                    <Select id="horarioTrabalho" className="form-control" onChange={handleChange} value={data.horarioTrabalho}>
                        <option value="">Selecione</option>
                        <option endpoint={`${endPoint}/horarioTrabalho`} valuefield="pkHorarioTrabalho" textfield="diaSemanaHorario" />
                    </Select>
                </div>
                <div className="form-group col-sm-2">
                    <Link to="#" onClick={handleAdicionarHorarioTrabalho}>Adicionar</Link>
                </div>
                {dataHorariosTrabalho.listHorarioTrabalho && dataHorariosTrabalho.listHorarioTrabalho.length > 0 &&
                    <div className="form-group col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                <thead>
                                    <tr className="bg-light-blue">
                                        <th>Horário de trabalho</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataHorariosTrabalho.listHorarioTrabalho.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.nomeHorarioTrabalho}</td>
                                                <td><Link to="#" onClick={() => handleRemoverHorarioTrabalho(item)}><i class="fa fa-trash-o"></i></Link></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                <div className="form-group col-sm-6">
                    <label>Centro de Custo</label>
                    <Select id="centroCusto" className="form-control" onChange={handleChange} value={data.centroCusto}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/CentroDeCusto" valuefield="pkCentroCusto" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Aprovador por</label>
                    <Select id="usuarioAprovador" className="form-control" onChange={handleChange} value={data.usuarioAprovador}>
                        <option value="">Selecione</option>
                        <option endpoint={`${endPoint}/aprovadores?groupName=Recursos Humanos`} valuefield="pkUsuario" textfield="nome" />
                    </Select>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
