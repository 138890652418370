import React, { useEffect, useState } from 'react';
import { config, apiHelper, Modal } from '../index';
import { LeadInteracaoForm } from './LeadInteracaoForm';

let dataAux = [];
let usuarios = [];
export function LeadInteracao(props) {
    const endPoint = 'portal/crud/ComercialLeadInteracao';
    const endPointUsuario = 'portal/crud/Usuario';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=leadId::equal::${parentId}`)
            .then(res => {
                dataAux = res.data.data;
                obterNomeUsuario(dataAux);
            });
    };

    const obterNomeUsuario = (interacoes) => {
        let ids = interacoes.map((item, i) => { return item.criadorId });
        if (ids.length > 0) {
            let distinctIds = ids.filter((v, i, a) => a.indexOf(v) === i);
            apiHelper.get(`${endPointUsuario}?filter=pkUsuario::in::${distinctIds.toString()}`)
                .then(res => {
                    usuarios = res.data.data;
                    dataAux.map((item, i) => {
                        dataAux[i].nome = usuarios.find(c => c.pkUsuario === item.criadorId).nome;
                        return null;
                    });
                    setData(dataAux);
                });
        }
    }

    useEffect(fetchData, [parentId]);

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Nova Interação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeadInteracaoForm parentId={parentId} onHide={handleHide} />
                </Modal.Body>
            </Modal>
            <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
            </div>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Cadastro</th>
                            <th>Data do Evento</th>
                            <th>Tipo</th>
                            <th>Descrição</th>
                            <th>Usuário</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                        <td>{config.dateFix(item.dataEvento)}</td>
                                        <td>{item.tipo}</td>
                                        <td>{item.descricao}</td>
                                        <td>{item.nome}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
