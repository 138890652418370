import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import axios from 'axios';
import App from './App';
import AppNew from './AppNew';
import * as serviceWorker from './serviceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
axios.get('/manifest.json').then(res => localStorage.setItem('apiUrl', res.data.api_url));

ReactDOM.render(
    <HashRouter basename={baseUrl}>
        {localStorage.getItem('theme') === 'adminlte3'
            ? <AppNew />
            : <App />
        }
    </HashRouter>, document.getElementById('root')
);

serviceWorker.unregister();
