import React, { useEffect, useState } from 'react';
import { apiHelper, toast, Modal } from '../index';
import { BuscarUsuario } from './BuscarUsuario';

export function AssociarUsuario(props) {
    const endPoint = 'projetoCliente/usuariosAssociados';
    const parentId = props.parentId;
    const [data, setData] = useState([]);

    const [filter, setFilter] = useState('');
    const [params, setParam] = useState({
        showModal: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        if (filter.length === 0 || filter.length >= 3)
            apiHelper.get(`${endPoint}/?id=${parentId}&nome=${filter}`).then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [filter]);

    const handleChange = (e) => {
        setFilter(e.target["value"]);
    };

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    const handleDelete = (id) => {
        apiHelper.api_delete(`${endPoint}/${parentId}?userId=${id}`).then(res => {
            toast.success('Usuário removido!');
            fetchData();
            handleRefresh();
        }).catch(error => {
            toast.error('Atenção, não foi possível remover.');
        });
    }

    const handleRefresh = () => {
        setFilter('');
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar </button>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th>Nome</th>
                                    <th style={{ width: "50px" }}>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} /></td>
                                    <td style={{ width: '50px' }}><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                </tr>
                                {data && data.sort((a, b) => a.pkUsuario < b.pkUsuario ? 1 : -1)
                                    .slice(params.initRow, params.endRow)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.nome}</td>
                                                <td>
                                                    <div className="btn-toolbar">
                                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.pkUsuario)}> <i className="fa fa-trash" /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "7px" }}>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.showModal && <BuscarUsuario parentId={parentId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
