import React, { useState, useEffect } from 'react';
import { Button, config, apiHelper, toast, Select } from '../index';
import moment from 'moment-timezone';

export function ContratoForm(props) {
    const endPoint = "portal/crud/contrato/";
    const rowId = props.parentId;
    const [data, setData] = useState([]);
    const [anexo, setAnexo] = useState({
        folder: config.getFolderModuloCliente(),
        fileName: '',
        file: {}
    });

    const fetchData = () => {
        let obj = {};
        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/${rowId}/pkContrato`)
                .then(res => {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        
        if (name === "anexo") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setAnexo({ ...anexo, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                setAnexo({ ...anexo, folder: anexo.folder, fileName: fileName, file: file });
                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (anexo.file !== '') {
            apiHelper.upload('anexo/upload', anexo.file, anexo.folder, anexo.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        if (rowId === 0) {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res.data);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkContrato`, data).then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Nome</label>
                                <input id="nome" name="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Nº Contrato</label>
                                <input id="numeroContrato" name="numeroContrato" type="text" className="form-control" onChange={handleChange} value={data.numeroContrato} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Origem (Nº Orçamento)</label>
                                <input id="nomeOrigem" name="nomeOrigem" type="text" className="form-control" onChange={handleChange} value={data.nomeOrigem} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Responsável Venda</label>
                                <Select id="responsavelVenda" className="form-control" onChange={handleChange} value={data.responsavelVenda}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="idCliente" className="form-control" onChange={handleChange} value={data.idCliente}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/projetoImplantacao/cliente" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div class="form-group">
                                <label>Data Início</label>
                                <div class="input-group date">
                                    <div class="input-group-addon"><i class="fa fa-calendar" /></div>
                                    <input id="dataInicio" name="dataInicio" type="text" className="form-control" onSelect={handleChange} data-provide="datepicker" data-date-format="dd/mm/yyyy" value={data.dataInicio} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group">
                                <label>Data Fim</label>
                                <div class="input-group date">
                                    <div class="input-group-addon"><i class="fa fa-calendar" /></div>
                                    <input id="dataFim" name="dataFim" type="date" className="form-control" onChange={handleChange} value={moment(new Date(data.dataFim)).format("YYYY-MM-DD")} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Valor</label>
                                <input id="valorContrato" name="valorContrato" type="number" className="form-control" onChange={handleChange} value={data.valorContrato} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Descrição</label>
                        <textarea id="observacao" className="form-control" rows="3" onChange={handleChange} value={data.observacao}></textarea>
                    </div>
                    <div class="form-group">
                        <div className="checkbox">
                            <label>
                                <input id="naoExisteContrato" type="checkbox" checked={data.naoExisteContrato} onChange={handleChange} />
                                Não Existe Contrato
                                </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="anexo">Anexo</label>
                        <input type="file" id="anexo" onChange={handleChange} />
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="box-tool pull-right">
                                <div className="btn-toolbar">
                                    <Button type="submit" bsStyle="success">Salvar</Button>
                                    {rowId > 0 && <Button bsStyle="default" onClick={fetchData}>Cancelar</Button>}
                                    <Button bsStyle="default" onClick={() => props.onHide()}>Fechar</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form >
    )
}
