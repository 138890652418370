import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast } from '../';
import { RatView } from './RatView';

export function Rat(props) {
    const endPoint = 'atendimento/rat';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: 0,
        details: false,
        grid: true,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        let filter = 'osId::equal::' + parentId;
        try {
            apiHelper.get(`${endPoint}?filter=${filter}`).then(res => {
                setData(res.data);
            });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    };

    useEffect(fetchData, [parentId, props.refresh]);

    const handleRefresh = () => {
        fetchData();
    };

    const handleDetails = (id) => {
        setParam({
            ...params,
            rowId: id,
            grid: false,
            details: true,
        });
    }

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            grid: true,
            details: false
        });

        refresh && fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            {params.grid &&
                <React.Fragment>
                    <div className="table-responsive">
                        <table className="table table-striped no-margin table-bordered">
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>ID</th>
                                    <th>Site</th>
                                    <th>Nome do Técnico</th>
                                    <th>Hora de Início</th>
                                    <th>Hora de Término</th>
                                    <th>Tempo Atendimento</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                    .slice(params.initRow, params.endRow)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '50px' }}>
                                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDetails(item.id)}><i className="fa fa-list" /></button>
                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.site}</td>
                                                <td>{item.nomeTecnico}</td>
                                                <td>{config.dateTimeDateAndHours(item.horaInicio)}</td>
                                                <td>{config.dateTimeDateAndHours(item.horaFim)}</td>
                                                <td>{config.timeConvert(item.tempoAtendimento)}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginTop: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                        <div className="pull-right">
                            {renderPage()}
                        </div>
                    </div>
                </React.Fragment>
            }
            {params.details && <RatView rowId={params.rowId} parentId={parentId} onHide={handleHide} />}
        </React.Fragment>
    )
}