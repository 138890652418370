import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select } from '../index';

export function AgrupamentoFuncaoForm(props) {
    const endPointFuncoes = "portal/crud/uvw_v2_CargoFuncoes";
    const endPointCargoFuncao = "portal/crud/rhCargoFuncao";
    const rowId = props.parentId;
    const [data, setData] = useState([]);
    const [dataFuncao, setDataFuncao] = useState([]);

    const fetchData = () => {
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPointFuncoes}?filter=fkCargo::equal::${rowId}`).then(res => {
                let data = res.data.data;
                setDataFuncao(data);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleAdicionarFuncao = () => {
        if (data.fkAgrupamentoFuncao === undefined) {
            toast.warn("Informe uma função!");
            return;
        }

        if (rowId !== undefined && data.fkAgrupamentoFuncao !== undefined) {
            let obj = {
                fkCargo: rowId,
                fkAgrupamentoFuncao: data.fkAgrupamentoFuncao
            }
            apiHelper.post(endPointCargoFuncao, obj).then(res => {
                fetchData();
                props.onUpdate();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a interação.');
            });
        }

    }
    const handleRemoverFuncao = (item) => {
        apiHelper.api_delete(`${endPointCargoFuncao}/${item.pkCargoFuncao}/pkCargoFuncao`).then(res => {
            fetchData();
            props.onUpdate();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });

    }

    return (
        <React.Fragment>
            <div className="form-group col-sm-10">
                <label>Agrupamento de funções</label>
                <Select id="fkAgrupamentoFuncao" className="form-control" onChange={handleChange} value={data.fkAgrupamentoFuncao}>
                    <option value="">Selecione</option>
                    <option endpoint="portal/crud/rhAgrupamentoFuncao" valuefield="pkAgrupamentoFuncao" textfield="nome" />
                </Select>
            </div>
            <div className="form-group col-sm-2">
                <label></label>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleAdicionarFuncao()}> Adicionar</button>
            </div>
            <div className="col-sm-12">
                <div className="table-responsive">
                    <table className="table table-hover table-bordered table-striped">
                        <thead>
                            <tr className="bg-light-blue">
                                <th>Nome</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataFuncao && dataFuncao.sort((a, b) => a.pkCargoFuncao < b.pkCargoFuncao ? 1 : -1)
                                .map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.nome}</td>
                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoverFuncao(item)}>Remover</button></td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}
