import React, { useState, useEffect } from 'react';
import { Content, apiHelper, Modal } from '../index';
import { DashboardForm } from './DashboardForm';
import { DashboardView } from './DashboardView';

export function DashboardReport(props) {
    const endPoint = 'portal/crud/boardReport';
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const usuarioLogadoId = localStorage.getItem('claims') !== null
        && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: ""
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=criador::equal::${usuarioLogadoId};;AND;;${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, [row]);

    const handleChange = (e) => {
        fetchData(`nome::contains::${e.target["value"]}`);
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    return (
        <Content title="Dashboards" browserTitle="Dashboards">
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block margin-bottom" onClick={() => handleShowForm("dashboard", "Novo Dashboard", 0)}>Novo Dashboard</button>

                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Pesquisar:</h3>
                            <div className="box-tools pull-right">
                                <div className="has-feedback">
                                    <input id="nome" type="text" className="form-control input-sm" placeholder="Nome" onChange={handleChange} />
                                    <span className="glyphicon glyphicon-search form-control-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive" style={{ overflow: "auto", maxHeight: 400 }}>
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td onClick={() => setRow(item)} style={{ cursor: 'pointer' }}>
                                                            {item.nome}
                                                            <div><small>{item.descricao}</small></div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    {row.id && <DashboardView id={row.id} admin={true} />}
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'dashboard' && <DashboardForm parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}