import axios from 'axios';
import { apiHeader, config } from '../../';

const API_URL = config.apiUrl();
const API_HEADER = apiHeader();

export const service = {
    getStates,
    getAllTicket
};

async function getStates() {
    return []
};

function getAllTicket(endPoint) {
    return axios.get(`${API_URL}${endPoint.replace("//", "/")}`, {
        headers: API_HEADER
    });
};
