import AtendimentoApp from './atendimento';
import ReportApp from './report';
import ProjectApp from './project';
import AdminApp from './admin';

export * from '../';

export default [
    ReportApp,
    AtendimentoApp,
    ProjectApp,
    AdminApp,
];
