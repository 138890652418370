import React, { useEffect, useState } from 'react';
import { apiHelper, toast } from '../index';

export function OrcamentoSite(props) {
    const endPoint = 'orcamento';
    const endPointOrcamentoSite = 'portal/crud/ComercialOrcamentoSite';
    const endPointOrcamentoItem = 'portal/crud/ComercialOrcamentoItem';
    const parentId = props.parentId;
    const clienteId = props.clienteId;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        cidade: '',
        estado: '',
    });
    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        if (clienteId === undefined)
            return;

        apiHelper.get(`${endPoint}/${parentId}/site?filter=IdCliente::equal::${clienteId};;AND;;${where}`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [props.clienteId, props.refresh]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleAdicionar = (item, isAssociarSite) => {
        let data = {
            orcamentoId: parentId,
            siteId: item.id
        }
        if (!isAssociarSite) {
            handleDesassociarSite(data);
            return;
        }
        apiHelper.post(endPointOrcamentoSite, data).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a interação.');
        });
    }

    const handleDesassociarSite = (data) => {
        verificarSiteTemProdutos(data);
    }

    const handleSearch = () => {
        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'nome' || key === 'cidade' || key === 'estado')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataCadastro') {
                where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;AND;;`;
            }
            else
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const verificarSiteTemProdutos = (data) => {
        apiHelper.get(`${endPointOrcamentoItem}?filter=OrcamentoId::equal::${data.orcamentoId};;AND;;SiteId::equal::${data.siteId}`)
            .then(res => {
                let existeProdutos = res.data.data.length > 0 ? true : false;
                if (existeProdutos) {
                    if (window.confirm(`Existem ${res.data.data.length} produtos associados ao site Id ${data.siteId}. Se remover este site, todos os produtos serão removidos do Escopo. Deseja continuar?`)) {
                        removerSite(data);
                        removerProdutos(res.data.data);
                    }
                } else
                    removerSite(data);
            });
    }

    const removerSite = (data) => {
        apiHelper.get(`${endPointOrcamentoSite}?filter=orcamentoId::equal::${data.orcamentoId};;AND;;SiteId::equal::${data.siteId}`)
            .then(res => {
                let id = res.data.data[0].id;

                apiHelper.api_delete(`${endPointOrcamentoSite}/${id}/Id`).then(res => {
                    fetchData();
                    props.onUpdate();
                }).catch(error => {
                    toast.error('Atenção, não foi possível excluir o registro.');
                });

            });
    }

    const removerProdutos = (data) => {
        data.map((item, i) => {
            apiHelper.api_delete(`${endPointOrcamentoItem}/${item.id}/id`).then(res => {
                fetchData();

            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o registro.');
            });

            return null;
        })
    }

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            cidade: '',
            estado: '',
        });

        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">

                <div className="col-md-12">
                    <div style={{ marginBottom: "7px" }}>
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                Pesquisar <span className="caret" />
                            </button>
                            <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 600, padding: 5 }} onClick={e => e.stopPropagation()}>
                                <div className="box" style={{ margin: 0 }}>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="form-group col-md-4">
                                                        <label>Id</label>
                                                        <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                    </div>
                                                    <div className="form-group col-md-8">
                                                        <label>Nome</label>
                                                        <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Cidade</label>
                                                        <input id="cidade" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cidade} />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>UF</label>
                                                        <input id="estado" type="text" className="form-control input-sm" onChange={handleChange} value={filter.estado} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="box-footer" style={{ padding: 5 }}>
                                        <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                        <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped">
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>Id</th>
                                    <th>Site</th>
                                    <th>Cidade</th>
                                    <th>UF</th>
                                    <th style={{ width: 60 }}>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.orcamentoId < b.orcamentoId ? 1 : -1)
                                    .slice(params.initRow, params.endRow)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '50px' }}>#</td>
                                                <td>{item.id}</td>
                                                <td>{item.nome}</td>
                                                <td>{item.cidade}</td>
                                                <td>{item.estado}</td>
                                                <td>
                                                    {item.orcamentoId === null
                                                        ? <button type="button" className="btn btn-default btn-xs" onClick={() => { handleAdicionar(item, true) }}>Adicionar</button>
                                                        : <button type="button" className="btn btn-danger btn-xs" onClick={() => { handleAdicionar(item, false) }}>Remover</button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
