import React from "react";
import { Content } from '../';
import ProjectDashboard from "./Dashboard";

export function Home(props) {
    return (
        <Content title="Gestão de Projetos">
            <ProjectDashboard />
        </Content>
    );
}
