import React, { Component } from 'react';
import {
    Content, Row, Box, Tabs, TabContent, FasIcon,
    Col, Table, Button, ButtonToolbar, FormGroupButton
} from '../../components/adminlte/AdminLTE';

import { DeviceEdit } from './DeviceEdit';
import { DeviceChannel } from './DeviceChannel';
import { DeviceUser } from './DeviceUser';
import { DeviceGroup } from './DeviceGroup';
import { ChannelDoor } from './ChannelDoor';
import { toast } from 'react-toastify';

const config = {
    keyName: 'id', //Passando o Guid
    rowName: 'name', // Exibe a coluna do banco com nome name
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiServer: localStorage.getItem('apiUrl'),
    apiUrl: localStorage.getItem('apiUrl') + 'vms/device/',
    apiEndPointGroup: localStorage.getItem('apiUrl') + 'vms/group/'
};

export class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameValue: '',
            keyValue: '',
            table: [],
            row: {},
            searchValue: '',
            action: 'new',
            showForm: false,
            showConfirm: false
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.hideForm = this.hideForm.bind(this);
        this.rowSelect = this.rowSelect.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getDataTable('');
    }

    getDataTable(filter) {
        if (filter === null || filter === undefined)
            filter = '';

        this.setState({ table: [] });

        fetch(config.apiUrl + '?name=' + filter, {
            headers: { 'Authorization': config.token }
        }).then(
            response => response.json()
        ).then(data => {
            if (data.length > 0) {
                this.setState({ table: data });
                this.rowSelect(data[0]);
            }
        });
    }

    handleSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ searchValue: value });
        if (value.length > 2 || value === '')
            this.getDataTable(value);
    }

    rowSelect(e) {
        this.setState({
            row: e,
            keyValue: e[config.keyName],
            nameValue: e[config.rowName]
        });
    }

    handleDelete(e) {
        if (e && window.confirm("Deseja realmente excluir este registro?")) {

            fetch(config.apiUrl + this.state.row.id, {
                method: 'DELETE',
                headers: { 'Authorization': config.token }
            }).then(response => {
                return Promise.all([response.status, response.json()]);
            }).then((response) => {
                if (response[0] === 200) {
                    toast.success('Dados excluidos com sucesso!');
                    this.getDataTable('');
                }
                else
                    toast.warn(response[1]["msg"]);

            }).catch(error => {
                toast.error(error);
            });
        }

        this.setState({ showConfirm: false });
    }

    showForm(action) {
        this.setState({
            showForm: true,
            action: action
        });
    }

    hideForm() {
        this.getDataTable('');
        this.setState({
            showForm: false,
            action: 'new'
        });
    }

    loadPerfil(action) {
        this.setState({
            showForm: true,
            action: action
        });
    }

    render() {
        return (
            <Content title="Dispositivos" browserTitle="SIGA | Dispositivos">
                <Row>
                    <Col md={4}>
                        <Box type="success">
                            <FormGroupButton size="sm" placeholder="Filtro"
                                onChange={this.handleSearch} value={this.state.searchValue}
                                buttonLeft={<Button bsStyle="success" onClick={() => this.loadPerfil('new')}>NOVO</Button>}
                                buttonRight={<Button text="Limpar" onClick={() => this.clearSearch()}><FasIcon name="refresh" /></Button>}
                            />
                            <Table striped bordered hover>
                                <tbody>
                                    {this.state.table
                                        .sort((a, b) => (a.name > b.name) ? 1 : -1)
                                        .map(row => {
                                            return (
                                                <tr key={row.id} onClick={() => this.rowSelect(row)} style={{ cursor: "pointer" }}>
                                                    <td>{row.name}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </Box>
                    </Col>
                    <Col md={8}>
                        <Box type="primary" title={this.state.nameValue} customOptions={
                            <ButtonToolbar>
                                <Button bsSize="sm" bsStyle="primary" onClick={() => this.loadPerfil('edit')}><FasIcon name="edit" /></Button>
                                <Button bsSize="sm" bsStyle="danger" onClick={this.handleDelete}><FasIcon name="trash" /></Button>
                            </ButtonToolbar>}
                        >
                            <Tabs id="group" defaultActiveKey={1}>
                                <TabContent title="Canais" eventKey={1}>
                                    <DeviceChannel config={config} keyValue={this.state.row.serialNumber} />
                                </TabContent>
                                <TabContent title="Usuarios" eventKey={2}>
                                    <DeviceUser config={config} row={this.state.row} keyValue={this.state.row.serialNumber} />
                                </TabContent>
                                <TabContent title="Grupos" eventKey={3}>
                                    <DeviceGroup config={config} row={this.state.row} keyValue={this.state.row.serialNumber} />
                                </TabContent>
                                <TabContent title="Portas" eventKey={4}>
                                    <ChannelDoor config={config} row={this.state.row} keyValue={this.state.row.serialNumber} />
                                </TabContent>
                            </Tabs>
                        </Box>
                    </Col>
                </Row>
                {this.state.showForm && <DeviceEdit config={config} show={this.state.showForm} keyValue={this.state.keyValue} action={this.state.action} onHide={this.hideForm} />}
            </Content>
        );
    }
}
