import React from 'react';
import { Content } from '../';
//import { OrdemServicoDashboard } from '../atendimentoV2/OrdemServicoDashboard';

export function OS2() {
    return (
        <Content title="Relatórios de OS" browserTitle="Relatórios de OS">
            Módulo migrado para dentro de atendimento
            {
                //<OrdemServicoDashboard />
            }
        </Content >
    )
}
