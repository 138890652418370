import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper } from '../index';
import Core from '../Core';

export function CronogramaTarefa(props) {
    const endPoint = 'projetoCliente/tarefa';
    const rowId = props.parentId;
    const [data, setData] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=cronogramaId::equal::${rowId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [rowId]);

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="bg-light-blue color-palette">
                            <th>Id</th>
                            <th>Tarefa</th>
                            <th>Status</th>
                            <th>Início Programado</th>
                            <th>Fim Programado</th>
                            <th>Início Real</th>
                            <th>Fim Real</th>
                            <th>Anexo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.dataInicioProgramado > b.dataInicioProgramado ? 1 : -1)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.id}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.status}</td>
                                        <td>{config.dateFix(item.dataInicioProgramado)}</td>
                                        <td>{config.dateFix(item.dataFimProgramado)}</td>
                                        <td>{config.dateFix(item.dataInicioReal)}</td>
                                        <td>{config.dateFix(item.dataFimReal)}</td>
                                        <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
