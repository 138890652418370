import React from 'react';

export function Tabs(props) {
  const { children, title, bg, type, className } = props;

  const child = React.Children.toArray(children);

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }

  const uuid = uuidv4();

  const navPills = () => {
    return (
      <div className={`card${className ? " " + className : ""}`}>
        <div className="card-header p-2">
          <ul className="nav nav-pills">
            {child.map((m, i) =>
              <li key={i} className="nav-item">
                <a className={`nav-link${i === 0 ? ' active' : ''}`} id={`easy-tabs-${uuid}-${i}-tab`} data-toggle="tab" href={`#easy-tabs-${uuid}-${i}`}>{m.props.label}</a>
              </li>
            )}
          </ul>
        </div>
        <div className="card-body p-0">
          <div className="tab-content">
            {child.map((m, i) =>
              <div key={i} className={`tab-pane${m.props.className ? " " + m.props.className : " p-3"} fade${i === 0 ? ' show active' : ''}`} id={`easy-tabs-${uuid}-${i}`}>
                {m.props.children}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    type === "pills" ? navPills()
      :
      <div className={`card${bg ? " card-" + bg : ""} card-tabs`}>
        <div className="card-header p-0 pt-1">
          <ul className="nav nav-tabs" id={`easy-tabs-${uuid}-tab`} role="tablist">
            {title
              ? <li className="pt-2 px-3"><h3 className="card-title">{title}</h3></li>
              : <li className="pt-2 px-1" />
            }

            {child.map((m, i) =>
              <li key={i} className="nav-item">
                <a className={`nav-link${i === 0 ? ' active' : ''}`} id={`easy-tabs-${uuid}-${i}-tab`} data-toggle="pill" href={`#easy-tabs-${uuid}-${i}`} role="tab">{m.props.label}</a>
              </li>
            )}
          </ul>
        </div>
        <div className="card-body p-0">
          <div className="tab-content" id={`easy-tabs-${uuid}-tabContent`}>
            {child.map((m, i) =>
              <div key={i} className={`tab-pane${m.props.className ? " " + m.props.className : " p-3"} fade${i === 0 ? ' show active' : ''}`} id={`easy-tabs-${uuid}-${i}`} role="tabpanel">
                {m.props.children}
              </div>
            )}
          </div>
        </div>
      </div>
  );
};
