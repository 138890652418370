import React from "react";
import { Content } from '../';
import { Dashboard } from "./Dashboard";

export function Home(props) {
    return (
        <Content title="Dashboard">
            <Dashboard id={1} />
        </Content>
    );
}
