import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select, config, Link } from '../index';
import Core from '../Core';
import moment from 'moment-timezone';
import { createHashHistory } from 'history'
export const history = createHashHistory();

export function OrcamentoFinalizarForm(props) {
    const endPoint = 'orcamento';
    const endPointComercialOrcamento = 'portal/crud/ComercialOrcamento';
    const endPointProjeto = 'portal/crud/Projeto';
    const parentId = props.parentId;
    const [data, setData] = useState({});
    const [existeProjeto, setExisteProjeto] = useState(false);
    const [anexoAceiteFormalCliente, setAnexoAceiteFormalCliente] = useState({
        folder: config.getFolderModuloOportunidade(),
        fileName: '',
        file: {}
    });
    const [anexoContrato, setAnexoContrato] = useState({
        folder: config.getFolderModuloOportunidade(),
        fileName: '',
        file: {}
    });
    const [anexoPropostaComercial, setAnexoPropostaComercial] = useState({
        folder: config.getFolderModuloOportunidade(),
        fileName: '',
        file: {}
    });

    const fetchData = () => {
        verificarExisteProjeto();
    }

    const verificarExisteProjeto = () => {
        if (parentId) {
            apiHelper.get(`${endPointProjeto}?filter=PropostaComercialId::equal::${parentId}`)
                .then(res => {
                    if (res.data.code === 200) {
                        if (res.data.data.length > 0) {
                            setExisteProjeto(true);
                            obterOrçamento();
                        } else {
                            setExisteProjeto(false);
                        }
                    }
                });
        }
    }

    const obterOrçamento = () => {
        let obj = {};
        if (parentId && parentId > 0) {
            apiHelper.get(`${endPointComercialOrcamento}/${parentId}/id`).then(res => {
                let data = res.data.data;
                if (data && data.dataVencimentoContrato !== null)
                    data.dataVencimentoContrato = `${data.dataVencimentoContrato.split('-')[0]}-${data.dataVencimentoContrato.split('-')[1]}-${data.dataVencimentoContrato.split('-')[2].substr(0, 2)}`;

                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o Orçamento');
            });
        }
    }

    useEffect(fetchData, [parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        if (name === "anexoAceiteFormalCliente" || name === "anexoContrato" || name === "anexoPropostaComercial") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    if (name === "anexoAceiteFormalCliente")
                        setAnexoAceiteFormalCliente({ ...anexoAceiteFormalCliente, folder: '', file: '' });
                    else if (name === "anexoContrato")
                        setAnexoContrato({ ...anexoContrato, folder: '', file: '' });
                    else if (name === "anexoPropostaComercial")
                        setAnexoPropostaComercial({ ...anexoPropostaComercial, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                if (name === "anexoAceiteFormalCliente")
                    setAnexoAceiteFormalCliente({ ...anexoAceiteFormalCliente, folder: anexoAceiteFormalCliente.folder, fileName: fileName, file: file });
                else if (name === "anexoContrato")
                    setAnexoContrato({ ...anexoContrato, folder: anexoContrato.folder, fileName: fileName, file: file });
                else if (name === "anexoPropostaComercial")
                    setAnexoPropostaComercial({ ...anexoPropostaComercial, folder: anexoPropostaComercial.folder, fileName: fileName, file: file });

                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.motivoCancelamento !== undefined)
            cancelarOrcamento();
        else
            gerarProjeto(data);
    };

    const gerarProjeto = (dataAux) => {

        if ((props.params.tipoOrcamento.includes('Locação') || props.params.tipoOrcamento.includes('Manutenção')) && anexoContrato.fileName === '')
            if (!window.confirm("Nenhum contrato foi anexado. Deseja criar o projeto sem anexar um contrato?"))
                return

        if (anexoContrato.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoContrato.file, anexoContrato.folder, anexoContrato.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        if (anexoAceiteFormalCliente.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoAceiteFormalCliente.file, anexoAceiteFormalCliente.folder, anexoAceiteFormalCliente.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        if (anexoPropostaComercial.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoPropostaComercial.file, anexoPropostaComercial.folder, anexoPropostaComercial.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        apiHelper.put(`${endPoint}/${parentId}/projeto`, dataAux)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    props.fetchData();
                } else if (res.data.code === 406) {
                    if (!window.confirm(res.data.msg + "Você gostaria de reverter o projeto para o status Rascunho?"))
                        return
                    reverterProjetoRascunho();
                }
                else {
                    toast.warn(res.data.msg);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    };

    const reverterProjetoRascunho = () => {
        let objData = {
            aprovadorId: null,
            status: 'Rascunho',
            custosSolicitados: 0,
            propostaGerada: 0,
            anexoImplantacao: null,
            horarioAtividade: null,
            dataNecessidade: null,
            necessarioTubulacao: null,
            tubulacaoEquipamentoLocalAlto: null,
            meiosElevacaoCliente: null,
            disponibilidadeMaisPessoas: null,
            SLAManutencaoCorretiva: null,
            SLAManutencaoPreventiva: null,
            solicitadoAtualizacaoValores: null
        }

        apiHelper.put(`${endPointComercialOrcamento}/${parentId}/Id`, objData)
            .then(res => {
                config.httpResult(res.data);
                props.fetchData();
                handleRefresh();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const cancelarOrcamento = () => {
        apiHelper.put(`orcamento/${parentId}/status/?acao=cancelar&&observacao=${data.motivoCancelamento}`)
            .then(res => {
                config.httpResult(res.data);
            }).catch(error => {
                toast.error('Atenção, não foi possível atualizar o status');
            });
    };

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloOportunidade(), fileName).then(res => {
        });
    }

    const contemRecorrente = (tipoOrcamento) => {
        return tipoOrcamento.includes('Locação') || tipoOrcamento.includes('Manutenção')
    }

    const handleRefresh = () => {
        setData({
            ...data,
            status: '',
            anexoAceiteFormalCliente: '',
            contratoAssinado: '',
            descricaoReferenciaContrato: '',
            valorMensal: '',
            dataVencimentoContrato: '',
            anexoContrato: '',
            propostaComercial: '',
            anexoPropostaComercial: ''
        });

    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                {
                    props.status === 'Fechado' &&
                    <div className='col-sm-12'>
                        <div className="form-group col-sm-6">
                            <label>Ação *</label>
                            <Select id="status" className="form-control" onChange={handleChange} value={data.status} required>
                                <option value="">Selecione</option>
                                <option value="GerarProjeto">{existeProjeto ? 'Atualizar Projeto' : 'Gerar Projeto'}</option>
                                <option value="Cancelar">Cancelar Orçamento</option>
                            </Select>
                        </div>
                    </div>
                }
            </div>
            {(data.status === 'GerarProjeto' && props.params.status === 'Fechado') &&
                <div className="row">
                    <div className='col-sm-12'>
                        <div className="form-group col-sm-6">
                            <label>Aceite formal do cliente (Anexo) *</label>
                            <input type="file" id="anexoAceiteFormalCliente" onChange={handleChange} required />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Possui o contrato assinado?</label>
                            <Select id="contratoAssinado" className="form-control" onChange={handleChange} value={data.contratoAssinado}>
                                <option value="">Selecione</option>
                                <option value={true}>Sim</option>
                                <option value={false}>Não</option>
                            </Select>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Possui Proposta Comercial? *</label>
                            <Select id="propostaComercial" className="form-control" onChange={handleChange} value={data.propostaComercial} required>
                                <option value="">Selecione</option>
                                <option value={true}>Sim</option>
                                <option value={false}>Não</option>
                            </Select>
                        </div>
                    <div className="form-group col-sm-6">
                        <label>Proposta Comercial (Anexo) {data.propostaComercial === 'true' ? '*': ''}</label>
                            <input type="file" id="anexoPropostaComercial" onChange={handleChange} required={data.propostaComercial === 'true' ? true : false} />
                        </div>
                        <div>
                            <div className="form-group col-sm-12">
                                <label>Referência do contrato {contemRecorrente(props.params.tipoOrcamento) === true ? '*' : ''}</label>
                                <textarea id="descricaoReferenciaContrato" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricaoReferenciaContrato} required={contemRecorrente(props.params.tipoOrcamento) === true ? true : false} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Valor Mensal {contemRecorrente(props.params.tipoOrcamento) === true ? '*' : ''}</label>
                                <input id="valorMensal" type="number" className="form-control" onChange={handleChange} value={data.valorMensal || ""} required={contemRecorrente(props.params.tipoOrcamento) === true ? true : false} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Vencimento Contrato {contemRecorrente(props.params.tipoOrcamento) === true ? '*' : ''}</label>
                                <input id="dataVencimentoContrato" type="date" className="form-control" onChange={handleChange} value={data.dataVencimentoContrato || ""} required={contemRecorrente(props.params.tipoOrcamento) === true ? true : false} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Contrato (Anexo) {data.contratoAssinado === 'true' ? '*' : ''}</label>
                                <input type="file" id="anexoContrato" onChange={handleChange} required={data.contratoAssinado === 'true' ? true : false} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {props.params.status === 'GerouProjeto' &&
                <table className="table table-striped table-bordered no-margin">
                    <tbody>
                        {(props.params.anexoAceiteFormalCliente !== null && props.params.anexoAceiteFormalCliente !== undefined) && <tr>
                            <td><b>Aceite formal do cliente (Anexo)</b> </td>
                            <td><Link to="#" onClick={() => downloadAnexo(props.params.anexoAceiteFormalCliente)}> {props.params.anexoAceiteFormalCliente}</Link></td>
                        </tr>}
                        {(props.params.descricaoReferenciaContrato !== null && props.params.descricaoReferenciaContrato !== undefined) && <tr>
                            <td><b>Referência do contrato</b> </td>
                            <td>{props.params.descricaoReferenciaContrato}</td>
                        </tr>}
                        {(props.params.valorMensal !== null && props.params.valorMensal !== undefined) && <tr>
                            <td><b>Valor Mensal</b> </td>
                            <td>{config.currencyFix(props.params.valorMensal)}</td>
                        </tr>}
                        {(props.params.dataVencimentoContrato !== null && props.params.dataVencimentoContrato !== undefined) && <tr>
                            <td><b>Vencimento Contrato</b> </td>
                            <td>{config.dateFix(props.params.dataVencimentoContrato)}</td>
                        </tr>}
                        {(props.params.anexoContrato !== null && props.params.anexoContrato !== undefined) && <tr>
                            <td><b>Contrato (Anexo)</b> </td>
                            <td><Link to="#" onClick={() => downloadAnexo(props.params.anexoContrato)}> {props.params.anexoContrato}</Link></td>
                        </tr>}
                        {(props.params.anexoPropostaComercial !== null && props.params.anexoPropostaComercial !== undefined) && <tr>
                            <td><b>Proposta Comercial (Anexo)</b> </td>
                            <td><Link to="#" onClick={() => downloadAnexo(props.params.anexoPropostaComercial)}> {props.params.anexoPropostaComercial}</Link></td>
                        </tr>}
                    </tbody>
                </table>

            }
            {data.status === 'Cancelar' &&
                <div className="row">
                    <div className='col-sm-12'>
                        <div className="form-group col-sm-12">
                            <label>Motivo Cancelamento *</label>
                            <textarea id="motivoCancelamento" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.motivoCancelamento} required />
                        </div>
                    </div>
                </div>}
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        {props.status === "Fechado" &&
                            <React.Fragment>
                                <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </form>
    )
}
