import React, { useState, useEffect } from 'react';
import { apiHelper, config, toast, Link, Modal } from '../index';
import { AcionamentoForm } from './AcionamentoForm';
import { AcionamentoCancelar } from './AcionamentoCancelar';

export function Acionamento(props) {
    const endPoint = 'v2/os/acionamentoTecnico';
    const parentId = props.parentId;
    const siteId = props.siteId;
    const regionalId = props.regionalId;
    const [data, setData] = useState([]);
    const [temPrimario, setTemPrimario] = useState(false);
    const [params, setParam] = useState({
        showModal: false,
        formName: '',
        modalTitle: '',
        rowId: 0
    });

    const fetchData = () => {
        let where = `fkOS::equal::${parentId}`;

        try {
            apiHelper.get(`${endPoint}?filter=${where}&order=pkAcionamentoTecnico DESC`)
                .then(res => {
                    for (let item of res.data) {
                        if (item.isPrimary && (!item.dataConclusao && !item.isCancelado)) {
                            setTemPrimario(true);
                            break;
                        }
                    }
                    setData(res.data);
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    }

    useEffect(fetchData, [parentId]);

    const handleShow = (acao, id = 0) => {
        setParam({
            ...params, showModal: true,
            formName: acao,
            modalTitle: id === 0 ? 'Novo Acionamento' : 'Cancelar Acionamento ' + id,
            rowId: id
        });
    }

    const handleHide = () => {
        setParam({ ...params, showModal: false, formName: '', rowId: 0 });
        fetchData();
        props.onHide();
    }

    const handleNovo = () => {

        handleShow('novo');
    }

    return (
        <React.Fragment>
            <div style={{ marginBottom: "7px" }}>
                {!params.showModal && config.validarClaim('atendimento.tecnico.criar') && props.allowEdit && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={(handleNovo)}><i className="fa fa-plus" /> Adicionar</button>}
            </div>

            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Data</th>
                            <th>Solicitante</th>
                            <th>SLA</th>
                            <th>Técnico</th>
                            <th>Tipo</th>
                            <th>Nível</th>
                            <th>Valor</th>
                            <th>Inicio Deslocamento</th>
                            <th>Fim Deslocamento</th>
                            <th>Inicio Atendimento</th>
                            <th>Fim Atendimento</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.pkAcionamentoTecnico < b.pkAcionamentoTecnico ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{config.dateTimeFix(item.criadoEm)}</td>
                                        <td>{item.solicitante}</td>
                                        <td>{item.valorSLA}</td>
                                        <td>{item.tecnico ? item.tecnico + ' - ' +
                                            ((item.isPrimary) ? 'Primário' : 'Secundário') : 'Nenhum!'}</td>
                                        <td>{item.tipoAtendimento ? item.tipoAtendimento : 'Não Informado'}</td>
                                        <td>{(item.nivelSLA === 4) ? "Solicitação" : (item.nivelSLA === 5) ? "Preventiva" : item.nivelSLA}</td>
                                        <td>{config.currencyFix(item.valorOS)}</td>
                                        <td>{config.dateTimeFix(item.dataDeslocamento)}</td>
                                        <td>{config.dateTimeFix(item.dataFimDeslocamento)}</td>
                                        <td>{config.dateTimeFix(item.dataInicioAtendimento)}</td>
                                        <td>{config.dateTimeFix(item.dataFimAtendimento)}</td>
                                        <td align="center">{!item.isCancelado && item.dataConclusao === null && config.validarClaim('atendimento.tecnico.criar') ?
                                            <Link replace to="#" onClick={() => handleShow("cancelar", item.pkAcionamentoTecnico)}><i className="fa fa-remove" /></Link> : (item.isCancelado) ? 'Cancelado' : config.validarClaim('atendimento.tecnico.criar') ? 'Concluído' : ''}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                <div style={{ marginTop: "7px" }}>
                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "novo" && <AcionamentoForm parentId={parentId} data={props.data} siteId={siteId} regionalId={regionalId} temPrimario={temPrimario} acionamentos={data} onHide={handleHide} />}
                    {params.formName === "cancelar" && <AcionamentoCancelar parentId={parentId} rowId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}