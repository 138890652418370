import React, { Component } from 'react';
import { Content, FieldGroup, Row, Box, Col, Table, Button, FasIcon } from '../../components/adminlte/AdminLTE';
import ExcelReader from './ExcelReader';
import { ImportDetail } from './ImporDetail';

const config = {
    keyName: 'id', //Passando o Guid
    rowName: 'name', // Exibe a coluna do banco com nome name
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiUrl: localStorage.getItem('apiUrl') + 'oi/import/',
    apiUrlExport: localStorage.getItem('apiUrl') + 'oi/export/exportXls'
};

let statusFile = '';

export class Import extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            keyValue: '',
            table: [],
            row: {},
            showForm: false,
            data: '',
            dataFim: '',
            nameUser: '',
            tipoImportacao: '',
            action: 'new'
        };

        this.getDataTable = this.getDataTable.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getDataTable('');
    }

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(''), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    getDataTable(name) {
        if (name === 'foo') {
            alert(name);
            return;
        }
        fetch(config.apiUrl + '?name=' + name, {
            headers: { 'Authorization': config.token }
        }).then(
            response => response.json()
        ).then(data => {
            if (data.length > 0) {
                this.setState({
                    table: data, row: data[0],
                    keyValue: data[0].id,
                    nameValue: data[0].name
                });
            }
        });
    }

    tick(name) {

        let itemPendente = this.state.table.filter((e) => e.status === "Pendente");

        if (this.state.showForm === false && itemPendente.length > 0) {
            this.getDataTable(name);
        }
    }

    loadDetail(e) {
        this.setState({
            showForm: true,
            row: e,
            keyValue: e.id,
            nameValue: e.name
        });
    }

    onHide() {
        this.setState({ showConfirmDelete: false });

        this.getDataTable('');
        this.setState({
            showForm: false,
            action: 'new'
        });
    }

    fillIcon(status, totalErros) {

        statusFile = status;

        if (status === 'Pendente')
            return 'label label-primary';
        else if (status === 'Erro')
            return 'label label-danger';
        else if (status === 'Processando')
            return 'label label-info';
        else if (status === 'Processada' && totalErros > 0) {
            statusFile = 'Importado com erros';
            return totalErros > 0 ? 'label label-warning' : 'label label-success';
        }
        else if (status === 'Processada')
            return 'label label-success';
    }

    handleSearch(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState({ [name]: value });
    }

    search() {
        fetch(config.apiUrl + 'search?tipoImportacao=' + this.state.tipoImportacao + '&nameUser=' + this.state.nameUser + "&data=" + this.state.data
            + "&dataFim=" + this.state.dataFim, {
            headers: { 'Authorization': config.token }
        }).then(
            response => response.json()
        ).then(data => {

            this.setState({ nameValue: '', keyValue: '', table: [] });

            if (data.length > 0) {
                this.setState({
                    table: data,
                    row: data[0],
                    keyValue: data[0].id,
                    nameValue: data[0].name
                });
            }
        });
    }

    handleDelete(e) {
        if (e && window.confirm("Deseja realmente excluir este registro?")) {
            fetch(config.apiUrl + e.id, {
                method: 'DELETE',
                headers: { 'Authorization': config.token }
            }).then(response => {
                return Promise.all([response.status, response.json()]);
            }).then((response) => {
                if (response[0] === 200) {
                    alert("Dados excluidos com sucesso!");
                    this.getDataTable('');
                }
                else
                    alert(response[1]["msg"]);
            }).catch(error => {
                alert(error);
            });
        }

        this.setState({ showConfirmDelete: false });
    }

    reload() {
        this.setState({
            data: '',
            nameUser: '',
            tipoImportacao: '',
        });

        this.getDataTable('');
    }

    render() {
        return (
            <Content title="Importa&ccedil;&atilde;o" browserTitle="OI Portal | Importa&ccedil;&atilde;o">

                <Row>
                    <Col md={4}>
                        <Box type="success">
                            <ExcelReader config={config} onImportSuccess={() => { this.reload(); }} />
                        </Box>
                        <Box type="success" title="Pesquisa">
                            <Row>
                                <FieldGroup bsSize="sm" col={12} id="tipoImportacao" componentClass="select" label="Tipo de Importa&ccedil;&atilde;o" value={this.state.tipoImportacao} onChange={this.handleSearch}>
                                    <option key="" value="">Selecione</option>
                                    <option value="AreaApprover">Aprovadores de Areas</option>
                                    <option value="area">Areas</option>
                                    <option value="cards">Crach&aacute;s</option>
                                    <option value="local">Locais</option>
                                    <option value="personel">Pessoas</option>
                                    <option value="AuthorizedAreasForPersons">Pessoas Autorizadas</option>
                                </FieldGroup>

                                <FieldGroup bsSize="sm" col={12} id="nameUser" type="text" label="Usuario" value={this.state.nameUser} onChange={this.handleSearch} />

                                <Col md={6}>
                                    <FieldGroup size="sm" label="Data Inicio" placeholder="Data" type="date" value={this.state.data} onChange={this.handleSearch} id="data" />
                                </Col>

                                <Col md={6}>
                                    <FieldGroup size="sm" label="Data Fim" placeholder="Data" type="date" value={this.state.dataFim} onChange={this.handleSearch} id="dataFim" />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={2}>
                                    <Button bsStyle="success" type="button" onClick={() => this.search()}><FasIcon name="search" /></Button>
                                </Col>
                            </Row>
                        </Box>
                    </Col>
                    <Col md={8}>
                        <Box type="primary" title="Importa&ccedil;&otilde;es">
                            <Row>
                                <Col md={12}>
                                    <form action={config.apiUrlExport}>
                                        <input type="hidden" name="tipoImportacao" value={this.state.tipoImportacao} />
                                        <input type="hidden" name="nameUser" value={this.state.nameUser} />
                                        <input type="hidden" name="data" value={this.state.data} />
                                        <input type="hidden" name="dataFim" value={this.state.dataFim} />

                                        <Button className="pull-right" bsStyle="success" type="submit">Exportar Excel</Button>
                                        <br /><br /><br />
                                    </form>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Table striped bordered hover>
                                        <tbody>
                                            {this.state.table.map(item => {
                                                let json = JSON.parse(item.json);
                                                let sucess = json.filter(f => f.StatusProcess === 'Processada').length;
                                                let error = json.filter(f => f.StatusProcess !== 'Processada' && f.StatusProcess !== 'Em Fila para Processamento').length;
                                                var pullRight = ' pull-right';

                                                return (
                                                    <tr key={item.id} style={{ cursor: "pointer" }}>
                                                        <td>
                                                            <Row>
                                                                <Col md={10} onClick={() => { this.loadDetail(item) }}>
                                                                    {item.name} | Data: {item.dateFormat} <br /> <b>Sucesso</b>: {sucess} <b>Erros</b>: {error} <b>Usuario</b>: {item.nameUser}
                                                                </Col>
                                                                <Col md={2}>
                                                                    <span onClick={() => { this.loadDetail(item); }} className={this.fillIcon(item.status, error) + pullRight}>{statusFile}</span><br />
                                                                    {item.status === 'Pendente' ? <Button bsStyle="danger" bsSize="xs" className="pull-right" onClick={() => this.handleDelete(item)}>EXCLUIR</Button> : ''}
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Box>
                    </Col>
                </Row>
                {this.state.showForm && <ImportDetail config={config} title={this.state.nameValue} show={this.state.showForm} keyValue={this.state.keyValue} data={this.state.row} action={this.state.action} onHide={() => this.onHide()} />}
            </Content>
        );
    }
}