import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, ButtonToolbar, Button, Table, Checkbox } from 'react-bootstrap';
import uuidv4 from 'uuid';
import axios from 'axios';

import { Content } from './Content';
import * as Sidebar from './sidebar/Sidebar';

import { Navbar } from './Navbar';

import { FasIcon } from './controls/FasIcon';
import { GlyIcon } from './controls/GlyIcon';

import { FormGroup } from './controls/FormGroup';
import { ButtonGroup } from './controls/ButtonGroup';
import { InputGroup } from './controls/InputGroup';
import { FormGroupButton } from '../controls/FormGroupButton';
import { FieldGroup } from '../controls/FieldGroup';

import { Box } from './controls/Box';
import { Modals } from '../controls/Modals';

import Tabs from './controls/tabs/Tabs';
import TabContent from './controls/tabs/TabContent';

import ModalSelect from '../controls/ModalSelect';

class AdminLTE extends Component {
    UNSAFE_componentWillMount() {
        document.body.classList.remove('login-page');
        document.body.classList.add('hold-transition');
        document.body.classList.add('skin-blue');
        document.body.classList.add('sidebar-mini');
        document.getElementById('root').classList.remove('login-box');
    }

    setTheme = () => {
        let theme = localStorage.getItem("theme") || "";

        if (theme === 'adminlte3' || theme === "")
            localStorage.setItem("theme", 'adminlte');
        else
            localStorage.setItem("theme", 'adminlte3');

        window.location.reload();
    }

    render() {
        const { children, sidebar = undefined } = this.props;
        const sidebarMenu = (<Sidebar.Core>{sidebar}</Sidebar.Core>);

        return (
            <React.Fragment>
                <header className="main-header">
                    <Link to="/" className="logo" onDoubleClick={this.setTheme}>
                        <span className="logo-mini"><b>S</b>ER</span>
                        <span className="logo-lg"><b>Siga</b>ERP</span>
                    </Link>
                    <Navbar />
                </header>
                {sidebarMenu}
                <div className="content-wrapper">
                    {children}
                </div>
                <aside className="control-sidebar control-sidebar-dark" />
                <div className="control-sidebar-bg" />
            </React.Fragment>
        );
    }
}

export default AdminLTE;
export {
    Row, Col, ButtonToolbar, uuidv4, Button, Checkbox, Link, axios,
    Content, Sidebar, FasIcon, GlyIcon, FormGroup, ButtonGroup,
    FormGroupButton, Modals, FieldGroup,
    Table, InputGroup, Tabs, TabContent, Box,
    ModalSelect
};