import React, { useEffect, useState } from 'react';
import {  apiHelper } from '../index';
import * as _ from 'lodash';

let distinctSites = [];

export function Escopo(props) {
    const endPointOrcamentoSite = 'portal/crud/uvw_v2_comercial_orcamento_site';
    const orcamentoId = props.orcamentoId;
    const [escopo, setEscopo] = useState([]);
    const fetchData = () => {
        if (orcamentoId) {
            apiHelper.get("projetocliente/" + props.orcamentoId + '/escopo').then(res => {
                obterSites(res.data.data);
            });
        }
    };

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, [props.orcamentoId, props.refresh]);

    const obterSites = (dataOrcamentoItem) => {
        
        apiHelper.get(`${endPointOrcamentoSite}?filter=OrcamentoId::equal::${orcamentoId};;`)
            .then(res => {
                let sites = res.data.data;
                
                dataOrcamentoItem.map((item, i) => {
                    let site = sites.find(c => c.id === item.siteId);
                    dataOrcamentoItem[i].nomeSite = site && site.nome;
                    return null;
                });

                let nomesSites = dataOrcamentoItem.map((item, i) => { return item.nomeSite });
                distinctSites = nomesSites.filter((v, i, a) => a.indexOf(v) === i);

                let dataSitesAgrupados = _.groupBy(dataOrcamentoItem, 'nomeSite');
                let dados = [];

                distinctSites.map(item => {
                    dados.push(dataSitesAgrupados[item]);
                    return null;
                });

                setEscopo(dados);
            });
    };

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin">
                    <thead>
                        <tr className="bg-light-blue">
                          
                            <th>Descrição</th>
                            <th>Classificação</th>
                            <th>Qtd</th>
                       
                        </tr>
                    </thead>
                    <tbody>
                        {distinctSites.length > 0 && distinctSites.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td colSpan="15" style={{ background: '#E4E4E4' }}><b>{item}</b></td>
                                    </tr>
                                    {escopo.length > 0 && escopo[i].filter(c => c.nomeSite === item).map((item, j) => {
                                        return <tr key={j}>
                                            <td>{item.nome}</td>
                                            <td>{item.classificacao}</td>
                                            <td>{item.quantidade}</td>
                                        </tr>
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
