import React, { Component } from 'react';
import {
    FieldGroup, Row, Col, Button, ButtonToolbar,
    FormGroupButton, Modals
} from '../../components/adminlte/AdminLTE';

import { PesquisarMaterial } from './PesquisarMaterial';
import { toast } from 'react-toastify';

export class SolicitarMaterial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            row: {},
            materialList: [],
            materialValueSearch: '',
            showFormFindMateriais: false,
            data: {
                description: '',
                materialCode: 0,
                estoqueCode: 0,
                amount: 0,
                valor: 0
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeMaterial = this.handleChangeMaterial.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row });
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "valor") {
            value = value.replace(/\d(?=(\d{3})+\.)/g, '$&');

            value = value.replace(",", ".");
            this.setState(prevState => {
                return { data: { ...prevState.data, [name]: value } };
            });
        }
        else {
            this.setState(prevState => {
                return { data: { ...prevState.data, [name]: value } };
            });
        }
    }

    handleSubmit(e) {
        if (this.state.data.estoqueCode === 0) {
            toast.warn('Informe o Estoque');
            return;
        }

        if (this.state.data.materialCode === 0) {
            toast.warn('Informe o Material');
            return;
        }

        if (this.state.data.amount === 0) {
            toast.warn('Informe a Quantidade');
            return;
        }

        if (this.state.data.valor === 0 || this.state.data.valor === "" || this.state.data.valor === "0") {
            toast.warn('Informe o Valor');
            return;
        }

        if (this.state.data.description === "") {
            toast.warn('Informe a Descrição');
            return;
        }

        const { config, row } = this.props;

        fetch(config.apiEndPoint + row.codeOS + "/solicitMaterial", {
            method: 'PUT',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }

        }).then(response => {

            return Promise.all([response.status, response.json()]);

        }).then((response) => {

            if (response[0] === 200) {
                toast.success('Material solicitado com sucesso!');
                this.onHide();
                this.props.getMateriaisSolicitados(row.codeOS);
            }
            else {
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            toast.error(error.response.data.msg);
        });
    }

    onHide() {
        this.setState({
            materialValueSearch: '',
            data: {
                description: '',
                materialCode: 0,
                estoqueCode: 0,
                amount: 0,
                valor: 0
            }
        });

        this.props.onHide();
    }

    handleChangeMaterial(filter) {
        fetch(this.state.config.apiUrl + "material", {
            method: 'GET',
            body: JSON.stringify(filter),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ materialList: data });
        });
    }

    handleAddMaterial(row) {
        this.setState({ materialValueSearch: row.name, materialList: [] });
        this.setState(prevState => {
            return { data: { ...prevState.data, materialCode: row.materialCode, valor: row.valor } };
        });
    }

    render() {
        const { show = false, config, row } = this.props;
        const { isSaving, data } = this.state;

        return (
            <Modals title={"Solicitar Material OS " + row.codeOS} show={show} onHide={() => this.onHide()}
                footer={
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="success" disabled={isSaving} onClick={this.handleSubmit}>
                            {isSaving ? 'Salvando...' : 'Salvar'}
                        </Button>
                        <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                    </ButtonToolbar>
                }>
                <PesquisarMaterial config={config} data={data} show={this.state.showFormFindMateriais} onImport={(row) => { this.handleAddMaterial(row); }} row={row} onHide={() => this.setState({ showFormFindMateriais: false })} />
                <Row>
                    <Col md={9}>
                        <FormGroupButton placeholder="Material" label="Material" disabled onChange={this.materialSearch} value={this.state.materialValueSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showFormFindMateriais: true })}>Selecionar</Button>} />
                    </Col>
                    <FieldGroup id="valor" col={3} type="number" label="Valor" value={this.state.data.valor} onChange={this.handleChange} />
                    <FieldGroup id="estoqueCode" col={9} componentClass="select" label="Estoque" value={this.state.data.estoqueCode} onChange={this.handleChange}>
                        <option key="" value="Selecione">Selecione</option>
                        <option key="1" value="1">Estoque MG</option>
                        <option key="2" value="2">Estoque RJ</option>
                        <option key="3" value="3">Estoque SP</option>
                    </FieldGroup>
                    <FieldGroup id="amount" col={3} type="number" label="Quantidade" value={this.state.data.amount} onChange={this.handleChange} />
                    <FieldGroup id="description" col={12} componentClass="textarea" maxLength="1000" type="text" label="Descrição" value={this.state.data.description} onChange={this.handleChange} />
                </Row>
            </Modals>
        );
    }
}