import React, { useState, useEffect } from 'react';
import { Content, config, apiHelper, toast, Modal, Link } from '../';
import { CampanhaForm } from './CampanhaForm';
import { Consultor } from './Consultor';

export function Campanha(props) {
    const endPoint = 'marketing/campanhas/';
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: 'Nova Campanha',
        formName: '',
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        try {
            apiHelper.get(endPoint).then(res => {
                setData(res.data);
            });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    };

    const onLoad = () => {
        //config.validarPermissao('MenuMarketing');
        fetchData();
    };

    useEffect(onLoad, []);

    const handleRefresh = () => {
        setData([]);
        fetchData();
    };

    const handleShowForm = (acao, rowId) => {
        setParam({
            ...params,
            rowId: rowId || 0,
            showModal: true,
            formName: acao,
            modalTitle: acao === 'new' ? 'Nova Campanha' : 'Editar Campanha'
        });
    };

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: ""
        });

        refresh && fetchData();
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData('');
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const getImage = (img) => {
        let url = config.apiUrl() + endPoint.replace("//", "/") + "imagem/" + img;
        return url;
    };

    return (
        <Content title="Campanhas" browserTitle="EMA">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                            <li><a href="#tab_consultores" data-toggle="tab">Consultores</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("new", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Nova Campanha</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue color-palette">
                                                <th>ID</th>
                                                <th>Nome</th>
                                                <th>Status</th>
                                                <th>Tipo Destinatário</th>
                                                <th>Data Disparo</th>
                                                <th>Imagem</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.id}</td>
                                                            <td><Link to={'/marketing/campanha/' + item.id}>{item.nome}</Link></td>
                                                            <td>{item.status}</td>
                                                            <td>{item.tiposDestinatarios}</td>
                                                            <td>{config.dateTimeFix(item.dataDisparo)}</td>
                                                            <td><img src={getImage(item.anexo)} style={{ height: "150px" }} alt={item.anexo} /></td>
                                                            <td style={{ width: '90px' }}>
                                                                {(!item.status || item.status !== 'Enviado') &&
                                                                    <div className="btn-toolbar">
                                                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm('edit', item.id)}> <i className="fa fa-edit" /></button>
                                                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.id)}> <i className="fa fa-trash" /></button>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>

                            <div id="tab_consultores" className="tab-pane">
                                <Consultor onHide={handleHide} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(params.formName === 'new' || params.formName === 'edit') && <CampanhaForm onHide={(acao) => handleHide(acao)} rowId={params.rowId} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
