import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../index';

export function GrupoPermissoes(props) {
    const endPointGroup = 'identity/Group';
    const parentId = props.parentId;
    const [distinctCategorias, setDistinctCategorias] = useState([]);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        nome: ''
    });

    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (description) => {
        let where = '';
        if (description && description.length > 0) {
            if (description.length < 3)
                return;
            where = `description::contains::${description}`;
        }

        apiHelper.get(`${endPointGroup}/Permissoes/${parentId}?filter=${where}`).then(res => {
            let teste = [...new Set(res.data.data.map(item => item.category))];
            setDistinctCategorias(teste);
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData("");
    };

    const onUpdatePage = () => {
        setParam({
            ...params,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });
    };

    useEffect(onLoad, [parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
        fetchData(value);
    };

    const handleAdicionar = (item) => {
        if (item.associado) {
            handleDesassociar(item);
            return;
        }
        if (item.category === "Relatório") {
            let data = {
                reportId: item.id,
                groupId: parentId
            }

            apiHelper.post('portal/crud/IdentityGroupReport', data).then(res => {
                fetchData(filter.description);
                toast.success("Permissão adicionada ao grupo!")
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a interação.');
            });

        } else {
            let data = {
                claimId: item.id,
                groupId: parentId
            }

            apiHelper.post('portal/crud/IdentityGroupClaim', data).then(res => {
                fetchData(filter.description);
                toast.success("Permissão adicionada ao grupo!")
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a interação.');
            });
        }

    }

    const handleDesassociar = (item) => {
        apiHelper.api_delete(`${endPointGroup}/${item.idGroup}/claim/${item.id}/${item.category}/`).then(res => {
            fetchData(filter.description);
            toast.success("Permissção removida do grupo!")
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleRefresh = () => {
        setFilter({
            description: ''
        });

        setData([]);
        fetchData("");
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = (categoria) => {
        const pages = Math.ceil(data.length / params.pageSize);
        const categoriaLength = data.filter(z => z.category === categoria).length;
        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + categoriaLength + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-4">
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                    <td><input id="description" type="text" className="form-control input-sm" onChange={handleChange} value={filter.description} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ul class="nav nav-pills nav-stacked" role="tablist">
                        {distinctCategorias && distinctCategorias.length > 0 && distinctCategorias.sort((a, b) => a > b ? 1 : -1).map((item, i) => {
                            return (
                                <li role="presentation" class={i === 0 ? "active" : ""} onClick={() => onUpdatePage()}><a href={`#${item.replaceAll(" ", "")}`} data-toggle="tab">{item}</a></li>
                            )
                        })}

                    </ul>
                </div>
                <div className="col-md-8">
                    <div className="tab-content">
                        {distinctCategorias && distinctCategorias.length > 0 && distinctCategorias.sort((a, b) => a > b ? 1 : -1).map((item, i) => {
                            return (
                                <div className={i === 0 ? "tab-pane active" : "tab-pane"} id={item.replaceAll(" ", "")} >
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                            <thead>
                                                <tr className="bg-light-blue color-palette">
                                                    <th>Descrição</th>
                                                    <th style={{ width: "5px" }}>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.filter(z => z.category === item).sort((a, b) => a.description > b.description ? 1 : -1).slice(params.initRow, params.endRow).map((ite, j) => {
                                                    return (
                                                        <tr key={j}>
                                                            <td>{ite.description}</td>
                                                            <td> <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleAdicionar(ite)}> <i className={ite.associado ? "fa fa-minus" : "fa fa-plus"} /></button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div style={{ marginTop: "7px" }}>
                                            <div className="pull-right">
                                                {renderPage(item)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}