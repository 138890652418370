import React, { useEffect, useState } from 'react';
import { PesquisaGenerica } from '../../components/controls/PesquisaGenerica';
import { apiHelper, Button, Checkbox, FormGroupButton, Select, toast } from '../index';

export function OrcamentoProdutoForm(props) {
    const endPointOrcamentoSite = 'portal/crud/uvw_v2_comercial_orcamento_site';
    const endPointOrcamento = 'orcamento';
    const rowId = props.parentId;
    const idItem = props.idItem;
    const clienteId = props.clienteId;
    const [data, setData] = useState({});
    const [sites, setSites] = useState([]);
    const [showFormFindMateriais, setShowFormFindMateriais] = useState(false);
    const [tipos, setTipos] = useState([]);

    const fetchData = () => {
        if (clienteId === undefined)
            return;

        if (idItem && idItem > 0) {
            obterProduto();
        }

        obterSites();
        obterTipos();
    }

    const obterProduto = () => {
        let obj = {};

        apiHelper.get(`${endPointOrcamento}/${idItem}/obterProduto?orcamentoId=${rowId}`)
            .then(res => {
                let data = res.data.data;
                data[0].produtoTemporario = (data[0].tipo === 'venda' || data[0].tipo === 'locacao') && (data[0].idMaterial === 0 || data[0].idMaterial === null) ? true : false;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj[0]);

            });
    }

    const obterSites = () => {
        apiHelper.get(`${endPointOrcamentoSite}?filter=IdCliente::equal::${clienteId};;AND;;orcamentoId::equal::${rowId};;`)
            .then(res => {
                setSites(res.data.data);
            });
    }

    const obterTipos = () => {
        apiHelper.get(`${endPointOrcamento}/${rowId}/tipo`).then(res => {
            let data = res.data.data;
            setTipos(data[0]);
        });
    }

    useEffect(fetchData, [props.clienteId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'tipo' && (value === 'venda' || value === 'locacao')) {
            setData({ ...data, nome: '', valorUnitario: '', tipo: value });
            return;
        }

        if (name === 'produtoTemporario') {
            setData({
                ...data,
                idMaterial: 0,
                nome: '',
                valorUnitario: 0,
                custoInstalacao: 0,
                valorManutencao: 0,
                [name]: value
            });
            return;
        }

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (sites.length === 0) {
            toast.warn("Cadastre pelo menos um site na aba Site!");
            return;
        }

        let form = {
            orcamentoId: rowId,
            siteId: data.siteId,
            idMaterial: data.idMaterial !== undefined ? data.idMaterial.toString() : '',
            nome: data.nome,
            unidade: '',
            quantidade: data.quantidade,
            valorUnitario: data.valorUnitario !== undefined ? data.valorUnitario : 0,
            custoInstalacao: data.custoInstalacao !== undefined ? data.custoInstalacao : 0,
            valorManutencao: data.valorManutencao !== undefined ? data.valorManutencao : 0,
            reposicao: data.reposicao !== undefined ? ((data.reposicao === true || data.reposicao === 'true')) : false,
            lpu: data.lpu !== undefined ? ((data.lpu === true || data.lpu === 'true')) : false,
            instalacao: data.instalacao,
            manutencao: data.manutencao,
            tipo: data.tipo,
            custoAdicionalRecorrente: 0,
            custoAdicionalNaoRecorrente: 0,
            valorLPU: (data.lpu === 'true' || data.lpu === true) && data.valorLPU !== undefined ? data.valorLPU : 0,
            classificacao: data.classificacao
        };
        if (idItem === 0) {
            apiHelper.post(`orcamento/material/`, form).then(res => {
                if (res.data.code === 200)
                    toast.success('Dados salvos com sucesso');
                else if (res.data.code === 400)
                    toast.error(res.data.data);
                else
                    toast.error('Atenção, não foi possível salvar o registro.');

                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPointOrcamento}/${idItem}/produto`, form)
                .then(res => {
                    if (res.data.code === 200)
                        toast.success('Dados salvos com sucesso');
                    else if (res.data.code === 400)
                        toast.error(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                    props.onHide(true);

                }).catch(error => {
                    toast.error('Atenção, não foi possível atualizar o registro.');
                });
        }

    };

    const handleAddMaterial = (rowMaterial) => {
        setData({
            ...data,
            idMaterial: rowMaterial.id,
            nome: rowMaterial.nome,
            valorUnitario: rowMaterial.valorUnidade,
            custoInstalacao: rowMaterial.custoInstalacao,
            valorManutencao: rowMaterial.valorManutencao,
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="siteId">Site</label>
                    <Select id="siteId" className="form-control" onChange={handleChange} value={data.siteId || ""}>
                        <option value="">Todos</option>
                        {sites && sites.map((item, i) => {
                            return (<option key={i} value={item.id}>{item.nome}</option>)
                        })}
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Tipo *</label>
                    <Select id="tipo" className="form-control" onChange={handleChange} value={data.tipo} required>
                        <option value="">{tipos ? 'Selecione' : 'Tipo de orçamento não informado'}</option>
                        {tipos && tipos.map((item, i) => {
                            return (<option key={i} value={item.valor}>{item.nome}</option>)
                        })}
                    </Select>
                </div>
                {(data.tipo === 'venda' || data.tipo === 'locacao') &&
                    <div className="form-group col-md-12">
                        <Checkbox id="produtoTemporario" onChange={handleChange} checked={data.produtoTemporario ? data.produtoTemporario : false}>Produto Temporário</Checkbox>
                    </div>}
                <div className="form-group col-md-12">
                    <FormGroupButton id="nome" value={data.nome || ""} label="Produto *" onChange={handleChange}
                        buttonRight={<Button bsStyle="success" type="button" onClick={() => setShowFormFindMateriais(true)} disabled={(data.tipo !== 'venda' && data.tipo !== 'locacao') || ((data.tipo === 'venda' || data.tipo === 'locacao') && data.produtoTemporario) ? true : false}>...</Button>} disabled={(data.tipo !== 'venda' && data.tipo !== 'locacao') || ((data.tipo === 'venda' || data.tipo === 'locacao') && data.produtoTemporario) ? false : true} required
                    />
                </div>
                <div className="form-group col-sm-6">
                    <label>Classificacao*</label>
                    <Select id="classificacao" className="form-control" onChange={handleChange} value={data.classificacao} required>
                        <option value="">Selecione</option>
                        {(data.tipo === 'venda' || data.tipo === 'locacao') &&
                            <>
                                <option value='Hardware'>Hardware</option>
                                <option value='Software'>Software</option>
                            </>}
                     <option value='Serviço'>Serviço</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Quantidade *</label>
                    <input id="quantidade" type="number" className="form-control" onChange={handleChange} value={data.quantidade} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Valor Unit. *</label>
                    <input id="valorUnitario" type="number" step="any" className="form-control" onChange={handleChange} value={data.valorUnitario} disabled={(data.tipo !== 'venda' && data.tipo !== 'locacao') || ((data.tipo === 'venda' || data.tipo === 'locacao') && data.produtoTemporario) ? false : true} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Reposição</label>
                    <Select id="reposicao" className="form-control" onChange={handleChange} value={data.reposicao}>
                        <option value="">Selecione</option>
                        <option value={'true'}>Sim</option>
                        <option value={'false'}>Não</option>
                    </Select>
                </div>
                <div className="form-group col-sm-4">
                    <label>LPU</label>
                    <Select id="lpu" className="form-control" onChange={handleChange} value={data.lpu}>
                        <option value="">Selecione</option>
                        <option value={'true'}>Sim</option>
                        <option value={'false'}>Não</option>
                    </Select>
                </div>
                {(data.lpu === 'true' || data.lpu === true) &&
                    <div className="form-group col-sm-6">
                        <label>Valor LPU</label>
                        <input id="valorLPU" type="number" step="any" className="form-control" onChange={handleChange} value={data.valorLPU} />
                    </div>}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
            {showFormFindMateriais && <PesquisaGenerica config={{ apiUrl: localStorage.getItem('apiUrl') }} title="Buscar Materiais" source="comercial/material/?nome=" data={data} show={showFormFindMateriais} AllowInput="true" onImport={(row) => { handleAddMaterial(row) }} row={''} onHide={() => { setShowFormFindMateriais(false) }} />}
        </form>
    )
}
