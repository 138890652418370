import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const userImage = process.env.PUBLIC_URL + '/img/no-avatar.png';

export class Navbar extends Component {
    render() {
        let name = localStorage.getItem('id_name');
        return (
            <nav className="navbar navbar-static-top">
                <Link to="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                    <span className="sr-only">Toggle navigation</span>
                </Link>
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <li className="dropdown user user-menu">
                            <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                                <img src={userImage} className="user-image" alt="" />
                                <span className="hidden-xs">{name}</span>
                            </Link>
                            <ul className="dropdown-menu">
                                <li className="user-header">
                                    <img src={userImage} className="img-circle" alt="" />
                                    <p>{name}</p>
                                </li>
                                <li className="user-footer">
                                    <div className="pull-left">
                                        <Link to="/identity/perfil" className="btn btn-default btn-flat">Perfil</Link>
                                    </div>
                                    <div className="pull-right">
                                        <Link to="/logoff" className="btn btn-default btn-flat">Sair</Link>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        {true === false && <li>
                            <Link to="#" data-toggle="control-sidebar"><i className="fa fa-gears" /></Link>
                        </li>}
                    </ul>
                </div>
            </nav>
        );
    }
}
