import React, { useState, useEffect } from 'react';
import { apiHelper } from './../../index';

export function ResumoMes(props) {
    const endPoint = 'atendimentoReport/resumoMes';
    const [data, setData] = useState([]);
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = [];
            res.data.data.dataSemAcidente.map((item) => {

                let percentRealizadas = 0;
                let abertas = item.abertas !== null ? item.abertas : 0;
                let realizadas = item.realizada !== null ? item.realizada : 0;
                if (abertas !== 0)
                    percentRealizadas = Math.round((realizadas * 100) / abertas);

                let obj = {
                    tipoAtendimento: item.tipoAtendimento,
                    indice: item.indice,
                    estipulado: item.estipulado,
                    percentRealizada: percentRealizadas
                }

                data.push(obj);

                return null;
            });

            res.data.data.dataAcidente.map((item) => {
                let obj = {
                    tipoAtendimento: item.tipoAtendimento,
                    indice: item.indice,
                    estipulado: item.estipulado,
                    //percentRealizada: percentRealizadas,
                    realizada: item.realizada !== null ? item.realizada : 0
                }

                data.push(obj);

                return null;
            });

            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Resumo do mês</h3>
            </div>
            <div className="panel-body">
                {data && data.length > 0 &&
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin">
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th>Indicador</th>
                                            <th>Indice</th>
                                            <th>Tipo de Atendimento</th>
                                            <th>Estipulado</th>
                                            <th>Realizado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.indice}</td>
                                                    <td>{item.tipoAtendimento}</td>
                                                    <td>{item.estipulado + '%'}</td>
                                                    {item.percentRealizada !== undefined ? <td>{item.percentRealizada + '%'}</td> : <td>{item.realizada}</td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}
