import React, { useState, useEffect } from 'react';
import { apiHelper, config, toast, Modal } from '../';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function RequisicaoMaterialView(props) {
    const endPoint = 'suprimentos/rm/';
    const rowId = props.parentId;
    const [totalItens, setTotalItens] = useState({});
    const [data, setData] = useState({});
    const [dataMaterialItem, setDataMaterialItem] = useState([]);
    const [form, setForm] = useState([]);
    const [params, setParams] = useState({ showModal: false, new: false, edit: false });

    const fetchData = () => {
        rowId > 0 && apiHelper.get(`${endPoint}/${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);
                }
                else
                    toast.error('Atenção, não foi executar esta operação ' + rowId);
            });
    };

    const fetchDataItens = () => {
        rowId > 0 && apiHelper.get(`${endPoint}${rowId}/material/`)
            .then(res => {
                if (res.data.code === 200) {
                    setDataMaterialItem(res.data.data);
                    handleRefresh(res.data.data.length);
                }
                else
                    toast.error('Atenção, não foi possível carregar os materiais!');
            }).catch(error => {
                toast.error(error);
            });
    }

    useEffect(fetchData, []);
    useEffect(fetchDataItens, [rowId]);

    const handleRefresh = (itens) => {
        setTotalItens(itens);
        fetchData();
    };

    const handleSolicitarAprovacao = () => {
        if (totalItens === 0) {
            toast.warn('Adicione pelo menos um item');
            return;
        }

        if (!window.confirm("Deseja realmente enviar esta requisição ?"))
            return;

        apiHelper.put(`${endPoint}solicitarAprovacao/${rowId}`)
            .then(res => {
                config.httpResult(res);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    };

    const handleClose = () => {
        props.onHide();
    };

    const handleNew = () => {
        setForm({});
        setParams({ ...params, showModal: true, new: true, edit: false });
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}item/${id}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchDataItens();
                }
                else
                    toast.error('Atenção, não foi possível excluir o produto!');
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o produto!');
            });
    };

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        delete form.nome;
        var obj = form;
        obj.requisicaoId = rowId;
        obj.processed = 0;
        obj.siteId = data.siteId;

        console.log(obj);

        if (params.new) {
            obj.status = "Rascunho";

            apiHelper.post(endPoint + 'item', obj).then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    setParams({ ...params, showModal: false, edit: false, new: false });
                    fetchDataItens();
                }
                else
                    toast.error('Atenção, não foi possível salvar a requisição.');
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a requisição.');
            });
        }
    };

    const handleAddMaterial = (row) => { 
        setForm({ ...form, materialId: row.pkMaterial, nome: row.nome });
    }

    const handleHide = () => {
        setParams({ ...params, showModal: false, edit: false, new: false });
        fetchDataItens();
    };

    return (
        <div className="no-margin">
            <div className="box-header">
                <h3 className="box-title">Requisição {data.id}</h3>

                <div className="box-tools pull-right">
                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                </div>
            </div>
            <div className="box-body">
                {data.id > 0 &&
                    <React.Fragment >
                        <div style={{ marginBottom: "7px" }}>
                            {(data.solicitanteId === config.idUsuarioLogado() && data.status === "Rascunho") &&
                                <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
                            }
                            {(data.status === 'Rascunho' && totalItens > 0) &&
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleSolicitarAprovacao}><i className="fa fa-share" /> Solicitar Aprovação</button>
                            }
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                <thead>
                                    <tr className="bg-light-blue">
                                        <th>Produto</th>
                                        <th>Qtde</th>
                                        <th>Previsao Entrega</th>
                                        <th>Data Entrega</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataMaterialItem.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                        .map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.nome}</td>
                                                    <td>{item.quantidade}</td>
                                                    <td>{config.dateTimeFix(item.previsaoEntrega)}</td>
                                                    <td>{config.dateTimeFix(item.dataEntrega)}</td>
                                                    <td align="center">
                                                        {item.status === "Rascunho" &&
                                                            <button onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /></button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>{params.edit ? "Atualizar" : "Adicionar Produto"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={handleSubmit}>
                                      
                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-12">
                                            <PesquisaGenericaHooks required textField="nome" titulo="Material" selecao={form.materialId} endpoint="portal/crud/material/?filter=nome::contains::" onImport={(row) => { handleAddMaterial(row); }} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label>Quantidade</label>
                                            <input type="number" id="quantidade" className="form-control" onChange={handleChange} value={form.quantidade || ''} required />
                                        </div>
                                   
                                        <div className="form-group col-md-9">
                                            <label>Sugestão Fornecedor</label>
                                            <input type="text" id="sugestaoFornecedor" className="form-control" onChange={handleChange} value={form.sugestaoFornecedor || ''} required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                        <label>Observação</label>
                                        <textarea id="observacao" className="form-control" rows={3} onChange={handleChange} value={form.observacao || ''} required={form.nome === 'Material Genérico' ? true : false}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-default pull-right" onClick={handleHide}>Cancelar</button>
                                            <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>{params.edit ? "Atualizar" : "Adicionar"}</button>
                                        </div>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
