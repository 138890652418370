import React, { Component } from 'react';
import {
    Modals, FieldGroup, Table, Button
} from '../../components/adminlte/AdminLTE';
import { toast } from 'react-toastify';

export class ModalFindDoors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            deviceChannelCode: this.props.deviceChannelCode,
            onImport: this.props.onImport,
            isSaving: false,
            row: {},
            tableDoorsDevice: [],
            channelDoors: [],
            doorSearch: '',
        };

        this.setDoors = this.setDoors.bind(this);
        this.tableDoorsBySearch = this.tableDoorsBySearch.bind(this);
        this.findDoors = this.findDoors.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { deviceChannelItem } = this.props;

        this.setState({ deviceChannelCode: deviceChannelItem.deviceChannelCode });
        this.getDoorsFromChannel(deviceChannelItem.deviceChannelCode);
    }

    findDoors(e) {
        if (this.state.deviceChannelCode === '') {
            toast.warn('Necessario informar o Canal primeiro.');
            return;
        }

        let value = e === null ? '' : e.target.value;
        this.setState({ doorSearch: value });

        if (value.length > 2 || value === '')
            this.tableDoorsBySearch(value);
    }

    tableDoorsBySearch(filter) {
        const { apiUrl, token } = this.props.config;

        if (filter === undefined)
            filter = '';

        fetch(apiUrl + 'doorsChannel?filter=' + filter, {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ tableDoorsDevice: data });
        });
    }

    onHide() {
        this.setState({ tableDoorsDevice: [], doorSearch: '' });
        this.props.onHide();
    }

    setDoors(row) {
        this.setState({ tableDoorsDevice: [], doorSearch: row.name });
        this.state.onImport(row);
    }

    findChannelDoor(obj) {
        let channel = this.state.channelDoors.filter((e) => e.doorCode === obj.objectID && e.channelCode === this.state.deviceChannelCode);
        return channel.length > 0;
    }

    getDoorsFromChannel(channelCode) {
        if (channelCode === '')
            return;

        this.setState({ table: [] });

        const { apiUrl, token } = this.props.config;

        fetch(apiUrl + channelCode + '/doorsChannel', {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ channelDoors: data.listDoorsChannel, tableDoorsChannel: data.doors });
        });
    }

    render() {
        const { show = false } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title="Adicionar porta" show={show} onHide={() => this.onHide()} >
                <FieldGroup id="door" type="text" onChange={this.findDoors} value={this.state.doorSearch} />

                <Table striped bordered hover>
                    <tbody>
                        {this.state.tableDoorsDevice.length > 0 && this.state.tableDoorsDevice.map(row => {
                            return (
                                <tr key={row.objectID}>
                                    <td>{row.name}</td>
                                    <td style={{ width: "50px" }}>
                                        {
                                            this.findChannelDoor(row) ? '' : <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.setDoors(row)}>
                                                {isSaving ? 'Adicionando...' : 'Adicionar'}
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Button bsStyle="success" type="button" onClick={() => this.onHide()}>Ok</Button>
            </Modals>
        );
    }
}

