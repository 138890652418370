import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FasIcon } from '../../components/adminlte/AdminLTE';
import { CancelarAcionamento } from './CancelarAcionamento';
import Core from '../Core';
 
export default class OrdemServico extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filePath: '',
            cancelarAcionamento: false,
            actTechnicalCode: 0
        };
    }

    modalCancelActTecnhical(technicalCode) {
        this.setState({
            cancelarAcionamento: true,
            actTechnicalCode: technicalCode
        });
    }

    onHide() {
        this.setState({
            cancelarAcionamento: false,
            actTechnicalCode: 0
        });

        this.props.getDataTable(this.props.codeOs);
    }

    downloadAnexo = async (fileName) => {
        await Core.api_download('OsAnexo', fileName).then(res => {
        });
    };

    render() {
        const { data } = this.props;
        const { cancelarAcionamento } = this.state;

        return (
            <React.Fragment>
                {cancelarAcionamento && <CancelarAcionamento show={cancelarAcionamento} actTechnicalCode={this.state.actTechnicalCode} onHide={(codigo) => this.onHide(codigo)} />}
                <ul className="timeline timeline-inverse">
                    {data.reverse().map((value, index) => {
                        return (
                            <React.Fragment key={index}>
                                <li key={index} className="time-label">
                                    <span className="bg-red">{value.date}</span>
                                </li>
                                {
                                    value.itens.reverse().map(item => {
                                        let { hour, description, title, color, icon, codeOS, anexoCode, anexoName, actTechnicalCode, isActTechnicalCanceled, isActTechnicalDone, isVisivelCliente } = item;
                                        var displayAnexo = anexoCode === null || anexoCode === undefined || anexoCode === 0 ? 'none' : '';
                                        var displayCancelActTechnical = actTechnicalCode === null || actTechnicalCode === undefined || actTechnicalCode === 0 ? 'none' : '';

                                        var actCancelado = isActTechnicalCanceled === true ? '' : 'none';
                                        var actConcluido = isActTechnicalDone === true ? '' : 'none';
                                        var actCancelar = isActTechnicalCanceled === false && isActTechnicalDone === false ? '' : 'none';

                                        return (
                                            <li key={codeOS}>
                                                <i className={color}><FasIcon name={icon} /></i>
                                                <div className="timeline-item">
                                                    <span className="time"><FasIcon name="clock-o" /> {hour}</span>
                                                    <span className="time">{isVisivelCliente ? <FasIcon name="eye" /> : <FasIcon name="eye-slash" />} </span>
                                                    <h3 className="timeline-header"><Link to="#">{title}</Link> </h3>
                                                    <div className="timeline-body">
                                                        {description}

                                                        <div style={{ display: displayAnexo }}>
                                                            <br />
                                                            <Link to="#" onClick={() => this.downloadAnexo(anexoName)}>Anexo</Link>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-footer">
                                                        <div style={{ display: displayCancelActTechnical }}>
                                                            <span style={{ display: actConcluido }} className="btn btn-success btn-flat btn-xs">Acionamento Técnico Concluído</span>
                                                            <span style={{ display: actCancelado }} className="btn btn-warning btn-flat btn-xs">Acionamento Técnico Cancelado</span>
                                                            <div style={{ display: actCancelar }}>
                                                                <Link to="#" className="btn btn-warning btn-flat btn-xs" onClick={() => this.modalCancelActTecnhical(actTechnicalCode)}>Cancelar Acionamento</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </React.Fragment>
                        );
                    })}
                    <li>
                        <i className="fa bg-gray"><FasIcon name="clock-o" /></i>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}
