import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { config, apiHelper, toast } from '../index';

export function MotivoReprovacaoForm(props) {
    const { handleSubmit } = useForm();
    const rowId = props.parentId;
    const [data, setData] = useState({});



    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (e.target.maxLength > 0 && value.length > e.target.maxLength) {
            return;
        }

        setData({ ...data, [name]: value });
    }

    const onSubmit = () => {
        apiHelper.put(`suprimentos/rm/cancelarRequisicao/${rowId}/?motivoCancelamento=${data.descricao}`)
            .then(res => {
                config.httpResult(res.data);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="form-group col-md-12">
                    <div className="form-group">
                        <label>Motivo Cancelamento</label>
                        <textarea id="descricao" className="form-control" onChange={handleChange} required />
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Cancelar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
