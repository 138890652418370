import React, { useState, useEffect } from 'react';
import { apiHelper, config } from './../../index';

export function Comparativo(props) {
    const endPoint = 'lead/report';
    const [dataTable, setDataTable] = useState([]);
    const [totais, setTotais] = useState({});
    const [periodo, setPeriodo] = useState({});

    const handleSearch = () => {
        let origem = props.origem;
        let where = '';
        if (!origem)
            return;
        where = `${origem}`;

        apiHelper.get(`${endPoint}/comparativo?filter=${where}`).then(res => {
            if (res.data.data.length > 0) {
                let data = res.data.data;
                let totalContatos = 0;
                let totalVisitas = 0;
                let totalPropostas = 0;

                obterMes();

                for (var i = 0; i < data.length; i++) {
                    data[i].percentualAproveitamentoVisitas = Math.round((data[i].visita * 100) / data[i].contato) + '%';
                    data[i].percentualAproveitamentoPropostas = Math.round((data[i].proposta * 100) / data[i].contato) + '%';
                    totalContatos = totalContatos + data[i].contato;
                    totalVisitas = totalVisitas + data[i].visita;
                    totalPropostas = totalPropostas + data[i].proposta;
                }

                let result = {
                    totalContatos: totalContatos,
                    totalVisitas: totalVisitas,
                    totalPropostas: totalPropostas,
                    totalPercentualAproveitamentoVisitas: Math.round((totalVisitas * 100) / totalContatos) + '%',
                    totalPercentualAproveitamentoPropostas: Math.round((totalPropostas * 100) / totalContatos) + '%',
                };

                setTotais(result);
                setDataTable(data);
            } else {
                setTotais({});
                setDataTable([]);
            }
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim]);

    const obterMes = () => {
        var month = [];
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        var dataIni = new Date(new Date().getFullYear(), 0, 1);
        setPeriodo({
            dataInicio: config.dateFix(dataIni),
            dataFim: config.dateFix(new Date()),
            mes: month[dataIni.getMonth()],
            ano: dataIni.getFullYear()
        });
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Quadro de Performance Comparativa- {props.origem} de {periodo.dataInicio} à {periodo.dataFim}</h3>
            </div>
            <div className="panel-body">
                {dataTable.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr style={{ 'backgroundColor': '#EBEBEC' }}>
                                                <th>Atividade</th>
                                                {dataTable.sort().map((item, i) => {
                                                    return (<th key={i}>{`${item.origem} ${config.formatName(item.nome) }`}</th>)
                                                })}
                                                <th>Total/ Média</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Total de Contatos Passados (Desde {periodo.mes}/{periodo.ano})</th>
                                                {dataTable.sort().map((item, i) => {
                                                    return (<td key={i}>{item.contato}</td>)
                                                })}
                                                <td>{totais.totalContatos}</td>
                                            </tr>
                                            <tr>
                                                <th className="bg-light-blue">Visitas Obtidas (Desde {periodo.mes}/{periodo.ano})</th>
                                                {dataTable.sort().map((item, i) => {
                                                    return (<td key={i}>{item.visita}</td>)
                                                })}
                                                <td>{totais.totalVisitas}</td>
                                            </tr>
                                            <tr>
                                                <th>Porcentual de Aproveitamento (Desde {periodo.mes}/{periodo.ano})</th>
                                                {dataTable.sort().map((item, i) => {
                                                    return (<td key={i}>{item.percentualAproveitamentoVisitas}</td>)
                                                })}
                                                <td style={{ 'backgroundColor': '#85F72C' }}>{totais.totalPercentualAproveitamentoVisitas}</td>
                                            </tr>
                                            <tr>
                                                <th className="bg-light-blue">Propostas Geradas (Desde {periodo.mes}/{periodo.ano})</th>
                                                {dataTable.sort().map((item, i) => {
                                                    return (<td key={i}>{item.proposta}</td>)
                                                })}
                                                <td>{totais.totalPropostas}</td>
                                            </tr>
                                            <tr>
                                                <th>Porcentual de Aproveitamento (Desde {periodo.mes}/{periodo.ano})</th>
                                                {dataTable.sort().map((item, i) => {
                                                    return (<td key={i}>{item.percentualAproveitamentoPropostas}</td>)
                                                })}
                                                <td style={{ 'backgroundColor': '#85F72C' }}>{totais.totalPercentualAproveitamentoPropostas}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
