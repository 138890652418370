import React, { useState, useEffect } from 'react';
import { apiHelper } from './../../index';

let distinctMes = [];
let distinctSites = [];
export function ManutencaoPreventivaGrid(props) {
    const endPoint = 'atendimentoReport/manutencaoPrevGrid';
    const [data, setData] = useState([]);
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;

            let sites = data.map((item, i) => { return item.site });
            distinctSites = sites.filter((v, i, a) => a.indexOf(v) === i);

            let mes = data.map((item, i) => { return item.mes });

            //Ordena os meses do ano
            var todosMeses = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'];
            distinctMes = mes.filter((v, i, a) => a.indexOf(v) === i);
            distinctMes.sort(function (a, b) {
                return todosMeses.indexOf(a) - todosMeses.indexOf(b);
            })

            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Manutenções Preventivas</h3>
            </div>
            <div className="panel-body">
                {data && data.length > 0 &&
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th rowspan="2">Site</th>
                                            {distinctMes && distinctMes.map((mes, i) => {
                                                return (
                                                    <th colspan="2" key={mes}>{mes}</th>
                                                )
                                            })}
                                        </tr>
                                        <tr className="bg-light-blue color-palette">
                                            {distinctMes && distinctMes.map((mes, i) => {
                                                return (
                                                    <React.Fragment>
                                                        <th colspan="1">Previsto</th>
                                                        <th colspan="1">Realizado</th>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {distinctSites && distinctSites.map((site, i) => {
                                            return (
                                                <tr>
                                                    <td>{site}</td>

                                                    {distinctMes && distinctMes.map((mes, i) => {
                                                        return (
                                                            <React.Fragment>
                                                                <td>{data.find(c => c.mes === mes && c.site === site) !== undefined ? data.find(c => c.mes === mes && c.site === site).qtdManutencaoPrevista : ''}</td>
                                                                <td>{data.find(c => c.mes === mes && c.site === site) !== undefined ? data.find(c => c.mes === mes && c.site === site).qtdManutencaoRealizada : ''}</td>
                                                            </React.Fragment>
                                                        )
                                                    })}

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}
