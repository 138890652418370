import React, { useEffect, useState } from 'react';
import { config, apiHelper } from '../index';

export function Extrato(props) {
    const endPoint = "v2/projeto/";
    const projetoId = props.parentId;
    const [data, setData] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}${props.parentId}/extrato`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId, props.refresh]);

    return (
        <div className="box box-primary">
            <div className="box-header with-border">
                <h3 className="box-title">Extrato</h3>

                <div className="box-tools pull-right">
                    <div className="btn-group">
                        <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                    </div>

                </div>
            </div>
            <div className="box-body">
                <table className="table table-striped no-margin">
                    <thead>
                        <tr>
                            <th style={{ background: '#E4E4E4' }}> Custos</th>
                            <th style={{ background: '#E4E4E4' }}> Previsto</th>
                            <th style={{ background: '#E4E4E4' }}> Realizado</th>
                            <th style={{ background: '#E4E4E4' }}> Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.custoInstalacao > 0 &&
                            <React.Fragment>
                                <tr>
                                    <td>Instalação</td>
                                    <td>{config.currencyFix(data.custoInstalacao)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoRealizado)}</td>
                                    <td>{config.currencyFix(data.custoInstalacao - data.custoInstalacaoRealizado)}</td>
                                </tr>
                                <tr>
                                    <td> - Hardware</td>
                                    <td>{config.currencyFix(data.custoInstalacaoHardware)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoHardwareRealizado)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoHardware - data.custoInstalacaoHardwareRealizado)}</td>
                                </tr>
                                <tr>
                                    <td> - Software</td>
                                    <td>{config.currencyFix(data.custoInstalacaoSoftware)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoSoftwareRealizado)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoSoftware - data.custoInstalacaoSoftwareRealizado)}</td>
                                </tr>
                                <tr>
                                    <td> - Serviço</td>
                                    <td>{config.currencyFix(data.custoInstalacaoServico)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoServicoRealizado)}</td>
                                    <td>{config.currencyFix(data.custoInstalacaoServico - data.custoInstalacaoServicoRealizado)}</td>
                                </tr>
                            </React.Fragment>
                        }
                        {data.custoManutencao > 0 &&
                            <React.Fragment>
                                <tr>
                                    <td>Manutenção</td>
                                    <td>{config.currencyFix(data.custoManutencao)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoRealizado)}</td>
                                    <td>{config.currencyFix(data.custoManutencao - data.custoManutencaoRealizado)}</td>
                                </tr>
                                <tr>
                                    <td> - Hardware</td>
                                    <td>{config.currencyFix(data.custoManutencaoHardware)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoHardwareRealizado)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoHardware - data.custoManutencaoHardwareRealizado)}</td>
                                </tr>
                                <tr>
                                    <td> - Software</td>
                                    <td>{config.currencyFix(data.custoManutencaoSoftware)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoSoftwareRealizado)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoSoftware - data.custoManutencaoSoftwareRealizado)}</td>
                                </tr>
                                <tr>
                                    <td> - Serviço</td>
                                    <td>{config.currencyFix(data.custoManutencaoServico)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoServicoRealizado)}</td>
                                    <td>{config.currencyFix(data.custoManutencaoServico - data.custoManutencaoServicoRealizado)}</td>
                                </tr>
                            </React.Fragment>
                        }
                        <tr>
                            <td>Produtos</td>
                            <td>{config.currencyFix(data.hardware + data.software)}</td>
                            <td>{config.currencyFix(data.hardwareRealizado + data.softwareRealizado)}</td>
                            <td>{config.currencyFix((data.hardware + data.software) - (data.hardwareRealizado + data.softwareRealizado))}</td>
                        </tr>
                        {data.hardware > 0 && <tr>
                            <td> - Hardware</td>
                            <td>{config.currencyFix(data.hardware)}</td>
                            <td>{config.currencyFix(data.hardwareRealizado)}</td>
                            <td>{config.currencyFix(data.hardware - data.hardwareRealizado)}</td>
                        </tr>}
                        {data.software > 0 && <tr>
                            <td> - Software</td>
                            <td>{config.currencyFix(data.software)}</td>
                            <td>{config.currencyFix(data.softwareRealizado)}</td>
                            <td>{config.currencyFix(data.software - data.softwareRealizado)}</td>
                        </tr>
                        }
                        <tr>
                            <td>Frete</td>
                            <td>{config.currencyFix(data.frete)}</td>
                            <td>{config.currencyFix(data.freteRealizado)}</td>
                            <td>{config.currencyFix(data.frete - data.freteRealizado)}</td>
                        </tr>
                        <tr>
                            <th style={{ background: '#E4E4E4' }}>Provisionamento</th>
                            <th style={{ background: '#E4E4E4' }}>Valor Provisionado</th>
                            <th style={{ background: '#E4E4E4' }}>Não Provisionado</th>
                            <th style={{ background: '#E4E4E4' }}></th>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{config.currencyFix(data.totalProvisionado)}</td>
                            <td>{config.currencyFix(data.totalAProvisionar)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th style={{ background: '#E4E4E4' }}>Faturamento</th>
                            <th style={{ background: '#E4E4E4' }}>Valor a Faturar</th>
                            <th style={{ background: '#E4E4E4' }}>Valor Faturado</th>
                            <th style={{ background: '#E4E4E4' }}>Saldo</th>
                        </tr>
                        <tr>
                            <td>Instalação</td>
                            <td>{config.currencyFix(data.totalAFaturInstalacao)}</td>
                            <td>{config.currencyFix(data.totalFaturadoInstalacao)}</td>
                            <td>{config.currencyFix(data.totalAFaturInstalacao - data.totalFaturadoInstalacao)}</td>
                           
                        </tr>
                        {data.totalAFaturarVenda > 0 && <tr>
                            <td>Venda</td>
                            <td>{config.currencyFix(data.totalAFaturarVenda)}</td>
                            <td>{config.currencyFix(data.totalFaturadoVenda)}</td>
                            <td>{config.currencyFix(data.totalAFaturarVenda - data.totalFaturadoVenda)}</td>
                        </tr>}
                        {data.totalAFaturarLocacao > 0 && <tr>
                            <td>Locação</td>
                            <td>{config.currencyFix(data.totalAFaturarLocacao)}</td>
                            <td>{config.currencyFix(data.totalFaturadoLocacao)}</td>
                            <td>{config.currencyFix(data.totalAFaturarLocacao - data.totalFaturadoLocacao)}</td>
                        </tr>}
                        {data.totalAFaturarManutencao > 0 && <tr>
                            <td>Manutenção</td>
                            <td>{config.currencyFix(data.totalAFaturarManutencao)}</td>
                            <td>{config.currencyFix(data.totalFaturadoManutencao)}</td>
                            <td>{config.currencyFix(data.totalAFaturarManutencao - data.totalFaturadoManutencao)}</td>
                        </tr>}
                        <tr>
                            <td>Total</td>
                            <td>{config.currencyFix(data.totalAFaturar)}</td>
                            <td>{config.currencyFix(data.totalFaturado)}</td>
                            <td>{config.currencyFix(data.totalAFaturar - data.totalFaturado)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
