import React, { useState, useEffect } from 'react';
import { Content, config, apiHelper, Link } from '../';
import { CentroCustoForm } from './CentroCustoForm';

export function CentroCusto(props) {
    const endPoint = 'v2/regional/';

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        pkRegional: '',
        gerenteRegional: ''
    });

    const [params, setParams] = useState({
        rowId: 0,
        edit: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}&order=nome DESC`)
            .then(res => {
                setData(res.data.data);
            });
    }

    const onLoad = () => {
        config.validarPermissao('MenuAtendimento');
        fetchData('');
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
        let where = '';

        if (name === "pkRegional" && value) {
            where = 'pkRegional::equal::' + value;
        } else {
            Object.entries(filter).map(([key, value2]) => {
                let operador = 'contains';

                if (key !== name) {
                    if (value2 && value2 !== '') {
                        if (where !== '') {
                            where += ';;AND;;';
                        }

                        where += `${key}::${operador}::${value2}`;
                    }
                } else if (value && value !== '') {
                    if (where !== '') {
                        where += ';;AND;;';
                    }

                    where += `${name}::${operador}::${value}`;
                }

                return "";
            });
        }

        fetchData(where);
    }

    const handleNew = () => {
        setParams({ ...params, edit: true });
    }

    const handleEdit = (id) => {
        setParams({ ...params, rowId: id, edit: true });
    }

    const onImport = () => {
        setParams({ ...params, rowId: 0, edit: false });
        fetchData();
    }

    const onHide = () => {
        setParams({
            ...params,
            rowId: 0,
            edit: false,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Regional" browserTitle="Regional">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Nova Regional</button>

                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue color-palette">
                                                <th style={{ width: "20px" }}>#</th>
                                                <th>Nome</th>
                                                <th>Gerente Regional</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /></button></td>
                                                <td><input type="text" className="form-control" id="nome" onChange={handleChange} /></td>
                                                <td><input type="text" className="form-control" id="gerenteRegional" onChange={handleChange} /></td>
                                            </tr>
                                            {data && data.sort((a, b) => a.pkOS < b.pkOS ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ width: '150px' }}>
                                                                <button type="button" title="Acionamento Técnico" className="btn btn-sm" onClick={() => handleEdit(item.pkRegional)}><i className="fa fa-edit" /></button>
                                                            </td>
                                                            <td><Link to={'/v2/regional/' + item.pkRegional}>{item.nome}</Link></td>
                                                            <td>{item.gerenteRegional}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat"><i className="fa fa-refresh" /> Atualizar</button>

                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {params.edit && <CentroCustoForm parentId={params.rowId} show={params.edit} onImport={onImport} onHide={onHide} />}
        </Content>
    )
}
