import { BehaviorSubject } from 'rxjs';
import jwt_decode from "jwt-decode";
import { handleResponse } from '../_helpers';

const userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const apiUrl = localStorage.getItem('apiUrl');

export const authService = {
    login,
    logout,
    checkValidSession,
    checkClaim,
    currentUser: userSubject.asObservable(),
    get currentUserValue() { return userSubject.value },
    get claims() { return userSubject && userSubject.value.claims },
};

async function login(username, password) {
    return await fetch(`${apiUrl}auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    }).then(handleResponse).then(res => {
        if (res.code === "200") {
            localStorage.setItem('currentUser', JSON.stringify(res.data));
            localStorage.setItem('id_token', res.data.token);
            userSubject.next(res.data);

            return res.data;
        }
        else
            return null;
    });
}

function logout() {
    localStorage.removeItem('id_token');
    userSubject.next(null);
}

function checkValidSession() {
    const token = localStorage.getItem('id_token');
    let loginApp = localStorage.loginApp ? JSON.parse(localStorage.loginApp) : {};

    if (!token)
        return false;

    if (jwt_decode(token).exp < Date.now() / 1000) {
        logout();
        if (loginApp.autoLogin) {
            login(loginApp.username, loginApp.password);
            return true;
        }

        localStorage.setItem('last_url', window.location.href);
        window.location.href = "#/login";

        return false;
    }

    return true;
};

function checkClaim(value) {
    let roles = jwt_decode(userSubject.value.token).role;
    return roles.includes(value);
}
