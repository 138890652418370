import React, { Component } from 'react';
import {
    Modals, FieldGroup, Table, Button
} from '../../components/adminlte/AdminLTE';

export class BuscarCliente extends Component {

    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            data: this.props.data,
            onImport: this.props.onImport,
            isSaving: false,
            row: {},
            clienteList: [],
            clienteValueSearch: ''
        };

        this.clienteSearch = this.clienteSearch.bind(this);
        this.setClientes = this.setClientes.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row, config: newProps.config });
    }

    getClientes(filter) {
        fetch(this.state.config.apiUrl + 'comercial/cliente/?filtro=' + filter, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(
            response => response.json()
        ).then(response => {
            if (response.data.length > 0) {
                this.setState({ clienteList: response.data });
            }
        });
    }

    clienteSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ clienteValueSearch: value });

        if (value.length > 2) {
            this.getClientes(value);
        } else {
            this.setState({ clienteList: [] });
        }
    }

    onHide() {
        this.setState({ clienteList: [], clienteSearch: '' });
        this.props.onHide();
    }

    setClientes(row) {
        this.setState({ clienteList: [], clienteValueSearch: row.nome });
        this.state.onImport(row);
    }

    render() {
        const { show = false } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title="Buscar Clientes" show={show} onHide={() => this.onHide()} >
                <form onSubmit={this.handleSubmit}>

                    <FieldGroup id="nameCliente" autoFocus col={12} type="text" onChange={this.clienteSearch} value={this.state.clienteValueSearch} />

                    <Table striped bordered hover>
                        <tbody>
                            {this.state.clienteList.map(row => {
                                return (
                                    <tr key={row.codigoIdentificador}>
                                        <td>{row.nome}</td>
                                        <td style={{ width: "50px" }}>
                                            <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.setClientes(row)}>
                                                {isSaving ? 'Adicionando...' : 'Adicionar'}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </form>
            </Modals>
        );
    }
}

