import React, { useState } from 'react';
import { Content, Link, config, apiHelper, fileHelper, Select, toast } from '../';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function AcompanhamentoObra(props) {
    const endPointAcompanhamentoObra = "siga/relatorio/comercial/acompanhamentoObra/"
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        DataAberturaCVInicio: null,
        DataAberturaCVFim: null,
        NumeroComunicacaoVenda: null,
        NumeroObraImplantacao: null,
        DataAprovacaoCV: null,
        IdUF: null,
        IdCliente: null,
        IdSite: null,
        IdPrestador: null,
        IdResponsavelObra: null,
        IdConsultorComercial: null,
        StatusImplantacao: null,
    });
    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowLoading(true);
        apiHelper.get(endPointAcompanhamentoObra + "?param=" + JSON.stringify(filter))
            .then(res => {
                setData(res.data.data);
                setShowLoading(false);
            });

    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            DataAberturaCVInicio: '',
            DataAberturaCVFim: '',
            NumeroComunicacaoVenda:'',
            NumeroObraImplantacao: '',
            DataAprovacaoCV: '',
            IdUF: '',
            IdCliente: '',
            IdSite: '',
            IdPrestador: '',
            IdResponsavelObra: '',
            IdConsultorComercial: '',
            StatusImplantacao: '',
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const handleOrdenarColunas = (e, nomeVariavelColuna) => {
        e.preventDefault();
        let sortedObjs = config.ordenarColunas(data, nomeVariavelColuna);
        setData(sortedObjs);
    }

    return (
        <Content title="Relatório Acompanhamento Obra" browserTitle="Relatório Acompanhamento Obra">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="NumeroComunicacaoVenda">Número Comunicação Venda</label>
                                <input id="NumeroComunicacaoVenda" type="text" className="form-control" placeholder="" value={filter.NumeroComunicacaoVenda} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>UF</label>
                                <Select id="IdUF" className="form-control" onChange={handleChange} value={filter.IdUF}>
                                    <option value="-1">Selecione...</option>
                                    <option value="1">Acre</option>
                                    <option value="2">Alagoas</option>
                                    <option value="3">Amazonas</option>
                                    <option value="4">Amapá</option>
                                    <option value="5">Bahia</option>
                                    <option value="6">Ceará</option>
                                    <option value="7">Distrito Federal</option>
                                    <option value="8">Espírito Santo</option>
                                    <option value="9">Goiás</option>
                                    <option value="10">Maranhão</option>
                                    <option value="11">Minas Gerais</option>
                                    <option value="12">Mato Grosso do Sul</option>
                                    <option value="13">Mato Grosso</option>
                                    <option value="14">Pará</option>
                                    <option value="15">Paraíba</option>
                                    <option value="16">Pernambuco</option>
                                    <option value="17">Piauí</option>
                                    <option value="18">Paraná</option>
                                    <option value="19">Rio de Janeiro</option>
                                    <option value="20">Rio Grande do Norte</option>
                                    <option value="21">Rondônia</option>
                                    <option value="22">Roraima</option>
                                    <option value="23">Rio Grande do Sul</option>
                                    <option value="24">Santa Catarina</option>
                                    <option value="25">Sergipe</option>
                                    <option value="26">São Paulo</option>
                                    <option value="27">Tocantins</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="IdCliente" className="form-control" onChange={handleChange} value={filter.IdCliente}>
                                    <option value="">Todos</option>
                                    <option endpoint="siga/serviceOrder/client" valuefield="clientCode" textfield="name" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Site</label>
                                <Select id="IdSite" className="form-control" onChange={handleChange} value={filter.IdSite} refresh={filter.IdCliente}>
                                    <option value="">Todos</option>
                                    <option endpoint={filter.IdCliente!==null?`siga/serviceOrder/${filter.IdCliente}/site`:''} valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Prestador</label>
                                <Select id="IdPrestador" className="form-control" onChange={handleChange} value={filter.IdPrestador}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=Prestador" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Responsável Obra</label>
                                <Select id="IdResponsavelObra" className="form-control" onChange={handleChange} value={filter.IdResponsavelObra}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=Tecnico" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Responsável Venda</label>
                                <Select id="IdConsultorComercial" className="form-control" onChange={handleChange} value={filter.IdConsultorComercial}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"DataAberturaCVInicio"} col={12} showHora={false} labelData={"Abertura Comunicação Venda"} valueData={filter.DataAberturaCVInicio} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"DataAberturaCVFim"} col={12} showHora={false} labelData={"Abertura Comunicação Venda Até"} valueData={filter.DataAberturaCVFim} onSelectValue={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Relatório Acompanhamento Obra</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => fileHelper.exportToCSV(data, 'RelatorioAcompanhamentoObra_' + moment().format("DD/MM/YYYY"))}><i class="fa fa-file-excel-o"></i></button>
                                </div>
                            </div>
                            <div className="box-body table-responsive no-padding">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'obra')}><i className="fa fa-sort" /> Obra Implantação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'numeroComunicacaoVenda')}><i className="fa fa-sort" /> Comunicação Venda</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataAberturaCV')}><i className="fa fa-sort" /> Data CV</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'formaVenda')}><i className="fa fa-sort" /> Forma Venda</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'numeroOS')}><i className="fa fa-sort" /> OS Implantação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'usuarioVendedor')}><i className="fa fa-sort" /> Responsável Venda</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'usuarioResponsavel')}><i className="fa fa-sort" /> Responsável Obra</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'estado')}><i className="fa fa-sort" /> UF</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'nomeCliente')}><i className="fa fa-sort" /> Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'nomeSite')}><i className="fa fa-sort" />Site</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'cameras')}><i className="fa fa-sort" /> Quantidade Câmeras</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'sensores')}><i className="fa fa-sort" /> Quantidade Sensores</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'modulosVIU')}><i className="fa fa-sort" /> Quantidade Módulo VIU</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'modulos')}><i className="fa fa-sort" /> Quantidade Outros Módulos</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'centralAlarme')}><i className="fa fa-sort" /> Quantidade Central Alarme</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataPrevistaAquisicaoMateriais')}><i className="fa fa-sort" /> Data Prevista Aquisição Material</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataRealAquisicaoMateriais')}><i className="fa fa-sort" /> Data Real Aquisição Material</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataPrevistaDisponibilizacaoMateriais')}><i className="fa fa-sort" /> Data Prevista Disponibilização Material</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataRealDisponibilizacaoMateriais')}><i className="fa fa-sort" /> Data Real Disponibilização Material</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataPrevistaContratacaoPrestador')}><i className="fa fa-sort" /> Data Prevista Contratação Prestador</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataRealContratacaoPrestador')}><i className="fa fa-sort" /> Data Real Contratação Prestador</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'nomePrestador')}><i className="fa fa-sort" /> Prestador</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataPrevistaInicioInstalacao')}><i className="fa fa-sort" /> Data Prevista Início Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataRealInicioInstalacao')}><i className="fa fa-sort" /> Data Real Início Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataPrevistaTerminoInstalacao')}><i className="fa fa-sort" /> Data Prevista Término Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataRealTerminoInstalacao')}><i className="fa fa-sort" /> Data Real Término Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataPrevistaEntregaObra')}><i className="fa fa-sort" /> Data Prevista Entrega Obra</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataRealEntregaObra')}><i className="fa fa-sort" /> Data Real Entrega Obra</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataInicioPendencia')}><i className="fa fa-sort" /> Início Pendência Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataTerminoPendencia')}><i className="fa fa-sort" /> Término Pendência Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'diasCorridos')}><i className="fa fa-sort" /> Dias corridos Até o Momento</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'ultimoStatus')}><i className="fa fa-sort" /> Status</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'ultimaInteracao')}><i className="fa fa-sort" /> Última Interação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><Link to="#" onClick={(e) => { window.open('http://siga.veotex.com.br/Forms/AcompanhamentoObra_Form.aspx?id=' + item.obra.replace("OBRA", "")); }}>{item.obra}</Link></td>
                                                        <td>{item.numeroComunicacaoVenda}</td>
                                                        <td>{config.dateFix(item.dataAberturaCV)}</td>
                                                        <td>{item.formaVenda}</td>
                                                        <td>{item.numeroOS}</td>
                                                        <td>{item.usuarioVendedor}</td>
                                                        <td>{item.usuarioResponsavel}</td>
                                                        <td>{item.estado}</td>
                                                        <td>{item.nomeCliente}</td>
                                                        <td>{item.nomeSite}</td>
                                                        <td>{item.cameras}</td>
                                                        <td>{item.sensores}</td>
                                                        <td>{item.modulosVIU}</td>
                                                        <td>{item.modulos}</td>
                                                        <td>{item.centralAlarme}</td>
                                                        <td>{config.dateFix(item.dataPrevistaAquisicaoMateriais)}</td>
                                                        <td>{config.dateFix(item.dataRealAquisicaoMateriais)}</td>
                                                        <td>{config.dateFix(item.dataPrevistaDisponibilizacaoMateriais)}</td>
                                                        <td>{config.dateFix(item.dataRealDisponibilizacaoMateriais)}</td>
                                                        <td>{config.dateFix(item.dataPrevistaContratacaoPrestador)}</td>
                                                        <td>{config.dateFix(item.dataRealContratacaoPrestador)}</td>
                                                        <td>{item.nomePrestador}</td>
                                                        <td>{config.dateFix(item.dataPrevistaInicioInstalacao)}</td>
                                                        <td>{config.dateFix(item.dataRealInicioInstalacao)}</td>
                                                        <td>{config.dateFix(item.dataPrevistaTerminoInstalacao)}</td>
                                                        <td>{config.dateFix(item.dataRealTerminoInstalacao)}</td>
                                                        <td>{config.dateFix(item.dataPrevistaEntregaObra)}</td>
                                                        <td>{config.dateFix(item.dataRealEntregaObra)}</td>
                                                        <td>{config.dateFix(item.dataInicioPendencia)}</td>
                                                        <td>{config.dateFix(item.dataTerminoPendencia)}</td>
                                                        <td>{item.diasCorridos}</td>
                                                        <td>{item.ultimoStatus}</td>
                                                        <td>{item.ultimaInteracao}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
