import React, { useState } from 'react';
import { Link, config, apiHelper, fileHelper, Select, toast } from '../../index';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function OrdemServicoGeral(props) {
    const endPoint= 'v2/os'
    const [data, setData] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);

    const [filter, setFilter] = useState({
        status: '',
        clienteSearch: '',
        dataInicioAberturaOS: '',
        dataFimAberturaOS: '',
        dataInicioConclusaoOS: '',
        dataFimConclusaoOS: '',
        regional: '',
        gerente: '',
        groupCode: 0,
        subGroupClientCode: '',
        searchValueCliente: ''
    });

    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }

        setShowLoading(true);

        apiHelper.post(`v2/os/report`, filter).then(res => {
            if (res.data.code === 200) {
                setData(res.data.data);
                let dataExcel = [];
                res.data.data.map((item, i) => {
                    let obj = {
                        "Os": item.pkOs,
                        "Número Externo": item.numeroExterno,
                        "Titulo": item.titulo,
                        "Cliente": item.nomeCliente,
                        "Site": item.nomeSite,
                        "Municipio Site": item.nomeMunicipio,
                        "Estado": item.estado,
                        "Tipo do Site": item.tipoSite,
                        "Semana": item.semana,
                        "Responsavel pela Manutenção": item.responsavelManutencao,
                        "Gerente": item.gerente,
                        "Estado do SLA": item.estadoSLA,
                        "SLA": item.sla,
                        "Data Abertura": item.dataAbertura,
                        "Tecnico": item.nomeTecnico,
                        "Quantidade de Visitas Tecnicas": item.qtdVisitasTecnicas,
                        "Data Despacho Tecnico": item.dataDespachoTecnico,
                        "Responsavel Despacho Tecnico": item.responsavelDespachoTecnico,
                        "Status": item.status,
                        "Classificação Cliente Grupo": item.classificacaoClienteGrupo,
                        "Classificação Cliente SubGrupo": item.classificacaoClienteSubGrupo,
                        "Classificação HD Grupo": item.classificacaoHDGrupo,
                        "Classificação HD SubGrupo": item.classificacaoHDSubGrupo,
                        "Classificação Conclusão Grupo": item.classificacaoConclusaoGrupo,
                        "Classificação Conclusão SubGrupo": item.classificacaoConclusaoSubGrupoGrupo,
                        "Manutenção Executada": item.manutencaoExecutada,
                        "Valor OS": item.valorConclusao,
                        "Data Conclusão": item.dataConclusao,
                        "Data Finalização": item.dataFinalizacao,
                        "Numero de Reaberturas": item.qtdReabertura,
                        "OS Reparo": item.reparo,
                        "Tempo Decorrido": item.tempoDecorrido,
                        "Tempo em Pendência com Cliente": item.tempoPendenciaCliente,
                        "Horas x Câmeras com Problema": item.horasPorCameraProblematica,
                        "Reparos Dentro do Prazo": item.reparosNoPrazo,
                        "Intervalo entre Manutenções no Site": item.intervaloManutencoes,
                        "Intervalo entre Manutenções Repetidas": item.intervaloManutencoesRepetidas,
                        "Descrição das Interações": item.descricao,
                    }
                    dataExcel.push(obj);

                    return null;
                });

                setDataExcel(dataExcel.sort((a, b) => a.pkOs > b.pkOs ? 1 : -1));
            }
            else if (res.code === 400) {
                toast.warn(res.data.data);
            }
            else {
                toast.error('Atenção, não foi possível recuperar os registros.');
            }

            setShowLoading(false);
        }).catch(error => {
            toast.error('Atenção, não foi possível recuperar os registros.');
            setShowLoading(false);
        });

    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            status: '',
            clienteSearch: '',
            searchValuePrestador: '',
            dataInicioAberturaOS: '',
            dataFimAberturaOS: '',
            dataInicioConclusaoOS: '',
            dataFimConclusaoOS: '',
            searchValueTecnico: '',
            regional: '',
            gerente: '',
            searchStatus: '',
            groupCode: 0,
            subGroupClientCode: '',
            searchValueCliente: '',
            siteSearch: '',
            usuarioConcluiuOs: '',
            codeCliente: ''
        });
        setData([]);
        setDataExcel([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10px' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                    <div className="btn-group" style={{ marginBottom: 5 }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                            Pesquisar <span className="caret" />
                        </button>
                        <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                            <div className="box" style={{ margin: 0 }}>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>Status</label>
                                            <Select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status}>
                                                <option value="1,2,3,4">Todos</option>
                                                <option value="1,2,3">Abertas</option>
                                                <option value="1">Nova</option>
                                                <option value="2">Em Andamento</option>
                                                <option value="3">Pendência do Cliente</option>
                                                <option value="4">Concluida</option>
                                                <option value="5">Finalizada</option>
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Regional</label>
                                            <Select id="regional" className="form-control input-sm" onChange={handleChange} value={filter.regional}>
                                                <option value="">Todos</option>
                                                <option endpoint={`portal/crud/Regional`} valuefield="pkRegional" textfield="nome" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Gerente</label>
                                            <Select id="gerente" className="form-control input-sm" onChange={handleChange} value={filter.gerente}>
                                                <option value="">Todos</option>
                                                <option endpoint={`${endPoint}/gerente`} valuefield="id" textfield="nome" />
                                            </Select>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Cliente</label>
                                            <Select id="searchValueCliente" className="form-control input-sm" onChange={handleChange} value={filter.searchValueCliente}>
                                                <option value="">Todos</option>
                                                <option endpoint="siga/serviceOrder/client/" valuefield="clientCode" textfield="name" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Site</label>
                                            <Select id="siteSearch" className="form-control input-sm" onChange={handleChange} value={filter.siteSearch} refresh={filter.searchValueCliente}>
                                                <option value="">Todos</option>
                                                <option endpoint={filter.searchValueCliente !== '' ? `siga/serviceOrder/${filter.searchValueCliente}/site` : ''} valuefield="id" textfield="nome" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Grupo</label>
                                            <Select id="groupCode" className="form-control input-sm" onChange={handleChange} value={filter.groupCode}>
                                                <option value="">Todos</option>
                                                <option endpoint="portal/crud/Grupo?filter=isExcluido::equal::0;;" valuefield="pkGrupo" textfield="nome" />
                                            </Select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Sub Grupo</label>
                                            <Select id="subGroupClientCode" className="form-control input-sm" onChange={handleChange} value={filter.subGroupClientCode} refresh={filter.groupCode}>
                                                <option key="" value="">Todos</option>
                                                <option endpoint={`portal/crud/SubGrupo?filter=fkGrupo::equal::${filter.groupCode}`} valuefield="pkSubGrupo" textfield="nome" />
                                            </Select>
                                        </div>

                                        <div className="form-group col-sm-6">
                                            <label>Início Abertura</label>
                                            <input id="dataInicioAberturaOS" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataInicioAberturaOS} />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Fim Abertura</label>
                                            <input id="dataFimAberturaOS" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataFimAberturaOS} />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Início Conclusão</label>
                                            <input id="dataInicioConclusaoOS" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataInicioConclusaoOS} />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Fim Conclusão</label>
                                            <input id="dataFimConclusaoOS" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataFimConclusaoOS} />
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer" style={{ padding: 5 }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat pull-right" onClick={handleRefresh}>Limpar</button>
                                    <button type="button" className="btn btn-primary btn-sm btn-flat pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                </div>
                            </div>
                        </div>

                        <button type="button" className="btn btn-sm" onClick={() => fileHelper.exportToCSV(dataExcel, 'RelatorioOS_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
                    </div>

                    <div className="pull-right">
                        {renderPage()}
                    </div>

                    <div className="box box-solid">
                        <div className="box-body table-responsive no-padding">
                            {dataExcel.length > 0 ?
                                <table className="table table-striped table-bordered" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue">
                                            {(dataExcel && dataExcel[0]) && Object.keys(dataExcel[0]).map((key, i) =>
                                                <th key={i}>{key.toUpperCase()}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataExcel && dataExcel.slice(params.initRow, params.endRow)
                                            .map(((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        {Object.keys(dataExcel[0]).map((key, j) =>
                                                            <td key={j}>{item[key]}</td>
                                                        )}
                                                    </tr>
                                                )
                                            }))
                                        }
                                    </tbody>
                                </table>
                                :
                                <div className="alert alert-info alert-dismissible">
                                    <center>Nenhum registro para exibir!</center>
                                </div>
                            }
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        </div>
    )
}
