import React, { useEffect, useState } from 'react';
import { config, apiHelper } from '../index';

export function HistoricoVagaEmprego(props) {
    const endPoint = 'portal/crud/uvw_v2_HistoricoVagasEmprego';
    const parentId = props.parentId;
    const [data, setData] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=pkVagaEmprego::equal::${parentId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [parentId]);

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Nome do Candidato</th>
                            <th>Data Abertura</th>
                            <th>Aprovador da Vaga</th>
                            <th>Aprovador do Candidato</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.nomeCandidato}</td>
                                        <td>{config.dateFix(item.dataAbertura)}</td>
                                        <td>{item.aprovadorVaga}</td>
                                        <td>{item.aprovadorCandidato}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
