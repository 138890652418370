import React, { useState, useEffect } from 'react';
import { apiHelper, config } from './../../index';

export function ManutencaoPreventivaTecnicos(props) {
    const endPoint = 'atendimentoReport/manutencaoPrevTecnicos';
    const [data, setData] = useState([]);
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Manutenções Preventivas (Técnicos)</h3>
            </div>
            <div className="panel-body">
                {data && data.length > 0 && <div className="row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin">
                                <thead>
                                    <tr className="bg-light-blue color-palette">
                                        <th>Site</th>
                                        <th>Previsto</th>
                                        <th>Realizado</th>
                                        <th>Técnico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.sort((a, b) => a.site < b.site ? 1 : -1)
                                        .map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.site}</td>
                                                    <td>{config.dateFix(item.previsto)}</td>
                                                    <td>{config.dateFix(item.realizado)}</td>
                                                    <td>{item.tecnico}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
