import React, { useEffect, useState } from 'react';
import { config, apiHelper } from '../index';

export function GuiaResumo(props) {
    const endPoint = "v2/projeto/";
    const projetoId = props.parentId;
    const [data, setData] = useState([]);
    const [custototal, setCustoTotal] = useState([]);;
    const [custoRealizado, setcustoRealizado] = useState([]);;
    const fetchData = () => {
        apiHelper.get(`${endPoint}${props.parentId}/extrato`)
            .then(res => {
                setData(res.data.data);
                setCustoTotal(res.data.data.custoInstalacao + res.data.data.custoManutencao + res.data.data.hardware + res.data.data.software + res.data.data.frete);
                setcustoRealizado(res.data.data.custoInstalacaoRealizado + res.data.data.hardwareRealizado + res.data.data.softwareRealizado + res.data.data.freteRealizado);
            });
    };

    useEffect(fetchData, [projetoId, props.refresh]);

    return (
        <div className="row">
            <div className="col-sm-3 col-xs-6">
                <div className="description-block border-right">
                    <span className="description-percentage text-green"><i className="fa fa-caret-up" /> {Math.round(((custototal - custoRealizado) * 100) / custototal)}%</span>
                    <h5 className="description-header">{config.currencyFix(custototal - custoRealizado)}</h5>
                    <span className="description-text">CUSTOS</span>
                </div>
            </div>
            <div className="col-sm-3 col-xs-6">
                <div className="description-block border-right">
                    <span className="description-percentage text-red"><i className="fa fa-caret-down" />  {Math.round(((custoRealizado) * 100) / (custototal))}%</span>
                    <h5 className="description-header">{config.currencyFix(custoRealizado)}</h5>
                    <span className="description-text">GASTO</span>
                </div>
            </div>
            <div className="col-sm-3 col-xs-6">
                <div className="description-block border-right">
                    <span className="description-percentage text-green"><i className="fa fa-caret-up" /> {Math.round(((data.totalAFaturar - data.totalFaturado) * 100) / data.totalAFaturar)}%</span>
                    <h5 className="description-header">{config.currencyFix(data.totalAFaturar - data.totalFaturado)}</h5>
                    <span className="description-text">A FATURAR</span>
                </div>
            </div>
            <div className="col-sm-3 col-xs-6">
                <div className="description-block">
                    <span className="description-percentage text-red"><i className="fa fa-caret-down" /> {Math.round((data.totalFaturado * 100) / (data.totalAFaturar))}%</span>
                    <h5 className="description-header">{config.currencyFix(data.totalFaturado)}</h5>
                    <span className="description-text">FATURADO</span>
                </div>
            </div>
        </div>
    );
}
