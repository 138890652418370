import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Link } from '../index';

export function Historico(props) {
    const endPoint = 'v3/os/'; //'v2/os/';
    const parentId = props.parentId;    
    const siteId = props.siteId;    
    const [data, setData] = useState([]);
    
    const fetchData = () => {        
        try {
            apiHelper.get(`${endPoint}?filter=siteId::equal::${siteId}&initRow=${1}&endRow=${100}`)
                .then(res => {
                    setData(res.data);
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    };

    useEffect(fetchData, [parentId]);

    return (
        <React.Fragment>          
            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered" >
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Id</th>
                            <th>Título</th>
                            <th>Cliente</th>
                            <th>Site</th>
                            <th>Status</th>                                                        
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, i) => {
                                return (
                                    <tr key={i}>                                                                                
                                        <td><Link to={`/atendimento/${item.id}`}>{item.id}</Link></td>
                                        <td>{item.resumo}</td>
                                        <td>{item.cliente}</td>                                        
                                        <td>{item.site}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>          
        </React.Fragment>
    );
}