import React, { useState, useEffect } from 'react';
import { Link, apiHelper, toast } from '../../index';

export function BuscarUsuario(props) {
    const endPoint = 'portal/crud/Usuario';
    const endPointUserGroup = 'portal/crud/IdentityUserGroup';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        pkUsuario: '',
        nome: '',
        ativo: ''
    });

    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, [parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nome')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleAdicionar = (e) => {
        let data = {
            userId: e.pkUsuario,
            groupId: parentId
        };

        apiHelper.post(`${endPointUserGroup}`, data)
            .then(res => {
                if (res.data.code === 200) {
                    props.onHide(true);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    }

    const handleRefresh = () => {
        setFilter({
            pkUsuario: '',
            nome: '',
            ativo: ''
        });

        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                        <thead>
                            <tr className="bg-light-blue color-palette">
                                <th style={{ width: "50px" }}>#</th>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Status</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                <td><input id="pkUsuario" type="text" className="form-control input-sm" onChange={handleChange} value={filter.pkUsuario} /></td>
                                <td><input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} /></td>
                                <td>
                                    <select id="ativo" className="form-control input-sm" onChange={handleChange} value={filter.ativo}>
                                        <option value="">Todos</option>
                                        <option value="true">Ativo</option>
                                        <option value="false">Inativo</option>

                                    </select>
                                </td>
                            </tr>
                            {data && data.sort((a, b) => a.pkUsuario < b.pkUsuario ? 1 : -1)
                                .slice(params.initRow, params.endRow)
                                .map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: '50px' }}>#</td>
                                            <td>{item.pkUsuario}</td>
                                            <td>{item.nome}</td>
                                            <td style={{ width: "100px" }}>{item.ativo ? 'Ativo' : 'Inativo'}</td>
                                            <td><Link to="#" onClick={() => handleAdicionar(item)}>Adicionar</Link></td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
                <div style={{ marginTop: "7px" }}>
                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                    <div className="pull-right">
                        {renderPage()}
                    </div>
                </div>
            </div>
        </div>
    )
}
