import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../index';

export function AgendamentoForm(props) {
    const endPoint = 'portal/crud/Agendamento';
    const rowId = props.rowId;
    const [data, setData] = useState({});

    let usuarioLogadoId = localStorage.getItem('claims') !== null
        && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}/id`).then(res => {
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);

                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o agendamento');
            });
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var obj = data;
        obj.usuarioId = usuarioLogadoId;

        if (rowId === 0) {
            obj.guid = "";

            apiHelper.post(`${endPoint}`, obj).then(res => {
                if (res.data.code === 200)
                    props.onHide(res.data.data);
                else
                    toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/Id`, obj).then(res => {
                if (res.data.code === 200)
                    props.onHide();
                else
                    toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ""} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="500" onChange={handleChange} value={data.descricao || ""} required />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Cancelar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Salvar</button>
                </div>
            </div>
        </form>
    )
}
