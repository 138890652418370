import React, { useState, useEffect, Fragment } from 'react';
import { config, apiHelper, toast, Select, Checkbox } from '../index';
import CreatableSelect from 'react-select/creatable/dist/react-select.esm';
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from 'react-select';

export function LeadForm(props) {
    const { handleSubmit, control } = useForm();
    const endPointLead = "lead/";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [empresa, setEmpresa] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [grupoEmpresarial, setGrupoEmpresarial] = useState({});
    const [cidades, setCidades] = useState({});
    const [defaultCidade, setDefaultCidade] = useState('');
    let usuarioLogadoId = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const fetchData = () => {
        let obj = {};
        let objEmpresa = [];
        let objGrupoEmpresarial = [];
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPointLead}${rowId}`).then(res => {
                let data = res.data;
                data.dataEntrada = `${data.dataEntrada.split('-')[0]}-${data.dataEntrada.split('-')[1]}-${data.dataEntrada.split('-')[2].substr(0, 2)}`;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);

                setData(obj);
                fetchCidades(data.uf);
                setDefaultCidade({ value: data.cidade, label: data.cidade });
            }).catch(error => {
                toast.error('Houve um erro ao carregar o LEAD');
            });
        }

        apiHelper.get(`${endPointLead}/empresa`).then(res => {
            res.data.data.map((item) =>
                objEmpresa.push({ value: item.empresa, label: item.empresa })
            );
            setEmpresa(objEmpresa);
        }).catch(error => {
            toast.error('Houve um erro ao carregar as empresas.');
        });

        apiHelper.get(`${endPointLead}/grupoEmpresarial`).then(res => {
            res.data.data.map((item) =>
                objGrupoEmpresarial.push({ value: item.grupoEmpresarial, label: item.grupoEmpresarial })
            );
            setGrupoEmpresarial(objGrupoEmpresarial);
        }).catch(error => {
            toast.error('Houve um erro ao carregar as empresas.');
        });

    }

    const buscaCEP = () => {

        if (!data.cep) {
            toast.warn("Informe um CEP.");
            return;
        }
        apiHelper.get(`cep/${data.cep.replace('-', '').trim()}`).then(resp => {
            if (resp.data) {
                setData({ ...data, 'endereco': resp.data.endereco + ', ' + resp.data.bairro, 'uf': resp.data.uf, 'cidade': resp.data.cidade });
                setDisabled(true);
            }
            else {
                setData({ ...data, 'endereco': '', 'uf': '', 'cidade': '' });
                setDisabled(false);
                toast.warn('CEP digitado não encontrado.');
            }
        }).catch(error => {
            toast.error('Houve um erro ao buscar CEP.');
        });
    }

    const fetchCidades = (uf) => {
        let cidades = [];
        apiHelper.get(`portal/crud/uvw_cidade?filter=SIGLA::equal::${uf}`).then(resp => {
            resp.data.data.map((item) =>
                cidades.push({ value: item.nome, label: item.nome })
            );
            setCidades(cidades);
        }).catch(error => {
            toast.error('Houve um erro ao carregar as cidades.');
        });
    }

    useEffect(fetchData, [rowId], cidades);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        if (name === "uf") {
            setDefaultCidade(null);
            fetchCidades(value);
        }

        if (e.target.maxLength > 0 && value.length > e.target.maxLength) {
            return;
        }

        setData({ ...data, [name]: value });
    }

    const onSubmit = (e) => {

        if (rowId === 0) {

            data.status = "Aguardando Interação";
            data.criadorId = usuarioLogadoId;
            apiHelper.post(endPointLead, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res);
                        props.onHide(true);
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            let form = data;
            delete form.id;
            apiHelper.put(`${endPointLead}/${rowId}`, form)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res);
                        props.onHide(true);
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleChangeCidade = (e) => {
        setData({ ...data, cidade: e.value });
        setDefaultCidade(e);
    }

    const handleChangeInputEmpresa = (e) => {
        setData({ ...data, empresa: config.removeSpacesAndToUpper(e) });
    }

    const handleBlurEmpresa = (e) => {
        if (e.target.value !== "")
            setData({ ...data, empresa: config.removeSpacesAndToUpper(e.target.value) })
    }

    const handleChangeEmpresa = (e) => {
        if (e)
            setData({ ...data, empresa: e.value ? config.removeSpacesAndToUpper(e.value) : config.removeSpacesAndToUpper(e) });
        else
            setData({ ...data, empresa: "" });
    }

    const handleChangeInputGrupoEmpresa = (e) => {
        setData({ ...data, grupoEmpresarial: config.removeSpacesAndToUpper(e) });
    }

    const handleBlurGrupoEmpresa = (e) => {
        if (e.target.value !== "")
            setData({ ...data, grupoEmpresarial: config.removeSpacesAndToUpper(e.target.value) })
    }

    const handleChangeGrupoEmpresa = (e) => {
        if (e)
            setData({ ...data, grupoEmpresarial: e.value ? config.removeSpacesAndToUpper(e.value) : config.removeSpacesAndToUpper(e) });
        else
            setData({ ...data, grupoEmpresarial: "" });
    }

    const handleBlurNomeContato = (e) => {
        if (e.target.value !== "")
            setData({ ...data, nomeContato: config.removeSpacesAndToUpperFirstLetter(e.target.value) })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/*Geral*/}
            <div className="row">
                {(data.status !== '' && data.status !== undefined) &&
                    <div className="form-group col-sm-12">
                        <label>Status {data.status}</label>
                    </div>}
                <div className="form-group col-sm-6">
                    <label>Origem *</label>
                    <Select id="origem" className="form-control" onChange={handleChange} value={data.origem || ""} required>
                        <option value="">Selecione</option>
                        <option value="PAP">PAP</option>
                        <option value="FTI">FTI</option>
                        <option value="MKT">MKT</option>
                        <option value="TPerson">TPerson</option>
                        <option value="Outros">Outros</option>
                    </Select>
                </div>
                {data.origem === 'FTI' &&
                    <div className="form-group col-sm-6">
                        <label>Informação Adicional FTI</label>
                        <Select id="origemInformacaoAdicional" className="form-control" onChange={handleChange} value={data.origemInformacaoAdicional || ""}>
                            <option value="">Selecione</option>
                            <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::LeadOrigemInfoAdicionalFTI" valuefield="valor" textfield="nome" />
                        </Select>
                    </div>}
                {data.origem === 'MKT' &&
                    <div className="form-group col-sm-6">
                        <label>Informação Adicional MKT</label>
                        <Select id="origemInformacaoAdicional" className="form-control" onChange={handleChange} value={data.origemInformacaoAdicional || ""}>
                            <option value="">Selecione</option>
                            <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::LeadOrigemInfoAdicionalMKT" valuefield="valor" textfield="nome" />
                        </Select>
                    </div>}
                {data.origem === 'Outros' &&
                    <div className="form-group col-sm-6">
                        <label>Informação Adicional Outros</label>
                        <Select id="origemInformacaoAdicional" className="form-control" onChange={handleChange} value={data.origemInformacaoAdicional || ""}>
                            <option value="">Selecione</option>
                            <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::LeadOrigemInfoAdicionalOutros" valuefield="valor" textfield="nome" />
                        </Select>
                    </div>}
                <div className="form-group col-sm-6">
                    <label>Data de Entrada *</label>
                    <input id="dataEntrada" type="date" className="form-control" onChange={handleChange} value={data.dataEntrada || ""} placeholder="dd/mm/yyyy" onKeyDown={(e) => e.preventDefault()} required />
                </div>

                <div className="form-group col-sm-6">
                    <label>Analista Veolink </label>
                    <Select id="consultorId" className="form-control" onChange={handleChange} value={data.consultorId || ""} >
                        <option value="">Selecione</option>
                        <option endpoint="comercial/pessoa/perfil?perfil=AnalistaVeolink" valuefield="id" textfield="nome" />
                    </Select>
                </div>

                <div className="form-group col-sm-6">
                    <label>Grupo Empresarial *</label>
                    <Fragment>
                        <CreatableSelect
                            isClearable={true}
                            onChange={handleChangeGrupoEmpresa}
                            onCreateOption={handleChangeInputGrupoEmpresa}
                            onBlur={handleBlurGrupoEmpresa}
                            options={grupoEmpresarial}
                            id="grupoEmpresarial"
                            placeholder=""
                            value={{ value: data.grupoEmpresarial || "", label: data.grupoEmpresarial || "" }}
                        />
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, height: 0 }}
                            value={data.grupoEmpresarial || ""}
                            required readOnly
                        />
                    </Fragment>
                </div>
            </div>

            {/*Dados Empresa*/}
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Segmento *</label>
                    <Select id="segmento" className="form-control" onChange={handleChange} value={data.segmento || ""} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::LeadSegmentos" valuefield="valor" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Empresa *</label>
                    <Fragment>
                        <CreatableSelect
                            isClearable={true}
                            onChange={handleChangeEmpresa}
                            onCreateOption={handleChangeInputEmpresa}
                            onBlur={handleBlurEmpresa}
                            options={empresa}
                            id="empresa"
                            placeholder=""
                            value={{ value: data.empresa || "", label: data.empresa || "" }}
                        />
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, height: 0 }}
                            value={data.empresa || ""}
                            required readOnly
                        />
                    </Fragment>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-4">
                    <label>Cep {data.isDecisor || data.existeDecisor ? "*" : ""}</label>
                    <div className="input-group" >
                        <Controller
                            name="cep"
                            control={control}
                            render={({ field }) => (
                                <InputMask id="cep" name="cep" pattern="\d{5}-\d{3}"
                                    mask="99999-999" required={data.isDecisor || data.existeDecisor}
                                    className="form-control" onChange={handleChange}
                                    value={data.cep || ""} placeholder="xxxxx-xxx" />
                            )}
                        />
                        <span className="input-group-addon" style={{ cursor: 'pointer' }} title="Buscar CEP" onClick={buscaCEP}><i className="fa fa-search"></i></span>
                    </div>
                </div>
                <div className="form-group col-sm-8">
                    <label>Endereço {data.isDecisor || data.existeDecisor ? "*" : ""}</label>
                    <input id="endereco" type="text" className="form-control" onChange={handleChange} value={data.endereco || ""} required={data.isDecisor || data.existeDecisor} disabled={disabled} />
                </div>
            </div>
            <div className="row">

                <div className="form-group col-sm-4">
                    <label>Complemento </label>
                    <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento || ""} />
                </div>
                <div className="form-group col-sm-2">
                    <label>Número {data.isDecisor || data.existeDecisor ? "*" : ""}</label>
                    <input id="numero" type="text" className="form-control" onChange={handleChange} value={data.numero || ""} required={data.isDecisor || data.existeDecisor} />
                </div>
                <div className="form-group col-sm-6">
                    <label>UF {data.isDecisor || data.existeDecisor ? "*" : ""}</label>
                    <Select id="uf" className="form-control" onChange={handleChange} value={data.uf} required={data.isDecisor || data.existeDecisor} disabled={disabled}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Cidade {data.isDecisor || data.existeDecisor ? "*" : ""}</label>
                    {disabled ? <input id="cidade" type="text" className="form-control" onChange={handleChange} value={data.cidade || ""} required={data.isDecisor || data.existeDecisor} disabled={disabled} />
                        : <Fragment>
                            <ReactSelect
                                onChange={handleChangeCidade}
                                options={cidades}
                                id="cidade"
                                value={defaultCidade}
                                placeholder="Selecione"
                            />
                            <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                value={data.cidade || ""}
                                required={data.isDecisor || data.existeDecisor}
                            />
                        </Fragment>}
                </div>
            </div>
            {/*Dados Contato*/}
            {(!rowId || rowId === 0) &&
                <React.Fragment>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <label>Nome Contato *</label>
                            <input id="nomeContato" type="text" className="form-control" onChange={handleChange} value={data.nomeContato || ""} onBlur={handleBlurNomeContato} required />
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Decisor</label>
                            <Checkbox id="isDecisor" name="isDecisor" onChange={handleChange} checked={data.isDecisor ? data.isDecisor : false}>É Decisor</Checkbox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <label>Cargo {data.isDecisor ? "*" : ""}</label>
                            <input id="cargo" type="text" className="form-control" onChange={handleChange} value={data.cargo || ""} required={data.isDecisor} />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Departamento</label>
                            <input id="departamento" type="text" className="form-control" onChange={handleChange} value={data.departamento || ""} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-4">
                            <label>Tipo Contato</label>
                            <Select id="tipoContato" className="form-control" onChange={handleChange} value={data.tipoContato || ""}>
                                <option value="">Selecione</option>
                                <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::LeadTipoContato" valuefield="valor" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Telefone Fixo {(data.celular === undefined || data.celular === '') ? '*' : ''}</label>
                            <Controller
                                name="telefone"
                                control={control}
                                render={({ field }) => (
                                    <InputMask id="telefone" name="telefone" pattern="\(\d{2}\)\d{4}-\d{4}"
                                        mask="(99)9999-9999" required={(data.celular === undefined || data.celular === '')}
                                        className="form-control" onChange={handleChange}
                                        value={data.telefone || ""} placeholder="(xx)xxxx-xxxx" />
                                )}
                            />
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Telefone Celular {(data.telefone === undefined || data.telefone === '') ? '*' : ''}</label>
                            <Controller
                                name="celular"
                                control={control}
                                render={({ field }) => (
                                    <InputMask id="celular" name="celular" pattern="\(\d{2}\)\d{5}-\d{4}"
                                        mask="(99)99999-9999"
                                        className="form-control" onChange={handleChange}
                                        value={data.celular || ""}
                                        placeholder="(xx)xxxxx-xxxx"
                                        required={(data.telefone === undefined || data.telefone === '')} />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <label>E-mail *</label>
                            <input id="email" type="email" className="form-control" onChange={handleChange} value={data.email || ""} required={true} />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Linkedin</label>
                            <input id="linkedin" type="text" className="form-control" onChange={handleChange} value={data.linkedin || ""} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label>Observação</label>
                            <textarea id="contatoObservacao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.contatoObservacao} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <strong> * campos obrigatorios</strong>
                        </div>
                    </div>

                </React.Fragment>
            }

            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
