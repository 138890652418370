import axios from 'axios';
import { config } from './config';
import { apiHeader } from './api-header';

const API_URL = config.apiUrl();
const API_HEADER = apiHeader();

export const apiHelper = {
    getProvince,
    getBlob,
    download,
    get,
    getById,
    post,
    put,
    api_delete,
    deleteById,
    upload,
    toBase64,
    patch
};

async function getProvince() {
    return await get('portal/crud/SystemMetaData/?filter=type:StateProvince')
        .then(res => {
            return res.data
        });
};

function getBlob(endPoint) {
    return axios({
        url: API_URL + endPoint,
        method: 'GET',
        responseType: 'blob',
        headers: API_HEADER
    });
};

async function download(file) {
    await this.api_getBlob('portal/download/?type=activity&file=' + file).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
    });
};

function get(endPoint) {
    return axios.get(API_URL + endPoint.replace("//", "/"), { headers: API_HEADER });
};

function getById(endPoint, id) {
    return axios.get(API_URL + (endPoint.replace("//", "/") + '/' + id).replace("//", "/"), { headers: API_HEADER });
};

function post(endPoint, obj) {
    return axios.post(API_URL + endPoint.replace("//", "/"), obj, { headers: API_HEADER });
};

function put(endPoint, obj) {
    return axios.put(API_URL + endPoint.replace("//", "/"), obj, { headers: API_HEADER });
};

function patch(endPoint, obj) {
    return axios.patch(API_URL + endPoint.replace("//", "/"), obj, { headers: API_HEADER });
};

function api_delete(endPoint) {
    return axios.delete(API_URL + endPoint.replace("//", "/"), { headers: API_HEADER });
};

function deleteById(endPoint, id) {
    return axios.delete(API_URL + (endPoint.replace("//", "/") + '/' + id).replace("//", "/"), { headers: API_HEADER });
};

function upload(endPoint, file, folder, fileName) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folder);
    formData.append('fileName', fileName);

    return axios.post(API_URL + endPoint.replace("//", "/"), formData,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('id_token')}`,
                "Content-type": "multipart/form-data",
            },
        }
    )

};

function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
};
