import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Select, Modal, toast } from '../';
import { OrcamentoForm } from './OrcamentoForm';

export function Orcamento(props) {
    const permissaoEscrita = config.validarClaim('orcamento.escrita');
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        nomeCliente: '',
        nomeConsultor: '',
        status: '',
        dataCadastro: '',
        locacao: '',
        venda: '',
        recorrente: '',
        naoRecorrente: '',
        tipoId: ''
    });

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`orcamento?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            nomeCliente: '',
            nomeConsultor: '',
            status: '',
            dataCadastro: '',
            locacao: '',
            venda: '',
            recorrente: '',
            naoRecorrente: '',
            tipoId: ''
        });

        setData([]);
        fetchData();
    };

    const handleClone = (rowId) => {
        apiHelper.put(`orcamento/${rowId}/duplicar`)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success('Orçamento duplicado e carregado!');
                    props.history.push({
                        pathname: '/v2/orcamento/' + res.data.data
                    });
                }
                else if (res.data.code === 500)
                    toast.warn('Atenção, não foi possível duplicar este orçamento!');
                else
                    toast.error('Atenção, não foi possível salvar o registro.');
            }).catch(error => {
                toast.error('Atenção, não foi possível duplicar este orçamento!');
            });
    }

    const handleSearch = () => {
        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'nome')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataCadastro') {
                where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;AND;;`;
            }
            else
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Orçamento" browserTitle="Orçamento">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                {permissaoEscrita && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("orcamento", "Novo Orçamento", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Adicionar</button>}
                                <div className="pull-right">
                                    {renderPage()}
                                </div>

                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>Id</label>
                                                                <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                            </div>
                                                            <div className="form-group col-md-8">
                                                                <label>Nome</label>
                                                                <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Data Cadastro</label>
                                                                <input id="dataCadastro" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastro} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Cliente</label>
                                                                <Select id="nomeCliente" className="form-control input-sm" onChange={handleChange} value={filter.nomeCliente}>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint="siga/serviceOrder/client/" valuefield="name" textfield="name" />
                                                                </Select>
                                                            </div>
                                                            {!config.validarClaim('ConsultorComercial') &&
                                                                <div className="form-group col-md-4">
                                                                    <label>Consultor</label>
                                                                    <Select id="nomeConsultor" className="form-control input-sm" onChange={handleChange} value={filter.nomeConsultor}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="nome" textfield="nome" />
                                                                    </Select>
                                                                </div>}
                                                            <div className="form-group col-md-4">
                                                                <label>Tipo Orçamento</label>
                                                                <Select id="tipoId" className="form-control input-sm" onChange={handleChange} value={filter.tipoId}>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint={`portal/crud/TipoOrcamento`} valuefield="id" textfield="nome" />
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Status</label>
                                                                <Select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status}>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::Orcamento" valuefield="valor" textfield="nome" />
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Locação</label>
                                                                <input id="locacao" type="text" className="form-control input-sm" onChange={handleChange} value={filter.locacao} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Venda</label>
                                                                <input id="venda" type="text" className="form-control input-sm" onChange={handleChange} value={filter.venda} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Recorrente</label>
                                                                <input id="recorrente" type="text" className="form-control input-sm" onChange={handleChange} value={filter.recorrente} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Não Recorrente</label>
                                                                <input id="naoRecorrente" type="text" className="form-control input-sm" onChange={handleChange} value={filter.naoRecorrente} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin">
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            {permissaoEscrita && <th style={{ width: "50px" }}>#</th>}
                                            <th>ID</th>
                                            <th>Nome</th>
                                            <th>Data Cadastro</th>
                                            <th>Cliente</th>
                                            {!config.validarClaim('ConsultorComercial') && <th>Consultor</th>}
                                            <th>Tipo Orçamento</th>
                                            <th>Status</th>
                                            <th>Locação</th>
                                            <th>Venda</th>
                                            <th>Recorrente</th>
                                            <th>Não Recorrente</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        {permissaoEscrita && <td style={{ width: '50px' }}>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </button>
                                                                <ul className="dropdown-menu" role="menu">
                                                                    {item.status === 'Rascunho' && <li><Link replace to="#" onClick={() => handleShowForm("orcamento", "Editar Orçamento", item.id)}><i className="fa fa-pencil" /> Editar</Link></li>}
                                                                    <li><Link replace to="#" onClick={() => handleClone(item.id)}><i className="fa fa-copy" /> Duplicar</Link></li>
                                                                    <li><Link replace to={'/v2/orcamento/' + item.id}><i className="fa fa-eye" /> Visualizar</Link></li>
                                                                </ul>
                                                            </div>
                                                        </td>}
                                                        <td>{item.id}</td>
                                                        <td><Link to={'/v2/orcamento/' + item.id}>{item.nome}</Link></td>
                                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                                        <td>{item.nomeCliente}</td>
                                                        {!config.validarClaim('ConsultorComercial') && <td>{config.formatName(item.nomeConsultor)}</td>}
                                                        <td>{item.tipoOrcamento}</td>
                                                        <td>{config.formataLabelStatus(item.status)}</td>
                                                        <td>{config.currencyFix(item.locacao)}</td>
                                                        <td>{config.currencyFix(item.venda)}</td>
                                                        <td>{config.currencyFix(item.recorrente)}</td>
                                                        <td>{config.currencyFix(item.naoRecorrente)}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.showModal && <OrcamentoForm rowId={params.rowId} nomeCliente={params.rowId > 0 ? data.find(c => c.id === params.rowId).nomeCliente : ''} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
