import React, { useState, useEffect } from 'react';
import { apiHelper } from './../../index';

export function IncidenteSolicitacaoDentroFora(props) {
    const endPoint = 'atendimentoReport/incidenteSolicitacao';
    const [data, setData] = useState([]);
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {

            let data = [];
            let objIncidente = CalcularDentroForaSLA('Incidente', res.data.data.dataIncidentes);
            let objSolicitacao = CalcularDentroForaSLA('Solicitação', res.data.data.dataSolicitacoes);

            data.push(objIncidente);
            data.push(objSolicitacao);

            setData(data);
        });
    };

    const CalcularDentroForaSLA = (tipo, data) => {
        let dentroSLA = data.find(c => c.nome === 'Dentro').valor;
        let foraSLA = data.find(c => c.nome === 'Fora').valor;
        let total = dentroSLA + foraSLA;
        let percentDentroSLA = Math.round((dentroSLA * 100) / total);
        let percentForaSLA = Math.round((foraSLA * 100) / total);

        let obj = {
            tipo: tipo,
            dentro: dentroSLA,
            fora: foraSLA,
            percentDentro: percentDentroSLA,
            percentFora: percentForaSLA
        };

        return obj;
    }

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Incidente/Solicitação Dentro e Fora</h3>
            </div>
            <div className="panel-body">
                {data && data.length > 0 && <div className="row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin">
                                <thead>
                                    <tr className="bg-light-blue color-palette">
                                        <th>Tipo</th>
                                        <th>Dentro</th>
                                        <th>Fora</th>
                                        <th>% Dentro</th>
                                        <th>% Fora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.tipo}</td>
                                                <td>{item.dentro}</td>
                                                <td>{item.fora}</td>
                                                <td>{item.percentDentro + '%'}</td>
                                                <td>{item.percentFora + '%'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
