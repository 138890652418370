import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config } from '../';

let itensAlterados = [];
export function SolicitarCompras(props) {
    const endPoint = 'suprimentos/rm/';
    const parentId = props.parentId;
    const dataParent = props.data;
    const [data, setData] = useState([]);

    const fetchData = () => {
        let ids = [];
        ids.push(...props.setIds);
        apiHelper.get(`${endPoint}${parentId}/material/`)
            .then(res => {
                if (res.data.code === 200) {
                    setData([]);
                    let data = res.data.data;
                    let itensSelecionados = [];

                    ids.forEach((id) => {
                        let result = data.filter(c => c.id === id);
                        let obj = {
                            id: result[0].id,
                            codigoProtheus: result[0].codigoProtheus,
                            nome: result[0].nome,
                            valor: result[0].valor,
                            quantidade: result[0].quantidade,
                        }
                        itensSelecionados.push(obj);
                    })
                    setData(itensSelecionados);
                }
                else
                    toast.error('Atenção, não foi possível carregar os materiais!');
            }).catch(error => {
                toast.error(error);
            });
    }

    useEffect(fetchData, []);

    const handleSolicitarCompra = () => {
        var obj = {};
        let listItens = [];
        data.forEach((item) => {
            let result = itensAlterados.filter(c => c.id === item.id);
            if (result.length === 0) {
                let obj = {
                    id: item.id,
                    quantidadeCompra: item.quantidade
                };
                listItens.push(obj);
            } else {
                let obj = {
                    id: result[0].id,
                    quantidadeCompra: result[0].quantidadeCompra
                };
                listItens.push(obj);
            }
        })

        obj.processed = 0;
        obj.requisicaoId = parentId;
        obj.itens = listItens;

        apiHelper.put(`${endPoint}solicitarCompra/`, obj)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    itensAlterados = [];
                    props.onHide();
                }
                else
                    toast.error('Atenção, não foi possível excluir o produto!');
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o produto!');
            });
    }

    const changeQuantidade = (e, item) => {
        let value = e.target.innerText;
        let obj = {
            id: item.id,
            quantidadeCompra: value
        };

        let exist = itensAlterados.find(c => c.id === item.id);
        if (exist === undefined || exist === null)
            itensAlterados.push(obj);
        else
            itensAlterados.find(c => c.id === item.id).quantidadeCompra = value;
    };

    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                <thead>
                    <tr className="bg-light-blue">
                        <th>Protheus</th>
                        <th>Produto</th>
                        <th>Valor</th>
                        <th>Quantidade</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{item.codigoProtheus}</td>
                                <td>{item.nome}</td>
                                <td>{config.currencyFix(item.valor)}</td>
                                <td><div style={{ background: '#fcf8e1', outline: 0 }} id="quantidadeCompra" suppressContentEditableWarning contentEditable onBlur={(e) => changeQuantidade(e, item)}>{item.quantidade}</div></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {config.validarClaim("RequisicaoMaterialEstoque") && (dataParent.status !== "Rascunho" && dataParent.status !== "Concluido") && <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleSolicitarCompra}><i className="fa fa-shopping-cart" /> Solicitar Compra</button>}
        </div>
    )
}
