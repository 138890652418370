import React, { useState, useEffect } from 'react';
import { Content, apiHelper, config, toast } from '../';

export function MovimentacaoView(props) {
    const endPoint = 'suprimentos/movimentacao';
    const endPointHistorico = 'suprimentos/movimentacao/historico';
    const rowId = props.match.params.id;
    const [data, setData] = useState({});    
    const [historico, setHistorico] = useState([]);    

    const fetchData = () => {
        apiHelper.get(`${endPoint}/${rowId}`)
            .then(res => {                
                if (res.data.code === 200) {
                    setData(res.data.data);
                    fetchHistorico(res.data.data.pkEstoque);
                }
                else
                    toast.error('Atenção, não foi possível carregar os registros');
            });
    }

    const fetchHistorico = (idEstoque) => {
        apiHelper.get(`${endPointHistorico}?filter=pkEstoque::equal::${idEstoque}`)
            .then(res => {
                if (res.data.code === 200)
                    setHistorico(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar os registros');
            });
    }

    useEffect(fetchData, [rowId]);

    const handleClose = () => {
        props.history.push({
            pathname: '/suprimentos/movimentacao/'
        });
    }

    return (
        <Content title="Movimentação de Estoque" bdataserTitle="Movimentação de Estoque">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Informações do Material</h3>

                            <div className="box-tools pull-right">
                                <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>                                   
                                    <tr>
                                        <th>Material:</th>
                                        <td>{data.material}</td>
                                    </tr>
                                    <tr>
                                        <th>Categoria</th>
                                        <td>{data.categoria}</td>
                                    </tr>
                                    <tr>
                                        <th>Nota Fiscal</th>
                                        <td>{data.numeroNotaFiscal}</td>
                                    </tr>
                                    <tr>
                                        <th>Valor</th>
                                        <td>{config.currencyFix(data.valorReal)}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{data.status}</td>
                                    </tr>
                                    <tr>
                                        <th>Patrimônio</th>
                                        <td>{data.patrimonio}</td>
                                    </tr>
                                    <tr>
                                        <th>Tipo</th>             
                                        <td>{data.tipo}</td>
                                    </tr>
                                    <tr>
                                        <th>Data Compra</th>
                                        <td>{config.dateFix(data.dataCompra)}</td>
                                    </tr>
                                    <tr>
                                        <th>Garantia</th>
                                        <td>{data.garantia}</td>
                                    </tr>
                                    <tr>
                                        <th>Material Veolink</th>
                                        <td>{data.materialVeotex}</td>
                                    </tr>
                                    <tr>
                                        <th>N. Série</th>
                                        <td>{data.numeroSerie}</td>
                                    </tr>
                                    <tr>
                                        <th>Modelo</th>
                                        <td>{data.modelo}</td>
                                    </tr>
                                    <tr>
                                        <th>Fornecedor</th>
                                        <td>{data.fornecedor}</td>
                                    </tr>
                                    <tr>
                                        <th>Unidade de Medida</th>
                                        <td>{data.unidadeMedida}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>                        
                    </div>
                </div>       
                
                <div className="col-md-9">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Histórico Movimentação</h3>
                            <div className="box-tools pull-right">
                                <div className="has-feedback">
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>                               
                                <div className="pull-right">
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th>Origem</th>
                                            <th>Destino</th>
                                            <th>Data</th>
                                            <th>Responsável</th>
                                            <th>Quantidade</th>
                                            <th>Observação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historico.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.origem}</td>
                                                        <td>{item.destino}</td>
                                                        <td>{config.dateTimeFix(item.dataMovimentacao)}</td>
                                                        <td>{item.responsavel}</td>
                                                        <td>{item.quantidadeMovimentada}</td>
                                                        <td>{item.observacaoMovimentacao}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
