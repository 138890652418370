import React, { useState } from 'react';
import { apiHelper, toast, Select } from '../';

export function OrdemServicoForm(props) {
    const endPoint = 'v3/os/';
    const [data, setData] = useState({
        titulo: '',
        descricao: '',
        siteId: 0,
        localId: 0,
        subGrupoTecnicoId: 0,
        codigoExternoCliente: '',
        isPrioridade: false,
        idOsAssociada: ''
    });

    const [grupo, setGrupo] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [site, setSite] = useState(null);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "grupo") {
            setGrupo(value);
            return;
        }
        else if (name === "clienteId") {
            setCliente(value);
            return;
        }
        else if (name === "siteId") {
            setSite(value);
        }
        else if (name === "fkSubGrupoTecnico") {
            setData({ ...data, fkSubGrupoTecnico: value, fkSubGrupoCliente: value, fkSubGrupoHD: value });
            return;
        }

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.post(endPoint, data).then(res => {
            if (res.code === 200 || res.status === 200)
                toast.success('Dados salvos com sucesso');
            else if (res.code === 400)
                toast.warn(res.data.data);
            else
                toast.error('Atenção, não foi possível salvar o registro.');

            props.onHide();
        }).catch(error => {
            if (error.response)
                toast.error('Atenção, não foi possível salvar o registro.' + error.response.data);
            else
                toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const onHide = () => {
        props.onHide()
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Clientes *</label>
                    <Select id="clienteId" className="form-control" onChange={handleChange} required>
                        <option value="">Selecione</option>
                        <option endpoint={`${endPoint}clientes`} valuefield="id" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Sites *</label>
                    <Select id="siteId" className="form-control" onChange={handleChange} refresh={cliente} required>
                        <option value="" endpoint="">Selecione</option>
                        {cliente && <option endpoint={`${endPoint}clientes/${cliente}/sites`} valuefield="id" textfield="nome" />}
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="localId">Local</label>
                    <Select id="localId" className="form-control" onChange={handleChange} refresh={data.siteId}>
                        <option value="">Selecione</option>
                        {site && <option endpoint={data.siteId !== '' ? `comercial/site/listarMateriais/${site}` : ''} valuefield="id" textfield="nome" />}
                    </Select>
                </div>
                <div className="form-group col-md-3">
                    <label>Cód. Cliente</label>
                    <input id="codigoExternoCliente" type="text" className="form-control" onChange={handleChange} />
                </div>
                <div className="form-group col-md-3">
                    <label>OS Associada</label>
                    <input id="idOsAssociada" type="number" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="grupo">Grupo *</label>
                    <Select id="grupo" className="form-control" onChange={handleChange} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/grupo/" valuefield="pkGrupo" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="subGrupoTecnicoId">Subgrupo *</label>
                    <Select id="subGrupoTecnicoId" className="form-control" onChange={handleChange} refresh={grupo} required>
                        <option value="">Selecione</option>
                        {grupo && <option endpoint={'portal/crud/subGrupo/?filter=fkGrupo::equal::' + grupo} valuefield="pkSubGrupo" textfield="nome" />}
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Resumo * (150 caracteres)</label>
                    <input id="titulo" type="text" className="form-control" onChange={handleChange} required maxLength={200} />
                </div>
                <div className="form-group col-md-12">
                    <div className="form-group">
                        <label>Descrição</label>
                        <textarea id="descricao" className="form-control" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default" onClick={onHide}>Fechar</button>
                    <button type="submit" className="btn btn-primary float-right">Salvar</button>
                </div>
            </div>
        </form>
    )
}
