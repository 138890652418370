import React, { Component } from 'react';
import {
    uuidv4, Table, Modals, Button, FasIcon, FormGroupButton
} from '../../adminlte/AdminLTE';

export default class ModalMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            api: props.api,
            keyName: props.keyName,
            rowName: props.rowName,
            parentKey: props.parentkey,
            parentApi: props.parentapi,
            memberCommand: props.membercommand,

            showModal: false,
            modalTable: [],
            modalSearchValue: '',

            memberTable: [],
            memberSearchValue: ''
        };

        this.onHide = this.onHide.bind(this);
        this.handleModalSearch = this.handleModalSearch.bind(this);
        this.handleModalSearchRefresh = this.handleModalSearchRefresh.bind(this);

        this.handleMemberSearch = this.handleMemberSearch.bind(this);
        this.handleMemberSearchRefresh = this.handleMemberSearchRefresh.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.state.parentKey !== undefined)
            this.getMemberData();
    }

    getModalData(filter) {
        if (filter === undefined) filter = '';
        const { api } = this.state;

        fetch(`${api.memberEndPoint}?${api.filterName}=${filter}`, {
            headers: api.headers
        }).then(
            response => response.json()
        ).then(data => {
            if (data.length > 0) {
                this.setState({ modalTable: data });
            }
        });
    }

    handleModalSearch(e) {
        let value = (e === null ? '' : e.target.value);
        this.setState({ modalSearchValue: value });

        if (value.length > 2 || value === '')
            this.getModalData(value);
    }

    handleModalSearchRefresh() {
        this.setState({
            modalSearchValue: '',
            modalTable: []
        });
    }

    handleAdd(memberId) {
        const { api, parentApi, keyName, memberCommand } = this.state;
        let member = memberCommand;
        member[keyName] = memberId;

        this.setState({ isSaving: true });

        fetch(`${parentApi.endPoint}${api.parentEndPoint}`, {
            method: 'POST',
            body: JSON.stringify(member),
            headers: api.headers
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                alert("Membro adicionado com sucesso!");
                this.getMemberData();
            }
            else {
                alert(response[1]["msg"]);
            }

            this.setState({ isSaving: false });
        }).catch(error => {
            alert(error);
            this.setState({ isSaving: false });
        });
    }

    onHide() {
        this.setState({
            showModal: false,
            modalTable: [],
            modalSearchValue: '',
        });
    }

    getMemberData(filter) {
        if (filter === undefined) filter = '';
        const { api, parentApi, parentKey } = this.state;

        fetch(`${parentApi.endPoint}/${parentKey}${api.parentEndPoint}/?${api.filterName}=${filter}`,
            { headers: api.headers })
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    this.setState({ memberTable: data });
                }
            });
    }

    handleMemberSearch(e) {
        let value = (e === null ? '' : e.target.value);
        this.setState({ memberSearchValue: value });

        if (value.length > 2 || value === '')
            this.getMemberData(value);
    }

    handleMemberSearchRefresh() {
        this.setState({
            memberSearchValue: '',
            memberTable: []
        });
        this.getMemberData();
    }

    handleMemberDelete(memberId) {
        if (!window.confirm("Deseja realmente excluir este registro?"))
            return;

        const { parentApi, parentKey, api } = this.state;
        const url = `${parentApi.endPoint}/${parentKey}${api.parentEndPoint}/${memberId}`;

        fetch(url, { method: 'DELETE', headers: api.headers })
            .then(response => {
                return Promise.all([response.status, response.json()]);
            })
            .then((response) => {
                this.handleMemberSearchRefresh();
                if (response[0] !== 200)
                    alert(response[1]["msg"]);
            })
            .catch(error => { alert(error); });
    }

    render() {
        const { isSaving, memberTable, modalTable, showModal, rowName, keyName } = this.state;

        return (
            <React.Fragment>
                <FormGroupButton size="small" placeholder="Buscar membros"
                    onChange={this.handleMemberSearch}
                    value={this.state.memberSearchValue}
                    buttonLeft={<Button bsStyle="success" onClick={() => this.setState({ showModal: true })}>NOVO</Button>}
                    buttonRight={<Button onClick={this.handleMemberSearchRefresh}><FasIcon name='undo' /></Button>}
                />

                <Table striped bordered hover>
                    <tbody>
                        {memberTable.map(row => {
                            return (
                                <tr key={uuidv4()}>
                                    <td>{row[rowName]}</td>
                                    <td style={{ width: "35px" }}>
                                        <Button bsSize="xsmall" bsStyle="danger" disabled={isSaving} onClick={() => this.handleMemberDelete(row[keyName])}>
                                            <FasIcon name="times-circle" />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Modals title="Adiconar Membros"
                    show={showModal}
                    onHide={this.onHide}
                    footer={<Button className="pull-right" bsStyle="default" onClick={this.onHide}>Fechar</Button>}
                >
                    <div style={{ minHeight: "200px" }}>
                        <FormGroupButton size="small" placeholder="Buscar membros"
                            onChange={this.handleModalSearch}
                            value={this.state.modalSearchValue}
                            buttonRight={<Button onClick={this.handleModalSearchRefresh}><FasIcon name='undo' /></Button>}
                        />

                        <Table striped bordered hover>
                            <tbody>
                                {modalTable.map(row => {
                                    return (
                                        <tr key={uuidv4()}>
                                            <td>{row[rowName]}</td>
                                            <td style={{ width: "50px" }}>
                                                <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.handleAdd(row[keyName])}>
                                                    {isSaving ? 'Adicionando...' : 'Adicionar'}
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Modals>
            </React.Fragment>
        );
    }
}