import React, { useEffect, useState } from 'react';
import { Recursive, Formatter } from './';

export function DataTables(props) {
    const { columns = [], rows = [], pagination, bg = "", bordered, striped = false, showHeader = true } = props;
    const [page, setPage] = useState({
        pageSize: 10, totalRows: 0, currentPage: 0, totalPages: 0, startIndex: 0, endIndex: 10
    });

    useEffect(() => {
        const initPage = () => {
            let total = rows.length;
            let pageSize = page.pageSize;
            let startIndex = 0, endIndex = 0, totalPages = 0;

            if (pagination && pagination.size)
                pageSize = pagination.size;

            startIndex = (page.currentPage * pageSize);
            endIndex = pagination ? (startIndex + pageSize) : total;
            totalPages = Math.ceil(total / pageSize);

            setPage({
                totalRows: total,
                totalPages: totalPages,
                pageSize: pageSize,
                currentPage: page.currentPage,
                startIndex: startIndex,
                endIndex: endIndex
            });
        };

        initPage();
    }, [rows.length, page.currentPage, page.pageSize, pagination]);

    const handleNextPage = () => setPage({
        ...page,
        currentPage: page.currentPage < page.totalPages - 1 ? page.currentPage + 1 : page.currentPage
    });

    const handlePreviousPage = () => setPage({
        ...page,
        currentPage: page.currentPage > 0 ? page.currentPage - 1 : page.currentPage
    });

    const handleRowSelect = (e) => {
        props.onSelect && props.onSelect(e.id);
    };

    const getRows = () => {
        return rows.slice(page.startIndex, page.endIndex);
    }

    const getValue = (value, col) => {
        let type = col.type;

        if (type === 'password')
            value = "";
        else
            value = Formatter.ValueFormat(col.formatter, value);

        return value;
    };

    const getFields = () => {
        let child = React.Children.toArray(props.children);
        let cols = child.filter(f => f.type === 'columns')[0];
        let fields = [...columns];

        cols && cols.props.children.map((col, i) => fields.push(col.props))

        return fields;
    };

    const renderPage = () => {
        return (
            <div className={`bg-light p-2${bordered ? ' border border-top-0' : ''}`}>
                <div className="float-right">
                    <div className="btn-group">
                        <button type="button" className="btn btn-default btn-sm" onClick={handlePreviousPage} disabled={page.currentPage <= 0}><i className="fas fa-chevron-left" /></button>
                        <button type="button" className="btn btn-default btn-sm">{`${page.currentPage + 1} de ${page.totalPages}`}</button>
                        <button type="button" className="btn btn-default btn-sm" onClick={handleNextPage} disabled={page.currentPage >= page.totalPages - 1}><i className="fas fa-chevron-right" /></button>
                    </div>
                </div>
                <div className="m-1 text-sm">
                    {`Exibindo ${page.currentPage + 1}-${page.totalPages} de ${page.totalRows} ${page.totalRows > 1 ? 'itens' : 'item'}`}
                </div>
            </div>
        )
    }

    const renderTable = () => {
        let child = React.Children.toArray(props.children);
        let card = child.filter(f => f.type === 'view')[0];

        let cols = !card && (
            <tr className={`${bg ? 'bg-' + bg : ''}`}>
                {getFields().map((col, i) => {
                    return <th key={i} style={col.width ? { width: col.width } : {}}>{col.label}</th>
                })}
            </tr>
        )

        let rows = getRows().map((row, i) =>
            <tr key={i} onClick={() => handleRowSelect(row)} style={props.onSelect && { cursor: 'pointer' }}>
                {!card ?
                    getFields().map((col, i) => {
                        let style = col.width ? { width: col.width } : {};

                        if (col.type === 'template')
                            return <td key={i} style={style} className={col.className}>{Recursive(col.children, row)}</td>
                        else if (col.type === 'image')
                            return <td key={i} style={style} className="p-2 text-center">{getValue(row[col.name], col)}</td>
                        else
                            return <td key={i} style={style} className={col.className}>{getValue(row[col.name], col)}</td>
                    })
                    :
                    <td key={i} className="p-2">{Recursive(card.props.children, row)}</td>
                }
            </tr>
        )

        return (
            <table className={`table${striped ? " table-striped" : ""}${bordered ? " table-bordered" : ""} m-0`}>
                {showHeader && <thead>{cols}</thead>}
                <tbody>{rows}</tbody>
            </table>
        )
    }

    return (
        <>
            {renderTable()}
            {(pagination && page.totalRows > 0) && renderPage()}
        </>
    )
}
