import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Modal, config } from '../';
import { ConsultorForm } from './ConsultorForm';

export function Consultor(props) {
    const endPoint = 'marketing/consultores/';
    const [data, setData] = useState([]);

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}`).then(res => {
            try {
                setData(res.data);
            } catch (e) {
                toast.error('Atenção, não foi possível carregar os Registros');
            }
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleRefresh = () => {
        setData([]);
        fetchData();
    };

    const handleShowForm = (rowId) => {
        setParam({
            ...params,
            rowId: rowId || 0,
            showModal: true
        });
    };

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false
        });

        refresh && fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const getImage = (img) => {
        let url = config.apiUrl() + endPoint.replace("//", "/") + "imagem/" + img;
        return url;
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                        <div className="pull-right">
                            {renderPage()}
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Assinatura</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                    .slice(params.initRow, params.endRow)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.id}</td>
                                                <td>{item.nome}</td>
                                                <td style={{
                                                    width: "250px",
                                                    height: "120px",
                                                    backgroundPosition: "center center",
                                                    backgroundRepeat: "no-repeat", overflow: "hidden"
                                                }}
                                                >
                                                    <img src={getImage(item.imagemAssinatura)} style={{ height: "550px", margin: "-400px 0 0 -170px" }} alt={item.imagemAssinatura} />
                                                </td>
                                                <td style={{ width: '50px' }}>
                                                    {(!item.status || item.status !== 'Enviado') &&
                                                        <div className="btn-toolbar">
                                                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm(item.id)}> <i className="fa fa-edit" /></button>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginTop: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                        <div className="pull-right">
                            {renderPage()}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Alterar Imagem da Assinatura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConsultorForm onHide={handleHide} rowId={params.rowId} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
