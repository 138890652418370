import React, { useEffect, useState } from 'react'
import { apiHelper, Modal, toast } from '../';
import { CardForm } from './CardForm';
import ReportContainer from '../relatorio/ReportContainer';
import ReactSelect from 'react-select';
import { Select } from '../../components/controls/crud/Select';

export function DashboardView(props) {
    const endPoint = 'Dashboard/card';
    const [data, setData] = useState([]);
    const [dataReport, setDataReport] = useState([]);
    const isAdmin = props.admin;
    const rowId = props.id ? props.id : props.match.params.id;
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [grupos, setGrupos] = useState({});
    const [defaultGrupos, setDefaultGrupos] = useState({});

    const handleEdit = (id) => {
        setParam({ ...params, showModal: true, rowId: id });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setDataReport({ ...dataReport, [name]: value });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este Card ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            if (res.code === 200 || res.status === 200) {
                toast.success('card excluido com sucesso');
                fetchData();
            }
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o card.');
        });
    }

    const fetchData = () => {
        let groups = [];
        let defaultGroups = [];
        try {
            apiHelper.get(`Dashboard/boardReport/${rowId}`).then(res => {
                if (res.data.data === null) {
                    //window.location.href = '#/erro/';
                    return "";
                }

                setDataReport(res.data.data);
                
                apiHelper.get(`${endPoint}/${rowId}/`).then(re => setData(re.data));
                let idsGrupo = res.data.data.idsGrupo;
                apiHelper.get('portal/crud/IdentityGroup').then(resp => {
                    resp.data.data.map((item) =>
                        groups.push({ value: item.id, label: item.name })
                    );
                    setGrupos(groups);
                    resp.data.data.filter(item => idsGrupo.includes(item.id)).map((item) =>
                        defaultGroups.push({ value: item.id, label: item.name })
                    );
                    setDefaultGrupos(defaultGroups);
                }).catch(error => {
                    toast.error('Houve um erro ao carregar o registro.1');
                });
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.2');
            });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os cards');
        }
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false, rowId: 0 });
        fetchData();
    }

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    }

    useEffect(fetchData, [rowId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let obj = {
            ...dataReport,
            idsGrupo: (defaultGrupos && defaultGrupos.length > 0) ? defaultGrupos.map(item => item.value) : null
        };
        apiHelper.put(`Dashboard/boardReport/${rowId}`, obj)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success("Dashboard atualizado com sucesso!");
                }
                else
                    toast.error('Atenção, não foi possível salvar o registro.');

            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.1');
            });
    }

    const handleChangeGrupo = (e) => {
        setDefaultGrupos(e);
    }

    return (
        <React.Fragment>
            {isAdmin && <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group col-sm-2">
                                    <label>Nome</label>
                                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={dataReport.nome || ""} required />
                                </div>
                                <div className="form-group col-sm-4">
                                    <label>Grupo</label>
                                    <ReactSelect
                                        id="idsGrupo"
                                        value={(defaultGrupos && defaultGrupos.length > 0) && defaultGrupos}
                                        isMulti
                                        name="colors"
                                        options={grupos}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleChangeGrupo}
                                    />
                                </div>
                                <div className="form-group col-sm-3">
                                    <label>Descrição</label>
                                    <input id="descricao" type="text" className="form-control" onChange={handleChange} value={dataReport.descricao || ""} required />
                                </div>
                                <div className="form-group col-sm-2">
                                    <label>Status</label>
                                    <Select id="ativo" className="form-control" onChange={handleChange} value={dataReport.ativo}>
                                        <option value={true}>Ativo</option>
                                        <option value={false}>Inativo</option>
                                    </Select>
                                </div>
                                <div className="form-group col-sm-1">
                                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>}
            {isAdmin && <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <button type="button" className="btn btn-default btn-sm" onClick={handleNew}><i className="fa fa-plus" /> Adicionar Card</button>
                        </div>
                    </div>
                </div>
            </div>}

            <div className="row">
                {data && data.sort((a, b) => a.ordem - b.ordem).map((item, i) =>
                    <div key={i} className={`col-md-${item.tamanho}`}>
                        <div className="box">
                            <div className="box-header with-border">
                                <h3 className="box-title">{item.nome}</h3>
                                <div className="box-tools pull-right">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                                        {isAdmin && <button type="button" className="btn btn-box-tool" onClick={() => handleEdit(item.id)}><i className="fa fa-pencil" /></button>}
                                        {isAdmin && <button type="button" className="btn btn-box-tool" onClick={() => handleDelete(item.id)}><i className="fa fa-times" /></button>}
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <ReportContainer reportId={item.reportId} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CardForm onHide={() => handleHide()} rowId={params.rowId} endPoint={`Dashboard/card/${rowId}`} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}