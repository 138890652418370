import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Bar } from 'react-chartjs-2';
import { apiHelper, Select } from '../index';

export default function GraficoIndicadores(props) {
    const endPoint = 'v2/os/graficoOs/';

    const [filter, setFilter] = useState({
        dataInicio: moment(new Date()).add(-10, 'days').format("YYYY-MM-DD"),
        dataFim: moment(new Date()).format("YYYY-MM-DD"),
        pkRegional: ''
    });

    const [dataGrafico, setDataGrafico] = useState();
    const [dataTable, setDataTable] = useState([]);

    const getData = () => {
        let where = `(;;dataCadastro::greaterEqual::${filter.dataInicio};;AND;;dataCadastro::lessEqual::${filter.dataFim};;);;`;

        if (filter.pkRegional !== "")
            where = `pkRegional::equal::${filter.pkRegional};;AND;;` + where;

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                if (res.data.code === 200 && res.data.data.length > 0) {
                    let data = res.data.data;
                    let osAbertas = 0;
                    let OsConcluidasDentroSLA = 0;
                    let OsConcluidasForaSLA = 0;
                    let EmAndamentoDentroSLA = 0;
                    let EmAndamentoForaSLA = 0;
                    let PendenciaClienteDentroSLA = 0;
                    let PendenciaClienteForaSLA = 0;
                    let Outros = 0;

                    for (var i = 0; i < data.length; i++) {
                        switch (data[i].tipo) {
                            case 'Os Abertas':
                                osAbertas = osAbertas + data[i].total;
                                break;
                            case 'Os Concluidas Dentro SLA':
                                OsConcluidasDentroSLA = OsConcluidasDentroSLA + data[i].total;
                                break;
                            case 'Os Concluidas Fora SLA':
                                OsConcluidasForaSLA = OsConcluidasForaSLA + data[i].total;
                                break;
                            case 'Em Andamento Dentro SLA':
                                EmAndamentoDentroSLA = EmAndamentoDentroSLA + data[i].total;
                                break;
                            case 'Em Andamento Fora SLA':
                                EmAndamentoForaSLA = EmAndamentoForaSLA + data[i].total;
                                break;
                            case 'Pendência Cliente Dentro SLA':
                                PendenciaClienteDentroSLA = PendenciaClienteDentroSLA + data[i].total;
                                break;
                            case 'Pendência Cliente Fora SLA':
                                PendenciaClienteForaSLA = PendenciaClienteForaSLA + data[i].total;
                                break;
                            case 'Outros':
                                Outros = Outros + data[i].total;
                                break;
                            default:
                        }
                    }
                    const dataAux = {
                        labels: ['Abertas', 'Concluidas Dentro SLA', 'Concluidas Fora SLA', 'Em Andamento Dentro SLA', 'Em Andamento Fora SLA', 'Pendência Cliente Dentro SLA', 'Pendência Cliente Fora SLA', 'Outros'],
                        datasets: [
                            {
                                label: `${filter.pkRegional === '' ? 'Todas as Regionais' : data[0].nome}`,
                                backgroundColor: ['#005DF5', '#5BFA00', '#FF0000', '#186310', '#AB0505', '#20CB0E', '#FF7575', '#B0B2B2'],
                                borderWidth: 1,
                                data: [osAbertas, OsConcluidasDentroSLA, OsConcluidasForaSLA, EmAndamentoDentroSLA, EmAndamentoForaSLA, PendenciaClienteDentroSLA, PendenciaClienteForaSLA, Outros]
                            }
                        ]
                    }

                    let table = [
                        { tipo: 'Abertas', total: osAbertas },
                        { tipo: 'Concluidas Dentro SLA', total: OsConcluidasDentroSLA },
                        { tipo: 'Concluidas Fora SLA', total: OsConcluidasForaSLA },
                        { tipo: 'Em Andamento Dentro SLA', total: EmAndamentoDentroSLA },
                        { tipo: 'Em Andamento Fora SLA', total: EmAndamentoForaSLA },
                        { tipo: 'Pendência Cliente Dentro SLA', total: PendenciaClienteDentroSLA },
                        { tipo: 'Pendência Cliente Fora SLA', total: PendenciaClienteForaSLA },
                        { tipo: 'Outros', total: Outros }
                    ];
                    setDataGrafico(dataAux);
                    setDataTable(table);
                }
            });
    }

    useEffect(getData, []);

    const handleSearch = () => {
        getData();
    }

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom', },
        tooltips: { mode: 'point', },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 5 }
            }]
        }
    };

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            dataInicio: moment(new Date()).add(-10, 'days').format("YYYY-MM-DD"),
            dataFim: moment(new Date()).format("YYYY-MM-DD"),
            pkRegional: ''
        });

        setDataGrafico([]);
        setDataTable([]);
    }

    return (
        <div className="box" style={{ margin: 0 }}>
            <div className="box-header with-border">
                <div className="box-body">
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label>Data Início</label>
                            <input id="dataInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataInicio} required />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Data Fim</label>
                            <input id="dataFim" type="date" className="form-control" onChange={handleChange} value={filter.dataFim} required />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Regional</label>
                            <Select id="pkRegional" className="form-control" onChange={handleChange} value={filter.pkRegional}>
                                <option value="">Todas</option>
                                <option endpoint="portal/crud/regional?filter=isExcluido::equal::0" valuefield="pkRegional" textfield="nome" />
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="box-footer" style={{ padding: 5 }}>
                    <button className="btn btn-default btn-flat pull-right" onClick={handleRefresh}>Limpar</button>
                    <button className="btn btn-primary btn-flat pull-right" onClick={handleSearch} style={{ marginRight: '3px' }}>Pesquisar</button>
                </div>
                <div className="box-tools pull-right">
                    <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                </div>
            </div>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-8">
                        <p className="text-center">
                            <strong>Período: {moment(filter.dataInicio).format("DD/MM/YYYY")} a {moment(filter.dataFim).format("DD/MM/YYYY")}</strong>
                        </p>

                        <div className="chart">
                            {dataGrafico && <Bar data={dataGrafico} options={options} />}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <p className="text-center"><strong>Resumo</strong></p>

                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                    {dataTable.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.tipo}</td>
                                                <td>{item.total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
