import React, { Component } from 'react';
import {
    Modals, FieldGroup, Table, Button
} from '../adminlte/AdminLTE';

export class PesquisaGenerica extends Component {

    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            data: this.props.data,
            title: this.props.title,
            source: this.props.source,
            onImport: this.props.onImport,
            isSaving: false,
            AllowInput: this.props.AllowInput,
            row: {},
            list: [],
            valueSearch: ''
        };

        this.dataSearch = this.dataSearch.bind(this);
        this.setData = this.setData.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row, config: newProps.config });
    }

    getList(filter) {
        fetch(this.state.config.apiUrl + this.state.source + filter.trim(), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(
            response => response.json()
        ).then(response => {
            if (response.data.length > 0) {
                this.setState({ list: response.data });
            }
        });
    }

    dataSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ valueSearch: value });

        if (value.length > 2) {
            this.getList(value);
        } else {
            this.setState({ list: [] });
        }
    }

    onHide() {
        this.setState({ list: [], search: '' });
        this.props.onHide();
    }

    setData(row) {
        this.setState({ list: [], valueSearch: row.nome });
        this.state.onImport(row);
        this.onHide()
    }

    currencyFix(val) {
        if (val)
            return val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        else
            return 0;
    }

    render() {

        const { show = false } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title={this.state.title} show={show} onHide={() => this.onHide()} >
                <form onSubmit={this.handleSubmit}>
                    <FieldGroup id="name" autoFocus col={12} type="text" onChange={this.dataSearch} value={this.state.valueSearch} />

                    <Table striped bordered hover>
                        <tbody>
                            {this.state.list.filter((item) => {
                                return !item.nome.includes('OBSOLETO');
                            }).map(row => {
                                return (
                                    <tr key={row.id}>
                                        <td>{row.nome}
                                            <br />
                                            {row.valorUnidade !== undefined && row.dataUltimaAtualizacao !== undefined &&
                                                <div>
                                                    <span>Valor unitario: {this.currencyFix(row.valorUnidade)}</span> |
                                                    <span> Atualizado em: {new Date(row.dataUltimaAtualizacao).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })}</span>
                                                </div>
                                            }
                                        </td>
                                        <td style={{ width: "50px" }}>
                                            <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.setData(row)}>
                                                {isSaving ? 'Adicionando...' : 'Adicionar'}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </form>
            </Modals>
        );
    }
}

