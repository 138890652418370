import React, { Component } from 'react';
import {
    Row, FieldGroup, Modals, Col, Button, ButtonToolbar
} from '../../adminlte/AdminLTE';

export default class CrudForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: props.config,
            formData: {},
            formNew: {},
            showModal: false
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.handleChangeEdit = this.handleChangeEdit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.initData(newProps);
    }

    UNSAFE_componentWillMount() {
        this.initData(this.props);
    }

    initData(props) {
        let data = {};

        if (props.config.selectedRow !== undefined) {
            props.children.map(item => {
                let value = props.config.selectedRow[item.props.id];
                data[item.props.id] = value;
                return null;
            })

            this.setState({ formData: data });
        }

        this.setState({
            config: props.config,
            showModal: props.config.action === 'new' ? true : false,
            formData: data
        });
    }

    handleChangeNew(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { formNew: { ...prevState.formNew, [name]: value } };
        });
    }

    handleChangeEdit(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { formData: { ...prevState.formData, [name]: value } };
        });
    }

    loadData(prop, value) {
        this.setState(prevState => {
            return { formData: { ...prevState.formData, [prop]: value } };
        });
    }

    handleSave() {
        const { endPoint, headers } = this.state.config.api;
        const { action, selectedRow, keyName } = this.state.config;
        let key = '';

        if (action === 'edit' && keyName !== undefined) {
            key = selectedRow[keyName];
        }

        let data = action === 'new' ? this.state.formNew : this.state.formData;

        fetch(endPoint + key, {
            method: action === 'new' ? 'POST' : 'PUT',
            body: JSON.stringify(data),
            headers: headers
        })
            .then(response => { return Promise.all([response.status, response.json()]); })
            .then((response) => {
                if (response[0] === 200) {
                    alert("Dados salvos com sucesso!");
                    this.onHide();
                }
                else alert(response[1]["msg"]);
            }).catch(error => {
                alert(error);
            });
    }

    onHide() {
        this.props.onHide();
    }

    render() {
        const { action, selectedRow } = this.state.config;
        const { isSaving, showModal, formData } = this.state;
        const { children } = this.props

        return (
            <React.Fragment>
                {action === 'view' &&
                    <Row>
                        {children.map((field, i) =>
                            <Col key={i} md={field.props.col}>
                                <strong>{field.props.label}</strong>
                                <p className="text-muted">{selectedRow[field.props.id]}</p>
                            </Col>
                        )}
                    </Row>
                }

                {action === 'edit' &&
                    <React.Fragment>
                        <Row>
                            {children.map((item, i) => {
                                let value = formData[item.props.id];

                                return (
                                    <FieldGroup
                                        key={i}
                                        col={item.props.col}
                                        id={item.props.id}
                                        type={item.props.type}
                                        componentClass={item.props.componentClass}
                                        label={item.props.label}
                                        value={value === null ? '' : value}
                                        onChange={this.handleChangeEdit}
                                    />
                                );
                            })}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <ButtonToolbar className="pull-right">
                                    <Button bsStyle="primary" onClick={this.handleSave} disabled={isSaving}>
                                        {isSaving ? 'Salvando...' : 'Salvar'}
                                    </Button>
                                    <Button onClick={() => this.onHide()}>Cancelar</Button>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </React.Fragment>
                }

                {action === 'new' && <Modals title="Criar Registro" show={showModal} onHide={() => this.onHide()}
                    footer={
                        <ButtonToolbar className="pull-right">
                            <Button bsStyle="success" onClick={this.handleSave} disabled={isSaving}>
                                {isSaving ? 'Salvando...' : 'Salvar'}
                            </Button>
                            <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                        </ButtonToolbar>
                    }
                >
                    {children.map((item, i) => {
                        return (
                            <FieldGroup
                                key={i}
                                col={item.props.col}
                                id={item.props.id}
                                type={item.props.type}
                                componentClass={item.props.componentClass}
                                label={item.props.label}
                                onChange={this.handleChangeNew}
                            />
                        );
                    })}
                </Modals>
                }
            </React.Fragment>
        );
    }
}
