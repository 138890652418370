import React, { useState, useEffect } from 'react';
import {
    Modals, FieldGroup, Button, ButtonToolbar
} from '../../components/adminlte/AdminLTE';
import { Global, Col, Row } from '../Core';
import { Checkbox } from 'react-bootstrap';
import { toast } from 'react-toastify';

export function OrdemServicoAdicionarInteracao(props) {
    const config = props.config;
    const row = props.row;
    const [disabled, setDisabled] = useState(false);

    const [data, setData] = useState({
        serviceOrderCode: 0,
        description: '',
        statusCode: 0,
        userCode: 0,
        visivelCliente: false,
        nomeAnexo: '',
        anexo: ''
    });

    const loadData = () => {
        if (props.row.codeOS !== undefined) {
            setData({ ...data, serviceOrderCode: props.row.codeOS });
        }

        setData({ ...data, statusCode: props.row.status });
    };

    useEffect(loadData, [props.row]);

    const handleChange = (e) => {
        let name = e.target.id;
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        
        if (name === "anexo") {            
            let file = e.target.files[0];
            
            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, anexo: null });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                Global.ToBase64(file).then((base) => {
                    setData({ ...data, anexo: base, nomeAnexo: file.name});
                });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSalvar = (ev) => {
        if (data.description === "") {
            toast.warn('Informe a Descricao');
            return;
        }
        
        setDisabled(true);

        fetch(`${config.apiEndPoint}${row.codeOS}/interaction/anexo`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then((response) => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Interação criada com sucesso!');
                onHide();
            }
            else {
                setDisabled(false);
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            setDisabled(false);
            toast.error(error.response.data.msg);
        });
    }

    const onHide = () => {
        setData({
            description: '',
            nomeAnexo: "",
            statusCode: 0,
            file: null
        });

        props.onHide();
    }

    return (
        <Modals title="Nova Interação" show={props.show} onHide={onHide}
            footer={
                <ButtonToolbar className="pull-right">
                    <Button bsStyle="success" onClick={handleSalvar} disabled={disabled}>Salvar</Button>
                    <Button bsStyle="default" onClick={onHide}>Cancelar</Button>
                </ButtonToolbar>
            }>

            {row.status < 4 &&
                <FieldGroup id="statusCode" componentClass="select" label="Status" value={data.statusCode} onChange={handleChange}>
                    <option value={0}>Selecione</option>
                    <option value={2}>Em andamento</option>
                    <option value={3}>Pendência</option>
                </FieldGroup>
            }

            <FieldGroup componentClass="textarea" rows="12" id="description" value={data.description} maxLength="1000" type="text" label="Descrição" onChange={handleChange} />

            <FieldGroup type="file" label="Arquivo" id="anexo" name="anexo" onChange={(e) => handleChange(e)} />

            <Row>
                <Col md={12}>
                    <Checkbox inline id="visivelCliente" name="visivelCliente" onClick={handleChange}>Visivel cliente</Checkbox>
                </Col>
            </Row>
        </Modals>
    );
}

