import React, { useState, useEffect } from 'react';
import { Button, config, apiHelper, toast, Checkbox, Select } from '../index';

export function MaterialForm(props) {
    const endPoint = "portal/crud/Material/";
    const endPointView = "portal/crud/uvw_material/";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [categoria, setCategoria] = useState(0);
    const [tipo, setTipo] = useState(0);

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPointView}/${rowId}/pkMaterial`)
                .then(res => {
                    Object.keys(res.data.data).map(p => p !== "fkCategoria" && p !== "fkTipo" && res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setCategoria(res.data.data.fkCategoria);
                    setTipo(res.data.data.fkTipo);

                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        } else {
            setData({
                isPermitidoEdicaoImplantacao: false,
                isPatrimoniado: false,
                isFracionado: false,
                isMutavel: false,
                hasNumeroSerie: false,
                isInativo: false
            });
        }
    };

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "fkCategoria")
            setCategoria(value);
        else if (name === "fkTipo")
            setTipo(value);
        else
            setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res.data);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkMaterial`, data).then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Nome</label>
                        <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Descrição</label>
                        <textarea id="descricao" className="form-control" rows="3" onChange={handleChange} value={data.descricao}></textarea>
                    </div>
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="name">Fabricante</label>
                    <Select id="fkFabricante" className="form-control" onChange={handleChange} value={data.fkFabricante}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/Fabricante/" valuefield="pkFabricante" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="name">Categoria</label>

                    <Select id="fkCategoria" className="form-control" onChange={handleChange} value={categoria}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/Categoria/" valuefield="pkCategoria" textfield="nome" />
                    </Select>
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="name">Tipo</label>
                    <Select id="fkTipo" className="form-control" onChange={handleChange} value={tipo} refresh={categoria}>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/Tipo/?filter=fkCategoria::equal::' + categoria} valuefield="pkTipo" textfield="nome" />
                    </Select>
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="name">Modelo</label>
                    <Select id="fkModelo" className="form-control" onChange={handleChange} value={data.fkModelo} refresh={tipo}>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/Modelo/?filter=fkTipo::equal::' + tipo} valuefield="pkModelo" textfield="nome" />
                    </Select>
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="name">Unidade Medida</label>
                    <Select id="fkUnidadeMedida" className="form-control" onChange={handleChange} value={data.fkUnidadeMedida}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/TipoUnidadeMedida/" valuefield="pkTipoUnidadeMedida" textfield="descricao" />
                    </Select>
                </div>

                <div className="form-group col-md-6">
                    <label>Quantidade Mínima</label>
                    <input id="quantidadeMinima" type="number" className="form-control" onChange={handleChange} value={data.quantidadeMinima} />
                </div>
                <div className="form-group col-md-6">
                    <label>Prazo Médio Entrega</label>
                    <input id="prazoMedioEntrega" type="number" className="form-control" onChange={handleChange} value={data.prazoMedioEntrega} />
                </div>

                <div className="form-group col-md-6">
                    <label>Valor Real</label>
                    <input id="valorReal" type="number" className="form-control" onChange={handleChange} value={data.valorReal} />
                </div>
                <div className="form-group col-md-6">
                    <label>Valor Dolar</label>
                    <input id="valorDolar" type="number" className="form-control" onChange={handleChange} value={data.valorDolar} />
                </div>
                <div className="form-group col-md-6">
                    <label>Custo Instalação</label>
                    <input id="custoInstalacao" type="number" className="form-control" onChange={handleChange} value={data.custoInstalacao} />
                </div>
                <div className="form-group col-md-6">
                    <label>Custo Manutenção</label>
                    <input id="custoManutencao" type="number" className="form-control" onChange={handleChange} value={data.custoManutencao} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <Checkbox id="isPatrimoniado" name="isPatrimoniado" onChange={handleChange} checked={data.isPatrimoniado ? data.isPatrimoniado : false}>Tem Patrimônio</Checkbox>
                    <Checkbox id="isFracionado" name="isFracionado" onChange={handleChange} checked={data.isFracionado ? data.isFracionado : false}>Fracionado</Checkbox>
                    <Checkbox id="isMutavel" name="isMutavel" onChange={handleChange} checked={data.isMutavel ? data.isMutavel : false}>Mutavel</Checkbox>
                </div>

                <div className="form-group col-md-6">
                    <Checkbox id="isPermitidoEdicaoImplantacao" name="isPermitidoEdicaoImplantacao" onChange={handleChange} checked={data.isPermitidoEdicaoImplantacao ? data.isPermitidoEdicaoImplantacao : false}>Permitido Edição Implantação</Checkbox>
                    <Checkbox id="hasNumeroSerie" name="hasNumeroSerie" onChange={handleChange} checked={data.hasNumeroSerie ? data.hasNumeroSerie : false}>Tem número de série</Checkbox>
                    <Checkbox id="isInativo" name="isInativo" onChange={handleChange} checked={data.isInativo ? data.isInativo : false}>Inativo</Checkbox>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="box-tool pull-right">
                        <div className="btn-toolbar">
                            <Button type="submit" bsStyle="success">Salvar</Button>
                            {rowId > 0 && <Button bsStyle="default" onClick={fetchData}>Cancelar</Button>}
                            <Button bsStyle="default" onClick={() => props.onHide()}>Fechar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
