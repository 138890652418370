import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Modal } from '../';
import { CronogramaTarefa } from './CronogramaTarefa';
import { CronogramaForm } from './CronogramaForm';

export function CronogramaView(props) {
    const endPoint = 'v2/projeto/cronograma';
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
    });

    const fetchData = () => {
        rowId > 0 && apiHelper.get(`${endPoint}/${rowId}`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível a requisição ' + rowId);
            });
    }

    useEffect(fetchData, []);

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleClose = () => {
        props.onHide();
    }

    return (
        <React.Fragment>
            <div className="box">
                <div className="box-header with-border">
                    <h3 className="box-title">Tarefas Cronograma {data.descricao}</h3>

                    <div className="box-tools pull-right">
                        {props.canEdit && <button title="Editar" type="button" className="btn btn-box-tool" onClick={() => handleShowForm("new", "Editar Agendamento", rowId)}><i className="fa fa-pencil" /></button>}
                        <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                    </div>
                </div>
                <div className="box-body">
                    <CronogramaTarefa parentId={rowId} projetoId={data.projetoId} onHide={handleHide} canEdit={props.canEdit}/>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Cronograma</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'new' && <CronogramaForm rowId={rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
