import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, toast, Select } from '../';
import { ContratoForm } from './ContratoForm';
import Core from '../Core';

export function Contrato(props) {
    const endPoint = 'portal/crud/uvw_contratos/';
    const folder = config.getFolderModuloCliente();
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        pkContrato: '',
        nome: '',
        numeroContrato: '',
        nomeOrigem: '',
        responsavelVenda: '',
        idCliente: '',
        dataFim: '',
        dataInicio: ''
    });

    const [params, setParams] = useState({
        rowId: props.match.params.id ? parseInt(props.match.params.id) : -1,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                setData(res.data.data);
            });
    }

    const onLoad = () => {
        config.validarPermissao('MenuClientesContratos');
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = () => {
        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'nome' || key === 'numeroContrato' || key === 'nomeOrigem' || key === 'responsavelVenda')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataCadastro') {
                where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;AND;;`;
            }
            else
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const handleNew = () => {
        setParams({
            rowId: 0,
            pageSize: 10,
            totalPages: 0,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });

        props.history.push({
            pathname: '/cliente/contrato/0'
        });
    }

    const handleRefresh = () => {
        setFilter({
            nome: '',
            numeroContrato: '',
            nomeOrigem: '',
            responsavelVenda: '',
            idCliente: '',
            dataFim: '',
            dataInicio: ''
        });

        setData([]);
        fetchData();
    }

    const handleClose = () => {
        setParams({ ...params, rowId: -1 });
        props.history.push({
            pathname: '/cliente/contrato/'
        });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.put(`${endPoint}/${id}/pkContrato`, { isExcluido: true }).then(res => {
            config.httpResult(res.data);
            handleClose();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const handleDownload = async (fileName) => {
        await Core.api_download(folder, fileName).then(res => {
        });
    }

    return (
        <Content title="Contrato" browserTitle="Contrato">
            <div className="row">
                {/*<div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block margin-bottom" onClick={handleNew}>Novo Contrato</button>
                    <div className="box box-solid">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="name">Filtro</label>
                                <input id="name" type="text" className="form-control" value={filter.name} placeholder="Filtro" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-default pull-right" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>*/}
                <div className="col-md-12">
                    {params.rowId === -1 &&
                        <div className="box box-primary">
                            <div className="box-body">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Contrato</button>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                            Pesquisar <span className="caret" />
                                        </button>
                                        <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                            <div className="box" style={{ margin: 0 }}>
                                                <div className="box-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="form-group col-md-4">
                                                                    <label>Id</label>
                                                                    <input id="id" type="number" className="form-control input-sm" onChange={handleChange} value={filter.pkContrato} />
                                                                </div>

                                                                <div className="form-group col-md-4">
                                                                    <label>Nome</label>
                                                                    <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Número</label>
                                                                    <input id="numeroContrato" type="number" className="form-control input-sm" onChange={handleChange} value={filter.numeroContrato} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Origem</label>
                                                                    <input id="nomeOrigem" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nomeOrigem} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Responsável Venda</label>
                                                                    <Select id="responsavelVenda" className="form-control input-sm" onChange={handleChange} value={data.responsavelVenda}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="nome" textfield="nome" />
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Cliente</label>
                                                                    <Select id="idCliente" className="form-control input-sm" onChange={handleChange} value={filter.idCliente}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint={`comercial/site/clientes`} valuefield="id" textfield="nome" />
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Data Inicio</label>
                                                                    <input id="dataInicio" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataInicio} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Data Fim</label>
                                                                    <input id="dataFim" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataFim} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-footer" style={{ padding: 5 }}>
                                                    <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                    <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                                <div className="box-body no-padding">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered no-margin">
                                            <thead>
                                                <tr className="bg-light-blue color-palette">
                                                    <th style={{ width: "50px" }}>#</th>
                                                    <th>ID</th>
                                                    <th>Nº Contrato</th>
                                                    <th>Nome</th>
                                                    <th>Descrição</th>
                                                    <th style={{ width: "50px" }}>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.sort((a, b) => a.pkContrato < b.pkContrato ? 1 : -1)
                                                    .slice(params.initRow, params.endRow)
                                                    .map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td style={{ width: '50px' }}>
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                            <i className="fa fa-ellipsis-v" />
                                                                        </button>
                                                                        <ul className="dropdown-menu" role="menu">
                                                                            <li><Link replace to={'/cliente/contrato/' + item.pkContrato}><i className="fa fa-pencil" /> Editar Contrato</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td><Link to={'/cliente/contrato/' + item.pkContrato}>{item.pkContrato}</Link></td>
                                                                <td><Link to={'/cliente/contrato/' + item.pkContrato}>{item.numeroContrato}</Link></td>
                                                                <td><Link to={'/cliente/contrato/' + item.pkContrato}>{item.nome}</Link></td>
                                                                <td>{item.observacao}</td>
                                                                {item.anexo !== null && <td><i class="fa fa-download" onClick={() => handleDownload(item.anexo)} /></td>}
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <div className="mailbox-controls">
                                        <div className="pull-right">
                                            {renderPage()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {params.rowId > 0 &&
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Contrato</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => handleDelete(params.rowId)}><i className="fa fa-trash"></i></button>
                                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="nav-tabs-custom">
                                    <ul className="nav nav-tabs">
                                        <li className="active"> <Link to="#tab_edicao" data-toggle="tab" aria-expanded="true">Edição</Link></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_edicao">
                                            <ContratoForm parentId={params.rowId} onHide={handleClose} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {params.rowId === 0 &&
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Novo Contrato</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="box-body">
                                <ContratoForm parentId={params.rowId} onHide={handleClose} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Content>
    )
}
