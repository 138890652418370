import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, Modal, toast, fileHelper } from '../index';
import { FaturamentoForm } from './FaturamentoForm';
import Core from '../Core';
import moment from 'moment-timezone';

export function Faturamento(props) {
    const endPoint = "v2/projeto/faturamento";
    const endPointExcel = "rpt_projetoFaturamento_excel";
    const projetoId = props.parentId;
    const canEdit = props.canEdit;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: '',
        showModal: false,
        modalTitle: "Novo Faturamento",
        formName: ""
    });
    const [resumo, setResumo] = useState({});

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=projetoId::equal::${projetoId}`)
            .then(res => {
                obterTotalFaturado(res.data.data);
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData();
            props.onUpdate();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleSolicitarFaturamento = (id) => {

        apiHelper.put(`v2/projeto/solicitarfaturamento/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData();
            props.onUpdate();
        }).catch(error => {
            toast.error('Atenção, não foi solicitar faturamento.');
        });
    }

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    const obterTotalFaturado = (data) => {
        let totalFaturado = 0;
        let totalFaturar = 0;
        let totalProvisionado = 0;

        data.map((item) => {
            if (!item.numeroNF && item.tipo !== 'Provisionamento')
                totalFaturar += item.valorFaturado;
            else if (item.tipo === 'Provisionamento')
                totalProvisionado += item.valorFaturado;
            else
                totalFaturado += item.valorFaturado;
            return 0;
        });

        setResumo({ 'totalFaturado': totalFaturado, 'totalFaturar': totalFaturar, 'totalProvisionado': totalProvisionado });
    }

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onUpdate();
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "edit" && <FaturamentoForm projetoId={projetoId} rowId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>

            {canEdit && <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={() => handleShowForm("edit", "Adicionar Faturamento", 0)}><i className="fa fa-plus" /> Adicionar</button>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fileHelper.exportToExcel(`projetoId::equal::${projetoId}`, endPointExcel, 'ProjetoFaturamento_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
            </div>}
            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin">
                    <thead>
                        <tr className="bg-light-blue color-palette">
                            {props.canEdit && <th style={{ width: "50px" }}>#</th>}
                            <th style={{ width: "50px" }}>ID</th>
                            <th>Criador</th>
                            <th>Valor</th>
                            <th>Data Solicitação</th>
                            <th>Descrição</th>
                            <th>Tipo</th>
                            <th>Data Faturamento</th>
                            <th>Nota Fiscal</th>
                            <th>Anexo Email</th>
                            <th>Anexo NF</th>
                            <th>Responsável Faturamento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.dataCadastro < b.dataCadastro ? 1 : -1)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        {props.canEdit && <td style={{ width: '50px' }}>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" />
                                                </button>
                                                <ul className="dropdown-menu" role="menu">
                                                    <li><Link replace to="#" onClick={() => handleShowForm("edit", "Editar Faturamento", item.id)}><i className="fa fa-edit" /> Editar</Link></li>
                                                    {item.tipo !== 'Provisionamento' && 
                                                        <li><Link replace to="#" onClick={() => handleSolicitarFaturamento(item.id)}><i className="fa fa-money" /> Solicitar Faturamento</Link></li>}
                                                        <li><Link replace to="#" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /> Excluir</Link></li>
                                                </ul>
                                            </div>
                                        </td>}
                                        <td style={{ width: '50px' }}>{item.id}</td>
                                        <td>{item.criador}</td>
                                        <td>{config.currencyFix(item.valorFaturado)}</td>
                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                        <td>{item.descricao}</td>
                                        <td>{item.tipo}</td>
                                        <td>{config.dateFix(item.dataFaturamento)}</td>
                                        <td>{item.numeroNF}</td>
                                        <td>{item.anexoEmail ? <Link to="#" onClick={() => downloadAnexo(item.anexoEmail)}>{item.anexoEmail}</Link> : "-"}</td>
                                        <td>{item.anexoNF ? <Link to="#" onClick={() => downloadAnexo(item.anexoNF)}>{item.anexoNF}</Link> : "-"}</td>
                                        <td>{item.responsavelFaturamento}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="12">
                                <div className="pull-right">
                                    <table className="table table-hover table-striped" style={{ margin: 0 }}>
                                        <tbody>
                                            <tr><td>Faturamento Solicitado</td><td align="right">{config.currencyFix(resumo.totalFaturar)}</td></tr>
                                            <tr><td>Saldo Provisionado</td><td align="right">{config.currencyFix(resumo.totalProvisionado)}</td></tr>
                                            <tr><td>Saldo Faturado</td><td align="right">{config.currencyFix(resumo.totalFaturado)}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </React.Fragment>
    );
}
