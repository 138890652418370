import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Checkbox, Select } from '../index';
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";

export function LeadContatoForm(props) {
    const { handleSubmit, control } = useForm();
    const endPoint = 'portal/crud/ComercialLeadContato/';
    const endPointLead = "lead/contato";
    const rowId = props.rowId;
    const leadId = props.leadId;
    const [data, setData] = useState({});
    const [lead, setLead] = useState({});

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);

                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o contato');
            });
        }

        apiHelper.get(`lead/${leadId}`).then(res => {
            setLead(res.data);
        }).catch(error => {
            toast.error('Não foi possível carregar o lead')
        });
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setData({ ...data, [name]: value });
    };

    const onSubmit = (e) => {

        if (data.isDecisor) {
            if (lead.uf == null || lead.empresa == null || lead.endereco == null || lead.cep == null || lead.cidade == null || lead.uf == null) {
                toast.warn("Preencha os campos Empresa, Endereço, CEP. UF e Cidade do lead para poder cadastrar um contato decisor!");
                return;
            }
            if (lead.uf.replace(/ /g, '') === "" || lead.empresa.replace(/ /g, '') === "" || lead.endereco.replace(/ /g, '') === "" ||
                lead.cep.replace(/ /g, '') === "" || lead.cidade.replace(/ /g, '') === "" || lead.uf.replace(/ /g, '') === "") {
                toast.warn("Preencha os campos Empresa, Endereço, CEP, UF e Cidade do lead para poder cadastrar um contato decisor!");
                return;
            }
        }
        data.leadId = leadId;
        if (rowId === 0) {
            apiHelper.post(endPointLead, data)
                .then(res => {
                    config.httpResult(res);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPointLead}/${rowId}`, data)
                .then(res => {
                    config.httpResult(res);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Nome Contato *</label>
                    <input id="nomeContato" type="text" className="form-control" onChange={handleChange} value={data.nomeContato || ""} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Decisor</label>
                    <Checkbox id="isDecisor" name="isDecisor" onChange={handleChange} checked={data.isDecisor ? data.isDecisor : false}>É Decisor</Checkbox>
                </div>
                <div className="form-group col-sm-6">
                    <label>Cargo {data.isDecisor ? "*" : ""}</label>
                    <input id="cargo" type="text" className="form-control" onChange={handleChange} value={data.cargo || ""} required={data.isDecisor} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Departamento</label>
                    <input id="departamento" type="text" className="form-control" onChange={handleChange} value={data.departamento || ""} />
                </div>
                <div className="form-group col-sm-4">
                    <label>Tipo Contato</label>
                    <Select id="tipoContato" className="form-control" onChange={handleChange} value={data.tipoContato || ""}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::LeadTipoContato" valuefield="valor" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-4">
                    <label>Telefone Fixo {(data.celular === undefined || data.celular === '') ? '*' : ''}</label>
                    <Controller
                        name="telefone"
                        control={control}
                        render={({ field }) => (
                            <InputMask id="telefone" name="telefone" pattern="\(\d{2}\)\d{4}-\d{4}"
                                mask="(99)9999-9999" required={(data.celular === undefined || data.celular === '')}
                                className="form-control" onChange={handleChange}
                                value={data.telefone || ""} placeholder="(xx)xxxx-xxxx" />
                        )}
                    />
                </div>
                <div className="form-group col-sm-4">
                    <label>Telefone Celular {(data.telefone === undefined || data.telefone === '') ? '*' : ''}</label>
                    <Controller
                        name="celular"
                        control={control}
                        render={({ field }) => (
                            <InputMask id="celular" name="celular"
                                mask="(99)99999-9999"
                                pattern="\(\d{2}\)\d{5}-\d{4}"
                                className="form-control" onChange={handleChange}
                                value={data.celular || ""}
                                placeholder="(xx)xxxxx-xxxx"
                                required={(data.telefone === undefined || data.telefone === '')} />
                        )}
                    />
                </div>
                <div className="form-group col-sm-6">
                    <label>E-mail  *</label>
                    <input id="email" type="email" className="form-control" onChange={handleChange} value={data.email || ""} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Linkedin</label>
                    <input id="linkedin" type="text" className="form-control" onChange={handleChange} value={data.linkedin || ""} />
                </div>
                <div className="form-group col-sm-12">
                    <label>Observação</label>
                    <textarea id="contatoObservacao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.contatoObservacao} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    )
}
