import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select, Button, FormGroupButton, config, Checkbox } from '../index';
import { PesquisaGenerica } from '../../components/controls/PesquisaGenerica';

export function SiteForm(props) {
    const endPoint = "comercial/site/";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [showFormFindClientes, setShowFormFindClientes] = useState(false);
    const [showFields, setShowFields] = useState(true);
    const [uf, setUf] = useState(0);

    const fetchData = () => {
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}?siteIdentificador=${rowId}`).then(res => {
                let data = res.data.data;
                setUf(data.uf);
                var obj = {
                    nome: data.nome,
                    codigoIdentificador: data.codigoIdentificador,
                    codigoIdentificadorCliente: data.cliente.id,
                    idCliente: data.cliente.id,
                    cnpj: data.cnpj,
                    tipo: data.tipo,
                    ativo: data.ativo,
                    telefone: data.telefone,
                    logradouro: data.endereco.logradouro,
                    numero: data.endereco.numero,
                    complemento: data.endereco.complemento,
                    bairro: data.endereco.bairro,
                    cep: data.endereco.cep,
                    cidade: data.endereco.cidade,
                    uf: data.endereco.uf,
                    pessoaCodigoIdentificador: '',
                    nivel1: data.nivel1,
                    nivel2: data.nivel2,
                    nivel3: data.nivel3,
                    slaSolicitacao: data.slaSolicitacao,
                    slaPreventiva: data.slaPreventiva,
                    regional: data.regional,
                    clienteValueSearch: data.cliente.nome,
                    aberturaOsPreventivaAutomatica: data.aberturaOsPreventivaAutomatica,
                    intervaloPreventivaAutomatica: data.intervaloPreventivaAutomatica,
                    diaDaSemanaPreventivaAutomatica: data.diaDaSemanaPreventivaAutomatica,
                    agendamentoId: data.agendamentoId,
                    latitude: data.latitude,
                    longitude: data.longitude
                };

                if (data.aberturaOsPreventivaAutomatica)
                    setShowFields(true);
                else
                    setShowFields(false);

                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o site');
            });
        } else {
            setShowFields(false);
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "uf")
            setUf(value);

        if (name === 'aberturaOsPreventivaAutomatica' && value) {
            setShowFields(true);
        } else if (name === 'aberturaOsPreventivaAutomatica' && value === false) {
            setShowFields(false);
            setData({ ...data, [name]: value, intervaloPreventivaAutomatica: 0, diaDaSemanaPreventivaAutomatica: '' });
            return;
        }

        setData({ ...data, [name]: value });
    }

    const handleSubmit = () => {
        if (data.nivel1 === '' || data.nivel1 == null)
            data.nivel1 = 0;
        if (data.nivel2 === '' || data.nivel2 == null)
            data.nivel2 = 0;
        if (data.nivel3 === '' || data.nivel3 == null)
            data.nivel3 = 0;
        if (data.slaPreventiva === '' || data.slaPreventiva == null)
            data.slaPreventiva = 0;
        if (data.slaSolicitacao === '' || data.slaSolicitacao == null)
            data.slaSolicitacao = 0;
        if (data.aberturaOsPreventivaAutomatica === false) {
            data.intervaloPreventivaAutomatica = 0;
            data.diaDaSemanaPreventivaAutomatica = null;
        }

        if (data.agendamentoId === null || data.agendamentoId === "")
        data.agendamentoId = 0
        data.ativo = (data.ativo === "true" || data.ativo === true);

        if (rowId === 0) {
            apiHelper.post(endPoint, data)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            console.log(data);
            apiHelper.put(`${endPoint}/${rowId}`, data)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleAddCliente = (row) => {
        setData({ ...data, clienteValueSearch: row.nome, codigoIdentificadorCliente: row.id, idCliente: row.id });

    }

    const verificarNomeSite = (e) => {
        if (data.codigoIdentificadorCliente === undefined)
            return;

        apiHelper.get(endPoint + 'cliente/' + data.codigoIdentificadorCliente + '?nome=' + data.nome).then(res => {
            var nomeExiste = false;
            let idEncontrado = '';
            for (var i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].nome.toUpperCase().trim() === data.nome.toUpperCase().trim()) {
                    nomeExiste = true;
                    idEncontrado = res.data.data[i].id;
                    break;
                }
            }
            if (nomeExiste && idEncontrado !== parseInt(data.codigoIdentificador)) {
                toast.warn("O nome " + data.nome + " ja existe! Informe um outro nome de site.");
            } else {
                handleSubmit();
            }
        }).catch(error => {
            toast.error('Houve um erro ao carregar o site');
        });
    }

    let days = []
    for (let i = 1; i < 29; i++)
        days.push(<option value={i}>{i}</option>)

    return (
        <form onSubmit={verificarNomeSite}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <FormGroupButton placeholder="Cliente" label="Cliente" disabled value={data.clienteValueSearch}
                        buttonRight={<Button bsStyle="success" type="button" onClick={() => { setShowFormFindClientes(true) }}>Selecionar</Button>}
                    />
                </div>
                <div className="form-group col-sm-12">
                    <label>Nome</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Logradouro</label>
                    <input id="logradouro" type="text" className="form-control" onChange={handleChange} value={data.logradouro} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Número</label>
                    <input id="numero" type="text" className="form-control" onChange={handleChange} value={data.numero} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Complemento</label>
                    <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Bairro</label>
                    <input id="bairro" type="text" className="form-control" onChange={handleChange} value={data.bairro} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Cep</label>
                    <input id="cep" type="text" className="form-control" onChange={handleChange} value={data.cep} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>UF</label>
                    <Select id="uf" className="form-control" onChange={handleChange} value={data.uf} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Cidade</label>
                    <Select id="cidade" className="form-control" onChange={handleChange} value={data.cidade || ''} refresh={uf} required>
                        <option value="">Selecione</option>
                        {data.uf && <option endpoint={`portal/crud/uvw_cidade?filter=SIGLA::equal::${data.uf}`} valuefield="nome" textfield="nome" />}
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Telefone</label>
                    <input id="telefone" type="text" className="form-control" onChange={handleChange} value={data.telefone} />
                </div>
                <div className="form-group col-sm-6">
                    <label>CNPJ</label>
                    <input id="cnpj" type="text" className="form-control" onChange={handleChange} value={data.cnpj} maxLength="14" />
                </div>
                <div className="form-group col-sm-6">
                    <label>Tipo</label>
                    <Select id="tipo" className="form-control" onChange={handleChange} value={data.tipo} required>
                        <option value="">Selecione</option>
                        <option value="Condomínio Residencial">Condomínio Residencial</option>
                        <option value="CondominioEmpresarial">Condomínio Empresarial</option>
                        <option value="Shopping Center">Shopping Center</option>
                        <option value="Call Center">Call Center</option>
                        <option value="IndustriaPadrao">Indústria Padrão</option>
                        <option value="IndustriaCritica">Industria Crítica (Usinas, mineração, pretrolífera, etc.)</option>
                        <option value="EstacaoRadioBase">Estação Radio Base</option>
                        <option value="LojaDentroShopping">Loja - Dentro de Shopping</option>
                        <option value="LojaRua">Loja- Rua</option>
                        <option value="Escritório">Escritório</option>
                        <option value="Veículo">Veículos</option>
                        <option value="Monitoramento Público">Monitoramento Público</option>
                        <option value="Outros">Outros</option>
                        <option value="ERB">ERB</option>
                        <option value="Loja">Loja</option>
                        <option value="Indústria">Indústria</option>
                        <option value="Escritório com Controle de Acesso">Escritório com Controle de Acesso</option>
                        <option value="Estação Telefônica">Estação Telefônica</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Status</label>
                    <Select id="ativo" className="form-control" onChange={handleChange} value={data.ativo} required>
                        <option value="">Selecione</option>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Regional</label>
                    <Select id="regional" className="form-control" onChange={handleChange} value={data.regional || ''} required>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/regional/?filter=isExcluido::equal::0'} valuefield="pkRegional" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-2">
                    <label>Nível SLA 1</label>
                    <input id="nivel1" type="number" className="form-control" onChange={handleChange} value={data.nivel1} />
                </div>
                <div className="form-group col-sm-2">
                    <label>Nível SLA 2</label>
                    <input id="nivel2" type="number" className="form-control" onChange={handleChange} value={data.nivel2} />
                </div>
                <div className="form-group col-sm-2">
                    <label>Nível SLA 3</label>
                    <input id="nivel3" type="number" className="form-control" onChange={handleChange} value={data.nivel3} />
                </div>
                <div className="form-group col-sm-3">
                    <label>SLA Solicitações</label>
                    <input id="slaSolicitacao" type="number" className="form-control" onChange={handleChange} value={data.slaSolicitacao} />
                </div>
                <div className="form-group col-sm-3">
                    <label>SLA Preventiva</label>
                    <input id="slaPreventiva" type="number" className="form-control" onChange={handleChange} value={data.slaPreventiva} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Latitude</label>
                    <input id="latitude" className="form-control" onChange={handleChange} value={data.latitude} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Longitude</label>
                    <input id="longitude" className="form-control" onChange={handleChange} value={data.longitude} />
                </div>
                <div className="form-group col-sm-12">
                    <label>Agendamento</label>
                    <Select id="agendamentoId" className="form-control" onChange={handleChange} value={data.agendamentoId}>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/Agendamento" valuefield="id" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <Checkbox id="aberturaOsPreventivaAutomatica" onChange={handleChange} checked={data.aberturaOsPreventivaAutomatica ? data.aberturaOsPreventivaAutomatica : false}>Abertura OS Preventiva Automática</Checkbox>
                </div>
                {showFields &&
                    <React.Fragment>
                        <div className="form-group col-sm-6">
                            <label>Intervalo Preventiva Automática</label>
                            <input id="intervaloPreventivaAutomatica" type="number" className="form-control" onChange={handleChange} value={data.intervaloPreventivaAutomatica} />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Dia do Mês</label>
                            <Select id="diaDaSemanaPreventivaAutomatica" className="form-control" onChange={handleChange} value={data.diaDaSemanaPreventivaAutomatica} required>
                                <option value="">Selecione</option>
                                {days}
                            </Select>
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {config.validarClaim('site.escrita') && <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Salvar</button>}
                </div>
            </div>

            {showFormFindClientes && <PesquisaGenerica config={{ apiUrl: localStorage.getItem('apiUrl') }} show={showFormFindClientes} title="Buscar Clientes" source="comercial/cliente/?filtro=nome::contains::" onImport={(row) => { handleAddCliente(row) }} onHide={() => { setShowFormFindClientes(false) }} />}
        </form>
    )
}
