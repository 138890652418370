import axios from 'axios';
import Global from '../Global';
import { Modal } from 'react-bootstrap';
import Crud from '../components/controls/crud/Crud';

import {
    Content, Row, Box, Tabs, TabContent, FasIcon, Link,
    Col, Table, Button, ButtonToolbar, FormGroupButton
} from '../components/adminlte/AdminLTE';

const API_URL = Global.ApiUrl();
const API_HEADER = Global.ApiHeader();

class Core {
    api_getBlob = (endPoint) => {
        return axios({
            url: API_URL + endPoint,
            method: 'GET',
            responseType: 'blob',
            headers: API_HEADER
        });
    }

    api_download = async (type, file) => {
        await this.api_getBlob(`anexo/download/${type}/${file}`).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
        });
    };

    api_anexoBase64 = async (type, file) => {
        await this.api_getBlob(`anexo/download/${type}/${file}`).then(res => {            
            return res.data;
        });
    };

    api_get = (endPoint) => {
        return axios.get(API_URL + endPoint, { headers: API_HEADER });
    }

    api_getById = (endPoint, id) => {
        return axios.get(API_URL + endPoint + '/' + id, { headers: API_HEADER });
    }

    api_post = (endPoint, obj) => {
        return axios.post(API_URL + endPoint, obj, { headers: API_HEADER });
    }

    api_put = (endPoint, obj) => {
        return axios.put(API_URL + endPoint, obj, { headers: API_HEADER });
    }

    api_delete = (endPoint) => {
        return axios.delete(API_URL + endPoint, { headers: API_HEADER });
    }
}

export default new Core();
export {
    axios, Global, Modal,
    Content, Row, Box, Tabs, TabContent, FasIcon, Link,
    Col, Table, Button, ButtonToolbar, FormGroupButton, Crud
};
