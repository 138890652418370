import React, { useState } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function AcionamentoForm(props) {

    const endPoint = 'v2/os/acionamentoTecnico';
    const siteId = props.siteId;
    const regionalId = props.regionalId;
    const acionamentos = props.acionamentos;



    const [data, setData] = useState({
        fkOS: props.parentId,
        tecnico: ''
    });

    const handleChange = (e) => {
        let value = e.target["value"];
        let name = e.target.id;

        if (name === "nivelSLA") {
            apiHelper.get(`portal/crud/uvw_siteNew/${siteId}/Id`)
                .then(res => {
                    if (value === "1")
                        setData({ ...data, [name]: value, valorSLA: res.data.data.nivel1 });
                    else if (value === "2")
                        setData({ ...data, [name]: value, valorSLA: res.data.data.nivel2 });
                    else if (value === "3")
                        setData({ ...data, [name]: value, valorSLA: res.data.data.nivel3 });
                    else if (value === "4")
                        setData({ ...data, [name]: value, valorSLA: res.data.data.slaSolicitacao });
                    else if (value === "5")
                        setData({ ...data, [name]: value, valorSLA: res.data.data.slaPreventiva });

                }).catch(error => {
                    toast.error('Atenção, Não foi possível selecionar o Nível SLA!');
                });
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let item of acionamentos) {
            if (item.idUsuarioAcionado.toString() === data.idUsuarioAcionado && (!item.dataConclusao && !item.isCancelado)) {
                toast.error("Este Usuário já encontra-se acionado para a Ordem de Serviço");
                return;
            }
        }
        apiHelper.post(endPoint, data).then(res => {
            config.httpResult(res.data);
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="form-group col-md-8">
                    <Select id="idUsuarioAcionado" className="form-control" onChange={handleChange} required>
                        <option value="">Selecione o Técnico</option>
                        <option endpoint={`portal/crud/regionalSubordinado/?filter=Perfil::in::Tecnico,Supervisor;;AND;;fkRegional::equal::${regionalId};;AND;;isExcluido::equal::0`} valuefield="pessoaId" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-md-4">
                    <select id="nivelSLA" className="form-control" onChange={handleChange} required>
                        <option value="">Selecione o Nível SLA</option>
                        {props.data && props.data.nivel1 > 0 && <option value="1">Nível 1</option>}
                        {props.data && props.data.nivel2 > 0 && <option value="2">Nível 2</option>}
                        {props.data && props.data.nivel3 > 0 && <option value="3">Nível 3</option>}
                        {props.data && props.data.slaSolicitacao > 0 && <option value="4">Solicitação</option>}
                        {props.data && props.data.slaPreventiva > 0 && <option value="5">Preventiva</option>}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <select id="tipoAtendimento" className="form-control" onChange={handleChange} required>
                        <option value="">Tipo Atendimento</option>
                        <option value="Remoto">Remoto</option>
                        <option value="Campo">Campo</option>
                        <option value="Remoto-Campo">Remoto/Campo</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <select id="isPrimary" className="form-control" onChange={handleChange} required>
                        <option value="">Prioridade</option>
                        {!props.temPrimario && <option value="1">Primário</option>}
                        <option value="0">Secundário</option>
                    </select>
                </div>
                {config.validarClaim('atendimento.tecnico.valor') &&
                    <div className="form-group col-md-4">
                        <input type="number" id="valorOS" className="form-control" placeholder='Valor OS' onChange={handleChange} />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Voltar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    );
}
