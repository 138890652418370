import React, { useState } from 'react';
import { apiHelper, toast, config, Select } from './../../index';

export function FaturamentoTotalObras(props) {
    const endPoint = "v2/projeto/relatorioFaturamentoTotal";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        gerenteResponsavel: '',
        aprovador: '',
        status: ''
    });

    const [distinctAnos, setDistinctAnos] = useState([]);
    const [distinctClientes, setDistinctClientes] = useState([]);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }

        apiHelper.post(`${endPoint}`, filter)
            .then(res => {
                if (res.data.code === 200) {

                    let data = res.data.data;
                    let dadosCliente = [];
                    let totalGeralColunas = [];
                    let totalGeralLinhas = [];
                    let totalGeralColunaTotal = [];
                    let clientes = data.map((item, i) => { return item.cliente });
                    let ano = data.map((item, i) => { return item.ano });

                    setDistinctClientes(clientes.filter((v, i, a) => a.indexOf(v) === i));
                    setDistinctAnos(ano.filter((v, i, a) => a.indexOf(v) === i));

                    distinctClientes.forEach((cliente) => {
                        //total da linha
                        let faturadoComPeD = 0;
                        let aFaturarComPeD = 0;
                        let faturadoSemPeD = 0;
                        let aFaturarSemPeD = 0;
                        let listItensClienteAno = [];

                        distinctAnos.forEach((ano) => {
                            let totalFaturadoComPeD = 0;
                            let totalAFaturarComPeD = 0;
                            let totalFaturadoSemPeD = 0;
                            let totalAFaturarSemPeD = 0;

                            //Filtra pelo cliente e ano
                            let listCliente = data.filter(c => c.cliente === cliente && c.ano === ano);

                            //Pega o totalFaturado e total AFaturar do cliente no ano com PeD e Sem Ped
                            listCliente.forEach((item) => {
                                let faturado = 0;
                                let aFaturar = 0;

                                faturado = somarFaturado(item);
                                aFaturar = somarAFaturar(item);

                                if (item.comPeD) {
                                    totalFaturadoComPeD = totalFaturadoComPeD + faturado;
                                    totalAFaturarComPeD = totalAFaturarComPeD + aFaturar;

                                } else {
                                    totalFaturadoSemPeD = totalFaturadoSemPeD + faturado;
                                    totalAFaturarSemPeD = totalAFaturarSemPeD + aFaturar;
                                }

                            });

                            let obj = {
                                cliente: cliente,
                                ano: ano,
                                totalFaturadoComPeD: totalFaturadoComPeD, //faturado com PeD
                                totalFaturadoSemPeD: totalFaturadoSemPeD, //faturado Sem PeD
                                totalAFaturarComPeD: totalAFaturarComPeD, //A faturar Com PeD
                                totalAFaturarSemPeD: totalAFaturarSemPeD, //A faturar Sem PeD
                            }

                            dadosCliente.push(obj);
                            listItensClienteAno.push(obj);
                        });

                        //Soma a linha do cliente
                        listItensClienteAno.forEach((item) => {
                            faturadoComPeD = faturadoComPeD + item.totalFaturadoComPeD;
                            faturadoSemPeD = faturadoSemPeD + item.totalFaturadoSemPeD;
                            aFaturarComPeD = aFaturarComPeD + item.totalAFaturarComPeD;
                            aFaturarSemPeD = aFaturarSemPeD + item.totalAFaturarSemPeD;

                        });
                        let objTotalLinhaCliente = {
                            cliente,
                            faturadoComPeD,
                            faturadoSemPeD,
                            aFaturarComPeD,
                            aFaturarSemPeD
                        };
                        totalGeralLinhas.push(objTotalLinhaCliente);

                    });

                    //Soma as Colunas do Ano
                    distinctAnos.forEach((ano, j) => {
                        let totalFaturadoColunaComPeD = 0;
                        let totalFaturadoColunaSemPeD = 0;
                        let totalAFaturarColunaComPeD = 0;
                        let totalAFaturarColunaSemPeD = 0;

                        distinctClientes.forEach(input => {
                            totalFaturadoColunaComPeD += dadosCliente.filter(c => c.ano === ano && c.cliente === input)[0].totalFaturadoComPeD;
                            totalFaturadoColunaSemPeD += dadosCliente.filter(c => c.ano === ano && c.cliente === input)[0].totalFaturadoSemPeD;
                            totalAFaturarColunaComPeD += dadosCliente.filter(c => c.ano === ano && c.cliente === input)[0].totalAFaturarComPeD;
                            totalAFaturarColunaSemPeD += dadosCliente.filter(c => c.ano === ano && c.cliente === input)[0].totalAFaturarSemPeD;
                        })

                        let objCol = {
                            ano: ano,
                            totalFaturadoColunaComPeD,
                            totalFaturadoColunaSemPeD,
                            totalAFaturarColunaComPeD,
                            totalAFaturarColunaSemPeD
                        };
                        totalGeralColunas.push(objCol);
                    });

                    //Soma as Colunas do total Geral Linhas
                    let faturadoColunaComPeD = 0;
                    let faturadoColunaSemPeD = 0;
                    let aFaturarColunaComPeD = 0;
                    let aFaturarColunaSemPeD = 0;

                    distinctClientes.forEach(cliente => {
                        faturadoColunaComPeD += totalGeralLinhas.filter(c => c.cliente === cliente)[0].faturadoComPeD;
                        faturadoColunaSemPeD += totalGeralLinhas.filter(c => c.cliente === cliente)[0].faturadoSemPeD;
                        aFaturarColunaComPeD += totalGeralLinhas.filter(c => c.cliente === cliente)[0].aFaturarComPeD;
                        aFaturarColunaSemPeD += totalGeralLinhas.filter(c => c.cliente === cliente)[0].aFaturarSemPeD;
                    });

                    let objCol = {
                        faturadoColunaComPeD,
                        faturadoColunaSemPeD,
                        aFaturarColunaComPeD,
                        aFaturarColunaSemPeD
                    };

                    totalGeralColunaTotal.push(objCol);

                    dadosCliente.totalGeralLinhas = totalGeralLinhas;
                    dadosCliente.totalGeralColunas = totalGeralColunas;
                    dadosCliente.totalGeralColunaTotal = totalGeralColunaTotal;
                    setData(dadosCliente);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível carregar os registros.');
            });
    }

    const somarFaturado = (item) => {
        let totalFaturado = (item.totalFaturadoVenda !== null ? item.totalFaturadoVenda : 0) + (item.totalFaturadoInstalacao !== null ? item.totalFaturadoInstalacao : 0) + (item.totalFaturadoLocacao !== null ? item.totalFaturadoLocacao : 0) + (item.totalFaturadoManutencao !== null ? item.totalFaturadoManutencao : 0);
        return totalFaturado;
    }

    const somarAFaturar = (item) => {
        let totalAFaturar = item.totalAFaturarVenda + item.totalAFaturarLocacao + item.totalAFaturInstalacao + item.totalAFaturManutencao;
        return totalAFaturar;
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            gerenteResponsavel: '',
            aprovador: '',
            status: ''
        });
        setData([]);
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Faturamento Total de Obras</h3>
            </div>
            <div className="panel-body">
                <React.Fragment>
                    <div className="row">
                        <div className="form-group col-sm-4">
                            <label>Gerente Responsável</label>
                            <Select id="aprovador" className="form-control" onChange={handleChange} value={filter.aprovador}>
                                <option value="">Todos</option>
                                <option endpoint="comercial/pessoa/perfil?perfil=GestorOperacional" valuefield="id" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-4">
                            <label>GP Responsável</label>
                            <Select id="gerenteResponsavel" className="form-control" onChange={handleChange} value={filter.gerenteResponsavel}>
                                <option value="">Todos</option>
                                <option endpoint="comercial/pessoa/perfil?perfil=GerenteProjeto" valuefield="id" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Status</label>
                            <select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status}>
                                <option value="">Todos</option>
                                <option value="Novo">Novo</option>
                                <option value="Andamento">Andamento</option>
                                <option value="Concluido">Concluído</option>
                                <option value="Cancelado">Cancelado</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-right" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                    {data && data.length > 0 && <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr key={Math.random()} className="bg-light-blue color-palette">
                                            <th key={Math.random()} rowSpan='3'>Cliente</th>
                                            {distinctAnos && distinctAnos.map((ano, i) => {
                                                return (
                                                    <th key={Math.random()} colSpan='4'>{ano}</th>
                                                )
                                            })}
                                            <th key={Math.random()} rowSpan='2' colSpan='2'>Total A Faturar</th>
                                            <th key={Math.random()} rowSpan='2' colSpan='2'>Total Faturado</th>
                                        </tr>
                                        <tr key={Math.random()} className="bg-light-blue color-palette">
                                            {distinctAnos && distinctAnos.map((ano, i) => {
                                                return (
                                                    <React.Fragment>
                                                        <th key={Math.random()} colSpan='2'>A Faturar</th> {/* {Ano} */}
                                                        <th key={Math.random()} colSpan='2'>Faturado</th> {/* {Ano} */}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tr>
                                        <tr key={Math.random()} className="bg-light-blue color-palette">
                                            {distinctAnos && distinctAnos.map((ano, i) => {
                                                return (
                                                    <React.Fragment>
                                                        <th key={Math.random()}>Com PeD</th> {/* A Faturar {Ano} */}
                                                        <th key={Math.random()}>Sem PeD</th>  {/* A Faturar {Ano} */}
                                                        <th key={Math.random()}>Com PeD</th> {/* Faturado {Ano} */}
                                                        <th key={Math.random()}>Sem PeD</th> {/* Faturado {Ano} */}
                                                    </React.Fragment>
                                                )
                                            })}
                                            <th key={Math.random()}>Com PeD</th> {/* Total A Faturar Com PeD*/}
                                            <th key={Math.random()}>Sem PeD</th>  {/* Total A Faturar Sem PeD */}
                                            <th key={Math.random()}>Com PeD</th> {/* Total Faturado Com PeD*/}
                                            <th key={Math.random()}>Sem PeD</th>  {/* Total Faturado Sem PeD */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {distinctClientes && distinctClientes.map((cliente, i) => {
                                            return (
                                                <tr key={Math.random()}>
                                                    <td key={Math.random()}>{cliente}</td> {/* Cliente */}
                                                    {distinctAnos && distinctAnos.map((ano, i) => {
                                                        return (
                                                            <React.Fragment>
                                                                <td key={Math.random()}>{config.currencyFix(data.filter(c => c.cliente === cliente && c.ano === ano)[0].totalAFaturarComPeD)}</td>  {/* A Faturar {Ano} Com PeD */}
                                                                <td key={Math.random()}>{config.currencyFix(data.filter(c => c.cliente === cliente && c.ano === ano)[0].totalAFaturarSemPeD)}</td> {/*  A Faturar {Ano} Sem PeD */}
                                                                <td key={Math.random()}>{config.currencyFix(data.filter(c => c.cliente === cliente && c.ano === ano)[0].totalFaturadoComPeD)}</td> {/* Faturado {Ano} Com PeD */}
                                                                <td key={Math.random()}>{config.currencyFix(data.filter(c => c.cliente === cliente && c.ano === ano)[0].totalFaturadoSemPeD)}</td> {/* Faturado {Ano} Sem PeD */}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    <td key={Math.random()} style={{ 'backgroundColor': '#EBEBEC' }}>{config.currencyFix(data.totalGeralLinhas.filter(c => c.cliente === cliente)[0].aFaturarComPeD)}</td> {/* Total A Faturar Com PeD */}
                                                    <td key={Math.random()} style={{ 'backgroundColor': '#EBEBEC' }}>{config.currencyFix(data.totalGeralLinhas.filter(c => c.cliente === cliente)[0].aFaturarSemPeD)}</td> {/* Total A Faturar Sem PeD */}
                                                    <td key={Math.random()} style={{ 'backgroundColor': '#EBEBEC' }}>{config.currencyFix(data.totalGeralLinhas.filter(c => c.cliente === cliente)[0].faturadoComPeD)}</td> {/* Total Faturado Com PeD */}
                                                    <td key={Math.random()} style={{ 'backgroundColor': '#EBEBEC' }}>{config.currencyFix(data.totalGeralLinhas.filter(c => c.cliente === cliente)[0].faturadoSemPeD)}</td> {/* Total Faturado Sem PeD */}
                                                </tr>
                                            )
                                        })}
                                        <tr key={Math.random()}>
                                            <td key={Math.random()} className='bg-light-blue'>Total</td>
                                            {distinctAnos && distinctAnos.map((ano, i) => {
                                                return (
                                                    <React.Fragment>
                                                        <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunas.filter(c => c.ano === ano)[0].totalAFaturarColunaComPeD)}</td> {/* Total A Faturar coluna {Ano} Com PeD */}
                                                        <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunas.filter(c => c.ano === ano)[0].totalAFaturarColunaSemPeD)}</td> {/* Total  A Faturar coluna {Ano} Sem PeD */}
                                                        <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunas.filter(c => c.ano === ano)[0].totalFaturadoColunaComPeD)}</td> {/* Total Faturado coluna {Ano} Com PeD */}
                                                        <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunas.filter(c => c.ano === ano)[0].totalFaturadoColunaSemPeD)}</td> {/* Total Faturado coluna {Ano} Sem PeD */}
                                                    </React.Fragment>
                                                )
                                            })}
                                            <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunaTotal[0].aFaturarColunaComPeD)}</td> {/* total geral Coluna A Faturar Com PeD */}
                                            <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunaTotal[0].aFaturarColunaSemPeD)}</td> {/* total geral Coluna A Faturar Sem PeD */}
                                            <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunaTotal[0].faturadoColunaComPeD)}</td> {/* total geral Coluna Faturado Com PeD */}
                                            <td key={Math.random()} className='bg-light-blue'>{config.currencyFix(data.totalGeralColunaTotal[0].faturadoColunaSemPeD)}</td> {/* total geral Coluna Faturado Sem PeD */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </React.Fragment>
            </div>
        </div>
    )
}
