import { Component } from "react";
import AuthHelper from './auth/AuthHelper';

export default class Logoff extends Component {
    Auth = new AuthHelper();

    render() {
        if (this.Auth.loggedIn()) {
            localStorage.removeItem('last_url');
            this.Auth.logout();
            this.props.history.replace('/login');
        }

        return null
    }
}
