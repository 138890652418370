import React, { useState, useEffect } from 'react';
import { Content, apiHelper } from '../index';
import { PrestadorForm } from './PrestadorForm';

export function PrestadorView(props) {
    const endPoint = "v2/prestador/";
    const rowId = props.match.params.id;

    const [data, setData] = useState({});
    const fetchData = () => {
        setData([]);
        apiHelper.get(`${endPoint}/${rowId}`).then(res => {
            setData(res.data);
        });
    };

    useEffect(fetchData, [rowId]);

    const handleClose = () => {
        props.history.push({
            pathname: '/prestador/'
        });
    };

    return (
        <Content title={data.nome} browserTitle="Detalhes do Prestador">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-header">
                            <div className="box-tools pull-right">
                                <button title="Sair" type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body">
                            <PrestadorForm rowId={rowId} onHide={handleClose} />
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}