import React, { useState } from 'react';
import { apiHelper, toast, Select,Checkbox, config } from '../index';
import moment from 'moment-timezone';

export function OrcamentoInteracaoForm(props) {
    const endPoint = "portal/crud/ComercialOrcamentoInteracao/";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [anexo, setAnexo] = useState({
        folder: config.getFolderModuloOportunidade(),
        fileName: '',
        file: {}
    });
    let usuarioLogadoId = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "anexo") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setAnexo({ ...anexo, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                setAnexo({ ...anexo, folder: anexo.folder, fileName: fileName, file: file });
                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        data.criadorId = usuarioLogadoId;
        data.orcamentoId = rowId;

        if (anexo.file !== '') {
            apiHelper.upload('anexo/upload', anexo.file, anexo.folder, anexo.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        apiHelper.post(endPoint, data)
            .then(res => {
                config.httpResult(res.data);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Tipo Interação *</label>
                    <Select id="tipoInteracao" className="form-control" onChange={handleChange} value={data.tipoInteracao} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/PortalMetadata?filter=categoria::equal::TipoInteracaoOrcamento`} valuefield="valor" textfield="nome" />
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao} required />
                </div>
            </div>
            <div className="row">
                <div className="form-group" style={{paddingLeft: '16px'}}>
                    <label>Anexo</label>
                    <input type="file" id="anexo" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12" style={{paddingLeft: '16px'}}>
                    <Checkbox id="visivelCliente" onClick={handleChange}>Visivel cliente</Checkbox>
                </div>
            </div>
            <div className="row">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
