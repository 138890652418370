import React, { useState, useEffect } from 'react';
import { Content, apiHelper } from '../index';
import { ReportForm } from './ReportForm';
import { ReportView } from './ReportView';

// const schema = {
//     search: { name: "name", keys: "name", placeholder: "Pesquisa...", criteria: "like", combinator: "and", value: "" },
//     filters: {
//         cliente: { type: "select", placeholder: "Tipo", criteria: "equal", combinator: "and", value: "", source: "column" }
//     },
//     pagination: { size: 10 },
//     model: {
//         id: { type: "text", label: 'ID' },
//         nome: { type: "text", label: 'Nome' },
//         usuario: { type: "text", label: 'Usuário' },
//         email: { type: "text", label: 'Descrição' },
//         isExcluido: { type: "bool", label: 'Excluído' }
//     }
// }

export function ReportAdm(props) {
    const endPoint = 'portal/crud/Report';
    const [reportId, setReportId] = useState(0);
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});

    const [params, setParam] = useState({ showModal: false });

    const fetchData = (where) => {
        if (!where)
            where = "";

        // const usuarioLogadoId = localStorage.getItem('claims') !== null
        //     && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

        apiHelper.get(`${endPoint}?filter=`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, [row]);

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    const handleSelectReport = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let obj = data.filter(f => f.id.toString() === value.toString())
        setReportId(value)
        setRow(obj[0]);
    }

    const handleShowForm = () => {
        setParam({ ...params, showModal: true });
    };

    return (
        <Content title="Relatórios" browserTitle="Relatórios">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-primary card-outline">
                        <div className="card-header p-2">
                            <div className="float-left">
                                <div className="btn-group mr-1">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-default"><i className="fas fa-chart-pie" /></button>
                                        </div>
                                        <select name="report" className="form-control form-control-sm" onChange={handleSelectReport}>
                                            <option value="">Selecione</option>
                                            {data && data.sort((a, b) => a.nome < b.nome ? -1 : 1).map((item, i) =>
                                                <option key={i} value={item.id}>{item.nome}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <button className="btn btn-default btn-sm mr-1" onClick={() => setParam({ ...params, showModal: true })} disabled={reportId === 0}><i className="fas fa-edit" /> Editar</button>
                                <button className="btn btn-default btn-sm"><i className="fas fa-sync-alt" /></button>
                            </div>
                            <div className="float-right">
                                <div className="btn-group">
                                    <button className="btn btn-primary btn-sm" onClick={handleShowForm}>Criar Relatório</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {params.showModal ? <ReportForm parentId={reportId} onHide={handleHide} />
                        :
                        row.id ?
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">{row.nome}</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    {row.id && <ReportView reportId={reportId} admin={true} />}
                                </div>
                            </div>
                            :
                            <div className="card">
                                <div className="card-body text-center">Nenhum relatório para exibir!</div>
                            </div>
                    }
                </div>
            </div>
        </Content>
    )
}