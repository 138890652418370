import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, Modal, Select, toast, fileHelper } from '../';
import { SiteForm } from './SiteForm';
import { MaterialForm } from './MaterialForm';
import { config } from '../../_helpers'
import moment from 'moment-timezone';

let where = '';
export function Site(props) {
    const endPoint = 'comercial/site/';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        cnpj: '',
        ativo: '',
        idCliente: ''
    });

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 1,
        endRow: 10
    });

    const fetchData = (where, page = 0, init = 1, end = 10) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=${where}&initRow=${init}&endRow=${end}`).then(res => {
            setData(res.data.data.data);

            setParam({
                ...params,
                rowId: 0,
                showModal: false,
                modalTitle: "",
                formName: "",
                totalPages: res.data.data.totalRegistros,
                selectedPage: page,
                initRow: init,
                endRow: end
            });
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    };

    const HandleExcel = () => {
        try {
            apiHelper.get(`${endPoint}?filter=${where}&initRow=${1}&endRow=${params.totalPages}`).then(res => {
                let dataExcel = [];

                res.data.data.data && res.data.data.data.map((item, i) => {
                    let obj = {
                        'ID': item.id,
                        'Site': item.nome,
                        'CNPJ': item.cnpj,
                        'Endereço': item.endereco.logradouro + ' ' + item.endereco.numero + ' ' + item.endereco.bairro + ' ' + item.endereco.cidade,
                        'Cliente': item.cliente.nome,
                        'Status': item.ativo ? 'Ativo' : 'Inativo'
                    }
                    dataExcel.push(obj);
                    return null;
                });
                fileHelper.exportToCSV(dataExcel, 'SITES_' + moment().format("DD/MM/YYYY"));
            });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    }

    const handleSearch = () => {
        where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'nome')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataCompraInicio') {
                where += `${'dataCompra'}::greaterEqual::${value2};;AND;;${'dataCompra'}::lessEqual::${filter.dataCompraFim} 23:59:59;;AND;;`;
            }
            else if (key !== 'dataCompraFim')
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            cnpj: '',
            ativo: '',
            idCliente: ''
        });

        where = '';
        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = (page * params.pageSize) + 1;
        let end = (page * params.pageSize) + params.pageSize;

        fetchData(where, page, init, end);
    };

    const renderPage = () => {
        const pages = Math.ceil(params.totalPages / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + params.totalPages + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Sites de Clientes" browserTitle="Sites de Clientes">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                {config.validarClaim('site.escrita') && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("site", "Novo Site", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Site</button>}
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => HandleExcel()} style={{ marginRight: "5px" }}><i className="fa fa-file-excel-o" /> Exportar</button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>Id</label>
                                                                <input id="id" type="number" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Site</label>
                                                                <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>CNPJ</label>
                                                                <input id="cnpj" type="number" className="form-control input-sm" onChange={handleChange} value={filter.cnpj} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Status</label>
                                                                <select id="ativo" className="form-control input-sm" onChange={handleChange} value={filter.ativo}>
                                                                    <option key="" value="">Todos</option>
                                                                    <option value="true">Ativo</option>
                                                                    <option value="false">Inativo</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Cliente</label>
                                                                <Select id="idCliente" className="form-control input-sm" onChange={handleChange} value={filter.idCliente}>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint={`comercial/site/clientes`} valuefield="id" textfield="nome" />
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-tools pull-right" style={{ marginTop: "1px" }}>
                                    {renderPage()}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th>#</th>
                                            <th>ID</th>
                                            <th>Site</th>
                                            <th>CNPJ</th>
                                            <th>Endereço</th>
                                            <th>Cliente</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </button>
                                                                <ul className="dropdown-menu" role="menu">
                                                                    <li><Link replace to="#" onClick={() => handleShowForm("site", "Editar Site", item.id)}><i className="fa fa-pencil" /> Editar Site</Link></li>
                                                                    <li><Link replace to="#" onClick={() => handleShowForm("materialSite", "Novo Material", item.id)}><i className="fa fa-pencil" /> Adicionar Material Site</Link></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td><Link to={'/cliente/site/' + item.id}>{item.nome}</Link></td>
                                                        <td>{item.cnpj}</td>
                                                        <td>{item.endereco.logradouro + ' ' + item.endereco.numero + ' ' + item.endereco.bairro + ' ' + item.endereco.cidade}</td>
                                                        <td>{item.cliente.nome}</td>
                                                        <td>{item.ativo ? <span className="label label-success">Ativo</span> : <span className="label label-warning">Inativo</span>}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal}  onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'site' && <SiteForm parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'materialSite' && <MaterialForm parentId={0} onHide={handleHide} siteId={params.rowId} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
