import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Select, Modal, toast } from '../../index';
import { GrupoForm } from './GrupoForm';

export function Grupo(props) {
    const endPoint = 'portal/crud/IdentityGroup';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id:'',
        name: '',
        enabled: '',
    });

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'name')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };
    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}/id`).then(res => {
            config.httpResult(res.data);
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            name: '',
            enabled: '',
        });

        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Grupo" browserTitle="Grupo">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("grupo", "Novo Grupo", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Grupo</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue color-palette">
                                                <th style={{ width: "50px" }}>#</th>
                                                <th style={{ width: "120px" }}>ID</th>
                                                <th>Nome</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                                <td><input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} /></td>
                                                <td><input id="name" type="text" className="form-control input-sm" onChange={handleChange} value={filter.name} /></td>
                                                <td>
                                                    <Select id="enabled" className="form-control" onChange={handleChange} value={filter.enabled}>
                                                        <option value="">Todos</option>
                                                        <option value="true">Ativo</option>
                                                        <option value="false">Inativo</option>
                                                    </Select>
                                                </td>
                                            </tr>
                                            {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ width: '50px' }}>
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" />
                                                                    </button>
                                                                    <ul className="dropdown-menu" role="menu">
                                                                        <li><Link replace to="#" onClick={() => handleShowForm("grupo", "Editar grupo", item.id)}><i className="fa fa-pencil" /> Editar grupo</Link></li>
                                                                        <li><Link replace to="#" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" />Remover</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td><Link to={'/identity/v2/group/' + item.id}>{item.id}</Link></td>
                                                            <td>{item.name}</td>
                                                            <td>{item.enabled? 'Ativo' : 'Inativo'}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'grupo' && <GrupoForm parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
