import React, { useEffect, useState } from 'react';
import { config, apiHelper, fileHelper, Link } from '../index';

export function OrcamentoResult(props) {
    const endPointProjeto = 'v2/projeto/';
    const projetoId = props.projetoId;
    const [data, setData] = useState([]);

    const fetchData = () => {
        apiHelper.get(endPointProjeto + projetoId + '/aprovacaoOrcamento')
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const obterDescricaoResultado = (tipo) => {
        switch (tipo.toLowerCase()) {
            case 'locacao':
                return 'Locação';
            case 'venda':
                return 'Venda';
            case 'serviconaorecorrente':
                return 'Instalação';
            case 'servicorecorrente':
                return 'Manutenção';
            default:
                return '';
        }
    }

    const downloadAnexo = (fileName) => {
        fileHelper.download('Proposta', fileName);
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <tbody>
                                <tr>
                                    <th>Orçamento:</th>
                                    <td><Link to={'/v2/orcamento/' + data.propostaComercialId}>Número {data.propostaComercialId}</Link></td>
                                </tr>
                                <tr>
                                    <th>Tipo Orçamento:</th>
                                    <td>{data.tipoOrcamento}</td>
                                </tr>
                                <tr>
                                    <th>Proposta Comercial:</th>
                                    <td>{data.anexoPropostaComercial && <Link to="#" onClick={() => downloadAnexo(data.anexoPropostaComercial)} style={{ color: 'blue', textDecoration: 'none' }}>{data.anexoPropostaComercial}</Link>}</td>
                                </tr>
                                <tr>
                                    <th>Aceite Formal:</th>
                                    <td>{data.anexoAceiteFormalCliente && <Link to="#" onClick={() => downloadAnexo(data.anexoAceiteFormalCliente)} style={{ color: 'blue', textDecoration: 'none' }}>{data.anexoAceiteFormalCliente}</Link>}</td>
                                </tr>
                                <tr>
                                    <th>Contrato:</th>
                                    <td>{data.anexoContrato && <Link to="#" onClick={() => downloadAnexo(data.anexoContrato)} style={{ color: 'blue', textDecoration: 'none' }}>{data.anexoContrato}</Link>}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped" style={{ whiteSpace: "nowrap", marginBottom: 0 }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th>Tipo</th>
                                    <th>Custo</th>
                                    <th>Resultado</th>
                                    <th>Prazo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.resumo && data.resumo.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><b>{obterDescricaoResultado(item.tipo)}</b></td>
                                            <td>{config.currencyFix(item.investimento)}</td>
                                            <td>{item.target > 0 ? config.currencyFix(item.target) : config.currencyFix(item.resultado)}</td>
                                            <td>{item.prazoContrato}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
