import React, { useState, useEffect } from 'react';
import { Content, apiHelper, config, toast, Modal, Select, fileHelper } from '../';
import { AvulsoForm } from './AvulsoForm';
import moment from 'moment-timezone';

let where = '';
export function CampanhaView(props) {
    const endPoint = 'marketing/campanhas/';
    const parentId = props.match.params.id;
    const [data, setData] = useState({});
    const [emails, setEmails] = useState([]);
    const [emailsAvulsos, setEmailsAvulsos] = useState([]);
    const [filter, setFilter] = useState({
        origem: '',
        nomeDestinatario: '',
        destinatario: '',
        consultorId: '',
        empresa: '',
        grupoEmpresarial: '',
        enviado: ''
    });

    const [params, setParam] = useState({
        showModal: false,
        modalTitle: 'Novo Contato Avulso',
        rowId: '',
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}${parentId}`).then(res => {
            try {
                setData(res.data);
            } catch (e) {
                toast.error('Atenção, não foi possível carregar os Registros');
            }
        });
    }

    const fetchEmails = (where) => {
        if (!where)
            where = '';
        
        apiHelper.get(`${endPoint}${parentId}/emails?filter=${where}`).then(res => {
            try {
                let data = [];
                res.data && res.data.map((item, i) => {
                    let obj = {
                        origem: item.origem,
                        nomeDestinatario: item.nomeDestinatario,
                        destinatario: item.destinatario,
                        consultor: item.consultor,
                        empresa: item.empresa,
                        grupoEmpresarial: item.grupoEmpresarial,
                        enviado: item.enviado === null ? -1 : item.enviado  //-1 Aguardando Envio
                    }
                    data.push(obj);
                    return null;
                });

                setEmails(data);
            } catch (e) {
                toast.error('Atenção, não foi possível carregar os Registros');
            }
        });
    }

    const fetchEmailsAvulsos = () => {
        apiHelper.get(`${endPoint}${parentId}/emails/avulsos`).then(res => {
            try {
                setEmailsAvulsos(res.data);
            } catch (e) {
                toast.error('Atenção, não foi possível carregar os Registros');
            }
        });
    }

    const onLoad = () => {
        fetchData();
        fetchEmails();
        fetchEmailsAvulsos();
    }

    useEffect(onLoad, [parentId]);

    const handleClose = () => {
        props.history.push({
            pathname: '/marketing/campanha/'
        });
    }

    const handleRefresh = (avulso) => {
        if (avulso)
            fetchEmailsAvulsos();
        else {
            setFilter({
                origem: '',
                nomeDestinatario: '',
                destinatario: '',
                consultorId: '',
                empresa: '',
                grupoEmpresarial: '',
                enviado: ''
            });
            setData([]);

            fetchEmails();
        }
    };

    const handleShowForm = (acao, id) => {
        setParam({
            ...params, showModal: true,
            modalTitle: acao === 'new' ? 'Novo Contato Avulso' : 'Editar Contato Avulso',
            rowId: id
        });
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}emails/${id}`).then(res => {
            config.httpResult(res);
            fetchEmailsAvulsos();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleDispararCampanha = (id) => {
        if (!window.confirm("Deseja realmente disparar esta Campanha ?"))
            return;

        apiHelper.patch(`${endPoint}disparar/${parentId}`).then(res => {
            config.httpResult(res);
            fetchData();
            fetchEmails();
        }).catch(error => {
            toast.error('Atenção, não foi possível disparar a campanha.');
        });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nomeDestinatario' || key === 'destinatario' || key === 'empresa' || key === 'grupoEmpresarial')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchEmails(where);
    };

    const handleExcelCampanha = () => {
        try {
            apiHelper.get(`${endPoint}${parentId}/extrato`)
                .then(res => {
                    let dataExcel = [];
                    console.log(res.data);
                    res.data.data && res.data.data.map((item, i) => {
                        let obj = {
                            'Nome Contato': item.nomeDestinatario,
                            'Email': item.destinatario,
                            'Grupo Empresarial': item.grupoEmpresarial,
                            'Empresa': item.empresa,
                            'Consultor': item.consultor,
                            'Origem': item.origem,
                            'Enviado': item.enviado,
                        }
                        dataExcel.push(obj);
                        return null;
                    });
                    fileHelper.exportToCSV(dataExcel, 'Campanha_' + moment().format("DD/MM/YYYY"));
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    }

    const handleHide = (refresh = true) => {
        setParam({ ...params, showModal: false });
        refresh && fetchEmailsAvulsos();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(emails.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(emails.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + emails.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Campanhas" bdataserTitle="EMA">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Informações da Campanha - {parentId}</h3>

                            <div className="box-tools pull-right">
                                <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>
                                    <tr>
                                        <th>Nome</th>
                                        <td>{data.nome}</td>
                                    </tr>
                                    <tr>
                                        <th>Data Criação</th>
                                        <td>{config.dateFix(data.dataCriacao)}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{data.status}</td>
                                    </tr>
                                    <tr>
                                        <th>Tipo Destinatário</th>
                                        <td>{data.tiposDestinatarios}</td>
                                    </tr>
                                    {data.dataDisparo && <tr>
                                        <th>Data Disparo</th>
                                        <td>{config.dateTimeFix(data.dataDisparo)}</td>
                                    </tr>}
                                    <tr>
                                        <th>Descrição</th>
                                        <td>{data.descricao}</td>
                                    </tr>
                                    {data.status === 'Novo' &&
                                        <tr style={{ textAlign: "center" }}>
                                            <td colSpan="2"><button type="button" className="btn btn-default btn-block" onClick={() => handleDispararCampanha()} style={{ marginRight: "5px" }}><i className="fa fa-arrow-righ" />Disparar Campanha</button></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_Email" data-toggle="tab">E-mails</a></li>
                            <li><a href="#tab_Avulso" data-toggle="tab">E-mails Avulsos</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_Email" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                    {data && data.status ==='Finalizado' && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleExcelCampanha()} style={{ marginRight: "5px" }}> <i className="fa fa-file-excel-o" /> Relatório de Envio</button>}
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin">
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>Origem</th>
                                                <th>Contato</th>
                                                <th>Email</th>
                                                <th>Consultor</th>
                                                <th>Empresa</th>
                                                <th>Grupo Empresarial</th>
                                                <th>Status Envio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Select id="origem" className="form-control" onChange={handleChange} value={filter.origem}>
                                                        <option value="">Todos</option>
                                                        <option endpoint={`portal/crud/PortalMetaData?filter=Categoria::equal::LeadOrigem;;AND;;Nome::in::FTI,TPerson;;`} valuefield="nome" textfield="nome" />
                                                    </Select>
                                                </td>
                                                <td><input id="nomeDestinatario" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nomeDestinatario} /></td>
                                                <td><input id="destinatario" type="text" className="form-control input-sm" onChange={handleChange} value={filter.destinatario} /></td>
                                                <td>
                                                    <Select id="consultorId" className="form-control" onChange={handleChange} value={filter.consultorId}>
                                                        <option value="">Todos</option>
                                                        <option endpoint="comercial/pessoa/perfil?perfil=AnalistaVeolink" valuefield="id" textfield="nome" />
                                                    </Select>
                                                </td>
                                                <td><input id="empresa" type="text" className="form-control input-sm" onChange={handleChange} value={filter.empresa} /></td>
                                                <td><input id="grupoEmpresarial" type="text" className="form-control input-sm" onChange={handleChange} value={filter.grupoEmpresarial} /></td>
                                                <td>
                                                    <Select id="enviado" className="form-control" onChange={handleChange} value={filter.enviado}>
                                                        <option value="">Todos</option>
                                                        <option endpoint="portal/crud/PortalMetaData?filter=Categoria::equal::MarketingStatusEnvio" valuefield="valor" textfield="nome" />
                                                    </Select>
                                                </td>
                                            </tr>
                                            {emails.sort((a, b) => a.id < b.id ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.origem}</td>
                                                            <td>{item.nomeDestinatario}</td>
                                                            <td>{item.destinatario}</td>
                                                            <td>{item.consultor}</td>
                                                            <td>{item.empresa}</td>
                                                            <td>{item.grupoEmpresarial}</td>
                                                            <td>{item.enviado}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>

                            <div id="tab_Avulso" className="tab-pane">
                                <div style={{ marginBottom: "7px" }}>
                                    {data.status === 'Novo' ? <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("new", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Avulso</button>
                                        : <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>}
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>Origem</th>
                                                <th>Contato</th>
                                                <th>Email</th>
                                                <th>Consultor</th>
                                                <th>Status Envio</th>
                                                {data.status === 'Novo' && <th></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {emailsAvulsos.sort((a, b) => a.id < b.id ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.origem}</td>
                                                            <td>{item.nomeDestinatario}</td>
                                                            <td>{item.destinatario}</td>
                                                            <td>{item.consultor}</td>
                                                            <td>{item.enviado}</td>
                                                            {data.status === 'Novo' && item.avulso &&
                                                                <td style={{ width: '90px' }}>
                                                                    <div className="btn-toolbar">
                                                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm('edit', item.id)}> <i className="fa fa-edit" /></button>
                                                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.id)}> <i className="fa fa-trash" /></button>
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh(true)}> <i className="fa fa-refresh" /> Atualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AvulsoForm onHide={() => handleHide(true)} parentId={parentId} rowId={params.rowId} />
                </Modal.Body>
            </Modal>
        </Content>
    )
}
