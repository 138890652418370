import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Portal from './components/Portal';
import Login from './components/Login';
import Logoff from './components/Logoff';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logoff" component={Logoff} />
                <Route path="/" component={Portal} />
            </Switch>

            <ToastContainer />
        </React.Fragment>
    );
}
