import React from 'react';
import { Formatter } from './';

export const Recursive = (children, row, parent, action) => {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) return child;

        let value = "";

        if (child.props && child.props.field)
            value = row[child.props.field];

        if (value !== undefined && value !== "")
            value = Formatter.ValueFormat(child.props.formatter, value);

        value = !value ? child.props.children : value;

        if (child.type === "card") {
            child = React.cloneElement(<div />, {
                ...child.props,
                className: "card " + (child.props.className || ""),
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "header") {
            child = React.cloneElement(<div />, {
                ...child.props,
                className: `card-header ${child.props.className || ''}`,
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "title") {
            child = React.cloneElement(child, {
                ...child.props,
                className: `card-header ${child.props.className || ''}`,
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "tools") {
            child = React.cloneElement(<div />, {
                ...child.props,
                className: "card-tools " + (child.props.className || ""),
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "body") {
            child = React.cloneElement(<div />, {
                ...child.props,
                className: "card-body " + (child.props.className || ""),
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "row") {
            child = React.cloneElement(<div />, {
                ...child.props,
                className: "row " + (child.props.className || ""),
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "col") {
            child = React.cloneElement(<div />, {
                ...child.props,
                className: "col " + (child.props.className || ""),
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "icon") {
            return <i className={`fas fa-${child.props.name}`} />
        }
        else if (child.type === "img") {
            return ""; //<Image {...child.props} src={value} />
        }
        else if (child.type === "btn") {
            child = React.cloneElement(<button />, {
                ...child.props,
                className: `btn btn-${child.props.color ? child.props.color : 'default'} btn-sm btn-flat mr-1`,
                children: Recursive(child.props.children, row, parent, action)
            });
        }
        else if (child.type === "btn_tool") {
            return (
                <button className="btn btn-tool" onClick={child.props.action ? () => action({ action: child.props.action }) : child.props.onClick}>
                    <i className={`fas fa-${child.props.icon}`} />
                </button>
            )
        }
        else if (child.type === "listview") {
            child = (
                <div className="row p-2">
                    {child.props.data.map((row, i) =>
                        <div key={i} className="col-12 col-sm-12 col-md-4 d-flex align-items-stretch flex-column">
                            {Recursive(child.props.children, row, child)}
                        </div>
                    )}
                </div>
            )
        }
        else if (child.type === "list") {
            if (!child.props.data) return null;

            child = (
                <div className="row p-2">
                    {child.props.data.map((row, i) =>
                        <div key={i} className="col-12 col-sm-12 col-md-4 d-flex align-items-stretch flex-column">
                            {Recursive(child.props.children, row, child)}
                        </div>
                    )}
                </div>
            )
        }
        else if (child.type === "datatable") {
            let data = child.props.data;

            if (!data || data.length === 0)
                return <center>Nenhum registro para exibir!</center>;

            let fields = child.props.children;
            let bordered = child.props.bordered === true ? ' table-bordered' : '';
            let striped = child.props.striped === true ? ' table-striped' : '';
            let pointer = child.props.onSelect ? { cursor: 'pointer' } : null;
            let classname = `m-0 table${bordered}${striped} ${child.props.className && child.props.className}`;
            let showHead = (child.props.showHead === undefined || child.props.showHead === true) ? true : false;

            return (
                <table className={classname}>
                    {showHead &&
                        <thead>
                            <tr>
                                {React.Children.toArray(fields).map((col, i) =>
                                    <th key={i}>{col.props.title}</th>
                                )}
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {data.map((row, i) =>
                            <tr key={i} style={pointer} onClick={() => child.props.onSelect && child.props.onSelect({ action: child.props.action, id: row["id"] })}>
                                {Recursive(fields, row, child)}
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        }
        else {
            let newChild = child.props.field && !child.props.action ?
                value : Recursive(child.props.children, row, parent, action);

            child = React.cloneElement(child, {
                ...child.props,
                children: newChild,
                onClick: child.props.action
                    ? () => parent.props.onAction({ action: child.props.action, id: row[child.props.field] })
                    : child.props.onClick ? child.props.onClick : null
            });
        }

        return child;
    });
};

