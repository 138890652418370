import React from 'react';
import { Route } from 'react-router-dom';
import { OrdemServico } from './OrdemServico';
import { AtendimentoPanel } from './Atendimento';

const Menu = () => {
    return [
        {
            name: "Atendimento", icon: "fas fa-medkit", order: 10, url: "#", children: [
                { name: "Painel", icon: "fas fa-business-time", order: 0, url: "/atendimento/panel" },
                { name: "Ordem de Serviço", icon: "fas fa-tools", order: 2, url: "/atendimento" },
            ]
        }
    ]
}

const AtendimentoApp = (props) => {
    return (
        <>
            <Route exact path="/atendimento/panel" component={AtendimentoPanel} />
            <Route exact path="/atendimento" component={OrdemServico} />
        </>
    );
};

export default {
    name: 'Atendimento',
    version: '1.0',
    control: <AtendimentoApp />,
    menu: Menu()
};
