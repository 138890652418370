import React, { useState } from 'react';
import { Modals, Button, config, apiHelper, toast } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function RequisicaoMaterialItemForm(props) {
    const endPoint = props.endPoint + 'item';
    const [show, setShow] = useState(props.show);
    const [form, setForm] = useState({
        idMaterial: 0,
        idRequisicaoMaterial: props.parentId,
    });

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.post(endPoint, form).then(res => {
            config.httpResult(res.data);
            onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });        
    }

    const handleAddMaterial = (row) => {        
        apiHelper.get(`${props.endPoint}/unidadeMedida/${row.fkUnidadeMedida}/pkTipoUnidadeMedida`).then(res => {
            setForm({ ...form, idMaterial: row.pkMaterial, descricao: row.nome, quantidade: 1, valor: row.valorReal, unidade: res.data.data.sigla });            
        }).catch(error => {
        });
    }

    const onHide = () => {
        setShow(false);
        props.onHide();
    }

    return (
        <Modals title="Novo Item" show={show} onHide={onHide}>
            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="form-group col-sm-12 col-md-8">
                        <PesquisaGenericaHooks titulo="Material" value={form.descricao} endpoint="portal/crud/material/?filter=nome::contains::" onImport={(row) => { handleAddMaterial(row); }} onHide={() => onHide} />
                    </div>
                
                    <div className="form-group col-sm-12 col-md-4">
                        <label>Quantidade</label>
                        <input type="number" id="quantidade" name="quantidade" className="form-control" onChange={handleChange} value={form.quantidade} required />
                    </div>                    
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Observação</label>
                        <textarea id="observacao" className="form-control" rows="3" onChange={handleChange}></textarea>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="box-tool pull-right">
                            <div className="btn-toolbar">
                                <Button type="submit" bsStyle="success">Salvar</Button>
                                <Button bsStyle="default" onClick={onHide}>Cancelar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modals>
    )
}
