import React, { useEffect, useState } from 'react';
import { config, apiHelper, Modal, Link } from '../index';
import { OrcamentoInteracaoForm } from './OrcamentoInteracaoForm';
import Core from '../Core';

export function OrcamentoInteracao(props) {
    const permissaoEscrita = config.validarClaim('orcamento.escrita');
    const endPoint = 'portal/crud/uvw_v2_comercial_orcamento_interacao';
    const folder = config.getFolderModuloOportunidade();
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=orcamentoId::equal::${parentId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [parentId, props.refresh]);

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
    };

    const downloadAnexo = async (fileName) => {
        await Core.api_download(folder, fileName).then(res => {
        });
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Nova Interação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrcamentoInteracaoForm parentId={parentId} onHide={handleHide} />
                </Modal.Body>
            </Modal>
            <div style={{ marginBottom: "7px" }}>
                {permissaoEscrita && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>}
            </div>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Tipo Interação</th>
                            <th>Descrição</th>
                            <th>Usuário</th>
                            <th>Data Cadastro</th>
                            <th>Anexo</th>
                            <th>Visível Cliente</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.tipoInteracao}</td>
                                        <td>{item.descricao}</td>
                                        <td>{item.nome}</td>
                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                        <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                        <td>{item.visivelCliente ? "Sim" :"Não"}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
