import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select } from '../index';

export function ReportForm(props) {
    const endPoint = "portal/crud/Report/";
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const fetchData = () => {
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
                setData(res.data.data);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            data.criador = config.idUsuarioLogado();
            apiHelper.post(`${endPoint}`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    } else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/id`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    } else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleDelete = () => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${data.id}/id`).then(res => {
            toast.success('Dados excluídos com sucesso!');

            props.onHide(true);
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="card card-primary card-outline">
                <div className="card-header">
                    <h3 className="card-title">{data.nome || "Novo Relatório"}</h3>
                </div>
                <div className="card-body p-2">
                    <div className="row">
                        <div className="form-group col-md-2">
                            <label>Tipo</label>
                            <input id="tipo" type="text" className="form-control" onChange={handleChange} value={data.tipo || ''} />
                        </div>
                        <div className="form-group col-md-2">
                            <label>Grupo</label>
                            <input id="grupo" type="text" className="form-control" onChange={handleChange} value={data.grupo || ''} />
                        </div>
                        <div className="form-group col-md-3">
                            <label>Nome *</label>
                            <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ''} required />
                        </div>
                        <div className="form-group col-md-3">
                            <label>Título *</label>
                            <input id="titulo" type="text" className="form-control" onChange={handleChange} value={data.titulo || ''} required />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Permissões</label>
                            <input id="claims" type="text" className="form-control" onChange={handleChange} value={data.claims || ''} />
                        </div>

                        <div className="form-group col-md-6">
                            <label>Caminho </label>
                            <input id="caminho" type="text" className="form-control" onChange={handleChange} value={data.caminho || ''} />
                        </div>
                        <div className="form-group col-md-12">
                            <label>Query </label>
                            <textarea id="query" className="form-control" rows="3" onChange={handleChange} value={data.query} />
                        </div>
                        <div className="form-group col-sm-12">
                            <label>Filtro</label>
                            <textarea id="filtro" className="form-control" rows="3" onChange={handleChange} value={data.filtro || ''} />
                        </div>
                        <div className="form-group col-sm-12">
                            <label>Configuração</label>
                            <textarea id="config" className="form-control" rows="3" onChange={handleChange} value={data.config || ''} />
                        </div>
                        <div className="form-group col-sm-12">
                            <label>DataSource</label>
                            <Select id="source_id" className="form-control" onChange={handleChange} value={data.source_id}>
                                <option value="">Todos</option>
                                <option endpoint="portal/crud/ReportsSource" valuefield="id" textfield="name" />
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="card-footer p-2">
                    <div className="float-right">
                        <button type="submit" className="btn btn-primary ml-1">Salvar</button>
                        <button type="button" className="btn btn-default ml-1" onClick={() => props.onHide(true)}>Fechar</button>
                        {data.id && <button type="button" className="btn btn-default ml-1" onClick={handleDelete}>Excluir</button>}
                    </div>
                </div>
            </div>
        </form>
    )
}