import React, { useState, useEffect } from 'react';
import { apiHelper, config } from '../../';

export function TicketView(props) {
    const id = props.id;
    const [data, setData] = useState({});

    useEffect(() => {
        const getRow = () => apiHelper.get(`v3/os/${id}`).then(res => setData(res.data));
        getRow();
    }, [id]);

    return (
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">{data.site || "Nenhuma os selecionada!"}</h3>
                <div className="card-tools">
                    {data.id}
                </div>
            </div>
            <div className="card-body p-2">
                {data.id &&
                    <table className="table table-striped table-bordered no-margin">
                        <tbody>
                            <tr>
                                <th>Abertura</th>
                                <td>{data && config.dateTimeFix(data.dataCadastro)}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>{data && data.status}</td>
                            </tr>
                            <tr>
                                <th colSpan={2}>
                                    <b>Tipo Chamado:</b><br />
                                    <div className="row">
                                        <div className="form-group col-md-10" style={{ marginBottom: 0, paddingRight: 0 }}>

                                        </div>
                                        <div className="form-group col-md-2" style={{ marginBottom: 0, paddingLeft: 5 }}>

                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Regional  Padrão:</b><br />
                                    {data.regionalPadrao}
                                </td>
                            </tr>

                            <tr>
                                <th>SLA Nível 1</th>
                                <td>{data && config.timeConvert(data.tempoDecorridoNivel1)} de {data && data.nivel1}</td>
                            </tr>

                            {(data && data.nivel2 > 0) && <tr>
                                <th>SLA Nível 2</th>
                                <td>{data && config.timeConvert(data.tempoDecorridoNivel2)} de {data && data.nivel2}</td>
                            </tr>}
                            {(data && data.nivel3 > 0) && <tr>
                                <th>SLA Nível 3</th>
                                <td>{data && config.timeConvert(data.tempoDecorridoNivel3)} de {data && data.nivel3}</td>
                            </tr>}
                            {(data && data.slaSolicitacao > 0) && <tr>
                                <th>SLA Solicitação</th>
                                <td>{data && config.timeConvert(data.tempoDecorridoNivelSolicitacao)} de {data && data.slaSolicitacao}</td>
                            </tr>}
                            {(data && data.slaPreventiva > 0) && <tr>
                                <th>SLA Preventiva</th>
                                <td>{data && config.timeConvert(data.tempoDecorridoNivelPreventiva)} de {data && data.slaPreventiva}</td>
                            </tr>}
                            <tr>
                                <td colSpan={2}>
                                    <b>Cliente:</b><br />
                                    {data.cliente}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Cód. Externo Cliente: </b>{data.codigoExternoCliente}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Site:</b><br />
                                    {data.site}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Endereço:</b><br />
                                    {data.enderecoSite}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Resumo:</b><br />
                                    {data.resumo}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Descrição:</b><br />
                                    {data.descricao}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}
