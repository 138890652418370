import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

export class ValidaForm extends Component {
   
    render() {
        const { array } = this.props;
        let display = array !== null && array !== undefined && array.length > 0 ? '' : 'none';
        
        return (
            <div style={{ display: display }}>
                <Row>
                    <Col md={12}>
                        <div className="box-body" >
                            <div className="alert alert-danger alert-dismissible">
                                <h4><i className="icon fa fa-ban" /> Valida&ccedil;&atilde;o!</h4>
                                <ul key={1}>
                                    {array !== null && array !== undefined && array.length > 0 && array.map(item => {
                                        return <li key={item}>Informe o campo {item}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}