import React, { useState, useEffect } from 'react';
import { Modals, Button, config, apiHelper, toast } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';

export function RequisicaoMaterialTarefaForm(props) {
    const endPoint = 'estoque/requisicaoMaterial/tarefa/';
    const [show, setShow] = useState(props.show);
    const tarefaId = props.tarefaId;
    const [form, setForm] = useState({
        idRequisicaoMaterial: props.parentId
    });

    const fetchData = () => {
        if (tarefaId && tarefaId > 0) {
            apiHelper.get(`${endPoint}${tarefaId}`)
                .then(res => {
                    setForm(res.data.data);
                });
        }
    }

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, [tarefaId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "status" && value === "finalizado") {
            let data = config.dateFix(new Date());
            setForm({ ...form, conclusao: data, status: value });
        } else {
            setForm({ ...form, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (tarefaId && tarefaId > 0) {
            apiHelper.put(`${endPoint}${tarefaId}`, form).then(res => {
                config.httpResult(res.data);
                onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.post(endPoint, form).then(res => {
                config.httpResult(res.data);
                onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const handleAddResponsavel = (row) => {
        setForm({ ...form, responsavel: row.nome });
    }

    const onHide = () => {
        setShow(false);
        props.onHide();
    }

    return (
        <Modals title="Nova Tarefa" show={show} onHide={onHide}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-sm-12 col-md-12">
                        <label>Nome</label>
                        <input type="text" id="nome" name="nome" className="form-control" onChange={handleChange} value={form.nome} required />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-12 col-md-12">
                        {(tarefaId === 0 || form.responsavel) && <PesquisaGenericaHooks titulo="Responsável" endpoint="portal/crud/usuario/?filter=nome::contains::" selecao={form.responsavel} onImport={(row) => { handleAddResponsavel(row); }} onHide={() => setForm({ ...form, pkUsuario: 0, responsavel: '' })} />}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="status">Status</label>
                        <select id="status" className="form-control" onChange={handleChange} value={form.status}>
                            <option value="">Selecione</option>
                            <option value="novo">Novo</option>
                            <option value="andamento">Em andamento</option>
                            <option value="finalizado">Finalizado</option>
                        </select>
                    </div>

                    <DataPtBrComponent Col={6} labelData={"Previsão"} id="previsao" valueData={form.previsao} onSelectValue={handleChange} />
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="box-tool pull-right">
                            <div className="btn-toolbar">
                                <Button type="submit" bsStyle="success">Salvar</Button>
                                <Button bsStyle="default" onClick={onHide}>Cancelar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modals>
    )
}
