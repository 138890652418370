import React, { Component } from 'react';
import {
    Modals, FieldGroup, Table, Button,
    ButtonToolbar, FormGroupButton, FasIcon
} from '../../components/adminlte/AdminLTE';

import { ModalFindDoors } from './ModalFindDoors';
import { toast } from 'react-toastify';

export class ChannelDoor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            tableDoors: [],
            tableDoorsChannel: [],
            deviceChannelCode: '',
            deviceChannelItem: {},
            channels: [],
            doorCode: 0,
            showModalDoors: false,
            showFormFindDoors: false
        };

        this.handleChangeChannel = this.handleChangeChannel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ keyValue: newProps.keyValue });
        this.getDoorsByDevice(newProps.keyValue);
        this.getChannels(newProps.keyValue);
    }

    getChannels(serialNumber) {
        if (serialNumber === '')
            return;

        this.setState({ table: [] });

        const { apiUrl, token } = this.props.config;

        fetch(`${apiUrl}${serialNumber}/channel?serialNumber=${serialNumber}`, {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ channels: data });
        });
    }

    getDoorsByDevice(keyValue) {
        if (keyValue === undefined)
            return;

        const { apiUrl, token } = this.props.config;

        fetch(apiUrl + keyValue + '/doors', {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ tableDoors: data.doors, tableDoorsChannel: data.doorsFromChannel });
        });
    }

    handleChangeChannel(e) {
        let value = e.target["value"];
        let obj = this.state.channels.filter((e) => e.deviceChannelCode === value);

        this.setState({ deviceChannelCode: value, deviceChannelItem: obj[0] });
    }

    handleAddDoor(row) {
        this.setState({ doorCode: row.objectID, doorSearch: row.name });
    }

    handleSubmit(e) {
        const { apiUrl, token } = this.props.config;

        let doorChannel = {
            channelCode: this.state.deviceChannelCode,
            doorCode: this.state.doorCode,
        };

        fetch(apiUrl + 'channel/door', {
            method: 'PUT',
            body: JSON.stringify(doorChannel),
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.setState({ isSaving: true, showModalDoors: false });
                this.getDoorsByDevice(this.state.keyValue);
            }
            else {
                toast.warn(response[1]["msg"]);
                this.setState({ isSaving: false });
            }
        }).catch(error => {
            this.setState({ isSaving: false });
            toast.error(error);
        });
    }

    onAction() {
        this.setState({ showModalDoors: true })
    }

    onHide() {
        this.getDoorsByDevice(this.state.keyValue);
        this.setState({ showModalDoors: false, doorSearch: '' });
    }

    remove(row) {
        const { config } = this.props;
        console.log(row);
        fetch(config.apiUrl + row.channelCode + '/door/' + row.doorCode, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados removidos com sucesso!');
                this.getDoorsByDevice(this.state.keyValue);
            }
            else
                toast.warn(response[1]["msg"]);
        }).catch(error => {
            toast.error(error);
        });
    }

    render() {
        const { config, row } = this.props;

        return (
            <React.Fragment>
                <Button bsSize="small" bsStyle="default" onClick={() => this.onAction()}>Adicionar Porta</Button>
                <Table striped bordered hover>
                    <tbody>
                        {this.state.tableDoorsChannel !== undefined && this.state.tableDoorsChannel.length > 0 && this.state.tableDoorsChannel.map(row => {
                            return (
                                <tr key={row.id}>
                                    <td>{row.nameChannel}</td>
                                    <td>{row.doorName}</td>
                                    <td style={{ width: "40px" }}>
                                        <Button bsSize="xs" bsStyle="danger" onClick={() => this.remove(row)}><FasIcon name="trash" /></Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Modals title="Adicionar Portas" show={this.state.showModalDoors} onHide={() => this.onHide()}
                    footer={
                        <ButtonToolbar>
                            <Button bsStyle="success" onClick={this.handleSubmit}>Salvar</Button>
                            <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                        </ButtonToolbar>}>

                    <div style={{ minHeight: "200px" }}>
                        <FieldGroup id="deviceChannelCode" componentClass="select" label="Canais" value={this.state.deviceChannelCode} onChange={this.handleChangeChannel}>
                            <option value="Selecione">Selecione</option>
                            {this.state.channels.map(item => {
                                return (
                                    <option key={item.deviceChannelCode} name={item.name} value={item.deviceChannelCode}>{item.name}</option>
                                );
                            })}
                        </FieldGroup>

                        <FormGroupButton placeholder="Portas" label="Portas" disabled value={this.state.doorSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showFormFindDoors: true })}>Selecionar</Button>}
                        />
                    </div>

                    {this.state.showFormFindDoors && <ModalFindDoors config={config} deviceChannelItem={this.state.deviceChannelItem} show={this.state.showFormFindDoors} onImport={(row) => { this.handleAddDoor(row); }} row={row} onHide={() => this.setState({ showFormFindDoors: false })} />}
                </Modals>
            </React.Fragment>
        );
    }
}