import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, Modal, toast } from '../';
import { AgendamentoForm } from './AgendamentoForm';
import { AgendamentoView } from './AgendamentoView';

export function Agendamento(props) {
    const rowId = props.match.params.id ? props.match.params.id : 0;
    const endPoint = 'portal/crud/Agendamento';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState();

    const [params, setParam] = useState({
        showModal: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            if (res.data.code === 200)
                setData(res.data.data);
            else
                toast.error('Atenção, não foi possível carregar as requisições');
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
        fetchData("nome::contains::" + value);
    };

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = (id) => {
        if (id) {
            props.history.push({
                pathname: `/agendamento/${id}`
            });
        }

        console.log(id);

        setParam({ ...params, showModal: false });
        fetchData();
    };

    const handleRefresh = () => {
        setFilter({});
        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Agendamentos" browserTitle="Agendamentos">
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Novo Agendamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AgendamentoForm rowId={0} onHide={handleHide} />
                </Modal.Body>
            </Modal>

            <div className="row">
                <div className="col-md-4">
                    <div className="box">
                        <div className="box-header with-border" style={{ padding: 5 }}>
                            <div className="input-group">
                                <div className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew} style={{ marginRight: 4 }}><i className="fa fa-plus" /> Novo</button>
                                </div>
                                <input type="text" className="form-control input-sm" placeholder="Pesquisa" onChange={handleChange} />
                                <i className="fa fa-search form-control-feedback" />
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin">
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th>ID</th>
                                            <th>Nome</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.id}</td>
                                                        <td><Link replace to={'/agendamento/' + item.id}>{item.nome}</Link></td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="box-footer" style={{ padding: 5 }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>

                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {rowId > 0 ?
                        <AgendamentoView rowId={rowId} onHide={handleHide} />
                        :
                        <div className="alert alert-info">
                            <center>Nenhum registro selecionado!</center>
                        </div>
                    }
                </div>
            </div>
        </Content>
    )
}
