import React from 'react';
import { Content, Link } from './adminlte/AdminLTE';

export function Error(props) {

    return (
        <Content title="Erro" browserTitle="Erro">
            <div className="error-page">
                <h2 className="headline text-warning">Erro</h2>
                <div className="error-content">
                    <h3><i className="fas fa-exclamation-triangle text-warning"></i> Acesso não autorizado!</h3>
                    <p> <Link to="/">Página Principal</Link></p>
                </div>
            </div>
        </Content>
    )
}
