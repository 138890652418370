import React, { useState, useEffect } from 'react';
import { apiHelper, GoogleMap } from '../../';
import user_off from '../../../../assets/maps/user-off.png';
import user_on from '../../../../assets/maps/user-on.png';

export function MapaGeral(props) {
    const endPoint = 'portal/crud/uvw_report_user_gps';
    const site = 'portal/crud/uvw_report_site_radius';

    const [locations, setLocations] = useState([]);
    const [sites, setSites] = useState([]);
    const [tecnico, setTecnico] = useState({});

    function formatName(value) {
        if (!value) return;
        let names = value.split(" ");
        return capitalize(names[0]) + " " + capitalize(names[names.length - 1]);
    }

    function capitalize(value) {
        if (!value) return;
        const words = value.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(" ");
    }

    useEffect(() => {
        const fetchData = () => {
            try {
                apiHelper.get(`${endPoint}`).then(res =>
                    res.data.data && setLocations(res.data.data)
                );
                apiHelper.get(`${site}`).then(res =>
                    res.data.data && setSites(res.data.data)
                );
            } catch (error) { }
        }

        fetchData();
        
        setTecnico(props.tecnico);

        const interval = setInterval(() => fetchData(), 5000);
        return () => clearInterval(interval);
    }, [props.tecnico]);

    const initCenter = {
        lat: tecnico.latitude ? parseFloat(tecnico.latitude.replace(',', '.')) : -18.096408,
        lng: tecnico.longitude ? parseFloat(tecnico.longitude.replace(',', '.')) : -50.659592
    }

    return (
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">Mapa Geral</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" onClick={() => setTecnico({})}><i className="fas fa-sync" /></button>
                    <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                </div>
            </div>
            <div className="card-body p-1" style={{ height: 400, overflow: 'auto' }}>
                <GoogleMap height="100%" initCenter={initCenter} zoomLevel={tecnico.longitude ? 14 : 4}>
                    {locations.map((m, i) => {
                        if (m.latitude && m.longitude) {
                            let icon = user_on;
                            if (new Date(m.date) < new Date().setHours(-1)) {
                                icon = user_off
                            }

                            return <maker key={i} position={{
                                icon: icon, lat: m.latitude, lng: m.longitude, title: m.login, label: (tecnico.latitude && m.email === tecnico.email) ? {
                                    text: formatName(m.nome),
                                    color: 'gray',
                                    fontWeight: 'bold'
                                } : ''
                            }} />;
                        }
                        else
                            return null
                    })}
                    {
                        sites.map((m, i) => {
                            return <circle key={i} circlePosition={{ lat: m.latitude, lng: m.longitude, radius: m.raio }} />;
                        })
                    }

                    {
                        sites.map((m, i) => {
                            return <maker key={i} position={{
                                icon: {
                                    path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                    scale: 5,
                                    strokeColor: "red",
                                    strokeOpacity: 0.001,
                                    strokeWeight: 2,
                                    fillColor: "red",
                                    fillOpacity: 0.001,
                                }, lat: m.latitude, lng: m.longitude, title: m.nome
                            }} />;
                        })
                    }
                </GoogleMap>
            </div>
        </div>
    );
}
