import React, { useEffect, useState } from 'react';
import { apiHelper, toast } from '../../index';
import * as _ from 'lodash';
let distinctCategorias = [];

export function GrupoPermissoes(props) {
    const endPointGroupClaim = 'portal/crud/IdentityGroupClaim';
    const endPointGroup = 'identity/Group';
    const nomeGrupo = props.nomeGrupo;
    const id = props.parentId;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        description: ''
    });
    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPointGroup}/Permissoes/${id}?filter=${where}`).then(res => {
            let data = res.data.data;
            let nomesCategoria = data.map((item, i) => { return item.category });
            distinctCategorias = nomesCategoria.filter((v, i, a) => a.indexOf(v) === i);

            let dataCategoriasAgrupadas = _.groupBy(data, 'category')
            let dados = [];

            distinctCategorias.map(item => {
                dados.push(dataCategoriasAgrupadas[item]);
                return null;
            });

            setData(dados);
        });
    };

    useEffect(fetchData, [nomeGrupo]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'description')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });
        fetchData(where);
    };

    const handleAdicionar = (item, isAdicionar) => {
        if (!isAdicionar) {
            handleDesassociar(item);
            return;
        }

        let data = {
            claimId: item.id,
            groupId: id
        }
        apiHelper.post(endPointGroupClaim, data).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a interação.');
        });
    }

    const handleDesassociar = (item) => {
        let data = {
            groupName: item.groupName,
            claimDescription: item.description
        }
        apiHelper.api_delete(`${endPointGroup}/${data.groupName}/claim/${data.claimDescription}`).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleRefresh = () => {
        setFilter({
            id: '',
            description: ''
        });

        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>Id</th>
                                    <th>Descrição</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                    <td><input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} /></td>
                                    <td><input id="description" type="text" className="form-control input-sm" onChange={handleChange} value={filter.description} /></td>
                                    <td></td>
                                </tr>
                                {data.length > 0 && distinctCategorias.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td colSpan="4" style={{ background: '#E4E4E4' }}><b>{item}</b></td>
                                            </tr>


                                            {data[i].filter(c => c.category === item).map((item, i) => {
                                                return <tr key={i}>
                                                    <td style={{ width: '50px' }}>#</td>
                                                    <td>{item.id}</td>
                                                    <td>{item.description}</td>
                                                    {item.associado === 0 ? <td><button type="button" className="btn btn-default btn-sm" onClick={() => { handleAdicionar(item, true) }}>Adicionar</button></td>
                                                        :
                                                        <td><button type="button" className="btn btn-danger btn-sm" onClick={() => { handleAdicionar(item, false) }}>Remover</button></td>}
                                                </tr>
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
