import React from 'react';
import { Route } from 'react-router-dom';
import { Home } from './Home';
import { Project } from './Project';

const Menu = () => {
    return [
        {
            claim: "MenuGroupAdmin", name: "Gestão de Projetos", icon: "fas fa-project-diagram", order: 3, url: "#", children: [
                { claim: "MenuGroupAdmin", name: "Dashboard", icon: "fas fa-home", order: 0, url: "/projects" },
                { claim: "MenuGroupAdmin", name: "Projetos", icon: "fas fa-tasks", order: 0, url: "/projects/all" },
            ]
        }
    ]
}

const ProjectApp = (props) => {
    return (
        <>
            <Route exact path="/projects" component={Home} />
            <Route exact path="/projects/all" component={Project} />
        </>
    );
};

export default {
    name: 'Gestão de Projetos',
    version: '1.0',
    control: <ProjectApp />,
    menu: Menu()
};
