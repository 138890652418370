import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Select, Modal, fileHelper, toast } from '../';
import { LeadForm } from './LeadForm';
import { LeadInteracaoForm } from './LeadInteracaoForm';
import { DashboardGraficos } from './DashboardGraficos';
import { LeadAssociarAnalista } from './LeadAssociarAnalista';
import { LeadAssociarAnalistaFTI } from './LeadAssociarAnalistaFTI';
import { LeadContatoForm } from './LeadContatoForm';
import { ExcelImport } from '../../components/controls/ExcelImport';
import moment from 'moment-timezone';

let where = '';
export function Lead(props) {
    const endPoint = 'lead';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        origem: '',
        status: '',
        dataEntrada: '',
        consultorId: '',
        criadorId: '',
        empresa: '',
        grupoEmpresarial: ''
    });

    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 1,
        endRow: 10,
        decisor: ''
    });
    const [mensagemErro, setMensagemErro] = useState('');

    const fetchData = (where, page = 0, init = 1, end = 10) => {
        if (!where)
            where = "";

        apiHelper.get(`Lead/?filter=${where}&initRow=${init}&endRow=${end}&decisor=${params.decisor}`).then(res => {
            setData(res.data.data.data);
            setParam({
                ...params,
                rowId: 0,
                showModal: false,
                modalTitle: "",
                formName: "",
                siteId: "",
                totalPages: res.data.data.totalRegistros,
                selectedPage: page,
                initRow: init,
                endRow: end
            });
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleChangeDecisor = (e) => {
        let value = e.target["value"];
        let name = e.target.id;
        setParam({ ...params, [name]: value });
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: rowId && rowId > 0 ? title + ' ' + rowId : title,
            formName: name
        });
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleHide = (refresh = false) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };
    const handleHideMensagemErro = () => {
        setMensagemErro('');
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            origem: '',
            status: '',
            dataEntrada: '',
            consultorId: '',
            criadorId: '',
            empresa: '',
            grupoEmpresarial: '',
            decisor: ''
        });

        where = '';
        setData([]);
        fetchData();
    };

    const onImportSuccessHandle = () => {
        toast.success('Planilha importada com sucesso.');
    };

    const onImportErrorHandle = (response) => {
        if (response !== undefined)
            setMensagemErro(response.msg);
        else setMensagemErro("Ocorreu um erro inesperado. Por favor entre em contato com o suporte do sistema.");
    };

    const handleSearch = () => {
        where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'empresa' || key === 'grupoEmpresarial')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataEntrada') {
                where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;AND;;`;
            }
            else
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const handleExcel = () => {
        try {
            apiHelper.get(`Lead/?filter=${where}&initRow=${1}&endRow=${params.totalPages}&decisor=${params.decisor}`)
                .then(res => {
                    let dataExcel = [];
                    res.data.data.data && res.data.data.data.map((item, i) => {
                        let obj = {
                            'ID': item.id,
                            'Origem': item.origem,
                            'Status': item.status,
                            'Data Entrada': config.dateFix(item.dataEntrada),
                            'Responsável': item.analistaFTI,
                            'Analista': item.analistaVeolink,
                            'Empresa': item.empresa,
                            'Grupo Econômico': item.grupoEmpresarial,
                            'Total Contatos': item.contatos
                        }
                        dataExcel.push(obj);
                        return null;
                    });
                    fileHelper.exportToCSV(dataExcel, 'FTIGeral_' + moment().format("DD/MM/YYYY"));
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os Registros');
        }
    }

    const renderMensagemErro = () => {
        var msg = mensagemErro.split('||');
        return (
            <div className="col-md-12">
                {msg.map((item, i) => <p key={i} class="text-red">{item}</p>)}
            </div>
        )
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = (page * params.pageSize) + 1;
        let end = (page * params.pageSize) + params.pageSize;

        fetchData(where, page, init, end);
    };

    const renderPage = () => {
        const pages = Math.ceil(params.totalPages / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + params.totalPages + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Lead" browserTitle="Lead">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                            {(config.validarClaim('Controladoria') || config.validarClaim('AnalistaFTI')) && <li><a href="#tab_dash" data-toggle="tab">Dashboard</a></li>}
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("lead", "Novo Lead", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Lead</button>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                            Pesquisar <span className="caret" />
                                        </button>
                                        <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                            <div className="box" style={{ margin: 0 }}>
                                                <div className="box-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="form-group col-md-4">
                                                                    <label>Id</label>
                                                                    <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Origem</label>
                                                                    <Select id="origem" className="form-control" onChange={handleChange} value={filter.origem}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="lead/origem" valuefield="nome" textfield="nome" />
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Status</label>
                                                                    <Select id="status" className="form-control" onChange={handleChange} value={filter.status}>
                                                                        <option value="">Todos</option>
                                                                        <option value="Aguardando Interação">Aguardando Interação</option>
                                                                        <option value="Em prospecção">Em prospecção</option>
                                                                        <option value="Em negociação">Em negociação</option>
                                                                        <option value="Bem sucedida">Bem sucedida</option>
                                                                        <option value="Perdida">Perdida</option>
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Data Entrada</label>
                                                                    <input id="dataEntrada" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataEntrada} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Responsável</label>
                                                                    <Select id="criadorId" className="form-control" onChange={handleChange} value={filter.criadorId}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="comercial/pessoa/perfil?perfil=AnalistaFTI" valuefield="id" textfield="nome" />
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Analista</label>
                                                                    <Select id="consultorId" className="form-control" onChange={handleChange} value={filter.consultorId}>
                                                                        <option value="">Todos</option>
                                                                        {(config.validarClaim('AnalistaFTI') || config.validarClaim('Controladoria'))
                                                                            && <option endpoint="comercial/pessoa/perfil?perfil=AnalistaVeolink" valuefield="id" textfield="nome" />}
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Empresa</label>
                                                                    <input id="empresa" type="text" className="form-control input-sm" onChange={handleChange} value={filter.empresa} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Grupo Econômico</label>
                                                                    <input id="grupoEmpresarial" type="text" className="form-control input-sm" onChange={handleChange} value={filter.grupoEmpresarial} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Possui Contato Decisor</label>
                                                                    <select id="decisor" className="form-control" onChange={handleChangeDecisor} value={params.decisor}>
                                                                        <option value="">Todos</option>
                                                                        <option value="sim">Sim</option>
                                                                        <option value="nao">Não</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-footer" style={{ padding: 5 }}>
                                                    <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                    <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleExcel()} style={{ marginRight: "5px" }}> <i className="fa fa-file-excel-o" /> Exportar</button>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("Import", "Importar Lead", 0)} style={{ marginRight: "5px" }}><i className="fa fa-file-excel-o" /> Importar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>

                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" >
                                        <thead>
                                            <tr className="bg-light-blue color-palette">
                                                <th style={{ width: "50px" }}>#</th>
                                                <th style={{ width: "70px" }}>ID</th>
                                                <th>Origem</th>
                                                <th>Status</th>
                                                <th>Data Entrada</th>
                                                <th>Responsável</th>
                                                <th>Analista</th>
                                                <th>Empresa</th>
                                                <th>Grupo Econômico</th>
                                                <th>Contatos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                                //.slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ width: '50px' }}>
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" />
                                                                    </button>
                                                                    <ul className="dropdown-menu" role="menu">
                                                                        <li><Link replace to="#" onClick={() => handleShowForm("interacao", "Nova Interação", item.id)}><i className="fa fa-comments-o" /> Nova Interação</Link></li>
                                                                        <li><Link replace to="#" onClick={() => handleShowForm("lead", "Editar Lead", item.id)}><i className="fa fa-pencil" /> Editar Lead</Link></li>
                                                                        <li><Link replace to="#" onClick={() => handleShowForm("contato", "Novo Contato", item.id)}><i className="fa fa-address-card-o" /> Novo Contato</Link></li>
                                                                        <li><Link replace to="#" onClick={() => handleShowForm("associarAnalista", "Associar Analista", item.id)}><i className="fa fa-user-o" /> Associar Analista</Link></li>
                                                                        {(item.origem === 'FTI' || item.origem === 'MKT') && <li> <Link replace to="#" onClick={() => handleShowForm("associarAnalistaFTI", "Associar Analista FTI", item.id)}><i className="fa fa-user-o" /> Associar Analista FTI</Link></li>}
                                                                        <li><Link replace to="#" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" />Remover</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td><Link to={'/crm/lead/' + item.id}>{item.id}</Link></td>
                                                            <td>{item.origem}</td>
                                                            <td>{item.status}</td>
                                                            <td>{config.dateFix(item.dataEntrada)}</td>
                                                            <td>{item.analistaFTI}</td>
                                                            <td>{item.analistaVeolink}</td>
                                                            <td>{item.empresa}</td>
                                                            <td>{item.grupoEmpresarial}</td>
                                                            <td>{item.contatos}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                            {(config.validarClaim('Controladoria') || config.validarClaim('AnalistaFTI')) &&
                                <div id="tab_dash" className="tab-pane">
                                    <DashboardGraficos />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'lead' && <LeadForm parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'interacao' && <LeadInteracaoForm parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'contato' && <LeadContatoForm parentId={0} leadId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'associarAnalista' && <LeadAssociarAnalista parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'associarAnalistaFTI' && <LeadAssociarAnalistaFTI parentId={params.rowId} onHide={handleHide} />}
                    {params.formName === 'Import' && <ExcelImport label="Importar Produtos" endPoint={`${endPoint}/planilha`} onHide={handleHide} onImportErrorHandle={onImportErrorHandle} onImportSuccessHandle={onImportSuccessHandle} filePath="PlanilhaImportacaoLead.xlsx" />}

                </Modal.Body>
            </Modal>
            <Modal show={mensagemErro !== ''} onHide={() => handleHideMensagemErro()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{'Erro ao importar a planilha'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {renderMensagemErro()}
                    </div>
                    <div className="box-footer">
                        <div className="box-tool pull-right">
                            <div className="btn-toolbar">
                                <button type="button" className="btn btn-default pull-right" onClick={() => handleHideMensagemErro()}>Fechar</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Content>
    )
}
