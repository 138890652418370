import React, { useState, useEffect } from 'react';
import { Content, apiHelper, config, Link, Modal, toast, Select } from '../';
import { ProjetoForm } from './ProjetoForm';
import { OrcamentoResult } from './OrcamentoResult';
import { OrcamentoDocumento } from './OrcamentoDocumento';
import { Cronograma } from './Cronograma';
import { Faturamento } from './Faturamento';
import { Provisionamento } from './Provisionamento';
import { Escopo } from './Escopo';
import { Interacao } from './Interacao';
import { Custo } from './Custo';
import { RequisicaoMaterial } from './RequisicaoMaterial';
import { Extrato } from './Extrato';
import { GuiaResumo } from './GuiaResumo';
import { ProvisionamentoRelatorio } from './ProvisionamentoRelatorio';
import { CustoRelatorio } from './CustoRelatorio';
import { FaturamentoRelatorio } from './FaturamentoRelatorio';

export function ProjetoView(props) {
    const endPoint = 'v2/projeto/';
    const projetoId = props.match.params.id;
    const [data, setData] = useState({});
    const [cliente, setCliente] = useState({});
    const [sites, setSites] = useState([]); // sites do orçamento vindo de Aprovação
    const [canEdit, setCanEdit] = useState({});
    const [aprovadorOrcamento, setAprovadorOrcamento] = useState({
        tipoProjeto: '',
        responsavelId: 0,
        status: '',
        dataNecessidade: '',
        localEntregaMesmoCliente: 'true',
        logradouro: '',
        complemento: '',
        numero: '',
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
    });

    const [params, setParam] = useState({
        showModal: false,
        acao: '',
        refresh: false,
        modalTitle: "Editar Projeto"
    });

    const fetchData = () => {
        config.validarPermissao('MenuClientesProjeto');
        if (projetoId) {
            apiHelper.get(`${endPoint}${projetoId}/orcamento`)
                .then(res => {
                    setCanEdit((config.validarClaim('GestorOperacional') || (config.validarClaim('GerenteProjeto') && config.idUsuarioLogado() === res.data.data.responsavelId))
                        && (res.data.data.status !== 'Concluido' && res.data.data.status !== 'Cancelado'));
                    apiHelper.get(`portal/crud/uvw_cliente/${res.data.data.clienteId}/id`)
                        .then(res => {
                            setCliente(res.data.data);
                        });
                    setData(res.data.data);
                });
        }
    }

    useEffect(fetchData, [projetoId]);

    const handleChangeAprovarOrcamento = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setAprovadorOrcamento({ ...aprovadorOrcamento, [name]: value });
    }

    const hadleSubmitAprovacao = (e) => {
        e.preventDefault();

        if (params.acao === 'aprovar') {
            if ((!aprovadorOrcamento.responsavelId || aprovadorOrcamento.responsavelId === 0)) {
                toast.warn('Atenção, necessário informar o Responsável.');
                return;
            }
            if (!aprovadorOrcamento.tipoProjeto || aprovadorOrcamento.tipoProjeto === '') {
                toast.warn('Atenção, necessário informar o Tipo Projeto.');
                return;
            }

            aprovarOrcamento();
        }
        else if (params.acao === 'reprovar') {
            reprovarOrcamento();
        }
    }

    const aprovarOrcamento = () => {
        aprovadorOrcamento.clienteId = data.clienteId;
        aprovadorOrcamento.propostaComercialId = data.propostaComercialId;

        apiHelper.put(endPoint + 'aprovar/' + data.id, aprovadorOrcamento).then(res => {
            config.httpResult(res.data);
            handleUpdate();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const reprovarOrcamento = async () => {
        if (!window.confirm("Deseja realmente reprovar este Orçamento ?"))
            return;

        try {
            await apiHelper.put(endPoint + projetoId + '/reprovar', { observacao: aprovadorOrcamento.observacao });

            let obj = {
                aprovadorId: null,
                status: 'Rascunho',
                custosSolicitados: 0,
                propostaGerada: 0,
                anexoImplantacao: null,
                horarioAtividade: null,
                dataNecessidade: null,
                necessarioTubulacao: null,
                tubulacaoEquipamentoLocalAlto: null,
                meiosElevacaoCliente: null,
                disponibilidadeMaisPessoas: null,
                SLAManutencaoCorretiva: null,
                SLAManutencaoPreventiva: null,
                solicitadoAtualizacaoValores: null
            }

            apiHelper.put(endPoint + projetoId + '/reprovado/orcamento', obj).then(res => {
                config.httpResult(res.data);
                handleUpdate();
            });

        } catch (error) {
            toast.error('Atenção, não foi possível salvar o registro.');
        };
    }

    const handleChangeStatusProjeto = (status) => {
        if (!window.confirm("Deseja realmente executar esta ação ?"))
            return;

        apiHelper.put(`${endPoint}/${projetoId}/status/`, { status }).then(res => {
            config.httpResult(res);
            fetchData();
        }).catch(error => {
            toast.error(error.response.data.msg);
        });
    }

    const descricaoStatus = (status) => {
        switch (status) {
            case 'AprovadoOperacional':
                return 'Aprovado pelo Operacional';
            case 'CronogramaAprovado':
                return 'Cronograma Aprovado';
            default:
                return status;
        }
    }

    const descricaoTipoProjeto = (tipoProjeto) => {
        switch (tipoProjeto) {
            case 'ProcessoRapido':
                return 'Processo Rápido';
            case 'ProcessoSimplificado':
                return 'Processo Simplificado';
            case 'Processo Completo':
                return 'Processo Completo';
            default:
                return tipoProjeto;
        }
    }

    const handleUpdate = () => {
        fetchData();
        setParam({
            ...params,
            showModal: false,
            modalTitle: '',
            acao: '',
            refresh: !params.refresh
        });
    }

    const handleAprovacao = (sites) => {
        setSites(sites);
    }

    const handleShowForm = (acao) => {
        setParam({
            ...params,
            showModal: true,
            modalTitle: acao === 'aprovar' ? 'Aprovar Projeto' : acao === 'reprovar' ? 'Reprovar Projeto' : acao === 'editForm' ? 'Editar Projeto' : '',
            acao,
        });
    };

    const handleHide = () => {
        setParam({
            ...params,
            showModal: false,
            modalTitle: '',
            acao: '',
        });

        fetchData();
    };

    return (
        <Content title="Detalhes do Projeto" bdataserTitle="Detalhes do Projeto">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id} - {data.nome}</h3>

                            <div className="box-tools pull-right">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                                    {canEdit && <button type="button" className="btn btn-box-tool dropdown-toggle" data-toggle="dropdown">
                                        <i className="fa fa-wrench" />
                                    </button>}
                                    <ul className="dropdown-menu" role="menu">
                                        {data.status === 'Novo' && config.validarClaim('GestorOperacional') && <li><Link to="#" onClick={() => handleShowForm('aprovar')}><i className="fa fa-thumbs-o-up" /> Aprovar</Link></li>}
                                        {data.status === 'Novo' && config.validarClaim('GestorOperacional') && <li><Link to="#" onClick={() => handleShowForm('reprovar')}><i className="fa fa-thumbs-o-down" /> Reprovar</Link></li>}
                                        {data.status === 'Novo' && config.validarClaim('GestorOperacional') && <li className="divider"></li>}
                                        {(data.status === 'Andamento' || data.status === 'CronogramaAprovado') && <li><Link to="#" onClick={() => handleChangeStatusProjeto('Concluido')}><i className="fa fa-check" /> Concluir</Link></li>}
                                        <li><Link to="#" onClick={() => handleChangeStatusProjeto('Cancelado')}><i className="fa fa-times-circle" /> Cancelar</Link></li>
                                        <li className="divider"></li>
                                        <li><Link to="#" onClick={() => handleShowForm('editForm')}><i className="fa fa-edit" /> Editar</Link></li>
                                    </ul>
                                </div>
                                <Link to="/v2/projeto/" className="btn btn-box-tool"><i className="fa fa-times" /></Link>
                            </div>
                        </div>

                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <table className="table table-striped table-bordered no-margin">
                                        <tbody>
                                            <tr>
                                                <th>Data de Criação</th>
                                                <td>{config.dateTimeFix(data.dataCriacao)}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{descricaoStatus(data.status)}</td>
                                            </tr>
                                            <tr>
                                                <th>Tipo Projeto</th>
                                                <td>{descricaoTipoProjeto(data.tipoProjeto)}</td>
                                            </tr>
                                            <tr>
                                                <th>Tipo</th>
                                                <td>{(data.comPeD === null || data.comPeD === false) ? 'Sem PeD' : 'Com PeD'}</td>
                                            </tr>
                                            <tr>
                                                <th>Cliente</th>
                                                <td>{data.cliente}</td>
                                            </tr>
                                            <tr>
                                                <th>Sites</th>
                                                <td>{sites && sites.join(', ')}</td>
                                            </tr>
                                            <tr>
                                                <th>Gerente Operacional</th>
                                                <td>{data.gerenteOperacional}</td>
                                            </tr>
                                            <tr>
                                                <th>Responsável</th>
                                                <td>{data.responsavel}</td>
                                            </tr>
                                            <tr>
                                                <th>Consultor Comercial</th>
                                                <td>{data.responsavelVenda}</td>
                                            </tr>
                                            <tr>
                                                <th>Descrição</th>
                                                <td>{data.descricao}</td>
                                            </tr>
                                            <tr>
                                                <th>Número do Pedido</th>
                                                <td>{data.numeroPedido}</td>
                                            </tr>
                                            <tr>
                                                <th>Data do Pedido</th>
                                                <td>{config.dateFix(data.dataPedido)}</td>
                                            </tr>
                                            <tr>
                                                <th>Empresa Origem</th>
                                                <td>{data.empresaOrigem}</td>
                                            </tr>
                                            <tr>
                                                <th>UF Faturamento</th>
                                                <td>{data.faturamentoEmpresa}</td>
                                            </tr>
                                            <tr>
                                                <th>UF Destino</th>
                                                <td>{data.faturamentoCliente}</td>
                                            </tr>
                                            <tr>
                                                <th>Observação</th>
                                                <td>{data.observacao}</td>
                                            </tr>
                                            <tr>
                                                <th>Data Prevista de Entrega Registrada</th>
                                                <td>{config.dateFix(data.dataPrevistaTermino)}</td>
                                            </tr>
                                            <tr>
                                                <th>Avanço da Obra</th>
                                                <td>{data.realizado ? data.realizado + '%' : ''}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <div className="nav-tabs-custom">
                                        <ul className="nav nav-tabs">
                                            <li className="active"><a href="#tab_top_orcamento" data-toggle="tab">Orçamento</a></li>
                                            <li><a href="#tab_top_doc_comercial" data-toggle="tab">Documentos</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="tab_top_orcamento" className="tab-pane active">
                                                <OrcamentoResult projetoId={projetoId} />
                                            </div>
                                            <div id="tab_top_doc_comercial" className="tab-pane">
                                                <OrcamentoDocumento parentId={projetoId} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-footer">
                            <GuiaResumo parentId={projetoId} refresh={params.refresh} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_interacao" data-toggle="tab">Interações</a></li>
                            <li><a href="#tab_cronograma" data-toggle="tab">Cronogramas</a></li>
                            <li><a href="#tab_escopo" data-toggle="tab">Escopo</a></li>
                            <li><a href="#tab_requisicao_material" data-toggle="tab">Requisição Material</a></li>
                            <li><a href="#tab_custo" data-toggle="tab">Custos de Serviço</a></li>
                            <li><a href="#tab_faturamento" data-toggle="tab">Faturamento</a></li>
                            <li><a href="#tab_provisionamento" data-toggle="tab">Provisionamento</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_interacao" className="tab-pane active">
                                <Interacao parentId={projetoId} canEdit={canEdit} />
                            </div>
                            <div id="tab_cronograma" className="tab-pane">
                                <Cronograma parentId={projetoId} canEdit={canEdit} />
                            </div>
                            <div id="tab_escopo" className="tab-pane">
                                <Escopo projetoId={projetoId} orcamentoId={data.propostaComercialId} onUpdate={handleUpdate} clienteId={data.clienteId} refresh={params.refresh} handleLoadDados={(sites) => handleAprovacao(sites)} canEdit={canEdit || (config.validarClaim('projeto.escopo.editar') && data.status !== 'Concluido' && data.status !== 'Cancelado')} />
                            </div>
                            <div id="tab_requisicao_material" className="tab-pane">
                                <RequisicaoMaterial parentId={projetoId} onUpdate={handleUpdate} canEdit={canEdit && data.status !== 'Novo'} refresh={params.refresh} />
                            </div>
                            <div id="tab_custo" className="tab-pane">
                                <Custo orcamentoId={data.propostaComercialId} parentId={projetoId} onUpdate={handleUpdate} canEdit={(canEdit || config.validarClaim('Faturamento')) && data.status !== 'Novo'} />
                            </div>
                            <div id="tab_faturamento" className="tab-pane">
                                <Faturamento parentId={projetoId} onUpdate={handleUpdate} canEdit={(canEdit || config.validarClaim('Faturamento')) && data.status !== 'Novo'} />
                            </div>
                            <div id="tab_provisionamento" className="tab-pane">
                                <Provisionamento parentId={projetoId} onUpdate={handleUpdate} canEdit={canEdit && data.status !== 'Novo'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Extrato parentId={projetoId} refresh={params.refresh} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ProvisionamentoRelatorio parentId={projetoId} onUpdate={handleUpdate} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <CustoRelatorio parentId={projetoId} onUpdate={handleUpdate} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <FaturamentoRelatorio parentId={projetoId} onUpdate={handleUpdate} />
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.acao === 'editForm' && <ProjetoForm projetoId={projetoId} onHide={handleHide} />}

                    {(params.acao === 'aprovar' || params.acao === 'reprovar') &&
                        <form onSubmit={hadleSubmitAprovacao}>
                            {params.acao === 'aprovar' &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>Responsável</label>
                                            <Select id="responsavelId" className="form-control" onChange={handleChangeAprovarOrcamento}>
                                                <option value="">Selecione</option>
                                                <option endpoint={`v2/projeto/pessoaPerfil?filter=Perfil::equal::GerenteProjeto;;and;;ativo::equal::1`} valuefield="id" textfield="nome" />
                                            </Select>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Tipo Projeto</label>
                                            <select id="tipoProjeto" className="form-control" onChange={handleChangeAprovarOrcamento} required={params.acao === 'aprovar'}>
                                                <option value="">Selecione</option>
                                                <option value="ProcessoCompleto">Processo Completo</option>
                                                <option value="ProcessoSimplificado">Processo Simplificado</option>
                                                <option value="ProcessoRapido">Processo Rápido</option>
                                            </select>
                                        </div>
                                        {(aprovadorOrcamento.tipoProjeto && aprovadorOrcamento.tipoProjeto === 'ProcessoRapido') &&
                                            <React.Fragment>
                                                <div className="form-group col-sm-6">
                                                    <label>Mesmo Local entrega Cliente</label>
                                                    <select id="localEntregaMesmoCliente" className="form-control" onChange={handleChangeAprovarOrcamento}>
                                                        <option value="true">Sim</option>
                                                        <option value="false">Não</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <label>Data Necessidade</label>
                                                    <input id="dataNecessidade" type="date" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                                <div className="form-group col-sm-12">
                                                    <label>Endereço Cliente</label><br />
                                                    <span>{`${cliente.logradouro}, ${cliente.numero} ${cliente.complemento} ${cliente.bairro} CEP:${cliente.cep} - ${cliente.cidade} ${cliente.estado}`}</span>
                                                </div>

                                            </React.Fragment>}
                                    </div>
                                    {aprovadorOrcamento.localEntregaMesmoCliente === 'false' &&
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="form-group col-sm-8">
                                                    <label>Logradouro</label>
                                                    <input id="logradouro" type="text" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                                <div className="form-group col-sm-4">
                                                    <label>Número</label>
                                                    <input id="numero" type="text" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-sm-8">
                                                    <label>Complemento</label>
                                                    <input id="complemento" type="text" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                                <div className="form-group col-sm-4">
                                                    <label>Cep</label>
                                                    <input id="cep" type="number" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-sm-6">
                                                    <label>Bairro</label>
                                                    <input id="bairro" type="text" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <label>Cidade</label>
                                                    <input id="cidade" type="text" className="form-control" onChange={handleChangeAprovarOrcamento} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-sm-6">
                                                    <label>UF</label>
                                                    <select id="uf" className="form-control" onChange={handleChangeAprovarOrcamento}>
                                                        <option key="" value="">Selecione</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapa</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceara</option>
                                                        <option value="DF">Distrito Federal</option>
                                                        <option value="ES">Espirito Santo</option>
                                                        <option value="GO">Goias</option>
                                                        <option value="MA">Maranhao</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="PA">Para</option>
                                                        <option value="PB">Paraiba</option>
                                                        <option value="PR">Parana</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piaui</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RN">Rio Grande do Norte</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="RO">Rondonia</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="SP">Sao Paulo</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }

                            {params.acao === 'reprovar' &&
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label>Observação</label>
                                        <textarea id="observacao" className="form-control" rows="3" onChange={handleChangeAprovarOrcamento} required={params.acao === 'reprovar'}></textarea>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-sm-12">
                                    <button type="button" className="btn btn-default pull-left" onClick={handleHide}>Fechar</button>
                                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                                </div>
                            </div>
                        </form>
                    }
                </Modal.Body>
            </Modal>
        </Content >
    )
}
