import React, { useState } from 'react';
import { apiHelper, toast, config, Select, Checkbox } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function RegistrarEntradaForm(props) {
    const endPoint = 'suprimentos/material';
    const [form, setForm] = useState([]);
    const [cliente, setCliente] = useState(0);
    const [params, setParams] = useState({
        endpoint: '',
        valuefield: '',
        textfield: ''
    });
    const [dataNumeroSerie, setDataNumeroSerie] = useState([]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "cliente") {
            setCliente(value);
            return;
        }

        if (name === "tipo") {
            if (value === "2")
                setParams({ ...params, endpoint: 'portal/crud/localArmazenamento', valuefield: 'pkLocalArmazenamento', textfield: 'nome' });
            else if (value === "3")
                setParams({ ...params, endpoint: 'portal/crud/prestador/?filter=isSituacaoAtivo::equal::1', valuefield: 'pkPrestador', textfield: 'nome' });
            else if (value === "4")
                setParams({ ...params, endpoint: 'portal/crud/rhFuncionario?filter=isAtivo::equal::1', valuefield: 'pkFuncionario', textfield: 'nome' });
        }

        else if (name === "quantidade") {
            setForm({ ...form, quantidade: value, patrimonio: '', patrimonioFim: '' });
            setDataNumeroSerie([]);
        } else if (name === "patrimonio") {
            setForm({ ...form, quantidade: '', patrimonio: value });
        } else if (name === "patrimonioFim") {
            setForm({ ...form, quantidade: '', patrimonioFim: value });
        } else
            setForm({ ...form, [name]: value });
    }

    const handleAddFornecedor = (row) => {
        setForm({ ...form, fkFornecedor: row.pkFornecedor });
    }

    const handleAddMaterial = (row) => {
        setForm({ ...form, fkMaterial: row.pkMaterial, isPatrimoniado: row.isPatrimoniado });
    }

    const handleNumeroSerie = () => {
        let data = gerarPatrimonios(true);
        setDataNumeroSerie(data);
    }

    const gerarPatrimonios = (isNumeroSerie = false) => {
        let total = form.patrimonioFim - form.patrimonio;
        let data = [];
        for (var i = 0; i <= total; i++) {

            let obj = {};
            obj.patrimonio = InserirZeroAEsquerda(parseInt(form.patrimonio) + i);
            if (isNumeroSerie)
                obj.numeroSerie = 0;

            data.push(obj);
        }

        return data;
    }

    function InserirZeroAEsquerda(num) {
        return num.toString().padStart(10, "0");
    }

    const changeNumeroSerie = (e, name) => {
        let numeroSerie = e.target.innerText;
        setForm({ ...form, [name]: numeroSerie });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var obj = form;

        if (obj.fkMaterial === undefined) {
            toast.warn("Selecione um material!");
            return;
        }

        if (obj.fkFornecedor === undefined) {
            toast.warn("Informe um fornecedor!");
            return;
        }

        let compras = {
            fkFornecedor: obj.fkFornecedor,
            numeroNotaFiscal: obj.numeroNotaFiscal,
            dataCompra: obj.dataCompra
        }

        let itemCompra = {};
        itemCompra.fkMaterial = obj.fkMaterial;
        itemCompra.isMaterialVeotex = obj.isMaterialVeotex;
        itemCompra.valorUnitario = obj.valorUnitario;
        itemCompra.garantia = obj.garantia;
        itemCompra.fktempoGarantia = obj.fkTempoGarantia;

        let estoque = {};
        estoque.observacao = form.observacao;
        estoque.isImobilizado = form.isImobilizado;
        estoque.isAlocado = form.tipo === 1 ? true : false;
        estoque.numeroSerie = obj.numerosSeries;

        if (dataNumeroSerie.length > 0) {
            let listNumeroSerie = [];
            dataNumeroSerie.map((item) => {
                let obj = {
                    'patrimonio': item.patrimonio,
                    'numeroSerie': form['numeroSerie' + item.patrimonio]
                }
                listNumeroSerie.push(obj);
                return true;
            });

            estoque.numerosSeries = listNumeroSerie;

        } else if ((obj.patrimonio !== undefined && obj.patrimonio !== '') && (obj.patrimonioFim !== undefined && obj.patrimonioFim !== '')) {
            estoque.patrimonios = gerarPatrimonios();
        }
        estoque.quantidade = obj.quantidade !== undefined && obj.quantidade !== '' ? obj.quantidade : 1;

        let objItens = {
            compras: compras,
            itemCompra: itemCompra,
            estoque: estoque
        }

        apiHelper.post(`${endPoint}/entrada`, objItens).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                props.onHide();
            }
            else {
                toast.error(res.data.msg);
            }
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar registro.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Número Nota</label>
                    <input id="numeroNotaFiscal" type="text" className="form-control" onChange={handleChange} maxLength="15" value={form.numeroNotaFiscal} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Data da Compra</label>
                    <input id="dataCompra" type="date" className="form-control" onChange={handleChange} value={form.dataCompra} required />
                </div>
                <div className="form-group col-md-12">
                    <PesquisaGenericaHooks required textField="nome" titulo="Fornecedor" selecao={form.fkFornecedor} endpoint="portal/crud/fornecedor/?filter=nome::contains::" onImport={(row) => { handleAddFornecedor(row); }} />
                </div>
                <div className="form-group col-md-12">
                    <PesquisaGenericaHooks required textField="nome" titulo="Material" selecao={form.fkMaterial} endpoint="portal/crud/material/?filter=nome::contains::" onImport={(row) => { handleAddMaterial(row); }} />
                </div>
                {form.isPatrimoniado ?
                    <React.Fragment>
                        <div className="form-group col-md-4">
                            <label>Patrimônio</label>
                            <input id="patrimonio" type="text" className="form-control" onChange={handleChange} maxLength="10" value={form.patrimonio} required />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Até</label>
                            <input id="patrimonioFim" type="text" className="form-control" onChange={handleChange} maxLength="10" value={form.patrimonioFim} required />
                        </div>
                    </React.Fragment> :
                    <div className="form-group col-md-4">
                        <label>Quantidade</label>
                        <input id="quantidade" type="number" className="form-control" onChange={handleChange} value={form.quantidade} required />
                    </div>}
                <div className="form-group col-md-4">
                    <label>O item é Imobilizado</label>
                    <Select id="isImobilizado" className="form-control" onChange={handleChange} value={form.isImobilizado} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </Select>
                </div>
            </div>
            <div className="row">
                {form.isPatrimoniado && <div className="form-group col-md-4">
                    <button className="btn btn-primary" onClick={handleNumeroSerie}>Criar campos para Nº Série</button>
                </div>}
                {dataNumeroSerie.length > 0 && <div className="form-group col-md-4">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th>Patrimônio</th>
                                    <th>Nº de Série</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataNumeroSerie.sort((a, b) => a.patrimonio > b.patrimonio ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.patrimonio}</td>
                                                <td><div style={{ background: '#fcf8e1', outline: 0 }} id={'numeroSerie' + item.patrimonio} suppressContentEditableWarning contentEditable onBlur={(e) => changeNumeroSerie(e, 'numeroSerie' + item.patrimonio)}>{item.numeroSerie}</div></td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <Checkbox inline id="isMaterialVeotex" onClick={handleChange}>Não é material Veotex</Checkbox>
                </div>
                <div className="form-group col-md-4">
                    <label>Tipo</label>
                    <Select id="tipo" className="form-control" onChange={handleChange} required>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/tipoLocalArmazenamento'} textfield="nome" valuefield="pkTipoLocalArmazenamento" />
                    </Select>
                </div>
                {form.tipo === "1" &&
                    <React.Fragment>
                        <div className="form-group col-md-8">
                            <label htmlFor="cliente">Cliente</label>
                            <Select id="cliente" className="form-control" onChange={handleChange}>
                                <option value="">Selecione</option>
                                <option endpoint={'portal/crud/uvw_cliente'} textfield="nome" valuefield="id" />
                            </Select>
                        </div>

                        <div className="form-group col-md-12">
                            <label htmlFor="localArmazenamento">Site</label>
                            <Select id="localArmazenamento" className="form-control" onChange={handleChange} refresh={cliente}>
                                <option value="">Selecione</option>
                                <option endpoint={'portal/crud/uvw_v2_pesquisa_site/?filter=fkCliente::equal::' + cliente} textfield="clienteSite" valuefield="pkSite" />
                            </Select>
                        </div>
                    </React.Fragment>
                }
                {form.tipo && form.tipo !== "1" &&
                    <div className="form-group col-md-8">
                        <label htmlFor="localArmazenamento">Local Armazenamento Destino</label>
                        <Select id="localArmazenamento" className="form-control" onChange={handleChange}>
                            <option value="">Selecione</option>
                            <option endpoint={params.endpoint} textfield={params.textfield} valuefield={params.valuefield} />
                        </Select>
                    </div>
                }
                <div className="form-group col-md-4">
                    <label>Garantia</label>
                    <input id="garantia" type="number" className="form-control" onChange={handleChange} value={form.garantia} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Tempo Garantia</label>
                    <Select id="fkTempoGarantia" className="form-control" onChange={handleChange} value={form.fkTempoGarantia} required>
                        <option value="">Selecione</option>
                        <option value="1">Dias</option>
                        <option value="2">Meses</option>
                        <option value="3">Ano</option>
                    </Select>
                </div>
                <div className="form-group col-md-4">
                    <label>Valor Unitário</label>
                    <input id="valorUnitario" type="number" className="form-control" onChange={handleChange} value={form.valorUnitario} required />
                </div>
                <div className="form-group col-md-12">
                    <label>Complemento</label>
                    <textarea id="observacao" className="form-control" rows="3" onChange={handleChange} maxLength="2000" value={form.observacao} required />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Salvar</button>
                </div>
            </div>
        </form >
    )
}
