import React, { useState } from 'react';
import { Content, Link, config, apiHelper, fileHelper, Select, toast } from '../';
import { DataPtBrComponent } from '../../components/controls/DataPtBrComponent';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function Projeto(props) {
    const endPointProposta = "portal/crud/uvw_Relatorio_Projetos";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        titleFiltro: '',
        numeroProposta: '',
        status: '',
        searchValueConsultor: '',
        searchValuePrestador: '',
        clienteSearch: '',
        dataCriacaoInicio: '',
        dataCriacaoFim: '',
        dataConclusaoInicio: '',
        dataConclusaoFim: ''
    });

    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowLoading(true);
        let query = montarQuery();

        apiHelper.get(`${endPointProposta}?filter=${query}`)
            .then(res => {
                setData(res.data.data);
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            numeroProposta: '',
            status: '',
            searchValueConsultor: '',
            searchValuePrestador: '',
            clienteSearch: '',
            dataCriacaoInicio: '',
            dataCriacaoFim: '',
            dataConclusaoInicio: '',
            dataConclusaoFim: '',
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const montarQuery = () => {
        let parametros = "";
        let numeroProposta = filter.numeroProposta === '' ? '' : `NumeroProposta::equal::${filter.numeroProposta};;AND;;`;
        let status = filter.status === '' ? '' : `Status::equal::${filter.status};;AND;;`;
        let consultor = filter.searchValueConsultor === '' ? '' : `IdConsultor::equal::${filter.searchValueConsultor};;AND;;`;
        let prestador = filter.searchValuePrestador === '' ? '' : `IdPrestador::equal::${filter.searchValuePrestador};;AND;;`;
        let cliente = filter.clienteSearch === '' ? '' : `IdCliente::equal::${filter.clienteSearch};;AND;;`;
        let dataCriacao = filter.dataCriacaoInicio === '' ? '' : `DataCriacao::greaterEqual::${filter.dataCriacaoInicio};;AND;;DataCriacao::lessEqual::${filter.dataCriacaoFim} 23:59:59;;AND;;`;
        let dataConclusao = filter.dataConclusaoFim === '' ? '' : `DataConclusao::greaterEqual::${filter.dataConclusaoInicio};;AND;;DataConclusao::lessEqual::${filter.dataConclusaoFim} 23:59:59;;`;

        return parametros.concat(numeroProposta, status, consultor, prestador, cliente, dataCriacao, dataConclusao);
    }

    const handleOrdenarColunas = (e, nomeVariavelColuna) => {
        e.preventDefault();
        let sortedObjs = config.ordenarColunas(data, nomeVariavelColuna);
        setData(sortedObjs);
    }

    return (
        <Content title="Relatório de Projetos" browserTitle="Relatório de Projetos">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="numeroProposta">Número Proposta</label>
                                <input id="numeroProposta" type="text" className="form-control" placeholder="" value={filter.numeroProposta} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <Select id="status" className="form-control" onChange={handleChange} value={filter.status}>
                                    <option value="">Todos</option>
                                    <option value={'Novo'}>Novo</option>
                                    <option value={'CronogramaAprovado'}>Cronograma Aprovado</option>
                                    <option value={'AprovadoOperacional'}>Aprovado Operacional</option>
                                    <option value={'ReprovadoOperacional'}>Reprovado Operacional</option>
                                    <option value={'Finalizado'}>Finalizado</option>
                                    <option value={'Cancelado'}>Cancelado</option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Consultor</label>
                                <Select id="searchValueConsultor" className="form-control" onChange={handleChange} value={filter.searchValueConsultor}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Prestador</label>
                                <Select id="searchValuePrestador" className="form-control" onChange={handleChange} value={filter.searchValuePrestador}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/projetoImplantacao/prestadores" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="clienteSearch" className="form-control" onChange={handleChange} value={filter.clienteSearch}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/projetoImplantacao/cliente" valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataCriacaoInicio"} col={12} showHora={false} labelData={"Data Criação Início"} valueData={filter.dataCriacaoInicio} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataCriacaoFim"} col={12} showHora={false} labelData={"Data Criação Fim"} valueData={filter.dataCriacaoFim} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataConclusaoInicio"} col={12} showHora={false} labelData={"Data Conclusão Início"} valueData={filter.dataConclusaoInicio} onSelectValue={handleChange} />
                            </div>
                            <div className="row">
                                <DataPtBrComponent id={"dataConclusaoFim"} col={12} showHora={false} labelData={"Data Conclusão Fim"} valueData={filter.dataConclusaoFim} onSelectValue={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Relatório de Projetos</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => fileHelper.exportToExcel(montarQuery(),'uvw_Relatorio_Projetos', 'RelatorioProjeto_' + moment().format("DD/MM/YYYY"))}><i class="fa fa-file-excel-o"></i></button>
                                </div>
                            </div>
                            <div className="box-body table-responsive no-padding">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'numeroProposta')}><i className="fa fa-sort" /> Nº Proposta</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'titulo')}><i className="fa fa-sort" /> Titulo</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'descricao')}><i className="fa fa-sort" /> Descrição</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'prestadorAcionado')}><i className="fa fa-sort" /> Prestador</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'consultor')}><i className="fa fa-sort" /> Consultor</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'cliente')}><i className="fa fa-sort" /> Cliente</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataCriacao')}><i className="fa fa-sort" /> Data Criação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataAprovacao')}><i className="fa fa-sort" /> Data Aprovação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataAprovacaoCronograma')}><i className="fa fa-sort" /> Data Aprovação Cronograma</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataConclusao')}><i className="fa fa-sort" /> Data Conclusão</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorInstalacao')}><i className="fa fa-sort" /> Valor Instalação</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'notaFiscal')}><i className="fa fa-sort" /> Nota Fiscal</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'dataFaturamento')}><i className="fa fa-sort" /> Data Faturamento</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'valorFaturamento')}><i className="fa fa-sort" /> Valor Faturamento</th>
                                            <th onClick={(e) => handleOrdenarColunas(e, 'status')}><i className="fa fa-sort" /> Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.numeroProposta}</td>
                                                        <td>{item.titulo}</td>
                                                        <td>{item.descricao}</td>
                                                        <td>{item.prestadorAcionado}</td>
                                                        <td>{item.consultor}</td>
                                                        <td>{item.cliente}</td>
                                                        <td>{config.dateFix(item.dataCriacao)}</td>
                                                        <td>{item.dataAprovacao !== null && item.dataAprovacao !== '' ? config.dateFix(item.dataAprovacao) : ''}</td>
                                                        <td>{item.dataAprovacaoCronograma !== null && item.dataAprovacaoCronograma !== '' ? config.dateFix(item.dataAprovacaoCronograma) : ''}</td>
                                                        <td>{item.dataConclusao !== null && item.dataConclusao !== '' ? config.dateFix(item.dataConclusao) : ''}</td>
                                                        <td>{config.currencyFix(item.valorInstalacao)}</td>
                                                        <td>{item.notaFiscal}</td>
                                                        <td>{item.dataFaturamento !== null && item.dataFaturamento !== '' ? config.dateFix(item.dataFaturamento) : ''}</td>
                                                        <td>{item.valorFaturamento !== null && item.valorFaturamento !== '' ? config.currencyFix(item.valorFaturamento) : 0}</td>
                                                        <td>{item.status}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
