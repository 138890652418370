import React, { useState } from 'react';
import { config, apiHelper, toast } from '../index';

export function LeadInteracaoForm(props) {
    const endPoint = "lead/interacao";
    const parentId = props.parentId;
    const [data, setData] = useState({});

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        data.leadId = parentId;

        apiHelper.post(endPoint, data).then(res => {            
            config.httpResult(res);
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a interação.');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Tipo *</label>
                    <select id="tipo" className="form-control" onChange={handleChange} value={data.tipo} required>
                        <option value="">Selecione</option>
                        <option value="Contato Telefonico">Contato Telefônico</option>
                        <option value="Email Enviado">E-mail Enviado</option>
                        <option value="Visita Obtida">Visita Obtida</option>
                        <option value="Visita Recusada">Visita Recusada</option>
                        <option value="Gerou Proposta">Gerou Proposta</option>
                    </select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Data *</label>
                    <input id="dataEvento" type="date" className="form-control" onChange={handleChange} value={data.dataEvento} placeholder="dd/mm/yyyy" required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Descrição</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    )
}
