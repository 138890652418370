import React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

export function GoogleMap({
    children,
    initCenter,
    zoomLevel = 4,
    height = "100%",
    disableDefaultUI = false,
    zoomControl = true,
    streetViewControl = false
}) {
    const [clicks, setClicks] = React.useState([]);
    const [zoom, setZoom] = React.useState(zoomLevel);
    const [center, setCenter] = React.useState(initCenter);

    React.useEffect(() => {
        initCenter ? setCenter(initCenter) : setCenter({ lat: -23.6117831, lng: -46.6929484 });
        setZoom(zoomLevel);
    }, [zoomLevel, initCenter]);

    const onClick = (e) => setClicks([...clicks, e.latLng]);

    const onIdle = (m) => {
        setZoom(m.getZoom());
        setCenter(m.getCenter() && m.getCenter().toJSON());
    };

    const config = {
        onClick: onClick,
        onIdle: onIdle,
        center: center,
        zoom: zoom,
        style: { width: "100%", height: height },
        options: {
            disableDefaultUI: disableDefaultUI,
            zoomControl: zoomControl,
            streetViewControl: streetViewControl
        }
    }

    return (
        <Wrapper apiKey="AIzaSyAWUTcoi03HTo3z7Chlfdy4OtdWoyk_w-E" render={render}>
            <Map {...config}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        let pos = child.props.position;
                        let circlePos = child.props.circlePosition;
                        if (pos) {
                            return React.cloneElement(<Marker />, { position: pos });
                        }
                        else if (circlePos) {
                            return React.cloneElement(<Circle />, { circlePosition: circlePos });
                        }
                    }
                })}
            </Map>
        </Wrapper>
    );
}

const Map = ({ onClick, onIdle, children, style, ...options }) => {
    const ref = React.useRef(null);
    const [map, setMap] = React.useState();
    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useDeepCompareEffectForMaps(() => {
        if (map) map.setOptions(options);
    }, [map, options]);

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) map.addListener("click", onClick);

            if (onIdle) map.addListener("idle", () => onIdle(map));
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

const render = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
};

const Marker = (options) => {
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        !marker && setMarker(new window.google.maps.Marker());

        // remove marker from map on unmount
        return () => marker && marker.setMap(null);
    }, [marker]);

    React.useEffect(() => {
        let obj = {
            ...options,
            position: {
                lat: parseFloat(options.position.lat.replace(",", ".")),
                lng: parseFloat(options.position.lng.replace(",", "."))
            },
            icon: options.position.icon,
            label: options.position.label,
            title: options.position.title
        };
        marker && marker.setOptions(obj)
    }, [marker, options]);


    return null;
}

const Circle = (options) => {
    const [circle, setCircle] = React.useState();

    React.useEffect(() => {
        !circle && setCircle(new window.google.maps.Circle());

        // remove circle from map on unmount
        return () => circle && circle.setMap(null);
    }, [circle]);
    React.useEffect(() => {
        
        let obj = {
            ...options,
            strokeColor: "blue",
            strokeOpacity: 0.1,
            strokeWeight: 2,
            fillColor: "red",
            fillOpacity: 0.1,
            map: options.map,
            center: {
                lat: parseFloat(options.circlePosition.lat.replace(",", ".")),
                lng: parseFloat(options.circlePosition.lng.replace(",", "."))
            },
            radius: parseFloat(options.circlePosition.radius),
            label: 'foo'
        };

        circle && circle.setOptions(obj)
    }, [circle, options]);


    return null;
}

const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a, b) => {
        if (
            isLatLngLiteral(a) || a instanceof window.google.maps.LatLng ||
            isLatLngLiteral(b) || b instanceof window.google.maps.LatLng
        ) {
            return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
        }

        return deepEqual(a, b);
    }
);

function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current))
        ref.current = value;

    return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
