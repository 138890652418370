import React from 'react';
import { RelatorioPadrao } from './report/RelatorioPadrao';
import { QuantitativoObras } from './report/QuantitativoObras';
import { QuantitativoObrasAno } from './report/QuantitativoObrasAno';
import { FaturamentoTotalObras } from './report/FaturamentoTotalObras';

export function DashboardRelatorios(props) {
    return (
        <div className="box-body">
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Relatórios</h3>
                        </div>
                        <div className="panel-body no-padding">
                            <ul className="nav nav-pills" id="myTabs"> {/*<ul className="nav nav-pills nav-stacked" id="myTabs"> */}
                                <li className="active"><a href="#tab_relatorioPadrao" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Relatório Padrão</a></li>
                                <li><a href="#tab_quantitativoObras" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Quantitativo de Obras</a></li>
                                <li><a href="#tab_quantitativoObrasAno" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Quantitativo de Obras por Ano</a></li>
                                <li><a href="#tab_faturamentoTotalObras" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Faturamento Total de Obras</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab_relatorioPadrao">
                            <RelatorioPadrao />
                        </div>
                        <div className="tab-pane" id="tab_quantitativoObras">
                            <QuantitativoObras />
                        </div>
                        <div className="tab-pane" id="tab_quantitativoObrasAno">
                            <QuantitativoObrasAno />
                        </div>
                        <div className="tab-pane" id="tab_faturamentoTotalObras">
                            <FaturamentoTotalObras />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
