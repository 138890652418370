import React, { useLayoutEffect } from 'react';
import * as Widgets from './widgets';

export default function AtendimentoDashboard(props) {
    const [tecnico, setTecnico] = React.useState({});

    useLayoutEffect(() => {
        //document.body.classList.add('sidebar-collapse');
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Widgets.TicketResumo />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Widgets.Tecnicos onSelect={(e) => setTecnico(e)} />
                </div>
                <div className="col-md-6">
                    <Widgets.MapaGeral tecnico={tecnico} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Widgets.TecnicoMensalGrafico />
                </div>
            </div>
        </>
    )
}
