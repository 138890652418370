import React from 'react';
import * as Widgets from './widgets';

export default function ProjectDashboard(props) {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Widgets.ProjectResume />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Widgets.Projetos />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">

                </div>
            </div>
        </>
    )
}
