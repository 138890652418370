import React from 'react';
import { Link } from 'react-router-dom';
import { authService, toast } from '../';

export function Login(props) {
    const [form, setForm] = React.useState({
        username: '',
        password: '',
        remember: '',
        autoLogin: ''
    });

    React.useLayoutEffect(() => {
        document.body.style = null;
        document.body.classList.remove(...document.body.classList);
        document.body.classList.add('hold-transition');
        document.body.classList.add('login-page');
        document.getElementById('root').className = 'login-box';
    });

    React.useEffect(() => {
        //if (authService.checkValidSession())
        //window.location.href = "/";

        let loginApp = localStorage.loginApp ? JSON.parse(localStorage.loginApp) : {};

        if (loginApp.remember === true) {
            setForm({
                remember: loginApp.remember,
                username: loginApp.username,
                password: loginApp.password,
                autoLogin: loginApp.autoLogin
            })
        }
    }, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'autoLogin') {
            setForm({ ...form, [name]: value, remember: value })
        } else
            setForm({ ...form, [name]: value });
    }

    const handleLogin = (e) => {
        e.preventDefault();

        if (form.remember === true || form.autoLogin === true) {
            localStorage.loginApp = JSON.stringify({
                username: form.username,
                password: form.password,
                remember: form.autoLogin ? true : form.remember,
                autoLogin: form.autoLogin
            });
        }
        else if (!form.remember) {
            localStorage.removeItem('loginApp');
        }

        authService.login(form.username, form.password)
            .then(res => {
                if (res) {
                    let last_url = localStorage.getItem('last_url');

                    if (last_url) {
                        localStorage.removeItem('last_url');
                        window.location.href = last_url;
                    }
                    else
                        window.location.href = '#/'
                }
                else {
                    toast.error("Não foi possível autenticar o usuário!")
                }
            });
    }

    return (
        <>
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/login" className="h1">Veolink SIGA</Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">Informe seus dados de login</p>

                    <form onSubmit={handleLogin}>
                        <div className="input-group mb-3">
                            <input id="username" type="text" className="form-control" value={form.username} placeholder="Login" required onChange={handleChange} />
                            <div className="input-group-append">
                                <div className="input-group-text"><span className="fas fa-envelope" /></div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input id="password" type="password" className="form-control" placeholder="Senha" value={form.password} required onChange={handleChange} />
                            <div className="input-group-append">
                                <div className="input-group-text"><span className="fas fa-lock" /></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" onChange={handleChange} checked={form.remember} disabled={form.autoLogin} />
                                    <label htmlFor="remember">Lembrar Me</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="autoLogin" onChange={handleChange} checked={form.autoLogin} />
                                    <label htmlFor="autoLogin">Manter-me logado</label>
                                </div>
                            </div>
                            <div className="col-4">
                                <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                            </div>
                        </div>
                    </form>

                    <p className="mb-1">
                        {/* <Link to="#">Esqueci minha senha</Link> */}
                    </p>
                </div>
            </div>
        </>
    );
}
