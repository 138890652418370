import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Modal } from '../';
import * as _ from 'lodash';

export function RequisicaoMaterialItem(props) {
    const endPoint = 'suprimentos/rm/';
    const endPointProjeto = 'v2/projeto/';
    const endPointOrcamentoSite = 'portal/crud/uvw_v2_comercial_orcamento_site';
    const idRequisicao = props.parentId;

    const [data, setData] = useState([]);
    const [requisicao, setRequisicao] = useState([]);
    const [sites, setSites] = useState([]);
    const [escopo, setEscopo] = useState([]);
    const [materiaisProjeto, setMateriaisProjeto] = useState([]);
    const [params, setParam] = useState({ showModal: false, new: false, edit: false });

    const fetchData = () => {
        apiHelper.get(`${endPoint}${idRequisicao}/material/`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);
                    props.refresh(res.data.data.length);
                }
                else
                    toast.error('Atenção, não foi possível carregar os materiais!');
            }).catch(error => {
                toast.error(error);
            });
    }

    const fetchAll = async () => {
        const resR = await apiHelper.get(`${endPoint}/${idRequisicao}`);
        setRequisicao(resR.data.data);

        const resP = await apiHelper.get(`${endPointProjeto}${resR.data.data.origemId}/aprovacaoOrcamento`);
        obterSites(resP.data.data);

        const materiaisProjeto = await apiHelper.get(`portal/crud/uvw_v2_requisicao_material_item?filter=origemId::equal::${resR.data.data.origemId}`);
        setMateriaisProjeto(materiaisProjeto.data.data);
    }

    const onLoad = () => {
        fetchAll();
        fetchData();
    }

    useEffect(onLoad, [idRequisicao]);

    const handleNewItem = () => {
        setParam({ ...params, showModal: true });
    }

    const obterSites = (projetoOrcamento) => {
        let escopo = projetoOrcamento.escopo.data;

        apiHelper.get(`${endPointOrcamentoSite}?filter=IdCliente::equal::${projetoOrcamento.clienteId};;AND;;OrcamentoId::equal::${projetoOrcamento.propostaComercialId};;`)
            .then(res => {
                let sites = res.data.data;
                let distinctSites = [];

                escopo.map((item, i) => {
                    let site = sites.find(c => c.id === item.siteId);
                    escopo[i].nomeSite = site && site.nome;
                    return null;
                });

                let nomesSites = escopo.map((item, i) => { return item.nomeSite });
                distinctSites = nomesSites.filter((v, i, a) => a.indexOf(v) === i);

                let dataSitesAgrupados = _.groupBy(escopo, 'nomeSite');
                let dados = [];

                distinctSites.map(item => {
                    dados.push(dataSitesAgrupados[item]);
                    return null;
                });

                setSites(distinctSites);
                setEscopo(dados);
            });
    };

    const handleAddItem = (item) => {
        apiHelper.get(`${endPoint}requisicao/${requisicao.origemId}/material/`)
            .then(res => {
                return res;
            }).catch(error => {
                toast.error(error);
            }).then(res => {
                var obj = res.data.data.find(w => w.materialId === item.idMaterial && w.siteId === item.siteId);

                if (obj && obj.id) {
                    toast.error(`Este produto ja foi solicitado na Requisição ${obj.requisicaoId}`);
                } else {
                    var objRMItem = {
                        status: "Rascunho",
                        siteId: item.siteId,
                        materialId: item.idMaterial,
                        orcamentoItemId: item.id,
                        unidade: item.unidade,
                        quantidade: item.quantidade,
                        valor: item.valorUnitario,
                        detalhamentoTecnico: item.detalhamentoTecnico,
                        requisicaoId: idRequisicao,
                        necessitaIndustrializacao: item.necessitaIndustrializacao,
                        processed: 0
                    };

                    apiHelper.post('v2/projeto/requisicaoMaterialItem', objRMItem).then(res => {
                        if (res.data.code === 200) {
                            config.httpResult(res.data);
                            setParam({ ...params, showModal: false, edit: false, new: false });
                            onLoad();
                            handleUpdate();
                        }
                        else
                            toast.error('Atenção, não foi possível salvar a requisição.');
                    }).catch(error => {
                        toast.error('Atenção, não foi possível salvar a requisição.');
                    });
                }
            });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}item/${id}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    onLoad();
                    handleUpdate();
                }
                else
                    toast.error('Atenção, não foi possível excluir o produto!');
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o produto!');
            });
    }

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onHide();
    }

    const handleUpdate = () => {
        props.onUpdate();
    }

    return (
        <React.Fragment>
            <div className="box">
                <div className="box-header with-border">
                    <h3 className="box-title">Materiais</h3>
                </div>

                {props.canEdit && <div className="box-body">
                    <div style={{ marginBottom: "7px" }}>
                        {requisicao && requisicao.status === "Rascunho" && <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleNewItem}><i className="fa fa-plus" /> Adicionar</button>}
                    </div>
                </div>
                }

                <div className="table-responsive">
                    <table className="table table-striped table-bordered no-margin">
                        <thead>
                            <tr className="bg-light-blue">
                                <th>Qtde</th>
                                <th>Produto</th>
                                <th>Site</th>
                                <th>Status</th>
                                <th>Comprar</th>
                                <th>Montagem</th>
                                <th>Detalhamento Técnico</th>
                                <th>Previsao Entrega</th>
                                <th>Data Entrega</th>
                                {props.canEdit && (requisicao.status === 'Rascunho' && data.find(w => w.status === 'Rascunho')) && <th>Excluir</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.quantidade}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.site}</td>
                                        <td>{item.status}</td>
                                        <td>{item.comprar && item.comprar === true ? 'Sim' : 'Não'}</td>
                                        <td>{item.montagem && item.montagem === true ? 'Sim' : 'Não'}</td>
                                        <td>{item.detalhamentoTecnico}</td>
                                        <td>{config.dateFix(item.previsaoEntrega)}</td>
                                        <td>{config.dateFix(item.dataEntrega)}</td>
                                        {props.canEdit && (requisicao.status === 'Rascunho' && item.status === "Rascunho") ? <td align="center"><span> <i className="fa fa-trash" onClick={() => handleDelete(item.id)} /></span></td> : false}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <Modal show={params.showModal} bsSize='large' onHide={handleHide}>
                    <Modal.Header closeButton >
                        <Modal.Title>Adicionar Produto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered table-striped">
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>Descrição</th>
                                                <th>Qtd</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sites.length > 0 && sites.map((item, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td colSpan="6" style={{ background: '#E4E4E4' }}><b>{item}</b></td>
                                                        </tr>
                                                        {escopo.length > 0 && escopo[i].filter(c => c.nomeSite === item && c.idMaterial > 0 && (c.tipo === 'venda' || c.tipo === 'locacao')).map((item, j) => {
                                                            return (
                                                                <tr key={j}>
                                                                    <td>{item.nome}</td>
                                                                    <td>{item.quantidade}</td>
                                                                    <td>
                                                                        {(materiaisProjeto.length === 0 || !materiaisProjeto.find(w => w.siteId === item.siteId && w.materialId === item.idMaterial)) ?
                                                                            <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={() => handleAddItem(item)}> <i className="fa fa-plus" /> Adicionar</button>
                                                                            : 'Já solicitado.'}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment >
    )
}
