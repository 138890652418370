import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function GraficoProspeccao(props) {
    const endPoint = 'lead/report';
    const [titleSemana, setTitleSemana] = useState('');
    const [labels, setLabels] = useState([]);
    const [dataOrigem, setDataOrigem] = useState([]);
    const [dataOrigemConsolidado, setDataOrigemConsolidado] = useState([]);
    const [dataInteracaoConsolidado, setdataInteracaoConsolidado] = useState([]);
    const [dataInteracao, setDataInteracao] = useState([]);
    const [dates, setDates] = useState([]);
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;

    const onLoad = () => {
        handleSearch();
    }

    useEffect(onLoad, [props.dataEntradaInicio, props.dataEntradaFim]);

    const handleSearch = () => {
        if (!props.dataEntradaInicio)
            return;

        apiHelper.get(`${endPoint}/prospeccao?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&analistaVeolink=${props.analistaVeolink}`).then(res => {
            if (res.data.code === 200 && res.data.data) {

                let dates = [];
                if (res.data.data.datasOrdenadas !== undefined) {
                    res.data.data.datasOrdenadas.map(item => {
                        dates.push(item.ano + '-' + item.semana);
                        return true;
                    });
                    setDataOrigem(res.data.data.origem);
                    setDataOrigemConsolidado(res.data.data.origemConsolidado);
                    setdataInteracaoConsolidado(res.data.data.interacaoConsolidado);
                    setDataInteracao(res.data.data.interacao);
                    setDates([...new Set(dates)]);
                }
            }
        });
    };

    const gerarGrafico = () => {
        if (!props.dataEntradaInicio)
            return;
        let fti = [];
        let mkt = [];
        let pap = [];
        let outros = [];
        let visitasObtidas = [];
        let gerouProposta = [];
        let mediaFti = [];
        let mediaMkt = [];
        let mediaPap = [];
        let mediaOutros = [];
        let mediaVisitasObtidas = [];
        let mediaGerouProposta = [];
        let datasets = []

        setTitleSemana(`(Semana ${dates[0]} a Semana ${dates[dates.length - 1]})`);

        dates.map(d => {
            let objFTI = dataOrigem.filter(p => p.origem === 'FTI' && (p.ano + '-' + p.semana) === d);
            fti.push(objFTI.length > 0 ? objFTI[0].total : 0)

            let objMKT = dataOrigem.filter(p => p.origem === 'MKT' && (p.ano + '-' + p.semana) === d);
            mkt.push(objMKT.length > 0 ? objMKT[0].total : 0)

            let objPAP = dataOrigem.filter(p => p.origem === 'PAP' && (p.ano + '-' + p.semana) === d);
            pap.push(objPAP.length > 0 ? objPAP[0].total : 0)

            let objOutro = dataOrigem.filter(p => p.origem === 'OUTROS' && (p.ano + '-' + p.semana) === d);
            outros.push(objOutro.length > 0 ? objOutro[0].total : 0)

            let objVisita = dataInteracao.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === d);
            visitasObtidas.push(objVisita.length > 0 ? objVisita[0].total : 0)

            let objProposta = dataInteracao.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === d);
            gerouProposta.push(objProposta.length > 0 ? objProposta[0].total : 0)

            let mediaTotalFTI = dataOrigemConsolidado.filter(p => p.origem === 'FTI' && (p.ano + '-' + p.semana) === d);
            let mediaTotalMKT = dataOrigemConsolidado.filter(p => p.origem === 'MKT' && (p.ano + '-' + p.semana) === d);
            let mediaTotalPAP = dataOrigemConsolidado.filter(p => p.origem === 'PAP' && (p.ano + '-' + p.semana) === d);
            let mediaTotalOutros = dataOrigemConsolidado.filter(p => p.origem === 'OUTROS' && (p.ano + '-' + p.semana) === d);
            let mediaTotalVisitas = dataInteracaoConsolidado.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === d);
            let mediaTotalPropostas = dataInteracaoConsolidado.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === d);

            mediaFti.push(mediaTotalFTI.length > 0 ? mediaTotalFTI[0].media : 0);
            mediaMkt.push(mediaTotalMKT.length > 0 ? mediaTotalMKT[0].media : 0)
            mediaPap.push(mediaTotalPAP.length > 0 ? mediaTotalPAP[0].media : 0);
            mediaOutros.push(mediaTotalOutros.length > 0 ? mediaTotalOutros[0].media : 0);
            mediaVisitasObtidas.push(mediaTotalVisitas.length > 0 ? mediaTotalVisitas[0].media : 0);
            mediaGerouProposta.push(mediaTotalPropostas.length > 0 ? mediaTotalPropostas[0].total : 0);

            return null;
        })

        datasets.push({
            label: 'Média de Contrato via FTI',
            type: 'line',
            data: mediaFti,
            fill: false,
            borderColor: '#5a6caa',
            backgroundColor: '#5a6caa',
            pointBorderColor: '#5a6caa',
            pointBackgroundColor: '#5a6caa',
            pointHoverBackgroundColor: '#5a6caa',
            pointHoverBorderColor: '#5a6caa',
            yAxisID: 'y-axis-1'

        });
        datasets.push({
            label: 'Média de Contrato via MKT',
            type: 'line',
            data: mediaMkt,
            fill: false,
            borderColor: '#523a20',
            backgroundColor: '#523a20',
            pointBorderColor: '#523a20',
            pointBackgroundColor: '#523a20',
            pointHoverBackgroundColor: '#523a20',
            pointHoverBorderColor: '#523a20',
            yAxisID: 'y-axis-1'

        });
        datasets.push({
            label: 'Média de Contrato via PAP',
            type: 'line',
            data: mediaPap,
            fill: false,
            borderColor: '#bd1b18 ',
            backgroundColor: '#bd1b18 ',
            pointBorderColor: '#bd1b18 ',
            pointBackgroundColor: '#bd1b18 ',
            pointHoverBackgroundColor: '#bd1b18 ',
            pointHoverBorderColor: '#bd1b18 ',
            yAxisID: 'y-axis-1'

        });
        datasets.push({
            label: 'Média Outros',
            type: 'line',
            data: mediaOutros,
            fill: false,
            borderColor: '#C8C832 ',
            backgroundColor: '#C8C832 ',
            pointBorderColor: '#C8C832 ',
            pointBackgroundColor: '#C8C832 ',
            pointHoverBackgroundColor: '#C8C832 ',
            pointHoverBorderColor: '#C8C832 ',
            yAxisID: 'y-axis-1'

        });
        datasets.push({
            label: 'Média de Visitas Obtidas',
            type: 'line',
            data: mediaVisitasObtidas,
            fill: false,
            borderColor: '#86a265',
            backgroundColor: '#86a265',
            pointBorderColor: '#86a265',
            pointBackgroundColor: '#86a265',
            pointHoverBackgroundColor: '#86a265',
            pointHoverBorderColor: '#86a265',
            yAxisID: 'y-axis-1'
        });
        datasets.push({
            label: 'Média de Propstas Geradas',
            type: 'line',
            data: mediaGerouProposta,
            fill: false,
            borderColor: '#030303',
            backgroundColor: '#030303',
            pointBorderColor: '#030303',
            pointBackgroundColor: '#030303',
            pointHoverBackgroundColor: '#030303',
            pointHoverBorderColor: '#030303',
            yAxisID: 'y-axis-1'

        });
        datasets.push(
            {
                type: 'bar',
                label: 'Contatos via FTI',
                data: fti,
                fill: false,
                backgroundColor: '#bfd7f1',
                yAxisID: 'y-axis-1'
            }
        );
        datasets.push(
            {
                type: 'bar',
                label: 'Contatos via MKT',
                data: mkt,
                fill: false,
                backgroundColor: '#c18500',
                yAxisID: 'y-axis-1'
            }
        );
        datasets.push(
            {
                type: 'bar',
                label: 'Contatos via PAP',
                data: pap,
                fill: false,
                backgroundColor: '#f9acb2',
                yAxisID: 'y-axis-1'
            }
        );
        datasets.push(
            {
                type: 'bar',
                label: 'Outros',
                data: pap,
                fill: false,
                backgroundColor: '#FFFE33',
                yAxisID: 'y-axis-1'
            }
        );
        datasets.push(
            {
                type: 'bar',
                label: 'Visitas Obtidas',
                data: visitasObtidas,
                fill: false,
                backgroundColor: '#cae0bc',
                yAxisID: 'y-axis-1'
            }
        );
        datasets.push(
            {
                type: 'bar',
                label: 'Propostas Geradas',
                data: gerouProposta,
                fill: false,
                backgroundColor: '#aaaaaa',
                yAxisID: 'y-axis-1'
            }
        );

        setLabels(dates);
        const dataAux = {
            datasets: datasets
        }
        return dataAux;
    };

    const options = {
        responsive: true,
        labels: labels,
        legend: { position: 'bottom' },
        tooltips: { mode: 'label' },
        elements: { line: { fill: false } },
        scales: {
            xAxes: [
                { display: true, gridLines: { display: false }, labels: labels }
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-1',
                    gridLines: { display: true },
                    labels: { show: true },
                    ticks: { beginAtZero: true, stepSize: 2 }
                }
            ]
        }
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">Análise de Prospecção {titleSemana}</h3>
            </div>
            <div className="panel-body">
                {setDataOrigem.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {dates.map((item, i) => {
                                                    return (<td key={i}>{item}</td>)
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Contato via FTI</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigem.filter(p => p.origem === 'FTI' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Contato via MKT</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigem.filter(p => p.origem === 'MKT' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Contato via PAP</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigem.filter(p => p.origem === 'PAP' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>OUTROS</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigem.filter(p => p.origem === 'OUTROS' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Visitas Obtidas</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataInteracao.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Propostas Geradas</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataInteracao.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Média de Contratos via FTI</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigemConsolidado.filter(p => p.origem === 'FTI' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].media : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Média de Contratos via MKT</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigemConsolidado.filter(p => p.origem === 'MKT' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].media : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Média de Contratos via PAP</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigemConsolidado.filter(p => p.origem === 'PAP' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].media : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Média de Contratos via PAP</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigemConsolidado.filter(p => p.origem === 'OUTROS' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].media : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Média de Visitas Obtidas</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataInteracaoConsolidado.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].media : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Média de Propostas Geradas</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataInteracaoConsolidado.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].media : 0}</td>)
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
