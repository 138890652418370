import React, { Component } from "react";
import AuthHelper from './auth/AuthHelper';
import { Row, Col, GlyIcon, FormGroup } from './adminlte/AdminLTE';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            rememberme: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.Auth.loggedIn())
            this.props.history.replace('/');

        document.body.classList.remove('sidebar-mini');
        document.body.classList.add('hold-transition');
        document.body.classList.add('login-page');
        document.getElementById('root').className = 'login-box';

        this.setState({
            username: localStorage.getItem('login_username'),
            password: localStorage.getItem('login_password'),
            rememberme: localStorage.getItem('login_rememberme')
        });
    }

    Auth = new AuthHelper();

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.name;

        this.setState({ [name]: value });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();

        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                if (res) {

                    if (this.state.rememberme) {
                        localStorage.setItem('login_username', this.state.username);
                        localStorage.setItem('login_password', this.state.password);
                        localStorage.setItem('login_rememberme', this.state.rememberme);
                    }
                    else {
                        localStorage.removeItem('login_username');
                        localStorage.removeItem('login_password');
                        localStorage.removeItem('login_rememberme');
                    }
                    //console.log(localStorage.getItem('last_url'));
                    //if (localStorage.getItem('last_url'))
                    //   history.push(localStorage.getItem('last_url'));
                    //else
                    window.location.href = '/';
                }
            })
            .catch(err => {
                alert(err);
            });
    }

    render() {
        return (
            <div className="login-box">
                <div className="login-logo">
                    <a href="/login"><b>Siga</b>ERP</a>
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Entre com os dados para iniciar a sess&atilde;o</p>
                    <form className="box-form">
                        <FormGroup placeholder="Username" autoFocus value={this.state.username} name="username" type="text" onChange={this.handleChange}>
                            <GlyIcon name="envelope" />
                        </FormGroup>
                        <FormGroup placeholder="Password" name="password" type="password" value={this.state.password} onChange={this.handleChange}>
                            <GlyIcon name="lock" />
                        </FormGroup>
                        <Row>
                            <Col xs={8}>
                                <div className="checkbox">
                                    <label><input type="checkbox" name="rememberme" onClick={this.handleChange} defaultChecked={this.state.rememberme} />{' Lembrar Me'}</label>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <button className="btn btn-primary btn-block btn-flat" onClick={this.handleFormSubmit}>Login</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        );
    }
}
