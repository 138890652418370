import React from 'react';
import moment from 'moment';
import { Icon } from '../';

export const Formatter = {
    ValueFormat,
    Enabled,
    ProgressBar,
    YesNo,
    Color,
    DateFormat,
    DateTimeFormat,
    Bool,
    Check,
    Badge,
    Icons
};

function ValueFormat(type, value) {
    if (!type) return value;

    let val = value;

    if (type === "Enabled" || type === "enabled")
        val = Enabled(val);
    else if (type === "YesNo")
        val = YesNo(val);
    else if (type === "ProgressBar")
        val = ProgressBar(val);
    else if (type === "DateFormat")
        val = DateFormat(val);
    else if (type === "DateTimeFormat")
        val = DateTimeFormat(val);
    else if (type === "Boolean" || type === "boolean" || type === "bool")
        val = Bool(val)
    else if (type === "Check" || type === "check")
        val = Check(val)
    else if (type === "Icon")
        val = Icons(val)
    else if (type.includes("Badge"))
        val = Badge(type, val)

    return val;
}

function Badge(type, value) {
    //Badge:Ativo=success,Suspenso=warning,*=secondary
    let criteria = type.replace("Badge:", "").split(",");
    let bg = "";

    criteria.map(m => {
        let f = m.split("=");

        if (f[0] === value) bg = f[1];
        else if (f[0] === '*' && !type.includes(value)) bg = f[1];

        return "";
    });

    return <span className={`badge bg-${bg}`}>{value}</span>
};

function Color(value, color) {
    return <span className={"badge bg-" + color}>{value}</span>
};

function Bool(value) {
    if (value === true || value === 'true' || value === 1 || value === "1")
        return "Sim"
    else if (value === false || value === 'false' || value === 0 || value === "0")
        return "Não"
    else
        return value;
};

function Enabled(value) {
    if (value === true || value === 'true' || value === 1 || value === "1")
        return <span className="badge badge-success">Ativo</span>
    else if (value === false || value === 'false' || value === 0 || value === "0")
        return <span className="badge badge-secondary">Inativo</span>
    else
        return value;
};

function YesNo(value) {
    if (value === true || value === 'true')
        return <span className="badge badge-success">Sim</span>
    else if (value === false || value === 'false')
        return <span className="badge badge-secondary">Não</span>
    else
        return value;
};

function Check(value) {
    if (value === true || value === 'true')
        return <Icon name="check-square" />
    else
        return <Icon name="square" iconStyle="regular" />
};

function Icons(value) {
    return <Icon name={value} />
};

function ProgressBar(value) {
    return (
        <div className="progress mb-3">
            <div className="progress-bar bg-info" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style={{ width: value + "%" }}>
                <span className="sr-only">{value + "% Completo"}</span>
            </div>
        </div>
    )
};

function DateFormat(value) {
    let val = value ? moment(new Date(value)).format('DD/MM/YYYY') : "";
    return val === '01/01/0001' ? "" : val;
};

function DateTimeFormat(value) {
    let val = value ? moment(new Date(value)).format('DD/MM/YYYY HH:mm') : "";
    return val === '01/01/0001 00:00' ? "" : val;
};
