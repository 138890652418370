import React, { useState, useEffect } from 'react';
import { apiHelper } from '../';

export function Select(props) {
    let source = null;
    
    try {
        source = React.Children.toArray(props.children).filter(f => f.props.endpoint)[0];
    } catch (error) {
        source = null;
    }

    const [data, setData] = useState([]);

    const fetchData = () => {
        source && apiHelper.get(source.props.endpoint)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.data)
                        setData(res.data.data);
                    else
                        setData(res.data);
                }
                else
                    console.log(res.data);
            })
    };

    useEffect(fetchData, [props, props.children]);

    return (
        <select {...props}>
            {React.Children.map(props.children, (child, i) => {
                let hasEndPoint = child && child.props.endpoint;

                if (hasEndPoint) {
                    return data && data.length > 0 && data.sort((a, b) => String(a[child.props.textfield || "name"]).toUpperCase().normalize("NFD") > String(b[child.props.textfield || "name"]).toUpperCase().normalize("NFD") ? 1 : -1)
                        .map((item, i) => {
                            return <option key={i} value={item[child.props.valuefield || "id"]}>{item[child.props.textfield || "name"]}</option>;
                        })
                }
                else
                    return child;
            })}
        </select>
    )
}