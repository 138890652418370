import React, { useState, useEffect } from 'react';
import { apiHelper, config, toast } from '../index';

export function DatasourceForm(props) {
    const endPoint = "portal/crud/ReportsSource/";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const title = props.title;
    const fetchData = () => {

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
                setData(res.data.data);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        } else
            setData({});
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (rowId === 0) {
            apiHelper.post(`${endPoint}`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    } else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/id`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    } else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleDelete = () => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}${rowId}/id`).then(res => {
            toast.success('Datasource excluído com sucesso!');
            props.onHide(true);
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    return (
        <div className="card card-primary card-outline">
            <form onSubmit={handleSubmit}>
                <div className="card-header">
                    <h3 className="card-title">{title}</h3>
                </div>
                <div className="card-body">

                    <div className="row">
                        <div className="form-group col-sm-8">
                            <label>Nome *</label>
                            <input id="name" type="text" className="form-control" onChange={handleChange} value={data.name || ''} required />
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Tipo</label>
                            {/* <input id="type" type="text" className="form-control" onChange={handleChange} value={data.type || ''} /> */}


                            <select id="type" className="form-control" onChange={handleChange} value={data.type || ''}>
                                <option value="">Selecione</option>
                                <option value="query">Consulta</option>
                                <option value="api">API</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-3">
                            <label>Tipo de Banco de Dados</label>
                            <input id="db_type" type="text" className="form-control" onChange={handleChange} value={data.db_type || ''} />
                        </div>
                        <div className="form-group col-sm-9">
                            <label>Conexão</label>
                            <input id="db_conn" type="text" className="form-control" onChange={handleChange} value={data.db_conn || ''} />
                        </div>
                        <div className="form-group col-sm-12">
                            <label>Source *</label>
                            <textarea id="source" className="form-control" onChange={handleChange} value={data.source || ''} required />
                        </div>
                    </div>
                </div>
                <div className="card-footer p-2">
                    <div className="float-right">
                        <button type="submit" className="btn btn-primary ml-1">Salvar</button>
                        <button type="button" className="btn btn-default ml-1" onClick={() => props.onHide(true)}>Fechar</button>
                        {rowId > 0 && <button type="button" className="btn btn-default ml-1" onClick={handleDelete}>Excluir</button>}
                    </div>
                </div>
            </form>
        </div>
    )
}