import React, { useState, useEffect } from 'react';
import { Modals, Button, config, apiHelper, toast } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function RegionalUsuarioForm(props) {
    const endPoint = 'v2/regional/usuario';
    const regionalId = props.parentId;

    const [data, setData] = useState({
        fkRegional: regionalId,
        pessoaId: 0,
        nome: '',
        perfil: '',
    });

    const fetchData = () => {
        let obj = {};

        if (props.rowId > 0) {
            apiHelper.get(`${endPoint}/${props.rowId}/pkRegionalSubordinado`)
                .then(res => {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('asdfsadf');
                });
        }
    }


    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (props.rowId === 0) {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res.data);
                props.onImport();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${props.rowId}/pkRegionalSubordinado`, data).then(res => {
                config.httpResult(res.data);
                props.onImport();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const handleAddUsuario = (row) => {
        if (row)
            setData({ ...data, pessoaId: row.pkUsuario, nome: row.nome });
    }

    const onHide = () => {
        props.onHide();
    }

    console.log(data.nome);
    return (
        <Modals title={regionalId > 0 ? 'Editar Usuário subordinado' : 'Novo Usuário'} show={props.show} onHide={onHide} >
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-md-12">
                        {data.nome !== '' ? <PesquisaGenericaHooks required textField="nome" titulo="Usuário" selecao={data.nome} endpoint="portal/crud/usuario/?filter=isExcluido::equal::false;;AND;;nome::contains::" onImport={(row) => { handleAddUsuario(row); }} />
                            : <PesquisaGenericaHooks required textField="nome" titulo="Usuário" selecao={data.nome} endpoint="portal/crud/usuario/?filter=isExcluido::equal::false;;AND;;nome::contains::" onImport={(row) => { handleAddUsuario(row); }} />}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label htmlFor="perfil">Perfil</label>
                        <select id="perfil" className="form-control" onChange={handleChange} value={data.perfil}>
                            <option value="">Selecione</option>
                            <option value="Supervisor">Supervisor</option>
                            <option value="Tecnico">Técnico</option>
                            <option value="Gerente">Gerente</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="box-tool pull-right">
                            <div className="btn-toolbar">
                                <Button type="submit" bsStyle="success">Salvar</Button>
                                <Button bsStyle="default" onClick={onHide}>Cancelar</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modals>
    )
}
