import React from "react";
import { Content } from '../';

export function Home(props) {
    const setTheme = (val) => {
        localStorage.setItem("theme", val);
        window.location.reload();
    }

    return (
        <Content title="Home">
            <button className="btn btn-default mr-2" onClick={() => setTheme('adminlte')}>AdminLTE 2</button>
            <button className="btn btn-default" onClick={() => setTheme('adminlte3')}>AdminLTE 3</button>
        </Content>
    );
}
