import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select, config, Link } from '../index';
import Core from '../Core';
import moment from 'moment-timezone';

export function OrcamentoImplantacaoForm(props) {
    const endPoint = 'portal/crud/ComercialOrcamento';
    const parentId = props.parentId;
    const [data, setData] = useState({});
    const folder = config.getFolderModuloOportunidade();
    const [anexoImplantacao, setAnexoImplantacao] = useState({
        folder: config.getFolderModuloOportunidade(),
        fileName: '',
        file: {}
    });

    const fetchData = () => {
        let obj = {};
        if (parentId && parentId > 0) {
            apiHelper.get(`${endPoint}/${parentId}/id`).then(res => {
                let data = res.data.data;
                if (data.dataNecessidade !== null)
                    data.dataNecessidade = `${data.dataNecessidade.split('-')[0]}-${data.dataNecessidade.split('-')[1]}-${data.dataNecessidade.split('-')[2].substr(0, 2)}`;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregara a implantação!');
            });
        }
    }

    useEffect(fetchData, [parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "anexoImplantacao") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setAnexoImplantacao({ ...anexoImplantacao, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                setAnexoImplantacao({ ...anexoImplantacao, folder: anexoImplantacao.folder, fileName: fileName, file: file });
                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let obj = data;
        obj.custosSolicitados = true;
        //setData({ ...data, custosSolicitados: "true" });

        if (anexoImplantacao.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoImplantacao.file, anexoImplantacao.folder, anexoImplantacao.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        apiHelper.put(`orcamento/${parentId}/implantacao`, obj)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    }

    const downloadAnexo = async (fileName) => {
        await Core.api_download(folder, fileName).then(res => {
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className='col-sm-12'>
                    <div className="form-group col-sm-10">
                        <label>Anexo</label>
                        <input type="file" id="anexoImplantacao" onChange={handleChange} />
                    </div>
                    <div className="col-sm-2">
                        {data.anexoImplantacao && <Link to="#" onClick={() => downloadAnexo(data.anexoImplantacao)}> Download Anexo</Link>}
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Horário Atividades *</label>
                        <Select id="horarioAtividade" className="form-control" onChange={handleChange} value={data.horarioAtividade} required>
                            <option value="">Selecione</option>
                            <option value="Horário Comercial">Horário Comercial</option>
                            <option value="Fim de Semana">Fim de Semana</option>
                            <option value="Noturno">Noturno</option>
                            <option value="Fim de Semana Noturno">Fim de Semana Noturno</option>
                        </Select>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Data Necessidade *</label>
                        <input id="dataNecessidade" type="date" className="form-control" onChange={handleChange} value={data.dataNecessidade} placeholder="dd/mm/yyyy" required />
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Necessário Tubulação? *</label>
                        <Select id="necessarioTubulacao" className="form-control" onChange={handleChange} value={data.necessarioTubulacao} required>
                            <option value="">Selecione</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </Select>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Meio de elevação é do cliente? *</label>
                        <Select id="meiosElevacaoCliente" className="form-control" onChange={handleChange} value={data.meiosElevacaoCliente} required>
                            <option value="">Selecione</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                            <option value="Não é Necessário">Não é Necessário</option>
                        </Select>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Tubulação e equipamentos são em locais altos (Acima de 2m)? *</label>
                        <Select id="tubulacaoEquipamentoLocalAlto" className="form-control" onChange={handleChange} value={data.tubulacaoEquipamentoLocalAlto} required>
                            <option value="">Selecione</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </Select>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Existe exigência do cliente, para disponibilidade de mais de uma pessoa para a manutenção? *</label>
                        <Select id="disponibilidadeMaisPessoas" className="form-control" onChange={handleChange} value={data.disponibilidadeMaisPessoas} required>
                            <option value="">Selecione</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </Select>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>SLA Manutenção Corretiva (Em horas)</label>
                        <input id="slaManutencaoCorretiva" type="number" className="form-control" onChange={handleChange} value={data.slaManutencaoCorretiva || ""} />
                    </div>
                    <div className="form-group col-sm-6">
                        <label>SLA Manutenção preventiva (Em Meses)</label>
                        <input id="slaManutencaoPreventiva" type="number" className="form-control" onChange={handleChange} value={data.slaManutencaoPreventiva || ""} />
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        {!data.custosSolicitados && <button type="submit" className="btn btn-primary pull-right">Solicitar Custos</button>}
                        {data.custosSolicitados && <span><b>Custos Solicitados</b></span>}
                    </div>
                </div>
            </div>
        </form>
    )
}
