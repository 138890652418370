import React from 'react';
import { GoogleMap } from '../../';

export function MapaGeral(props) {
    React.useEffect(() => {
     
    }, []);

    return (
        <div className="card card-primary card-outline">
            <div className="card-header">
                <h3 className="card-title">Mapa Geral</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool"><i className="fas fa-sync" /></button>
                    <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand" /></button>
                </div>
            </div>
            <div className="card-body p-1" style={{ height: 400, overflow: 'auto' }}>
                <GoogleMap height="100%">
                    {/* lista de obras ativas */}
                </GoogleMap>
            </div>
        </div>
    );
}
