import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function PrazosAtendimento(props) {
    const endPoint = 'atendimentoReport/prazosAtendimento';
    const [data, setData] = useState([]);
    const title = 'Prazos de Atendimento';
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: { mode: 'point' },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 5 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!dataEntradaInicio && !dataEntradaFim)
            return;

        let valuesDentroPrazo = [];
        let valuesForaPrazo = [];
        let labels = [];

        var todosPrazos = data.dentroPrazo.concat(data.foraPrazo);
        todosPrazos.map((item) => {
            if (!labels.includes(item.mes))
                labels.push(item.mes);

            return null;
        });

        labels.map((mes) => {
            let dentroPrazo = data.dentroPrazo.find(c => c.mes === mes) !== undefined ? data.dentroPrazo.find(c => c.mes === mes).qtdDentroPrazo : 0;
            let foraPrazo = data.foraPrazo.find(c => c.mes === mes) !== undefined ? data.foraPrazo.find(c => c.mes === mes).qtdForaPrazo : 0;

            valuesDentroPrazo.push(dentroPrazo);
            valuesForaPrazo.push(foraPrazo);

            return "";
        });

        //Acumulados no ano Dentro e Fora do Prazo 
        let totalAcumuladoDentroPrazo = 0;
        let totalAcumuladoForaPrazo = 0;
        let mesesAno = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

        mesesAno.map((mes) => {
            let acumuladoDentroPrazo = data.acumuladoDentroPrazo.find(c => c.mes === mes) !== undefined ? data.acumuladoDentroPrazo.find(c => c.mes === mes).qtdAcumuladoDentroPrazo : 0;
            let acumuladoForaPrazo = data.acumuladoForaPrazo.find(c => c.mes === mes) !== undefined ? data.acumuladoForaPrazo.find(c => c.mes === mes).qtdAcumuladoForaPrazo : 0;
            totalAcumuladoDentroPrazo = totalAcumuladoDentroPrazo + acumuladoDentroPrazo;
            totalAcumuladoForaPrazo = totalAcumuladoForaPrazo + acumuladoForaPrazo;
            return null;
        });

        labels.push("Acumulado");
        valuesDentroPrazo.push(totalAcumuladoDentroPrazo);
        valuesForaPrazo.push(totalAcumuladoForaPrazo);

        let datasets = [
            { label: 'Dentro Prazo', backgroundColor: '#3e71c4', borderWidth: 1, data: valuesDentroPrazo },
            { label: 'Fora Prazo', backgroundColor: '#F44343', borderWidth: 1, data: valuesForaPrazo },
        ];

        const dataAux = {
            labels: labels,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
                {((data.dentroPrazo && data.dentroPrazo.length > 0) || (data.foraPrazo && data.foraPrazo.length > 0)) &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
