import React, { useEffect, useState } from 'react';
import { apiHelper, toast } from '../../index';

export function UsuarioSitesAssociados(props) {
    const endPoint = 'portal/crud/uvw_v2_ObterSitesPessoa';
    const endPointComercialSite = 'comercial/site';
    const parentId = props.parentId;
    const nome = props.nome;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        idSite: '',
        site: ''
    });
    const [params, setParam] = useState({
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = `idPessoa::equal::${parentId};;`;
        else
            where = `idPessoa::equal::${parentId};;And;;${where}`;

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [props.parentId, props.refresh]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'site')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleRemover = (item) => {
        apiHelper.api_delete(`${endPointComercialSite}/removerPessoaSite/${parentId}/${item.idSite}`).then(res => {
            props.onUpdate();
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleRemoverTodosSites = () => {
        if (window.confirm(`Deseja excluir todos os sites associados ao usuário ${nome}`)) {
            apiHelper.api_delete(`${endPointComercialSite}/removerTodosSitesPessoa/${parentId}`).then(res => {
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o registro.');
            });
        }
    }

    const handleRefresh = () => {
        setFilter({
            idSite: '',
            site: ''
        });

        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: "7px", marginTop: "7px" }}>
                        <button type="button" className="btn btn-danger btn-sm btn-flat" onClick={() => { handleRemoverTodosSites() }}>Remover todos</button>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>Id</th>
                                    <th>Site</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                    <td><input id="idSite" type="text" className="form-control input-sm" onChange={handleChange} value={filter.idSite} /></td>
                                    <td><input id="site" type="text" className="form-control input-sm" onChange={handleChange} value={filter.site} /></td>
                                    <td></td>
                                </tr>
                                {data.length > 0 && data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: '50px' }}>#</td>
                                            <td>{item.idSite}</td>
                                            <td>{item.site}</td>
                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={() => { handleRemover(item, false) }}>Remover</button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
