import React, { useState } from 'react';
import { apiHelper, toast, Select } from './../../index';

export function QuantitativoObrasAno(props) {
    const endPoint = "v2/projeto/relatorioQtdObrasAno";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        gerenteResponsavel: '',
        aprovador: ''
    });

    const [distinctClientes, setDistinctClientes] = useState([]);
    const [distinctAnos, setDistinctAnos] = useState([]);

    const handleSearch = () => {
        apiHelper.post(`${endPoint}`, filter)
            .then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data.result;

                    let dadosCliente = [];
                    let totalGeralColunas = [];
                    let totalGeralLinhas = [];
                    let totalGeralColunaTotal = 0;
                    let clientes = data.map((item, i) => { return item.cliente });
                    let ano = data.map((item, i) => { return item.ano });

                    setDistinctClientes(clientes.filter((v, i, a) => a.indexOf(v) === i));
                    setDistinctAnos(ano.filter((v, i, a) => a.indexOf(v) === i));

                    distinctAnos.forEach(ano => {
                        let totalColuna = 0;

                        distinctClientes.forEach(input => {
                            let obj = {
                                cliente: input,
                                ano: ano,
                                qtdObras: data.filter(c => c.ano === ano && c.cliente === input)[0] === undefined
                                    ? 0
                                    : data.filter(c => c.ano === ano && c.cliente === input)[0].qtdObras
                            };

                            dadosCliente.push(obj);
                            totalColuna += obj.qtdObras;
                        });

                        let objCol = {
                            ano: ano,
                            totalColuna: totalColuna
                        };
                        totalGeralColunas.push(objCol);
                    });

                    distinctClientes.forEach((nomeCliente, i) => {
                        let totalLinha = 0;
                        let cliente = dadosCliente.filter(c => c.cliente === nomeCliente);

                        //Soma a quantidade de obras do cliente na linha
                        for (var j = 0; j < cliente.length; j++) {
                            totalLinha += cliente[j].qtdObras;
                        }

                        let objRow = {
                            cliente: nomeCliente,
                            totalLinha: totalLinha
                        };

                        totalGeralLinhas.push(objRow);
                    });

                    totalGeralLinhas.forEach((item, i) => {
                        totalGeralColunaTotal += item.totalLinha;
                    });

                    dadosCliente.totalGeralColunas = totalGeralColunas;
                    dadosCliente.totalGeralLinhas = totalGeralLinhas;
                    dadosCliente.totalGeralColunaTotal = totalGeralColunaTotal;
                    setData(dadosCliente);

                }
            }).catch(error => {
                toast.error('Atenção, não foi possível carregar os registros.');
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            gerenteResponsavel: '',
            aprovador: ''
        });
        setData([]);
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Quantitativo de Obras por Ano - Em Andamento</h3>
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="form-group col-sm-4">
                        <label>Gerente Responsável</label>
                        <Select id="aprovador" className="form-control" onChange={handleChange} value={filter.aprovador}>
                            <option value="">Todos</option>
                            <option endpoint="comercial/pessoa/perfil?perfil=GestorOperacional" valuefield="id" textfield="nome" />
                        </Select>
                    </div>
                    <div className="form-group col-sm-4">
                        <label>GP Responsável</label>
                        <Select id="gerenteResponsavel" className="form-control" onChange={handleChange} value={filter.gerenteResponsavel}>
                            <option value="">Todos</option>
                            <option endpoint="comercial/pessoa/perfil?perfil=GerenteProjeto" valuefield="id" textfield="nome" />
                        </Select>
                    </div>
                    <div className="form-group col-sm-4">
                        <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                        <button type="button" className="btn btn-default pull-right" onClick={handleRefresh}>Limpar</button>
                    </div>
                </div>
                <React.Fragment>
                    {data && data.length > 0 && <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th>Cliente</th>
                                            {distinctAnos && distinctAnos.map((item, i) => {
                                                return (
                                                    <th key={item}>{item}</th>
                                                )
                                            })}
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {distinctClientes && distinctClientes.map((cliente, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{cliente}</td>
                                                    {distinctAnos && distinctAnos.map((ano, i) => {
                                                        return (
                                                            <td key={i}>{data.filter(c => c.cliente === cliente && c.ano === ano)[0].qtdObras}</td>
                                                        )
                                                    })}
                                                    <td style={{ 'backgroundColor': '#EBEBEC' }}>{data.totalGeralLinhas.filter(c => c.cliente === cliente)[0].totalLinha}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr key={data.total}>
                                            <td className='bg-light-blue'>Total</td>
                                            {distinctAnos && distinctAnos.map((ano, i) => {
                                                return (
                                                    <td key={i} className='bg-light-blue'>{data.totalGeralColunas.filter(c => c.ano === ano)[0].totalColuna}</td>
                                                )
                                            })}
                                            <td className='bg-light-blue'>{data.totalGeralColunaTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </React.Fragment>
            </div>
        </div>
    )
}
