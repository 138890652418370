import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

export class Modals extends Component {
    footer(content) {
        return (
            <Modal.Footer>
                {content}
            </Modal.Footer>
        );
    }

    render() {
        const {
            title, show = false, onHide, children,
            backdrop = "static", footer, size = undefined
        } = this.props;

        return (
            <Modal bsSize={size} show={show} onHide={onHide} backdrop={backdrop}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                {footer && this.footer(footer)}
            </Modal>
        );
    }
}
