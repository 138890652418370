import React, { useEffect, useState } from 'react';
import { config, apiHelper, Modal, Link, toast } from '../index';
import { LeadContatoForm } from './LeadContatoForm';

export function LeadContatos(props) {
    const endPoint = 'portal/crud/ComercialLeadContato';
    const parentId = props.parentId;
    const leadId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: ""
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=leadId::equal::${parentId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [parentId]);

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh = false) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}/id`).then(res => {
            config.httpResult(res.data);
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    return (
        <React.Fragment>
            <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("contato", "Novo Contato", 0)}><i className="fa fa-plus" /> Adicionar</button>
            </div>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="bg-light-blue">
                            <th style={{ width: "50px" }}>#</th>
                            <th>Contato</th>
                            <th>Cargo</th>
                            <th>Departamento</th>
                            <th>Tipo Contato</th>
                            <th>Telefone Fixo</th>
                            <th>Telefone Celular</th>
                            <th>Email</th>
                            <th>Linkedin</th>
                            <th>Observação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ width: '50px' }}>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" />
                                                </button>
                                                <ul className="dropdown-menu" role="menu">
                                                    <li><Link replace to="#" onClick={() => handleShowForm("contato", "Editar Contato", item.id)}><i className="fa fa-edit" /> Editar Contato</Link></li>
                                                    <li><Link replace to="#" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" />Remover</Link></li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>{item.nomeContato}</td>
                                        <td>{item.cargo}</td>
                                        <td>{item.departamento}</td>
                                        <td>{item.tipoContato}</td>
                                        <td>{item.telefone}</td>
                                        <td>{item.celular}</td>
                                        <td>{item.email}</td>
                                        <td>{item.linkedin}</td>
                                        <td>{item.contatoObservacao}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'contato' && <LeadContatoForm rowId={params.rowId} leadId={leadId} origem={props.origem} onHide={(acao) => handleHide(acao)} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
