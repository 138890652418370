import React, { Component } from 'react';
import {
    Row, FieldGroup, Col, Button, ButtonToolbar,
    FormGroupButton, Modals
} from '../../components/adminlte/AdminLTE';

import { ModalFindGroups } from './ModalFindGroups';
import { toast } from 'react-toastify';

const data = {
    id: '',
    name: '',
    serialNumber: '',
    deviceTypeId: '129837ed-c21d-4ccc-9c7f-80a92e20893b',
    deviceGroupId: '',
    host: '',
    port: 9000,
    username: '',
    password: '',
    active: true,
    totalChannels: 0,
    analogicChannels: 0,
    ipChannels: 0,
    rtpsConfiguration: ''
};

export class DeviceEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            keyValue: '',
            isSaving: false,
            data: data,
            groupValueSearch: '',
            showFormFindGroups: false,
            deviceGroupList: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { action, keyValue, groupValueSearch } = this.props;

        if (action === 'edit') {
            this.setState({ groupValueSearch: groupValueSearch });
            this.setState({ keyValue: keyValue });
            this.getData(keyValue);
        }
        else {
            this.setState({ keyValue: '', groupValueSearch: '' });
        }
    }

    getData(name) {
        const { apiUrl, token } = this.props.config;

        fetch(apiUrl + name, {
            headers: { 'Authorization': token }
        }).then(response => response.json()).then(data => {

            this.setState({ data: data, groupValueSearch: data.deviceGroupName });
        });
    }

    handleChange(e) {

        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { data: { ...prevState.data, [name]: value } };
        });
    }

    handleSubmit(e) {
        const { apiUrl, token } = this.props.config;
        const { action } = this.props;

        fetch(apiUrl, {
            method: action === 'edit' ? 'PUT' : 'POST',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.onHide();
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    groupSearch(e) {

        let value = e === null ? '' : e.target.value;
        this.setState({ groupValueSearch: value });

        if (value.length > 2) {
            this.getDeviceGroups(value);
        } else {
            this.setState({ deviceGroupList: [] });
        }
    }

    getDeviceGroups(name) {
        const { apiServer } = this.props.config;

        if (name === undefined)
            name = '';

        fetch(apiServer + 'vms/group/' + name, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ deviceGroupList: data });
        });
    }

    handleAddGroup(row) {

        this.setState({ groupValueSearch: row.name, deviceGroupList: [] });

        this.setState(prevState => {
            return { data: { ...prevState.data, deviceGroupId: row.id } };
        });
    }

    onHide() {
        this.setState({ data: data });
        this.props.onHide();
    }

    render() {
        const { show = false, config, row } = this.props;
        const { isSaving, data } = this.state;

        return (
            <Modals title="Edi&#xE7;&#xE3;o de Dispositivo" show={show} onHide={() => this.onHide()} footer={
                <ButtonToolbar>
                    <Button bsStyle="success" className="pull-right" onClick={this.handleSubmit} disabled={isSaving}>{isSaving ? 'Salvando...' : 'Salvar'}</Button>
                    <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                </ButtonToolbar>}
            >
                <Row>
                    <FieldGroup id="name" col={8} type="text" label="Nome" value={this.state.data.name} onChange={this.handleChange} />
                    <FieldGroup id="active" col={4} componentClass="select" label="Status" value={this.state.data.active} onChange={this.handleChange}>
                        <option value>Ativo</option>
                        <option value={false}>Inativo</option>
                    </FieldGroup>

                    <FieldGroup id="serialNumber" col={6} type="text" label="Num. de serie" value={this.state.data.serialNumber} onChange={this.handleChange} />

                    <FieldGroup id="deviceTypeId" col={6} componentClass="select" label="Tipo" value={this.state.data.deviceTypeId} onChange={this.handleChange}>
                        <option value="129837ED-C21D-4CCC-9C7F-80A92E20893B">VIU V7</option>
                    </FieldGroup>

                    <FieldGroup id="totalChannels" col={3} type="number" label="Total de canais" value={this.state.data.totalChannels} onChange={this.handleChange} />

                    <Col md={9}>
                        <FormGroupButton placeholder="Grupo" label="Grupo" disabled onChange={this.groupSearch} value={this.state.groupValueSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showFormFindGroups: true })}>Selecionar</Button>} />
                    </Col>

                    <FieldGroup id="host" col={8} type="text" label="IP" value={this.state.data.host} onChange={this.handleChange} />
                    <FieldGroup id="port" col={4} type="number" label="Porta" value={this.state.data.port} onChange={this.handleChange} />

                    <FieldGroup id="username" col={6} type="text" label="Usu&aacute;rio" value={this.state.data.username} onChange={this.handleChange} />
                    <FieldGroup id="password" col={6} type="password" label="Senha" value={this.state.data.password} onChange={this.handleChange} />

                </Row>
                {this.state.showFormFindGroups && <ModalFindGroups config={config} data={data} show={this.state.showFormFindGroups} onImport={(row) => { this.handleAddGroup(row); }} row={row} onHide={() => this.setState({ showFormFindGroups: false })} />}
            </Modals>
        );
    }
}