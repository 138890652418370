import React, { useState } from 'react';
import { config, apiHelper, toast } from '../index';

export function AcionamentoCancelar(props) {    
    const parentId = props.parentId;
    const rowId = props.rowId;

    const [form, setForm] = useState({
        descricao: '',
        idAcionamentoTecnico: props.fkAcionamentoTecnico
    });

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.patch(`v3/os/${parentId}/acionamentoTecnico/${rowId}`, form)
            .then(res => {
                config.httpResult(res);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível cancelar o acionamento técnico.');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <div className="form-group">
                        <label>Motivo Cancelamento</label>
                        <textarea id="motivo" className="form-control" rows="3" maxLength="500" onChange={handleChange} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Voltar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    );
}