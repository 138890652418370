import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, Modal, toast } from '../index';
import Core from '../Core';

export function FaturamentoRelatorio(props) {
    const endPoint = 'v2/projeto/faturamento';
    const projetoId = props.parentId;
    const [data, setData] = useState({});
    const [faturamentos, setFaturamentos] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [resultado, setResultado] = useState([]);

    const [params, setParam] = useState({
        showModal: false,
        faturamentos: [],
        modalTitle: '',
        receita: 0,
        totalMeses: 1
    });
    const [mesesAnos, setMesesAnos] = useState([]);


    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=projetoId::equal::${projetoId};;AND;;tipo::equal::Provisionamento`)
            .then(res => {
                if (res.data.code === 200) {
                    setFaturamentos(res.data.data);
                    const minDate = new Date(Math.min(...res.data.data.map((item) => new Date(item.dataProvisionamento))));
                    const maxDate = new Date(Math.max(...res.data.data.map((item) => new Date(item.dataProvisionamento))));
                    const meses = [];
                    const dataAtual = new Date(minDate);
                    while (dataAtual <= maxDate || dataAtual.getMonth() === maxDate.getMonth()) {
                        meses.push(new Date(dataAtual));
                        dataAtual.setMonth(dataAtual.getMonth() + 1);
                    }
                    setMesesAnos(meses);

                    const valoresPorMesAnoETipo = {};
                    let receita = 0;
                    // Preencha o objeto com os valores dos dados
                    res.data.data.forEach((item) => {
                        let dataProvisionamento = new Date(item.dataProvisionamento);
                        const chave = `${item.tipoProvisionamento}_${dataProvisionamento.getMonth() + 1}_${dataProvisionamento.getFullYear()}`;
                        if (!valoresPorMesAnoETipo[chave]) {
                            valoresPorMesAnoETipo[chave] = 0;
                        }
                        valoresPorMesAnoETipo[chave] += item.valorFaturado;
                        receita += item.valorFaturado;
                    });
                    setParam({ ...params, receita: receita, totalMeses: meses.length + 3 });
                    setData(valoresPorMesAnoETipo);

                    apiHelper.get('v2/projeto/' + projetoId + '/aprovacaoOrcamento')
                        .then(res => {
                            setResultado(res.data.data.resumo);
                        });

                    let tips = [];
                    apiHelper.get('portal/crud/PortalMetadata?filter=Categoria::equal::TipoFaturamento').then(resp => {
                        resp.data.data.map((item) =>
                            tips.push(item.nome)
                        );
                        setTipos(tips);
                    }).catch(error => {
                        toast.error('Houve um erro ao carregar o registro.');
                    });
                }
                else
                    toast.error(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const handleDetails = (date, tipo) => {

        let provs = [];
        faturamentos.forEach(item => {
            let dataProv = new Date(item.dataProvisionamento);
            if (tipo === item.tipoProvisionamento && date.getMonth() === dataProv.getMonth()
                && dataProv.getFullYear() === date.getFullYear())
                provs.push(item);
        });

        let title = `${config.capitalize(date.toLocaleString('pt-BR', { month: 'long' }))} de 
        ${date.getFullYear()}`;

        setParam({
            ...params,
            showModal: true,
            faturamentos: provs,
            modalTitle: `Descrição Provisionamentos - 
            ${title}`
        });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false, faturamentos: [], modalTitle: '' });
    }

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    const obterDescricaoResultado = (tipo) => {
        switch (tipo) {
            case 'Locação':
                return 'locacao';
            case 'Venda':
                return 'venda';
            case 'Instalação':
                return 'servicoNaoRecorrente';
            case 'Manutenção':
                return 'servicoRecorrente';
            default:
                return '';
        }
    }

    const calcularPorcentagem = (total, parte) => {
        if (total > 0)
            //return parseFloat((parte * 100) / total).toFixed(0);
            return parseInt((parte * 100) / total);
        return 0;
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin">
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th>Descrição</th>
                                    <th>Data Provisionamento</th>
                                    <th>Tipo</th>
                                    <th>Valor</th>
                                    <th>Criado Por</th>
                                    <th>Anexo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {params.faturamentos.length > 0 && params.faturamentos.sort((a, b) => a.id < b.id ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i} style={{ "width": "95px" }}>
                                                <td>{item.descricao}</td>
                                                <td>{config.dateFix(item.dataProvisionamento)}</td>
                                                <td>{item.tipoProvisionamento}</td>
                                                <td>{config.currencyFix(item.valorFaturado)}</td>
                                                <td>{item.criador}</td>
                                                <td>{item.anexoEmail ? <Link to="#" onClick={() => downloadAnexo(item.anexoEmail)}>{item.anexoEmail}</Link> : "-"}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">Relatório de Faturamento Provisionado</h3>

                    <div className="box-tools pull-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                        </div>

                    </div>
                </div>
                <div className="box-body">
                    {(mesesAnos && mesesAnos.length > 0) &&
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin">
                                <thead>
                                    <tr>
                                        <th className="bg-light-blue color-palette">Tipo | Data</th>
                                        {mesesAnos.map((item, index) => (
                                            <th className="bg-light-blue color-palette" key={index}>{`${config.capitalize(item.toLocaleString('pt-BR', { month: 'long' }))} / ${item.getFullYear()}`}</th>
                                        ))}
                                        <th className="bg-light-blue color-palette">Total</th>
                                        <th className="bg-light-blue color-palette">%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tipos.map((tipo, index) => {
                                        let total = 0;
                                        let res = resultado.length > 0 ? resultado.find((item) => item.tipo === obterDescricaoResultado(tipo)) : null;
                                        let resultadoTotal = res ? (res.target > 0 ? res.target : res.resultado) : 0;
                                        return (
                                            <tr key={index}>
                                                <td className="bg-light-blue color-palette">{tipo}</td>
                                                {mesesAnos.map((item, index) => {
                                                    const chave = `${tipo}_${item.getMonth() + 1}_${item.getFullYear()}`;
                                                    if (data[chave])
                                                        total += data[chave];
                                                    return (
                                                        <td key={index} onClick={data[chave] && (() => handleDetails(item, tipo))}
                                                            style={{ cursor: data[chave] ? 'zoom-in' : 'auto' }}>
                                                            {data[chave] ? config.currencyFix(data[chave]) : '-'}
                                                        </td>
                                                    );
                                                })}
                                                <td>{config.currencyFix(total)}</td>
                                                <td>{calcularPorcentagem(resultadoTotal, total)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={params.totalMeses}>
                                            <div className="pull-right">
                                                <table className="table table-hover table-striped" style={{ margin: 0 }}>
                                                    <tbody>
                                                        <tr><td>Provisionamento Receita</td><td align="right">{config.currencyFix(params.receita)}</td></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}
