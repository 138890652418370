import React, { useState } from 'react';
import { apiHelper, toast, config } from '../index';

export function RequisicaoMaterialForm(props) {
    const endPoint = 'suprimentos/rm/';
    const [form, setForm] = useState({});

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let d = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0, 0, 0, 0);
        if (new Date(form.dataNecessidade + " 00:00:00") < d) {
            toast.warn(`A data mínima permitida é ${config.dateFix(d)}`);
            return;
        }

        var obj = form;
        obj.SolicitanteId = "[user_id]";
        obj.status = "Rascunho";
        obj.origem = "Projeto";
        obj.origemId = props.parentId;
        obj.clienteId = props.clienteId;
        obj.centroCusto = '0061-MANURENÇÃO';
        obj.classificacaoContabil = '';
        obj.localEntrega = props.site;
        obj.guid = "";

        apiHelper.post(endPoint, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                props.onHide();
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">               
                <div className="form-group col-sm-12 col-md-6">
                    <label>Data Necessidade</label>
                    <input type="date" id="dataNecessidade" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Cancelar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Criar Requisição</button>
                </div>
            </div>
        </form>
    )
}
