import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper } from '../';
import { SiteForm } from './SiteForm';
import { MaterialTable } from './MaterialTable';
import { EquipamentoTable } from './EquipamentoTable';

export function SiteView(props) {
    const endPoint = 'comercial/site/';
    const rowId = props.match.params.id;
    const [data, setData] = useState({});

    const fetchData = () => {
        apiHelper.get(`${endPoint}${rowId}?siteIdentificador=${rowId}`)
            .then(res => {
                setData(res.data.data);
            });
    }

    useEffect(fetchData, [rowId]);

    const handleClose = () => {
        fetchData();
        //props.history.push({
        //    pathname: '/cliente/site'
        //});
    }

    return (
        <Content title={data.nome} browserTitle={"Detalhes do Site "}>
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><Link to="#tab_edit" data-toggle="tab" aria-expanded="true">Editar</Link></li>
                            <li><Link to="#tab_equipamento" data-toggle="tab" aria-expanded="true">Equipamentos</Link></li>
                            <li><Link to="#tab_material" data-toggle="tab" aria-expanded="true">Materiais</Link></li>
                            <li className="pull-right"><Link to="/cliente/site/" className="text-muted"><i className="fa fa-times"></i></Link></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="tab_edit">
                                <SiteForm parentId={rowId} onHide={handleClose} />
                            </div>
                            <div className="tab-pane" id="tab_equipamento">
                                <EquipamentoTable parentId={rowId} onHide={handleClose} />
                            </div>
                            <div className="tab-pane" id="tab_material">
                                <MaterialTable parentId={rowId} onHide={handleClose} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
