import React, { useState, useEffect } from 'react';
import { Checkbox, config, apiHelper, toast } from '../index';

export function AcidenteForm(props) {
    const endPoint = 'v2/os/acidente';
    const rowId = props.parentId;

    const [data, setData] = useState({
        osId: props.idOS,
        dataOcorrencia: '',
        local: '',
        horasTrabalhadasAntesAcidente: 0,
        tipoAcidente: '',
        houvePerdadeTempo: 0,
        tempodeAfastamento: 0,
        houveInternacao: 0,
        cid: '',
        crm: '',
        acidenteFatal: 0,
        parteCorpoAtingida: '',
        agenteCausador: '',
        situacaoGeradora: '',
        naturezaLesao: '',
        hospitalAtendimento: '',
        houveOcorrenciaPolicial: 0,
        descricaoAcidente: '',
        envolvidos: '',
        gestorLocal: '',
        numeroAPR: '',
        anexoAPR: '',
        fileAPR: '',
        nomeEquipamento: '',
        descricaoAtividade: '',
        descricaoAcoesAdotadas: '',
        anexoFluxograma: '',
        fileFluxograma: '',
    });

    const fetchData = () => {
        let obj = {};
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}/`).then(res => {

                let data = res.data.data;

                data.dataOcorrencia = `${data.dataOcorrencia.split('-')[0]}-${data.dataOcorrencia.split('-')[1]}-${data.dataOcorrencia.split('-')[2].substr(0, 2)}`;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);

                setData(data);
            }).catch(error => {
                toast.error('Houve um erro ao carregar os dados');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let name = e.target.id;
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];

        if (name === "anexoAPR" || name === "anexoFluxograma") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                if (name === "anexoAPR")
                    apiHelper.toBase64(file)
                        .then((base) => {
                            setData({ ...data, fileAPR: base, anexoAPR: file.name });
                        });
                if (name === "anexoFluxograma")
                    apiHelper.toBase64(file)
                        .then((base) => {
                            setData({ ...data, fileFluxograma: base, anexoFluxograma: file.name });
                        });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (rowId === 0) {
            apiHelper.post(endPoint, data)
                .then(res => {
                    if (res.data.code === 200) {
                        toast.success('Dados salvos com sucesso!');
                        props.onHide();
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            let form = data;
            delete form.id;
            apiHelper.put(`${endPoint}/${rowId}`, form)
                .then(res => {
                    config.httpResult(res);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>

            <div className="row">
                <div className="form-group col-md-4">
                    <label>Data Ocorrencia</label>
                    <input id="dataOcorrencia" type="date" className="form-control" onChange={handleChange} value={data.dataOcorrencia || ""} placeholder="dd/mm/yyyy" required />
                </div>
                <div className="form-group col-md-4">
                    <label>Local</label>
                    <input type="text" id="local" className="form-control" rows="3" onChange={handleChange} value={data.local} />
                </div>
                <div className="form-group col-md-4">
                    <label>Horas Trabalhadas</label>
                    <input type="number" id="horasTrabalhadasAntesAcidente" className="form-control" rows="3" onChange={handleChange} value={data.horasTrabalhadasAntesAcidente} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label>Natureza da Lesão</label>
                    <input type="text" id="naturezaLesao" className="form-control" rows="3" onChange={handleChange} value={data.naturezaLesao} />
                </div>
                <div className="form-group col-md-6">
                    <label>Parte do Corpo Atingida</label>
                    <input type="text" id="parteCorpoAtingida" className="form-control" rows="3" onChange={handleChange} value={data.parteCorpoAtingida} />
                </div>

            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Envolvidos</label>
                    <input type="text" id="envolvidos" className="form-control" rows="3" onChange={handleChange} value={data.envolvidos} />
                </div>
                <div className="form-group col-md-6">
                    <label>Nome Equipamento</label>
                    <input type="text" id="nomeEquipamento" className="form-control" rows="3" onChange={handleChange} value={data.nomeEquipamento} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Tipo Acidente</label>
                    <select id="tipoAcidente" className="form-control" onChange={handleChange} value={data.tipoAcidente}>
                        <option value="">Selecione</option>
                        <option value="Tipico">Tipico</option>
                        <option value="Trajeto">Trajeto</option>
                        <option value="Doença Ocupacional">Doença Ocupacional</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label>Situação Geradora</label>
                    <input type="text" id="situacaoGeradora" className="form-control" rows="3" onChange={handleChange} value={data.situacaoGeradora} />
                </div>
                <div className="form-group col-md-4">
                    <label>Agente Causador</label>
                    <input type="text" id="agenteCausador" className="form-control" rows="3" onChange={handleChange} value={data.agenteCausador} />
                </div>
            </div>
         
            <div className="row">
                <div className="form-group col-md-4">
                    <label>CID</label>
                    <input type="text" id="cid" className="form-control" rows="3" onChange={handleChange} value={data.cid} />
                </div>
                <div className="form-group col-md-4">
                    <label>CRM</label>
                    <input type="text" id="crm" className="form-control" rows="3" onChange={handleChange} value={data.crm} />
                </div>
                <div className="form-group col-md-4">
                    <label>Hospital Atendimento</label>
                    <input type="text" id="hospitalAtendimento" className="form-control" rows="3" onChange={handleChange} value={data.hospitalAtendimento} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <Checkbox inline id="houvePerdadeTempo" onClick={handleChange} checked={data.houvePerdadeTempo}>Houve Perda de Tempo</Checkbox>
                </div>
                <div className="form-group col-md-4">
                    <label>Tempo Afastamento (dias)</label>
                    <input type="number" id="tempodeAfastamento" className="form-control" rows="3" onChange={handleChange} value={data.tempodeAfastamento} />
                </div>
                <div className="form-group col-md-4">
                    <label>Gestor Local</label>
                    <input type="text" id="gestorLocal" className="form-control" rows="3" onChange={handleChange} value={data.gestorLocal} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <Checkbox inline id="houveInternacao" onClick={handleChange} checked={data.houveInternacao}>Houve Internação</Checkbox>
                </div>
                <div className="form-group col-md-3">
                    <Checkbox inline id="acidenteFatal" onClick={handleChange} checked={data.acidenteFatal}>Acidente Fatal</Checkbox>
                </div>
                <div className="form-group col-md-5">
                    <Checkbox inline id="houveOcorrenciaPolicial" onClick={handleChange} checked={data.houveOcorrenciaPolicial}>Houve Ocorrencia Policial</Checkbox>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição Acidente</label>
                    <textarea id="descricaoAcidente" className="form-control" rows="3" onChange={handleChange} value={data.descricaoAcidente} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição Atividade</label>
                    <textarea id="descricaoAtividade" className="form-control" rows="3" onChange={handleChange} value={data.descricaoAtividade} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição Ações Adotadas</label>
                    <textarea id="descricaoAcoesAdotadas" className="form-control" rows="3" onChange={handleChange} value={data.descricaoAcoesAdotadas} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Numero APR</label>
                    <input type="text" id="numeroAPR" className="form-control" rows="3" onChange={handleChange} value={data.numeroAPR} />
                </div>
                <div className="form-group col-md-8">
                    <label>Anexo APR</label>
                    <input type="file" id="anexoAPR" onChange={handleChange} fileName={data.anexoAPR} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-8">
                    <label>Anexo Fluxugrama</label>
                    <input type="file" id="anexoFluxograma" onChange={handleChange} fileName={data.anexoFluxograma} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

