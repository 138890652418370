import React from "react";
import { Content, noAvatar, Link } from '../';

export function Profile(props) {
    return (
        <Content title="Perfil">
            <div className="row">
                <div className="col-md-3">
                    <div className="card card-primary card-outline">
                        <div className="card-body box-profile">
                            <div className="text-center">
                                <img className="profile-user-img img-fluid img-circle" src={noAvatar()} alt="Perfil" />
                            </div>
                            <h3 className="profile-username text-center">Nina Mcintire</h3>
                            <p className="text-muted text-center">Software Engineer</p>
                            <ul className="list-group list-group-unbordered mb-3">
                                <li className="list-group-item">
                                    <b>Followers</b> <Link to="#" className="float-right">1,322</Link>
                                </li>
                                <li className="list-group-item">
                                    <b>Following</b> <Link to="#" className="float-right">543</Link>
                                </li>
                                <li className="list-group-item">
                                    <b>Friends</b> <Link to="#" className="float-right">13,287</Link>
                                </li>
                            </ul>
                            <Link to="#" className="btn btn-primary btn-block"><b>Follow</b></Link>
                        </div>
                    </div>
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Sobre Mim</h3>
                        </div>

                        <div className="card-body">
                            <strong><i className="fas fa-book mr-1"></i> Education</strong>
                            <p className="text-muted">
                                B.S. in Computer Science from the University of Tennessee at Knoxville
                            </p>
                            <hr />
                            <strong><i className="fas fa-map-marker-alt mr-1"></i> Location</strong>
                            <p className="text-muted">Malibu, California</p>
                            <hr />
                            <strong><i className="fas fa-pencil-alt mr-1"></i> Skills</strong>
                            <p className="text-muted">
                                <span className="tag tag-danger">UI Design</span>
                                <span className="tag tag-success">Coding</span>
                                <span className="tag tag-info">Javascript</span>
                                <span className="tag tag-warning">PHP</span>
                                <span className="tag tag-primary">Node.js</span>
                            </p>
                            <hr />
                            <strong><i className="far fa-file-alt mr-1"></i> Notes</strong>
                            <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header p-2">
                            <ul className="nav nav-pills">
                                <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="active tab-pane" id="activity">

                                </div>
                                <div className="tab-pane" id="timeline">

                                </div>
                                <div className="tab-pane" id="settings">
                                    <form className="form-horizontal">
                                        <div className="form-group row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputName" placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputEmail" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="offset-sm-2 col-sm-10">
                                                <button type="submit" className="btn btn-danger">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}
