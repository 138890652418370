import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select, fileHelper, Link } from '../index';
import { Global } from '../Core';

export function OrdemServicoConcluir(props) {
    const endPoint = 'v2/os/concluir';
    const endPointConclusaoOs = 'portal/crud/rpt_ConlusaoOS';
    const parentId = props.parentId;
    const [grupo, setGrupo] = useState(null);
    const [data, setData] = useState({
        fkOS: props.parentId,
        file: '',
        anexo: ''
    });
    const [dataConclusao, setDataConclusao] = useState([]);

    const fetchData = () => {
        parentId > 0 && apiHelper.get(`${endPointConclusaoOs}?filter=fkOs::equal::${parentId};;And;;isAtual::equal::1`)
            .then(res => {
                if (res.data.code === 200) {
                    setDataConclusao(res.data.data);
                }
                else
                    toast.error('Atenção, não foi executar esta operação ' + parentId);
            });
    }

    useEffect(fetchData, [parentId, props.refresh]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "grupo") {
            setGrupo(value);
            return;
        }
        else if (name === "fkSubGrupoTecnico") {
            setData({ ...data, fkSubGrupoTecnico: value, fkSubGrupoCliente: value, fkSubGrupoHD: value });
            return;
        }

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                Global.ToBase64(file).then((base) => {
                    setData({ ...data, file: base, anexo: file.name });
                });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        data.fkGrupo = grupo;
        apiHelper.post(endPoint, data).then(res => {
            config.httpResult(res.data);
            fetchData();
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const downloadAnexo = (fileName) => {
        fileHelper.download('OsAnexo', fileName).then(res => { });
    };

    return (
        <React.Fragment>
            {((dataConclusao && dataConclusao.length === 0) || dataConclusao[0].status < 4) &&
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group col-sm-12 col-md-6">
                            <label htmlFor="name">Grupo</label>
                            <Select id="grupo" className="form-control" onChange={handleChange} required>
                                <option value="">Selecione</option>
                                <option endpoint="portal/crud/grupo/" valuefield="pkGrupo" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-12 col-md-6">
                            <label htmlFor="name">Sub Grupo</label>
                            <Select id="fkSubGrupoTecnico" className="form-control" onChange={handleChange} value={data.fkSubGrupoTecnico} refresh={grupo} required>
                                <option value="">Selecione</option>
                                {grupo && <option endpoint={'portal/crud/subGrupo/?filter=fkGrupo::equal::' + grupo} valuefield="pkSubGrupo" textfield="nome" />}
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12 col-md-6">
                            <label htmlFor="name">Manutenção executada</label>
                            <Select id="fkManutencaoExecutada" className="form-control" onChange={handleChange} required refresh={data.fkSubGrupoTecnico}>
                                <option value="">Selecione</option>
                                {data.fkSubGrupoTecnico && <option endpoint={`portal/crud/manutencaoExecutada?filter=fkSubGrupo::equal::${data.fkSubGrupoTecnico}`} valuefield="pkManutencaoExecutada" textfield="nome" />}
                            </Select>
                        </div>
                        <div className="form-group col-sm-12 col-md-6">
                            <label>Valor</label>
                            <input type="number" step="any" id="valorOS" name="valorOS" className="form-control" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12 col-md-6">
                            <label>Anexo</label>
                            <input type="file" id="file" label="Anexo" onChange={handleChange} />
                        </div>
                        <div className="form-group col-sm-12 col-md-6">
                            <label>Equipamento</label>
                            <Select id="equipamento" className="form-control" onChange={handleChange}>
                                <option value="">Selecione</option>
                                <option endpoint={`v3/os/${props.parentId}/equipamento/`} valuefield="id" textfield="nome" />
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12 col-md-12">
                            <label>Observação</label>
                            <textarea id="descricao" className="form-control" rows="3" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn-toolbar">
                                <button type="submit" className="btn btn-primary pull-right">Concluir OS</button>
                            </div>
                        </div>
                    </div>
                </form>}
            {(dataConclusao && dataConclusao.length > 0 && (dataConclusao[0].status === 4 || dataConclusao[0].status === 5)) &&
                <React.Fragment>
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>
                                    <tr>
                                        <td><b>Grupo</b> </td>
                                        <td>{dataConclusao[0].grupo !== null ? dataConclusao[0].grupo : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>SubGrupo</b> </td>
                                        <td>{dataConclusao[0].subGrupo !== null ? dataConclusao[0].subGrupo : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Manutenção Executada</b> </td>
                                        <td>{dataConclusao[0].manutencaoExecutada}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Valor OS</b> </td>
                                        <td>{dataConclusao[0].valorOS !== null ? config.currencyFix(dataConclusao[0].valorOS) : 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Usuário</b> </td>
                                        <td>{dataConclusao[0].usuario}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Descrição</b> </td>
                                        <td>{dataConclusao[0].descricao}</td>
                                    </tr>
                                    {dataConclusao[0].equipamentoIdentificador && <tr>
                                        <td><b>Equipamento</b> </td>
                                        <td>{dataConclusao[0].equipamentoIdentificador + ' - ' + dataConclusao[0].equipamento}</td>
                                    </tr>}
                                    <tr>
                                        <td><b>Anexo</b> </td>
                                        <td>{dataConclusao[0].anexo ? <Link to="#" onClick={() => downloadAnexo(dataConclusao[0].anexo)}>{dataConclusao[0].anexo}</Link> : "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
}
