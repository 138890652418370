import React, { useState, useEffect } from 'react';
import { apiHelper } from './../../index';

let distinctConsultores = [];
let distinctAnalistas = [];
let contatosPorConsultor = [];
let contatosPorAnalista = [];
let totalGeralContatos = 0;
let percentualTotal = 0;
export function ContatosConsultores(props) {
    const endPoint = 'lead/report';
    const [dataTable, setDataTable] = useState([]);

    const handleSearch = () => {
        let origem = props.origem;
        let where = '';
        if (!origem)
            return;

        where = `origem::equal::${origem};;`;

        apiHelper.get(`${endPoint}/contatosConsultores?filter=${where}`).then(res => {
            if (res.data.data.length > 0) {
                let data = res.data.data;

                let analistas = data.map((item, i) => { return item.analistaVeolink });
                let consultores = data.map((item, i) => { return item.analistaFTI });
                distinctAnalistas = analistas.filter((v, i, a) => a.indexOf(v) === i);
                distinctConsultores = consultores.filter((v, i, a) => a.indexOf(v) === i);

                for (var i = 0; i < distinctConsultores.length; i++) {
                    let totalContatos = 0;
                    let nomeConsultor = distinctConsultores[i];
                    contatosPorConsultor[i] = data.filter(c => c.analistaFTI === nomeConsultor);

                    contatosPorConsultor[i].map(item => totalContatos = totalContatos + item.contato);

                    contatosPorConsultor[i].totalContatosConsultor = totalContatos;

                    totalGeralContatos = totalGeralContatos + totalContatos;
                }

                for (var j = 0; j < distinctAnalistas.length; j++) {
                    let nomeAnalista = distinctAnalistas[j];
                    let totalContatos = 0;

                    contatosPorAnalista[j] = data.filter(c => c.analistaVeolink === nomeAnalista);
                    contatosPorAnalista[j].map(item => totalContatos = totalContatos + item.contato)
                    contatosPorAnalista[j].totalContatosAnalista = totalContatos;

                    //Obtem o valor total Geral 
                    let totalGeralConsultores = 0;
                    contatosPorConsultor.map(item => totalGeralConsultores = totalGeralConsultores + item.totalContatosConsultor)

                    //Insere a porentagem por analista
                    contatosPorAnalista[j].percentualContatosAnalista = Math.round((totalContatos * 100) / totalGeralConsultores) + '%';
                    percentualTotal = percentualTotal + (totalContatos * 100) / totalGeralConsultores;
                }

                setDataTable(data);
            } else {
                setDataTable([]);
            }
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Contatos</h3>
            </div>
            <div className="panel-body">
                {dataTable.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr style={{ 'backgroundColor': '#EBEBEC' }}>
                                                <th>FTI</th>
                                                {distinctAnalistas.map((nome, i) => {
                                                    return (<th key={Math.random()}>{`${props.origem} ${nome}`}</th>)
                                                })}
                                                <th key={Math.random()}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {distinctConsultores.map((itemNome, index) => {
                                                return (
                                                    <tr key={Math.random()}>
                                                        <th key={Math.random()}>{itemNome}</th>
                                                        {distinctAnalistas.map((nomeAnalista, i) => {
                                                            let colunas = [];
                                                            if (contatosPorConsultor[index].filter(c => c.analistaVeolink === nomeAnalista && c.analistaFTI === itemNome).length > 0) {
                                                                colunas.push(<td key={Math.random()}>{dataTable.filter(c => c.analistaVeolink === nomeAnalista && c.analistaFTI === itemNome)[0].contato}</td>);
                                                            } else {
                                                                colunas.push(<td key={Math.random()}>0</td>)
                                                            }
                                                            return (colunas);

                                                        })}
                                                        <td key={Math.random()}>{contatosPorConsultor[index].totalContatosConsultor}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <th className="bg-light-blue">Total</th>
                                                {contatosPorAnalista.map((item, i) => {
                                                    return (<td key={Math.random()} className="bg-light-blue">{item.totalContatosAnalista}</td>)
                                                })}
                                                <td key={Math.random()} className="bg-light-blue">{totalGeralContatos}</td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                {contatosPorAnalista.map((item, i) => {
                                                    return (<td key={Math.random()} className="bg-light-blue">{item.percentualContatosAnalista}</td>)
                                                })}
                                                <td key={Math.random()} className="bg-light-blue">{Math.round(percentualTotal) + '%'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
