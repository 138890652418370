import React, { Component } from 'react';
import { Modals, Table, FasIcon } from '../../components/adminlte/AdminLTE';
import uuidv4 from 'uuid';

const table = [];

export class ImportDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            title: this.props.title,
            keyValue: '',
            header: [],
            rowsData: [],
            data: this.props.data,
            table: table,
            totalSuccess: 0,
            totalErrors: 0
        };

        this.getData = this.getData.bind(this);
        this.getHeader = this.getHeader.bind(this);
        this.getRowsData = this.getRowsData.bind(this);
        this.getKeys = this.getKeys.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { title, data } = this.props;
        this.getData(data);

        var dados = JSON.parse(data.json);
        let header = this.getHeader(dados);
        let rowsData = this.getRowsData(dados);

        this.setState({
            title: title,
            data: data,
            header: header,
            rowsData: rowsData
        });
    }

    getData(data) {
        let table = JSON.parse(data.json);
        let sucess = table.filter(f => f.StatusProcess === 'Processada').length;
        let error = table.filter(f => f.StatusProcess !== 'Processada' && f.StatusProcess !== 'Em Fila para Processamento').length;

        this.setState({ table: table, totalSuccess: sucess, totalErrors: error });
    }

    onHide() {
        this.setState({ table: table });
        this.props.onHide();
    }

    getKeys = function (data) {
        return Object.keys(data[0]);
    }

    getHeader = function (data) {
        var keys = this.getKeys(data);
        return (
            <thead>
                <tr>
                    <th />
                    {keys.map((key, index) => {
                        return <th style={{ cursor: "pointer", padding: "5px" }} key={uuidv4()}>{key}</th>
                    })}
                </tr>
            </thead>
        );
    }

    fillIcon(status) {
        if (status === 'Pendente')
            return 'label label-light';
        else if (status === 'Erro')
            return 'label label-danger';
        else if (status === 'Processando')
            return 'label label-info';
        else if (status === 'Processada')
            return 'label label-success';
    }

    getRowsData = function (data) {
        var keys = this.getKeys(data);

        return data.map((row, index) => {
            if (row.StatusProcess === 'Processada') {
                return <tr align="center" style={{ cursor: "pointer", padding: "5px" }} key={uuidv4()}>
                    <td><span className={this.fillIcon('Processada')}><FasIcon name="check" /></span></td>
                    <RenderRow data={row} keys={keys} /></tr>
            }
            if (row.StatusProcess === 'Pendente') {
                return <tr align="center" style={{ cursor: "pointer", padding: "5px" }} key={uuidv4()}>
                    <td><span className={this.fillIcon('Pendente')}><FasIcon name="warning" /></span></td>
                    <RenderRow data={row} keys={keys} /></tr>
            }
            else {
                return <tr align="center" style={{ cursor: "pointer", padding: "5px" }} key={uuidv4()}>
                    <td><span className={this.fillIcon('Erro')}><FasIcon name="exclamation" /></span></td>
                    <RenderRow data={row} keys={keys} />
                </tr>
            }
        });
    }

    render() {
        const { show = false } = this.props;
        return (
            <Modals title={"Detalhe Arquivo " + this.state.title} show={show} onHide={() => this.onHide()} size="large" centered>
                <span className="info-box-text" ><b>Total Sucessos:</b> {this.state.totalSuccess}
                    <b> Total Erros:</b> {this.state.totalErrors}</span>

                <Table responsive striped bordered hover>
                    {this.state.header}
                    <tbody>
                        {this.state.rowsData}
                    </tbody>
                </Table>
            </Modals>
        );
    }
}

const RenderRow = (props) => {
    return props.keys.map((key) => {
        return <td key={uuidv4()}>{props.data[key]}</td>
    });
};