import React from 'react'

export function VeolinkApresentationCard() {
    return (
        <div className="bom">
            <div className="box-body">
                <div className="row">
                    <div className="col-md-8">
                        <img width="100%" src="https://veolink.com.br/wp-content/uploads/2019/11/empresa-veolink.jpg" alt="veolink" />
                        <p style={{ textAlign: 'justify' }}>A&nbsp;<strong>VEOLINK&nbsp;</strong>é uma&nbsp;<strong>integradora de sistemas eletrônicos de segurança, automação e gestão de dados&nbsp;</strong>do<strong>&nbsp;Grupo Graber</strong>, que fornece desde soluções simples destinadas a proteção das pessoas e ativos de uma organização até soluções complexas, desenhadas especificamente para seus clientes em função de suas particularidades específicas.</p>
                        <p style={{ textAlign: 'justify' }}>Aliando conhecimento adquirido ao longo de décadas de implantação e operação de sistemas a um departamento de Desenvolvimento de Aplicações (P&amp;D), possuímos habilidade de integrar ampla gama de soluções interligando&nbsp; a segurança da organização aos processos industriais, promovendo assim uma otimização completa dos sistemas e fornecendo soluções dedicadas aos seus clientes.</p>
                        <p style={{ textAlign: 'justify' }}>Atua em todo o território nacional, possuindo uma&nbsp; ampla gama de clientes nos diversos setores da economia.</p>
                    </div>
                    <div className="col-md-4">
                        <p>Soluções em Destaque</p>
                        <ul>
                            <li><a href="https://veolink.com.br/solucoes/design-de-sistemas-integrados/">Design de Sistemas Integrados</a></li>
                            <li><a href="https://veolink.com.br/solucoes/sistemas-de-cftv-circuito-fechado-de-televisao/">Sistemas de CFTV – Circuito Fechado de Televisão</a></li>
                            <li><a href="https://veolink.com.br/solucoes/monitoramento-movel/">Monitoramento Móvel</a></li>
                            <li><a href="https://veolink.com.br/solucoes/monitoramento-patrimonial/">Monitoramento Patrimonial</a></li>
                            <li><a href="https://veolink.com.br/solucoes/intrusao/">Intrusão</a></li>
                            <li><a href="https://veolink.com.br/solucoes/deteccao-de-incendio/">Detecção de Incêndio</a></li>
                            <li><a href="https://veolink.com.br/solucoes/controle-de-acesso/">Controle de acesso</a></li>
                            <li><a href="https://veolink.com.br/solucoes/rfid-sistemas-de-identificacao-via-radio-frequencia/">RFID – Sistemas de Identificação via Rádiofrequência</a></li>
                            <li><a href="https://veolink.com.br/solucoes/ronda-informatizada/">Ronda Informatizada</a></li>
                            <li><a href="https://veolink.com.br/solucoes/sonorizacao/">Sonorização</a></li>
                            <li><a href="https://veolink.com.br/solucoes/cabeamento-estruturado-e-comunicacao-ip/">Cabeamento Estruturado e Comunicação IP</a></li>
                            <li><a href="https://veolink.com.br/solucoes/automacao/">Automação</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
