import React, { Component } from 'react';
import Item from './Item';
import uuidv4 from 'uuid';

class Sidebar extends Component {
    render() {
        const { children } = this.props;
        let localChildren;

        if (children)
            localChildren = children.length ? children : [children];
        else
            localChildren = [];

        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <ul className="sidebar-menu" data-widget="tree">
                        <li key={uuidv4()} className="header">MENU</li>
                        {localChildren}
                    </ul>
                </section>
            </aside>
        );
    }
}

export default Sidebar;
export { Item, Sidebar as Core };