import React, { useState, useEffect } from 'react';
import { apiHelper, toast } from '../';
import { GraficoGeral } from './report/GraficoGeral';
import { GraficoConsolidado } from './report/GraficoConsolidado';
import { GraficoProspeccao } from './report/GraficoProspeccao';
import { Comparativo } from './report/Comparativo';
import { FtiGeral } from './report/FtiGeral';
import { ContatosConsultores } from './report/ContatosConsultores';
import { VisitasConsultores } from './report/VisitasConsultores';
import { PropostasGeradasConsultores } from './report/PropostasGeradasConsultores';
import * as _ from 'lodash';

export function DashboardGraficos(props) {
    const [filter, setFilter] = useState({ dataEntradaInicio: '', dataEntradaFim: '' });
    const [consultores, setConsultores] = useState([]);

    const obterConsultores = () => {
        apiHelper.get(`comercial/pessoa/perfil?perfil=AnalistaVeolink`)
            .then(res => {
                setConsultores(res.data.data);
            });
    };

    const obterDataSemanasAntes = (numSemanas) => {
        var date = new Date();
        var pastDate = date.getDate() - (7 * numSemanas);
        date.setDate(pastDate);

        return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    };

    const onLoad = () => {
        obterConsultores();
        let dataInicio = obterDataSemanasAntes(8);
        var currentDate = new Date();
        let dataFim = currentDate.getFullYear() + "-" + ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + currentDate.getDate()).slice(-2);
        setFilter({ dataEntradaInicio: dataInicio, dataEntradaFim: dataFim })
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        let mensagem = 'A data início não pode ser maior que a data fim!';
        if (name === 'dataEntradaInicio' && value) {
            let dataInicio = new Date(value.split('-')[0], parseInt(value.split('-')[1]) - 1, value.split('-')[2]);
            let dataFim = new Date(filter.dataEntradaFim.split('-')[0], parseInt(filter.dataEntradaFim.split('-')[1]) - 1, filter.dataEntradaFim.split('-')[2]);
            if (dataInicio > dataFim) {
                toast.warn(mensagem);
                return
            }
        } else if (name === 'dataEntradaFim' && value) {
            let dataFim = new Date(value.split('-')[0], parseInt(value.split('-')[1]) - 1, value.split('-')[2]);
            let dataInicio = new Date(filter.dataEntradaInicio.split('-')[0], parseInt(filter.dataEntradaInicio.split('-')[1]) - 1, filter.dataEntradaInicio.split('-')[2]);
            if (dataInicio > dataFim) {
                toast.warn(mensagem);
                return
            }
        }

        setFilter({ ...filter, [name]: value });
    };

    const formatName = (e) => {
        let names = e.split(" ");
        return _.capitalize(names[0]) + " " + _.capitalize(names[names.length - 1]);
    };

    return (
        <div className="box-body">
            <div className="row">
                <div className="col-md-3">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">Relatórios</h3>
                        </div>
                        <div className="panel-body no-padding">
                            <ul className="nav nav-pills nav-stacked" id="myTabs">
                                <li className="active"><a href="#tab_graficoGeral" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Prospecção Geral</a></li>
                                <li><a href="#tab_graficoConsolidado" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Prospecção Consolidado</a></li>
                                <li><a href="#tab_comparativo" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> Comparativo</a></li>
                                <li><a href="#tab_ftiGeral" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> FTI- Geral</a></li>
                                <li><a href="#tab_contagemPorComercial" data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> FTI- Contagem por Comercial</a></li>
                                {consultores.sort((a, b) => a.nome > b.nome ? 1 : -1).map((item, i) => {
                                    return <li key={i}><a href={'#tab_graficoProspeccao' + item.id} data-toggle="pill"><i className="fa fa-circle-o text-yellow" /> {formatName(item.nome)}</a></li>
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="row form-horizontal">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="dataEntradaInicio" className="col-sm-3 control-label">Data Início</label>
                                <div className="col-sm-9">
                                    <input id="dataEntradaInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataEntradaInicio} required />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="dataEntradaFim" className="col-sm-3 control-label">Data Fim</label>
                                <div className="col-sm-9">
                                    <input id="dataEntradaFim" type="date" className="form-control" onChange={handleChange} value={filter.dataEntradaFim} required />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-content">
                        <div className="tab-pane active" id="tab_graficoGeral">
                            <GraficoGeral dataEntradaInicio={filter.dataEntradaInicio} dataEntradaFim={filter.dataEntradaFim} />
                        </div>
                        <div className="tab-pane" id="tab_graficoConsolidado">
                            <GraficoConsolidado dataEntradaInicio={filter.dataEntradaInicio} dataEntradaFim={filter.dataEntradaFim} />
                        </div>
                        <div className="tab-pane" id="tab_comparativo">
                            <Comparativo origem={'FTI'} />
                            <Comparativo origem={'MKT'} />
                            <Comparativo origem={'PAP'} />
                        </div>
                        <div className="tab-pane" id="tab_ftiGeral">
                            <FtiGeral />
                        </div>
                        <div className="tab-pane" id="tab_contagemPorComercial">
                            <ContatosConsultores origem={'FTI'} />
                            <VisitasConsultores origem={'FTI'} />
                            <PropostasGeradasConsultores origem={'FTI'} />
                        </div>
                        {consultores.sort((a, b) => a.nome > b.nome ? 1 : -1).map((item, i) => {
                            return (
                                <div key={i} className="tab-pane" id={'tab_graficoProspeccao' + item.id}>
                                    <GraficoProspeccao dataEntradaInicio={filter.dataEntradaInicio} dataEntradaFim={filter.dataEntradaFim} analistaVeolink={item.nome} />
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}
