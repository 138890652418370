import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper } from '../index';
import { CronogramaView } from './CronogramaView';
import Core from '../Core';

export function Cronograma(props) {
    const endPoint = "projetoCliente/cronograma";
    const projetoId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: '',
        showModal: false,
        modalTitle: "Novo Cronograma",
        formName: "",
        details: false,
        grid: true
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=projetoId::equal::${projetoId};;AND;;isExcluido::equal::0&order=projetoId DESC`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    const handleHide = (refresh) => {
        setParam({
            ...params,
            showModal: false,
            grid: true,
            details: false
        });
        refresh && fetchData();
    }

    const handleDetails = (id) => {
        setParam({
            ...params,
            rowId: id,
            grid: false,
            details: true,
        });
    }

    return (
        <React.Fragment>
            {params.grid &&
                <React.Fragment>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th>Nome</th>
                                    <th>Data Cadastro</th>
                                    <th>Anexo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.dataCadastro < b.dataCadastro ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td><Link to={'#'} onClick={() => handleDetails(item.id)}>{item.descricao}</Link></td>
                                                <td>{config.dateFix(item.dataCadastro)}</td>
                                                <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }

            {params.details && <CronogramaView parentId={params.rowId} onHide={handleHide} canEdit={props.canEdit} />}
        </React.Fragment>
    );
}
