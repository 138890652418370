import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function GraficoConsolidado(props) {
    const endPoint = 'lead/report';
    const [dataOrigem, setDataOrigem] = useState([]);
    const [dataInteracao, setDataInteracao] = useState([]);
    const [dates, setDates] = useState([]);
    const [titleSemana, setTitleSemana] = useState('');
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;

    const handleSearch = () => {
        if (!props.dataEntradaInicio)
            return;
        apiHelper.get(`${endPoint}/consolidado?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}`).then(res => {
            let dates = [];
            
            if (res.data.data.datasOrdenadas !== undefined) {
                res.data.data.datasOrdenadas.map(item => {
                    dates.push(item.ano + '-' + item.semana);
                    return true;
                });

                setDataOrigem(res.data.data.origem);
                setDataInteracao(res.data.data.interacao);
                setDates([...new Set(dates)]);
            }
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: { mode: 'point' },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 5 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!props.dataEntradaInicio)
            return;
        let totalContratos = [];
        let visitasObtidas = [];
        let gerouProposta = [];

        setTitleSemana(`(Semana ${dates[0]} a Semana ${dates[dates.length - 1]})`);
        dates.map(d => {
            let objTotal = dataOrigem.filter(p => (p.ano + '-' + p.semana) === d);
            totalContratos.push(objTotal.length > 0 ? objTotal[0].total : 0)

            let objVisita = dataInteracao.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === d);
            visitasObtidas.push(objVisita.length > 0 ? objVisita[0].total : 0)

            let objProposta = dataInteracao.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === d);
            gerouProposta.push(objProposta.length > 0 ? objProposta[0].total : 0)

            return null;
        })
        let datasets = [
            { label: 'Total de Contatos (FTI, PAP, MKT)', backgroundColor: '#3e71c4', borderWidth: 1, data: totalContratos },
            { label: 'Visitas Obtidas', backgroundColor: '#6fad4a', borderWidth: 1, data: visitasObtidas },
            { label: 'Propostas Geradas', backgroundColor: '#000000', borderWidth: 1, data: gerouProposta }
        ];

        const dataAux = {
            labels: dates,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">Análise de Prospecção Consolidado {titleSemana}</h3>
            </div>
            <div className="panel-body">
                {dataOrigem.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {dates.map((item, i) => {
                                                    return (<td key={i}>{item}</td>)
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Total de Contatos (FTI, PAP, MKT)</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataOrigem.filter(p => (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Visitas Obtidas</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataInteracao.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                            <tr>
                                                <th>Propostas Geradas</th>
                                                {dates.map((item, i) => {
                                                    let obj = dataInteracao.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === item);
                                                    return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
