import React, { useState } from 'react';
import { apiHelper, toast, config } from '../index';

export function MotivoCancelamentoForm(props) {
    const endPoint = 'suprimentos/rm/';
    const parentId = props.parentId;
    const [data, setData] = useState({});

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.put(`${endPoint}/cancelarRequisicao/${parentId}?motivoCancelamento=${data.motivoCancelamento}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    props.onHide();
                }
                else
                    toast.error('Atenção, não foi possível cancelar a requisição!');
            }).catch(error => {
                toast.error('Atenção, não foi possível atualizar o status');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group col-sm-12">
                <label>Motivo Cancelamento *</label>
                <textarea id="motivoCancelamento" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.motivoCancelamento} required />
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
