import React, { Component } from 'react';

export class Box extends Component {
    render() {
        const { type, title = undefined, footerContent, customOptions = undefined, children } = this.props;
        let boxType = 'box';
        if (type !== undefined) boxType = 'box box-' + type;

        return (
            <div className={boxType}>
                {title !== undefined &&
                    <div className="box-header with-border">
                        <h3 className="box-title">{title}</h3>
                        {customOptions !== undefined &&
                            <div className="box-tools pull-right">
                                {customOptions}
                            </div>
                        }
                    </div>
                }
                {title === undefined && customOptions !== undefined &&
                    <div className="box-header with-border">
                        <div className="box-tools pull-right">
                            {customOptions}
                        </div>
                    </div>
                }
                <div className="box-body">
                    {children}
                </div>
                {footerContent !== undefined &&
                    <div className="box-footer clearfix">
                        {footerContent}
                    </div>
                }
            </div>
        );
    }
}
