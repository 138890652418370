import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Login, Portal } from './_components';

import 'react-toastify/dist/ReactToastify.css'

export default function App() {
    return (
        <>
            <ToastContainer />
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={Portal} />
            </Switch>
        </>
    );
}
