import React, { Component } from 'react';
import {
    uuidv4, Table, Button, FasIcon, FormGroupButton
} from '../../adminlte/AdminLTE';

export default class InternalMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            api: props.api,
            keyName: props.keyName,
            rowName: props.rowName,
            parentKey: props.parentkey,
            parentApi: props.parentapi,
            memberCommand: props.membercommand,
            internalTable: [],
            memberTable: [],
            memberSearchValue: '',
        };

        this.handleMemberSearch = this.handleMemberSearch.bind(this);
        this.handleMemberSearchRefresh = this.handleMemberSearchRefresh.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.state.parentKey !== undefined) {
            this.getData();
            this.getMemberData();
        }
    }

    handleAdd(memberId) {
        const { api, parentApi, keyName, memberCommand } = this.state;
        let member = memberCommand;
        member[keyName] = memberId;

        this.setState({ isSaving: true });

        fetch(`${parentApi.endPoint}${api.parentEndPoint}`, {
            method: 'POST',
            body: JSON.stringify(member),
            headers: api.headers
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.getData();
                this.getMemberData();
            }
            else {
                alert(response[1]["msg"]);
            }

            this.setState({ isSaving: false });
        }).catch(error => {
            alert(error);
            this.setState({ isSaving: false });
        });
    }

    getData(filter) {
        if (filter === undefined) filter = '';
        const { api } = this.state;

        fetch(`${api.memberEndPoint}?${api.filterName}=${filter}`, {
            headers: api.headers
        }).then(
            response => response.json()
        ).then(data => {
            if (data.length > 0) {
                this.setState({ internalTable: data });
            }
        });
    }

    getMemberData(filter) {
        if (filter === undefined) filter = '';
        const { api, parentApi, parentKey } = this.state;

        fetch(`${parentApi.endPoint}/${parentKey}${api.parentEndPoint}/?${api.filterName}=${filter}`,
            { headers: api.headers })
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    this.setState({ memberTable: data });
                }
            });
    }

    handleMemberSearch(e) {
        let value = (e === null ? '' : e.target.value);
        this.setState({ memberSearchValue: value });

        if (value.length > 2 || value === '')
            this.getData(value);
    }

    handleMemberSearchRefresh() {
        this.setState({
            memberSearchValue: '',
            internalTable: [],
            memberTable: []
        });

        this.getData();
        this.getMemberData();
    }

    addRemove(memberId, action) {
        if (action === 'add') {
            this.handleAdd(memberId);
            return;
        }

        const { parentApi, parentKey, api } = this.state;
        const url = `${parentApi.endPoint}/${parentKey}${api.parentEndPoint}/${memberId}`;

        fetch(url, { method: 'DELETE', headers: api.headers })
            .then(response => {
                return Promise.all([response.status, response.json()]);
            })
            .then((response) => {
                this.handleMemberSearchRefresh();
                if (response[0] !== 200)
                    alert(response[1]["msg"]);
            })
            .catch(error => { alert(error); });
    }

    findMember(memberId) {
        const { memberTable, keyName } = this.state;

        let member = memberTable.filter(e => e[keyName] === memberId);
        return member.length > 0;
    }

    render() {
        const { internalTable, rowName, keyName } = this.state;

        return (
            <React.Fragment>
                <FormGroupButton size="small" placeholder="Buscar"
                    onChange={this.handleMemberSearch}
                    value={this.state.memberSearchValue}
                    buttonRight={<Button onClick={this.handleMemberSearchRefresh}><FasIcon name='undo' /></Button>}
                />

                <Table striped bordered>
                    <tbody>
                        {internalTable.map(row => {
                            let isMember = this.findMember(row[keyName]);

                            return (
                                <tr key={uuidv4()}>
                                    <td>{row[rowName]}</td>
                                    <td style={{ width: "35px" }}>
                                        <Button bsSize="xsmall"
                                            bsStyle={isMember ? "success" : "default"}
                                            onClick={() => this.addRemove(row[keyName], isMember ? 'del' : 'add')}
                                        >
                                            <FasIcon name={isMember ? 'check-circle' : 'circle-thin'} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}