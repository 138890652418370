import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, config, toast } from '../';
import { LeadInteracao } from './LeadInteracao';
import { LeadContatos } from './LeadContatos';

export function LeadView(props) {
    const endPoint = 'portal/crud/uvw_v2_crm_lead/';
    const endPointContatos = 'portal/crud/ComercialLeadContato/';
    const endPointInteracao = 'portal/crud/ComercialLeadInteracao/';
    const rowId = props.match.params.id;
    const [data, setData] = useState({});

    const fetchData = () => {
        apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [rowId]);

    const handleClose = () => {
        props.history.push({
            pathname: '/crm/lead/'
        });
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ? Obs: Todos os dados serão apagados. Esta operação não poderá ser desfeita!"))
            return;

        deleteInteracoes(id);
    }

    const deleteInteracoes = (id) => {
        apiHelper.api_delete(`${endPointInteracao}${id}/leadId`).then(res => {
            deleteContatos(id);
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const deleteContatos = (id) => {
        apiHelper.api_delete(`${endPointContatos}${id}/leadId`).then(res => {
            deleteLead(id);
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const deleteLead = (id) => {
        apiHelper.api_delete(`${endPoint}${id}/id`).then(res => {
            config.httpResult(res.data);
            handleClose();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });

    }

    return (
        <Content title="Lead" browserTitle="Detalhes do Lead">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id} - {config.dateFix(data.dataEntrada)}</h3>

                            <div className="box-tools pull-right">
                                <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body">
                            <dl>
                                <dt>Analista</dt>
                                <dd>{data.analistaVeolink}</dd>
                                <dt>Status</dt>
                                <dd>{data.status}</dd>
                                <dt>Origem</dt>
                                <dd>{data.origem}</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Detalhes</h3>
                        </div>
                        <div className="box-body">
                            <dl>
                                <dt>Empresa</dt>
                                <dd>{data.empresa}</dd>
                                <dt>Grupo Empresarial</dt>
                                <dd>{data.grupoEmpresarial ? data.grupoEmpresarial : "-"}</dd>
                                <dt>Segmento</dt>
                                <dd>{data.segmento}</dd>
                            </dl>                            
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><Link to="#tab_interacao" data-toggle="tab">Interações</Link></li>
                            <li><Link to="#tab_contato" data-toggle="tab">Contatos</Link></li>
                            <button type="button" className="btn btn-box-tool pull-right" onClick={() => handleDelete(rowId)}><i className="fa fa-trash"></i></button>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_interacao" className="tab-pane active">
                                <LeadInteracao parentId={rowId} />
                            </div>
                            <div id="tab_contato" className="tab-pane">
                                <LeadContatos parentId={rowId} origem={data.origem} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
