import React, { useState, useEffect } from 'react';
import { config, apiHelper, Select, fileHelper } from '../../index';
import moment from 'moment-timezone';

export function FtiGeral(props) {
    const endPoint = 'lead/report';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        origem: '',
        status: '',
        criadorId: '',
        consultorId: '',
        grupoEmpresarial: '',
        empresa: '',
        segmento: '',
        cidade: '',
        nomeContato: '',
        departamento: '',
        cargo: '',
        tipoContato: '',
        telefone: '',
        email: '',
        linkedin: '',
        site: '',
        contatoObservacao: '',
        contatoTelefonico: '',
        emailEnviado: '',
        visitaObtida: '',
        visitaRecusada: '',
        aguardandoResposta: '',
        gerouProposta: '',
        agenda: '',
        outros: '',
        dataEntrada: ''
    });
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [dataExcel, setDataExcel] = useState([]);

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}/ftiGeral?filter=${where}`).then(res => {
            var data = res.data.data;
            setData(data);
            obterDataToExcel(data);
        });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';

        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'titulo')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }

                    where += `${key}::${operador}::${value2}`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }

                where += `${name}::${operador}::${value}`;
            }

            return null;
        });

        fetchData(where);
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            origem: '',
            status: '',
            criadorId: '',
            consultorId: '',
            grupoEmpresarial: '',
            empresa: '',
            segmento: '',
            cidade: '',
            nomeContato: '',
            departamento: '',
            cargo: '',
            tipoContato: '',
            telefone: '',
            email: '',
            linkedin: '',
            site: '',
            contatoObservacao: '',
            contatoTelefonico: '',
            emailEnviado: '',
            visitaObtida: '',
            visitaRecusada: '',
            aguardandoResposta: '',
            gerouProposta: '',
            agenda: '',
            outros: '',
            dataEntrada: ''
        });

        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        //if (page === params.totalPages)
        //    return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const obterDataToExcel = (data) => {
        let dataExcel = [];
        dataExcel = data;

        for (var i = 0; i < dataExcel.length; i++) {
            dataExcel[i].contatoTelefonico = dataExcel[i].contatoTelefonico === 1 ? 'Sim' : 'Não';
            dataExcel[i].emailEnviado = dataExcel[i].emailEnviado === 1 ? 'Sim' : 'Não';
            dataExcel[i].visitaObtida = dataExcel[i].visitaObtida === 1 ? 'Sim' : 'Não';
            dataExcel[i].visitaRecusada = dataExcel[i].visitaRecusada === 1 ? 'Sim' : 'Não';
            dataExcel[i].aguardandoResposta = dataExcel[i].aguardandoResposta === 1 ? 'Sim' : 'Não';
            dataExcel[i].gerouProposta = dataExcel[i].gerouProposta === 1 ? 'Sim' : 'Não';
            dataExcel[i].agenda = dataExcel[i].agenda === 1 ? 'Sim' : 'Não';
            dataExcel[i].outros = dataExcel[i].outros === 1 ? 'Sim' : 'Não';
        }

        setDataExcel(dataExcel);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div style={{ marginBottom: "7px" }}>
                    <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fileHelper.exportToCSV(dataExcel, 'FTIGeral_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
                    <div className="pull-right">
                        {renderPage()}
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                        <thead>
                            <tr className="bg-light-blue color-palette">
                                <th style={{ width: "50px" }}>#</th>
                                <th style={{ width: "120px" }}>Codigo</th>
                                <th>Origem</th>
                                <th>Status</th>
                                <th>Analista FTI</th>
                                <th>Analista Veolink</th>
                                <th>Grupo Empresarial</th>
                                <th>Empresa</th>
                                <th>Segmento</th>
                                <th>Cidade</th>
                                <th>Contato</th>
                                <th>Departamento</th>
                                <th>Cargo</th>
                                <th>Tipo Contato</th>
                                <th>Telefone</th>
                                <th>Email</th>
                                <th>Linkedin</th>
                                <th>Site</th>
                                <th>Observação</th>
                                <th>Contato Telefonico</th>
                                <th>Email Enviado</th>
                                <th>Visita Obtida</th>
                                <th>Visita Recusada</th>
                                <th>Aguardando Resposta</th>
                                <th>Gerou Proposta</th>
                                <th>Agenda</th>
                                <th>Outros</th>
                                <th>Data Entrada</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                <td><input id="id" type="number" className="form-control input-sm" onChange={handleChange} value={filter.id} /></td>
                                <td>
                                    <Select id="origem" className="form-control" onChange={handleChange} value={filter.origem}>
                                        <option value="">Todas</option>
                                        <option endpoint="lead/origem" valuefield="nome" textfield="nome" />
                                    </Select>
                                </td>
                                <td>
                                    <Select id="status" className="form-control" onChange={handleChange} value={filter.status}>
                                        <option value="">Todos</option>
                                        <option value="Aguardando Interação">Aguardando Interação</option>
                                        <option value="Em prospecção">Em prospecção</option>
                                        <option value="Em negociação">Em negociação</option>
                                        <option value="Bem sucedida">Bem sucedida</option>
                                        <option value="Perdida">Perdida</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="criadorId" className="form-control" onChange={handleChange} value={filter.criadorId}>
                                        <option value="">Todos</option>
                                        <option endpoint="portal/crud/Usuario?filter=isExcluido::equal::0" valuefield="pkUsuario" textfield="nome" />
                                    </Select>
                                </td>
                                <td>
                                    <Select id="consultorId" className="form-control" onChange={handleChange} value={filter.consultorId}>
                                        <option value="">Todos</option>
                                        <option endpoint="comercial/pessoa/perfil?perfil=AnalistaVeolink" valuefield="id" textfield="nome" />
                                    </Select>
                                </td>
                                <td><input id="grupoEmpresarial" type="text" className="form-control input-sm" onChange={handleChange} value={filter.grupoEmpresarial} /></td>
                                <td><input id="empresa" type="text" className="form-control input-sm" onChange={handleChange} value={filter.empresa} /></td>
                                <td><input id="segmento" type="text" className="form-control input-sm" onChange={handleChange} value={filter.segmento} /></td>
                                <td><input id="cidade" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cidade} /></td>
                                <td><input id="nomeContato" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nomeContato} /></td>
                                <td><input id="departamento" type="text" className="form-control input-sm" onChange={handleChange} value={filter.departamento} /></td>
                                <td><input id="cargo" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cargo} /></td>
                                <td><input id="tipoContato" type="text" className="form-control input-sm" onChange={handleChange} value={filter.tipoContato} /></td>
                                <td><input id="telefone" type="text" className="form-control input-sm" onChange={handleChange} value={filter.telefone} /></td>
                                <td><input id="email" type="text" className="form-control input-sm" onChange={handleChange} value={filter.email} /></td>
                                <td><input id="linkedin" type="text" className="form-control input-sm" onChange={handleChange} value={filter.linkedin} /></td>
                                <td><input id="site" type="text" className="form-control input-sm" onChange={handleChange} value={filter.site} /></td>
                                <td><input id="contatoObservacao" type="text" className="form-control input-sm" onChange={handleChange} value={filter.contatoObservacao} /></td>
                                <td>
                                    <Select id="contatoTelefonico" className="form-control" onChange={handleChange} value={filter.contatoTelefonico}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="emailEnviado" className="form-control" onChange={handleChange} value={filter.emailEnviado}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="visitaObtida" className="form-control" onChange={handleChange} value={filter.visitaObtida}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="visitaRecusada" className="form-control" onChange={handleChange} value={filter.visitaRecusada}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="aguardandoResposta" className="form-control" onChange={handleChange} value={filter.aguardandoResposta}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="gerouProposta" className="form-control" onChange={handleChange} value={filter.gerouProposta}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="agenda" className="form-control" onChange={handleChange} value={filter.agenda}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td>
                                    <Select id="outros" className="form-control" onChange={handleChange} value={filter.outros}>
                                        <option value="">Todas</option>
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Select>
                                </td>
                                <td><input id="dataEntrada" type="text" className="form-control input-sm" onChange={handleChange} value={filter.dataEntrada} /></td>
                            </tr>
                            {data && data.sort((a, b) => a.dataCriacao < b.dataCriacao ? 1 : -1)
                                .slice(params.initRow, params.endRow)
                                .map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>#</td>
                                            <td>{item.origem + '-' + item.id}</td>
                                            <td>{item.origem}</td>
                                            <td>{item.status}</td>
                                            <td>{item.analistaFTI}</td>
                                            <td>{item.analistaVeolink}</td>
                                            <td>{item.grupoEmpresarial}</td>
                                            <td>{item.empresa}</td>
                                            <td>{item.segmento}</td>
                                            <td>{item.cidade}</td>
                                            <td>{item.nomeContato}</td>
                                            <td>{item.departamento}</td>
                                            <td>{item.cargo}</td>
                                            <td>{item.tipoContato}</td>
                                            <td>{item.telefone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.linkedin}</td>
                                            <td>{item.site}</td>
                                            <td>{item.contatoObservacao}</td>
                                            <td>{item.contatoTelefonico}</td>
                                            <td>{item.emailEnviado}</td>
                                            <td>{item.visitaObtida}</td>
                                            <td>{item.visitaRecusada}</td>
                                            <td>{item.aguardandoResposta}</td>
                                            <td>{item.gerouProposta}</td>
                                            <td>{item.agenda}</td>
                                            <td>{item.outros}</td>
                                            <td>{config.dateFix(item.dataEntrada)}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
                <div style={{ marginTop: "7px" }}>
                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>

                    <div className="pull-right">
                        {renderPage()}
                    </div>
                </div>
            </div>
        </div>
    )
}
