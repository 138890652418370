import React, { Component } from 'react';
import {
    Row, Modals, FieldGroup, Col, Button,
    ButtonToolbar, FormGroupButton, Checkbox
} from '../../components/adminlte/AdminLTE';

import { PesquisaGenerica } from '../../components/controls/PesquisaGenerica';
import { toast } from 'react-toastify';

const config = {
    token: 'Bearer ' + localStorage.getItem('id_token'),
    endPoint: localStorage.getItem('apiUrl') + 'comercial/site/'
};

export class OrdemServicoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            row: {},
            clientList: [],
            siteList: [],
            materialList: [],
            groupList: [],
            subGroupList: [],
            showFormFindSites: false,
            siteSearch: '',
            data: {
                titleOS: '',
                siteCode: 0,
                idMaterialSite: 0,
                subGroupClientCode: 0,
                groupCode: 0,
                priority: false,
                description: '',
                codigoExternoCliente: '',
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeGroup = this.handleChangeGroup.bind(this);
        this.sitesSearch = this.sitesSearch.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setState({ row: this.props.row });
        this.getGroups();
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { data: { ...prevState.data, [name]: value } };
        });
    }

    getSites(filter) {
        const { config } = this.props;

        fetch(config.apiEndPoint + "site/" + filter, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ siteList: data });
        });
    }

    sitesSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ siteSearch: value });

        if (value.length > 2) {
            this.getSites(value);
        } else {
            this.setState({ siteList: [] });
        }
    }

    getGroups() {
        const { config } = this.props;

        fetch(config.apiEndPoint + "group", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ groupList: data });
        });
    }

    getMateriaisSite = (idSite) => {
        this.setState({ materialList: [] });
        let url = `${config.endPoint}listarMateriais/${idSite}`;

        fetch(url, { headers: { 'Authorization': config.token } })
            .then(
                response => response.json()
            ).then(data => {
                if (data.data.length > 0)
                    this.setState({ materialList: data.data });                
            });
    }

    handleAdd(row) {
        this.setState({ siteSearch: row.nome, siteList: [] });

        this.setState(prevState => {
            return { data: { ...prevState.data, siteCode: row.id } };
        });

        this.getMateriaisSite(row.id);
    }

    handleChangeGroup(e) {
        let value = e.target["value"];

        this.setState(prevState => {
            return { data: { ...prevState.data, groupCode: value } };
        });

        const { config } = this.props;

        fetch(config.apiEndPoint + "/subGroup/" + value, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ subGroupList: data });
        });
    }

    handleChangeMaterial = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { data: { ...prevState.data, [name]: value } };
        });
    }

    handleSubmit(e) {
        if (this.state.data.titleOS === "") {
            toast.warn('Informe o Titulo');
            return;
        }

        if (this.state.data.siteCode === "") {
            toast.warn('Informe o Site');
            return;
        }

        if (this.state.data.groupCode === "") {
            toast.warn('Informe o Grupo');
            return;
        }

        if (this.state.data.subGroupClientCode === "") {
            toast.warn('Informe o Sub Grupo');
            return;
        }

        if (this.state.data.description === "") {
            toast.warn('Informe a descrição');
            return;
        }

        const { config } = this.props;

        fetch(config.apiEndPoint + "openOS", {
            method: 'POST',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {

            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.onHide();
            }
            else {
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            toast.error(error.response.data.msg);
        });
    }

    onHide() {
        this.setState(prevState => {
            return {
                data: {
                    ...prevState.data, description: '',
                    siteCode: 0,
                    groupCode: 0,
                    subGroupClientCode: 0,
                    priority: false,
                    titleOS: ''
                }
            };
        });

        this.setState({ siteList: [], groupList: [], description: '', siteSearch: '' });
        this.props.onHide();
    }

    render() {
        const { show = false, config, row } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title="Abrir OS" show={show} onHide={() => this.onHide()}
                footer={
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="success" disabled={isSaving} onClick={this.handleSubmit}>
                            {isSaving ? 'Salvando...' : 'Salvar'}
                        </Button>
                        <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                    </ButtonToolbar>
                }>

                <PesquisaGenerica config={config} title="Buscar Sites" source="comercial/site/?nome=" show={this.state.showFormFindSites} onImport={(row) => { this.handleAdd(row); }} row={row} onHide={() => this.setState({ showFormFindSites: false })} />

                <Row>
                    <Col xs={12}>
                        <Checkbox id="priority" checked={this.state.priority} onChange={this.handleChange}>Prioridade</Checkbox>
                    </Col>
                    <Col xs={12}>
                        <FormGroupButton placeholder="Site" label="Site" disabled onChange={this.sitesSearch} value={this.state.siteSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showFormFindSites: true })}>Selecionar</Button>} />
                    </Col>
                    <FieldGroup id="idMaterialSite" col={12} componentClass="select" label="Material" value={this.state.data.idMaterialSite} onChange={this.handleChangeMaterial}>
                        <option key="" value="Selecione">Selecione</option>
                        {this.state.materialList.map(item => {
                            return (
                                <option key={item.id} value={item.id}>{item.nome}</option>
                            );
                        })}
                    </FieldGroup>
                    <FieldGroup id="titleOS" col={12} type="text" label="Titulo" value={this.state.data.titleOS} onChange={this.handleChange} />
                    <FieldGroup id="groupCode" col={6} componentClass="select" label="Grupo" value={this.state.data.groupCode} onChange={this.handleChangeGroup}>
                        <option key="" value="Selecione">Selecione</option>
                        {this.state.groupList.map(item => {
                            return (
                                <option key={item.name} value={item.groupCode}>{item.name}</option>
                            );
                        })}
                    </FieldGroup>
                    <FieldGroup id="subGroupClientCode" col={6} componentClass="select" label="Sub Grupo" value={this.state.data.subGroupClientCode} onChange={this.handleChange}>
                        <option key="" value="Selecione">Selecione</option>
                        {this.state.subGroupList.map(item => {
                            return (
                                <option key={item.subGroupCode} value={item.subGroupCode}>{item.name}</option>
                            );
                        })}
                    </FieldGroup>

                    <FieldGroup id="codigoExternoCliente" col={12} type="text" label="Cód Externo Cliente" value={this.state.data.codigoExternoCliente} onChange={this.handleChange} />

                    <FieldGroup id="description" col={12} type="text" componentClass="textarea" maxLength="1000" label="Descri&#xE7;&#xE3;o" value={this.state.data.description} onChange={this.handleChange} />
                </Row>
            </Modals>
        );
    }
}