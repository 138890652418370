import React, { useState, useEffect } from 'react';
import { Content, Link, Select, config, apiHelper, toast, fileHelper } from '../';
import moment from 'moment-timezone';
import { ProjetoFaturamento } from './report/ProjetoFaturamento';
import { DashboardRelatorios } from './DashboardRelatorios';

export function Projeto(props) {
    const endPoint = 'v2/projeto/';
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        cliente: '',
        dataCriacao: '',
        status: '',
        tipoProjeto: '',
        propostaComercialId: '',
        numeroIdentificador: '',
        aprovador: '',
        responsavel: '',
        valorManutencao: '',
        valorInstalacao: '',
        valorVenda: '',
        valorLocacao: '',
        dataAprovacaoOperacional: '',
        dataVencimentoContrato: '',
        dataUltimaInteracao: '',
        dataPedido: ''
    });

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const idLogado = config.idUsuarioLogado();
    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}projeto2/?filter=${where}`)
            .then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data;
                    console.log(data);
                    setData(data);

                    let dataExcel = [];
                    data.map((item, i) => {
                        let obj = {
                            ID: item.id,
                            Nome: item.nome,
                            Cliente: item.cliente,
                            Criação: config.dateTimeFix(item.datacriacao),
                            Status: item.status,
                            Tipo: obterTipoProjeto(item.tipoprojeto),
                            Orçamento: item.propostacomercialid,
                            'OC(obsoleto)': item.numeroIdentificador,
                            GerenteOperacional: item.aprovador,
                            Responsável: item.responsavel,
                            Manutenção: item.valorManutencao,
                            Instalação: item.valorInstalacao,
                            Venda: item.valorVenda,
                            Locação: item.valorLocacao,
                            DataAprovação: config.dateTimeFix(item.dataaprovacaooperacional),
                            VencimentoContrato: config.dateTimeFix(item.datavencimentocontrato),
                            ÚltimaInteração: config.dateTimeFix(item.dataUltimaInteracao),
                            DataPedido: config.dateTimeFix(item.datapedido),
                            AFaturar: item.valorInstalacao + item.valorVenda,
                            Faturado: item.saldoFaturado,
                            Saldo: (item.valorInstalacao + item.valorVenda) - item.saldoFaturado,
                            Provisionado: item.valorProvisionado,
                            'Não Provisionado':(item.valorManutencao + item.valorInstalacao) - item.valorProvisionado,
                            'Data Prevista de Entrega Registrada': config.dateFix(item.dataPrevistaTermino),
                            'Avanço da Obra': item.realizado ? item.realizado + '%'  : '%',
                            'Observação': item.observacao
                        }
                        dataExcel.push(obj);

                        return null;
                    });

                    setDataExcel(dataExcel);
                }
                else
                    toast.error('Atenção, não foi possível carregar os Projetos');
            });

        apiHelper.get(`${endPoint}projeto2/?filter=ResponsavelId::equal::${idLogado};;AND;;Status::equal::Andamento`)
            .then(res => {
                if (res.data.code === 200) {
                    setData2(res.data.data);
                }
                else
                    toast.error('Atenção, não foi possível carregar os Projetos');
            });
    };

    const onLoad = () => {
        config.validarPermissao('MenuClientesProjeto');
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nome' || key === 'cliente' || key === 'responsavel' || key === 'tipoProjeto')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            cliente: '',
            dataCriacao: '',
            status: '',
            tipoProjeto: '',
            propostaComercialId: '',
            numeroIdentificador: '',
            aprovador: '',
            responsavel: '',
            valorManutencao: '',
            valorInstalacao: '',
            valorVenda: '',
            valorLocacao: '',
            dataAprovacaoOperacional: '',
            dataVencimentoContrato: '',
            dataUltimaInteracao: '',
            dataPedido: ''
        });
        setData([]);
        fetchData();
    };

    const obterTipoProjeto = (tipo) => {
        if (tipo === 'ProcessoSimplificado')
            return 'Processo Simplificado';
        else if (tipo === 'ProcessoCompleto')
            return 'Processo Completo';
        else if (tipo === 'ProcessoRapido')
            return 'Processo Rápido';

        return "";
    }


    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Projeto" browserTitle="Projeto">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                            <li><a href="#tab_meus_projetos" data-toggle="tab">Meus Projetos</a></li>
                            <li><a href="#tab_report" data-toggle="tab">Relatórios</a></li>
                            <li><a href="#tab_relatoriosProjetos" data-toggle="tab">Relatórios Projetos</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fileHelper.exportToCSV(dataExcel, 'Projeto_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                            Pesquisar <span className="caret" />
                                        </button>
                                        <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                            <div className="box" style={{ margin: 0 }}>
                                                <div className="box-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="form-group col-md-4">
                                                                    <label>Id</label>
                                                                    <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                                </div>
                                                                <div className="form-group col-md-8">
                                                                    <label>Nome</label>
                                                                    <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Cliente</label>
                                                                    <Select id="cliente" className="form-control input-sm" onChange={handleChange} value={filter.cliente}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="siga/serviceOrder/client/" valuefield="name" textfield="name" />
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Criação</label>
                                                                    <input id="dataCriacao" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCriacao} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Tipo</label>
                                                                    <input id="tipoProjeto" type="text" className="form-control input-sm" onChange={handleChange} value={filter.tipoProjeto} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Status</label>
                                                                    <select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status}>
                                                                        <option value="">Todos</option>
                                                                        <option value="Novo">Novo</option>
                                                                        <option value="Andamento">Andamento</option>
                                                                        <option value="Concluido">Concluído</option>
                                                                        <option value="Cancelado">Cancelado</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Orçamento</label>
                                                                    <input id="propostaComercialId" type="number" className="form-control input-sm" onChange={handleChange} value={filter.propostaComercialId} />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Gerente Operacional</label>
                                                                    <Select id="aprovador" className="form-control" onChange={handleChange} value={filter.aprovador}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="comercial/pessoa/perfil?perfil=GestorOperacional" valuefield="nome" textfield="nome" />
                                                                    </Select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label>Responsável</label>
                                                                    <Select id="responsavel" className="form-control" onChange={handleChange} value={filter.responsavel}>
                                                                        <option value="">Todos</option>
                                                                        <option endpoint="comercial/pessoa/perfil?perfil=GerenteProjeto" valuefield="nome" textfield="nome" />
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-footer" style={{ padding: 5 }}>
                                                    <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                    <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue color-palette">
                                                <th>ID</th>
                                                <th>Nome</th>
                                                <th>Cliente</th>
                                                <th>Criação</th>
                                                <th>Status</th>
                                                <th>Tipo</th>
                                                <th>Orçamento</th>
                                                <th>Gerente Operacional</th>
                                                <th>Responsável</th>
                                                <th>Manutenção</th>
                                                <th>Instalação</th>
                                                <th>Venda</th>
                                                <th>Locação</th>
                                                <th>Data Aprovação</th>
                                                <th>Vencimento Contrato</th>
                                                <th>Última Interação</th>
                                                <th>Data Pedido</th>
                                                <th>A Faturar</th>
                                                <th>Faturado</th>
                                                <th>Saldo</th>
                                                <th>Provisionado</th>
                                                <th>Não Provisionado</th>
                                                <th>Data Prevista de Entrega Registrada</th>
                                                <th>Avanço da Obra</th>
                                                <th>Observação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.sort((a, b) => a.id < b.id ? 1 : -1).slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.id}</td>
                                                            <td><Link to={'/v2/projeto/' + item.id}>{item.nome}</Link></td>
                                                            <td>{item.cliente}</td>
                                                            <td>{config.dateTimeFix(item.datacriacao)}</td>
                                                            <td>{item.status}</td>
                                                            <td>{obterTipoProjeto(item.tipoprojeto)}</td>
                                                            <td>{item.propostacomercialid}</td>
                                                            <td>{item.aprovador}</td>
                                                            <td>{item.responsavel}</td>
                                                            <td>{config.currencyFix(item.valorManutencao)}</td>
                                                            <td>{config.currencyFix(item.valorInstalacao)}</td>
                                                            <td>{config.currencyFix(item.valorVenda)}</td>
                                                            <td>{config.currencyFix(item.valorLocacao)}</td>
                                                            <td>{config.dateTimeFix(item.dataaprovacaooperacional)}</td>
                                                            <td>{config.dateTimeFix(item.datavencimentocontrato)}</td>
                                                            <td>{config.dateTimeFix(item.dataUltimaInteracao)}</td>
                                                            <td>{config.dateTimeFix(item.datapedido)}</td>
                                                            <td>{config.currencyFix(item.valorInstalacao + item.valorVenda)}</td>
                                                            <td>{config.currencyFix(item.saldoFaturado)}</td>
                                                            <td>{config.currencyFix((item.valorInstalacao + item.valorVenda) - item.saldoFaturado)}</td>
                                                            <td>{config.currencyFix(item.valorProvisionado)}</td>
                                                            <td>{config.currencyFix((item.valorManutencao + item.valorInstalacao) - item.valorProvisionado)}</td>
                                                            <td>{config.dateFix(item.dataPrevistaTermino)}</td>
                                                            <td>{item.realizado ? item.realizado + '%'  : ''}</td>
                                                            <td>{item.observacao}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                            <div id="tab_meus_projetos" className="tab-pane">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue color-palette">
                                                <th>ID</th>
                                                <th>Nome</th>
                                                <th>Cliente</th>
                                                <th>Criação</th>
                                                <th>Status</th>
                                                <th>Tipo</th>
                                                <th>Orçamento</th>
                                                <th>Responsável</th>
                                                <th>Manutenção</th>
                                                <th>Instalação</th>
                                                <th>Venda</th>
                                                <th>Locação</th>
                                                <th>Data Aprovação</th>
                                                <th>Vencimento Contrato</th>
                                                <th>Última Interação</th>
                                                <th>Data Pedido</th>
                                                <th>A Faturar</th>
                                                <th>Faturado</th>
                                                <th>Saldo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                            </tr>
                                            {data2 && data2.sort((a, b) => a.id < b.id ? 1 : -1).map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.id}</td>
                                                        <td><Link to={'/v2/projeto/' + item.id}>{item.nome}</Link></td>
                                                        <td>{item.cliente}</td>
                                                        <td>{config.dateTimeFix(item.datacriacao)}</td>
                                                        <td>{item.status}</td>
                                                        <td>{obterTipoProjeto(item.tipoprojeto)}</td>
                                                        <td>{item.propostacomercialid}</td>
                                                        <td>{item.responsavel}</td>
                                                        <td>{config.currencyFix(item.valorManutencao)}</td>
                                                        <td>{config.currencyFix(item.valorInstalacao)}</td>
                                                        <td>{config.currencyFix(item.valorVenda)}</td>
                                                        <td>{config.currencyFix(item.valorLocacao)}</td>
                                                        <td>{config.dateTimeFix(item.dataaprovacaooperacional)}</td>
                                                        <td>{config.dateTimeFix(item.datavencimentocontrato)}</td>
                                                        <td>{config.dateTimeFix(item.dataUltimaInteracao)}</td>
                                                        <td>{config.dateTimeFix(item.datapedido)}</td>
                                                        <td>{config.currencyFix(item.valorInstalacao + item.valorVenda)}</td>
                                                        <td>{config.currencyFix(item.saldoFaturado)}</td>
                                                        <td>{config.currencyFix((item.valorInstalacao + item.valorVenda) - item.saldoFaturado)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                </div>
                            </div>
                            <div id="tab_report" className="tab-pane">
                                <ProjetoFaturamento />
                            </div>
                            <div id="tab_relatoriosProjetos" className="tab-pane">
                                <DashboardRelatorios />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
