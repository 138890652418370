import React, { useState, useEffect } from 'react';
import { Content, apiHelper } from '../index';
import { AberturaVagasForm } from './AberturaVagasForm';
export function AberturaVagasView(props) {
    const endPoint = "portal/crud/uvw_v2_VagasEmprego";
    const rowId = props.match.params.id;
    const [data, setData] = useState({});
    const fetchData = () => {
        setData([]);
        apiHelper.get(`${endPoint}/${rowId}/Id`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [rowId]);
    const handleClose = () => {
        props.history.push({
            pathname: '/rh/aberturaVagas'
        });
    };

    return (
        <Content title={rowId + "-" + data.cargo} browserTitle="Detalhes do Vaga">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-header">
                            <div className="box-tools pull-right">
                                <button title="Sair" type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body">
                            <AberturaVagasForm parentId={rowId} onHide={handleClose} />
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
