import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Checkbox } from '../index';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function CampanhaForm(props) {
    const endPoint = 'marketing/campanhas/';
    const rowId = props.rowId;
    const [showLoading, setShowLoading] = useState(false);

    const [data, setData] = useState({
        nome: "",
        tPerson: false,
        fti: false,
        outros: false,
        avulso: false,
        descricao: ""
    });

    const fetchData = () => {
        let obj = {
            nome: "",
            tPerson: false,
            fti: false,
            outros: false,
            avulso: false,
            descricao: ""
        };

        if (rowId && rowId !== 0) {
            apiHelper.get(`${endPoint}/${rowId}`)
                .then(res => {
                    let data = res.data;

                    Object.keys(data).map(p => obj[p] !== undefined ? obj[p] = data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                apiHelper.toBase64(file)
                    .then((base) => {
                        setData({ ...data, file: base, anexo: file.name });
                    });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!rowId || rowId === '') {
            setShowLoading(true);
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res);
                setShowLoading(false);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
                setShowLoading(false);
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}`, data).then(res => {
                config.httpResult(res);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const onHide = (acao = false) => {
        props.onHide(acao);
    }

    return (
        <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'#3c8dbc'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(255, 99, 71, 0)' }) }}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Nome</label>
                        <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ''} />
                    </div>
                    {rowId === 0 && <div className="form-group col-md-6">
                        <label>Tipo Destinatário</label>
                        <div className="form-group">
                            <Checkbox inline id="tPerson" onClick={handleChange} checked={data.tPerson} value={data.tPerson || ''}>TPerson</Checkbox>
                            <Checkbox inline id="fti" onClick={handleChange} checked={data.fti} value={data.fti || ''}>FTI</Checkbox>
                            <Checkbox inline id="outros" onClick={handleChange} checked={data.outros} value={data.outros || ''}>Outros</Checkbox>
                            <Checkbox inline id="avulso" onClick={handleChange} checked={data.avulso} value={data.avulso || ''}>Avulso</Checkbox>
                        </div>
                    </div>}
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Imagem da Campanha</label>
                        <input type="file" id="file" onChange={handleChange} accept="image/*" required={!rowId || rowId === 0} />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Descrição</label>
                        <textarea id="descricao" className="form-control" rows="4" onChange={handleChange} value={data.descricao || ''} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="btn-toolbar">
                            <button type="button" className="btn btn-default pull-right" onClick={() => onHide(false)}>Fechar</button>
                            <button type="submit" className="btn btn-primary pull-right" disabled={showLoading? true: false}>Salvar</button>
                        </div>
                    </div>
                </div>
            </form>
        </LoadingOverlay>
    )
}
