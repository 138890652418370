import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function AvulsoForm(props) {
    const endPoint = 'marketing/campanhas/';
    const parentId = props.parentId;
    const rowId = props.rowId;
    
    const [data, setData] = useState({
        id: '',
        nomeDestinatario: "",
        destinatario: "",
        consultorId: 0,
        avulso: true,
    });

    const fetchData = () => {
        let obj = {
            id:'',
            nomeDestinatario: "",
            destinatario: "",
            consultorId: 0,
            avulso: true,
        };

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/emails/avulso/${rowId}`)
                .then(res => {
                    let data = res.data;

                    Object.keys(data).map(p => obj[p] !== undefined ? obj[p] = data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!rowId || rowId === 0) {
            apiHelper.post(endPoint + parentId + '/emails', data).then(res => {
                config.httpResult(res);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(endPoint + parentId + `/emails`, data).then(res => {
                config.httpResult(res);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    const onHide = () => {
        props.onHide();
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="nomeDestinatario">Nome</label>
                    <input id="nomeDestinatario" type="text" className="form-control" onChange={handleChange} value={data.nomeDestinatario || ''} />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="destinatario">E-mail</label>
                    <input id="destinatario" type="text" className="form-control" onChange={handleChange} value={data.destinatario || ''} />
                </div>
                <div className="form-group col-md-12">
                    <label htmlFor="consultorId">Consultor</label>
                    <Select id="consultorId" className="form-control" onChange={handleChange} value={data.consultorId || ''}>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/uvw_v2_pessoa_perfil/?filter=Perfil::equal::ConsultorComercial'} textfield="nome" valuefield="id" />
                    </Select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
