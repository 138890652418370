import React, { useState } from 'react';
import { apiHelper, Select, toast } from './../../index';

export function QuantitativoObras(props) {
    const endPoint = "v2/projeto/relatorioQtdObras";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        gerenteResponsavel: '',
        aprovador: ''
    });

    const handleSearch = () => {
        apiHelper.post(`${endPoint}`, filter)
            .then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data.result;
                    let total = 0;

                    data.forEach(item => {
                        total += item.qtdObras;
                    });

                    data.total = total;
                    setData(data);
                }
            }).catch(error => {
                toast.error('Atenção, não foi possível carregar os registros.');
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            gerenteResponsavel: '',
            aprovador: ''
        });
        setData([]);
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading bg-light-blue">
                <h3 className="panel-title">Quantitativo Total de Obras - Em Andamento</h3>
            </div>
            <div className="panel-body">
                <React.Fragment>
                    <div className="row">
                        <div className="form-group col-sm-4">
                            <label>Gerente Responsável</label>
                            <Select id="aprovador" className="form-control" onChange={handleChange} value={filter.aprovador}>
                                <option value="">Todos</option>
                                <option endpoint="comercial/pessoa/perfil?perfil=GestorOperacional" valuefield="id" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-4">
                            <label>GP Responsável</label>
                            <Select id="gerenteResponsavel" className="form-control" onChange={handleChange} value={filter.gerenteResponsavel}>
                                <option value="">Todos</option>
                                <option endpoint="comercial/pessoa/perfil?perfil=GerenteProjeto" valuefield="id" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-4">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-right" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                    {data && data.length > 0 && <div className="row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th>Cliente</th>
                                            <th>Qtd Obras</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.cliente < b.cliente ? 1 : -1)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.cliente}</td>
                                                        <td>{item.qtdObras}</td>
                                                    </tr>
                                                )
                                            })}
                                        <tr key={data.total}>
                                            <td className='bg-light-blue'>Total</td>
                                            <td className='bg-light-blue'>{data.total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </React.Fragment>
            </div>
        </div>
    )
}
