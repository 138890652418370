import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, Modal, toast } from '../index';
import { CronogramaTarefaForm } from './CronogramaTarefaForm';
import Core from '../Core';

export function CronogramaTarefa(props) {
    const endPoint = 'v2/projeto/tarefa';
    const rowId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: '',
        showModal: false,
        modalTitle: "Nova Tarefa",
        formName: ""
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=cronogramaId::equal::${rowId}`)
            .then(res => {
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [rowId]);

    const handleNew = () => {
        setParam({ ...params, showModal: true, rowId: '', formName: 'edit' });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    }

    return (
        <React.Fragment>
            {props.canEdit && <div style={{ marginBottom: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
            </div>}

            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                    <thead>
                        <tr className="bg-light-blue color-palette">
                            {props.canEdit && <th style={{ width: "50px" }}>#</th>}
                            <th>Id</th>
                            <th>Tarefa</th>
                            <th>Status</th>
                            <th>Início Programado</th>
                            <th>Fim Programado</th>
                            <th>Início Real</th>
                            <th>Fim Real</th>
                            <th>Anexo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.sort((a, b) => a.dataInicioProgramado > b.dataInicioProgramado ? 1 : -1)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        {props.canEdit && <td style={{ width: '50px' }}>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" />
                                                </button>
                                                <ul className="dropdown-menu" role="menu">
                                                    <li><Link replace to="#" onClick={() => handleShowForm("edit", "Editar Tarefa", item.id)}><i className="fa fa-edit" />Editar</Link></li>
                                                    <li><Link replace to="#" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" />Remover</Link></li>
                                                </ul>
                                            </div>
                                        </td>}
                                        <td>{item.id}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.status}</td>
                                        <td>{config.dateFix(item.dataInicioProgramado)}</td>
                                        <td>{config.dateFix(item.dataFimProgramado)}</td>
                                        <td>{config.dateFix(item.dataInicioReal)}</td>
                                        <td>{config.dateFix(item.dataFimReal)}</td>
                                        <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "edit" && <CronogramaTarefaForm projetoId={props.projetoId} cronogramaId={rowId} rowId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
