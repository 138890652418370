import React, { useState } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function ContatoForm(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.post(`${endPoint}/${rowId}/contato`, data)
            .then(res => {
                config.httpResult(res);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    }

    const onHide = () => {
        props.onHide()
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Telefone *</label>
                    <input id="telefone" type="text" className="form-control" onChange={handleChange} value={data.telefone} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Email *</label>
                    <input id="email" type="text" className="form-control" onChange={handleChange} value={data.email} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Logradouro</label>
                    <input id="logradouro" type="text" className="form-control" onChange={handleChange} value={data.logradouro} />
                </div>
                <div className="form-group col-sm-2">
                    <label>Número</label>
                    <input id="numero" type="text" className="form-control" onChange={handleChange} value={data.numero} />
                </div>
                <div className="form-group col-sm-10">
                    <label>Complemento</label>
                    <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Cep</label>
                    <input id="cep" type="text" className="form-control" onChange={handleChange} value={data.cep} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Bairro</label>
                    <input id="bairro" type="text" className="form-control" onChange={handleChange} value={data.bairro} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Cidade</label>
                    <input id="cidade" type="text" className="form-control" onChange={handleChange} value={data.cidade} />
                </div>
                <div className="form-group col-sm-6">
                    <label>UF</label>
                    <Select id="uf" className="form-control" onChange={handleChange} value={data.uf} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Origem Contato</label>
                    <Select id="origemContato" className="form-control" onChange={handleChange} value={data.origemContato} required>
                        <option value="">Selecione</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="ProspeccaoPropria">Prospecção própria</option>
                        <option value="FTI">FTI</option>
                        <option value="Indicacao">Indicação</option>        
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
