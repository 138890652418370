import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, toast } from '../';

export function PesquisaItem(props) {
    const endPointEstoque = 'estoque/estoque/';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        patrimonio: '',
        numeroSerie: '',
    });
    const [infoItemEstoque, setInfoItemEstoque] = useState([{}]);
    const [params, setParams] = useState({
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showCards, setShowCard] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }
        setShowCard(true);
        getInfoItem();
        getMovimentacaoItemEstoque();
    }

    const onLoad = () => {
        config.validarPermissao('MenuEstoqueItem');
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'patrimonio' && value.length > 10) {
            return;
        }

        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            patrimonio: '',
            numeroSerie: '',
        });
        setInfoItemEstoque([]);
        setData([]);
        setShowCard(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const getInfoItem = () => {
        apiHelper.get(`${endPointEstoque}itemEstoque?patrimonio=${filter.patrimonio}&numeroSerie=${filter.numeroSerie}`)
            .then(res => {
                setInfoItemEstoque(res.data.data);
            });
    }

    const getMovimentacaoItemEstoque = () => {
        apiHelper.get(`${endPointEstoque}movimentacaoItemEstoque?patrimonio=${filter.patrimonio}&numeroSerie=${filter.numeroSerie}`)
            .then(res => {
                setData(res.data.data);
            });
    }

    return (
        <Content title="Pesquisa Item de Estoque" browserTitle="Pesquisa Item de Estoque">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="patrimonio">Patrimônio</label>
                                <input id="patrimonio" type="text" className="form-control" placeholder="" value={filter.patrimonio} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="patrimonio">Número de Série</label>
                                <input id="numeroSerie" type="text" className="form-control" placeholder="" value={filter.numeroSerie} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="box box-primary">
                        <div className="box-header with-border"><h3 className="box-title">Pesquisa Item de Estoque</h3></div>
                        <div className="box-body">
                            {showCards === false ? 'Informe um filtro de pesquisa!' :
                                <div className="nav-tabs-custom">
                                    <ul className="nav nav-tabs">
                                        <li className="active"> <Link to="#tab_material" data-toggle="tab" aria-expanded="true">Dados Material</Link></li>
                                        <li className=""><Link to="#tab_historicoMaterial" data-toggle="tab" aria-expanded="false">Histórico de Movimentação</Link></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_material">
                                            <div className="row">
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Material: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.material : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Patrimônio: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.patrimonio : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Nº de Serie: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.numeroSerie : ''}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Categoria: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.categoria : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Tipo: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.tipo : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Modelo: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.modelo : ''}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Nota Fiscal: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.numeroNotaFiscal : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Data da Compra: <span className="text-muted">{infoItemEstoque !== null ? config.dateFix(infoItemEstoque.dataCompra) : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Fornecedor: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.fornecedor : ''}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Valor: <span className="text-muted">{infoItemEstoque !== null ? config.currencyFix(infoItemEstoque.valor) : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Garantia: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.garantia : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Unidade de Medida: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.unidadeMedida : ''}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Status: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.status : ''}</span></label>
                                                    </div>
                                                </div>
                                                <div className="form-inline">
                                                    <div className="form-group col-md-4">
                                                        <label>Material Veotex: <span className="text-muted">{infoItemEstoque !== null ? infoItemEstoque.materialVeotex : ''}</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="tab_historicoMaterial">
                                            <div className="box-body table-responsive no-padding">
                                                <table className="table table-hover table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Origem</th>
                                                            <th>Destino</th>
                                                            <th>Data</th>
                                                            <th>Responsável</th>
                                                            <th>Quantidade</th>
                                                            <th>Observação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data
                                                            .slice(params.initRow, params.endRow)
                                                            .map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{item.origem}</td>
                                                                        <td>{item.destino}</td>
                                                                        <td>{config.dateTimeFix(item.dataMovimentacao)}</td>
                                                                        <td>{item.responsavel}</td>
                                                                        <td>{item.quantidadeMovimentada}</td>
                                                                        <td>{item.observacaoMovimentacao}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="box-footer">
                                                <div className="mailbox-controls">
                                                    <div className="pull-right">
                                                        {renderPage()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
