import React, { Component } from 'react';
import {
    Modals, FieldGroup, Row, Col, Button, ButtonToolbar, FormGroupButton
} from '../../components/adminlte/AdminLTE';

import { PesquisarTecnico } from './PesquisarTecnico';
import { toast } from 'react-toastify';

export class AdicionarAcionamento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            row: {},
            actTechnicalList: [],
            nivelSiteSLA: [],
            actTechnicalValueSearch: '',
            showFormFindActTechnical: false,
            data: {
                IdOS:0,
                serviceOrderCode: 0,
                technicalCode: 0,
                technicalName: '',
                valueOS: 0,
                nivelSLA: 0
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeActTechnical = this.handleChangeActTechnical.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row });
    }

    UNSAFE_componentWillMount() {
        this.setState({ row: this.props.row });
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        this.setState(prevState => {
            return { data: { ...prevState.data, [name]: value } };
        });
    }

    handleSubmit(e) {
        if (this.state.data.technicalCode === 0) {

            toast.warn('Informe o Tecnico!');
            return;
        }

        const { config, row } = this.props;

        fetch(config.apiEndPoint + row.codeOS + "/actTechnical", {
            method: 'POST',
            body: JSON.stringify(this.state.data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }

        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {

            if (response[0] === 200) {

                toast.success('Acionamento técnico criado com sucesso!');
                this.onHide();
            }
            else {
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            toast.error(error.response.data.msg);
        });
    }

    onHide() {
        this.setState({
            data: {
                serviceOrderCode: 0,
                technicalCode: 0,
                technicalName: '',
                valueOS: 0,
                nivelSLA: 0
            }
        });

        this.props.onHide();
    }

    handleChangeActTechnical(filter) {
        fetch(this.state.config.apiUrl + "actTechnical", {
            method: 'GET',
            body: JSON.stringify(filter),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ materialList: data });
        });
    }

    handleAddTechnical(row) {
        this.setState({ actTechnicalValueSearch: row.nome, actTechnicalList: [] });

        this.setState(prevState => {
            return { data: { ...prevState.data, technicalCode: row.id, technicalName: row.nome } };
        });
    }

    render() {

        const { show = false, config, row } = this.props;
        const { isSaving, data } = this.state;

        return (
            <Modals title={"Acionamento Técnico OS " + row.codeOS} show={show} onHide={() => this.onHide()}
                footer={
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="success" disabled={isSaving} onClick={this.handleSubmit}>
                            {isSaving ? 'Salvando...' : 'Salvar'}
                        </Button>
                        <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                    </ButtonToolbar>
                }>

                <PesquisarTecnico config={config} data={data} show={this.state.showFormFindActTechnical} onImport={(row) => { this.handleAddTechnical(row); }} row={row} onHide={() => this.setState({ showFormFindActTechnical: false })} />

                <Row>
                    <Col md={9}>
                        <FormGroupButton label="Técnico" placeholder="Técnico" disabled
                            onChange={this.actTechnicalSearch} value={this.state.actTechnicalValueSearch}
                            buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showFormFindActTechnical: true })}>Selecionar</Button>}
                        />
                    </Col>
                    <FieldGroup col={3} id="valueOS" type="number" label="Valor" value={this.state.data.valueOS} onChange={this.handleChange} />
                </Row>
                <Row>
                    <Col md={9}>
                        <FieldGroup id="nivelSLA" componentClass="select" label="Nível SLA" value={this.state.data.nivelSLA} onChange={this.handleChange}>
                            <option key="" value="">Selecione</option>
                            <option key="1" value="1">Nível 1</option>
                            <option key="2" value="2">Nível 2</option>
                            <option key="3" value="3">Nível 3</option>
                        </FieldGroup>
                    </Col>
                </Row>

            </Modals>
        );
    }
}