import React from 'react';
import { Route } from 'react-router-dom';

import { Home } from './Home';

const Menu = () => {
    return [
        {
            claim: "MenuGroupAdmin", name: "Admin", icon: "fas fa-cog", order: 1000, url: "#", children: [
                { claim: "MenuGroupAdmin", name: "Theme", icon: "fas fa-image", order: 0, url: "/admin" },
                { claim: "MenuAdminUsuario", name: "Usuários", icon: "fas fa-users", order: 0, url: "/admin/users" },
                { claim: "MenuAdminGrupo", name: "Grupos", icon: "fas fa-folder", order: 0, url: "/admin/groups" }
            ]
        }
    ]
}

const AdminApp = (props) => {
    return (
        <>
            <Route exact path="/admin" component={Home} />
        </>
    );
};

export default {
    name: 'Admin',
    version: '1.0',
    control: <AdminApp />,
    menu: Menu()
};
