import React from 'react';

export const Icon = (props) => {
  return FasIcon(props)
};

export const FasIcon = (props) => {
  const { name, className = "" } = props;
  const icon = name.includes("fa-") ? name : `fa fa-${name}`;

  return <i className={`${icon} ${className}`} />
}