import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';
import { PesquisaGenericaHooks } from '../../components/controls/PesquisaGenericaHooks';

export function ClienteForm(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [uf, setUf] = useState(0);

    const fetchData = () => {

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}/cliente`).then(res => {
                let data = res.data.data;
                setUf(data.uf);
                let cliente = {
                    id: data.id,
                    nome: data.nome,
                    razaoSocial: data.razaoSocial,
                    cpfCnpj: data.cpfCnpj,
                    idConsultor: data.consultor !== undefined && data.consultor !== null ? data.consultor.id : 0,
                    consultor: data.consultor !== undefined && data.consultor !== null ? data.consultor.nome : '',
                    tipoPessoa: data.tipoPessoa,
                    tipoEmpresa: data.tipoEmpresa,
                    tipoCliente: data.tipoCliente,
                    inscricaoEstadual: data.inscricaoEstadual,
                    inscricaoMunicipal: data.inscricaoMunicipal,
                    ativo: data.ativo,
                    cep: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.cep : '',
                    cidade: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.cidade : '',
                    uf: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.uf : '',
                    bairro: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.bairro : '',
                    logradouro: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.logradouro : '',
                    complemento: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.complemento : '',
                    numero: (data.enderecoCorrepondencia !== undefined) ? data.enderecoCorrepondencia.numero : '',
                };

                setData(cliente);

            }).catch(error => {
                toast.error('Houve um erro ao carregar o Cliente!');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "uf")
            setUf(value);

        if (name === 'nome' && data.tipoPessoa === 'Fisica') {
            setData({ ...data, razaoSocial: value, nome: value });
            return;
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data.idConsultor === undefined || data.idConsultor === '') {
            toast.warn("Infome um Consultor!");
            return;
        }
        if (data.icms === '1') {
            if (data.inscricaoEstadual === '') {
                toast.warn("Informe a Inscricao estadual!");
                return;
            }

            if (data.inscricaoMunicipal === '') {
                toast.warn("Informe a Incrição Municipal!");
                return;
            }
        }

        if (data.tipoPessoa === 'Juridica' && (data.tipoEmpresa === '' || data.tipoEmpresa === undefined)) {
            toast.warn("Tipo Empresa não pode ser vazio");
            return;
        }

        data.ativo = (data.ativo === "true" || data.ativo === true);

        if (rowId === 0) {
            apiHelper.post(`${endPoint}`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res);
                        props.onHide();
                    } else if (res.data.code === 409) {
                        toast.error(res.data.msg);
                    }
                }).catch(error => {
                    toast.error(error.response.data.msg);
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/cliente`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res);
                        props.onHide();
                    }
                }).catch(error => {
                    toast.error(error.response.data.msg);
                });
        }
    }

    const handleAddConsultor = (row) => {
        setData({ ...data, idConsultor: row.id, consultor: row.nome });
    }

    const onHide = () => {
        props.onHide()
    }

    const handleRefresh = () => {
        setData({ ...data, idConsultor: 0, consultor: '' });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <PesquisaGenericaHooks titulo="Buscar Consultores" textfield="consultor" endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial&nome=" selecao={data.consultor} onImport={(row) => { handleAddConsultor(row); }} onHide={handleRefresh} />
                </div>
                <div className="form-group col-sm-4">
                    <label>Tipo Pessoa</label>
                    <Select id="tipoPessoa" className="form-control" onChange={handleChange} value={data.tipoPessoa} required >
                        <option value="">Selecione</option>
                        <option value="Fisica">Fisica</option>
                        <option value="Juridica">Juridica</option>
                    </Select>
                </div>
                <div className="form-group col-sm-4">
                    <label>Tipo Empresa</label>
                    <Select id="tipoEmpresa" className="form-control" onChange={handleChange} value={data.tipoEmpresa} required>
                        <option value="">Selecione</option>
                        <option value="privada">Privada</option>
                        <option value="publica">Publica</option>
                    </Select>
                </div>
                <div className="form-group col-sm-4">
                    <label>CNPJ ou CPF</label>
                    <input id="cpfCnpj" type="text" className="form-control" onChange={handleChange} value={data.cpfCnpj} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Nome</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                </div>
                <div className="form-group col-sm-3">
                    <label>Tipo Cliente</label>
                    <select id="tipoCliente" className="form-control input-sm" onChange={handleChange} value={data.tipoCliente} required>
                        <option key="" value="">Selecione</option>
                        <option value="F">Cons. Final </option>
                        <option value="L">Produtor Rural </option>
                        <option value="R">Revendedor </option>
                        <option value="S">Solidário </option>
                        <option value="X">Exportação </option>
                    </select>
                </div>
                <div className="form-group col-sm-3">
                    <label>Status</label>
                    <select id="ativo" className="form-control input-sm" onChange={handleChange} value={data.ativo} required>
                        <option key="" value="">Selecione</option>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                    </select>
                </div>
                <div className="form-group col-sm-12">
                    <label>Razão Social</label>
                    <input id="razaoSocial" type="text" className="form-control" onChange={handleChange} value={data.razaoSocial} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Logradouro</label>
                    <input id="logradouro" type="text" className="form-control" onChange={handleChange} value={data.logradouro} required />
                </div>
                <div className="form-group col-sm-2">
                    <label>Número</label>
                    <input id="numero" type="number" className="form-control" onChange={handleChange} value={data.numero} />
                </div>
                <div className="form-group col-sm-10">
                    <label>Complemento</label>
                    <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Cep</label>
                    <input id="cep" type="text" className="form-control" onChange={handleChange} value={data.cep} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Bairro</label>
                    <input id="bairro" type="text" className="form-control" onChange={handleChange} value={data.bairro} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>UF</label>
                    <Select id="uf" className="form-control" onChange={handleChange} value={data.uf} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Cidade</label>
                    <Select id="cidade" className="form-control" onChange={handleChange} value={data.cidade || ''} refresh={uf} required>
                        <option value="">Selecione</option>
                        {data.uf && <option endpoint={`portal/crud/uvw_cidade?filter=SIGLA::equal::${data.uf}`} valuefield="nome" textfield="nome" />}
                    </Select>
                </div>
                {data.tipoPessoa === 'Juridica' && <div className="form-group col-sm-4">
                    <label>ICMS</label>
                    <select id="icms" className="form-control input-sm" onChange={handleChange} value={data.icms}>
                        <option key="" value="">Selecione</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                </div>}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
