import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function LeadAssociarAnalistaFTI(props) {
    const endPoint = "portal/crud/ComercialLead/";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [analistaFTI, setanalistaFTI] = useState([]);

    const fetchData = () => {
        let obj = {};
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o LEAD');
            });
        }

        obterConsultores();
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
   
        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let form = data;
        delete form.id;

        apiHelper.put(`lead/${rowId}/analistaFTI/${data.criadorId}`)
            .then(res => {
                config.httpResult(res.data);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro. ');
            });
    }

    const obterConsultores = () => {
        apiHelper.get(`comercial/pessoa/perfil?perfil=AnalistaFTI`)
            .then(res => {
                setanalistaFTI(res.data.data);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Analista FTI</label>
                    <Select id="criadorId" className="form-control" onChange={handleChange} value={data.criadorId || ""}>
                        <option value="">Selecione</option>
                        {analistaFTI.map((item, i) => {
                            return (<option value={item.id}>{item.nome}</option>)
                        })}
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    )
}
