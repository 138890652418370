import React, { useState } from 'react';
import { config, apiHelper, toast } from '../index';

export function ConsultorForm(props) {
    const endPoint = 'marketing/consultores/';
    const rowId = props.rowId;

    const [data, setData] = useState({        
        file: null        
    });

    const handleChange = (e) => {
        let file = e.target.files[0];

        if (file !== undefined && file !== null) {
            const sizeMB = Math.round((file.size / 1024));

            if (sizeMB >= 20096) {
                e.target.value = null;
                setData({ ...data, file: null });
                toast.warn('Não é permitido anexo acima de 20MB.');
                return;
            }

            apiHelper.toBase64(file)
                .then((base) => {
                    setData({ ...data, file: base});
                });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.put(`${endPoint}/${rowId}`, data).then(res => {
            config.httpResult(res);
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const onHide = () => {
        props.onHide()
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Imagem da Assinatura</label>
                    <input type="file" id="file" onChange={handleChange} accept="image/*" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
