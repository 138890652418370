import React, { useState, useEffect } from 'react';
import { Content, Link, Select, config, apiHelper, toast, fileHelper, Modal } from '../';
import moment from 'moment-timezone';
import { OrcamentoForm } from './OrcamentoForm';


export function ProjetoCliente(props) {
    const endPoint = 'ProjetoCliente/';
    const [data, setData] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        cliente: '',
        status: '',
        dataCadastro: '',
        tipoProjeto: '',
        propostaComercialId: '',
        numeroIdentificador: '',
        aprovador: '',
        responsavel: '',
        valorManutencao: '',
        valorInstalacao: '',
        valorVenda: '',
        valorLocacao: '',
        dataAprovacaoOperacional: '',
        dataVencimentoContrato: '',
        dataUltimaInteracao: '',
        dataPedido: ''
    });

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}&order=statusid, id desc`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);

                    let dataExcel = [];
                    res.data.data.map((item, i) => {
                        let obj = {
                            ID: item.id,
                            Nome: item.nome,
                            Cliente: item.cliente,
                            Status: config.formataLabelStatusCliente(item.status),
                            Criação: config.dateTimeFix(item.dataCadastro),
                        }
                        dataExcel.push(obj);

                        return null;
                    });
                    setDataExcel(dataExcel);
                }
                else
                    toast.error('Atenção, não foi possível carregar os Projetos');
            });
    };

    const onLoad = () => {
        config.validarPermissao('projeto.cliente.acompanhamento');
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nome')
                operador = 'contains';

            if (key === 'status')
                operador = 'in';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });
        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            cliente: '',
            status: '',
            dataCadastro: '',
            tipoProjeto: '',
            propostaComercialId: '',
            numeroIdentificador: '',
            aprovador: '',
            responsavel: '',
            valorManutencao: '',
            valorInstalacao: '',
            valorVenda: '',
            valorLocacao: '',
            dataAprovacaoOperacional: '',
            dataVencimentoContrato: '',
            dataUltimaInteracao: '',
            dataPedido: ''
        });
        setData([]);
        fetchData();
    };




    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Projeto" browserTitle="Projeto">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("orcamento", "Novo Orçamento", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Adicionar</button>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fileHelper.exportToCSV(dataExcel, 'Projeto_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>Id</label>
                                                                <input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} />
                                                            </div>
                                                            <div className="form-group col-md-8">
                                                                <label>Nome</label>
                                                                <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Cliente</label>
                                                                <Select id="cliente" className="form-control input-sm" onChange={handleChange} value={filter.cliente}>
                                                                    <option value="">Todos</option>
                                                                    <option endpoint="siga/serviceOrder/client/" valuefield="name" textfield="name" />
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Status</label>
                                                                <Select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status}>
                                                                    <option value="">Todos</option>
                                                                    <option value="Rascunho">Novo</option>
                                                                    <option value="Fechado,AguardandoAprovacaoFinanceira">Em Processamento</option>
                                                                    <option value="AguardandoAprovacaoCliente">Aguardando Cliente</option>
                                                                    <option value="GerouProjeto">Gerou Projeto</option>
                                                                    <option value="Cancelado">Cancelado</option>
                                                                </Select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Criação</label>
                                                                <input id="dataCadastro" type="date" className="form-control input-sm" onChange={handleChange} value={filter.dataCadastro} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th>ID</th>
                                            <th>Nome</th>
                                            <th>Cliente</th>
                                            <th>Status</th>
                                            <th>Criação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.id}</td>
                                                        <td><Link to={'/v2/client/project/' + item.id}>{item.nome}</Link></td>
                                                        <td>{item.cliente}</td>
                                                        <td>{config.formataLabelStatusCliente(item.status)}</td>
                                                        <td>{config.dateTimeFix(item.dataCadastro)}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.showModal && <OrcamentoForm rowId={params.rowId} nomeCliente={params.rowId > 0 ? data.find(c => c.id === params.rowId).nomeCliente : ''} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
