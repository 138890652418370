import React from 'react';
import { Link } from 'react-router-dom';
import { authService } from '../';

export function Content(props) {
    const { title, children } = props;

    React.useEffect(() => {
        props.browserTitle ?
            document.title = `SIGA - ${props.browserTitle}`
            :
            document.title = 'SIGA';

        if (!authService.checkValidSession()) {
            
            localStorage.setItem('last_url', window.location.href);
            window.location.href = "#/login";
        }
    });

    return (
        title ? <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{title || "title"}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/">{"Home"}</Link></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    {children}
                </div>
            </div>
        </>
            :
            <div className="content pt-2">
                <div className="container-fluid p-0">
                    {children}
                </div>
            </div>
    );
}
