import React, { useEffect, useState } from 'react';
import { config, apiHelper, toast, Modal } from '../index';
import Core from '../Core';
import { OrcamentoMotivoReprovacaoForm } from './OrcamentoMotivoReprovacaoForm';

export function OrcamentoResultado(props) {
    const permissaoEscrita = config.validarClaim('orcamento.escrita');

    const parentId = props.parentId
    const folder = 'Proposta';
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false
    });
    const [resumo, setResumo] = useState([]);

    const fetchData = () => {
        apiHelper.get(`orcamento/${parentId}`).then(res => {
            setData(res.data.data);
            setResumo(res.data.data.resumo);
        });
    };

    useEffect(fetchData, [parentId, props.refresh]);

    const handleChange = (e) => {
        let name = e.target.id.split('-')[0];
        let tipo = e.target.id.split('-')[1];
        let valor = e.target.value;
        let resumoClone = [...resumo];
        let proj = resumoClone.find(f => f.tipo === tipo);
        proj[name] = valor;

        setResumo(resumoClone);
    }

    const handleSubmit = () => {
        apiHelper.put(`orcamento/${parentId}/faturamento`, resumo)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success("Parametros atualizados com sucesso!")
                    fetchData();
                }
                else toast.error('Atenção, não foi possível salvar o registro.');
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
    };

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    const handleAction = (action) => {
        let criadoPorcliente = data.criadoPorCliente;
        switch (action) {
            case "concluir":
                handleStatus(action);
                break;
            case "enviar":
                handleStatus(action);
                break;
            case "reprovar":
                setParam({ ...params, showModal: true });
                break;
            case "aprovar":
                handleStatus(action, criadoPorcliente);
                break;
            case "proposta":
                Core.api_get(`orcamento/${parentId}/proposta/`)
                    .then(res => {
                        if (res.data.msg === "success") {
                            fetchData();
                            props.onUpdate();
                        }
                    }).catch(error => {

                    });
                break;
            case "enviarCliente":
                if (!window.confirm("Deseja realmente Enviar o Projeto para Aprovação do Cliente?"))
                    return;
                const teste = {
                    observacao: '',
                    anexo: '',
                    file: '',
                    userId: '[user_id]',
                    id: parentId,
                    acao: 'enviarCliente'
                };
                const url = `projetocliente/status/`;
                apiHelper.put(url, teste)
                    .then(res => {
                        config.httpResult(res.data);
                        fetchData();
                        props.onUpdate();
                    }).catch(error => {
                        toast.error('Atenção, não foi possível atualizar o status');
                    });
                break;
            default:
                break;
        }
    };

    const handleStatus = (action, observacao) => {
        const url = `orcamento/${parentId}/status/?acao=${action}` + (observacao ? `&&observacao=${observacao}` : "");
        apiHelper.put(url)
            .then(res => {
                config.httpResult(res.data);
                fetchData();
                props.onUpdate();
            }).catch(error => {
                toast.error('Atenção, não foi possível atualizar o status');
            });
    };

    const handleDownload = async (fileName) => {
        await Core.api_download(folder, fileName).then(res => {
        });
    }

    const obterDescricao = (tipo) => {
        switch (tipo.toLowerCase()) {
            case 'locacao':
                return 'Locação';
            case 'venda':
                return 'Venda';
            case 'serviconaorecorrente':
                return 'Serviço Não Recorrente';
            case 'servicorecorrente':
                return 'Serviço Recorrente';
            default:
                return '';
        }
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Motivo Reprovação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrcamentoMotivoReprovacaoForm parentId={parentId} onUpdate={props.onUpdate} onHide={handleHide} />
                </Modal.Body>
            </Modal>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: "7px" }}>
                        {(config.validarClaim('AprovadorFinanceiro') && props.status === 'AguardandoAprovacaoFinanceira') && permissaoEscrita &&
                            <React.Fragment>
                                <button type="button" style={{ marginRight: "3px" }} className="btn btn-success btn-flat" onClick={() => handleAction('aprovar')} ><i className="fa fa-thumbs-o-up" /> Aprovar</button>
                                <button type="button" style={{ marginRight: "3px" }} className="btn btn-danger btn-flat" onClick={() => handleAction('reprovar')}><i className="fa fa-thumbs-o-down" /> Reprovar</button>
                            </React.Fragment>
                        }
                        {(!data.necessitaAprovacao && props.status === 'Rascunho' && !data.criadoPorCliente) && permissaoEscrita && (data.produtos && data.produtos.length > 0) && <button type="button" style={{ marginRight: "3px" }} className="btn btn-default btn-flat btn-sm" onClick={() => handleAction('concluir')}><i className="fa fa-times-circle-o" /> Concluir Orcamento</button>}
                        {(!data.necessitaAprovacao && props.status === 'Rascunho' && data.criadoPorCliente) && permissaoEscrita && (data.produtos && data.produtos.length > 0) && <button type="button" style={{ marginRight: "3px" }} className="btn btn-default btn-flat btn-sm" onClick={() => handleAction('enviarCliente')}><i className="fa fa-times-circle-o" /> Enviar Para Aprovação do Cliente</button>}
                        {(data.necessitaAprovacao && props.status === 'Rascunho') && permissaoEscrita && <button type="button" style={{ marginRight: "3px" }} className="btn btn-default btn-flat btn-sm" onClick={() => handleAction('enviar')}><i className="fa fa-paper-plane-o" /> Enviar para Aprovação</button>}
                        {(props.status === 'Fechado' && !props.propostaGerada) && permissaoEscrita && <button type="button" style={{ marginRight: "3px" }} className="btn btn-default btn-flat btn-sm" onClick={() => handleAction('proposta')}><i className="fa fa-file-o" /> Gerar Proposta</button>}
                        {props.propostaGerada && permissaoEscrita && <button type="button" style={{ marginRight: "3px" }} className="btn btn-default btn-flat btn-sm" onClick={() => handleDownload(props.anexoProposta)}><i className="fa fa-download" /> Baixar Proposta</button>}
                        <div className="pull-right">
                            {props.status === 'Rascunho' && permissaoEscrita && <button type="button" className="btn btn-default btn-flat btn-sm" onClick={() => handleSubmit()}><i className="fa fa-save" /> Salvar</button>}
                        </div>
                    </div>
                    <div className="row">
                        {(data && data.resumo) && <div className="table-responsive col-md-12">
                            <table className="table table-hover table-bordered table-striped no-spacing">
                                <thead>
                                    <tr className="bg-light-blue">
                                        <th>Descrição</th>
                                        <th>Investimento</th>
                                        <th>Mult.</th>
                                        <th>Resultado</th>
                                        <th>Target</th>
                                        <th>Prazo</th>
                                        <th>Inicio Fat.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data && data.resumo) && data.resumo.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <b>{obterDescricao(item.tipo)}</b>
                                                    {item.tipo === 'locacao' &&
                                                        <React.Fragment>
                                                            {item.custosAdicionaisNaoRecorrentes > 0 && <span style={{ display: "block" }}>-Custo Instalação</span>}
                                                            {item.custosAdicionaisRecorrentes > 0 && <span style={{ display: "block" }}>-Custo Manutenção</span>}
                                                        </React.Fragment>
                                                    }
                                                </td>
                                                <td>
                                                    <span style={{ display: "block" }}>{config.currencyFix(item.investimento)}</span>
                                                    {item.tipo === 'locacao' &&
                                                        <React.Fragment>
                                                            {item.custosAdicionaisNaoRecorrentes > 0 && <span style={{ display: "block" }}>{config.currencyFix(item.custosAdicionaisNaoRecorrentes)}</span>}
                                                            {item.custosAdicionaisRecorrentes > 0 && <span style={{ display: "block" }}>{config.currencyFix(item.custosAdicionaisRecorrentes)}</span>}
                                                        </React.Fragment>
                                                    }
                                                </td>
                                                <td>{parseFloat(item.multiplicador).toFixed(2)}</td>
                                                <td>{(config.currencyFix(item.resultado))}</td>
                                                <td>
                                                    {props.status === "Rascunho" && permissaoEscrita ?
                                                        <input style={{ width: '100px' }} className="input-group input-group-sm" id={`target-${item.tipo}`} type="number" value={item.target || ""} onChange={handleChange} />
                                                        : config.currencyFix(item.target)
                                                    }
                                                </td>
                                                <td>
                                                    {props.status === "Rascunho" && permissaoEscrita ?
                                                        <input style={{ width: '60px' }} className="input-group input-group-sm" id={`prazoContrato-${item.tipo}`} type="number" value={item.prazoContrato || ""} onChange={handleChange} />
                                                        : item.prazoContrato
                                                    }
                                                </td>
                                                <td>
                                                    {props.status === "Rascunho" && permissaoEscrita ?
                                                        <input style={{ width: '60px' }} className="input-group input-group-sm" id={`inicioFaturamento-${item.tipo}`} type="number" value={item.inicioFaturamento || ""} onChange={handleChange} />
                                                        : item.inicioFaturamento
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            {(data && data.resultado && data.resultado.parametros) &&
                                <table className="table table-hover table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>Dados do Projeto e Parâmetros</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.resultado.valorVendaMaterial > 0 && <tr><td>Venda</td><td align="right">{config.currencyFix(data.resultado.valorVendaMaterial)}</td></tr>}
                                        {data.resultado.valorLocacao > 0 && <tr><td>Locação</td><td align="right">{config.currencyFix(data.resultado.valorLocacao)}</td></tr>}
                                        {data.resultado.valorServicoNaoRecorrente > 0 && <tr><td>Não Recorrente</td><td align="right">{config.currencyFix(data.resultado.valorServicoNaoRecorrente)}</td></tr>}
                                        {data.resultado.valorServicoRecorrente > 0 && <tr><td>Recorrente</td><td align="right">{config.currencyFix(data.resultado.valorServicoRecorrente)}</td></tr>}
                                        <tr><td>Overhead Operacional</td><td align="right">{data.resultado.parametros.overheadOperacional * 100}%</td></tr>
                                        <tr><td>Overhead Administrativo</td><td align="right">{data.resultado.parametros.overheadAdministrativo * 100}%</td></tr>
                                        <tr><td>Custo Financeiro</td><td align="right">{data.resultado.parametros.custoFinanceiro * 100}%</td></tr>
                                        <tr><td>Taxa Reeinvestimento</td><td align="right">{data.resultado.parametros.taxaReeinvestimento * 100}%</td></tr>
                                        <tr><td>Taxa Desconto Mensal VP</td><td align="right">{data.resultado.parametros.taxaDescontoMensalValorPresente * 100}%</td></tr>
                                        <tr><td>Frete ({data.resultado.parametros.payback && data.resultado.parametros.frete * 100}%)</td><td align="right">{config.currencyFix(data.resultado.frete)}</td></tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div className="col-md-7">
                            {(data && data.resultado && data.resultado.parametros) &&
                                <table className="table table-hover table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Indicadores</th>
                                            <th align="right">Investimento</th>
                                            <th align="right">Target</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>VPL ({data.resultado.parametros.vpl})</td>
                                            <td align="right">{config.currencyFix(data.resultado.vpl)}</td>
                                            <td align="right">{config.currencyFix(data.resultadoTarget.vpl)}</td>
                                        </tr>
                                        <tr>
                                            <td>MTIR ({data.resultado.parametros.mtir && data.resultado.parametros.mtir * 100}%)</td>
                                            <td align="right">{data.resultado.mtir}</td>
                                            <td align="right">{data.resultadoTarget.mtir}</td>
                                        </tr>
                                        <tr>
                                            <td>Lucro Líquido ({data.resultado.parametros.lucroLiquido && data.resultado.parametros.lucroLiquido * 100}%)</td>
                                            <td align="right">{data.resultado.lucroLiquidoPercent}</td>
                                            <td align="right">{data.resultadoTarget.lucroLiquidoPercent}</td>
                                        </tr>
                                        <tr>
                                            <td>EBITDA ({data.resultado.parametros.ebtida && data.resultado.parametros.ebtida * 100}%)</td>
                                            <td align="right">{data.resultado.ebitdaPercent}</td>
                                            <td align="right">{data.resultadoTarget.ebitdaPercent}</td>
                                        </tr>
                                        <tr>
                                            <td>Payback ({data.resultado.parametros.payback && data.resultado.parametros.payback * 100}%)</td>
                                            <td align="right">{data.resultado.paybackPercent}</td>
                                            <td align="right">{data.resultadoTarget.paybackPercent}</td>
                                        </tr>
                                        <tr>
                                            <td>{data.resultado.mesPayback && "Mês Payback"}</td>
                                            <td align="right">{data.resultado.mesPayback && data.resultado.mesPayback + " º Mês"}</td>
                                            <td align="right">{data.resultadoTarget.mesPayback && data.resultadoTarget.mesPayback + " º Mês"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}