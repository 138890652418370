import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function ProjetoForm(props) {
    const endPoint = 'v2/projeto/';
    const projetoId = props.projetoId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {
            responsavelid: '',
            nome: '',
            comped: false,
            status: '',
            datapedido: '',
            numeropedido: '',
            observacao: '',
            descricao: '',
        };
        if (projetoId && projetoId !== '') {
            apiHelper.get(`${endPoint}/${projetoId}`)
                .then(res => {
                    let data = res.data.data;
                    if (data.dataNecessidade) {
                        data.dataNecessidade = `${data.dataNecessidade.split('-')[0]}-${data.dataNecessidade.split('-')[1]}-${data.dataNecessidade.split('-')[2].substr(0, 2)}`;
                    }
                    if (data.datapedido)
                        data.datapedido = `${data.datapedido.split('-')[0]}-${data.datapedido.split('-')[1]}-${data.datapedido.split('-')[2].substr(0, 2)}`;
                    if (data.comped === null || data.comped === false)
                        data.comped = false;
                    else
                        data.comped = true;

                    Object.keys(data).map(p => obj[p] !== undefined ? obj[p] = data[p] : null);
                    setData(obj);
                    console.log(obj);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.put(`${endPoint}/${projetoId}`, data).then(res => {
            config.httpResult(res.data);
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Responsável</label>
                    <Select id="responsavelid" className="form-control" onChange={handleChange} value={data.responsavelid || ''}>
                        <option value="">Selecione</option>
                        <option endpoint={`v2/projeto/pessoaPerfil?filter=Perfil::equal::AprovarImplantacao`} valuefield="id" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <label>Nome</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ''} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição</label>
                    <textarea id="descricao" className="form-control" rows="3" onChange={handleChange} value={data.descricao || ''}></textarea>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Tipo</label>
                    <select id="comped" className="form-control" onChange={handleChange} value={data.comped}>
                        <option value="">Selecione</option>
                        <option value='true'>Com P&D</option>
                        <option value='false'>Sem P&D</option>
                    </select>
                </div>

                <div className="form-group col-sm-6">
                    <label>Número Pedido</label>
                    <input id="numeropedido" className="form-control" onChange={handleChange} value={data.numeropedido || ''} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Data Pedido</label>
                    <input id="datapedido" type="date" className="form-control" onChange={handleChange} value={data.datapedido || ''} />
                </div>
                <div className="form-group col-md-6">
                    
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Observação</label>
                    <textarea id="observacao" className="form-control" rows="3" onChange={handleChange} value={data.observacao || ''}></textarea>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form >
    );
}

