import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config } from '../index';

export function ReportForm(props) {
    const endPoint = "portal/crud/Report/";
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const fetchData = () => {

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
                setData(res.data.data);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }

    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            data.criador = config.idUsuarioLogado();
            apiHelper.post(`${endPoint}`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    } else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/id`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    } else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-4">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ''} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Título *</label>
                    <input id="titulo" type="text" className="form-control" onChange={handleChange} value={data.titulo || ''} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Caminho </label>
                    <input id="caminho" type="text" className="form-control" onChange={handleChange} value={data.caminho || ''} />
                </div>
                <div className="form-group col-sm-12">
                    <label>Query </label>
                    <textarea id="query" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.query} />
                </div>
                <div className="form-group col-sm-12">
                    <label>Filtro</label>
                    <textarea id="filtro" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.filtro || ''} />
                </div>
                <div className="form-group col-sm-12">
                    <label>Configuração</label>
                    <textarea id="config" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.filtro || ''} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Tipo</label>
                    <input id="tipo" type="text" className="form-control" onChange={handleChange} value={data.tipo || ''} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Permissões</label>
                    <input id="claims" type="text" className="form-control" onChange={handleChange} value={data.claims || ''} />
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide(true)}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}