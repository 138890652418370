import React, { useState } from 'react';
import { config, apiHelper, toast } from '../index';

export function OrdemServicoReabrir(props) {
    const endPoint = 'v2/os/reabrir';
    const endPointOS = 'siga/ServiceOrder';
    const [data, setData] = useState({
        pkOS: props.parentId,
        descricao: ''
    });

    const handleChange = (e) => {
        
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (e.target.type === "radio") {
            setData({ ...data, qualidadeAtendimento: value });
        } else
            setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.post(endPoint, data).then(res => {
            config.httpResult(res.data);
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const handleFinalizarOs = () => {
        if (data.descricao === undefined || data.descricao === '') {
            toast.warn("Informe o campo Observação!")
            return;
        }

        apiHelper.put(`${endPointOS}/${props.parentId}/finalizar`, data).then(res => {
            config.httpResult(res.data);
            props.onHide();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });

    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Avalie este atendimento</label>
                    <label class="radio-inline" style={{ marginLeft: '7px' }}>
                        <input type="radio" name="optionsRadios" id="otimo" value="1" onChange={handleChange} />Ótimo
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="optionsRadios" id="bom" value="2" onChange={handleChange} />Bom
                     </label>
                    <label class="radio-inline">
                        <input type="radio" name="optionsRadios" id="regular" value="3" onChange={handleChange} />Regular
                     </label>
                    <label class="radio-inline">
                        <input type="radio" name="optionsRadios" id="ruim" value="4" onChange={handleChange} />Ruim
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12 col-md-12">
                    <label>Observação</label>
                    <textarea id="descricao" className="form-control" rows="5" onChange={handleChange} value={data.descricao} required></textarea>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="button" className="btn btn-primary pull-right" onClick={handleFinalizarOs} >Finalizar OS</button>
                    <button type="submit" className="btn btn-default pull-right" style={{ marginRight: '5px' }}>Reabrir OS</button>
                </div>
            </div>
        </form >
    );
}
