import React, { useState } from 'react';
import { Button, FormGroupButton, Row, Col, Link } from '../../modules';
import { fileHelper } from '../../_helpers/file-helper';

import { config, apiHelper, toast } from '../../modules/index';

import XLSX from 'xlsx';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls"
].map(function (x) { return "." + x; }).join(",");

const downloadAnexo = (fileName) => {
    fileHelper.download("Template", fileName).then(res => { });
};

export function ExcelImport(props) {
    const [file, setFile] = useState({});
    const [showLoading, setShowLoading] = useState(false);

    const handleFile = (props) => {

        try {
            if (!config.checkFiltroPreenchido(file)) {
                toast.warn('Selecione um arquivo!');
                return;
            }

            setShowLoading(true);
            let reader = new FileReader();
            let binaryString = !!reader.readAsBinaryString;
            let planilha;

            reader.onload = (e) => {
                let bstr = e.target.result;
                let wb = XLSX.read(bstr, { type: binaryString ? 'binary' : 'array', bookVBA: true, cellDates: true, dateNF: 'yyyy/mm/dd;@' });
                let wsname = wb.SheetNames[0];
                let ws = wb.Sheets[wsname];
                planilha = XLSX.utils.sheet_to_json(ws, { defval: "" });

                apiHelper.post(props.endPoint, planilha).then(res => {
                    if (res.data.code === 200) {
                        props.onImportSuccessHandle();
                        setShowLoading(false);
                    }
                    else
                        props.onImportErrorHandle(res.data);

                    setShowLoading(false);
                    props.onHide(true);
                }).catch(error => {
                    props.onImportErrorHandle();
                    setShowLoading(false);
                });
            }
            if (binaryString)
                reader.readAsBinaryString(file);
            else
                reader.readAsArrayBuffer(file);

        } catch (e) { props.onImportErrorHandle('Selecione Arquivo!'); }
    }
    const handleChangeFile = (e) => {
        let files = e.target.files;

        if (files && files[0])
            setFile(files[0]);
    }

    return (
        <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'#3c8dbc'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(255, 99, 71, 0)' }) }}>
            <div>
                <Row>
                    <Col md={12}>
                        <FormGroupButton type="file" className="form-control" id="file" accept={SheetJSFT} onChange={(e) => handleChangeFile(e)}
                            onImportSucsess={props.onImportSuccessHandle} onImportError={props.onImportErrorHandle}
                            buttonRight={<Button type="button" className="btn btn-default btn-flat" onClick={() => handleFile(props)}>Importar</Button>} />
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>
                    </Col>
                    <Col md={2}>
                        <Link to="#" onClick={() => downloadAnexo(props.filePath)}>{"Template"}</Link>
                    </Col>
                </Row>
            </div>
        </LoadingOverlay>
    )
}

