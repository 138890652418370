import React, { useEffect } from 'react';
import { apiHelper, config, toast } from '../';
import ReportContainer from './ReportContainer';

export function Report(props) {
    const reportId = props.id ? props.id : props.match.params.id;
    //const [report, setReport] = useState({});

    const fetchData = () => {
        let idUsuarioLogado = config.idUsuarioLogado();
        apiHelper.get(`v2/report/${reportId}/${idUsuarioLogado}`)
            .then(res => {
                if (res.data.data === null) {
                    window.location.href = '#/erro/';
                    return;
                }

                //setReport(res.data.data);
            }).catch(error => {
                toast.error('Não foi possível encontrar o relatório!');
            });
    }

    useEffect(fetchData, [reportId]);
    return (
        <div className="box box-primary">
            <div className="box-body">
                <ReportContainer reportId={reportId} showBox={true} />
            </div>
        </div>
    )
}