import React, { useState } from 'react';
import { config, apiHelper, fileHelper, toast, Select } from '../../index';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

export function RelatorioPadrao(props) {
    const endPoint = "v2/projeto/relatorioPadrao";
    const [data, setData] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        nome: '',
        cliente: '',
        status: '',
        tipoProjeto: '',
        numeroPedido: '',
        dataPedidoInicio: '',
        dataPedidoFim: '',
        dataCriacaoInicio: '',
        dataConclusaoInicio: '',
        dataConclusaoFim: '',
        dataCriacaoFim: '',
        idOrcamento: '',
        gerenteResponsavel: '',
        responsavelVenda: '',
        aprovador: ''
    });
    const [params, setParam] = useState({
        rowId: 0,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }

        setShowLoading(true);
        apiHelper.post(`${endPoint}`, filter)
            .then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data;
                    setData(data);

                    let dataExcel = [];
                    data.map((item, i) => {
                        let obj = {
                            "ID": item.id,
                            "Nome": item.nome,
                            "Cliente": item.cliente,
                            "Status": item.status,
                            "Tipo Projeto": item.tipoProjeto,
                            "Número Pedido": item.numeroPedido,
                            "Data Pedido": item.dataPedido === null ? '' : config.dateFix(item.dataPedido),
                            "Data Criação": config.dateFix(item.dataCriacao),
                            "Data Conclusão": item.dataAlteracao === null || item.status !== 'Concluido' ? '' : config.dateFix(item.dataAlteracao), //Data alteração com status Concluido
                            "Orçamento": item.idOrcamento,
                            "GP Responsável": item.gerenteResponsavel,
                            "Consultor": item.responsavelVenda,
                            "Gerente Responsável": item.aprovador,
                            "Instalação": item.instalacao === null ? 0 : item.instalacao,
                            "Manutenção": item.manutencao === null ? 0 : item.manutencao,
                            "Venda": item.venda === null ? 0 : item.venda,
                            "Locação": item.locacao === null ? 0 : item.locacao,
                            "A Faturar": somarAFaturar(item),
                            "Faturado": somarFaturado(item),
                            "Saldo": (somarAFaturar(item) - somarFaturado(item))
                        }
                        dataExcel.push(obj);
                        return null;
                    });

                    setDataExcel(dataExcel);
                }
                setShowLoading(false);
            }).catch(error => {
                toast.error('Atenção, não foi possível carregar os registros.');
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            id: '',
            nome: '',
            cliente: '',
            status: '',
            tipoProjeto: '',
            numeroPedido: '',
            dataPedidoInicio: '',
            dataPedidoFim: '',
            dataCriacaoInicio: '',
            dataCriacaoFim: '',
            dataConclusaoInicio: '',
            dataConclusaoFim: '',
            idOrcamento: '',
            gerenteResponsavel: '',
            responsavelVenda: '',
            aprovador: ''
        });
        setData([]);
        setShowLoading(false);
    }

    const somarFaturado = (item) => {
        let totalFaturado = (item.totalFaturadoVenda !== null ? item.totalFaturadoVenda : 0) + (item.totalFaturadoInstalacao !== null ? item.totalFaturadoInstalacao : 0) + (item.totalFaturadoLocacao !== null ? item.totalFaturadoLocacao : 0) + (item.totalFaturadoManutencao !== null ? item.totalFaturadoManutencao : 0);
        return totalFaturado;
    }

    const somarAFaturar = (item) => {
        let totalAFaturar = item.totalAFaturarVenda + item.totalAFaturarLocacao + item.totalAFaturInstalacao + item.totalAFaturManutencao;
        return totalAFaturar;
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group col-sm-3">
                                <label>ID</label>
                                <input id="id" type="text" className="form-control" onChange={handleChange} value={filter.id} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Nome</label>
                                <input id="nome" type="text" className="form-control" onChange={handleChange} value={filter.nome} />
                            </div>
                            <div className="form-group col-sm-3">
                                <label>Status</label>
                                <select id="status" className="form-control input-sm" onChange={handleChange} value={filter.status}>
                                    <option value="">Todos</option>
                                    <option value="Novo">Novo</option>
                                    <option value="Andamento">Andamento</option>
                                    <option value="Concluido">Concluído</option>
                                    <option value="Cancelado">Cancelado</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-8">
                                <label>Cliente</label>
                                <Select id="cliente" className="form-control" onChange={handleChange} value={filter.cliente}>
                                    <option value="">Todos</option>
                                    <option endpoint="siga/serviceOrder/client/" valuefield="name" textfield="name" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Tipo Projeto</label>
                                <Select id="tipoProjeto" className="form-control" onChange={handleChange} value={filter.tipoProjeto}>
                                    <option value="">Todos</option>
                                    <option value="ProcessoCompleto">Processo Completo</option>
                                    <option value="ProcessoRapido">Processo Rápido</option>
                                    <option value="ProcessoSimplificado">Processo Simplificado</option>
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Número Pedido</label>
                                <input id="numeroPedido" type="text" className="form-control" onChange={handleChange} value={filter.numeroPedido} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>ID Orçamento</label>
                                <input id="idOrcamento" type="text" className="form-control" onChange={handleChange} value={filter.idOrcamento} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Pedido Início</label>
                                <input id="dataPedidoInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataPedidoInicio} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Pedido Fim</label>
                                <input id="dataPedidoFim" type="date" className="form-control" onChange={handleChange} value={filter.dataPedidoFim} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Criação Início</label>
                                <input id="dataCriacaoInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataCriacaoInicio} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Criação Fim</label>
                                <input id="dataCriacaoFim" type="date" className="form-control" onChange={handleChange} value={filter.dataCriacaoFim} placeholder="dd/mm/yyyy" />
                            </div>

                            <div className="form-group col-sm-4">
                                <label>Data Conclusão Início</label>
                                <input id="dataConclusaoInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataConclusaoInicio} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Conclusão Fim</label>
                                <input id="dataConclusaoFim" type="date" className="form-control" onChange={handleChange} value={filter.dataConclusaoFim} placeholder="dd/mm/yyyy" />
                            </div>

                            <div className="form-group col-sm-4">
                                <label>GP Responsável</label>
                                <Select id="gerenteResponsavel" className="form-control" onChange={handleChange} value={filter.gerenteResponsavel}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=GerenteProjeto" valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Consultor Comercial</label>
                                <Select id="responsavelVenda" className="form-control" onChange={handleChange} value={filter.responsavelVenda}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Gerente Responsável</label>
                                <Select id="aprovador" className="form-control" onChange={handleChange} value={filter.aprovador}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=GestorOperacional" valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div style={{ marginBottom: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fileHelper.exportToCSV(dataExcel, 'RelatorioPadrao_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                <thead>
                                    <tr className="bg-light-blue color-palette">
                                        <th>ID</th>
                                        <th>Nome</th>
                                        <th>Cliente</th>
                                        <th>Status</th>
                                        <th>Tipo Projeto</th>
                                        <th>Número Pedido</th>
                                        <th>Data Pedido</th>
                                        <th>Data Criação</th>
                                        <th>Data Conclusão</th>
                                        <th>Orçamento</th>
                                        <th>GP Responsável</th>
                                        <th>Consultor</th>
                                        <th>Gerente Responsável</th>
                                        <th>Instalação</th>
                                        <th>Manutenção</th>
                                        <th>Venda</th>
                                        <th>Locação</th>
                                        <th>A Faturar</th>
                                        <th>Faturado</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                        .slice(params.initRow, params.endRow)
                                        .map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.id}</td>
                                                    <td>{item.nome}</td>
                                                    <td>{item.cliente}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.tipoProjeto}</td>
                                                    <td>{item.numeroPedido}</td>
                                                    <td>{item.dataPedido === null ? '' : config.dateFix(item.dataPedido)}</td>
                                                    <td>{config.dateFix(item.dataCriacao)}</td>
                                                    <td>{(item.dataAlteracao === null || item.status !== 'Concluido') ? '' : config.dateFix(item.dataAlteracao)}</td>
                                                    <td>{item.idOrcamento}</td>
                                                    <td>{item.gerenteResponsavel}</td>
                                                    <td>{item.responsavelVenda}</td>
                                                    <td>{item.aprovador}</td>
                                                    <td>{item.instalacao === null ? 0 : config.currencyFix(item.instalacao)}</td>
                                                    <td>{item.manutencao === null ? 0 : config.currencyFix(item.manutencao)}</td>
                                                    <td>{item.venda === null ? 0 : config.currencyFix(item.venda)}</td>
                                                    <td>{item.locacao === null ? 0 : config.currencyFix(item.locacao)}</td>
                                                    <td>{config.currencyFix(somarAFaturar(item))}</td>
                                                    <td>{config.currencyFix(somarFaturado(item))}</td>
                                                    <td>{config.currencyFix(somarAFaturar(item) - somarFaturado(item))}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </React.Fragment>
    )
}
