import React, { useState, useEffect } from 'react';
import { Content, apiHelper, config, Link, Modal } from '../';
import { OrcamentoResultCliente } from './OrcamentoResultCliente';
import { AssociarUsuario } from './AssociarUsuario';
import { Cronograma } from './Cronograma';
import { Escopo } from './Escopo';
import { Interacao } from './Interacao';
import { OrcamentoMotivoReprovacaoForm } from './OrcamentoMotivoReprovacaoForm';


export function ProjetoClienteView(props) {
    const endPoint = 'projetoCliente/';
    const orcamentoId = props.match.params.id;
    const [data, setData] = useState({});

    const [params, setParam] = useState({
        showModal: false,
        acao: '',
        refresh: false,
        modalTitle: "Editar Projeto",
        motivo: ''
    });

    const onLoad = () => {
        config.validarPermissao('projeto.cliente.acompanhamento');
        fetchData();
    };

    const fetchData = () => {
        if (orcamentoId) {
            apiHelper.get(`${endPoint}${orcamentoId}/orcamento`)
                .then(res => {
                    setData(res.data.data);
                    if (res.data.data == null)
                        window.location.href = '#/erro/';
                });
        }
    }

    useEffect(onLoad, [orcamentoId]);

    const handleHide = (refresh) => {
        setParam({
            ...params,
            showModal: false,
            modalTitle: '',
            acao: '',
            refresh: false
        });

        refresh && fetchData();
    };

    const handleUpdate = () => {
        fetchData();
        setParam({ ...params, refresh: !params.refresh });
    }

    const handleClick = (action) => {
        if (action === 'aprovar') {
            setParam({ ...params, showModal: true, modalTitle: 'Aprovar', motivo: 'aprovadoCliente' });
        } else if (action === 'reprovar') {
            setParam({ ...params, showModal: true, modalTitle: 'Reprovar', motivo: 'reprovadoCliente' });
        }
    };

    return (
        data && <Content title="Detalhes do Projeto" bdataserTitle="Detalhes do Projeto">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id} - {data.nome}</h3>

                            <div className="box-tools pull-right">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                                    {data.status === "AguardandoAprovacaoCliente" &&
                                        <React.Fragment>
                                            <button type="button" className="btn btn-box-tool dropdown-toggle" data-toggle="dropdown">
                                                <i className="fa fa-wrench" />
                                            </button>
                                            <ul className="dropdown-menu" role="menu">
                                                <li><Link to="#" onClick={() => handleClick('aprovar')}><i className="fa fa-thumbs-o-up" /> Aprovar</Link></li>
                                                <li><Link to="#" onClick={() => handleClick('reprovar')}><i className="fa fa-thumbs-o-down" /> Reprovar</Link></li>
                                            </ul></React.Fragment>}
                                </div>
                                <Link to="/v2/client/project/" className="btn btn-box-tool"><i className="fa fa-times" /></Link>
                            </div>
                        </div>

                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <table className="table table-striped table-bordered no-margin">
                                        <tbody>
                                            <tr>
                                                <th>Data de Criação</th>
                                                <td>{config.dateTimeFix(data.dataCriacao)}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{config.formataLabelStatusCliente(data.status)}</td>
                                            </tr>
                                            <tr>
                                                <th>Cliente</th>
                                                <td>{data.cliente}</td>
                                            </tr>
                                            <tr>
                                                <th>Sites</th>
                                                <td>{data.sites && data.sites}</td>
                                            </tr>
                                            <tr>
                                                <th>Descrição</th>
                                                <td>{data.descricao}</td>
                                            </tr>
                                            {data.status === "AguardandoAprovacaoCliente" && <tr>
                                                <th>Deseja aprovar proposta comercial?</th>
                                                <td>
                                                    <div style={{ marginBottom: "7px" }}>
                                                        <button type="button" style={{ marginRight: "3px" }} className="btn btn-success btn-flat" onClick={() => handleClick('aprovar')} ><i className="fa fa-thumbs-o-up" /> Aprovar</button>
                                                        <button type="button" style={{ marginRight: "3px" }} className="btn btn-danger btn-flat" onClick={() => handleClick('reprovar')}><i className="fa fa-thumbs-o-down" /> Reprovar</button>
                                                    </div>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <div className="nav-tabs-custom">
                                        <ul className="nav nav-tabs">
                                            <li className="active"><a href="#tab_top_orcamento" data-toggle="tab">Orçamento</a></li>
                                            <li><a href="#tab_top_doc_comercial" data-toggle="tab">Associar Usuários</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="tab_top_orcamento" className="tab-pane active">
                                                <OrcamentoResultCliente projetoId={orcamentoId} data={data} />
                                            </div>
                                            <div id="tab_top_doc_comercial" className="tab-pane">
                                                {<AssociarUsuario parentId={orcamentoId} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_interacao" data-toggle="tab">Interações</a></li>
                            {data.projetoId && <li><a href="#tab_cronograma" data-toggle="tab">Cronogramas</a></li>}
                            <li><a href="#tab_escopo" data-toggle="tab">Escopo</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_interacao" className="tab-pane active">
                                <Interacao parentId={orcamentoId} onUpdate={handleUpdate} refresh={params.refresh} />
                            </div>
                            <div id="tab_cronograma" className="tab-pane">
                                {data.projetoId && <Cronograma parentId={data.projetoId} />}
                            </div>
                            <div id="tab_escopo" className="tab-pane">
                                <Escopo orcamentoId={orcamentoId} refresh={params.refresh} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrcamentoMotivoReprovacaoForm parentId={orcamentoId} onHide={handleHide} onUpdate={handleUpdate} motivo={params.motivo} />
                </Modal.Body>
            </Modal>
        </Content>
    )
}
