import React, { Component } from 'react';
import {
    Modals, FieldGroup, Table, Button
} from '../../components/adminlte/AdminLTE';

export class ModalFindSubGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            data: this.props.data,
            onImport: this.props.onImport,
            isSaving: false,
            row: {},
            subGroupList: [],
            subGroupValueSearch: ''
        };

        this.subGroupSearch = this.subGroupSearch.bind(this);
        this.setSubGroups = this.setSubGroups.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ row: newProps.row });
    }

    getSubGroups(filter) {
        const { data } = this.props;

        fetch(this.state.config.apiUrl + "?name=" + filter + "&parentId=" + data.parentId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(response => response.json()).then(data => {
            this.setState({ subGroupList: data });
        });
    }

    subGroupSearch(e) {

        let value = e === null ? '' : e.target.value;
        this.setState({ subGroupValueSearch: value });

        if (value.length > 1) {
            this.getSubGroups(value);
        } else {
            this.setState({ subGroupList: [] });
        }
    }

    onHide() {

        this.setState({ subGroupList: [], subGroupSearch: '' });
        this.props.onHide();
    }

    setSubGroups(row) {
        this.setState({ subGroupList: [], subGroupValueSearch: row.name });
        this.state.onImport(row);
    }

    render() {

        const { show = false } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title="Buscar Sub Grupos" show={show} onHide={() => this.onHide()} >
                <form onSubmit={this.handleSubmit}>

                    <FieldGroup id="nameSubGroup" col={12} type="text" onChange={this.subGroupSearch} value={this.state.subGroupValueSearch} />

                    <Table striped bordered hover>
                        <tbody>
                            {this.state.subGroupList.map(row => {
                                return (
                                    <tr key={row.id}>
                                        <td>{row.name}</td>
                                        <td style={{ width: "50px" }}>
                                            <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.setSubGroups(row)}>
                                                {isSaving ? 'Adicionando...' : 'Adicionar'}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                    <Button bsStyle="success" type="button" onClick={() => this.onHide()}>Ok</Button>
                </form>
            </Modals>
        );
    }
}

