import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select } from '../index';
import { createHashHistory } from 'history'
import moment from 'moment-timezone';
export const history = createHashHistory();

export function FuncionarioForm(props) {
    const endPoint = "funcionario";
    const endPointInfoVaga = "portal/crud/uvw_v2_InfomacaoVagaEmprego";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [dataVaga, setDataVaga] = useState({});
    const [anexoFoto, setAnexoFoto] = useState({
        folder: config.getFolderModuloRHFotos(),
        fileName: '',
        file: {}
    });
    const [anexoCurriculo, setAnexoCurriculo] = useState({
        folder: config.getFolderModuloRHCurriculos(),
        fileName: '',
        file: {}
    });

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}`).then(res => {
                let data = res.data.data;
                if (data.dataNascimento !== null)
                    data.dataNascimento = `${data.dataNascimento.split('-')[0]}-${data.dataNascimento.split('-')[1]}-${data.dataNascimento.split('-')[2].substr(0, 2)}`;
                if (data.dataAdmissao !== null)
                    data.dataAdmissao = `${data.dataAdmissao.split('-')[0]}-${data.dataAdmissao.split('-')[1]}-${data.dataAdmissao.split('-')[2].substr(0, 2)}`;
                if (data.dataExpedicao !== null)
                    data.dataExpedicao = `${data.dataExpedicao.split('-')[0]}-${data.dataExpedicao.split('-')[1]}-${data.dataExpedicao.split('-')[2].substr(0, 2)}`;
                if (data.dataExpedicaoCNH !== null)
                    data.dataExpedicaoCNH = `${data.dataExpedicaoCNH.split('-')[0]}-${data.dataExpedicaoCNH.split('-')[1]}-${data.dataExpedicaoCNH.split('-')[2].substr(0, 2)}`;
                if (data.dataValidadeCNH !== null)
                    data.dataValidadeCNH = `${data.dataValidadeCNH.split('-')[0]}-${data.dataValidadeCNH.split('-')[1]}-${data.dataValidadeCNH.split('-')[2].substr(0, 2)}`;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                obterInformacoesVaga(obj.fkVagaEmprego)
                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    const obterInformacoesVaga = (idVaga) => {
        let obj = {};

        apiHelper.get(`${endPointInfoVaga}?filter=pkVagaEmprego::equal::${idVaga}`).then(res => {
            let data = res.data.data[0];
            if (data === undefined) {
                setDataVaga({});
                return;
            }
            Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
            setDataVaga(obj);
            console.log(obj);
        }).catch(error => {
            toast.error('Houve um erro ao carregar o registro.');
        });

    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'fkVagaEmprego' && value !== '') {
            obterInformacoesVaga(value);
        }

        if (name === "anexoFoto" || name === "anexoCurriculo") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    if (name === "anexoFoto")
                        setAnexoFoto({ ...anexoFoto, folder: '', file: '' });
                    else if (name === "anexoCurriculo")
                        setAnexoCurriculo({ ...anexoCurriculo, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }
                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                if (name === "anexoFoto")
                    setAnexoFoto({ ...anexoFoto, folder: anexoFoto.folder, fileName: fileName, file: file });
                else if (name === "anexoCurriculo")
                    setAnexoCurriculo({ ...anexoCurriculo, folder: anexoCurriculo.folder, fileName: fileName, file: file });

                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let endereco = {
            logradouro: data.logradouro,
            complemento: data.complemento,
            cep: data.cep,
            bairro: data.bairro,
            fkUf: data.fkUf,
            fkCidade: data.fkCidade,
            guid: ''
        }

        let documentos = {
            cpf: data.cpf,
            rg: data.rg,
            dataExpedicao: data.dataExpedicao,
            orgaoExpeditor: data.orgaoExpeditor,
            ctps: data.ctps,
            pis: data.pis,
            localNascimento: data.localNascimento,
            matricula: data.matricula,
            serieCTPS: data.serieCTPS,
            fkUFCTPS: data.fkUFCTPS,
            fkUfRG: data.fkUfRG,
            CNH: data.cnh,
            categoriaCNH: data.categoriaCNH,
            dataExpedicaoCNH: data.dataExpedicaoCNH,
            dataValidadeCNH: data.dataValidadeCNH,
            tituloEleitor: data.tituloEleitor,
            guid: ''
        }

        let escolaridade = {
            fkGrauEscolaridade: data.fkGrauEscolaridade,
            isCompleto: data.isCompleto,
            descricao: data.descricao,
            guid: '',
        }

        let funcionario = {
            isOriginadoVagaEmprego: true,
            fkVagaEmprego: data.fkVagaEmprego,
            nome: data.nome,
            iniciais: data.iniciais,
            telefoneResidencial: data.telefoneResidencial,
            telefoneEmpresarial: data.telefoneEmpresarial,
            telefoneCelularEmpresarial: data.telefoneCelularEmpresarial,
            telefoneCelularPessoal: data.telefoneCelularPessoal,
            dataNascimento: data.dataNascimento,
            localNascimento: data.localNascimento,
            isAtivo: true,
            grupoSanguineo: data.grupoSanguineo,
            dataAdmissao: data.dataAdmissao,
            observacoes: data.observacoes,
            fkUsuario: 0,
            sexo: data.sexo,
            guid: ''
        }

        let email = {
            email: data.email,
            isPessoal: false
        }

        let obj = {
            endereco,
            documentos,
            escolaridade,
            funcionario,
            email,
            anexoFoto,
            anexoCurriculo
        };

        if (anexoFoto.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoFoto.file, anexoFoto.folder, anexoFoto.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        if (anexoCurriculo.fileName !== '') {
            apiHelper.upload('anexo/upload', anexoCurriculo.file, anexoCurriculo.folder, anexoCurriculo.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        if (rowId === 0) {
            apiHelper.post(`${endPoint}`, obj)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                        //history.push(`/rh/funcionarios/${res.data.data}`); Não vai redirecionar para a FuncionarioView por enquanto
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } 
        else {
            apiHelper.put(`${endPoint}/${rowId}`, obj)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                    <li className="active"><a href="#tab_funcionario" data-toggle="tab">Funcionario</a></li>
                    <li><a href="#tab_escolaridade" data-toggle="tab">Escolaridade</a></li>
                    <li><a href="#tab_endereco" data-toggle="tab">Endereço</a></li>
                    <li><a href="#tab_documentos" data-toggle="tab">Documentos</a></li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="tab_funcionario">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label>Código da Vaga *</label>
                                <Select id="fkVagaEmprego" className="form-control" onChange={handleChange} value={data.fkVagaEmprego} >
                                    <option value="">Selecione</option>
                                    <option endpoint={`${endPoint}/vaga/${rowId}`} valuefield="pkVagaEmprego" textfield="cargo" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Matricula</label>
                                <input id="matricula" type="text" className="form-control" onChange={handleChange} value={data.matricula} />
                            </div>
                            {data.fkVagaEmprego !== undefined && data.fkVagaEmprego !== '' &&
                                <React.Fragment>
                                    <div className="form-group col-sm-4">
                                        <label>Motivo da Adimissão: <span className="text-muted">{dataVaga.motivoAdmissao}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Setor: <span className="text-muted">{dataVaga.setor}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Cargo: <span className="text-muted">{dataVaga.cargo}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Agrupamento de Função: <span className="text-muted">{dataVaga.agrupamentoFuncao}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Regime de Contratação: <span className="text-muted">{dataVaga.regimeContratacao}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Horário de trabalho: <span className="text-muted">{dataVaga.horarioTrabalho}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Local de Trabalho: <span className="text-muted">{dataVaga.localTrabalho}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Salário: <span className="text-muted">{dataVaga.salario}</span></label>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label>Centro de Custo: <span className="text-muted">{dataVaga.centroCusto}</span></label>
                                    </div>
                                </React.Fragment>
                            }
                            <div className="form-group col-sm-8">
                                <label>Nome Completo</label>
                                <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Iniciais</label>
                                <input id="iniciais" type="text" className="form-control" onChange={handleChange} value={data.iniciais} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Nascimento</label>
                                <input id="dataNascimento" type="date" className="form-control" onChange={handleChange} value={data.dataNascimento} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Sexo</label>
                                <Select id="sexo" className="form-control" onChange={handleChange} value={data.sexo} >
                                    <option value="">Selecione</option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Feminino</option>
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Admissão</label>
                                <input id="dataAdmissao" type="date" className="form-control" onChange={handleChange} value={data.dataAdmissao} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Grupo Sanguíneo</label>
                                <Select id="grupoSanguineo" className="form-control" onChange={handleChange} value={data.grupoSanguineo}>
                                    <option value="">Selecione</option>
                                    <option value="O+">O+</option>
                                    <option value="A+">A+</option>
                                    <option value="B+">B+</option>
                                    <option value="AB+">AB+</option>
                                    <option value="O-">O-</option>
                                    <option value="B-">B-</option>
                                    <option value="AB-">AB-</option>
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Telefone Celular Empresarial</label>
                                <input id="telefoneCelularEmpresarial" type="text" className="form-control" onChange={handleChange} value={data.telefoneCelularEmpresarial} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Telefone Empresarial</label>
                                <input id="telefoneEmpresarial" type="text" className="form-control" onChange={handleChange} value={data.telefoneEmpresarial} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Telefone Residêncial</label>
                                <input id="telefoneResidencial" type="text" className="form-control" onChange={handleChange} value={data.telefoneResidencial} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Telefone Celular Pessoal</label>
                                <input id="telefoneCelularPessoal" type="text" className="form-control" onChange={handleChange} value={data.telefoneCelularPessoal} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Email</label>
                                <input id="email" type="text" className="form-control" onChange={handleChange} value={data.email} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Foto</label>
                                <input type="file" id="anexoFoto" onChange={handleChange} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Anexar Curriculo</label>
                                <input type="file" id="anexoCurriculo" onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="tab_escolaridade">
                        <div className="row">
                            <div className="form-group col-sm-4">
                                <label>Escolaridade</label>
                                <Select id="fkGrauEscolaridade" className="form-control" onChange={handleChange} value={data.fkGrauEscolaridade}>
                                    <option value="">Selecione</option>
                                    <option value="1">1º Grau</option>
                                    <option value="2">2º Grau</option>
                                    <option value="3">2º Grau Técnico</option>
                                    <option value="4">3º Grau</option>
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Grau Completo?</label>
                                <Select id="isCompleto" className="form-control" onChange={handleChange} value={data.isCompleto} >
                                    <option value="">Selecione</option>
                                    <option value="true">Completo</option>
                                    <option value="false">Incompleto</option>
                                </Select>
                            </div>
                            <div className="form-group col-sm-12">
                                <label>Nome do Curso</label>
                                <input id="descricao" type="text" className="form-control" onChange={handleChange} value={data.descricao} />
                            </div>

                        </div>
                    </div>
                    <div className="tab-pane" id="tab_endereco">
                        <div className="row">
                            <div className="form-group col-sm-9">
                                <label>Logradouro</label>
                                <input id="logradouro" type="text" className="form-control" onChange={handleChange} value={data.logradouro} />
                            </div>
                            <div className="form-group col-sm-3">
                                <label>Complemento</label>
                                <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Bairro</label>
                                <input id="bairro" type="text" className="form-control" onChange={handleChange} value={data.bairro} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Cep</label>
                                <input id="cep" type="text" className="form-control" onChange={handleChange} value={data.cep} />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Estado</label>
                                <Select id="fkUf" className="form-control" onChange={handleChange} value={data.fkUf} >
                                    <option value="">Selecione</option>
                                    <option endpoint="portal/crud/UF" valuefield="pkUf" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Município</label>
                                <Select id="fkCidade" className="form-control" onChange={handleChange} value={data.fkCidade} refresh={data.fkUf} >
                                    <option value="">Selecione</option>
                                    <option endpoint={data.fkUf !== undefined ? `portal/crud/cidade?filter=fkUf::equal::${data.fkUf}` : ''} valuefield="pkCidade" textfield="nome" />
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="tab_documentos">
                        <div className="row">
                            <div className="form-group col-sm-4">
                                <label>CPF</label>
                                <input id="cpf" type="text" className="form-control" onChange={handleChange} value={data.cpf} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>RG</label>
                                <input id="rg" type="text" className="form-control" onChange={handleChange} value={data.rg} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>UF RG</label>
                                <Select id="fkUfRG" className="form-control" onChange={handleChange} value={data.fkUfRG} >
                                    <option value="">Selecione</option>
                                    <option endpoint="portal/crud/UF" valuefield="pkUf" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data de Expedição</label>
                                <input id="dataExpedicao" type="date" className="form-control" onChange={handleChange} value={data.dataExpedicao} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Orgão Expeditor</label>
                                <input id="orgaoExpeditor" type="text" className="form-control" onChange={handleChange} value={data.orgaoExpeditor} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Local de Nascimento</label>
                                <input id="localNascimento" type="text" className="form-control" onChange={handleChange} value={data.localNascimento} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>CTPS</label>
                                <input id="ctps" type="text" className="form-control" onChange={handleChange} value={data.ctps} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Série CTPS</label>
                                <input id="serieCTPS" type="text" className="form-control" onChange={handleChange} value={data.serieCTPS} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>UF CTPS</label>
                                <Select id="fkUFCTPS" className="form-control" onChange={handleChange} value={data.fkUFCTPS} >
                                    <option value="">Selecione</option>
                                    <option endpoint="portal/crud/UF" valuefield="pkUf" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Pis</label>
                                <input id="pis" type="text" className="form-control" onChange={handleChange} value={data.pis} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>CNH</label>
                                <input id="cnh" type="text" className="form-control" onChange={handleChange} value={data.cnh} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Categoria CNH</label>
                                <input id="categoriaCNH" type="text" className="form-control" onChange={handleChange} value={data.categoriaCNH} />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data de Expedição CNH</label>
                                <input id="dataExpedicaoCNH" type="date" className="form-control" onChange={handleChange} value={data.dataExpedicaoCNH} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Data Validade CNH</label>
                                <input id="dataValidadeCNH" type="date" className="form-control" onChange={handleChange} value={data.dataValidadeCNH} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Título Eleitor</label>
                                <input id="tituloEleitor" type="text" className="form-control" onChange={handleChange} value={data.tituloEleitor} />
                            </div>
                            <div className="form-group col-sm-12">
                                <label>Observação</label>
                                <textarea id="observacoes" className="form-control" rows="3" maxLength="250" onChange={handleChange} value={data.observacoes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
