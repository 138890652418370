import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast } from '../index';

export function RatView(props) {
    const endPoint = 'atendimento/rat';
    const rowId = props.rowId || 0;
    const parentId = props.parentId;

    const [data, setData] = useState({});
    const [peca, setPeca] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}/${rowId}?osId=${parentId}`)
            .then(res => {
                setData(res.data);
                if (res.data.pecas.length > 0)
                    setPeca(...peca, res.data.pecas);
            }).catch(error => {
                toast.error('');
            });
    }

    useEffect(fetchData, []);

    const onImprimir = () => {
        window.print();
    }
    const handleClose = () => {
        props.onHide();
    }

    return (
        <React.Fragment>
            <div class="box-header"><h3 class="box-title"><b>Ordem de Serviço {data.ordemServico}</b></h3>
                <div class="box-tools pull-right no-printme">
                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                </div></div>
            <div class="box-body printme">
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Site</label><br />
                        <span>{data.site}</span>
                    </div>

                    <div className="form-group col-md-12">
                        <label>Endereço</label><br />
                        <span>{data.endereco}</span>
                    </div>

                    <div className="form-group col-md-12">
                        <label>UF</label><br />
                        <span>{data.uf}</span>
                    </div>

                    <div className="form-group col-md-12">
                        <label>Serviço a Executar</label><br />
                        <span>{data.servicoAExecutar}</span>
                    </div>

                    <div className="form-group col-md-12">
                        <label>Descrição do trabalho Realizado</label><br />
                        <span> {data.descricaoTrabalho} </span>
                    </div>

                    <div className="form-group col-md-12">
                        <label>Eventuais Pendencias</label><br />
                        <span>{data.eventuaisPendencias} </span>
                    </div>
                    <div className="row" style={{ marginLeft: "1px", marginRight: "1px" }}>
                        <div className="form-group col-md-3">
                            <label>Início Deslocamento</label><br />
                            <span>{config.dateTimeDateAndHours(data.inicioDeslocamento)}</span>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Término Deslocamento</label><br />
                            <span>{config.dateTimeDateAndHours(data.fimDeslocamento)}</span>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Início do Atedimento</label><br />
                            <span>{config.dateTimeDateAndHours(data.horaInicio)}</span>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Término do Atendimento</label><br />
                            <span>{config.dateTimeDateAndHours(data.horaFim)}</span>
                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: "1px", marginRight: "1px" }}>
                        <div className="form-group col-md-6">
                            <label>Tempo em Deslocamento: </label>
                            <span>{config.timeConvert(data.tempoDeslocamento)}</span>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Tempo em Atendimento: </label>
                            <span>{config.timeConvert(data.tempoAtendimento)}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Nome do Técnico</label><br />
                        <span>{data.nomeTecnico}</span>
                    </div>

                    <div className="form-group col-md-6">
                        <label>CPF do Técnico</label><br />
                        <span>{data.cpfTecnico}</span>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Assinatura do Técnico</label><br />
                        {data.assinaturaTecnico ? <img alt="Assinatura Tecnico" height={100} src={`data:image/jpeg;base64,${data.assinaturaTecnico}`} /> : <span>Sem assinatura do técnico.</span>}
                    </div>
                    <div className="form-group col-md-12">
                        <label>Nome do Responsável</label><br />
                        <span>{data.nomeResponsavel}</span>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Assinatura do Responsável</label><br />
                        {data.assinaturaResponsavel ? <img alt="Assinatura Responsavel" height={100} src={`data:image/jpeg;base64,${data.assinaturaResponsavel}`} /> : <span>Sem assinatura do responsável.</span>}
                    </div>

                    {peca.length > 0 && peca.map((item, i) => {
                        return (
                            <div key={i} className="form-group col-md-6">
                                <label>Peça {i + 1}</label><br />
                                <span> <b>Entrou:</b> {item.numSerieEntrou} <b>Tipo:</b> {item.tipoEntrou}</span>
                                <br></br>
                                <span><b>Saiu:</b> {item.numSerieSaiu} <b>Tipo:</b> {item.tipoSaiu}</span>
                            </div>
                        )
                    })
                    }

                </div>
            </div>
            <div className="row no-printme">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-primary pull-right" onClick={() => onImprimir()}>Imprimir</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}