import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Tabs, TabContent, toast } from '../';
import { RegionalForm } from './RegionalForm';

export function Regional(props) {
    const endPoint = 'portal/crud/regional/';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ filtro: '' });

    const [params, setParams] = useState({
        rowId: props.match.params.id ? parseInt(props.match.params.id) : -1,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = 'nome::contains::';

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                setData(res.data.data);
            });
    }

    const onLoad = () => {
        config.validarPermissao('MenuEstoqueMaterial');
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
        fetchData(`${name}::contains::${value}`);
    }

    const handleNew = () => {
        setParams({
            rowId: 0,
            pageSize: 10,
            totalPages: 0,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });

        props.history.push({
            pathname: '/estoque/regional/0'
        });
    }

    const handleRefresh = () => {
        setFilter({ ...filter, nome: '' });
        fetchData();
    }
    const handleClose = () => {
        setParams({ ...params, rowId: -1 });
        props.history.push({
            pathname: '/estoque/regional/'
        });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ? "))
            return;

        apiHelper.api_delete(`${endPoint}/${id}/pkRegional`)
            .then(res => {
                config.httpResult(res.data);
                handleClose();
            }).catch(error => {
                toast.error('Atenção, não foi possível excluir o registro.');
            });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Regional" browserTitle="Regional">
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary btn-block margin-bottom" onClick={handleNew}>Nova Regional</button>
                    <div className="box box-solid">
                        <div className="box-body">
                            <div className="form-group">
                                <label htmlFor="nome">Filtro</label>
                                <input id="nome" type="text" className="form-control" placeholder="Filtro" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-default pull-right" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>

                <div className="col-md-9">
                    {params.rowId === -1 &&
                        <div className="box box-primary">
                            <div className="box-header with-border"><h3 className="box-title">Regionais</h3></div>
                            <div className="box-body no-padding">
                                <div className="table-responsive mailbox-messages">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Gerente</th>
                                                <th>Supervisor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td><Link to={'/estoque/regional/' + item.pkRegional}>{item.nome}</Link></td>
                                                            <td><Link to={'/estoque/regional/' + item.pkRegional}>{item.gerenteRegional}</Link></td>
                                                            <td><Link to={'/estoque/regional/' + item.pkRegional}>{item.supervisorRegional}</Link></td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="box-footer">
                                <div className="mailbox-controls">
                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {params.rowId > 0 &&
                        <div className="box box-primary">
                            <div className="box-header"><h3 className="box-title">Regional</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={() => handleDelete(params.rowId)}><i className="fa fa-trash"></i></button>
                                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times"></i></button>
                                </div>
                            </div>

                            <div className="box-body">
                                <Tabs id="user2" defaultActiveKey="user2">
                                    <TabContent title="Edição" eventKey="user2">
                                    <RegionalForm parentId={params.rowId} onHide={handleClose} />
                                    </TabContent>
                                </Tabs>
                            </div>
                        </div>
                    }

                    {params.rowId === 0 &&
                        <div className="box box-primary">
                            <div className="box-header"><h3 className="box-title">Nova Regional</h3>
                                <div className="box-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="box-body">
                            <RegionalForm parentId={params.rowId} onHide={handleClose} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Content>
    )
}
