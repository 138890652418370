import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select } from '../index';
import { createHashHistory } from 'history'
import { AgrupamentoFuncaoForm } from './AgrupamentoFuncaoForm';
export const history = createHashHistory();

export function AberturaVagasForm(props) {
    const endPoint = "portal/crud/rhVagaEmprego/";
    const endPointVagaEmprego = "portal/crud/uvw_v2_VagasEmprego";
    const endPointCargo = "portal/crud/uvw_v2_rhCargo";
    const rowId = props.parentId;
    const [data, setData] = useState({});
    const [dataCargo, setDataCargo] = useState({});
    let usuarioLogadoId = localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const [params, setParam] = useState({
        refresh: false
    });

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPointVagaEmprego}?filter=id::equal::${rowId}`).then(res => {
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                obterInformacoesCargo(obj[0].idCargo)
                setData(obj[0]);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    const obterInformacoesCargo = (idCargo) => {
        let obj = {};

        apiHelper.get(`${endPointCargo}?filter=idCargo::equal::${idCargo}`).then(res => {
            let data = res.data.data[0];
            if (res.data.data === undefined)
                return;

            Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
            setDataCargo(obj);
        }).catch(error => {
            toast.error('Houve um erro ao carregar o registro.');
        });

    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'idCargo' && value !== '') {
            obterInformacoesCargo(value);
        }
        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let obj = {
            hasAssistenciaMedica: data.hasAssistenciaMedica,
            hasAssistenciaOdontologica: data.hasAssistenciaOdontologica,
            fkMotivoAdmissao: data.fkMotivoAdmissao,
            sexo: data.sexo,
            observacao: data.observacao,
            fkSetor: data.fkSetor,
            fkCargo: data.idCargo,
            fkHorarioTrabalho: data.fkHorarioTrabalho,
            fkLocalTrabalho: data.fkLocalTrabalho,
            tempoExperiencia: data.tempoExperiencia,
            fkUsuarioResponsavel: usuarioLogadoId,
            isExcluido: false,
            isOrigemFuncionarioExistente: false,
            isReaberta: false,
            fkStatus: data.fkStatus
        }

        if (rowId === 0) {
            obj.guid = "";
            obj.dataCadastro = new Date();
            apiHelper.post(`${endPoint}/pkVagaEmprego`, obj)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkVagaEmprego`, obj)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleUpdate = () => {
        fetchData();
        setParam({ ...params, refresh: !params.refresh });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-6">
                    <label>Assistência Médica *</label>
                    <Select id="hasAssistenciaMedica" className="form-control" onChange={handleChange} value={data.hasAssistenciaMedica} required>
                        <option value="">Selecione</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Assistência Odontológica *</label>
                    <Select id="hasAssistenciaOdontologica" className="form-control" onChange={handleChange} value={data.hasAssistenciaOdontologica} required>
                        <option value="">Selecione</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Motivo da Admissão *</label>
                    <Select id="fkMotivoAdmissao" className="form-control" onChange={handleChange} value={data.fkMotivoAdmissao} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhMotivoAdmissao" valuefield="pkMotivoAdmissao" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Sexo *</label>
                    <Select id="sexo" className="form-control" onChange={handleChange} value={data.sexo} required>
                        <option value="">Selecione</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                        <option value="0">Sem Restrição</option>
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <label>Observação</label>
                    <textarea id="observacao" className="form-control" rows="3" maxLength="200" onChange={handleChange} value={data.observacao} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Setor *</label>
                    <Select id="fkSetor" className="form-control" onChange={handleChange} value={data.fkSetor} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhSetor" valuefield="pkSetor" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Cargo *</label>
                    <Select id="idCargo" className="form-control" onChange={handleChange} value={data.idCargo} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/rhCargo" valuefield="pkCargo" textfield="nome" />
                    </Select>
                </div>
                {(data.idCargo !== undefined && data.idCargo !== '') &&
                    <React.Fragment>
                        <div className="form-group col-sm-6">
                            <label>Horário de Trabalho</label>
                            <Select id="fkHorarioTrabalho" className="form-control" onChange={handleChange} value={data.fkHorarioTrabalho}>
                                <option value="">Selecione</option>
                                <option endpoint="portal/crud/rhHorarioTrabalho" valuefield="pkHorarioTrabalho" textfield="horario" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Local de Trabalho</label>
                            <Select id="fkLocalTrabalho" className="form-control" onChange={handleChange} value={data.fkLocalTrabalho}>
                                <option value="">Selecione</option>
                                <option endpoint="portal/crud/rhLocalTrabalho" valuefield="pkLocalTrabalho" textfield="nome" />
                            </Select>
                        </div>
                        <div className="form-group col-sm-12">
                            <label>Perfil: <span className="text-muted">{dataCargo.perfil}</span></label>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Regime de Contratação: <span className="text-muted">{dataCargo.regimeContratacao}</span></label>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Escolaridade: <span className="text-muted">{dataCargo.escolaridade}</span></label>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Idiomas: <span className="text-muted">{dataCargo.idioma}</span></label>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Informática: <span className="text-muted">{dataCargo.informatica}</span></label>
                        </div>

                        <div className="form-group col-sm-6">
                            <label>Outros Cursos: <span className="text-muted">{dataCargo.outrosCursos}</span></label>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Salário: <span className="text-muted">{dataCargo.salario}</span></label>
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Centro de Custo: <span className="text-muted">{dataCargo.centroDeCusto}</span></label>
                        </div>
                    </React.Fragment>}
                <div className="form-group col-sm-12">
                    <label>Usuário Responsável: <span className="text-muted">{data.usuarioResponsavel}</span></label>
                </div>
                <div className="form-group col-sm-12">
                    <label>Aprovado por: <span className="text-muted">{data.usuarioAprovador}</span></label>
                </div>

                <div className="form-group col-sm-12">
                    <label>Abertura da Vaga Aprovada por: <span className="text-muted">{data.usuarioAprovadorAbertura}</span></label>
                </div>
                <div className="form-group col-sm-12">
                    <label>Responsável Preenchimento: <span className="text-muted">{data.usuarioPreenchimento}</span></label>
                </div>
                <div className="form-group col-sm-6">
                    <label>Tempo de Experiência (anos)</label>
                    <Select id="tempoExperiencia" className="form-control" onChange={handleChange} value={data.tempoExperiencia} required>
                        <option value="">Selecione</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Status da Vaga</label>
                    <Select id="fkStatus" className="form-control" onChange={handleChange} value={data.fkStatus} disabled={(data.funcionario !== null  && data.funcionario !== undefined)} >
                        <option value="">Selecione</option>
                        <option endpoint={`funcionario/vaga/status/${rowId}/${data.funcionario}`} valuefield="pkStatusVagaEmprego" textfield="nome" />
                    </Select>
                </div>
                <AgrupamentoFuncaoForm parentId={data.idCargo} onUpdate={handleUpdate} refresh={params.refresh} />
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
