import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function CustoForm(props) {
    const endPoint = 'v2/projeto/custo';
    const projetoId = props.projetoId;
    const orcamentoId = props.orcamentoId;
    const custoId = props.custoId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {};

        if (custoId && custoId !== '') {
            apiHelper.get(`${endPoint}/${custoId}`)
                .then(res => {
                    let data = res.data.data;
                    Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                    setData(obj);

                }).catch(error => {
                    toast.error('');
                });
        }
    }

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        data.criadorId = '[user_id]';
        data.projetoId = projetoId;

        if (custoId === '') {
            apiHelper.post(endPoint, data)
                .then(res => {
                    if (res.data.code === 200) {
                        toast.success('Dados salvos com sucesso!');
                        props.onHide(true);
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${custoId}`, data).then(res => {
                config.httpResult(res.data);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-8">
                    <label>Tipo</label>
                    <Select id="tipo" className="form-control input-sm" onChange={handleChange} value={data.tipo || ''} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/PortalMetadata?filter=Categoria::equal::TipoProvisionamento`} valuefield="valor" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-md-4">
                    <label>Valor</label>
                    <input type="number" step=".01" id="valor" className="form-control input-sm" onChange={handleChange} value={data.valor || ''} required />
                </div>

                {data.tipo === 'Prestador' &&
                    <div className="form-group col-md-12">
                        <label>Prestador</label>
                        <Select id="prestadorId" className="form-control input-sm" onChange={handleChange} value={data.prestadorId || ''} required={data.tipo === 'Prestador'}>
                            <option value="">Selecione</option>
                            <option endpoint={`portal/crud/Prestador`} valuefield="pkPrestador" textfield="nome" />
                        </Select>
                    </div>
                }
                <div className="form-group col-md-6">
                    <label>Site</label>
                    <Select id="siteId" className="form-control input-sm" onChange={handleChange} value={data.siteId || ''} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/uvw_v2_comercial_orcamento_site?filter=OrcamentoId::equal::${orcamentoId}`} valuefield="id" textfield="nome" />
                    </Select>
                </div>
                {data.siteId && data.siteId !== '' && data.tipo !== 'frete' &&
                    <div className="form-group col-md-6">
                        <label>Item do Escopo</label>
                        <Select id="orcamentoItemId" className="form-control input-sm" onChange={handleChange} value={data.orcamentoItemId || ''} required={data.tipo !== 'frete'}>
                            <option value="">Selecione</option>
                            <option endpoint={`portal/crud/ComercialOrcamentoItem?filter=OrcamentoId::equal::${orcamentoId};;AND;;siteId::equal::${data.siteId};;AND;;Classificacao::equal::Serviço`} valuefield="id" textfield="nome" />
                        </Select>
                    </div>}
                <div className="form-group col-md-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control input-sm" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao || ''} required />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    );
}

