import React, { Component } from 'react';

const siteOsNova = process.env.PUBLIC_URL + '/img/tracker/site/siteOsNova.png';
const siteOsEmAndamentoDefault = process.env.PUBLIC_URL + '/img/tracker/site/siteOsEmAndamentoDefault.png';
const siteOsProximoEstouroSLA = process.env.PUBLIC_URL + '/img/tracker/site/siteOsProximoEstouroSLA_2.png';
const sitesSLAEstourado = process.env.PUBLIC_URL + '/img/tracker/site/sitesSLAEstourado.png';
const siteSemOs = process.env.PUBLIC_URL + '/img/tracker/site/siteSemOs.png';

const tecnicoStandBy_2 = process.env.PUBLIC_URL + '/img/tracker/tecnico/tecnicoStandBy_2.png';
const tecnicoAtendimento_2 = process.env.PUBLIC_URL + '/img/tracker/tecnico/tecnicoAtendimento_2.png';

var markers = [];
var sitesMarkers = [];

export default class TrackerMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            apiEndPoint: localStorage.getItem('apiUrl') + 'tracker/Gps',
            location: [],
            listOsSite: [],
            sites: [],
            map: null
        };

        this.onScriptLoad = this.onScriptLoad.bind(this);
        this.getCoordenadas = this.getCoordenadas.bind(this);
        this.addMarker = this.addMarker.bind(this);
        this.addMarkerSites = this.addMarkerSites.bind(this);
        this.getListOs = this.getListOs.bind(this);
    }

    componentDidMount() {
        if (!window.google) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWUTcoi03HTo3z7Chlfdy4OtdWoyk_w-E';

            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            // Below is important. 
            //We cannot access google.maps until it's finished loading
            s.addEventListener('load', e => {
                this.onScriptLoad();
            });
        } else {
            this.onScriptLoad();
        }

        this.interval = setInterval(() => {
            this.getCoordenadas(this.state.map);
        }, 120000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onScriptLoad() {
        var map = new window.google.maps.Map(document.getElementById(this.props.id), this.props.options);

        this.setState({ map: map });
        this.props.onMapLoad(map);
        this.getCoordenadas(map);
    }

    getCoordenadas(map) {
        fetch(this.state.apiEndPoint, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('id_token')
            }
        }).then(
            response => response.json()
        ).then(data => {

            var listOs = [];
            var k = 0;

            for (var i = 0; i < data.sites.length; i++) {

                for (var j = 0; j < data.sites[i].serviceOrders.length; j++) {
                    listOs[k] = data.sites[i].serviceOrders[j];
                    k++;
                }
            }

            this.setState({ location: data.gps, sites: data.sites, listOsSite: listOs });

            this.clearMarkers();
            this.clearSitesMarker();
            markers = [];
            sitesMarkers = [];

            this.addMarker(map);
            this.addMarkerSites(map);
        });
    }

    getListOs(idSite) {
        for (var i = 0; i < this.state.sites.length; i++) {

            if (this.state.sites[i].id === idSite) {

                this.setState({ listOsSite: this.state.sites[i].serviceOrders });
                break;
            }
        }
    }

    addMarker(map) {
        var InforObj = [];

        for (var i = 0; i < this.state.location.length; i++) {

            var content = '<div><h2 id="firstHeading" class="firstHeading">VEOTEX</h2>' +
                '<div id="bodyContent"><p><b>Colaborador: </b> ' + this.state.location[i].account + '</div> </div> ';

            var lat = parseFloat(this.state.location[i].latitude.replace("\"", ""));
            var lgn = parseFloat(this.state.location[i].longitude.replace("\"", ""));

            var imageColaborador = [tecnicoStandBy_2, tecnicoAtendimento_2];
            var isAcionamentoTecnico = this.state.location[i].isAcionamentoTecnico === false ? 0 : 1;

            var marker = new window.google.maps.Marker({
                position: { lat: lat, lng: lgn },
                map: map,
                title: this.state.location[i].account,
                icon: imageColaborador[isAcionamentoTecnico]
            });

            var infowindow = new window.google.maps.InfoWindow({
                content: content,
                visible: true,
                maxWidth: 200
            });

            //marker.addListener('click', function () {
            //    infowindow.open(map, marker);
            //});

            new window.google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
                return function () {
                    if (InforObj.length > 0) {
                        InforObj[0].set("marker", null);
                        InforObj[0].close();
                        InforObj.length = 0;
                    }

                    infowindow.setContent(content);
                    infowindow.open(map, marker);
                    InforObj[0] = infowindow;
                    //new window.google.maps.event.addListener(map, 'click', function () {
                    //    infowindow.close();
                    //});
                };
            })(marker, content, infowindow));

            markers.push(marker);
        }
    }

    addMarkerSites(map) {
        var InforObj = [];

        for (var i = 0; i < this.state.sites.length; i++) {

            var content = '<div><div><b>Site </b> ' + this.state.sites[i].name + '</br>';
            content += '<b><h3 style="text-align:center">OS</h3></b></br>';

            for (var j = 0; j < this.state.sites[i].serviceOrders.length; j++) {
                content += '<b>OS</b> ' + this.state.sites[i].serviceOrders[j].id + '</br>';
                content += '<b>Status</b> ' + this.state.sites[i].serviceOrders[j].statusOSFormat + '</br>';
                content += '<b>Data</b> ' + this.state.sites[i].serviceOrders[j].dateCreatedOsFormat + '</br>';
                content += '<b>Tempo decorrido</b> ' + this.state.sites[i].serviceOrders[j].timeOS + '</br>';

                content += '<hr>';
            }

            content += '</div></div> ';

            var lat = parseFloat(this.state.sites[i].latitude.replace("\"", ""));
            var lgn = parseFloat(this.state.sites[i].longitude.replace("\"", ""));

            var imageSite = [siteSemOs, siteOsNova, siteOsEmAndamentoDefault, siteOsProximoEstouroSLA, sitesSLAEstourado];

            var marker = new window.google.maps.Marker({
                position: { lat: lat, lng: lgn },
                map: map,
                title: this.state.sites[i].name,
                //draggable: false,
                //animation: new window.google.maps.Animation.DROP,
                icon: imageSite[this.state.sites[i].slaOS]
            });

            var infowindow = new window.google.maps.InfoWindow({
                content: content,
                visible: true,
                maxWidth: 200
            });

            //var idSite = this.state.sites[i].id;
            //marker.addListener('click', this.getListOs(idSite));

            new window.google.maps.event.addListener(marker, 'click', (
                function (marker, content, infowindow) {
                    return function () {
                        //this.getListOs(idSite); exibe no grid abaixo do map apenas as 'OS' do site
                        if (InforObj.length > 0) {
                            InforObj[0].set("marker", null);
                            InforObj[0].close();
                            InforObj.length = 0;
                        }

                        infowindow.setContent(content);
                        infowindow.open(map, marker);
                        InforObj[0] = infowindow;
                    };
                }
            )(marker, content, infowindow));

            sitesMarkers.push(marker);
        }
    }

    setMapOnAll(map) {
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    clearMarkers() {
        this.setMapOnAll(null);
    }

    clearSitesMarker() {
        for (var i = 0; i < sitesMarkers.length; i++) {
            sitesMarkers[i].setMap(null);
        }
    }

    render() {
        return (
            <div id={this.props.id} style={{ height: '500px' }} />
        );
    }
}
