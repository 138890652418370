import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, fileHelper, Modal, toast } from '../index';
import { AcidenteForm } from './AcidenteForm';

export function Acidente(props) {
    const endPoint = "v2/os/acidente";
    const idOS = props.parentId;

    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        showModal: false,
        cancelarAcionamento: false,
        rowId: 0,
        interacao: false,
        idAcionamentoTecnico: 0,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        try {
            apiHelper.get(`${endPoint}?pkOS=${idOS}&order=pkOS DESC`)
                .then(res => {
                    setData(res.data.data);
                });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    };

    useEffect(fetchData, [idOS, props.refresh]);

    const handleNew = () => {
        setParam({ ...params, showModal: true, modalTitle: 'Novo Registro', formName: 'new' });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}/`).then(res => {
            config.httpResult(res);
            fetchData('');
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const downloadAnexo = (fileName) => {
        fileHelper.download('OsAnexo', fileName).then(res => { });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false });
        fetchData();
        props.onHide();
    }

    const handleShowForm = (name, title, rowId) => {

        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === 'new' && <AcidenteForm idOS={idOS} parentId={0} onHide={handleHide} />}
                    {params.formName === 'edit' && <AcidenteForm idOS={idOS} parentId={params.rowId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>

            <div style={{ marginBottom: "7px" }}>
                {(config.validarClaim('atendimento.interacao.criar') && props.allowEdit) ? <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
                    : <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>}

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>#</th>
                            <th>Data Acidente</th>
                            <th>Usuário</th>
                            <th>Descrição Acidente</th>
                            <th>Anexo APR</th>
                            <th>Anexo Fluxograma</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ width: '50px' }}>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" />
                                                </button>
                                                <ul className="dropdown-menu" role="menu">
                                                    <li><Link replace to="#" onClick={() => handleShowForm("edit", "Editar Registro", item.id)}><i className="fa fa-pencil" /> Editar</Link></li>
                                                    <li><Link replace to="#" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /> Excluir</Link></li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>{config.dateFix(item.dataOcorrencia)}</td>
                                        <td>{item.usuario}</td>
                                        <td>{item.descricaoAcidente}</td>
                                        <td>{item.anexoAPR ? <Link to="#" onClick={() => downloadAnexo(item.anexoAPR)}>{item.anexoAPR}</Link> : "-"}</td>
                                        <td>{item.anexoFluxograma ? <Link to="#" onClick={() => downloadAnexo(item.anexoFluxograma)}>{item.anexoFluxograma}</Link> : "-"}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
        </React.Fragment>
    );
}
