import React, { Component } from 'react';
import {
    uuidv4, Table, Button, FormGroupButton, Modals, FasIcon
} from '../../components/adminlte/AdminLTE';
import { toast } from 'react-toastify';

export class GroupDevice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            table: [],
            tableSearch: '',
            devices: [],
            keyValue: '',
            deviceSearch: '',
            showForm: false
        };

        this.tableSearch = this.tableSearch.bind(this);
        this.deviceSearch = this.deviceSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ keyValue: newProps.keyValue });
        this.getData(newProps.keyValue);
    }

    getData(key, name) {

        const { apiUrl, token } = this.props.config;

        if (name === undefined)
            name = '';

        fetch(apiUrl + key + '/device/?name=' + name, {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ table: data });
        });
    }

    tableSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ tableSearch: value });
        this.getData(this.props.keyValue, value);
    }

    getDevice(name) {
        const { apiServer, token } = this.props.config;

        if (name === '') name = null;

        fetch(apiServer + 'vms/device/?name=' + name, {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ devices: data });
        });
    }

    deviceSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ deviceSearch: value });

        if (value.length > 2 || value === '')
            this.getDevice(value);
    }

    addDevice(e) {
        const { apiUrl, token } = this.props.config;

        let device = {
            serialNumber: e.serialNumber,
            groupId: this.props.keyValue
        };

        this.setState({ isSaving: true });

        fetch(apiUrl + 'device', {
            method: 'POST',
            body: JSON.stringify(device),
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200)
                toast.success('Dispositivo adicionado com sucesso!');
            else
                toast.warn(response[1]["msg"]);

            this.setState({ isSaving: false });
        }).catch(error => {
            toast.error(error);
            this.setState({ isSaving: false });
        });
    }

    handleDelete(e) {
        const { config } = this.props;

        if (e) {
            console.log(config.apiUrl + this.state.keyValue + '/device/' + e.serialNumber);
            fetch(config.apiUrl + this.state.keyValue + '/device/' + e.serialNumber, {
                method: 'DELETE',
                headers: { 'Authorization': config.token }
            }).then(response => {
                return Promise.all([response.status, response.json()]);
            }).then((response) => {
                if (response[0] === 200) {
                    toast.success('Dados excluidos com sucesso!');
                    this.getData(this.state.keyValue);
                }
                else
                    toast.warn(response[1]["msg"]);
            }).catch(error => {
                toast.error(error);
            });
        }

        this.setState({ showConfirm: false });
    }

    onHide() {
        this.setState({ showForm: false, deviceSearch: '' });
        this.getDevice('');
        this.getData(this.props.keyValue, '');
    }

    render() {
        const { isSaving } = this.state;

        return (
            <React.Fragment>
                <FormGroupButton size="sm" placeholder="Buscar dispositivos"
                    onChange={this.tableSearch} value={this.state.tableSearch}
                    buttonLeft={<Button bsStyle="success" onClick={() => this.setState({ showForm: true })}><FasIcon name="plus" /></Button>}
                    buttonRight={<Button text="Limpar" onClick={() => this.tableSearch(null)}><FasIcon name="refresh" /></Button>}
                />

                <Table striped bordered hover>
                    <tbody>
                        {this.state.table.map(row => {
                            return (
                                <tr key={uuidv4()}>
                                    <td>{row.name + ' | ' + row.serialNumber}</td>
                                    <td style={{ width: "40px" }}><Button bsSize="xs" bsStyle="danger" disabled={isSaving} onClick={() => this.handleDelete(row)}><FasIcon name="trash" /></Button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Modals title="Adicionar Dispositivos"
                    show={this.state.showForm}
                    onHide={() => this.onHide()}
                    footer={<Button className="pull-right" bsStyle="default" onClick={() => this.onHide()}>Fechar</Button>}
                >
                    <div style={{ minHeight: "200px" }}>
                        <FormGroupButton size="sm" placeholder="Buscar dispositivos"
                            onChange={this.deviceSearch} value={this.state.deviceSearch}
                            buttonRight={<Button text="Limpar" onClick={() => this.deviceSearch(null)}>LIMPAR</Button>}
                        />

                        <Table striped bordered hover>
                            <tbody>
                                {this.state.devices.map(row => {
                                    return (
                                        <tr key={uuidv4()}>
                                            <td>{row.name}</td>
                                            <td style={{ width: "50px" }}>
                                                <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.addDevice(row)}>
                                                    {isSaving ? 'Adicionando...' : 'Adicionar'}
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Modals>
            </React.Fragment >
        );
    }
}


