import React, { useState, useEffect } from 'react';
import { Button, config, apiHelper, toast } from '../index';

export function ArmazemProtheusForm(props) {
    const endPoint = "portal/crud/localarmazenamento/";
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/${rowId}/pklocalarmazenamento`)
                .then(res => {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        } else {
            setData({ ...data, nome: '', armazemProtheus: '' });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let form = {
            nome: data.nome,
            armazemProtheus: data.armazemProtheus,
            contato: "",
            dataCadastro: "",
            dddTelefone: "",
            telefone: "",
            dddTelefone2: "",
            telefone2: "",
            logradouro: "",
            bairro: "",
            cep: "",
            fkUf: 19,
            fkCidade: 3691,
            numero: 0,
            fksite: 0,
            fkfornecedor: 0
        }


        if (rowId === 0) {
            apiHelper.post(endPoint, form).then(res => {
                config.httpResult(res.data);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pklocalarmazenamento`, form).then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Codigo Armazem Protheus </label>
                        <input id="armazemProtheus" name="armazemProtheus" type="number" maxLength={6} className="form-control" onChange={handleChange} value={data.armazemProtheus} placeholder="(Código Filial + Local)" required disabled={rowId} />
                    </div>
                    <div className="form-group">
                        <label>Nome</label>
                        <input id="nome" name="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} placeholder="Nome" required />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="box-tool pull-right">
                        <div className="btn-toolbar">
                            <Button type="submit" bsStyle="success">Salvar</Button>
                            {rowId > 0 && <Button bsStyle="default" onClick={fetchData}>Cancelar</Button>}
                            <Button bsStyle="default" onClick={() => props.onHide()}>Fechar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
