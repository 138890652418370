import React from 'react';
import { GraficoIndicadores } from './GraficoIndicadores';

export function OrdemServicoDashboard(props) {
    return (
        <div className="row">
            <div className="col-md-12">
                <GraficoIndicadores />
            </div>
        </div>
    )
}
