import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function Endereco(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.parentId;
    const tipoEndereco = props.tipoEndereco;
    const [data, setData] = useState({});
    const [uf, setUf] = useState(0);

    const fetchData = () => {
        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/${rowId}/cliente`).then(res => {
                let data = res.data.data;
                let EnderecoCorrespondencia = {};
                let EnderecoFaturamento = {};


                EnderecoCorrespondencia = {
                    uf: data.enderecoCorrepondencia.uf,
                    cidade: data.enderecoCorrepondencia.cidade,
                    endereco: data.enderecoCorrepondencia.endereco,
                    cep: data.enderecoCorrepondencia.cep,
                    bairro: data.enderecoCorrepondencia.bairro,
                    logradouro: data.enderecoCorrepondencia.logradouro,
                    complemento: data.enderecoCorrepondencia.complemento,
                    numero: data.enderecoCorrepondencia.numero
                };

                EnderecoFaturamento = {
                    uf: data.enderecoFaturamento.uf,
                    cidade: data.enderecoFaturamento.cidade,
                    endereco: data.enderecoFaturamento.endereco,
                    cep: data.enderecoFaturamento.cep,
                    bairro: data.enderecoFaturamento.bairro,
                    logradouro: data.enderecoFaturamento.logradouro,
                    complemento: data.enderecoFaturamento.complemento,
                    numero: data.enderecoFaturamento.numero
                };

                setUf(tipoEndereco === 'faturamento' ? EnderecoFaturamento.uf : EnderecoCorrespondencia.uf);

                let endereco = tipoEndereco === 'faturamento' ? EnderecoFaturamento : EnderecoCorrespondencia;
                setData(endereco);

            }).catch(error => {
                toast.error('Houve um erro ao carregar o endereço!');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "uf")
            setUf(value);

        if (name === 'nome' && data.tipoPessoa === 'Fisica') {
            setData({ ...data, razaoSocial: value, nome: value });
            return;
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.put(`${endPoint}/${rowId}/endereco?tipoEndereco=${tipoEndereco}`, data)
            .then(res => {
                config.httpResult(res);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    }

    const onHide = () => {
        props.onHide()
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Logradouro</label>
                    <input id="logradouro" type="text" className="form-control" onChange={handleChange} value={data.logradouro} />
                </div>
                <div className="form-group col-sm-2">
                    <label>Número</label>
                    <input id="numero" type="text" className="form-control" onChange={handleChange} value={data.numero} />
                </div>
                <div className="form-group col-sm-10">
                    <label>Complemento</label>
                    <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Cep</label>
                    <input id="cep" type="text" className="form-control" onChange={handleChange} value={data.cep} />
                </div>
                <div className="form-group col-sm-6">
                    <label>Bairro</label>
                    <input id="bairro" type="text" className="form-control" onChange={handleChange} value={data.bairro} />
                </div>
                <div className="form-group col-sm-6">
                    <label>UF</label>
                    <Select id="uf" className="form-control" onChange={handleChange} value={data.uf} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Cidade</label>
                    <Select id="cidade" className="form-control" onChange={handleChange} value={data.cidade || ''} refresh={uf} required>
                        {data.uf && <option endpoint={`portal/crud/uvw_cidade?filter=SIGLA::equal::${data.uf}`} valuefield="nome" textfield="nome" />}
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
