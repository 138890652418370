import React, { useState, useEffect } from 'react';
import { Link, config, apiHelper, toast } from '../';

export function Contato(props) {
    const endPoint = 'marketing/ema/contato';
    const parentId = props.parentId;
    const [form, setForm] = useState({});
    const [data, setData] = useState([]);

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}&order=id DESC`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);
                }
                else
                    toast.error('Atenção, não foi possível carregar os Registros');
            });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleAdicionar = () => {
        apiHelper.post(endPoint + parentId, data).then(res => {
            config.httpResult(res.data);
            onLoad();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData('');
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const onHide = () => {
        props.onHide()
    }

    return (
        <React.Fragment>
            <div className="row" style={{ marginBottom: '10px' }}>
                <div className="form-group col-md-12">
                    <label>Nome</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} />
                </div>
                <div className="form-group col-md-12">
                    <label>Email</label>
                    <input id="email" type="text" className="form-control" onChange={handleChange} />
                </div>

                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="button" onClick={() => handleAdicionar()} className="btn btn-primary pull-right">Adicionar</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.id}</td>
                                                <td>{item.nome}</td>
                                                <td>{item.email}</td>
                                                <td><Link to="#" onClick={() => handleDelete(item.id)}> <i className="fa fa-trash" /></Link></td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
