import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select, config } from '../index';

export function EquipamentoForm(props) {
    const endPoint = "comercial/site/";
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}/obterMaterial/${rowId}`).then(res => {
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o LEAD');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        apiHelper.post(endPoint + `/equipamento/${props.siteId}`, data)
            .then(res => {
                config.httpResult(res.data);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });

    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-4">
                    <label>ID</label>
                    <input id="identificador" type="text" className="form-control" onChange={handleChange} value={data.identificador} required />
                </div>
                <div className="form-group col-sm-8">
                    <label>Equipamento</label>
                    <Select id="equipamentoId" className="form-control" onChange={handleChange} value={data.equipamentoId || ''} required>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/equipamento/'} valuefield="id" textfield="nome" />
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Salvar</button>
                </div>
            </div>
        </form>
    )
}
