import React from 'react';

const TabTitle = ({ title, titleLeft, icon }) => {
    const hasIcon = !!icon;
    //const localIcon = hasIcon ? splitIcon(icon) : null;
    const hasTitle = !!title;
    const hasIconOrHeader = hasIcon || hasTitle;

    return hasIconOrHeader &&
        <li className={`header${titleLeft ? ' pull-left' : ''}`}>
            {/*hasIcon && <FontAwesomeIcon icon={localIcon} />*/}
            {hasTitle ? ` ${title}` : ''}
        </li>;
};

TabTitle.defaultProps = {
    icon: undefined,
    title: undefined,
    titleLeft: false
};

export default TabTitle;