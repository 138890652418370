import React, { useState } from 'react';
import { apiHelper, toast, config } from '../index';

export function OrcamentoMotivoReprovacaoForm(props) {
    const parentId = props.parentId;
    const motivo = props.motivo;
    const [data, setData] = useState({
        motivoReprovacao: '',
        anexo: '',
        file: ''
    });

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                apiHelper.toBase64(file)
                    .then((base) => {
                        setData({ ...data, file: base, anexo: file.name });
                    });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const obj = {
            id: parentId,
            observacao: data.motivoReprovacao,
            anexo: data.anexo,
            file: data.file,
            userId: '[user_id]',
            acao: motivo
        };
        data.parentId = parentId;
        const url = `projetocliente/status/`;
        apiHelper.put(url, obj)
            .then(res => {
                config.httpResult(res.data);
                props.onUpdate();
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível atualizar o status');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group col-sm-12">
                <label>{motivo === 'aprovadoCliente' ? 'Motivo Aprovação *' : 'Motivo Reprovação *'}</label>
                <textarea id="motivoReprovacao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.motivoReprovacao} required />
            </div>
            <div className="form-group col-md-12">
                <label>Anexo</label>
                <input type="file" id="file" onChange={handleChange} />
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
