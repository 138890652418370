import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select } from '../index';

export function ManutencaoExecutadaForm(props) {
    const endPoint = "portal/crud/manutencaoExecutada";
    const endPointV2 = "portal/crud/uvw_v2_ManutencaoExecutada";
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPointV2}?filter=pkManutencaoExecutada::equal::${rowId}`).then(res => {
                
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj[0]);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let manutencao = {
            nome: data.nome,
            descricao: data.descricao,
            fkSubGrupo: data.fkSubGrupo,
            isExcluido: false  
        }
        if (rowId === 0) {
            manutencao.dataCadastro= new Date();
            apiHelper.post(`${endPoint}`, manutencao)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        props.onHide(true);
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkManutencaoExecutada`, manutencao)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Grupo *</label>
                    <Select id="fkGrupo" className="form-control" onChange={handleChange} value={data.fkGrupo} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/grupo" valuefield="pkGrupo" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-6">
                    <label>Sub Grupo *</label>
                    <Select id="fkSubGrupo" className="form-control" onChange={handleChange} value={data.fkSubGrupo} refresh={data.fkGrupo} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/subGrupo?filter=fkGrupo::equal::${data.fkGrupo}`} valuefield="pkSubGrupo" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="500" onChange={handleChange} value={data.descricao} required />
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
