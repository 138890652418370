import React, { Component } from 'react';
import {
    Box, Button, ButtonToolbar, Col,
    Content, FieldGroup, Row, Table
} from '../../components/adminlte/AdminLTE';

import { OrdemServicoItens } from './OrdemServicoItens';
import { OrdemServicoConcluir } from './OrdemServicoConcluir';
import { AdicionarAcionamento } from './AdicionarAcionamento';
import { OrdemServicoAdicionarInteracao } from './OrdemServicoAdicionarInteracao';
import { OrdemServicoForm } from './OrdemServicoForm';
import { OrdemServicoReabrir } from './OrdemServicoReabrir';
import { SolicitarMaterial } from './SolicitarMaterial';
import { OrdemServicoFinalizar } from './OrdemServicoFinalizar';
import { OrdemServicoMotivoCancelarMaterial } from './OrdemServicoMotivoCancelarMaterial';

import { Global } from '../Core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';
import { Link } from "react-router-dom";

const config = {
    keyName: 'title',
    rowName: 'title',
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiUrl: localStorage.getItem('apiUrl'),
    apiEndPoint: localStorage.getItem('apiUrl') + 'siga/serviceOrder/',
    claimsUsuarioLogado: localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.getItem('claims').split(',') : '',
};

let pageNumbers = [];

export class ServiceOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataTable: [],
            dataTablePagination: [],
            dataTableHistorico: [],
            registroPorPagina: 30,
            Tecnicos: [],
            Clientes: [],
            selectedRow: {},
            searchValue: '',
            searchStatus: 0,
            searchValueTecnico: '',
            searchValueCliente: '',
            searchValueNivel: '',
            concluirOrdemServico: false,
            criarAcionamento: false,
            criarInteracao: false,
            criarOrdemServico: false,
            ordemServicoItens: false,
            reabrirOrdemServico: false,
            finalizarOrdemServico: false,
            solicitarMaterial: false,
            statusText: '',
            showMotivoCancelarMaterialForm: false,
            dataTableMaterial: [],
            userLogged: [],
            aprovadorMaterial: false,
            selectedRowMaterialSolicitacao: {},
            showTodosCamposFiltro: false,
            showInteracaoButton: false,
            showAcionamentoButton: false,
            showConcluirButton: false,
            showFinalizarOSButton: false,
            showAbaInteracao: false,
            showAbaMaterial: false,
            showSolicitarMaterialButton: false,
            showReabrirOSButton: false,
            showLoading: false,
            buscarDados: true
        };

        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    UNSAFE_componentWillMount() {
        var idOs = window.location.hash.split('/')[2];

        this.getClientes();
        this.getTecnicos();
        idOs !== undefined ? this.getDataTable(idOs, this.state.searchValueTecnico, this.state.searchValueCliente, this.state.searchValueNivel, 0) : this.getDataTable('', this.state.searchValueTecnico, this.state.searchValueCliente, this.state.searchValueNivel, 2);
        this.ConfigurarTelaDeAcordoComPerfilLogado();
        this.VerificarSePodeAprovarMaterial();
        this.setState({ ordemServicoItens: true });
    }

    getClientes() {
        let active = true;
        let url = `${config.apiEndPoint}client/` + active;

        fetch(url, { headers: { 'Authorization': config.token } })
            .then(
                response => response.json()
            ).then(data => {

                this.setState({
                    Clientes: data
                });
            });
    }

    getTecnicos() {
        let url = `${config.apiEndPoint}analist`;

        fetch(url, { headers: { 'Authorization': config.token } })
            .then(
                response => response.json()
            ).then(data => {
                this.setState({
                    Tecnicos: data
                });
            });
    }

    getOSBySite(siteId) {
        let url = `${config.apiEndPoint}site/` + siteId + '/os';

        fetch(url, { headers: { 'Authorization': config.token } })
            .then(
                response => response.json()
            ).then(data => {
                this.setState({
                    dataTableHistorico: data
                });
            });
    }

    getDataTable(searchValue, searchValueTecnico, searchValueCliente, searchValueNivel, status) {

        if (this.state.buscarDados) {
            this.setState({
                showFinalizarOSButton: false, showReabrirOSButton: false, showConcluirButton: false,
                showAcionamentoButton: false, showInteracaoButton: false, showLoading: true, buscarDados: false
            });

            pageNumbers = [];
            searchValue = searchValue.replace("/", "");

            let url = `${config.apiEndPoint}attendance?name=${searchValue}&tecnico=${searchValueTecnico}&cliente=${searchValueCliente}&nivel=${searchValueNivel}&status=${status}`;
            fetch(url, { headers: { 'Authorization': config.token } })
                .then(
                    response => response.json()
                ).then(data => {
                    var dataPagination = data.slice(0, this.state.registroPorPagina);

                    this.setState({
                        dataTable: data,
                        dataTablePagination: dataPagination,
                        selectedRow: dataPagination.length > 0 ? dataPagination[0] : [],
                        statusText: dataPagination.length > 0 ? this.ObterDescricaoStatus(dataPagination[0]) : ''
                    });

                    if (data.length > 0) {
                        this.getOSBySite(data[0].siteId);
                        this.getMateriaisSolicitados(data[0].codeOS);

                        if (data[0].status === 4) {
                            this.setState({ showReabrirOSButton: true, showFinalizarOSButton: true, showInteracaoButton: true });
                        }
                        else if (data[0].status !== 5) {
                            this.setState({ showReabrirOSButton: false, showConcluirButton: true, showAcionamentoButton: true, showInteracaoButton: true });
                        }

                        for (let i = 1; i <= Math.ceil(data.length / this.state.registroPorPagina); i++) {
                            pageNumbers.push(i);
                        }
                    }

                    this.setState({ showLoading: false, buscarDados: true });

                }).catch(error => {

                    this.setState({ showLoading: false, buscarDados: false });
                });
        }
    }

    VerificarSePodeAprovarMaterial() {
        var isAprovadorMaterial = config.claimsUsuarioLogado.includes('AprovarMaterial');
        this.setState({ aprovadorMaterial: isAprovadorMaterial });
    }

    ConfigurarTelaDeAcordoComPerfilLogado() {
        if (config.claimsUsuarioLogado.includes('HD') || config.claimsUsuarioLogado.includes('SupervisorOperacional') || config.claimsUsuarioLogado.includes('Admin')) {
            this.LiberarTodosCamposBotoes();            
        }
        else if (config.claimsUsuarioLogado.includes('Prestador')) {
            this.setState({
                showTodosCamposFiltro: false,
                showInteracaoButton: true,
                showConcluirButton: true,
                showAbaInteracao: true,
                showAbaMaterial: true,
                showSolicitarMaterialButton: true,
                showAcionamentoButton: false
            });
        }
        else if (config.claimsUsuarioLogado.includes('Cliente')) {
            this.setState({
                showTodosCamposFiltro: false,
                showInteracaoButton: true,
                showConcluirButton: true,
                showAbaInteracao: true,
                showAcionamentoButton: false
            });
        }
    }

    LiberarTodosCamposBotoes() {
        this.setState({
            showTodosCamposFiltro: true,
            showInteracaoButton: true,
            showConcluirButton: true,
            showAbaInteracao: true,
            showAbaMaterial: true,
            showSolicitarMaterialButton: true,
            showAcionamentoButton: true
        });
    }

    getMateriaisSolicitados = (codeOs) => {
        let url = `${config.apiEndPoint}materialSolicitado?codigoOs=${codeOs}`;

        fetch(url, { headers: { 'Authorization': config.token } })
            .then(
                response => response.json()
            ).then(data => {

                this.setState({ dataTableMaterial: data });
                this.setState({ userLogged: data.userLogged });
            });
    }

    onChangeSearch(e) {
        let id = e.target.id;
        let value = e.target.value;
        this.setState({ [id]: value });
    }

    handleSearch() {
        var status = this.state.searchStatus;

        if (this.state.searchValue === '' && this.state.searchValueTecnico === '' &&
            this.state.searchValueCliente === '' && this.state.searchValueNivel === '' && this.state.searchStatus === 0)
            status = 2;

        this.getDataTable(this.state.searchValue, this.state.searchValueTecnico, this.state.searchValueCliente, this.state.searchValueNivel, status);
    }

    handleClear() {
        this.setState(prevState => {
            return {
                ...prevState, searchValue: '',
                searchStatus: 2,
                ordemServicoItens: true,
                searchValueTecnico: '',
                searchValueCliente: '',
                searchValueNivel: '',

            };
        });
    }

    handleRowSelect(row) {
        this.setState({ selectedRow: row, statusText: this.ObterDescricaoStatus(row) });
        this.getMateriaisSolicitados(row.codeOS);

        if (row.status === 4 || row.status === 5) {
            this.setState({ showReabrirOSButton: true, showConcluirButton: false, showAcionamentoButton: false, showInteracaoButton: false });
        } else {
            this.setState({ showReabrirOSButton: false, showConcluirButton: true, showAcionamentoButton: true, showInteracaoButton: true });
        }

        this.getOSBySite(row.siteId);
    }

    handleAprovarMaterial = (e) => {
        var data = {
            serviceOrderCode: e.serviceOrderCode,
            idSolicitacao: e.idSolicitacao,
            fkAprovacaoMaterial: e.fkAprovacaoMaterial,
            isPendente: false,
            isAprovado: true
        }

        fetch(config.apiEndPoint + "aprovarMaterial", {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {

                toast.success('Material aprovado com sucesso!');
                this.getMateriaisSolicitados(data.serviceOrderCode);
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    handleCancelarMaterial = (rowSelectedMaterial) => {
        this.setState({ showMotivoCancelarMaterialForm: true, rowSelectedMaterial: rowSelectedMaterial });
    }

    handleDesfazerAprovacao = (e) => {
        var data = {
            serviceOrderCode: e.serviceOrderCode,
            idSolicitacao: e.idSolicitacao,
            fkAprovacaoMaterial: e.fkAprovacaoMaterial,
            isPendente: true,
            isAprovado: false
        }

        fetch(config.apiEndPoint + "desfazerAcaoAprovacao", {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.getMateriaisSolicitados(data.serviceOrderCode);
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    ObterDescricaoStatus(e) {
        var tipo = '';

        if (e.status === 1)
            tipo = 'Nova';
        else if (e.status === 2)
            tipo = 'Em Andamento';
        else if (e.status === 3)
            tipo = 'Pendência do Cliente';
        else if (e.status === 4)
            tipo = 'Concluída';
        else if (e.status === 5)
            tipo = 'Finalizada';

        return tipo;
    }

    cancelarConclusaoOS() {
        this.setState({ showReabrirOSButton: false, showConcluirButton: true, showAcionamentoButton: true, showInteracaoButton: true, concluirOrdemServico: false });
    }

    cancelarReabrirOS() {
        this.setState({
            showReabrirOSButton: true, reabrirOrdemServico: false, showConcluirButton: false,
            showAcionamentoButton: false, showInteracaoButton: false, concluirOrdemServico: false
        });
    }

    concluirOS() {
        this.setState({
            showReabrirOSButton: true, reabrirOrdemServico: false, showConcluirButton: false,
            showAcionamentoButton: false, showInteracaoButton: false, concluirOrdemServico: false
        });
    }

    onReloadConclusaoOS() {
        this.setState({
            showReabrirOSButton: true, showConcluirButton: false, concluirOrdemServico: false,
            showAcionamentoButton: false, showInteracaoButton: false, statusText: 'Concluída'
        });
    }

    onReloadOSReaberta() {
        this.setState({
            showReabrirOSButton: false, showConcluirButton: true, concluirOrdemServico: false, reabrirOrdemServico: false,
            showAcionamentoButton: true, showInteracaoButton: true, statusText: 'Em Andamento'
        });
    }

    onReloadFinalizarOS() {
        this.setState({
            showReabrirOSButton: false, showConcluirButton: false, concluirOrdemServico: false,
            showFinalizarOSButton: false, reabrirOrdemServico: false, finalizarOrdemServico: false,
            showAcionamentoButton: false, showInteracaoButton: false, statusText: 'OS Finalizada'
        });
    }

    paginationNext(number) {
        let ultimoRegistro = number * this.state.registroPorPagina;
        let primeiroRegistro = ultimoRegistro - this.state.registroPorPagina;
        let registrosCorrente = this.state.dataTable.slice(number === 1 ? 1 : primeiroRegistro, ultimoRegistro);

        var selectedRow = registrosCorrente.length > 0 ? registrosCorrente[0] : [];
        this.setState({ dataTablePagination: registrosCorrente, selectedRow: selectedRow });
    }

    render() {
        const { dataTablePagination, selectedRow, showMotivoCancelarMaterialForm, dataTableMaterial, aprovadorMaterial, rowSelectedMaterial } = this.state;
        const { concluirOrdemServico, criarAcionamento, criarInteracao, criarOrdemServico, reabrirOrdemServico, finalizarOrdemServico, ordemServicoItens, solicitarMaterial,
            showAcionamentoButton, showInteracaoButton, showConcluirButton, showSolicitarMaterialButton, showFinalizarOSButton, showAbaInteracao, showAbaMaterial,
            showReabrirOSButton, dataTableHistorico } = this.state;

        var color = '';

        if (selectedRow === undefined)
            return;

        if (selectedRow.status === 4 || selectedRow.status === 5 || selectedRow.percentualAproximadoStatusOS <= 20)
            color = 'progress-bar progress-bar-success progress-bar-striped';
        else if (selectedRow.percentualAproximadoStatusOS >= 80)
            color = 'progress-bar progress-bar-danger progress-bar-striped';
        else if (selectedRow.percentualAproximadoStatusOS >= 60)
            color = 'progress-bar progress-bar-warning  progress-bar-striped';
        else if (selectedRow.percentualAproximadoStatusOS >= 40)
            color = 'progress-bar progress-bar-primary progress-bar-striped';

        var valueBar = Math.round(selectedRow.percentualAproximadoStatusOS);

        var nivelSLAAcionamento = '';

        if (selectedRow.nivelSLAAcionamentoTecnico)
            nivelSLAAcionamento = ' | Nivel SLA ' + selectedRow.nivelSLAAcionamentoTecnico;

        return (
            <Content title="Ordem de Serviço" browserTitle="Veotex Siga - Atendimento">
                <Row>
                    <Col md={4}>
                        <Box type="primary">
                            <Button className="btn-block" bsSize="sm" title="Nova OS" bsStyle="primary" onClick={() => this.setState({ criarOrdemServico: true })}>Nova OS</Button>
                            <br />
                            <FieldGroup bsSize="sm" id="searchValue" type="text" label="Número OS | Descrição | Cód. Externo Cliente" placeholder="Filtro" value={this.state.searchValue} onChange={this.onChangeSearch} />

                            {this.state.showTodosCamposFiltro && <FieldGroup bsSize="sm" id="searchValueCliente" componentClass="select" label="Cliente" placeholder="Filtro" value={this.state.searchValueCliente} onChange={this.onChangeSearch} >
                                <option value="">Todos</option>
                                {this.state.Clientes.map((row, i) => {
                                    return (
                                        <option key={i} value={row.clientCode}>{row.name}</option>
                                    );
                                })}
                            </FieldGroup>}
                            {this.state.showTodosCamposFiltro && <FieldGroup bsSize="sm" id="searchValueTecnico" componentClass="select" label="Técnico Acionado" placeholder="Filtro" value={this.state.searchValueTecnico} onChange={this.onChangeSearch} >
                                <option value="">Todos</option>
                                {this.state.Tecnicos.map((row, i) => {
                                    return (
                                        <option key={i} value={row.personId}>{row.name}</option>
                                    );
                                })}
                            </FieldGroup>}
                            <FieldGroup bsSize="sm" id="searchStatus" label="Status" componentClass="select" value={this.state.searchStatus} onChange={this.onChangeSearch}>
                                <option value={0}>Selecione</option>
                                <option value={1}>Nova</option>
                                <option value={2}>Em Andamento</option>
                                <option value={3}>Pendência do Cliente</option>
                                <option value={4}>Concluida</option>
                                <option value={5}>Finalizada</option>
                            </FieldGroup>
                            {this.state.showTodosCamposFiltro && <FieldGroup bsSize="sm" id="searchValueNivel" label="Nivel Atendimento" componentClass="select" value={this.state.searchValueNivel} onChange={this.onChangeSearch}>
                                <option value="">Todos</option>
                                <option value="1">Nível 1</option>
                                <option value="2">Nível 2</option>
                                <option value="3">Nível 3</option>
                            </FieldGroup>}
                            <ButtonToolbar>
                                <Button bsSize="sm" title="Buscar" bsStyle="primary" className="pull-right" onClick={this.handleSearch}>Pesquisar</Button>
                                <Button bsSize="sm" title="Limpar" bsStyle="default" onClick={this.handleClear}>Limpar</Button>
                            </ButtonToolbar>
                        </Box>

                        <LoadingOverlay active={this.state.showLoading} spinner={<SyncLoader color={'white'} size='10px' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                            <Box type="primary">
                                <nav className='pull-right'>
                                    <ul className='pagination'>
                                        {pageNumbers && pageNumbers.map(number => (
                                            <li key={number} className='page-item' style={{ paddingLeft: "3px" }}>
                                                <button onClick={() => this.paginationNext(number)} className='page-link'>
                                                    {number}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                {dataTablePagination.length === 0 ? '' :
                                    <Table striped bordered hover>
                                        <tbody>
                                            {dataTablePagination.map((row, i) => {
                                                let color = '';

                                                if (row.status === 4 || row.status === 5 ||
                                                    row.percentualAproximadoStatusOS <= 20) color = 'success';
                                                else if (row.percentualAproximadoStatusOS >= 80) color = 'danger';
                                                else if (row.percentualAproximadoStatusOS >= 60) color = 'warning';
                                                else if (row.percentualAproximadoStatusOS >= 40) color = 'primary';

                                                return (
                                                    <tr key={i} onClick={() => this.handleRowSelect(row)} style={{ cursor: "pointer" }}>
                                                        <td>
                                                            <Link to={`/atendimento/${row.codeOS}`}>{<strong>{row.codeOS}</strong>}</Link>
                                                            - SLA {row.slaCliente}
                                                            {row.percentualAproximadoStatusOS >= 80 ?
                                                                <span style={{ backgroundColor: "Black", color: "White" }} className={'label pull-right'}>{row.timeOS}hs</span> :
                                                                <span className={`label label-${color} pull-right`}>{row.timeOS}hs</span>
                                                            }
                                                            <br />
                                                            <span>{row.title} | Local: {row.site}</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                }

                            </Box>
                        </LoadingOverlay>
                    </Col>
                    <Col md={8}>

                        <React.Fragment>
                            <LoadingOverlay active={this.state.showLoading} spinner={<SyncLoader color={'white'} size='10px' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                                {selectedRow.codeOS !== undefined && <Box type="primary" title={`OS ${selectedRow.codeOS} - ${this.state.statusText}`} customOptions={
                                    <ButtonToolbar>
                                        {showInteracaoButton && <Button bsSize="sm" title="Nova Interação" bsStyle="warning" onClick={() => this.setState({ criarInteracao: true })}>Interação</Button>}
                                        {showAcionamentoButton && <Button bsSize="sm" title="Acionamento Técnico" bsStyle="warning" onClick={() => this.setState({ criarAcionamento: true })}>Acionamento</Button>}
                                        {showConcluirButton && <Button bsSize="sm" title="Concluir OS" bsStyle="warning" onClick={() => this.setState({ concluirOrdemServico: true })}>Concluir</Button>}
                                        {showReabrirOSButton && <Button bsSize="sm" title="Reabrir OS" bsStyle="warning" onClick={() => this.setState({ reabrirOrdemServico: true })}>Reabrir OS</Button>}
                                        {showFinalizarOSButton && <Button bsSize="sm" title="Finalizar OS" bsStyle="warning" onClick={() => this.setState({ finalizarOrdemServico: true })}>Finalizar OS</Button>}

                                    </ButtonToolbar>}>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Site</strong>
                                            <p className="text-muted">{selectedRow.cliente} - {selectedRow.site}</p>
                                        </Col>
                                        <Col md={6}>
                                            <strong>Resumo</strong>
                                            <p className="text-muted">{selectedRow.title}</p>
                                        </Col>
                                        <Col md={12}>
                                            <strong>Endereço</strong>
                                            <p className="text-muted">{selectedRow.logradouro}, {selectedRow.numero}, {selectedRow.bairro}, {selectedRow.cidade}, {selectedRow.uf}</p>
                                        </Col>
                                    </Row>
                                </Box>}
                            </LoadingOverlay>
                        </React.Fragment>
                        <LoadingOverlay active={this.state.showLoading} spinner={<SyncLoader color={'white'} size='10px' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    {showAbaInteracao && <li className="active"><a href="#timeLine" data-toggle="tab">Interações</a></li>}
                                    {showAbaMaterial && <li> <a href="#material" data-toggle="tab">Material</a></li>}
                                    {showAbaMaterial && <li> <a href="#historico" data-toggle="tab">Historico</a></li>}
                                </ul>
                                <div className="tab-content">

                                    <div className="tab-pane active" id="timeLine">

                                        {selectedRow.length === 0 ? <Box>Nenhuma OS selecionada!</Box> :
                                            <React.Fragment>

                                                <div>
                                                    <div className="clearfix">
                                                        <span className="pull-left">{`Tempo decorrido ${selectedRow.timeOS} de ${selectedRow.slaCliente} horas ${nivelSLAAcionamento}`}</span>
                                                        <small className="pull-right">{valueBar}%</small>
                                                    </div>
                                                    <div className="progress xs">
                                                        <div className={"progress-bar progress-bar-" + color} style={{ width: valueBar + '%' }}></div>
                                                    </div>
                                                </div>
                                                {ordemServicoItens && <OrdemServicoItens rowId={selectedRow.codeOS} />}

                                            </React.Fragment>
                                        }

                                    </div>
                                    <div className="tab-pane" id="material">
                                        {showSolicitarMaterialButton && < p > <Button bsSize="sm" title="Solicitar Material" bsStyle="warning" onClick={() => this.setState({ solicitarMaterial: true })}>Solicitar Material</Button></p>}
                                        <div className="row">
                                            <div className="col-md-12">
                                                {dataTableMaterial.length === 0 ? "Não têm materiais!" :
                                                    <div className="table-responsive">
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-left">Material</th>
                                                                    <th className="text-left">Quant.</th>
                                                                    <th className="text-left">Valor Unit.</th>
                                                                    <th className="text-left">Solicitante</th>
                                                                    <th className="text-left">Data</th>
                                                                    <th className="text-left">Descrição</th>
                                                                    <th className="text-left">Uf</th>
                                                                    <th className="text-left">Status</th>
                                                                    <th className="text-left">Motivo</th>
                                                                    {aprovadorMaterial && <th className="text-center col-md-2">Ação</th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataTableMaterial.map(row => {
                                                                    return (
                                                                        <tr key={row.idSolicitacao}>
                                                                            <td className="text-left">{row.nome}</td>
                                                                            <td className="text-left">{row.amount}</td>
                                                                            <td className="text-left">{Global.CurrencyFix(row.valor)}</td>
                                                                            <td className="text-left">{row.userLogged.name}</td>
                                                                            <td className="text-left">{new Date(row.created).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                                                                            <td className="text-left">{row.description}</td>
                                                                            <td className="text-left">{row.uf}</td>
                                                                            <td className="text-left">{row.isPendente ? 'Pendente Aprovação' : row.isAprovado ? 'Aprovado' : row.isCancelado ? 'Reprovado' : ''}</td>
                                                                            <td className="text-left">{row.motivoCancelamento !== undefined ? row.motivoCancelamento : '-'}</td>
                                                                            {aprovadorMaterial &&
                                                                                <td className="text-left">
                                                                                    <div className="btn-group">
                                                                                        <button type="button" className="btn btn-success btn-xs" onClick={() => this.handleAprovarMaterial(row)} style={{ display: (row.isAprovado || row.isCancelado) ? 'none' : '' }}>Aprovar</button>
                                                                                        <button type="button" className="btn btn-danger btn-xs" onClick={() => this.handleCancelarMaterial(row)} style={{ display: (row.isAprovado || row.isCancelado) ? 'none' : '' }}>Reprovar</button>
                                                                                        <button type="button" className="btn btn-danger btn-xs" onClick={() => this.handleDesfazerAprovacao(row)} style={{ display: (row.isAprovado) ? '' : 'none' }}>Desfazer Aprovação</button>
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="historico">
                                        <Row>
                                            <Col md={12}>
                                                <Box type="primary">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">OS</th>
                                                                <th className="text-center">Titulo</th>
                                                                <th className="text-center">Cliente</th>
                                                                <th className="text-center">Site</th>
                                                                <th className="text-center">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                dataTableHistorico.map(row => {
                                                                    return (
                                                                        <tr key={row.codeOS} style={{ cursor: "pointer" }}>
                                                                            <td>
                                                                                <Link to={`/atendimento/${row.codeOS}`}>{<strong>{row.codeOS}</strong>}</Link>
                                                                            </td>
                                                                            <td><span>{row.title}</span></td>
                                                                            <td><span>{row.cliente}</span></td>
                                                                            <td><span> {row.site}</span></td>
                                                                            <td><span>{this.ObterDescricaoStatus(row)}</span></td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </Table>
                                                </Box>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    </Col>
                </Row>

                {concluirOrdemServico && <OrdemServicoConcluir config={config} show={concluirOrdemServico} row={selectedRow} onReload={() => this.onReloadConclusaoOS()} onHide={() => this.cancelarConclusaoOS()} />}
                {reabrirOrdemServico && <OrdemServicoReabrir config={config} show={reabrirOrdemServico} row={selectedRow} onReload={() => this.onReloadOSReaberta()} onHide={() => this.cancelarReabrirOS()} />}
                {criarAcionamento && <AdicionarAcionamento config={config} show={criarAcionamento} row={selectedRow} onHide={() => this.setState({ criarAcionamento: false })} />}
                {criarInteracao && <OrdemServicoAdicionarInteracao config={config} show={criarInteracao} row={selectedRow} onHide={() => this.setState({ criarInteracao: false })} />}
                {criarOrdemServico && <OrdemServicoForm config={config} show={criarOrdemServico} row={selectedRow} onHide={() => this.setState({ criarOrdemServico: false })} />}
                {solicitarMaterial && <SolicitarMaterial config={config} show={solicitarMaterial} row={selectedRow} getMateriaisSolicitados={this.getMateriaisSolicitados} onHide={() => this.setState({ solicitarMaterial: false })} />}
                {showMotivoCancelarMaterialForm && <OrdemServicoMotivoCancelarMaterial show={showMotivoCancelarMaterialForm} row={rowSelectedMaterial} config={config} getMateriaisSolicitados={this.getMateriaisSolicitados} onHide={() => this.setState({ showMotivoCancelarMaterialForm: false })} />}
                {finalizarOrdemServico && <OrdemServicoFinalizar config={config} show={finalizarOrdemServico} row={selectedRow} onReload={() => this.onReloadFinalizarOS()} onHide={() => this.onReloadFinalizarOS()} />}
            </Content>

        );
    }
}