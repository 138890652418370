import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CrudForm from './CrudForm';

export default class Fields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            children: this.props.children
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            config: newProps.config,
            children: newProps.children
        });
    }

    onHide() {
        this.props.onHide();
    }

    render() {
        const { children } = this.props;
        const { selectedRow, keyName, action, api } = this.state.config;

        return (
            <div>
                {action !== 'edit' && selectedRow !== undefined &&
                    <Row>
                        {React.Children.map(children, (child, i) => (
                            <Col key={i} md={child.props.col}>
                                <strong>{child.props.label}</strong>
                                <p className="text-muted">{selectedRow[child.props.id]}</p>
                            </Col>
                        ))}
                    </Row>
                }
                {action === 'edit' &&
                    <CrudForm api={api} action={action} keyName={keyName} selectedRow={selectedRow} onHide={() => this.props.onHide()}>
                        {children}
                    </CrudForm>
                }
            </div>
        )
    }
}
