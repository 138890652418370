import React, { Component } from 'react';
import { 
    Modals, FieldGroup, Button, ButtonToolbar
} from '../../components/adminlte/AdminLTE';
import { toast } from 'react-toastify';


export class OrdemServicoReabrir extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            titleOS: 'Reabrir OS',
            row: {},
            description: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ description: '', row: newProps.row });
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        this.setState({ description: value });
    }

    handleSubmit(e) {
        const { config, row } = this.props;

        if (this.state.description === "") {
            toast.warn('Informe a Descrição');
            return;
        }

        fetch(config.apiEndPoint + row.codeOS + "/reopen/" + this.state.description, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);

        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.props.onReload();
            }
            else {
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            toast.error(error.response.data.msg);
        });
    }

    onHide() {
        this.props.onHide();
    }

    render() {
        const { show = false, row } = this.props;
        const { isSaving } = this.state;

        return (
            <Modals title={"Reabrir OS " + row.codeOS} show={show} onHide={() => this.onHide()}
                footer={
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="success" disabled={isSaving} onClick={this.handleSubmit}>
                            {isSaving ? 'Salvando...' : 'Salvar'}
                        </Button>
                        <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                    </ButtonToolbar>
                }>

                <FieldGroup id="description" col={12} type="text" maxLength="1000" componentClass="textarea" label="Descri&#xE7;&#xE3;o" value={this.state.description} onChange={this.handleChange} />
            </Modals>
        );
    }
}