import React, { useEffect, useState } from 'react';
import { Link, config, apiHelper, Modal, toast } from '../index';
import Core from '../Core';

export function ProvisionamentoRelatorio(props) {
    const endPoint = 'v2/projeto/provisionamento';
    const projetoId = props.parentId;
    const [data, setData] = useState({});
    const [provisionamentos, setProvisionamentos] = useState([]);
    const [tipos, setTipos] = useState([]);

    const [params, setParam] = useState({
        showModal: false,
        provisionamentos: [],
        modalTitle: ''
    });
    const [mesesAnos, setMesesAnos] = useState([]);


    const fetchData = () => {
        apiHelper.get(`${endPoint}?projetoId=${projetoId}`)
            .then(res => {
                if (res.data.code === 200) {
                    setProvisionamentos(res.data.data);
                    const minDate = new Date(Math.min(...res.data.data.map((item) => new Date(item.dataProvisionamento))));
                    const maxDate = new Date(Math.max(...res.data.data.map((item) => new Date(item.dataProvisionamento))));
                    const meses = [];
                    const dataAtual = new Date(minDate);
                    while (dataAtual <= maxDate || dataAtual.getMonth() === maxDate.getMonth()) {
                        meses.push(new Date(dataAtual));
                        dataAtual.setMonth(dataAtual.getMonth() + 1);
                    }
                    setMesesAnos(meses);

                    const valoresPorMesAnoETipo = {};

                    // Preencha o objeto com os valores dos dados
                    res.data.data.forEach((item) => {
                        let dataProvisionamento = new Date(item.dataProvisionamento);
                        const chave = `${item.tipo}_${dataProvisionamento.getMonth() + 1}_${dataProvisionamento.getFullYear()}`;
                        if (!valoresPorMesAnoETipo[chave]) {
                            valoresPorMesAnoETipo[chave] = 0;
                        }
                        valoresPorMesAnoETipo[chave] += item.valor;
                    });
                    setData(valoresPorMesAnoETipo);
                    apiHelper.get('portal/crud/PortalMetadata?filter=Categoria::equal::TipoProvisionamento')
                        .then(resp => {
                            let tips = [];
                            resp.data.data.map((item) =>
                                tips.push({ valor: item.valor, nome: item.nome })
                            );
                            setTipos(tips);
                        }).catch(error => {
                            toast.error('Houve um erro ao carregar o registro.');
                        });
                }
                else
                    toast.error(res.data.data);
            });
    };

    useEffect(fetchData, [projetoId]);

    const handleDetails = (date, tipo) => {

        let provs = [];
        provisionamentos.forEach(item => {
            let dataProv = new Date(item.dataProvisionamento);
            if (tipo === item.tipo && date.getMonth() === dataProv.getMonth()
                && dataProv.getFullYear() === date.getFullYear())
                provs.push(item);
        });

        let title = `${config.capitalize(date.toLocaleString('pt-BR', { month: 'long' }))} de 
        ${date.getFullYear()}`;

        setParam({
            ...params,
            showModal: true,
            provisionamentos: provs,
            modalTitle: `Descrição Provisionamentos - 
            ${title}`
        });
    };

    const handleHide = () => {
        setParam({ ...params, showModal: false, provisionamentos: [], modalTitle: '' });
    }

    const downloadAnexo = async (fileName) => {
        await Core.api_download(config.getFolderModuloProjeto(), fileName).then(res => {
        });
    }

    return (
        <React.Fragment>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin">
                            <thead>
                                <tr className="bg-light-blue color-palette">
                                    <th>Descrição</th>
                                    <th>Data Provisionamento</th>
                                    <th>Tipo</th>
                                    <th>Valor</th>
                                    <th>Criado Por</th>
                                    <th>Anexo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {params.provisionamentos.length > 0 && params.provisionamentos.sort((a, b) => a.id < b.id ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i} style={{ "width": "95px" }}>
                                                <td>{item.descricao}</td>
                                                <td>{config.dateFix(item.dataProvisionamento)}</td>
                                                <td>{item.tipo}</td>
                                                <td>{config.currencyFix(item.valor)}</td>
                                                <td>{item.criador}</td>
                                                <td>{item.anexo ? <Link to="#" onClick={() => downloadAnexo(item.anexo)}>{item.anexo}</Link> : "-"}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title">Relatório Provisionamento</h3>

                    <div className="box-tools pull-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" /></button>
                        </div>

                    </div>
                </div>
                <div className="box-body">
                    {(mesesAnos && mesesAnos.length > 0) &&
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin">
                                <thead>
                                    <tr>
                                        <th className="bg-light-blue color-palette">Tipo | Data</th>
                                        {mesesAnos.map((item, index) => (
                                            <th className="bg-light-blue color-palette" key={index}>{`${config.capitalize(item.toLocaleString('pt-BR', { month: 'long' }))} / ${item.getFullYear()}`}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tipos.map((tipo, index) => (
                                        <tr key={index}>
                                            <td className="bg-light-blue color-palette">{tipo.nome}</td>
                                            {mesesAnos.map((item, index) => {
                                                const chave = `${tipo.valor}_${item.getMonth() + 1}_${item.getFullYear()}`;
                                                return (
                                                    <td key={index} onClick={data[chave] && (() => handleDetails(item, tipo.valor))}
                                                        style={{ cursor: data[chave] ? 'zoom-in' : 'auto' }}>
                                                        {data[chave] ? config.currencyFix(data[chave]) : '-'}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}
