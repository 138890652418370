import React, { useState, useEffect } from 'react';
import { apiHelper, config, toast, Link } from '../';
import { RequisicaoMaterialItem } from './RequisicaoMaterialItem';

export function RequisicaoMaterialView(props) {
    const endPoint = 'suprimentos/rm/';

    const idRequisicao = props.parentId;

    const [totalItens, setTotalItens] = useState({});
    const [data, setData] = useState({});
    const [dataRow, setDataRow] = useState([]);

    const fetchData = () => {
        idRequisicao > 0 && apiHelper.get(`${endPoint}/${idRequisicao}`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);
                }
                else
                    toast.error('Atenção, não foi executar esta operação ' + idRequisicao);
            });
    }

    useEffect(fetchData, []);

    const handleRefresh = (itens) => {
        setTotalItens(itens);
        fetchData();
    }

    const handeSubmit = () => {
        if (Object.keys(dataRow) !== undefined) {
            apiHelper.put(`portal/crud/requisicaoMaterial/${idRequisicao}/Id`, dataRow)
                .then(res => {
                    config.httpResult(res.data);
                    fetchData();
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    const handleEnviarRequisicao = () => {
        if (totalItens === 0) {
            toast.warn('Adicione pelo menos um item');
            return;
        }

        if (!window.confirm("Deseja realmente enviar esta requisição ?"))
            return;

        apiHelper.put(`${endPoint}enviarRequisicao/${idRequisicao}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                    handleClose();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleCancelarRequisicao = () => {
        if (!window.confirm("Deseja realmente cancelar esta requisição de material ?"))
            return;

        apiHelper.api_delete(`${endPoint}cancelar/${idRequisicao}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    handleClose();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleConclusao = () => {
        if (!window.confirm("Deseja realmente concluir esta requisição de material ?"))
            return;

        apiHelper.put(`${endPoint}conclusao/${idRequisicao}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleClose = () => {
        props.onHide();
    }

    const handleUpdate = () => {
        props.onUpdate();
    }

    const changeRequisicao = (e, name) => {
        
        let detalhamento = e.target.innerText;
        setDataRow({ ...dataRow, [name]: detalhamento });
    };

    return (
        <div className="row">
            <div className="col-md-4">
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">Requisição {data.id} </h3>
                        <div className="box-tools pull-right">
                            {data.status !== 'Concluido' && <button type="button" className="btn btn-box-tool" onClick={handeSubmit}><i className="fa fa-save" /></button>}
                            <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                        </div>
                    </div>
                    <div className="box-body no-padding">
                        <table className="table table-striped table-bordered no-margin">
                            <tbody>
                                <tr>
                                    <th>Requisição</th>
                                    <td><Link to={'/suprimentos/rm/' + data.id}>Abrir</Link></td>
                                </tr>
                                <tr>
                                    <th>Necessidade</th>
                                    <td>{config.dateFix(data.dataNecessidade)}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{data.status}</td>
                                </tr>
                                <tr>
                                    <th>Solicitante</th>
                                    <td>{data.usuario}</td>
                                </tr>
                                <tr>
                                    <th>Descrição</th>
                                    <td>{data.status === 'Concluido' ? data.descricao :
                                        <div style={{ background: '#fcf8e1', outline: 0 }} suppressContentEditableWarning contentEditable onBlur={(e) => changeRequisicao(e, 'descricao')}>{data.descricao}</div>}</td>
                                </tr>
                                <tr>
                                    <th>Local Entrega</th>
                                    <td>{data.status === 'Concluido' ? data.localEntrega :
                                        <div style={{ background: '#fcf8e1', outline: 0 }} suppressContentEditableWarning contentEditable onBlur={(e) => changeRequisicao(e, 'localEntrega')}>{data.localEntrega}</div>}</td>
                                </tr>
                                {data.observacao && <tr>
                                    <th>Observação</th>
                                    <td>{data.observacao}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    {props.canEdit && <div className="box-footer">
                        {data.status === 'Concluido' && <span>Requisição concluída!</span>}
                        {(data.solicitanteId === config.idUsuarioLogado() && data.status === 'Rascunho' && totalItens > 0) && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleEnviarRequisicao}><i className="fa fa-share" /> Enviar Requisição</button>}
                        {data.status === 'Entregue' && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleConclusao}><i className="fa fa-folder" /> Concluir</button>}
                        {data.status === 'Rascunho' && <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleCancelarRequisicao}>Excluir</button>}
                    </div>}
                </div>
            </div>
            <div className="col-md-8">
                <RequisicaoMaterialItem parentId={idRequisicao} refresh={(totalItens) => handleRefresh(totalItens)} onHide={handleClose} onUpdate={handleUpdate} canEdit={props.canEdit} />
            </div>
        </div >
    )
}
