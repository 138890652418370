import React, { Component } from 'react';
import { InputGroup, FormControl, ControlLabel } from 'react-bootstrap';

export class FormGroupButton extends Component {
    render() {
        const { label = undefined, buttonLeft = undefined,
            buttonRight = undefined, size = undefined, ...props
        } = this.props;
        return (
            <React.Fragment>
                {label !== undefined && <ControlLabel>{label}</ControlLabel>}
                <InputGroup bsSize={size}>
                    {buttonLeft !== undefined &&
                        <InputGroup.Button>{buttonLeft}</InputGroup.Button>
                    }
                    <FormControl {...props} />
                    {buttonRight !== undefined &&
                        <InputGroup.Button>{buttonRight}</InputGroup.Button>
                    }
                </InputGroup>
            </React.Fragment>
        );
    }
}
