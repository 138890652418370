import React from 'react';
import { config } from '../..';
import { Bar } from 'react-chartjs-2';

export function BarChart(props) {
    const conteudo = {
        labels: [],
        datasets: []
    };

    const configuration = {
        type: 'bar',
        options: {
            responsive: true,
            plugins: {
                legend: { position: 'top', },
                title: { display: true, text: 'teste' }
            }
        }
    };

    if (!props.data || !props.config)
        return;

    let configData = JSON.parse(props.config);
    let colors = configData.colors;
    conteudo.labels = [...new Set(props.data.map(item => item[configData.label]))];
    conteudo.datasets = [...new Set(props.data.map(item => item[configData.nome]))]
        .map((item2) => {
            return {
                label: item2,
                data: conteudo.labels.map(item => 0),
                backgroundColor: colors && colors.length > 0 ? colors.shift() : config.getRandomColor(1)[0],
            };
        });

    props.data.map((item, i) => {
        conteudo.datasets.map((dataset) => {
            if (item[configData.nome] === dataset.label) {
                let indice = conteudo.labels.indexOf(item[configData.label]);
                dataset.data[indice] = item[configData.valor];
            }

            return "";
        });

        return "";
    });

    if (conteudo.datasets.length === 1)
        conteudo.datasets[0].backgroundColor = config.colors || config.getRandomColor(conteudo.labels.length);

    return (<Bar options={configuration} data={conteudo} />)
}