import React, { useState, useEffect } from 'react';
import Core, { Content, Table, Global } from '../Core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { OrdemServicoMotivoCancelarMaterial } from './OrdemServicoMotivoCancelarMaterial';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';

const config = {
    keyName: 'title',
    rowName: 'title',
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiUrl: localStorage.getItem('apiUrl'),
    apiEndPoint: 'siga/serviceOrder/',
    claimsUsuarioLogado: localStorage.getItem('claims') !== null && localStorage.getItem('claims') !== undefined ? localStorage.getItem('claims').split(',') : ''
};

export function AprovarMaterial(props) {
    const [param, setParam] = useState({
        titleFiltro: '',
        showMotivoCancelarMaterialForm: false,
        rowSelectedMaterial: ''
    });

    const [dataTableMaterial, setDataTableMaterial] = useState([]);
    const [aprovadorMaterial, setAprovadorMaterial] = useState(false);
    //const [setUserLogged] = useState([{
    //    personId: 0,
    //    name: '',
    //    login: '',
    //    active: '',
    //    phoneNumber: '',
    //    email: ''
    //}]);

    const [showLoading, setShowLoading] = useState(false);

    const loadData = () => {
        VerificarSePodeAprovarMaterial();
        getMateriaisSolicitadosPendentes();
    };

    useEffect(loadData, []);

    const getMateriaisSolicitadosPendentes = async () => {
        setShowLoading(true);
        var url = `${config.apiEndPoint}materialSolicitado`;

        await Core.api_get(url).then(res => {
            setDataTableMaterial(res.data);
            //setUserLogged(res.data.userLogged);

            setShowLoading(false);
        });
    }

    const VerificarSePodeAprovarMaterial = () => {
        var isAprovadorMaterial = config.claimsUsuarioLogado.includes('AprovarMaterial');
        setAprovadorMaterial(isAprovadorMaterial);
    }
    const handleSearch = (e) => {
        let value = !e ? '' : e.target.value;

        if (value.length > 1) {
            let filtro = dataTableMaterial.filter(c => c.serviceOrderCode.toString().trim().includes(value.toString().trim()));
            setDataTableMaterial(filtro);
        } else {
            getMateriaisSolicitadosPendentes();
        }
    }

    const handleAprovarMaterial = (e) => {
        var data = {
            serviceOrderCode: e.serviceOrderCode,
            idSolicitacao: e.idSolicitacao,
            fkAprovacaoMaterial: e.fkAprovacaoMaterial,
            isPendente: false,
            isAprovado: true
        }

        fetch(localStorage.getItem('apiUrl') + 'siga/serviceOrder/aprovarMaterial', {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', 'Authorization': config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Material aprovado com sucesso!');
                getMateriaisSolicitadosPendentes();
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    const handleCancelarMaterial = (rowSelectedMaterial) => {
        setParam({ showMotivoCancelarMaterialForm: true, rowSelectedMaterial: rowSelectedMaterial });
    }

    return (
        <Content title="Aprovação de materiais" browserTitle="Aprovação de materiais">

            <div className="row">
                <div className="col-md-12">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10px' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">{param.titleFiltro}</h3>

                                <div className="box-tools pull-right">
                                    <div className="has-feedback">
                                        <input type="text" className="form-control input-sm" placeholder="Buscar por OS" onChange={handleSearch} value={param.searchValue} />
                                        <span className="glyphicon glyphicon-search form-control-feedback"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body no-padding">
                                <div className="col-md-12">
                                    {dataTableMaterial.length === 0 && showLoading === false ? "Não há materiais pendentes para aprovação!" :
                                        <div className="table-responsive">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">OS</th>
                                                        <th className="text-center">Cliente/Site</th>
                                                        <th className="text-center">Material</th>
                                                        <th className="text-center">Quantidade</th>
                                                        <th className="text-center">Valor Unitário</th>
                                                        <th className="text-center col-md-2">Solicitante</th>
                                                        <th className="text-center">Data Solicitação</th>
                                                        <th className="text-center">Descrição</th>
                                                        <th className="text-center">Uf</th>
                                                        <th className="text-center col-md-2">Status</th>
                                                        {aprovadorMaterial && <th className="text-center col-md-2">Ação</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataTableMaterial.map(row => {
                                                        return (
                                                            <tr key={row.idSolicitacao}>
                                                                <td className="text-center">{row.serviceOrderCode}</td>
                                                                <td className="text-center">{`${row.nomeCliente} - ${row.nomeSite}`}</td>
                                                                <td className="text-center">{row.nome}</td>
                                                                <td className="text-center">{row.amount}</td>
                                                                <td className="text-center">{Global.CurrencyFix(row.valor)}</td>
                                                                <td className="text-center">{row.userLogged ? row.userLogged.name : ''}</td>
                                                                <td className="text-center">{new Date(row.created).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                                                                <td className="text-center">{row.description}</td>
                                                                <td className="text-center">{row.uf}</td>
                                                                <td className="text-center">{row.isPendente ? 'Pendente Aprovação' : row.isAprovado ? 'Aprovado' : row.isCancelado ? 'Reprovado' : ''}</td>
                                                                {aprovadorMaterial &&
                                                                    <td className="text-center">
                                                                        <div className="btn-group">
                                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleAprovarMaterial(row)} disabled={row.isAprovado || row.isCancelado ? true : false}>Aprovar</button>
                                                                            <button type="button" className="btn btn-danger btn-xs" onClick={() => handleCancelarMaterial(row)} disabled={row.isAprovado || row.isCancelado ? true : false}>Reprovar</button>
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
            {param.showMotivoCancelarMaterialForm && <OrdemServicoMotivoCancelarMaterial show={param.showMotivoCancelarMaterialForm} row={param.rowSelectedMaterial} config={config} getMateriaisSolicitados={getMateriaisSolicitadosPendentes}  onHide={() => setParam({ showMotivoCancelarMaterialForm: false })} />}
        </Content>
    )
}
