import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select } from '../index';

export function CardForm(props) {
    const endPoint = props.endPoint;
    const id = props.rowId;
    const [data, setData] = useState({});

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (id === 0) {
            apiHelper.post(`${endPoint}/`, data).then(res => {
                toast.success('Card Adicionado com sucesso!')
                onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o card.' + error.message);
            });
        }
        else {
            apiHelper.put(`${endPoint}/${id}`, data).then(res => {
                toast.success('Card Alterado com sucesso!')
                onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o card.' + error.message);
            });
        }
    }

    const fetchData = () => {
        if (id && id > 0) {
            apiHelper.get(`${endPoint}/${id}`).then(res => {
                setData(res.data);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    const onHide = () => {
        props.onHide()
    }

    useEffect(fetchData, [id]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-4">
                    <label>Nome</label>
                    <Select id="reportId" className="form-control" onChange={handleChange} value={data.reportId} required>
                        <option value="">Selecione o Card</option>
                        <option endpoint={`v2/report/card/user`} valuefield="id" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-sm-4">
                    <label>Tamanho</label>
                    <Select id="tamanho" className="form-control" onChange={handleChange} value={data.tamanho} required>
                        <option value={0}>Selecione</option>
                        <option value={4}>25%</option>
                        <option value={6}>50%</option>
                        <option value={8}>75%</option>
                        <option value={12}>100%</option>
                    </Select>
                </div>
                <div className="form-group col-sm-4">
                    <label>Ordem</label>
                    <Select id="ordem" className="form-control" onChange={handleChange} value={data.ordem} required>
                        <option value={0}>Selecione</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={onHide}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
