import React, { useState, useEffect } from 'react';
import { Content, apiHelper, toast, Select, Modal, config, Link } from '../';
import { RegistrarEntradaForm } from './RegistrarEntradaForm';

export function RegistrarEntrada(props) {
    const endPoint = 'suprimentos/material';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        pkCompra: '',
        fkFornecedor: '',
        numeroNotaFiscal: '',
        valor: '',
        dataCompra: '',
        dataCompraInicio: '',
        dataCompraFim: '',
    });
    const [params, setParam] = useState({
        ids: '',
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 50,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 50
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}/compra?filter=${where}`)
            .then(res => {
                if (res.data.code === 200) {
                    setData(res.data.data);
                }
                else
                    toast.error('Atenção, não foi possível carregar as compras!');
            });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    };

    const handleShowForm = (name, title) => {
        setParam({
            ...params,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: "",
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            pkCompra: '',
            fkFornecedor: '',
            numeroNotaFiscal: '',
            valor: '',
            dataCompra: '',
            dataCompraInicio: '',
            dataCompraFim: '',
        });
        setData([]);
        fetchData();
    };

    const handleSearch = () => {
        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            if (!value2)
                return null;

            if (key === 'numeroNotaFiscal')
                where += key + '::contains::' + value2 + ";;AND;;";
            else if (key === 'dataCompraInicio') {
                where += `${'dataCompra'}::greaterEqual::${value2};;AND;;${'dataCompra'}::lessEqual::${filter.dataCompraFim} 23:59:59;;AND;;`;
            }
            else if (key !== 'dataCompraFim')
                where += key + '::equal::' + value2 + ";;AND;;";

            return null;
        });

        fetchData(where);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Registrar Entrada" browserTitle="Registrar Entrada">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{ marginBottom: "7px" }}>
                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("registrarEntrada", "Registrar Entrada", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Nova Entrada</button>
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                                Pesquisar <span className="caret" />
                                            </button>
                                            <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 600, padding: 5 }} onClick={e => e.stopPropagation()}>
                                                <div className="box" style={{ margin: 0 }}>
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="form-group col-md-4">
                                                                        <label>Id</label>
                                                                        <input id="pkCompra" type="text" className="form-control" onChange={handleChange} value={filter.pkCompra} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Fornecedor</label>
                                                                        <Select id="fkFornecedor" className="form-control" onChange={handleChange} value={filter.fkFornecedor}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint="portal/crud/fornecedor?filter=isExcluido::equal::0" valuefield="pkFornecedor" textfield="nome" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Nota Fiscal</label>
                                                                        <input id="numeroNotaFiscal" type="text" className="form-control" onChange={handleChange} value={filter.numeroNotaFiscal} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Valor</label>
                                                                        <input id="valor" type="text" className="form-control" onChange={handleChange} value={filter.valor} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Data Compra Início</label>
                                                                        <input id="dataCompraInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataCompraInicio} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Data Compra Fim</label>
                                                                        <input id="dataCompraFim" type="date" className="form-control" onChange={handleChange} value={filter.dataCompraFim} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="box-footer" style={{ padding: 5 }}>
                                                        <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                        <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                            {renderPage()}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                            <thead>
                                                <tr className="bg-light-blue">
                                                    <th>Id</th>
                                                    <th>Fornecedor</th>
                                                    <th>Nota Fiscal</th>
                                                    <th>Valor</th>
                                                    <th>Data Compra</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.sort((a, b) => a.pkCompra < b.pkCompra ? 1 : -1)
                                                    .slice(params.initRow, params.endRow)
                                                    .map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td><Link to={'/suprimentos/registrarEntrada/' + item.pkCompra}>{item.pkCompra}</Link></td>
                                                                <td>{item.fornecedor}</td>
                                                                <td>{item.numeroNotaFiscal}</td>
                                                                <td>{config.currencyFix(item.valor)}</td>
                                                                <td>{config.dateFix(item.dataCompra)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ marginTop: "7px" }}>
                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>

                                        <div className="pull-right">
                                            {renderPage()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} bsSize={'lg'} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "registrarEntrada" && <RegistrarEntradaForm onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
