import React, { useState, useEffect } from 'react';
import { Link, apiHelper, toast, config } from '../';
import { RequisicaoMaterialForm } from './RequisicaoMaterialForm';
import { RequisicaoMaterialItemForm } from './RequisicaoMaterialItemForm';

export function RequisicaoMaterialItem(props) {
    const endPoint = 'estoque/requisicaoMaterial/';
    const [row, setRow] = useState({});
    const [data, setData] = useState([]);
    const [params, setParams] = useState({
        rowId: props.parentId,        
        novoItem: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = `idRequisicaoMaterial::equal::${params.rowId}`;

        apiHelper.get(`${endPoint}item?filter=${where}&order=id`)
            .then(res => {
                setData(res.data.data);
            }).catch(error => {
                toast.error('');
            });
    }

    const fetchRow = () => {
        let where = `id::equal::${params.rowId}`;

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                setRow(res.data.data[0]);
            }).catch(error => {
                toast.error('');
            });
    }

    const onLoad = () => {    
        fetchData();
        fetchRow();
    }

    useEffect(onLoad, [props.parentId]);

    const handleNew = () => {
        setParams({...params,
            novoItem: true,
            pageSize: 10,
            totalPages: 1,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });
    }

    const handleClose = () => {
        setParams({ ...params, rowId: -1 });

        props.history.push({
            pathname: '/estoque/requisicaoMaterial/'
        });
    }

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`${endPoint}item/${id}/id`, { isExcluido: true }).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleEnviarSolicitacao = () => {
        if (!window.confirm("Deseja realmente enviar a solicitação ?"))
            return;

        apiHelper.put(`${endPoint}/${params.rowId}/id`, { status: 'Enviado' }).then(res => {            
            config.httpResult(res.data);
            fetchRow();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }
    

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    }

    const renderPage = () => {
        const items = [];
        const pages = Math.ceil(data.length / params.pageSize);

        for (let i = 0; i < pages; i++) {
            items.push(
                <li key={i} className="page-item">
                    <Link replace to="#" className="page-link" onClick={() => setPage(i)}>
                        {params.selectedPage === i ? <b>{i + 1}</b> : (i + 1)}
                    </Link>
                </li>
            );
        }

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    const onHide = () => {
        setParams({ ...params, novoItem: false });
        fetchData();
    }

    return (        
        <div className="row">
            <div className="col-md-4">
                <RequisicaoMaterialForm parentId={params.rowId} solicitante={row ? row.usuario : ''} onHide={handleClose} cols="col-md-12" />
            </div>

            <div className="col-md-8">
                <div className="box box-solid">
                    <div className="box-header with-border">
                        <h3 className="box-title">Materiais {row && row.status === 'Enviado' ? <span className="text-danger">- Solicitação enviada!</span> : ''}</h3>
                        <div className="box-tools pull-right">
                            <div role="toolbar" className="btn-toolbar">
                                <button className="btn btn-warning pull-right" title="Novo Item" onClick={handleNew}>Novo Item</button>
                                {row.status !== 'Enviado' && <button className="btn btn-success pull-right" title="Novo Item" onClick={handleEnviarSolicitacao}>Enviar solicitação</button>}
                            </div>
                        </div>
                    </div>

                    <div className="box-body no-padding">
                        <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Cód</th>
                                        <th>Descrição</th>
                                        <th>Valor</th>
                                        <th>Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 && data.sort((a, b) => a.nome > b.nome ? 1 : -1)
                                        .slice(params.initRow, params.endRow)
                                        .map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.id}</td>
                                                    <td>{item.descricao}</td>
                                                    <td>{item.valor}</td>
                                                    <td>{item.quantidade}</td>
                                                    <td><button type="button" className="btn btn-box-tool" onClick={() => handleDelete(item.id)}><i className="fa fa-trash"></i></button></td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="box-footer">
                        <div className="mailbox-controls">
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {params.novoItem && <RequisicaoMaterialItemForm parentId={params.rowId} show={params.novoItem} onHide={onHide} endPoint={endPoint} />}
        </div>
    )
}
