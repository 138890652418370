import React, { useState } from 'react';
import { apiHelper, toast } from '../index';

export function NovaInteracao(props) {
    const endPoint = 'comercial/cliente';
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const handleChange = (e) => {
        let name = e.target.id;
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                apiHelper.toBase64(file)
                    .then((base) => {
                        setData({ ...data, file: base, anexo: file.name, });
                    });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let interacao = {};
        if (data.tipo !== 'NovaInteracao') {
            interacao.titulo = data.titulo;
            interacao.descricao = data.descricao;
            interacao.tipo = data.tipo;
            if (data.anexo !== undefined && data.anexo !== '') {
                interacao.anexo = data.file;
                interacao.nomeAnexo = data.anexo;
            }
            interacao.agendaTitulo = data.titulo;
            interacao.agendaDescricao = data.descricao;
            interacao.agendaTipo = data.tipo;
            interacao.agendaDataInicio = data.agendaDataInicio;
            interacao.agendaDataFim = data.agendaDataFim;
        } else {
            interacao.titulo = data.titulo;
            interacao.descricao = data.descricao;
            interacao.tipo = data.tipo;
            if (data.anexo !== undefined && data.anexo !== '') {
                interacao.anexo = data.file;
                interacao.nomeAnexo = data.anexo;
            }
        }

        apiHelper.post(`${endPoint}/${rowId}/acaoComercial`, interacao)
            .then(res => {
                if (res.data.code === 200) {
                    toast.success('Dados salvos com sucesso!');
                    setData({});
                    props.onHide();
                }
                else if (res.code === 400)
                    toast.warn(res.data.data);
                else
                    toast.error('Atenção, não foi possível salvar a interação.');
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar a interação.');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Titulo *</label>
                    <input id="titulo" type="text" className="form-control" onChange={handleChange} value={data.titulo} required />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao} required />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label htmlFor="fkStatus">Tipo *</label>
                    <select id="tipo" className="form-control" onChange={handleChange} value={data.tipo} required>
                        <option value="">Selecione</option>
                        <option value="NovaInteracao">Nova Interação</option>
                        <option value="Reuniao">Reunião</option>
                        <option value="Almoco">Almoço</option>
                        <option value="Apresentacao">Apresentação</option>
                        <option value="Treinamento">Treinamento</option>
                        <option value="VisitaTecnica">Visita Técnica</option>
                        <option value="VisitaComercial">Visita Comercial</option>
                        <option value="ContatoTelefonico">Contato telefônico</option>
                        <option value="Entrevista">Entrevista</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Anexo</label>
                    <input type="file" id="file" onChange={handleChange} />
                </div>
            </div>
            {data.tipo && data.tipo !== 'NovaInteracao' &&
                <React.Fragment>
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label>Data Início *</label>
                            <input id="agendaDataInicio" type="datetime-local" className="form-control" onChange={handleChange} value={data.agendaDataInicio} required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label>Data Fim *</label>
                            <input id="agendaDataFim" type="datetime-local" className="form-control" onChange={handleChange} value={data.agendaDataFim} required />
                        </div>
                    </div>
                </React.Fragment>}
            <div className="row">
                <div className="col-sm-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

