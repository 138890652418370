import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, config, toast } from '../';

export function RegistrarEntradaView(props) {
    const endPoint = 'suprimentos/material';
    const rowId = props.match.params.id;
    const [data, setData] = useState({});
    const [dataDetalhes, setDataDetalhes] = useState([]);

    const fetchData = () => {
        apiHelper.get(`${endPoint}/compra?filter=pkCompra::equal::${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    
                    setData(res.data.data[0]);
                    obterDetalhesCompra();
                }
                else
                    toast.error('Atenção, não foi possível carregar as compras!');
            });
    };

    const obterDetalhesCompra = () => {
        apiHelper.get(`${endPoint}/${rowId}/compraDetalhes?id=${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    
                    setDataDetalhes(res.data.data);
                }
                else
                    toast.error('Atenção, não foi possível carregar as compras!');
            });

    }

    useEffect(fetchData, [rowId]);

    const handleClose = () => {
        props.history.push({
            pathname: '/suprimentos/RegistrarEntrada/'
        });
    };

    return (
        <Content title="Registros Entrada" browserTitle="Detalhes dos Registros de Entrada">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.pkCompra}</h3>

                            <div className="box-tools pull-right">
                                <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body">
                            <dl>
                                <dt>Fornecedor</dt>
                                <dd>{data.fornecedor}</dd>
                                <dt>Nota Fiscal</dt>
                                <dd>{data.numeroNotaFiscal}</dd>
                                <dt>Valor</dt>
                                <dd>{config.currencyFix(data.valor)}</dd>
                                <dt>Data Compra</dt>
                                <dd>{config.dateFix(data.dataCompra)}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><Link to="#tab_detalhes" data-toggle="tab">Detalhes</Link></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_detalhes" className="tab-pane active">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin">
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>Patrimônio</th>
                                                <th>Numero de Série</th>
                                                <th>Material</th>
                                                <th>Quantidade</th>
                                                <th>Valor Unitário</th>
                                                <th>Material Veotex</th>
                                                <th>Garantia</th>
                                                <th>Imobilizado</th>
                                                <th>Alocado</th>
                                                <th>Observação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataDetalhes && dataDetalhes.sort((a, b) => a.patrimonio < b.patrimonio ? 1 : -1)
                                                .map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{item.patrimonio}</td>
                                                            <td>{item.numeroSerie}</td>
                                                            <td>{item.material}</td>
                                                            <td>{item.quantidade}</td>
                                                            <td>{config.currencyFix(item.valorUnitario)}</td>
                                                            <td>{item.isMaterialVeotex ? 'Sim' : 'Não'}</td>
                                                            <td>{item.garantia + ' ' + item.tempoGarantia}</td>
                                                            <td>{item.isImobilizado ? 'Sim' : 'Não'}</td>
                                                            <td>{item.isAlovado ? 'Sim' : 'Não'}</td>
                                                            <td>{item.observacao}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
