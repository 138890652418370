import decode from 'jwt-decode';

export default class AuthHelper {
    login = (username, password) => {

        return this.fetch(localStorage.getItem('apiUrl') + 'auth', {
            method: 'POST',
            body: JSON.stringify({ username, password })
        }).then(res => {
            if (res.code !== "200") {
                alert(res.message);
                return false;
            }
            else {
                this.setToken(res.data.token);
                localStorage.setItem('id_name', res.data.name);
                localStorage.setItem('id_username', res.data.username);
                localStorage.setItem('id', res.data.userId);
                localStorage.setItem('claims', res.data.claims);
                return true;
            }
        });
    }

    loggedIn = () => {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired = (token) => {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false;
        }
        catch (err) {
            console.log("expired check failed! Line 42: AuthService.js");
            return false;
        }
    }

    setToken = (idToken) => {
        localStorage.setItem('id_token', idToken);
    }

    getToken = () => {
        return localStorage.getItem('id_token');
    }

    getClaims = () => {
        return localStorage.getItem('claims');
    }

    logout = () => {
        localStorage.removeItem('id_token');
        localStorage.removeItem('claims');
    }

    getConfirm = () => {
        let answer = decode(this.getToken());
        return answer;
    }

    fetch = (url, options) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken();
        }

        return fetch(url, { headers, ...options })
            .then(this._checkStatus)
            .then(response => response.json());
    }

    _checkStatus = (response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }
}