import React, { useState } from 'react';
import { apiHelper, toast, config } from '../index';

export function OrcamentoMotivoReprovacaoForm(props) {
    const parentId = props.parentId;
    const [data, setData] = useState({});

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        apiHelper.put(`orcamento/${parentId}/status/?acao=reprovar&&observacao=${data.motivoReprovacao}`)
            .then(res => {
                config.httpResult(res.data);
                props.onHide(true);
                props.onUpdate();
            }).catch(error => {
                toast.error('Atenção, não foi possível atualizar o status');
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group col-sm-12">
                <label>Motivo Reprovação *</label>
                <textarea id="motivoReprovacao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.motivoReprovacao} required />
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
