import React, { useState, useEffect } from 'react';
import { Button,Select, config, apiHelper, toast } from '../index';

export function RegionalForm(props) {
    const endPoint = "portal/crud/Regional/";
    const rowId = props.parentId;
    const [data, setData] = useState([]);

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${"portal/crud/uvw_regional/"}/${rowId}/pkRegional`)
                .then(res => {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        } else {
            setData({ ...data, nome: '', descricao: '' });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res.data);
                props.onHide();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/pkRegional`, data).then(res => {
                config.httpResult(res.data);
                fetchData();
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Nome</label>
                        <input id="nome" name="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                    </div>
                    <div className="form-group">
                        <label>Gerente Regional</label>
                        <Select id="pkUsuario" className="form-control" onChange={handleChange} value={data.fkUsuario}>
                            <option value="">Selecione</option>
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>Supervisor Regional</label>
                        <Select id="pkUsuario" className="form-control" onChange={handleChange} value={data.fkUsuario}>
                            <option value="">Selecione</option>
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input id="email" name="email" type="email" className="form-control" onChange={handleChange} value={data.email} required />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="box-tool pull-right">
                        <div className="btn-toolbar">
                            <Button type="submit" bsStyle="success">Salvar</Button>
                            <Button bsStyle="default" onClick={fetchData}>Cancelar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
