import React, { useState } from 'react';
import { Content, config, apiHelper, fileHelper, toast, Select } from '../../index';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay'
import { SyncLoader } from 'react-spinners';


export function ProjetoFaturamento(props) {
    const endPointFaturamento = "v2/projeto/relatorioComercial";
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        statusOrcamento: '',
        origem: '',
        responsavelVenda: '',
        cliente: '',
        criadorId: '',
        tipoOrcamento: '',
        dataCriacaoInicio: '',
        dataCriacaoFim: '',
        dataCriacaoProjetoInicio: '',
        dataCriacaoProjetoFim: '',
        dataConclusaoInicio: '',
        dataConclusaoFim: ''
    });
    const [dataExcel, setDataExcel] = useState([]);
    const [params, setParam] = useState({
        rowId: 0,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleSearch = () => {
        if (!config.checkFiltroPreenchido(filter)) {
            toast.warn('Informe pelo menos um filtro!');
            return;
        }

        setShowLoading(true);
        apiHelper.post(`${endPointFaturamento}`, filter)
            .then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data;
                    setData(data);

                    let dataExcel = [];
                    data.map((item, i) => {
                        let obj = {
                            "Cliente": item.cliente,
                            "Site": item.site,
                            "Orçamento": item.orcamento,
                            "Data Orçamento": config.dateFix(item.dataOrcamento),
                            "Responsável Cadastro": item.usuarioCadastro,
                            "Consultor Comercial": item.responsavelVenda,
                            "Tipo Orcamento": item.tipoOrcamento,
                            "Venda": item.venda === null ? 0 : config.currencyFix(item.venda),
                            "Locação": item.locacao === null ? 0 : config.currencyFix(item.locacao),
                            "Instalação": item.instalacao === null ? 0 : config.currencyFix(item.instalacao),
                            "Manutenção": item.manutencao === null ? 0 : config.currencyFix(item.manutencao),
                            "Projeto": item.projeto,
                            "Data Projeto": config.dateFix(item.dataProjeto),
                            "Status Projeto": item.statusProjeto,
                            "Data Conclusão": config.dateFix(item.dataConclusaoProjeto),
                            "Origem Contato": item.origem,
                            "Codigo Obra Referência Site": item.codigoObra,
                            "Descricão": item.descricao
                        }
                        dataExcel.push(obj);
                        return null;
                    });

                    setDataExcel(dataExcel);
                }
                setShowLoading(false);
            }).catch(error => {
                toast.error('Atenção, não foi possível carregar os registros.');
                setShowLoading(false);
            });
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    }

    const handleRefresh = () => {
        setFilter({
            statusOrcamento: '',
            origem: '',
            responsavelVenda: '',
            cliente: '',
            criadorId: '',
            tipoOrcamento: '',
            dataCriacaoInicio: '',
            dataCriacaoFim: '',
            dataCriacaoProjetoInicio: '',
            dataCriacaoProjetoFim: '',
            dataConclusaoInicio: '',
            dataConclusaoFim: ''
        });
        setData([]);
        setShowLoading(false);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Relatório de Comercial" browserTitle="Relatório de Faturamento">
            <div className="row">
                <div className="col-md-3">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="form-group">
                                <label>Consultor Comercial</label>
                                <Select id="responsavelVenda" className="form-control" onChange={handleChange} value={filter.responsavelVenda}>
                                    <option value="">Todos</option>
                                    <option endpoint="comercial/pessoa/perfil?perfil=ConsultorComercial" valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Cliente</label>
                                <Select id="cliente" className="form-control" onChange={handleChange} value={filter.cliente}>
                                    <option value="">Todos</option>
                                    <option endpoint="siga/serviceOrder/client/" valuefield="name" textfield="name" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Criador Orçamento</label>
                                <Select id="criadorId" className="form-control" onChange={handleChange} value={filter.criadorId}>
                                    <option value="">Todos</option>
                                    <option endpoint={`portal/crud/uvw_CriadorComercialOrcamento`} valuefield="id" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Tipo Orçamento</label>
                                <Select id="tipoOrcamento" className="form-control" onChange={handleChange} value={filter.tipoOrcamento}>
                                    <option value="">Todos</option>
                                    <option endpoint={`portal/crud/TipoOrcamento`} valuefield="nome" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Status Orçamento</label>
                                <Select id="statusOrcamento" className="form-control" onChange={handleChange} value={filter.statusOrcamento}>
                                    <option value="">Todos</option>
                                    <option endpoint={`portal/crud/PortalMetadata?filter=Categoria::equal::Orcamento`} valuefield="valor" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Origem do Contato</label>
                                <Select id="origem" className="form-control" onChange={handleChange} value={filter.origem}>
                                    <option value="">Todos</option>
                                    <option endpoint={`portal/crud/PortalMetadata?filter=Categoria::equal::LeadOrigem`} valuefield="valor" textfield="nome" />
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Data Orçamento Criação Início</label>
                                <input id="dataCriacaoInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataCriacaoInicio} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group">
                                <label>Data Orçamento Criação Fim</label>
                                <input id="dataCriacaoFim" type="date" className="form-control" onChange={handleChange} value={filter.dataCriacaoFim} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group">
                                <label>Data Criação Projeto Início</label>
                                <input id="dataCriacaoProjetoInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataCriacaoProjetoInicio} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group">
                                <label>Data Criação Projeto Fim</label>
                                <input id="dataCriacaoProjetoFim" type="date" className="form-control" onChange={handleChange} value={filter.dataCriacaoProjetoFim} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group">
                                <label>Data Conclusão Início</label>
                                <input id="dataConclusaoInicio" type="date" className="form-control" onChange={handleChange} value={filter.dataConclusaoInicio} placeholder="dd/mm/yyyy" />
                            </div>
                            <div className="form-group">
                                <label>Data Conclusão Fim</label>
                                <input id="dataConclusaoFim" type="date" className="form-control" onChange={handleChange} value={filter.dataConclusaoFim} placeholder="dd/mm/yyyy" />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="button" className="btn btn-primary pull-right" onClick={handleSearch}>Pesquisar</button>
                            <button type="button" className="btn btn-default pull-left" onClick={handleRefresh}>Limpar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <LoadingOverlay active={showLoading} spinner={<SyncLoader color={'white'} size='10' />} styles={{ overlay: (base) => ({ ...base, background: 'rgba(157,162,162,0.6)' }) }}>
                        <div style={{ marginBottom: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => fileHelper.exportToCSV(dataExcel, 'RelatorioFaturamento_' + moment().format("DD/MM/YYYY"))}><i className="fa fa-file-excel-o" /> Exportar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                <thead>
                                    <tr className="bg-light-blue color-palette">
                                        <th>Cliente</th>
                                        <th>Site</th>
                                        <th>Orcamento</th>
                                        <th>Data Orçamento</th>
                                        <th>Responsável Cadastro</th>
                                        <th>Consultor Comercial</th>
                                        <th>Tipo Orçamento</th>
                                        <th>Venda</th>
                                        <th>Locação</th>
                                        <th>Instalação</th>
                                        <th>Manutenção</th>
                                        <th>Projeto</th>
                                        <th>Data Projeto</th>
                                        <th>Status Projeto</th>
                                        <th>Data Conclusão</th>
                                        <th>Origem Contato</th>
                                        <th>Código Obra/ Referência do Site</th>
                                        <th>Descrição</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                        .slice(params.initRow, params.endRow)
                                        .map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{item.cliente}</td>
                                                    <td>{item.site}</td>
                                                    <td>{item.orcamento}</td>
                                                    <td>{config.dateFix(item.dataOrcamento)}</td>
                                                    <td>{item.usuarioCadastro}</td>
                                                    <td>{item.responsavelVenda}</td>
                                                    <td>{item.tipoOrcamento}</td>
                                                    <td>{item.venda === null ? 0 : config.currencyFix(item.venda)}</td>
                                                    <td>{item.locacao === null ? 0 : config.currencyFix(item.locacao)}</td>
                                                    <td>{item.instalacao === null ? 0 : config.currencyFix(item.instalacao)}</td>
                                                    <td>{item.manutencao === null ? 0 : config.currencyFix(item.manutencao)}</td>
                                                    <td>{item.projeto}</td>
                                                    <td>{config.dateFix(item.dataProjeto)}</td>
                                                    <td>{item.statusProjeto}</td>
                                                    <td>{config.dateFix(item.dataConclusaoProjeto)}</td>
                                                    <td>{item.origem}</td>
                                                    <td>{item.codigoObra}</td>
                                                    <td>{item.descricao}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        </Content>
    )
}
