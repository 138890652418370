import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config, Select } from '../index';

export function RequisicaoMaterialForm(props) {
    const endPoint = 'suprimentos/rm/';
    const [form, setForm] = useState([]);
    const [site, setSite] = useState('');

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === 'siteId' && value !== '') {
            obterInformacaoSite(value);
        }
        setForm({ ...form, [name]: value });
    }

    const fetchData = () => {
        apiHelper.get(`portal/crud/cliente/?filter=nomeFantasia::equal::VEOLINK`)
            .then(res => {
                if (res.data.code === 200) {
                    setForm({ ...form, clienteId: res.data.data[0].pkCliente });
                }
                else
                    toast.error('Atenção, não foi possível carregar os materiais!');
            }).catch(error => {
                toast.error(error);
            });
    }

    const obterInformacaoSite = (siteId) => {
        apiHelper.get(`portal/crud/uvw_siteNew?filter=Id::equal::${siteId}`)
            .then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data[0];
                    let enderecoSite = `Logradouro: ${data.logradouro}, Nº: ${data.numero}, Complemento: ${data.complemento}, Bairro: ${data.bairro}, Cidade: ${data.cidade}-${data.estado}`
                    setSite(enderecoSite);
                }
                else
                    toast.error('Atenção, não foi possível obter informações do site selecionado!');
            }).catch(error => {
                toast.error(error);
            });
    }

    useEffect(fetchData, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let d = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0, 0, 0, 0);
        if (new Date(form.dataNecessidade + " 00:00:00") < d) {
            toast.warn(`A data mínima permitida é ${config.dateFix(d)}`);
            return;
        }

        var obj = form;
        obj.SolicitanteId = "[user_id]";
        obj.status = "Rascunho";
        obj.origem = "Usuario";
        obj.origemId = "[user_id]";
        obj.guid = "";
        obj.localEntrega = site + ` Local Entrega: ${obj.localEntrega}`;

        apiHelper.post(endPoint, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                props.history.push({
                    pathname: `/suprimentos/rm/${res.data.data}`
                });
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Data Necessidade</label>
                    <input type="date" id="dataNecessidade" className="form-control" onChange={handleChange} required />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="name">Site</label>
                    <Select id="siteId" className="form-control" onChange={handleChange} refresh={form.clienteId} required>
                        <option value="">Selecione</option>
                        {form.clienteId && <option endpoint={'portal/crud/site/?filter=fkCliente::equal::' + form.clienteId} valuefield="pkSite" textfield="nome" />}
                    </Select>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-12">
                    <label htmlFor="name">Centro Custo</label>
                    <Select id="centroCusto" className="form-control" onChange={handleChange} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/PortalMetadata/?filter=categoria::equal::centrocusto" textfield="nome" valuefield="valor" />
                    </Select>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-12">
                    <label>Local Entrega</label>
                    <textarea id="localEntrega" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição</label>
                    <textarea id="descricao" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Cancelar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Criar Requisição</button>
                </div>
            </div>
        </form>
    )
}
