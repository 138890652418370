import React, { Component } from 'react';
import { Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

export class FieldGroup extends Component {
    constructor(props) {
        super(props);

        let chars = undefined;

        if (this.props.componentClass === 'textarea' && this.props.value !== null && this.props.value !== undefined && this.props.maxLength !== undefined)
            chars = this.props.maxLength - this.props.value.length;
        this.state = {
            remainingChars: chars
        }
    }

    label(label) {
        return <ControlLabel>{label}</ControlLabel>;
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.componentClass === 'textarea' && newProps.value !== null && newProps.value !== undefined && newProps.maxLength !== undefined)
            this.setState({ remainingChars: (newProps.maxLength - newProps.value.length) });
    }

    render() {
        const { col, id, label = undefined, bsSize, ...props } = this.props;

        let remainingCharsEnable = this.state.remainingChars !== undefined;
        let remainingCharsOverflow = this.state.remainingChars < 0;
        return col !== undefined && col > 0 ?
            <Col md={col}>
                <FormGroup controlId={id} bsSize={bsSize}>
                    {label !== undefined ? this.label(label) : ""}
                    <FormControl {...props} />
                    <ControlLabel style={{
                        display: (remainingCharsEnable) ? '' : 'none', float: 'right',
                        color: (remainingCharsEnable && remainingCharsOverflow) ? 'Red' : 'LightSteelBlue'
                    }} > {(remainingCharsEnable && remainingCharsOverflow) ? 'Excedidos ' + (-1) * this.state.remainingChars + ' Caracteres' : this.state.remainingChars + ' Caracteres Restantes'}</ControlLabel>
                </FormGroup>
            </Col> :
            <FormGroup controlId={id} bsSize={bsSize}>
                {label !== undefined ? this.label(label) : ""}
                <FormControl {...props} />
                <ControlLabel style={{
                    display: (remainingCharsEnable) ? '' : 'none', float: 'right',
                    color: (remainingCharsEnable && remainingCharsOverflow) ? 'Red' : 'LightSteelBlue'
                }} > {(remainingCharsEnable && remainingCharsOverflow) ? 'Excedidos ' + (-1) * this.state.remainingChars + ' Caracteres' : this.state.remainingChars + ' Caracteres Restantes'}</ControlLabel>
            </FormGroup>;
    }
}