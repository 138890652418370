import React, { useState } from 'react';
import { config } from '../..';

export function Table(props) {
    const data = props.data;
    const header = [];
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    if (data && data.length > 0) {
        Object.keys(data[0]).forEach((key) => {
            header.push(key);
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };


    return (
        <div class="table-responsive">
            <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                <thead>
                    <tr className="bg-light-blue color-palette">
                        {header.map((row, i) =>
                            <th key={i} className="text-center">{config.capitalize(row)}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data && data.slice(params.initRow, params.endRow).map((item, index) =>
                        <tr key={index}>
                            {header.map((key, j) =>
                                <td key={j} className="text-center">{typeof item[key] === 'string' ? (item[key].length > 40 ? (item[key].substring(0, 40) + "...") : item[key]) : item[key]}</td>
                            )}
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={header.length} className="p-0">
                            <div className="pull-left">
                                {renderPage()}
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}