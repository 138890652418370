import React, { useState, useEffect } from 'react';
import { Content, Link, config, apiHelper, Modal, toast, Select } from '../';
import { RequisicaoMaterialForm } from './RequisicaoMaterialForm';

export function Material(props) {
    const endPoint = 'suprimentos/material/';
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        dataRequisicao: '',
        dataNecessidade: '',
        status: '',
        solicitanteId: '',
        empresaVeolink: '',
        centroCusto: '',
        origem: '',
        localEntrega: '',
        descricao: ''
    });

    const [params, setParam] = useState({
        filter: [],
        rowId: 0,
        showModal: false,
        modalTitle: "",
        formName: "",

        pageSize: 8,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 8
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar as requisições');
            });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        let condition = value ? e.target.name + value : "";

        if (e.target.type === "date" && value) {
            condition = e.target.name + value;
            //adicionar data+1 e formatar as datas
            condition += ";;and;;" + e.target.name + value;
        }

        setFilter({ ...filter, [name]: condition });
        let obj = filter;
        obj[name] = condition;

        let where = "";
        let sep = "";

        Object.keys(obj).map(k => {
            if (obj[k]) {
                where += sep + obj[k];
                sep = ";;and;;";
            }

            return null;
        });

        fetchData(where);
    };

    const handleShowForm = (name, title, rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: ""
        });

        refresh && fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Requisição de Materiais" browserTitle="Requisição de Materiais">
            <div className="row">
                <div className="col-md-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab_grid" data-toggle="tab">Geral</a></li>
                            <li><a href="#tab_report" data-toggle="tab">Relatórios</a></li>
                        </ul>
                        <div className="tab-content">
                            <div id="tab_grid" className="tab-pane active">
                                <div style={{ marginBottom: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm("new", "Nova Requisição", 0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Nova Requisição</button>

                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>#</th>
                                                <th>ID</th>
                                                <th>Solicitação</th>
                                                <th>Necessidade</th>
                                                <th>Status</th>
                                                <th>Solicitante</th>
                                                <th>Empresa Origem</th>
                                                <th>Empresa Destino</th>
                                                <th>Centro de Custo</th>
                                                <th>Tipo</th>
                                                <th>Descricao</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><button type="button" className="btn btn-default btn-sm btn-flat"><i className="fa fa-refresh" /></button></td>
                                                <td><input id="id" style={{ width: "80px" }} name="id::equal::" type="number" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td><input id="dataRequisicao" name="dataRequisicao::equal::" type="date" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td><input id="dataNecessidade" name="dataNecessidade::equal::" type="date" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td>
                                                    <Select id="status" style={{ width: "120px" }} className="form-control input-sm" onChange={handleChange} required>
                                                        <option value="">Selecione</option>
                                                        <option endpoint="portal/crud/PortalMetadata/?filter=categoria::equal::SolicitacaoMaterialStatus" textfield="nome" valuefield="valor" />
                                                    </Select>
                                                </td>
                                                <td><input id="solicitante" name="solicitante::contains::" type="text" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td><input id="empresaVeolink" name="empresaVeolink::contains::" type="text" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td><input id="cliente" name="cliente::contains::" type="text" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td><input id="centroCusto" name="centroCusto::contains::" type="text" className="form-control input-sm" onChange={handleChange} /></td>
                                                <td>
                                                    <select id="origemTipo" name="origem::contains::" className="form-control input-sm" onChange={handleChange} required>
                                                        <option value=""></option>
                                                        <option value="usuario">Usuário</option>
                                                        <option value="os">Ordem de Serviço</option>
                                                        <option value="projeto">Projeto</option>
                                                    </select>
                                                </td>
                                                <td><input id="descricao" name="descricao::contains::" type="text" className="form-control input-sm" onChange={handleChange} /></td>
                                            </tr>
                                            {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                                .slice(params.initRow, params.endRow)
                                                .map((item, i) => {

                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ width: '50px' }}>
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" />
                                                                    </button>
                                                                    <ul className="dropdown-menu bg-gray disabled" role="menu">
                                                                        <li className="header"><center>{item.id}</center></li>
                                                                        <li className="divider" />
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td><Link to={'/suprimentos/rm/' + item.id}>{item.id}</Link></td>
                                                            <td>{config.dateTimeFix(item.dataRequisicao)}</td>
                                                            <td>{config.dateFix(item.dataNecessidade)}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.usuario}</td>
                                                            <td>{item.empresaVeolink}</td>
                                                            <td>{item.cliente}</td>
                                                            <td>{item.centroCusto}</td>
                                                            <td>{item.origemTipo}</td>
                                                            <td>{item.descricao}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div style={{ marginTop: "7px" }}>
                                    <button type="button" className="btn btn-default btn-sm btn-flat"><i className="fa fa-refresh" /> Atualizar</button>

                                    <div className="pull-right">
                                        {renderPage()}
                                    </div>
                                </div>
                            </div>
                            <div id="tab_report" className="tab-pane">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "new" && <RequisicaoMaterialForm {...props} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
