import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function FornecedorForm(props) {
    const endPoint = 'suprimentos/fornecedor';
    const rowId = props.rowId;
    const [uf, setUf] = useState(0);

    const [data, setData] = useState({
        nome: "",
        razaoSocial: "",
        tipo: "",
        cpfCnpj: "",
        inscricaoEstadual: "",
        inscricaoMunicipal: "",
        logradouro: "",
        numero: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        bairro: "",
        dddFax: "",
        fax: "",
        contato: "",
        contato2: "",
        telefone: "",
        telefone2: "",
        email: "",
        email2: "",
        codigoBanco: "",
        codigoAgencia: "",
        numeroConta: ""
    });

    const fetchData = () => {
        let obj = data;

        if (rowId && rowId !== 0) {
            apiHelper.get(`${endPoint}/${rowId}`)
                .then(res => {
                    let data = res.data;
                    setUf(data.uf);

                    Object.keys(data).map(p => obj[p] !== undefined ? obj[p] = data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    useEffect(fetchData, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "uf")
            setUf(value);
        if (e.target.maxLength > 0 && value.length > e.target.maxLength) {
            return;
        }

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        data.numero = data.numero.toString();
        if (!rowId || rowId === '') {
            apiHelper.post(endPoint, data).then(res => {
                config.httpResult(res);
                props.onHide(true);
            }).catch(error => {
                let mensagem = error.response.data.includes("Já existe um fornecedor") ? error.response.data : 'Atenção, não foi possível salvar o registro.';
                toast.error(mensagem);
            });
        } else {
            apiHelper.put(`${endPoint}/${rowId}`, data).then(res => {
                config.httpResult(res);
                props.onHide(true);
            });
        }
    }

    const onHide = (acao = false) => {
        props.onHide(acao);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Nome</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome || ''} required />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Razão Social</label>
                    <input id="razaoSocial" type="text" className="form-control" onChange={handleChange} value={data.razaoSocial || ''} required />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>CPF/CNPJ</label>
                    <input id="cpfCnpj" type="text" className="form-control" onChange={handleChange} value={data.cpfCnpj || ''} maxLength={14}/>
                </div>
                <div className="form-group col-md-6">
                    <label>Tipo</label>
                    <select id="tipo" className="form-control" onChange={handleChange} value={data.tipo || ''} required>
                        <option value="">Selecione</option>
                        <option value="F">Físico</option>
                        <option value="J">Jurídico</option>
                        <option value="X">Outros</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Inscrição Estadual</label>
                    <input id="inscricaoEstadual" type="text" className="form-control" onChange={handleChange} value={data.inscricaoEstadual || ''} />
                </div>
                <div className="form-group col-md-6">
                    <label>Inscrição Municipal</label>
                    <input id="inscricaoMunicipal" type="text" className="form-control" onChange={handleChange} value={data.inscricaoMunicipal || ''} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-8">
                    <label>Logradouro</label>
                    <input id="logradouro" type="text" className="form-control" onChange={handleChange} value={data.logradouro || ''} required />
                </div>
                <div className="form-group col-md-4">
                    <label>Número</label>
                    <input id="numero" type="number" className="form-control" onChange={handleChange} value={data.numero || ''} maxLength={6} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <label>Complemento</label>
                    <input id="complemento" type="text" className="form-control" onChange={handleChange} value={data.complemento || ''} />
                </div>
                <div className="form-group col-md-4">
                    <label>CEP</label>
                    <input id="cep" type="number" className="form-control" onChange={handleChange} value={data.cep || ''} required maxLength={8} />
                </div>
                <div className="form-group col-md-4">
                    <label>Bairro</label>
                    <input id="bairro" type="text" className="form-control" onChange={handleChange} value={data.bairro || ''} required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-sm-6">
                    <label>UF</label>
                    <Select id="uf" className="form-control" onChange={handleChange} value={data.uf || ''} required>
                        <option value="">Selecione</option>
                        <option endpoint="portal/crud/UF" valuefield="pkUf" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="name">Município</label>
                    <Select id="cidade" className="form-control" onChange={handleChange} value={data.cidade || ''} refresh={uf} required>
                        <option value="">Selecione</option>
                        <option endpoint={'portal/crud/cidade/?filter=fkUf::equal::' + uf} valuefield="pkCidade" textfield="nome" />
                    </Select>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-2">
                    <label>DDD(Fax)</label>
                    <input id="dddFax" type="number" className="form-control" onChange={handleChange} value={data.dddFax || ''} maxLength={4} />
                </div>
                <div className="form-group col-md-4">
                    <label>Fax</label>
                    <input id="fax" type="text" className="form-control" onChange={handleChange} value={data.fax || ''} />
                </div>

                <div className="form-group col-md-6">
                    <label>Contato</label>
                    <input id="contato" type="text" className="form-control" onChange={handleChange} value={data.contato || ''} required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <label>Contato 2</label>
                    <input id="contato2" type="text" className="form-control" onChange={handleChange} value={data.contato2 || ''} />
                </div>
                <div className="form-group col-md-4">
                    <label>Telefone</label>
                    <input id="telefone" type="text" className="form-control" onChange={handleChange} value={data.telefone || ''} />
                </div>
                <div className="form-group col-md-4">
                    <label>Telefone 2</label>
                    <input id="telefone2" type="text" className="form-control" onChange={handleChange} value={data.telefone2 || ''} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label>E-mail </label>
                    <input id="email" type="text" className="form-control" onChange={handleChange} value={data.email || ''} />
                </div>
                <div className="form-group col-md-6">
                    <label>E-mail 2</label>
                    <input id="email2" type="text" className="form-control" onChange={handleChange} value={data.email2 || ''} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-4">
                    <label>Cód. Banco</label>
                    <input id="codigoBanco" type="number" className="form-control" onChange={handleChange} value={data.codigoBanco || ''} maxLength={6} />
                </div>
                <div className="form-group col-md-4">
                    <label>Agência</label>
                    <input id="codigoAgencia" type="text" className="form-control" onChange={handleChange} value={data.codigoAgencia || ''} maxLength={10} />
                </div>
                <div className="form-group col-md-4">
                    <label>Num. Conta</label>
                    <input id="numeroConta" type="text" className="form-control" onChange={handleChange} value={data.numeroConta || ''} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => onHide(false)}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
