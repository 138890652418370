import React, { useState, useEffect } from 'react';
import { Link, config, apiHelper, toast } from '../';
import { RequisicaoMaterialView } from './RequisicaoMaterialView';

export function RequisicaoMaterial(props) {
    const endPoint = 'suprimentos/rm/';
    const parentId = props.parentId;
    const siteId = props.siteId;
    const [form, setForm] = useState({});
    const [data, setData] = useState([]);
    const allowEdit = props.allowEdit;

    const [params, setParam] = useState({
        rowId: 0,
        details: false,
        grid: true
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}origem/${parentId}/OS`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar as requisições');
            });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let d = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0, 0, 0, 0);
        if (new Date(form.dataNecessidade + " 00:00:00") < d) {
            toast.warn(`A data mínima permitida é ${config.dateFix(d)}`);
            return;
        }

        var obj = form;
        obj.SolicitanteId = "[user_id]";
        obj.status = "Rascunho";
        obj.origem = "OS";
        obj.codigoObra = Number(1000000) + Number(props.siteId)
        obj.origemId = props.parentId;
        obj.clienteId = props.clienteId;
        obj.siteId = props.siteId;
        obj.centroCusto = 'MANUTENÇÃO';
        obj.classificacaoContabil = '';

        if (form.localEntregaSite === 'Site') {
            obj.localEntrega = props.enderecoSite;
        } else if (form.localEntregaSite === 'Prestador/Regional') {
            obj.localEntrega = props.enderecoPrestador;
        }

        delete obj.localEntregaSite;

        obj.guid = "";

        apiHelper.post(endPoint, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                handleHide();
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    const handleDetails = (id) => {
        setParam({ ...params, rowId: id, grid: false, details: true });
    }

    const handleCancelarRequisicao = (id) => {
        if (!window.confirm("Deseja realmente cancelar esta requisição de material ?"))
            return;

        apiHelper.api_delete(`${endPoint}cancelar/${id}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleHide = () => {
        setParam({ ...params, rowId: 0, grid: true, details: false });
        fetchData();
    };

    return (
        <div className="row">
            <div className="col-md-12">
                {params.grid &&
                    <React.Fragment>
                        <form onSubmit={handleSubmit}>
                            {config.validarClaim('atendimento.rm.criar') && props.allowEdit &&
                                <React.Fragment>
                                    <div className="form-group col-sm-6">
                                        <label>Data Necessidade *</label>
                                        <input id="dataNecessidade" type="date" className="form-control" onChange={handleChange} required />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>Local Entrega Site *</label>
                                        <select id="localEntregaSite" className="form-control" onChange={handleChange} required>
                                            <option value="">Selecione</option>
                                            <option value={'Site'}>Site</option>
                                            <option value={'Prestador/Regional'}>Prestador/Regional</option>
                                            <option value={'Outro'}>Outro</option>
                                        </select>
                                    </div>
                                    {form.localEntregaSite === 'Outro' && <div className="form-group col-md-12">
                                        <label>Endereço de Entrega *</label>
                                        <textarea id="localEntrega" className="form-control" rows="3" onChange={handleChange} required />
                                    </div>}
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-default pull-right btn-flat" style={{ marginBottom: "7px" }}>Criar Requisição</button>
                                    </div>
                                </React.Fragment>
                            }
                        </form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped no-margin table-bordered">
                                        <thead>
                                            <tr className="bg-light-blue">
                                                <th>ID</th>
                                                <th>Status</th>
                                                <th>Solicitação</th>
                                                <th>Necessidade</th>
                                                <th>Solicitante</th>
                                                <th>Centro de Custo</th>
                                                <th>Cód Obra</th>
                                                {data.find(w => w.status === 'Rascunho') && <th></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.sort((a, b) => a.dataRequisicao < b.dataRequisicao ? 1 : -1).map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{allowEdit ? <Link replace to="#" onClick={() => handleDetails(item.id)}>{item.id}</Link> : item.id}</td>
                                                        <td>{item.status}</td>
                                                        <td>{config.dateTimeFix(item.dataRequisicao)}</td>
                                                        <td>{config.dateFix(item.dataNecessidade)}</td>
                                                        <td>{item.usuario}</td>
                                                        <td>{item.centroCusto}</td>
                                                        <td>{item.codigoObra}</td>
                                                        {item.status === 'Rascunho' && allowEdit ? <td align="center"><button type="button" className="btn btn-default btn-sm btn-flat btn-block" onClick={() => handleCancelarRequisicao(item.id)}>Cancelar</button>
                                                        </td> : false}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }

                {params.details && <RequisicaoMaterialView parentId={params.rowId} siteId={siteId} onHide={handleHide} />}
            </div>
        </div>
    )
}
