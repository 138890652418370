import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

export const Menu = (props) => {
    const { pathname } = useLocation();
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        setMenu(props.data);
    }, [props.data]);

    const renderMenu = (data) => {
        return data && data.sort((a, b) => a.order - b.order)
            .map((item, i) => {
                const { url, name, icon, children } = item;

                let hasChildren = (children && children.length > 0) && children.filter(f => f.url === pathname).length;
                let selected = (pathname === url || hasChildren);

                return (
                    <li key={i} className={`nav-item${selected ? ' menu-is-opening menu-open' : ''}`}>
                        <Link replace to={(children && children.length > 0) ? '#' : url} className={`nav-link${selected ? ' active' : ''}`}>
                            <i className={`nav-icon ${icon ? icon : 'far fa-circle'}`} />
                            <p>
                                {name}
                                {(children && children.length > 0) && <i className="right fas fa-angle-left" />}
                            </p>
                        </Link>

                        {(children && children.length > 0) &&
                            <ul className="nav nav-treeview" style={{ display: selected ? 'block' : 'none' }}>
                                {renderMenu(children)}
                            </ul>
                        }
                    </li>
                )
            });
    }

    return (
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
                {renderMenu(menu)}
            </ul>
        </nav>
    );
};
