import React, { useEffect, useState } from 'react';
import { apiHelper, toast, Modal } from '../../index';
import { BuscarGrupo } from './BuscarGrupo';

export function UsuarioGrupo(props) {
    const endPoint = 'portal/crud/rpt_GroupByNameUser';
    const endPointUser = 'identity/User';
    const parentId = props.parentId;
    const userName = props.userName;
    const [data, setData] = useState([]);

    const [filter, setFilter] = useState({
        id: '',
        name: '',
    });
    const [params, setParam] = useState({
        showModal: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=usuario::equal::${userName};;AND;;${where}`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(fetchData, [userName]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'name')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleNew = () => {
        setParam({ ...params, showModal: true });
    };

    const handleHide = (refresh) => {
        setParam({ ...params, showModal: false });
        refresh && fetchData();
    };

    const handleDelete = (item) => {
        apiHelper.api_delete(`${endPointUser}/${userName}/group/${item.name}`).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleRefresh = () => {
        setFilter({
            id: '',
            name: '',
        });
        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ marginBottom: "7px" }}>
                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar </button>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-striped" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th style={{ width: "50px" }}>#</th>
                                    <th>Id</th>
                                    <th>Nome</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /></button></td>
                                    <td><input id="id" type="text" className="form-control input-sm" onChange={handleChange} value={filter.id} /></td>
                                    <td><input id="name" type="text" className="form-control input-sm" onChange={handleChange} value={filter.name} /></td>
                                    <td></td>
                                </tr>
                                {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                    .slice(params.initRow, params.endRow)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '50px' }}>#</td>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td><button type="button" className="btn btn-danger btn-sm" onClick={() => { handleDelete(item) }}>Remover</button></td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "7px" }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Grupo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.showModal && <BuscarGrupo parentId={parentId} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
