import React, { Component } from 'react';
import { Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

export default class FormField extends Component {
    render() {
        const { col = 0, id, label = undefined, bsSize, ...props } = this.props;
        return col > 0 || col !== 12 ?
            <Col md={col}>
                <FormGroup controlId={id} bsSize={bsSize}>
                    {label !== undefined && <ControlLabel>{label}</ControlLabel>}
                    <FormControl {...props} />
                </FormGroup>
            </Col> :
            <FormGroup controlId={id} bsSize={bsSize}>
                {label !== undefined && <ControlLabel>{label}</ControlLabel>}
                <FormControl {...props} />
            </FormGroup>;
    }
}
