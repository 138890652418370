import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';

export function ProvisionamentoForm(props) {
    const endPoint = 'v2/projeto/provisionamento';
    const projetoId = props.projetoId;
    const provisionamentoId = props.provisionamentoId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {};

        if (provisionamentoId && provisionamentoId !== '') {
            apiHelper.get(`${endPoint}/${provisionamentoId}`)
                .then(res => {
                    let data = res.data.data;
                    if (data.dataProvisionamento !== null)
                        data.dataProvisionamento = `${data.dataProvisionamento.split('-')[0]}-${data.dataProvisionamento.split('-')[1]}-${data.dataProvisionamento.split('-')[2].substr(0, 2)}`;
                    Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                    setData(obj);

                }).catch(error => {
                    toast.error('');
                });
        }
    }

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "file") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));

                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setData({ ...data, file: null, anexo: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                apiHelper.toBase64(file)
                    .then((base) => {
                        setData({ ...data, file: base, anexo: file.name });
                    });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let partes = data.dataProvisionamento.split('-');
        let date = new Date(parseInt(partes[0]), parseInt(partes[1]) - 1, parseInt(partes[2]));
        if (date < new Date()) {
            window.alert('Data de provosionamento inválida! (Deve ser superior a hoje.)');
            return;
        }

        data.criadorId = '[user_id]';
        data.projetoId = projetoId;
        if (provisionamentoId === '') {
            apiHelper.post(endPoint, data)
                .then(res => {
                    if (res.data.code === 200) {
                        toast.success('Dados salvos com sucesso!');
                        props.onHide(true);
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${provisionamentoId}`, data).then(res => {
                config.httpResult(res.data);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Tipo</label>
                    <Select id="tipo" className="form-control input-sm" onChange={handleChange} value={data.tipo || ''} required>
                        <option value="">Selecione</option>
                        <option endpoint={`portal/crud/PortalMetadata?filter=Categoria::equal::TipoProvisionamento`} valuefield="valor" textfield="nome" />
                    </Select>
                </div>
                <div className="form-group col-md-4">
                    <label>Valor</label>
                    <input type="number" step=".01" id="valor" className="form-control input-sm" onChange={handleChange} value={data.valor || ''} required />
                </div>
                <div className="form-group col-sm-4">
                    <label>Provisionado Para</label>
                    <input id="dataProvisionamento" type="date" className="form-control" onChange={handleChange} value={data.dataProvisionamento || ''} required />
                </div>
                <div className="form-group col-md-12">
                    <label> Descrição </label>
                    <textarea id="descricao" className="form-control input-sm" rows="3" maxLength="500" onChange={handleChange} value={data.descricao || ''} required />
                </div>
                <div className="form-group col-md-12">
                    <label> Observação </label>
                    <textarea id="observacao" className="form-control input-sm" rows="3" maxLength="500" onChange={handleChange} value={data.observacao || ''} />
                </div>
                <div className="form-group col-md-12">
                    <label>Anexo</label>
                    <input type="file" id="file" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    );
}

