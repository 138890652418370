import React, { useState, useEffect } from 'react';
import { Content, apiHelper } from '../index';
import { DashboardForm } from './DashboardForm';
import { Dashboard } from './Dashboard';

export function DashboardAdm(props) {
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const [reports, setReports] = useState([]);
    const [params, setParam] = useState({ showEdit: false });

    useEffect(() => {
        const fetchData = async () => {
            let res = await apiHelper.get(`portal/crud/ReportsDashboard/?filter=`)
            setData(res.data.data)
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let res = await apiHelper.get(`portal/crud/Report/?filter=`)
            setReports(res.data.data)
        }

        fetchData();
    }, []);

    const handleNew = () => {
        setRow({})
        setParam({ ...params, showEdit: true })
    }

    const handleHide = () => {
        setParam({ ...params, showEdit: false })
        setRow({})
    }

    const handleSelectDashboard = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];

        handleHide();

        if (value) {
            let obj = data.filter(f => f.id.toString() === value.toString())
            setRow(obj[0]);
        }
    }

    return (
        <Content title="Dashboards" browserTitle="Dashboards">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-primary card-outline">
                        <div className="card-header p-2">
                            <div className="float-left">
                                <div className="btn-group mr-1">
                                    <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-default"><i className="fas fa-tachometer-alt" /></button>
                                        </div>
                                        <select name="report" className="form-control form-control-sm" onChange={handleSelectDashboard} value={row.id || ""}>
                                            <option value="">Selecione</option>
                                            {data.sort((a, b) => a.name < b.name ? -1 : 1).map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <button className="btn btn-default btn-sm mr-1" disabled={!row.id} onClick={() => setParam({ ...params, showEdit: true })}><i className="fas fa-edit" /> Editar</button>
                                <button className="btn btn-default btn-sm mr-1" onClick={handleHide}><i className="fas fa-sync-alt" /></button>
                            </div>
                            <div className="float-right">
                                <div className="btn-group">
                                    <button className="btn btn-primary btn-sm mr-1" onClick={handleNew}>Criar Dashboard</button>
                                </div>
                            </div>
                        </div>
                        {params.showEdit &&
                            <div className="card-body bg-light p-2">
                                <DashboardForm parentId={row.id} onHide={handleHide} />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                {params.showEdit2 &&
                    <div className="col-md-3">
                        <div className="card card-primary card-outline">
                            <div className="card-header">
                                <h3 className="card-title">Relatórios</h3>
                                <div className="card-tools">

                                </div>
                            </div>
                            <div className="card-body p-1" style={{ maxHeight: 300, overflow: 'auto' }}>
                                <ul className="todo-list ui-sortable" data-widget="todo-list">
                                    {reports.length > 0 && reports.sort((a, b) => a.nome < b.nome ? -1 : 1).map((item, i) =>
                                        <li key={i}>
                                            <span className="handle ui-sortable-handle">
                                                <i className="fas fa-ellipsis-v" />
                                                <i className="fas fa-ellipsis-v" />
                                            </span>
                                            <span className="text">{item.nome}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }

                <div className={`col-md-${params.showEdit ? 12 : 12}`}>
                    {row.id && <Dashboard id={row.id} admin={params.showEdit} />}
                </div>
            </div>
        </Content>
    )
}