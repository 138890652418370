import React, { useState, useEffect } from 'react';
import { Content, config, apiHelper, toast, Modal, Link } from '../';
import { PrestadorForm } from './PrestadorForm';

export function Prestador(props) {
    const endPoint = 'v2/prestador/';
    const [data, setData] = useState([]);
    const [params, setParam] = useState({
        rowId: 0,
        showModal: false,
        modalTitle: 'Novo Prestador',
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const [filter, setFilter] = useState({
        pkPrestador: '',
        nome: '',
        cnpj: '',
        responsavel: ''
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        try {
            apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
                setData(res.data);
            });
        } catch (e) {
            toast.error('Atenção, não foi possível carregar os registros');
        }
    };

    const onLoad = () => {
        config.validarPermissao('MenuPrestador');
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });
    };

    const handleSearch = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setFilter({ ...filter, [name]: value });

        let where = '';
        Object.entries(filter).map(([key, value2]) => {
            let operador = 'equal';
            if (key === 'nome' || key === 'responsavel')
                operador = 'contains';

            if (key !== name) {
                if (value2 && value2 !== '' && !key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::${operador}::${value2}`;
                } else if (value2 && value2 !== '' && key.includes('data')) {
                    if (where !== '') {
                        where += ';;AND;;';
                    }
                    where += `${key}::greaterEqual::${value2};;AND;;${key}::lessEqual::${value2} 23:59:59;;`;
                }
            } else if (value && value !== '') {
                if (where !== '') {
                    where += ';;AND;;';
                }
                if (key.includes('data')) {
                    where += `${name}::greaterEqual::${value};;AND;;${name}::lessEqual::${value} 23:59:59;;`;
                } else
                    where += `${name}::${operador}::${value}`;
            }
            return null;
        });

        fetchData(where);
    };

    const handleRefresh = () => {
        setFilter({
            pkPrestador: '',
            nome: '',
            cnpj: '',
            responsavel: ''
        });
        setData([]);
        fetchData();
    };

    const handleShowForm = (rowId) => {
        setParam({
            ...params,
            rowId: rowId || 0,
            showModal: true,
            modalTitle: !rowId || rowId === 0 ? 'Novo Prestador' : 'Editar Prestador'
        });
    };

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: ""
        });

        refresh && fetchData();
    };

    const handleAtivarInativar = (id, status) => {
        let texto = status ? 'inativar' : 'ativar';
        if (!window.confirm(`Deseja realmente ${texto} este registro ?`))
            return;

        apiHelper.api_delete(`${endPoint}/${id}`).then(res => {
            config.httpResult(res.data);
            fetchData('');
        }).catch(error => {
            toast.error('Atenção, não foi possível ativar/inativar o registro.');
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Prestadores" browserTitle="Prestadores">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div style={{ marginBottom: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm(0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Prestador</button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                        Pesquisar <span className="caret" />
                                    </button>
                                    <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 800, padding: 5 }} onClick={e => e.stopPropagation()}>
                                        <div className="box" style={{ margin: 0 }}>
                                            <div className="box-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label>ID</label>
                                                                <input id="pkPrestador" type="text" className="form-control input-sm" onChange={handleChange} value={filter.pkPrestador} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Nome</label>
                                                                <input id="nome" type="text" className="form-control input-sm" onChange={handleChange} value={filter.nome} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>CNPJ</label>
                                                                <input id="cnpj" type="text" className="form-control input-sm" onChange={handleChange} value={filter.cnpj} />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Responsável</label>
                                                                <input id="responsavel" type="text" className="form-control input-sm" onChange={handleChange} value={filter.responsavel} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-footer" style={{ padding: 5 }}>
                                                <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className="bg-light-blue color-palette">
                                            <th style={{ width: "50px" }}>#</th>
                                            <th style={{ width: "120px" }}>ID</th>
                                            <th>Nome</th>
                                            <th>CNPJ</th>
                                            <th>Responsável</th>
                                            <th>Situação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td style={{ width: '50px' }}>
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </button>
                                                                <ul className="dropdown-menu" role="menu">
                                                                    <li><Link replace to="#" onClick={() => handleShowForm(item.id)}><i className="fa fa-edit" /> Editar</Link></li>
                                                                    <li><Link replace to="#" onClick={() => handleAtivarInativar(item.id, item.status)}><i className={"fa " + (item.status ? "fa-minus-square" : "fa-check-square")} /> {item.status ? 'Desativar' : 'Ativar'}</Link></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><Link to={'/prestador/' + item.id}>{item.id}</Link></td>
                                                        <td>{item.nome}</td>
                                                        <td>{item.cnpj}</td>
                                                        <td>{item.responsavel}</td>
                                                        <td>{item.status ? 'Ativo' : 'Inativo'}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ marginTop: "7px" }}>
                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>
                                <div className="pull-right">
                                    {renderPage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={params.showModal} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrestadorForm onHide={(acao) => handleHide(acao)} rowId={params.rowId} />
                </Modal.Body>
            </Modal>
        </Content>
    )
}
