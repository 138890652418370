import React from 'react';
import { Content } from '../index';
import * as Widgets from './widgets';

export function AtendimentoPanel(props) {
    const [selected, setSelected] = React.useState(0);

    React.useLayoutEffect(() => {
        //document.body.classList.add('sidebar-collapse');
    }, []);

    const handleSelect = (e) => {
        e && setSelected(e);
    }

    return (
        <Content title="Atendimento" browserTitle="Ordens de Serviço">
            <div className="row">
                <div className="col-md-12">
                    <Widgets.TicketResumo />
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <Widgets.TicketGeral onSelect={handleSelect} />
                    {/* <Widgets.MapaGeral /> */}
                </div>
                <div className="col-md-7">
                    <Widgets.TicketView id={selected} />
                    <Widgets.Tecnicos />
                </div>
            </div>
        </Content>
    )
}
