import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function PercentQtdSolicitacoesDentroFora(props) {
    const endPoint = 'atendimentoReport/percentQtdSolicitacoes';
    const [data, setData] = useState([]);
    const title = 'Quantidade de % Solicitações Dentro x Fora';
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: {
            mode: 'point',
            callbacks: {
                label: (tooltipItem) => {
                    return `${tooltipItem.value}%`
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 20 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!dataEntradaInicio && !dataEntradaFim)
            return;

        let values = [];
        let labels = ['% Dentro', '% Fora'];

        let dentroSLA = data.find(c => c.nome === 'Dentro') !== undefined ? data.find(c => c.nome === 'Dentro').valor : 0;
        let foraSLA = data.find(c => c.nome === 'Fora') !== undefined ? data.find(c => c.nome === 'Fora').valor : 0;
        let total = dentroSLA + foraSLA;
        let percentDentroSLA = total !== 0 ? Math.round((dentroSLA * 100) / total) : 0;
        let percentForaSLA = total !== 0 ? Math.round((foraSLA * 100) / total) : 0;

        values.push(percentDentroSLA);
        values.push(percentForaSLA);

        let datasets = [
            { label: 'Solicitação', backgroundColor: '#A5D55F', borderWidth: 1, data: values },
        ];

        const dataAux = {
            labels: labels,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
                {data.length > 0 &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
