import React, { useEffect, useState } from 'react';
import { config, apiHelper, toast, Modals } from '../index';
import { EquipamentoForm } from './EquipamentoForm';

export function EquipamentoTable(props) {
    const endPoint = 'comercial/site/';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [params, setParams] = useState({
        showModal: false,
        pageSize: 10,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = () => {
        apiHelper.get(`${endPoint}equipamento/${parentId}`)
            .then(res => {
                console.log(res.data);
                setData(res.data.data);
            });
    };

    useEffect(fetchData, [parentId]);

    const handleNew = () => {
        setParams({
            ...params,
            showModal: true,
            rowId: 0,
            pageSize: 10,
            totalPages: 0,
            selectedPage: 0,
            initRow: 0,
            endRow: 10

        });
    }

    const handleHide = (refresh) => {
        setParams({
            ...params, parentId: -1, showModal: false,
            pageSize: 10,
            totalPages: 0,
            selectedPage: 0,
            initRow: 0,
            endRow: 10
        });

        refresh && fetchData();
    };

    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ? "))
            return;

        apiHelper.api_delete(`${endPoint}equipamento/${id}`).then(res => {
            config.httpResult(res.data);

            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParams({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <React.Fragment>

            <div style={{ marginBottom: "7px" }}>
                {(config.validarClaim('site.escrita')) ? <button type="button" className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
                    : <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>}

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped no-margin table-bordered">
                    <thead>
                        <tr className="bg-light-blue">
                            {config.validarClaim('site.escrita') && <th>Ação</th>}
                            <th>Id</th>
                            <th>Equipamento</th>
                            <th>Tipo</th>
                            <th>Responsável Cadastro</th>
                            <th>Data Cadastro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.sort((a, b) => a.id < b.id ? 1 : -1)
                            .slice(params.initRow, params.endRow)
                            .map((item, i) => {
                                return (
                                    <tr key={i}>
                                        {config.validarClaim('site.escrita') && <td>
                                            <button type="button" className="btn btn-box-tool" title="Excluir" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /></button>
                                        </td>}
                                        <td>{item.identificador}</td>
                                        <td>{item.equipamento}</td>
                                        <td>{item.tipo}</td>
                                        <td>{config.formatName(item.usuario)}</td>
                                        <td>{config.dateFix(item.dataCadastro)}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: "7px" }}>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={fetchData}><i className="fa fa-refresh" /> Atualizar</button>

                <div className="pull-right">
                    {renderPage()}
                </div>
            </div>
            {params.showModal &&
                <Modals title={params.rowId === 0 ? 'Novo Equipamento' : 'Editar Material'} show={params.showModal} onHide={handleHide}>
                    <div className="row">
                        <div className="col-sm-12">
                            <EquipamentoForm parentId={params.rowId} onHide={handleHide} siteId={parentId} />
                        </div>
                    </div>
                </Modals>
            }
        </React.Fragment>
    );
}
