import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function PercentPreventivasRealizadas(props) {
    const endPoint = 'atendimentoReport/percentManutencaoPreventiva';
    const [data, setData] = useState([]);
    const title = '% Preventivas Realizadas';
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            let data = res.data.data;
            setData(data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: {
            mode: 'point',
            callbacks: {
                label: (tooltipItem) => {
                    return `${tooltipItem.value}%`
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 20 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!dataEntradaInicio && !dataEntradaFim)
            return;

        let valuesRealizados = [];
        let valuesPrevistas = [];
        let labels = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
        labels.map((mes) => {


            let valorPrevistas = data.previsto.find(c => c.mes === mes) !== undefined ? data.previsto.find(c => c.mes === mes).qtdManutencaoPreventiva : 0;
            let valorRealizadas = data.realizado.find(c => c.mes === mes) !== undefined ? data.realizado.find(c => c.mes === mes).qtdManutencaoRealizada : 0;

            let percentPrevista = valorRealizadas !== 0 ? Math.round((valorPrevistas * 100) / valorRealizadas) : 0;
            let percentRealiza = valorPrevistas !== 0 ? Math.round((valorRealizadas * 100) / valorPrevistas) : 0;
            valuesPrevistas.push(percentPrevista);
            valuesRealizados.push(percentRealiza);

            return null;
        });

        let datasets = [
            { label: '% Previsto', backgroundColor: '#3e71c4', borderWidth: 1, data: valuesPrevistas },
            { label: '% Realizado', backgroundColor: '#F44343', borderWidth: 1, data: valuesRealizados },
        ];

        const dataAux = {
            labels: labels,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
                {((data.previsto && data.previsto.length > 0) || (data.realizado && data.realizado.length > 0)) &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
