import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../index';
import moment from 'moment-timezone';

export function CronogramaTarefaForm(props) {
    const endPoint = 'v2/projeto/tarefa';
    const rowId = props.rowId;
    const [data, setData] = useState({});
    const [anexo, setAnexo] = useState({
        folder: config.getFolderModuloProjeto(),
        fileName: '',
        file: {}
    });

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId !== '') {
            apiHelper.get(`${endPoint}/${rowId}`)
                .then(res => {
                    let data = res.data.data;

                    if (data.dataInicioProgramado !== null)
                        data.dataInicioProgramado = `${data.dataInicioProgramado.split('-')[0]}-${data.dataInicioProgramado.split('-')[1]}-${data.dataInicioProgramado.split('-')[2].substr(0, 2)}`;
                    if (data.dataFimProgramado !== null)
                        data.dataFimProgramado = `${data.dataFimProgramado.split('-')[0]}-${data.dataFimProgramado.split('-')[1]}-${data.dataFimProgramado.split('-')[2].substr(0, 2)}`;
                    
                    Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                    setData(obj);
                }).catch(error => {
                    toast.error('');
                });
        }
    }

    const onLoad = () => {
        fetchData();
    }

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        if (name === "anexo") {
            let file = e.target.files[0];

            if (file !== undefined && file !== null) {
                const sizeMB = Math.round((file.size / 1024));
                if (sizeMB >= 20096) {
                    e.target.value = null;
                    setAnexo({ ...anexo, folder: '', file: '' });
                    toast.warn('Não é permitido anexo acima de 20MB.');
                    return;
                }

                let fileName = moment(new Date()).format('YYYYMMDDhhmmss_') + file.name;
                setAnexo({ ...anexo, folder: anexo.folder, fileName: fileName, file: file });
                setData({ ...data, [name]: fileName });
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (anexo.fileName !== '') {
            apiHelper.upload('anexo/upload', anexo.file, anexo.folder, anexo.fileName).then(res => {
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o arquivo!');
            });
        }

        data.projetoId = props.projetoId;
        data.cronogramaId = props.cronogramaId;

        if (rowId === '') {
            data.criadorId = '[user_id]';

            apiHelper.post(endPoint, data)
                .then(res => {
                    if (res.data.code === 200) {
                        toast.success('Dados salvos com sucesso!');
                        props.onHide(true);
                    }
                    else if (res.code === 400)
                        toast.warn(res.data.data);
                    else
                        toast.error('Atenção, não foi possível salvar o registro.');
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}`, data).then(res => {
                config.httpResult(res.data);
                props.onHide(true);
            }).catch(error => {
                toast.error('Atenção, não foi possível salvar o registro.');
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="nome" type="text" className="form-control" onChange={handleChange} value={data.nome} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Data Início Programado *</label>
                    <input id="dataInicioProgramado" type="date" className="form-control" onChange={handleChange} value={data.dataInicioProgramado} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Data Fim Programado *</label>
                    <input id="dataFimProgramado" type="date" className="form-control" onChange={handleChange} value={data.dataFimProgramado} required />
                </div>                
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="descricao" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.descricao} required />
                </div>
                <div className="form-group col-sm-6">
                    <label>Status *</label>
                    <Select id="status" className="form-control" onChange={handleChange} value={data.status} required>
                        <option value="">Selecione</option>
                        <option value="Finalizada">Finalizada</option>
                        <option value="Pendente">Pendente</option>
                        <option value="Progresso">Progresso</option>
                    </Select>
                </div>
                <div className="form-group col-sm-12">
                    <label>Anexo</label>
                    <input type="file" id="anexo" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                </div>
            </div>
        </form>
    );
}

