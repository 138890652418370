import React, { Component } from 'react';
import {
    axios, Modals, FieldGroup, Table, Button, FormGroupButton
} from '../adminlte/AdminLTE';

export default class ModalSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
            selectedItem: '',
            data: [],
            isSaving: false,
            showModal: false
        };

        this.handleSearch = this.handleSearch.bind(this);
    }

    getApiData(filter) {
        const { method = 'GET', url, headers, filterName } = this.props;
        let filterUrl = filterName !== undefined && `?${filterName}=${filter}`;

        axios({
            method: method, url: `${url}${filterUrl}`,
            headers: headers
        }).then(response => {
            this.setState({ data: response.data })
        });
    }

    handleSearch(e) {
        let value = e === null ? '' : e.target.value;
        this.setState({ searchValue: value });

        if (value.length > 2)
            this.getApiData(value);
        else
            this.setState({ data: [] });
    }

    onSelect(row) {
        const { rowName = "name" } = this.props;

        this.setState({ selectedItem: row[rowName] });
        this.props.onSelect(row);
        this.setState({ showModal: false, data: [], searchValue: '' });
    }

    onHide() {
        this.setState({ showModal: false, data: [], searchValue: '' });
    }

    render() {
        const { isSaving, data, showModal, selectedItem } = this.state;
        const { label, rowName = "name" } = this.props;

        return (
            <React.Fragment>
                <FormGroupButton placeholder="Selecione" label={label} disabled value={selectedItem}
                    buttonRight={<Button bsStyle="success" type="button" onClick={() => this.setState({ showModal: true })}>Selecionar</Button>} />

                {showModal &&
                    <Modals size="sm" title="Buscar Itens" show={showModal} onHide={() => this.onHide()} footer={
                        <Button bsStyle="success" type="button" onClick={() => this.onHide()}>Cancelar</Button>}
                    >
                        <FieldGroup type="text" onChange={this.handleSearch} value={this.state.searchValue} />
                        <Table striped bordered hover>
                            <tbody>
                                {data.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{row[rowName]}</td>
                                            <td style={{ width: "50px" }}>
                                                <Button bsSize="xsmall" bsStyle="link" disabled={isSaving} onClick={() => this.onSelect(row)}>
                                                    {isSaving ? 'Adicionando...' : 'Selecionar'}
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Modals>}
            </React.Fragment>
        );
    }
}