import React, { useState, useEffect } from 'react'
import { Button, Modal } from '../Core';
import { ValidaForm } from '../../components/controls/ValidaForm';
import { toast } from 'react-toastify';

const data = {
    motivoCancelamento: 'Motivo',
};

export function OrdemServicoMotivoCancelarMaterial(props) {
    const [form, setForm] = useState({
        serviceOrderCode: 0,
        idSolicitacao: 0,
        motivoCancelamento: '',
        fkAprovacaoMaterial: 0
    });

    const [erros, setErros] = useState('');

    const loadData = () => {
        setForm({
            serviceOrderCode: props.row.serviceOrderCode,
            idSolicitacao: props.row.idSolicitacao,
            fkAprovacaoMaterial: props.row.fkAprovacaoMaterial,
            motivoCancelamento: ''
        });
    }

    useEffect(loadData, [props.row]);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.id]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        fetch(localStorage.getItem('apiUrl') + 'siga/serviceOrder/cancelarMaterial', {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: { 'Content-Type': 'application/json', 'Authorization': props.config.token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Material reprovado com sucesso!');
                props.getMateriaisSolicitados(form.serviceOrderCode);
            }
            else {
                toast.warn(response[1].msg);
            }
        }).catch(error => {
            toast.error(error.response.data.msg);
        });

        handleClose('');
    }

    const getKeys = (data) => {
        return Object.keys(data);
    }

    const handleValida = (e) => {
        e.preventDefault();

        let keysState = getKeys(form);
        let cont = 0;
        let listErros = [];
        const arrayData = Object.entries(data);

        arrayData.map(([key, value]) => {
            for (var j = 0; j < keysState.length; j++) {

                if ((key === keysState[j] && form[keysState[j]] === '') || (key === keysState[j] && form[keysState[j]] === 'Selecione')) {
                    listErros[cont] = value;
                    cont++;
                }

            }
            return null;
        });

        if (listErros.length > 0) {
            setErros(listErros);
            return;
        }

        setErros([]);
        handleSubmit(e);
    }

    const handleClose = (newId) => {
        props.onHide(newId);
    };


    return (
        <Modal show={props.show} onHide={handleClose} backdrop="static">
            <form onSubmit={handleValida}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Motivo do cancelamento'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ValidaForm array={erros} />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Motivo</label>
                                <textarea id="motivoCancelamento" className="form-control" rows="3" onChange={handleChange} value={form.motivoCancelamento || ''}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button bsStyle="secondary" onClick={handleClose} className="pull-left">Fechar</Button>
                    <Button type="submit" bsStyle="danger">Cancelar Material</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
