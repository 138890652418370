import React, { Component } from 'react';

import {
    uuidv4, Row, Col, Table, Button,
    ButtonToolbar, Modals, FieldGroup, FasIcon
} from '../../components/adminlte/AdminLTE';

import { toast } from 'react-toastify';

export class DeviceChannel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            isSaving: false,
            table: [],
            showForm: false,
            name: '',
            channelNumber: '',
            serialNumber: 0,
            channelNumberOld: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeRowValue = this.changeRowValue.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ keyValue: newProps.keyValue });
        this.getData(newProps.keyValue);
    }

    getData(serialNumber) {
        if (serialNumber === '' || serialNumber === undefined)
            return;

        this.setState({ table: [] });
        const { apiUrl, token } = this.props.config;

        fetch(`${apiUrl}${serialNumber}/channel?serialNumber=${serialNumber}`, {
            headers: { 'Authorization': token }
        }).then(
            response => response.json()
        ).then(data => {
            this.setState({ table: data });
        });
    }

    handleSubmit() {
        if (this.state.name === '' || this.state.channelNumber === '') {
            toast.warn('Informe os campos!');
            return;
        }

        if (this.state.channelNumber === 0) {
            toast.warn('O n�mero de canal deve ser maior do que 0!')
            return;
        }

        const existChannelNumber = this.state.table.some(p => p.channelNumber === this.state.channelNumber);

        if (existChannelNumber) {
            toast.warn('O n�mero do canal informado j� existe!');
            return;
        }

        const { apiUrl, token } = this.props.config;

        var objChannel = {
            "name": this.state.name,
            "channelNumber": this.state.channelNumber
        };

        var serialNumber = this.props.keyValue;

        fetch(apiUrl + serialNumber + "/channel", {
            method: 'POST',
            body: JSON.stringify(objChannel, serialNumber),
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados salvos com sucesso!');
                this.onHide();
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    deleteChannel(e) {
        const { apiUrl, token } = this.props.config;
        var serialNumber = this.props.keyValue;
        var url = apiUrl + serialNumber + "/channel/" + e.channelNumber;

        this.setState({ isSaving: true });

        fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                toast.success('Dados excluidos com sucesso!');
                this.getData(serialNumber);
            }
            else
                toast.warn(response[1]["msg"]);

            this.setState({ isSaving: false });
        }).catch(error => {
            toast.error(error);
            this.setState({ isSaving: false });
        });
    }

    handleChange(e) {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        this.setState({ [name]: value });
    }

    changeRowValue(e) {
        var channelNumber = e.target.parentElement.id;

        var objChannel = {
            "name": e.target.textContent,
            "channelNumber": channelNumber,
            "serialNumber": this.props.keyValue,
            "channelNumberOld": channelNumber
        };

        this.editChannel(objChannel);
    }

    editChannel(objChannel) {
        const { apiUrl, token } = this.props.config;

        fetch(apiUrl + "/" + objChannel.serialNumber + "/channel/" + objChannel.channelNumberOld, {
            method: 'PUT',
            body: JSON.stringify(objChannel),
            headers: { 'Content-Type': 'application/json', 'Authorization': token }
        }).then(response => {
            return Promise.all([response.status, response.json()]);
        }).then((response) => {
            if (response[0] === 200) {
                this.getData(objChannel.serialNumber);
            }
            else {
                toast.warn(response[1]["msg"]);
            }
        }).catch(error => {
            toast.error(error);
        });
    }

    ShowModal() {
        this.setState({ name: '', channelNumber: '', showForm: true });
    }

    onHide() {
        this.setState({ showForm: false });
        this.getData(this.props.keyValue);
    }

    render() {
        const { isSaving } = this.state;
        return (
            <React.Fragment>
                <Button bsSize="small" bsStyle="default" onClick={this.ShowModal.bind(this)}>Novo Canal</Button>
                <Table striped bordered hover>
                    <tbody>
                        {this.state.table.sort((a, b) => a.channelNumber - b.channelNumber).map(row => {
                            return (
                                <tr key={uuidv4()} id={row.channelNumber}>
                                    <td style={{ width: "30px" }}>{row.channelNumber}</td>
                                    <td suppressContentEditableWarning contentEditable onBlur={(e) => this.changeRowValue(e)}>{row.name}</td>
                                    <td style={{ width: "40px" }}><Button bsSize="xs" bsStyle="danger" disabled={isSaving} onClick={() => this.deleteChannel(row)}><FasIcon name="trash" /></Button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Modals title="Adicionar canais" show={this.state.showForm} onHide={() => this.onHide()}
                    footer={
                        <ButtonToolbar>
                            <Button bsStyle="success" disabled={isSaving} onClick={() => this.handleSubmit()}>
                                {isSaving ? 'Salvando...' : 'Salvar'}
                            </Button>
                            <Button bsStyle="default" onClick={() => this.onHide()}>Cancelar</Button>
                        </ButtonToolbar>
                    }
                >
                    <form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col>
                                <FieldGroup col={3} id="channelNumber" type="number" label="N&uacute;mero do canal" value={this.state.channelNumber} onChange={this.handleChange} />
                                <FieldGroup col={9} id="name" type="text" label="Nome" value={this.state.name} onChange={this.handleChange} />
                            </Col>
                        </Row>
                    </form>
                </Modals>
            </React.Fragment>
        );
    }
}

