import React, { useState, useEffect } from 'react';
import { Content, apiHelper, config, toast, Select, Link } from '../';
import { RequisicaoMaterialItem } from './RequisicaoMaterialItem';

export function RequisicaoMaterialView(props) {
    const endPoint = 'suprimentos/rm/';
    const rowId = props.match.params.id;
    const [data, setData] = useState({});
    const [form, setForm] = useState({});
    const [itens, setItens] = useState({});

    const fetchData = () => {
        let obj = {};
        rowId > 0 && apiHelper.get(`${endPoint}/${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    Object.keys(res.data.data).map(p => res.data.data[p] ? obj[p] = res.data.data[p] : null);
                    setForm(obj);

                    setData(res.data.data);

                }
                else
                    toast.error('Atenção, não foi possível a requisição ' + rowId);
            });
    }

    useEffect(fetchData, rowId);

    const handleRefresh = (itens) => {
        setItens(itens);
        fetchData();
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const changeCampoEditavel = (e, name) => {
        let value = e.target.innerText;
        setForm({ ...form, [name]: value });
    };

    const handleEnviarRequisicao = () => {
        if (itens.length === 0) {
            toast.warn('Adicione pelo menos um item');
            return;
        }
        if (itens.find(x => x.nome === 'Material Genérico')) {
            toast.warn('Edite os materiais genéricos.');
            return;
        }
        if (!window.confirm("Deseja realmente enviar esta requisição ?"))
            return;

        apiHelper.put(`${endPoint}enviarRequisicao/${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleConclusao = () => {
        if (!window.confirm("Deseja realmente concluir esta requisição de material ?"))
            return;

        apiHelper.put(`${endPoint}conclusao/${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    fetchData();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleCancelarRequisicao = () => {
        if (!window.confirm("Deseja realmente cancelar esta requisição de material ?"))
            return;

        apiHelper.api_delete(`${endPoint}cancelar/${rowId}`)
            .then(res => {
                if (res.data.code === 200) {
                    config.httpResult(res.data);
                    handleClose();
                }
                else
                    toast.error('Atenção, não foi possível executar esta operação!');
            }).catch(error => {
                toast.error('Atenção, não foi possível executar esta operação!');
            });
    }

    const handleClose = () => {
        props.history.push({
            pathname: '/suprimentos/rm/'
        });
    }

    const handeSubmit = () => {
        if (Object.keys(form).length !== 0) {
            let obj = {
                empresaVeolink: form.empresaVeolink,
                classificacaoContabil: form.classificacaoContabil,
                observacao: form.observacao,
                descricao: form.descricao,
                localEntregaCompra: form.localEntregaCompra
            };

            apiHelper.put(`${endPoint}avulso/${rowId}`, obj)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        fetchData();
                    }
                    else
                        toast.error('Atenção, não foi possível executar esta operação!');
                }).catch(error => {
                    toast.error('Atenção, não foi possível executar esta operação!');
                });
        }
    }

    return (
        <Content title="Requisição de Material" bdataserTitle="Requisição de Material">
            <div className="row">
                <div className="col-md-4">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{data.id}</h3>

                            <div className="box-tools pull-right">
                                {(data.status !== 'Concluido' && data.status !== 'Cancelado') && <button type="button" className="btn btn-box-tool" onClick={handeSubmit} title="Salvar Origem e Classificação Contábil"><i className="fa fa-save" /></button>}
                                <button type="button" className="btn btn-box-tool" onClick={handleClose}><i className="fa fa-times" /></button>
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            <table className="table table-striped table-bordered no-margin">
                                <tbody>
                                    {data.origem !== "Usuario" && <tr>
                                        <th>{(data.origem === "Projeto") ? "Projeto:" : "Ordem de Serviço:"}</th>
                                        <td>{(data.origem === "Projeto") ? <Link to={'/v2/projeto/' + data.origemId}>{data.origemId}</Link> : data.origemId}</td>
                                    </tr>}
                                    <tr>
                                        <th>Necessidade:</th>
                                        <td>{config.dateFix(data.dataNecessidade)}</td>
                                    </tr>
                                    <tr>
                                        <th>Status:</th>
                                        <td>{data.status}</td>
                                    </tr>
                                    <tr>
                                        <th>Solicitante:</th>
                                        <td>{data.usuario}</td>
                                    </tr>
                                    <tr>
                                        <th>Origem:</th>
                                        <td>{data.status === 'Concluido' ? data.empresaVeolink : config.validarClaim("RequisicaoMaterialEstoque") ?
                                            <Select id="empresaVeolink" className="form-control" onChange={handleChange} value={form.empresaVeolink}>
                                                <option value="">Selecione</option>
                                                <option endpoint={"portal/crud/PortalMetadata/?filter=Categoria::equal::EmpresasVeolink"} textfield="valor" valuefield="nome" />
                                            </Select> : data.empresaVeolink}</td>
                                    </tr>
                                    <tr>
                                        <th>Cliente:</th>
                                        <td>{data.cliente}</td>
                                    </tr>
                                    <tr>
                                        <th>Site:</th>
                                        <td>{data.site}</td>
                                    </tr>
                                    <tr>
                                        <th>Centro Custo:</th>
                                        <td>{data.centroCusto}</td>
                                    </tr>
                                    <tr>
                                        <th>Classificação Contábil</th>
                                        <td>{data.status === 'Concluido' ? data.classificacaoContabil : config.validarClaim("RequisicaoMaterialEstoque") ?
                                            <Select id="classificacaoContabil" className="form-control" onChange={handleChange} value={form.classificacaoContabil}>
                                                <option value="">Selecione</option>
                                                <option endpoint={"portal/crud/PortalMetadata/?filter=Categoria::equal::ClassificacaoContabil"} textfield="valor" valuefield="nome" />
                                            </Select> : data.classificacaoContabil}</td>
                                    </tr>
                                    <tr>
                                        <th>Descrição:</th>
                                        <td>{data.status === 'Concluido' ? data.descricao : config.validarClaim("RequisicaoMaterialEstoque") ?
                                            <div style={{ background: '#fcf8e1', outline: 0 }} id="descricao" suppressContentEditableWarning contentEditable onBlur={(e) => changeCampoEditavel(e, 'descricao')}>{form.descricao}</div> : form.descricao}</td>
                                    </tr>
                                    <tr>
                                        <th>Local de Entrega da Compra:</th>
                                        <td>{data.status === 'Concluido' ? data.localEntregaCompra : config.validarClaim("RequisicaoMaterialEstoque") ?
                                            <div style={{ background: '#fcf8e1', outline: 0, height: '50px' }} id="localEntregaCompra" suppressContentEditableWarning contentEditable onBlur={(e) => changeCampoEditavel(e, 'localEntregaCompra')}>{form.localEntregaCompra}</div> : form.localEntregaCompra}</td>
                                    </tr>
                                    <tr>
                                        <th>Local Entrega:</th>
                                        <td>{data.localEntrega}</td>
                                    </tr>
                                    <tr>
                                        <th>Observação</th>
                                        <td>{data.status === 'Concluido' ? data.observacao : config.validarClaim("RequisicaoMaterialEstoque") ?
                                            <div style={{ background: '#fcf8e1', outline: 0 }} id="observacao" suppressContentEditableWarning contentEditable onBlur={(e) => changeCampoEditavel(e, 'observacao')}>{form.observacao}</div> : form.observacao}</td>
                                    </tr>
                                    {data.status === 'Cancelado' && <tr>
                                        <th>Motivo Cancelamento</th>
                                        <td>{data.motivoCancelamento}</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>

                        <div className="box-footer" style={{ textAlign: "center" }}>
                            {data.status === 'Concluido' && <span>Requisição concluída!</span>}
                            {((data.solicitanteId === config.idUsuarioLogado() && data.status === "Rascunho") || (config.validarClaim("AprovarMaterial") && data.status === "Em Aprovação")) && itens.length > 0 && <button type="button" className="btn btn-default btn-sm btn-flat btn-block" onClick={handleEnviarRequisicao}><i className="fa fa-share" /> Enviar Requisição</button>}
                            {data.status === 'Entregue' && <button type="button" className="btn btn-default btn-sm btn-flat btn-block" onClick={handleConclusao}><i className="fa fa-folder" /> Concluir</button>}
                            {data.status === 'Rascunho' && <button type="button" className="btn btn-default btn-sm btn-flat btn-block" onClick={handleCancelarRequisicao}>Cancelar</button>}
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {data.id > 0 && <RequisicaoMaterialItem parentId={rowId} data={data} refresh={(itens) => handleRefresh(itens)} />}
                </div>
            </div>
        </Content>
    )
}
