import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config } from '../../index';
import { createHashHistory } from 'history'
export const history = createHashHistory();

export function GrupoForm(props) {
    const endPoint = "portal/crud/IdentityGroup/";
    const rowId = props.parentId;
    const [data, setData] = useState({});

    const fetchData = () => {
        let obj = {};

        if (rowId && rowId > 0) {
            apiHelper.get(`${endPoint}${rowId}/id`).then(res => {
                let data = res.data.data;
                Object.keys(data).map(p => data[p] ? obj[p] = data[p] : null);
                setData(obj);
            }).catch(error => {
                toast.error('Houve um erro ao carregar o registro.');
            });
        }
    }

    useEffect(fetchData, [rowId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setData({ ...data, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (rowId === 0) {
            data.enabled = true;
            apiHelper.post(`${endPoint}`, data)
                .then(res => {
                    if (res.data.code === 200) {
                        config.httpResult(res.data);
                        history.push(`/identity/v2/group/${res.data.data}`);
                    }
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        } else {
            apiHelper.put(`${endPoint}/${rowId}/Id`, data)
                .then(res => {
                    config.httpResult(res.data);
                    props.onHide(true);
                }).catch(error => {
                    toast.error('Atenção, não foi possível salvar o registro.');
                });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-sm-12">
                    <label>Nome *</label>
                    <input id="name" type="text" className="form-control" onChange={handleChange} value={data.name} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Descrição *</label>
                    <textarea id="description" className="form-control" rows="3" maxLength="2000" onChange={handleChange} value={data.description} required />
                </div>
            </div>
            <div className="box-footer">
                <div className="box-tool pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Fechar</button>
                        <button type="submit" className="btn btn-primary pull-right">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
