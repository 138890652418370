import React, { Component } from 'react';
import {
    Content, Row, Box, Tabs, TabContent, FasIcon,
    Col, Table, Button, ButtonToolbar, FormGroupButton
} from '../../components/adminlte/AdminLTE';

import { GroupEdit } from './GroupEdit';
import { GroupDevice } from './GroupDevice';
import { toast } from 'react-toastify';

const config = {
    keyName: 'id',
    rowName: 'name',
    token: 'Bearer ' + localStorage.getItem('id_token'),
    apiServer: localStorage.getItem('apiUrl'),
    apiUrl: localStorage.getItem('apiUrl') + 'vms/group/'
};

export class Group extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameValue: '',
            keyValue: '',
            table: [],
            row: {},
            searchValue: '',
            action: 'new',
            showForm: false,
            showConfirm: false
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.hidePerfil = this.hidePerfil.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getDataTable('');
    }

    getDataTable(filter) {
        if (filter === null || filter === undefined)
            filter = '';

        this.setState({ table: [] });

        fetch(config.apiUrl + '?name=' + filter, {
            headers: { 'Authorization': config.token }
        }).then(
            response => response.json()
        ).then(data => {
            if (data.length > 0) {
                this.setState({
                    table: data,
                    row: data[0],
                    keyValue: data[0].id,
                    nameValue: data[0].name
                });
            }
        });
    }

    handleDelete(e) {
        if (e && window.confirm("Deseja realmente excluir este registro?")) {
            fetch(config.apiUrl + this.state.row[config.keyName], {
                method: 'DELETE',
                headers: { 'Authorization': config.token }
            }).then(response => {
                return Promise.all([response.status, response.json()]);
            }).then((response) => {
                if (response[0] === 200) {
                    toast.success('Dados excluidos com sucesso!');
                    this.getDataTable('');
                }
                else
                toast.warn(response[1]["msg"]);
            }).catch(error => {
                toast.error(error);
            });
        }

        this.setState({ showConfirm: false });
    }

    loadPerfil(action) {
        this.setState({
            showForm: true,
            action: action
        });
    }

    hidePerfil() {
        this.getDataTable('');
        this.setState({
            showForm: false,
            action: 'new'
        });
    }

    handleSearch(e) {
        let value = e.target.value;
        this.setState({ searchValue: value });
        if (value.length > 1 || value === '')
            this.getDataTable(value);
    }

    clearSearch() {
        this.setState({ searchValue: '' });
        this.getDataTable('');
    }

    rowSelect(e) {
        this.setState({
            row: e,
            keyValue: e.id,
            nameValue: e.name
        });
    }

    render() {
        return (
            <Content title="Grupos de Dispositivos" browserTitle="SIGA | Grupos de Dispositivos">
                <Row>
                    <Col md={4}>
                        <Box type="success">
                            <FormGroupButton size="sm" placeholder="Filtro"
                                onChange={this.handleSearch} value={this.state.searchValue}
                                buttonLeft={<Button bsStyle="success" onClick={() => this.setState({ showForm: true })}>NOVO</Button>}
                                buttonRight={<Button title="Limpar" onClick={() => this.clearSearch()}><FasIcon name="refresh" /></Button>}
                            />
                            <Table striped bordered hover>
                                <tbody>
                                    {this.state.table
                                        .sort((a, b) => (a.name > b.name) ? 1 : -1)
                                        .map((row, i) => {
                                            return (
                                                <tr key={i} onClick={() => this.rowSelect(row)} style={{ cursor: "pointer" }}>
                                                    <td>{row.name}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </Box>
                    </Col>
                    <Col md={8}>
                        <Box type="primary" title={this.state.row.name} customOptions={
                            <ButtonToolbar>
                                <Button bsSize="sm" bsStyle="primary" onClick={() => this.loadPerfil('edit')}><FasIcon name="edit" /></Button>
                                <Button bsSize="sm" bsStyle="danger" onClick={this.handleDelete}><FasIcon name="trash" /></Button>
                            </ButtonToolbar>}
                        >
                            <Tabs defaultActiveKey="devices">
                                <TabContent title="Dispositivos" eventKey="devices">
                                    <GroupDevice config={config} keyValue={this.state.keyValue} />
                                </TabContent>
                            </Tabs>
                        </Box>
                    </Col>
                </Row>
                {this.state.showForm && <GroupEdit config={config} show={this.state.showForm} keyValue={this.state.keyValue} data={this.state.row} action={this.state.action} onHide={this.hidePerfil} />}
            </Content>
        );
    }
}
