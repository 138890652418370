import React, { useState, useEffect } from 'react';
import { apiHelper, toast, config } from '../index';

export function SucatearForm(props) {
    const endPoint = 'suprimentos/movimentacao';
    const [form, setForm] = useState([]);
    const [data, setData] = useState([]);
    const ids = props.ids;

    const fetchData = () => {
        if (!props.ids)
            return;

        let ids = String([...props.ids]);
        let where = `pkEstoque::in::${ids}`;
        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                if (res.data.code === 200)
                    setData(res.data.data);
                else
                    toast.error('Atenção, não foi possível carregar os materiais selecionados!');
            });
    };

    useEffect(fetchData, []);
    
    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        setForm({ ...form, [name]: value });
    }

    const handleRemoverItem = (id) => {
        if (props.ids.has(id)) {
            props.ids.delete(id);
            props.removerItemSelecionado(id);

            let itens = data.filter(item => item.pkEstoque !== id)
            setData(itens);

            if (itens.length === 0) {
                props.onHide();
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var obj = form;
        obj.ids = JSON.stringify([...ids]);

        apiHelper.put(`${endPoint}/sucatear`, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                props.onHide();
            }
            else
                toast.error('Atenção, não foi possível salvar o registro.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    return (
        <form onSubmit={handleSubmit}>            
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Motivo Sucateamento</label>
                    <textarea id="motivoSucateamento" className="form-control" onChange={handleChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className="bg-light-blue">
                                    <th>Id</th>
                                    <th>Material</th>
                                    <th>Patrimônio</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.sort((a, b) => a.pkEstoque < b.pkEstoque ? 1 : -1)
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.pkEstoque}</td>
                                                <td>{item.material}</td>
                                                <td>{item.patrimonio}</td>
                                                <td> <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRemoverItem(item.pkEstoque)}><i className="fa fa-trash" /></button></td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-right" onClick={() => props.onHide()}>Cancelar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Movimentar </button>
                </div>
            </div>
        </form>
    )
}
