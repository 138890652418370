import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, toast, Select, Modal } from '../';
import { MovimentacaoForm } from './MovimentacaoForm';
import { SucatearForm } from './SucatearForm';

let setIds = new Set();
export function Movimentacao(props) {
    const endPoint = 'suprimentos/movimentacao';
    const [data, setData] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [tipo, setTipo] = useState(0);
    const [form, setForm] = useState({});
    const [filter, setFilter] = useState({
        pkEstoque: '',
        material: '',
        patrimonio: '',
        fkTipoLocalArmazenamentoDestino: '',
        fkLocalArmazenamentoDestino: '',
        pkCategoria: '',
        pkTipo: '',
        pkModelo: '',
        isSucateado: ''
    });
    const [params, setParam] = useState({
        ids: '',
        showModal: false,
        modalTitle: "",
        formName: "",
        pageSize: 50,
        totalPages: 0,
        selectedPage: 0,
        initRow: 0,
        endRow: 50
    });

    const fetchData = (where) => {
        if (!where)
            where = '';

        apiHelper.get(`${endPoint}?filter=${where}`)
            .then(res => {
                console.log(res.data.data);

                if (res.data.code === 200) {
                    let data = [];
                    res.data.data.map((item) => {
                        let obj = {
                            pkEstoque: item.pkEstoque,
                            material: item.material,
                            patrimonio: item.patrimonio,
                            tipoLocalArmazenamento: item.tipoLocalArmazenamento,
                            localArmazenamento: item.localArmazenamento,
                            categoria: item.categoria,
                            tipo: item.tipo,
                            modelo: item.modelo,
                            isSucateado: item.isSucateado,
                            isChecked: setIds.has(item.pkEstoque) ? true : false // propriedade de controle usado somente no front
                        }
                        data.push(obj);
                        return true;
                    })
                    setData(data);
                }
                else
                    toast.error('Atenção, não foi possível carregar as requisições');
            });
    };

    const onLoad = () => {
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        let condition = value ? e.target.name + value : "";

        if (name === "pkTipo")
            setTipo(value);
        else if (name === "pkCategoria")
            setCategoria(value);

        setForm({ ...form, [name]: condition });
        setFilter({ ...filter, [name]: value });
    };

    const handleShowForm = (name, title) => {
        if (setIds.size === 0) {
            alert("Selecione pelo menos um item");
            return;
        }

        setParam({
            ...params,
            ids: setIds,
            showModal: true,
            modalTitle: title,
            formName: name
        });
    };

    const handleHide = (refresh = true) => {
        setParam({
            ...params,
            rowId: 0,
            showModal: false,
            modalTitle: "",
            formName: "",
            siteId: ""
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setFilter({
            pkEstoque: '',
            material: '',
            patrimonio: '',
            fkTipoLocalArmazenamentoDestino: '',
            fkLocalArmazenamentoDestino: '',
            pkCategoria: '',
            pkTipo: '',
            pkModelo: '',
            isSucateado: ''
        });
        setForm([]);
        setIds.clear();
        setData([]);
        fetchData();
    };

    const handleChangeEdit = (e, id) => {
        if (id) {
            if (setIds.has(id))
                setIds.delete(id);
            else
                setIds.add(id);
        }
        handleSearch();
    }

    const removerItemSelecionado = (id) => {
        if (setIds.has(id))
            setIds.delete(id);
    }

    const handleSearch = () => {
        let obj = form;
        let where = "";
        let sep = "";

        Object.keys(obj).map(k => {
            if (obj[k]) {
                where += sep + obj[k];
                sep = ";;AND;;";
            }

            return null;
        });

        fetchData(where);
    }

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            return;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Movimentação de Estoque" browserTitle="Movimentação de Estoque">
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-primary">
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{ marginBottom: "7px" }}>
                                        <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={() => handleShowForm("movimentacao", "Movimentar", 0)}>Movimentar</button>
                                        <button type="button" className="btn btn-default btn-sm btn-flat" style={{ marginRight: "5px" }} onClick={() => handleShowForm("sucatear", "Sucatear", 0)}>Sucatear</button>

                                        <div className="btn-group">
                                            <button type="button" className="btn btn-default btn-sm btn-flat dropdown-toggle" data-toggle="dropdown" style={{ marginRight: "5px" }}>
                                                Pesquisar <span className="caret" />
                                            </button>
                                            <div className="dropdown-menu bg-gray disabled color-palette" role="menu" style={{ width: 600, padding: 5 }} onClick={e => e.stopPropagation()}>
                                                <div className="box" style={{ margin: 0 }}>
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="form-group col-md-4">
                                                                        <label>Id</label>
                                                                        <input id="pkEstoque" name="pkEstoque::equal::" type="number" className="form-control input-sm" onChange={handleChange} value={filter.pkEstoque} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Material</label>
                                                                        <input id="material" name="material::contains::" type="text" className="form-control input-sm" onChange={handleChange} value={filter.material} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Patrimônio</label>
                                                                        <input id="patrimonio" name="patrimonio::equal::" type="number" className="form-control input-sm" onChange={handleChange} value={filter.patrimonio} />
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Tipo Armazenamento</label>
                                                                        <Select id="fkTipoLocalArmazenamentoDestino" name="fkTipoLocalArmazenamentoDestino::equal::" className="form-control input-sm" onChange={handleChange} value={filter.fkTipoLocalArmazenamentoDestino} >
                                                                            <option value="">Todos</option>
                                                                            <option endpoint={'portal/crud/tipoLocalArmazenamento'} textfield="nome" valuefield="pkTipoLocalArmazenamento" />
                                                                        </Select>
                                                                    </div>
                                                                    {filter.fkTipoLocalArmazenamentoDestino === "1" &&
                                                                        <div className="form-group col-md-4">
                                                                            <label>Local Armazenamento</label>
                                                                            <Select id="fkLocalArmazenamentoDestino" name="fkLocalArmazenamentoDestino::equal::" className="form-control input-sm" onChange={handleChange} value={filter.fkLocalArmazenamentoDestino} >
                                                                                <option value="">Todos</option>
                                                                                <option endpoint={'portal/crud/uvw_v2_pesquisa_site'} textfield="clienteSite" valuefield="pkSite" />
                                                                            </Select>
                                                                        </div>
                                                                    }
                                                                    {filter.fkTipoLocalArmazenamentoDestino === "2" &&
                                                                        <div className="form-group col-md-4">
                                                                            <label>Local Armazenamento</label>
                                                                            <Select id="fkLocalArmazenamentoDestino" name="fkLocalArmazenamentoDestino::equal::" className="form-control input-sm" onChange={handleChange} value={filter.fkLocalArmazenamentoDestino} >
                                                                                <option value="">Todos</option>
                                                                                <option endpoint={'portal/crud/localArmazenamento'} textfield="nome" valuefield="pkLocalArmazenamento" />
                                                                            </Select>
                                                                        </div>
                                                                    }
                                                                    {filter.fkTipoLocalArmazenamentoDestino === "3" &&
                                                                        <div className="form-group col-md-4">
                                                                            <label>Local Armazenamento</label>
                                                                            <Select id="fkLocalArmazenamentoDestino" name="fkLocalArmazenamentoDestino::equal::" className="form-control input-sm" onChange={handleChange} value={filter.fkLocalArmazenamentoDestino} >
                                                                                <option value="">Todos</option>
                                                                                <option endpoint={'portal/crud/prestador/?filter=isSituacaoAtivo::equal::1'} textfield="nome" valuefield="pkPrestador" />
                                                                            </Select>
                                                                        </div>
                                                                    }
                                                                    {filter.fkTipoLocalArmazenamentoDestino === "4" &&
                                                                        <div className="form-group col-md-4">
                                                                            <label>Local Armazenamento</label>
                                                                            <Select id="fkLocalArmazenamentoDestino" name="fkLocalArmazenamentoDestino::equal::" className="form-control input-sm" onChange={handleChange} value={filter.fkLocalArmazenamentoDestino} >
                                                                                <option value="">Todos</option>
                                                                                <option endpoint={'portal/crud/rhFuncionario?filter=isAtivo::equal::1'} textfield="nome" valuefield="pkFuncionario" />
                                                                            </Select>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group col-md-4">
                                                                        <label>Categoria</label>
                                                                        <Select id="pkCategoria" name="pkCategoria::equal::" className="form-control input-sm" onChange={handleChange} value={filter.pkCategoria}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint="portal/crud/categoria" textfield="nome" valuefield="pkCategoria" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Tipo</label>
                                                                        <Select id="pkTipo" name="pkTipo::equal::" className="form-control input-sm" onChange={handleChange} refresh={categoria} value={filter.pkTipo}>
                                                                            <option value="">Todos</option>
                                                                            <option endpoint={'portal/crud/tipo/?filter=fkCategoria::equal::' + categoria} textfield="nome" valuefield="pkTipo" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Modelo</label>
                                                                        <Select id="pkModelo" name="pkModelo::equal::" className="form-control input-sm" onChange={handleChange} refresh={tipo} value={filter.pkModelo} >
                                                                            <option value="">Todos</option>
                                                                            <option endpoint={'portal/crud/Modelo/?filter=fkTipo::equal::' + tipo} valuefield="pkModelo" textfield="nome" />
                                                                        </Select>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label>Sucateado</label>
                                                                        <select id="isSucateado" name="isSucateado::equal::" className="form-control input-sm" onChange={handleChange} value={filter.isSucateado}>
                                                                            <option value="">Todos</option>
                                                                            <option value="True">Sim</option>
                                                                            <option value="False">Não</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="box-footer" style={{ padding: 5 }}>
                                                        <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                                        <button type="button" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }} onClick={handleSearch}>Pesquisar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                            {renderPage()}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered no-margin" style={{ whiteSpace: "nowrap" }}>
                                            <thead>
                                                <tr className="bg-light-blue">
                                                    <th>#</th>
                                                    <th>Id</th>
                                                    <th>Material</th>
                                                    <th>Patrimônio</th>
                                                    <th>Tipo Armazenamento</th>
                                                    <th>Local Armazenamento</th>
                                                    <th>Categoria</th>
                                                    <th>Tipo</th>
                                                    <th>Modelo</th>
                                                    <th>Sucateado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.sort((a, b) => a.pkEstoque < b.pkEstoque ? 1 : -1)
                                                    .slice(params.initRow, params.endRow)
                                                    .map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td style={{ width: '50px' }}>
                                                                    {item.isSucateado === false ? <input type="checkbox" id={item.pkEstoque} onClick={(e) => handleChangeEdit(e, item.pkEstoque)} checked={item.isChecked} /> : ''}
                                                                </td>
                                                                <td>{item.pkEstoque}</td>
                                                                <td><Link to={'/suprimentos/movimentacao/' + item.pkEstoque}>{item.material}</Link></td>
                                                                <td>{item.patrimonio}</td>
                                                                <td>{item.tipoLocalArmazenamento}</td>
                                                                <td>{item.localArmazenamento}</td>
                                                                <td>{item.categoria}</td>
                                                                <td>{item.tipo}</td>
                                                                <td>{item.modelo}</td>
                                                                <td>{item.isSucateado ? 'Sim' : 'Não'}</td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ marginTop: "7px" }}>
                                        <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}> <i className="fa fa-refresh" /> Atualizar</button>

                                        <div className="pull-right">
                                            {renderPage()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={params.showModal} bsSize={'lg'} onHide={handleHide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{params.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {params.formName === "movimentacao" && <MovimentacaoForm ids={params.ids} removerItemSelecionado={(id) => removerItemSelecionado(id)} onHide={handleHide} />}
                    {params.formName === "sucatear" && <SucatearForm ids={params.ids} removerItemSelecionado={(id) => removerItemSelecionado(id)} onHide={handleHide} />}
                </Modal.Body>
            </Modal>
        </Content>
    )
}
