import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function AnaliseAtendimentos(props) {
    const endPoint = 'atendimentoReport/analiseAtendimentos';
    const [data, setData] = useState([]);
    const title = 'Análise de Atendimentos';
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;
    const idCliente = props.idCliente;

    const handleSearch = () => {
        if (!props.dataEntradaInicio || !props.dataEntradaFim || !props.idCliente)
            return;
        apiHelper.get(`${endPoint}?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}&idCliente=${idCliente}`).then(res => {
            setData(res.data.data);
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim, props.idCliente]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: { mode: 'point' },
        scales: {
            yAxes: [{
                ticks: { beginAtZero: true, stepSize: 5 }
            }]
        }
    };

    const gerarGrafico = () => {
        if (!dataEntradaInicio && !dataEntradaFim)
            return;

        let values = [];
        let values2 = [];
        let values3 = [];
        let labels = [];

        var todasOS = data.abertasDentroSLA.concat(data.abertasForaSLA);
        todasOS.map((item) => {
            if (!labels.includes(item.mes))
                labels.push(item.mes);

            return null;
        });

        //Ordena os meses do ano
        var todosMeses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
        labels.sort(function (a, b) {
            return todosMeses.indexOf(a) - todosMeses.indexOf(b);
        })

        labels.map((mes) => {
            let dentroSLA = data.abertasDentroSLA.find(c => c.mes === mes) !== undefined ? data.abertasDentroSLA.find(c => c.mes === mes).qtdOS : 0;
            let foraSLA = data.abertasForaSLA.find(c => c.mes === mes) !== undefined ? data.abertasForaSLA.find(c => c.mes === mes).qtdOS : 0;

            values.push(dentroSLA);
            values2.push(foraSLA);
            values3.push(dentroSLA + foraSLA);

            return "";
        });

        let datasets = [
            { label: 'Dentro', backgroundColor: '#3e71c4', borderWidth: 1, data: values },
            { label: 'Fora', backgroundColor: '#F44343', borderWidth: 1, data: values2 },
            { label: 'Total', backgroundColor: '#ECB25F', borderWidth: 1, data: values3 },
        ];

        const dataAux = {
            labels: labels,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
                {((data.abertasDentroSLA && data.abertasDentroSLA.length > 0) || (data.abertasForaSLA && data.abertasForaSLA.length > 0)) &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12">
                                <Bar data={gerarGrafico} options={options} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
