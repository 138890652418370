import React, { Component } from 'react';
import { FormControl, Button, Col, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';

const templatesPath = 'http://siga.veotex.com.br/templates/';

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");

export default class ExcelReader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config,
            doImportSuccess: this.props.onImportSuccess,
            file: {},
            data: [],
            cols: [],
            typeFile: '',
            nameFile: '',
            errorUpload: '',
            descriptionErrorUpload: [],
            displayModalErro: 'none'
        };

        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.typeFileChange = this.typeFileChange.bind(this);
    }

    typeFileChange(e) {
        this.setState({ typeFile: e.target.value });
    }

    handleChange(e) {
        let files = e.target.files;
        if (files && files[0]) {
            this.setState({ file: files[0] });
        }
    }

    handleFile(e) {
        let { apiUrl, token } = this.props.config;

        this.setState({ displayModalErro: 'none' });

        if (this.state.typeFile === '') { alert('Selecione um tipo de importa��o!'); return; }
        try {
            let reader = new FileReader();
            let binaryString = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                let bstr = e.target.result;
                let wb = XLSX.read(bstr, { type: binaryString ? 'binary' : 'array', bookVBA: true, cellDates: true, dateNF: 'yyyy/mm/dd;@' });
                let wsname = wb.SheetNames[0];
                let ws = wb.Sheets[wsname];
                let data = XLSX.utils.sheet_to_json(ws, { defval: "" });

                let StatusProcess = { StatusProcess: "Em Fila para Processamento" };
                let list = data.map(item => { return Object.assign(item, StatusProcess); });

                this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => { });
                
                fetch(apiUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        type: this.state.typeFile,
                        name: this.state.file.name,
                        data: list,
                        rawJson: JSON.stringify(list)
                    }, null, 2),
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': token }
                }).then(response => {
                    return Promise.all([response.status, response.json()]);
                }).then((response) => {
                    if (response[0] === 200) {
                        alert("Dados salvos com sucesso!");
                        if (typeof this.state.doImportSuccess === 'function')
                            this.state.doImportSuccess();
                    }
                    else {
                        var erros = [];

                        if (response[1].erros !== undefined) {
                            for (var i = 0; i < response[1].erros.length; i++) {
                                erros[i] = response[1].erros[i];
                            }

                            this.setState({ descriptionErrorUpload: erros, displayModalErro: '' });
                        }
                        alert(response[1]["msg"]);
                    }
                }).catch(error => {
                    alert('alert ' + error);
                });
            };

            if (binaryString)
                reader.readAsBinaryString(this.state.file);
            else
                reader.readAsArrayBuffer(this.state.file);
        } catch (e) { alert('Selecione um tipo de importa��o!'); }
    }

    render() {
        return (
            <div>
                <Row>
                    <div style={{ display: this.state.displayModalErro }}>
                        <Col md={12}>
                            <div className="box-body" >
                                <div className="alert alert-danger alert-dismissible">
                                    <h4><i className="icon fa fa-ban" /> Alerta!</h4>
                                    <ul key={1}>
                                        {this.state.descriptionErrorUpload.map(item => {
                                            return <li key={item}>{item}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </div>

                    <Col md={12}>
                        <FormControl id="active" componentClass="select" label="Ativo" value={this.state.type} onChange={this.typeFileChange} className="form-control">
                            <option value="select">Tipo de Importa&ccedil;&atilde;o</option>
                            <option value="AreaApprover">Aprovadores de Areas</option>
                            <option value="area">Areas</option>
                            <option value="cards">Crach&aacute;s</option>
                            <option value="local">Locais</option>
                            <option value="personel">Pessoas</option>
                            <option value="AuthorizedAreasForPersons">Pessoas Autorizadas</option>
                        </FormControl>

                        <br />
                        <FormControl type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <a href={templatesPath + this.state.typeFile + ".xls"} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 10, marginBottom: 5 }}>
                            <img src={require('./img/excel-icon.png')} width="50" height="50" alt="" />
                        </a>
                        <Button bsStyle="success" className="pull-right" style={{ marginTop: 10, marginBottom: 5 }} onClick={this.handleFile}>Importar</Button>
                    </Col>
                </Row>

            </div >
        );
    }
}