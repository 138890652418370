import React, { useState, useEffect } from 'react';
import { config, apiHelper, toast, Select } from '../';

export function RequisicaoForm(props) {
    const projetoId = props.projetoId;
    const [cliente, setCliente] = useState({});
    const [form, setForm] = useState({
        dataNecessidade: '',
        localEntregaMesmoCliente: 'true',
        descricao: '',
        logradouro: '',
        complemento: '',
        numero: '',
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
    });

    const fetchData = () => {
        if (projetoId) {
            apiHelper.get(`portal/crud/uvw_v2_projeto/${projetoId}/id`).then(res => {
                apiHelper.get(`portal/crud/uvw_cliente/${res.data.data.clienteId}/id`).then(res => {
                    setCliente(res.data.data);
                });
            });
        }
    }

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setForm({ ...form, [name]: value });
    }

    const handleSubmit = () => {
        if (!form.dataNecessidade || form.dataNecessidade === '') {
            alert("Informe a Data Necessidade");
            return;
        }

        let d = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0, 0, 0, 0);
        if (new Date(form.dataNecessidade + " 00:00:00") < d) {
            toast.warn(`A data mínima permitida é ${config.dateFix(d)}`);
            return;
        }

        var obj = form;
        obj.SolicitanteId = "[user_id]";
        obj.status = "Rascunho";
        obj.origem = "Projeto";
        obj.origemId = projetoId;
        obj.centroCusto = 'IMPLANTAÇÃO';
        obj.classificacaoContabil = '';
        obj.localEntrega = '';
        obj.guid = "";

        apiHelper.post(`v2/projeto/${projetoId}/requisicaomaterial`, obj).then(res => {
            if (res.data.code === 200) {
                config.httpResult(res.data);
                props.onHide();
            }
            else
                toast.error('Atenção, não foi possível salvar a requisição.');
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar a requisição.');
        });
    }

    useEffect(fetchData, [projetoId]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Mesmo Local entrega Cliente</label>
                    <select id="localEntregaMesmoCliente" className="form-control" onChange={handleChange}>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label>Data Necessidade</label>
                    <input type="date" id="dataNecessidade" className="form-control" onChange={handleChange} required />
                </div>
                <div className="form-group col-sm-12">
                    <label>Endereço Cliente</label><br />
                    <span>{`${cliente.logradouro}, ${cliente.numero} ${cliente.complemento} ${cliente.bairro} CEP:${cliente.cep} - ${cliente.cidade} ${cliente.estado}`}</span>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Descrição</label>
                    <textarea id="descricao" className="form-control" onChange={handleChange} />
                </div>
            </div>
            {form.localEntregaMesmoCliente === 'false' &&
                <React.Fragment>
                    <div className="row">
                        <div className="form-group col-sm-8">
                            <label>Logradouro</label>
                            <input id="logradouro" type="text" className="form-control" onChange={handleChange} />
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Número</label>
                            <input id="numero" type="text" className="form-control" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-8">
                            <label>Complemento</label>
                            <input id="complemento" type="text" className="form-control" onChange={handleChange} />
                        </div>
                        <div className="form-group col-sm-4">
                            <label>Cep</label>
                            <input id="cep" type="number" className="form-control" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <label>Bairro</label>
                            <input id="bairro" type="text" className="form-control" onChange={handleChange} />
                        </div>
                        <div className="form-group col-sm-6">
                            <label>Cidade</label>
                            <input id="cidade" type="text" className="form-control" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <label>UF</label>
                            <Select id="estadodeFaturamentoCliente" className="form-control" onChange={handleChange} required>
                                <option value="">Selecione</option>
                                <option endpoint="portal/crud/UF" valuefield="sigla" textfield="nome" />
                            </Select>
                        </div>
                    </div>
                </React.Fragment>
            }

            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-default pull-left" onClick={() => props.onHide()}>Fechar</button>
                    <button type="submit" className="btn btn-primary pull-right" style={{ marginRight: "7px" }}>Criar Requisição</button>
                </div>
            </div>
        </form>
    )
}
