import React, { useState, useEffect } from 'react';
import { apiHelper, Link, config } from '../../index';

export function TicketGeral(props) {
    const endPoint = 'v3/os/';
    const [novas, setNovas] = useState([]);
    const [andamento, setAndamento] = useState([]);
    const [pendencia, setPendencia] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            try {
                apiHelper.get(`${endPoint}/pages?filter=statusId::in::1,2,3&initRow=1&endRow=10000`)
                    .then(res => {
                        if (res.data.data) {
                            let rows = res.data.data.sort((a, b) => a.id < b.id ? 1 : -1);
                            setNovas(rows.filter(f => f.statusId === 1))
                            setAndamento(rows.filter(f => f.statusId === 2))
                            setPendencia(rows.filter(f => f.statusId === 3))
                        }
                    });
            } catch (error) { }
        }

        fetchData();
        setTimeout(() => setRefresh(!refresh), 30000);

    }, [refresh]);

    const getPercent = (value) => {
        let color = "";

        if (value < 40) color = 'badge-success';
        else if (value < 70) color = 'badge-primary';
        else if (value < 99) color = 'badge-warning';
        else color = 'badge-danger';

        return color;
    };

    const handleFilter = (e) => {
        //let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        //let name = e.target.name;
    };

    const renderTable = (data, filter) => {
        let customer = [...new Set(data.map(item => item.cliente))];
        let uf = [...new Set(data.map(item => item.uf))];

        return (
            <table className="table table-striped table-bordered m-0">
                <tbody>
                    {filter &&
                        <tr>
                            <td className="p-1">
                                <div className="row">
                                    <div className="col-md-3">
                                        <select className="form-control form-control-sm" onChange={handleFilter} name="uf">
                                            <option value="">Estado</option>
                                            {uf.sort().map((m, i) => <option key={i} value={m} className="text-uppercase">{m}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-control form-control-sm" onChange={handleFilter} name="customer">
                                            <option value="">Clientes</option>
                                            {customer.sort().map((m, i) => <option key={i} value={m} className="text-uppercase">{m}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    }
                    {data.length > 0
                        ? data.map((item, i) => {
                            let title = `${item.tempoDecorridoHHMM} de ${item.nivelSLACorrente}h (${Math.round(item.percentualSLACorrente)}%)`;
                            return (
                                <tr key={i}>
                                    <td className="p-2">
                                        <span className={`badge ${getPercent(item.percentualSLACorrente)} float-right`}>Nº {item.id}</span>
                                        <Link replace to="#" className="product-title text-uppercase" onClick={() => props.onSelect(item.id)}>{item.uf} - {item.site}</Link>
                                        <div className="product-description text-sm">{item.resumo}</div>
                                        <span className="product-description text-sm float-right">{title}</span>
                                        <span className="product-description text-sm">{config.dateTimeFix(item.dataCadastro)}</span>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td className="p-1">
                                <center>Nenhum registro para exibir!</center>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        )
    }

    return (
        <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0 bg-light">
                <ul className="nav nav-tabs" id="os-tabs-list-tab" role="tablist">
                    {/* <li className="pt-2 px-3"><h3 className="card-title"><i className="fas fa-tasks" /></h3></li> */}
                    <li className="pt-2 px-1"></li>
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="pill" href="#os-tabs-list-01" role="tab">Novas <span className="badge badge-success">{novas.length}</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="pill" href="#os-tabs-list-02" role="tab">Andamento <span className="badge badge-warning">{andamento.length}</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="pill" href="#os-tabs-list-03" role="tab">Pendencia <span className="badge badge-secondary">{pendencia.length}</span></a>
                    </li>
                </ul>
            </div>
            <div className="card-body p-1" style={{ height: 390, overflow: 'auto' }}>
                <div className="tab-content" id="os-tabs-list-tabContent">
                    <div className="tab-pane fade show active" id="os-tabs-list-01" role="tabpanel">
                        {renderTable(novas, false)}
                    </div>
                    <div className="tab-pane fade" id="os-tabs-list-02" role="tabpanel">
                        {renderTable(andamento, true)}
                    </div>
                    <div className="tab-pane fade" id="os-tabs-list-03" role="tabpanel">
                        {renderTable(pendencia, true)}
                    </div>
                </div>
            </div>
        </div>
    )
}
