import React, { Component } from 'react';
import {
    Modals, FieldGroup, Button, ButtonToolbar
} from '../../components/adminlte/AdminLTE';
import { toast } from 'react-toastify';

export class CancelarAcionamento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actTechnicalCode: 0,
            description: '',
            apiEndPoint: localStorage.getItem('apiUrl') + 'siga/serviceOrder/',
            token: 'Bearer ' + localStorage.getItem('id_token')
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ actTechnicalCode: newProps.actTechnicalCode });
    }

    handleChange(e) {
        let value = e.target["value"];
        this.setState({ description: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (window.confirm("Cancelar Acionamento Tecnico ? ")) {

            var dados = {
                actTechnicalCode: this.state.actTechnicalCode,
                description: this.state.description
            };

            fetch(this.state.apiEndPoint + "cancelActTechnical", {
                method: 'PUT',
                body: JSON.stringify(dados),
                headers: { 'Content-Type': 'application/json', 'Authorization': this.state.token }

            }).then(response => {
                return Promise.all([response.status, response.json()]);
            }).then((response) => {
                if (response[0] === 200) {

                    toast.success('Acionamento cancelado com sucesso!');
                    this.onHide();
                }
                else {
                    toast.warn(response[1]["msg"]);
                }
            }).catch(error => {
                toast.error(error);
            });
        }
    }

    onHide() {

        this.setState({ actTechnicalCode: 0, description: '' });
        this.props.onHide();
    }

    render() {
        const { show = false } = this.props;

        return (
            <Modals title="Cancelar Acionamento" show={show} onHide={() => this.onHide()}
                footer={
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="success" type="submit" onClick={this.handleSubmit}>Executar</Button>
                        <Button bsStyle="default" onClick={(codigo) => this.onHide(codigo)}>Fechar</Button>
                    </ButtonToolbar>
                }
            >
                <FieldGroup id="description" type="text" componentClass="textarea" maxLength="500" label="Descrição" value={this.state.description} onChange={this.handleChange} />
            </Modals>
        );
    }
}