import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiHelper } from './../../index';

export function GraficoGeral(props) {
    const endPoint = 'lead/report';
    const [dataOrigem, setDataOrigem] = useState([]);
    const [dataInteracao, setDataInteracao] = useState([]);
    const [dates, setDates] = useState([]);
    const [titleSemana, setTitleSemana] = useState('');
    const dataEntradaInicio = `${props.dataEntradaInicio} 00:00:00`;
    const dataEntradaFim = `${props.dataEntradaFim} 23:59:59`;

    const handleSearch = () => {
        if (!props.dataEntradaInicio)
            return;

        apiHelper.get(`${endPoint}/geral?dataInicio=${dataEntradaInicio}&dataFim=${dataEntradaFim}`).then(res => {
            if (res.data.code === 200 && res.data.data) {

                let dates = [];
                if (res.data.data.datasOrdenadas !== undefined) {
                    res.data.data.datasOrdenadas.map(item => {
                        dates.push(item.ano + '-' + item.semana);
                        return true;
                    });
                    setDataOrigem(res.data.data.origem);
                    setDataInteracao(res.data.data.interacao);
                    setDates([...new Set(dates)]);
                }
            }
        });
    };

    useEffect(handleSearch, [props.dataEntradaInicio, props.dataEntradaFim]);

    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: { position: 'bottom' },
        tooltips: { mode: 'point' },
        scales: {
            yAxes: [{ ticks: { beginAtZero: true, stepSize: 5 } }]
        }
    };

    const gerarGrafico = () => {
        if (!props.dataEntradaInicio)
            return;
        let fti = [];
        let mkt = [];
        let pap = [];
        let outros = [];
        let visitasObtidas = [];
        let gerouProposta = [];

        setTitleSemana(`(Semana ${dates[0]} a Semana ${dates[dates.length - 1]})`);

        dates.map(d => {
            let objFTI = dataOrigem.filter(p => p.origem === 'FTI' && (p.ano + '-' + p.semana) === d);
            fti.push(objFTI.length > 0 ? objFTI[0].total : 0)

            let objMKT = dataOrigem.filter(p => p.origem === 'MKT' && (p.ano + '-' + p.semana) === d);
            mkt.push(objMKT.length > 0 ? objMKT[0].total : 0)

            let objPAP = dataOrigem.filter(p => p.origem === 'PAP' && (p.ano + '-' + p.semana) === d);
            pap.push(objPAP.length > 0 ? objPAP[0].total : 0)

            let objOutro = dataOrigem.filter(p => p.origem === 'OUTROS' && (p.ano + '-' + p.semana) === d);
            outros.push(objOutro.length > 0 ? objOutro[0].total : 0)

            let objVisita = dataInteracao.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === d);
            visitasObtidas.push(objVisita.length > 0 ? objVisita[0].total : 0)

            let objProposta = dataInteracao.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === d);
            gerouProposta.push(objProposta.length > 0 ? objProposta[0].total : 0)

            return null;
        })

        let datasets = [
            { label: 'Contatos via FTI', backgroundColor: '#0771bf', borderWidth: 1, data: fti },
            { label: 'Contatos via MKT', backgroundColor: '#c18503', borderWidth: 1, data: mkt },
            { label: 'Contatos via PAP', backgroundColor: '#fd0014', borderWidth: 1, data: pap },
            { label: 'OUTROS', backgroundColor: '#FFFE33', borderWidth: 1, data: outros },
            { label: 'Visitas Obtidas', backgroundColor: '#01af50', borderWidth: 1, data: visitasObtidas },
            { label: 'Propostas Geradas', backgroundColor: '#000000', borderWidth: 1, data: gerouProposta }
        ];

        const dataAux = {
            labels: dates,
            datasets: datasets
        }
        return dataAux;
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">Análise de Prospecção {titleSemana}</h3>
            </div>
            <div className="panel-body">
                {dataOrigem.length > 0 && <Bar data={gerarGrafico} options={options} />}
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            {dates.map((item, i) => {
                                return (<td key={i}>{item}</td>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Contato via FTI</th>
                            {dates.map((item, i) => {
                                let obj = dataOrigem.filter(p => p.origem === 'FTI' && (p.ano + '-' + p.semana) === item);
                                return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                            })}
                        </tr>
                        <tr>
                            <th>Contato via MKT</th>
                            {dates.map((item, i) => {
                                let obj = dataOrigem.filter(p => p.origem === 'MKT' && (p.ano + '-' + p.semana) === item);
                                return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                            })}
                        </tr>
                        <tr>
                            <th>Contato via PAP</th>
                            {dates.map((item, i) => {
                                let obj = dataOrigem.filter(p => p.origem === 'PAP' && (p.ano + '-' + p.semana) === item);
                                return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                            })}
                        </tr>
                        <tr>
                            <th>OUTROS</th>
                            {dates.map((item, i) => {
                                let obj = dataOrigem.filter(p => p.origem === 'OUTROS' && (p.ano + '-' + p.semana) === item);
                                return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                            })}
                        </tr>
                        <tr>
                            <th>Visitas Obtidas</th>
                            {dates.map((item, i) => {
                                let obj = dataInteracao.filter(p => p.tipo === 'Visita Obtida' && (p.ano + '-' + p.semana) === item);
                                return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                            })}
                        </tr>
                        <tr>
                            <th>Propostas Geradas</th>
                            {dates.map((item, i) => {
                                let obj = dataInteracao.filter(p => p.tipo === 'Gerou Proposta' && (p.ano + '-' + p.semana) === item);
                                return (<td key={i}>{obj[0] ? obj[0].total : 0}</td>)
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
    )
}
