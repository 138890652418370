import React, { useState, useEffect } from 'react';
import { Content, Link, apiHelper, toast, config } from '../index';
import { GrupoForm } from './GrupoForm';

export function Grupo(props) {
    const endPoint = 'portal/crud/IdentityGroup';
    const [data, setData] = useState([]);

    const [params, setParam] = useState({
        rowId: 0,
        showForm: false,
        pageSize: 10,
        totalPages: 1,
        selectedPage: 0,
        initRow: 0,
        endRow: 10
    });

    const fetchData = (where) => {
        if (!where)
            where = "";

        apiHelper.get(`${endPoint}?filter=${where}`).then(res => {
            setData(res.data.data);
        });
    };

    const onLoad = () => {
        config.validarPermissao('MenuAdminGrupo');
        fetchData();
    };

    useEffect(onLoad, []);

    const handleChange = (e) => {
        fetchData(`name::contains::${e.target["value"]}`);
    };

    const handleShowForm = (rowId) => {
        setParam({
            ...params,
            rowId: rowId,
            showForm: true
        });
    };
    const handleDelete = (id) => {
        if (!window.confirm("Deseja realmente excluir este registro ?"))
            return;

        apiHelper.api_delete(`identity/Group/${id}`).then(res => {
            toast.success('Grupo excluído com sucesso!');
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro.');
        });
    }

    const handleHide = (refresh) => {
        setParam({
            ...params,
            rowId: 0,
            showForm: false
        });

        refresh && fetchData();
    };

    const handleRefresh = () => {
        setData([]);
        fetchData();
    };

    const setPage = (page) => {
        if (!page || page < 0)
            page = 0;

        if (page === params.totalPages)
            page = params.totalPages;

        let init = page * params.pageSize;
        let end = init + params.pageSize;

        setParam({
            ...params,
            totalPages: Math.ceil(data.length / params.pageSize),
            selectedPage: page,
            initRow: init,
            endRow: end
        });
    };

    const renderPage = () => {
        const pages = Math.ceil(data.length / params.pageSize);

        return (
            <div className="btn-group">
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage - 1)}><i className="fa fa-chevron-left" /></button>
                <div className="btn btn-default btn-sm">{(params.selectedPage + 1) + '-' + pages + '/' + data.length + ' '}</div>
                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => setPage(params.selectedPage + 1)}><i className="fa fa-chevron-right" /></button>
            </div>
        )
    };

    return (
        <Content title="Grupo" browserTitle="Grupo">
            <div className="row">
                <div className="col-md-4">
                    <div className="box">
                        <div className="box-header with-border" style={{ padding: 5 }}>
                            <div className="input-group">
                                <div className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleShowForm(0)} style={{ marginRight: "5px" }}><i className="fa fa-plus" /> Novo Grupo</button>
                                </div>
                                <input id="name" type="text" className="form-control input-sm" placeholder="Pesquisar" onChange={handleChange} />
                                <i className="fa fa-search form-control-feedback" />
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-bordered no-margin">
                                    <thead>
                                        <tr className="bg-light-blue">
                                            <th>Nome</th>
                                            <th style={{ width: "5px" }}>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.sort((a, b) => a.id < b.id ? 1 : -1)
                                            .slice(params.initRow, params.endRow)
                                            .map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><Link replace to="#" onClick={() => handleShowForm(item.id)}>{item.name}</Link></td>
                                                        <td>
                                                            <div className="btn-toolbar">
                                                                <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleDelete(item.id)}> <i className="fa fa-trash" /></button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="box-footer" style={{ padding: 5 }}>
                            <button type="button" className="btn btn-default btn-sm btn-flat" onClick={() => handleRefresh()}><i className="fa fa-refresh" /> Atualizar</button>
                            <div className="pull-right">
                                {renderPage()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {params.showForm ?
                        <GrupoForm parentId={params.rowId} onHide={handleHide} />
                        :
                        <div className="alert alert-info">
                            <center>Nenhum registro selecionado!</center>
                        </div>
                    }
                </div>
            </div>
        </Content>
    )
}