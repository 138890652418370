import React, { useState, useEffect } from 'react';
import { apiHelper, toast, Select } from '../';

export function AgendamentoItem(props) {
    const endPoint = 'portal/crud/agendamentoIntervalo';
    const parentId = props.parentId;
    const [data, setData] = useState([]);
    const [horas, setHoras] = useState([]);

    let usuarioLogadoId = localStorage.getItem('claims') !== null
        && localStorage.getItem('claims') !== undefined ? localStorage.id : '';

    const fetchData = () => {
        apiHelper.get(`${endPoint}?filter=AgendamentoId::equal::${parentId}`).then(res => {
            if (res.data.code === 200)
                setData(res.data.data);
            else
                toast.error('Atenção, não foi possível carregar os intervalos!');
        }).catch(error => {
            toast.error(error);
        });
    }

    const onLoad = () => {
        fetchData();
        obterHoras();
    }

    useEffect(onLoad, [parentId]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;
        let idIntervalo = e.target.parentElement.parentElement.id;
        let item = { [name]: value };
        atualizarIntervalo(idIntervalo, item);
    }

    const obterHoras = () => {
        let horas = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
        setHoras(horas);
    }

    const handleNew = () => {
        let data = {
            segunda: 0,
            terca: 0,
            quarta: 0,
            quinta: 0,
            sexta: 0,
            sabado: 0,
            domingo: 0,
            agendamentoId: parentId,
            usuarioId: usuarioLogadoId
        };

        apiHelper.post("v2/os/agendamento", data).then(res => {
            if (res.data.code === 200)
                fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    const handleDelete = (id) => {
        apiHelper.api_delete(`v2/os/agendamento/${id}`).then(res => {
            
            if (res.data === 200)
                fetchData();
            else
                toast.error('Atenção, não foi possível excluir o registro!');
        }).catch(error => {
            toast.error('Atenção, não foi possível excluir o registro!');
        });
    }

    const atualizarIntervalo = (id, item) => {
        apiHelper.put(`v2/os/agendamento/${id}`, item).then(res => {
            fetchData();
        }).catch(error => {
            toast.error('Atenção, não foi possível salvar o registro.');
        });
    }

    return (
        <React.Fragment>
            <div style={{ marginBottom: "7px" }}>
                <button type="button" style={{ marginRight: "7px" }} className="btn btn-default btn-sm btn-flat" onClick={handleNew}><i className="fa fa-plus" /> Adicionar</button>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-bordered no-margin">
                    <thead>
                        <tr className="bg-light-blue">
                            <th>Seg</th>
                            <th>Ter</th>
                            <th>Qua</th>
                            <th>Qui</th>
                            <th>Sex</th>
                            <th>Sab</th>
                            <th>Dom</th>
                            <th>Hora Início</th>
                            <th>Hora Fim</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data
                            .map((item, i) => {
                                return (
                                    <tr id={item.id} key={i}>
                                        <td><input type="checkbox" id="segunda" onChange={handleChange} checked={item.segunda ? true : false} /></td>
                                        <td><input type="checkbox" id="terca" onChange={handleChange} checked={item.terca ? true : false} /></td>
                                        <td><input type="checkbox" id="quarta" onChange={handleChange} checked={item.quarta ? true : false} /></td>
                                        <td><input type="checkbox" id="quinta" onChange={handleChange} checked={item.quinta ? true : false} /></td>
                                        <td><input type="checkbox" id="sexta" onChange={handleChange} checked={item.sexta ? true : false} /></td>
                                        <td><input type="checkbox" id="sabado" onChange={handleChange} checked={item.sabado ? true : false} /></td>
                                        <td><input type="checkbox" id="domingo" onChange={handleChange} checked={item.domingo ? true : false} /></td>
                                        <td>
                                            <Select id="horaInicio" className="form-control input-sm" onChange={handleChange} value={item.horaInicio || ""}>
                                                <option value="">Selecione</option>
                                                {horas && horas.map((item, i) => {
                                                    return (<option key={i} value={item}>{item}</option>)
                                                })}
                                            </Select>
                                        </td>
                                        <td>
                                            <Select id="horaFim" className="form-control input-sm" onChange={handleChange} value={item.horaFim || ""}>
                                                <option value="">Selecione</option>
                                                {horas && horas.map((item, i) => {
                                                    return (<option key={i} value={item}>{item}</option>)
                                                })}
                                            </Select>
                                        </td>
                                        <td style={{ width: 48 }}>
                                            <button type="button" className="btn btn-danger btn-sm btn-flat" onClick={() => handleDelete(item.id)}><i className="fa fa-trash" /></button>
                                        </td>
                                    </tr>
                                )
                            })}

                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}
