import React, { Component } from 'react';
import { Link, FasIcon } from '../AdminLTE';

class Item extends Component {
    render() {
        const { to, text, icon, children } = this.props;
        const hasChildren = !!(children);
        const pathname = window.location.hash.substr(1);

        let active = pathname === to;
        let activeChild = false;

        if (hasChildren) {
            activeChild = children
                .filter(p => p.type === Item)
                .filter(p => p.props.to === pathname)
                .length;
        }

        let group = hasChildren &&
            <Link to={to}>
                <FasIcon name={icon} /> <span>{text}</span>
                <span className="pull-right-container"><i className="fa fa-angle-left pull-right" /></span>
            </Link>

        let actualComponent = hasChildren ? group : <Link replace to={to}><i className="fa fa-circle-o" /> {text}</Link>;

        const liClasses = [
            active ? 'active' : null,
            hasChildren ? 'treeview' : null,
            activeChild ? 'menu-open' : null
        ].filter(p => p).join(' ');

        return (
            <li className={liClasses}>
                {actualComponent}
                {hasChildren && (
                    <ul className="treeview-menu" style={{ display: activeChild ? 'block' : 'none' }}>
                        {children}
                    </ul>
                )}
            </li>
        );
    }
}

export default Item;