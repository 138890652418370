import React, { useState, useEffect } from 'react';
import { Select } from '../';
import { EvolucaoQtdDispositivos } from './report/EvolucaoQtdDispositivos';
import { EvolucaoQtdUnidades } from './report/EvolucaoQtdUnidades';
import { DispositivosPorTipo } from './report/DispositivosPorTipo';
import { DispositivosPorUnidade } from './report/DispositivosPorUnidade';
import { QtdChamadosMes } from './report/QtdChamadosMes';
import { QtdChamadosAno } from './report/QtdChamadosAno';
import { QtdTipoChamado } from './report/QtdTipoChamado';
import { PercentQtdTipoChamado } from './report/PercentQtdTipoChamado';
import { QtdTipoAtendimento } from './report/QtdTipoAtendimento';
import { PercentQtdTipoAtendimento } from './report/PercentQtdTipoAtendimento';
import { QtdIncidentesDentroFora } from './report/QtdIncidentesDentroFora';
import { PercentQtdIncidentesDentroFora } from './report/PercentQtdIncidentesDentroFora';
import { QtdSolicitacoesDentroFora } from './report/QtdSolicitacoesDentroFora';
import { PercentQtdSolicitacoesDentroFora } from './report/PercentQtdSolicitacoesDentroFora';
import { PercentPreventivasRealizadas } from './report/PercentPreventivasRealizadas';
import { ManutencoesPreventivas } from './report/ManutencoesPreventivas';
import { PercentAnaliseAtendimentos } from './report/PercentAnaliseAtendimentos';
import { PrazosAtendimento } from './report/PrazosAtendimento';
import { PercentPrazosAtendimento } from './report/PercentPrazosAtendimento';
import { AnaliseAtendimentos } from './report/AnaliseAtendimentos';
import { AnaliseChamadosForaPrazo } from './report/AnaliseChamadosForaPrazo';
import { GestaoSites } from './report/GestaoSites';
import { GestaoNiveisServicos } from './report/GestaoNiveisServico';
import { ControleMateriais } from './report/ControleMateriais';
import { ResumoMes } from './report/ResumoMes';
import { IncidenteSolicitacaoDentroFora } from './report/IncidenteSolicitacaoDentroFora';
import { ManutencaoPreventivaTecnicos } from './report/ManutencaoPreventivaTecnicos';
import { ManutencaoPreventivaGrid } from './report/ManutencaoPreventivaGrid';

import moment from 'moment-timezone';

export function DashboardRelatoriosClientes(props) {
    const endPoint = 'v3/os/';
    const [filter, setFilter] = useState({});
    const [form, setForm] = useState({});
    const [anos, setAnos] = useState([]);

    const handleChange = (e) => {
        let value = e.target[e.target.type === "checkbox" ? "checked" : "value"];
        let name = e.target.id;

        setFilter({ ...filter, [name]: value });
    };

    const preencherComboAno = () => {
        let anoAtual = new Date().getFullYear();
        let anos = [];

        for (var ano = 2021; ano <= anoAtual; ano++) {
            anos.push(ano);
        }
        setAnos(anos.reverse());
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let mes = moment().month(`${filter.mes}`).format("MM");
        let ultimoDiaMes = moment(`${filter.ano}-${mes}`, 'YYYY-MM').endOf('month').format('D')
        let dataInicio = `${filter.ano}-${mes}-01`;
        let dataFim = `${filter.ano}-${mes}-${ultimoDiaMes}`;

        setForm({ ...form, dataEntradaInicio: dataInicio, dataEntradaFim: dataFim, idCliente: filter.idCliente })
    }

    useEffect(preencherComboAno, []);

    const handleRefresh = () => {
        setFilter({
            mes: '',
            ano: '',
            idCliente: '',
            dataEntradaInicio: '',
            dataEntradaFim: '',
        });

        setForm({
            mes: '',
            ano: '',
            idCliente: '',
            dataEntradaInicio: '',
            dataEntradaFim: '',
        });

        preencherComboAno();
    };

    return (
        <div className="box-body">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row form-horizontal">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="mes" className="col-sm-3 control-label">Mês</label>
                                    <div className="col-sm-9">
                                        <Select id="mes" className="form-control" onChange={handleChange} value={filter.mes} required>
                                            <option value="">Selecione</option>
                                            <option value="Jan">Janeiro</option>
                                            <option value="Feb">Fevereiro</option>
                                            <option value="Mar">Março</option>
                                            <option value="Apr">Abril</option>
                                            <option value="May">Maio</option>
                                            <option value="Jun">Junho</option>
                                            <option value="Jul">Julho</option>
                                            <option value="Aug">Agosto</option>
                                            <option value="Sep">Setembro</option>
                                            <option value="Oct">Outubro</option>
                                            <option value="Nov">Novembro</option>
                                            <option value="Dec">Dezembro</option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="ano" className="col-sm-3 control-label">Ano</label>
                                    <div className="col-sm-9">
                                        <Select id="ano" className="form-control" onChange={handleChange} value={filter.ano} required>
                                            <option value="">Selecione</option>
                                            {anos.map((ano, i) => <option key={i} value={ano}>{ano}</option>)}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row form-horizontal">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="clienteId" className="col-sm-3 control-label">Cliente</label>
                                            <div className="col-sm-9">
                                                <Select id="idCliente" className="form-control" onChange={handleChange} value={filter.idCliente} required>
                                                    <option value="">Selecione</option>
                                                    <option endpoint={`${endPoint}clientes`} valuefield="id" textfield="nome" />
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="box-footer" style={{ padding: 5 }}>
                                            <button type="button" className="btn btn-default btn-flat btn-sm pull-right" onClick={handleRefresh}>Limpar</button>
                                            <button type="submit" className="btn btn-primary btn-flat btn-sm pull-right" style={{ marginRight: '3px' }}>Pesquisar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {form.dataEntradaInicio && form.dataEntradaFim && form.idCliente &&
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <EvolucaoQtdDispositivos dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <EvolucaoQtdUnidades dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DispositivosPorTipo idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DispositivosPorUnidade dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <QtdChamadosMes dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <QtdChamadosAno dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <QtdTipoChamado dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentQtdTipoChamado dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <QtdTipoAtendimento dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentQtdTipoAtendimento dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <AnaliseAtendimentos dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentAnaliseAtendimentos dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <PrazosAtendimento dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentPrazosAtendimento dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <QtdIncidentesDentroFora dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentQtdIncidentesDentroFora dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <QtdSolicitacoesDentroFora dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentQtdSolicitacoesDentroFora dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <ManutencoesPreventivas dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                        <div className="col-md-6">
                            <PercentPreventivasRealizadas dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <GestaoSites idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <AnaliseChamadosForaPrazo dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <GestaoNiveisServicos dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ControleMateriais dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ResumoMes dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <IncidenteSolicitacaoDentroFora dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ManutencaoPreventivaTecnicos dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ManutencaoPreventivaGrid dataEntradaInicio={form.dataEntradaInicio} dataEntradaFim={form.dataEntradaFim} idCliente={form.idCliente} />
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>

    )
}
